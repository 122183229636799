import React, { Fragment } from 'react';
import { List, Avatar, Empty } from 'antd';
import styled from 'styled-components';
import empty from '../../assets/icons/empty.svg';
import { I18n } from '@aws-amplify/core';

export const TitleItem = styled.span`
	padding-left: 15px;
`;

export const Container = styled.div`
	min-height: 120px;
`;

export function EmptyList({ description, footer }) {
	return (
		<Container>
			<Empty image={empty} imageStyle={{ height: 60 }} description={<span>{I18n.get(description)}</span>} />
		</Container>
	);
}

export default function Item({ item, onAction }) {
	const maxCharacters = 40;

	function selectItem(e, item) {
		e.preventDefault();
		onAction(item);
	}

	function truncateText(str, limit) {
		return str.length > limit ? str.slice(0, limit - 1) + '...' : str;
	}

	return (
		<Fragment>
			<Avatar size="large" src={item.url_logo} />
			<a href="!#" onClick={(e) => selectItem(e, item)}>
				<TitleItem>{truncateText(item.name, maxCharacters)}</TitleItem>
			</a>
		</Fragment>
	);
}

export function ListItens({ data, onAction }) {
	return (
		<List
			dataSource={data || []}
			itemLayout="horizontal"
			renderItem={(item) => (
				<List.Item>
					<List.Item.Meta title={<Item onAction={onAction} item={item} />} />
				</List.Item>
			)}
		/>
	);
}
