import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Header } from './Header';
import { OverallDetailedParameters } from '../types';
import { DetailDescription, Details, DetailTitle } from './styles';

type Detail = {
	id: number;
	title: string;
	left: number;
	right: number;
};

interface IDetailedProps {
	parameters: OverallDetailedParameters;
}

export const Detailed: React.FC<IDetailedProps> = ({ parameters }) => {
	const { intensity_of_exertion, efforts_per_minute, exertion_duration, wrist_posture, daily_duration } = parameters;

	const details: Detail[] = [
		{
			id: 1,
			title: 'Intensity of exertion',
			left: intensity_of_exertion.left,
			right: intensity_of_exertion.right
		},
		{
			id: 2,
			title: 'Efforts per minute',
			left: efforts_per_minute.left,
			right: efforts_per_minute.right
		},
		{
			id: 3,
			title: 'Duration per exertion',
			left: exertion_duration.left,
			right: exertion_duration.right
		},
		{
			id: 4,
			title: 'Hand/wrist posture',
			left: wrist_posture.left,
			right: wrist_posture.right
		},
		{
			id: 5,
			title: 'Duration of task per day',
			left: daily_duration.left,
			right: daily_duration.right
		}
	];
	return (
		<Row>
			<Col span={24}>
				<Header />
			</Col>
			<Divider style={{ margin: 0 }} />
			<Col span={24}>
				{details.map((detail) => {
					return (
						<Row justify="space-between" key={detail.id}>
							<Col span={12} style={{ paddingLeft: '1rem' }}>
								<Details>
									<Row>
										<Col span={24}>
											<DetailTitle>{I18n.get(detail.title)}:</DetailTitle>
										</Col>
									</Row>
								</Details>
							</Col>

							<Col span={6}>
								<Details>
									<Row justify="center" style={{ marginRight: '16px' }}>
										<DetailDescription>{detail.left}</DetailDescription>
									</Row>
								</Details>
							</Col>

							<Col span={5}>
								<Details>
									<Row justify="center" style={{ marginRight: '8px' }}>
										<DetailDescription>{detail.right}</DetailDescription>
									</Row>
								</Details>
							</Col>
							<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
							{detail.id !== details.length && <Divider style={{ margin: 0 }} />}
						</Row>
					);
				})}
			</Col>
		</Row>
	);
};
