import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Tooltip, Button, Form, message } from 'antd';
import { InfoCircleOutlined, FileAddOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { Title, Icon } from './styles';
import { CreateCustom } from './Customization/Create';
import { BandRiskList } from './Customization';

export function Preferences() {
	const [form] = Form.useForm();
	const [visible, setVisible] = useState(false);

	const [disabledCreate, setDisabledCreate] = useState(true);
	const [rangesRisk, setRangesRisk] = useState({ loading: true, data: [] });
	const [deleteBand, setDeleteBand] = useState({
		loading: false,
		data: null,
	});
	const [createdBand, setCreatedBand] = useState({
		loading: false,
		data: null,
	});

	const [errors, setErrors] = useState(null);

	const rangeRiskListCallback = useCallback(() => {
		const url = '/customization/risk-ranges/';
		Api.get(url)
			.then(setRangesRisk((current) => ({ ...current, loading: true })))
			.then((res) => {
				const total = res.data.reduce(
					(acc, item) => (acc += !item.standard ? 1 : 0),
					0
				);
				setDisabledCreate(total >= 4 ? true : false);
				setRangesRisk((current) => ({ ...current, data: res.data }));
			})
			.catch((err) => setErrors(err))
			.finally(() =>
				setRangesRisk((current) => ({ ...current, loading: false }))
			);
	}, []);

	useEffect(() => {
		rangeRiskListCallback();
	}, [rangeRiskListCallback]);

	const onShowCreate = () => setVisible(true);

	const onCloseCreate = () => setVisible(false);

	const onCreate = (body) => {
		const url = '/customization/risk-ranges/create';
		Api.post(url, body)
			.then(setCreatedBand((current) => ({ ...current, loading: true })))
			.then((res) => {
				setCreatedBand((current) => ({ ...current, data: res.data }));
				onCloseCreate();
			})
			.catch((err) => setErrors(err))
			.finally(() =>
				setCreatedBand((current) => ({ ...current, loading: false }))
			);
	};

	const onDelete = (id) => {
		const url = `/customization/risk-ranges/${id}`;
		return Api.delete(url)
			.then(setDeleteBand((current) => ({ ...current, loading: true })))
			.then((res) => {
				setDeleteBand((current) => ({ ...current, data: res.data }));
				onCloseCreate();
			})
			.catch((err) => setErrors(err))
			.finally(() =>
				setDeleteBand((current) => ({ ...current, loading: false }))
			);
	};

	useEffect(() => {
		if (createdBand.data) {
			const description = I18n.get('Risk track created successfully!');
			message.success(description);
			setCreatedBand((current) => ({ ...current, data: null }));
			rangeRiskListCallback();
		}

		if (deleteBand.data) {
			const description = I18n.get(deleteBand.data.message);
			message.success(description);
			setDeleteBand((current) => ({ ...current, data: null }));
			rangeRiskListCallback();
		}
	}, [createdBand.data, deleteBand.data, rangeRiskListCallback]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	return (
		<Row>
			<CreateCustom
				form={form}
				visible={visible}
				onFinish={onCreate}
				onClose={onCloseCreate}
				loading={createdBand.loading}
			/>
			<Col sm={24}>
				<Title>
					<h2>{I18n.get('Preferences')}</h2>
				</Title>
			</Col>
			<Col sm={24}>
				<Title>
					<h2 style={{ fontSize: '20px' }}>
						{I18n.get('Risk ranges')}
					</h2>
				</Title>
				<Icon>
					<Tooltip
						placement="topLeft"
						arrowPointAtCenter
						title={I18n.get(
							'Create custom risk bands based on your analysis'
						)}
					>
						<InfoCircleOutlined style={{ fontSize: '15px' }} />
					</Tooltip>
				</Icon>
				<Button
					size="large"
					type="primary"
					onClick={onShowCreate}
					disabled={disabledCreate}
					icon={<FileAddOutlined />}
					style={{ display: 'inline', float: 'right' }}
				>
					{I18n.get('Create')}
				</Button>
			</Col>
			<Col sm={24} style={{ paddingTop: '10px' }}>
				<BandRiskList
					onDelete={onDelete}
					data={rangesRisk.data}
					loading={rangesRisk.loading}
				/>
			</Col>
		</Row>
	);
}
