import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useWorkstations } from '@/hooks/v1';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Workstations() {
	const form = useFormInstance();
	const field_name = HierarchiesField.WORKSTATION;
	const sector_id = useWatch(HierarchiesField.SECTOR, form);
	const company_id = useWatch(HierarchiesField.COMPANY, form);
	const organization_id = useWatch(HierarchiesField.ORGANIZATION, form);

	const {
		data: workstations,
		isFetching: isGettingWorkstations,
		isError: errorGettingWorkstations
	} = useWorkstations({
		organization_id,
		company_id,
		sector_id
	});

	function handleSelectWorkstation(id: string): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(field_name, id);
		}
	}

	function handleClearWorkstation(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={field_name} label={I18n.get('Workstation')}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={workstations}
				loading={isGettingWorkstations}
				onClear={handleClearWorkstation}
				onChange={handleSelectWorkstation}
				placeholder={I18n.get('Workstation')}
				disabled={!sector_id || !workstations || errorGettingWorkstations}
			/>
		</Form.Item>
	);
}
