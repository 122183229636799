import styled from 'styled-components'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const Body = styled.div`
  	min-height: 70px;
`

export const AntIcon = styled(LoadingOutlined)`
  	font-size: ${props => props.size}px;
`

export const SpinnerLoading = styled(Spin)`
	transform: translate(-50%, -50%);
  	position: absolute;
	left: 50%;
	top: 50%;
`


