import Api from '@/services/api';
import {
	CompanyDTO,
	CompanyResponse,
	CreateOrganizationRequest,
	CreateRequest,
	DeleteOrganizationRequest,
	DeleteRequest,
	OrganizationDTO,
	OrganizationResponse,
	SectorDTO,
	UpdateOrganizationRequest,
	UpdateRequest,
	UrlOrganizationFilter,
	WorkstationDTO
} from './types';
import moment from 'moment';

async function fetchOrganizations(
	limit: number,
	offset: number,
	parameters: UrlOrganizationFilter,
	organizationId: string
) {
	const parametersFixed = await setFilterParams(parameters);
	const baseUrl = `/organization/getAll/${organizationId}?${parametersFixed}limit=${limit}&offset=${offset}`;
	const { data } = await Api.get<OrganizationDTO[]>(baseUrl);
	return data;
}

async function fetchAllOrganizations(parameters?: UrlOrganizationFilter) {
	const parametersFixed = parameters && (await setFilterParams(parameters));
	const baseUrl = `/organization?${parametersFixed}`;
	const { data } = await Api.get<OrganizationDTO[]>(baseUrl);
	return data;
}

async function fetchCompanies(organizationId: string) {
	const baseUrl = `/company?organization_id=${organizationId}`;
	const { data } = await Api.get<CompanyDTO[]>(baseUrl);
	return data;
}

async function fetchSectors(organizationId: string, companyId: string) {
	const baseUrl = `/sector/${organizationId}/${companyId}`;
	const { data } = await Api.get<SectorDTO[]>(baseUrl);
	return data;
}

async function fetchWorkstations(organizationId: string, companyId: string, sectorId: string) {
	const baseUrl = `/workstation/${organizationId}/${companyId}/${sectorId}`;
	const { data } = await Api.get<WorkstationDTO[]>(baseUrl);
	return data;
}

async function createOrganization(body: CreateOrganizationRequest) {
	const url = '/organization';
	const { data } = await Api.post<OrganizationResponse>(url, body);
	return data;
}

async function updateOrganization(body: UpdateOrganizationRequest) {
	const { organization_id } = body;
	const url = `/organization/${organization_id}`;
	const { data } = await Api.put<OrganizationResponse>(url, body);
	return data;
}

async function deleteOrganization({ organization_id }: DeleteOrganizationRequest) {
	const url = `/organization/${organization_id}`;
	const { data } = await Api.delete<OrganizationResponse>(url);
	return data;
}

async function create(body: CreateRequest) {
	const { url, mappedBody } = await setCreateUrlAndBody(body);
	const { data } = await Api.post<CompanyResponse>(url, mappedBody);
	if (data.data) {
		return data.data;
	}
	return data;
}

async function update(body: UpdateRequest) {
	const url: string = await setUpdateUrl(body);
	const { data } = await Api.put<any>(url, body);
	return data;
}

async function destroy(body: DeleteRequest) {
	const url: string = await setDeleteUrl(body);
	const { data } = await Api.delete<any>(url);
	return data;
}

async function setDeleteUrl(bodyParams: DeleteRequest) {
	const { organization_id, company_id, sector_id, workstation_id } = bodyParams;

	if (workstation_id) {
		return `/workstation/${organization_id}/${company_id}/${sector_id}/${workstation_id}`;
	}

	if (sector_id) {
		return `/sector/${organization_id}/${company_id}/${sector_id}`;
	}

	return `/company/${organization_id}/${company_id}`;
}

async function setUpdateUrl(bodyParams: UpdateRequest) {
	const { organization_id, company_id, sector_id, workstation_id } = bodyParams;

	if (workstation_id) {
		return `/workstation/${organization_id}/${company_id}/${sector_id}/${workstation_id}`;
	}

	if (sector_id) {
		return `/sector/${organization_id}/${company_id}/${sector_id}`;
	}

	return `/company/${company_id}`;
}

async function setCreateUrlAndBody(bodyParams: CreateRequest) {
	const { organization_id, company_id, sector_id } = bodyParams;

	const { company_name, sector_name, workstation_name } = bodyParams;

	if (sector_id && workstation_name) {
		return {
			url: '/workstation/create',
			mappedBody: {
				organization_id,
				sector_id,
				company_id,
				name: workstation_name
			}
		};
	}

	if (company_id && sector_name) {
		return {
			url: '/sector/create',
			mappedBody: {
				organization_id,
				company_id,
				name: sector_name
			}
		};
	}

	return {
		url: '/company/create',
		mappedBody: {
			organization_id,
			name: company_name || ''
		}
	};
}

async function setFilterParams(queryParams: UrlOrganizationFilter) {
	const { organizationName, createdAt, status } = queryParams;

	let filterParams = '';

	if (organizationName) {
		filterParams = `${filterParams}name=${organizationName}&`;
	}

	if (status) {
		filterParams = status === 'active' ? `${filterParams}is_active=1&` : `${filterParams}`;
		filterParams = status === 'inactive' ? `${filterParams}is_active=0&` : `${filterParams}`;
		filterParams = status === 'all' ? `${filterParams}is_active=all&` : `${filterParams}`;
	}

	if (createdAt && createdAt.length > 0) {
		const [start, end] = createdAt;
		const start_date = moment(start).format();
		const end_date = moment(end).format();
		filterParams = `${filterParams}created_start_date=${start_date}&created_end_date=${end_date}&`;
	}

	return filterParams;
}

export const Services = {
	create,
	update,
	destroy,
	deleteOrganization,
	updateOrganization,
	createOrganization,
	fetchWorkstations,
	fetchOrganizations,
	fetchAllOrganizations,
	fetchCompanies,
	fetchSectors
};
