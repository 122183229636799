import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { PieChart } from './Pie';
import { Diagnostics } from '@/types/Reba';
import { Text } from '@/components/Typography';
import { DIAGNOSTIC_COLORS } from '@/utils/constants';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import type { RebaResultsDTO, Member } from '../types';

type PercentageItem = {
	value: string;
	index: number;
};

interface ChartsProps {
	isLoading?: boolean;
	data: RebaResultsDTO;
	isError?: boolean;
	member: Member;
}

export const Charts: React.FC<ChartsProps> = ({ isLoading, data, isError, member }) => {
	const { calculateBodyPartPercentage } = usePreliminaryAnalysis();

	if (isLoading) {
		return <p>{I18n.get('Loading')}...</p>;
	}

	if (isError) {
		return <p style={{ color: 'red' }}>{I18n.get('Failed to fetch information')} :(</p>;
	}

	const scales = Object.values(Diagnostics);
	const percentages = calculateBodyPartPercentage(data[member]);

	const PercentageItem = ({ value, index }: PercentageItem) => (
		<Col key={index} span={24}>
			<span style={{ fontSize: '12px' }}>
				{I18n.get(scales[index])}:{' '}
				<Text strong style={{ color: DIAGNOSTIC_COLORS[index] }}>
					{value}%
				</Text>
			</span>
		</Col>
	);

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Text strong>{I18n.get('Exposure')}:</Text>
			</Col>
			<Col span={24}>
				<Row gutter={[20, 20]}>
					<Col span={8}>
						<PieChart scores={data[member]} />
					</Col>
					<Col span={14}>
						<Row>
							{percentages?.map((value: string, i: number) => (
								<PercentageItem key={i} value={value} index={i} />
							))}
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
