import React from 'react';
import _ from 'lodash';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import { ReactComponent as NotFoundSvg } from '@/assets/svg/not_found.svg';
import { OrganizationUser } from '../../../hooks/useGetOrganizationUsers';
import { Column, EditButton, UserContainer } from './styles';
import { Loading } from '@/components/Spinner/styles';
import { Text } from '@/components/Typography';
import { Header } from './Header';
import * as S from './styles';

interface ContentProps {
	isLoading?: boolean;
	data?: OrganizationUser[];
	isError?: boolean;
}

export function Content({ isLoading = false, data, isError = false }: Readonly<ContentProps>) {
	const history = useHistory();

	if (isLoading) {
		return (
			<Row style={{ minHeight: '4rem' }}>
				<Col span={24}>
					<Loading />
				</Col>
			</Row>
		);
	}

	if (isError) {
		return (
			<div style={{ textAlign: 'center' }}>
				<Text type="danger">{I18n.get('Failed to fetch information')}</Text>
			</div>
		);
	}

	if (_.isArray(data) && _.isEmpty(data)) {
		return (
			<>
				<S.ColNotFound lg={24}>
					<S.IconNotFound component={NotFoundSvg} />
				</S.ColNotFound>
				<S.ColNotFound lg={24}>
					<p style={{ color: '#c8c8c8', marginTop: '-5px' }}>Não há dados</p>
				</S.ColNotFound>
			</>
		);
	}

	return (
		<Row style={{ minHeight: '4rem' }}>
			<Col span={24}>
				<Header />
			</Col>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					{data?.map((user) => (
						<UserContainer span={24}>
							<Row align="middle" style={{ padding: '0 1rem' }}>
								<Col span={15}>
									<Text>{user.name}</Text>
								</Col>
								<Col span={1}>
									<Row>
										<Col>
											<Divider
												type="vertical"
												style={{ borderLeft: '1px solid #00000033', height: '100%' }}
											/>
										</Col>
									</Row>
								</Col>
								<Column span={6}>
									<Text>{user.role}</Text>
								</Column>
								<Col span={1}>
									<Row>
										<Col>
											<Divider
												type="vertical"
												style={{ borderLeft: '1px solid #00000033', height: '100%' }}
											/>
										</Col>
									</Row>
								</Col>
								<Column span={1}>
									<EditButton
										ghost
										type="primary"
										onClick={() => history.push(`/users?name=${user.name}`)}
										icon={<EditOutlined style={{ fontSize: '20px' }} />}
									/>
								</Column>
							</Row>
						</UserContainer>
					))}
				</Row>
			</Col>
		</Row>
	);
}
