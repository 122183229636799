import styled from 'styled-components';

interface IDescriptionProps {
	isactive: boolean;
}

const ACTIVE_COLOR = '#2CC852';
const INATIVE_COLOR = '#E74150';

export const Description = styled.span<IDescriptionProps>`
	width: 100%;
	font-size: small;
	text-align: center;
	display: inline-block;
	color: ${({ isactive }) => (isactive ? ACTIVE_COLOR : INATIVE_COLOR)};
`;
