import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getFile({ organizationId, companyId, fileId }) {
	if (!organizationId || !companyId || !fileId) return [];
	const url = `/upload/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

/*  Deprecated - please use useGetFile.ts */
export function useFile(payload) {
	const enabled = !!payload.organizationId && !!payload.companyId && !!payload.fileId;
	const file = useQuery(['file', payload], () => getFile(payload), { enabled });
	return { file };
}
