import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import store from '../store';
import { Services } from '@/hooks/useAuth/services';
import { handleLogout } from '../redux/auth/actions';

function setLocalStorage(key: string, payload: any): void {
	localStorage.setItem(key, JSON.stringify(payload));
}

function getLocalStorage(key: string): any {
	return JSON.parse(localStorage.getItem(key) || '');
}

async function setRefreshToken(error: Error | AxiosError): Promise<string> {
	try {
		const session = getLocalStorage('info_user');
		const { user, token, refresh_token } = await Services.refreshToken({ refresh_token: session.refresh_token });
		setLocalStorage('info_user', { user, token, refresh_token });
		return token;
	} catch (err) {
		store.dispatch(handleLogout());
		throw error;
	}
}

function checkingPassword(value: string) {
	if (!value) {
		return Promise.reject(I18n.get('Enter a new password'));
	}

	if (value.length < 8) {
		return Promise.reject(I18n.get('Password must be at least 8 characters long'));
	}

	if (value.length >= 20) {
		return Promise.reject(I18n.get('The password must have a maximum of 20 characters'));
	}

	const specialCharacters: string = '@#$%&*-+=" ́`^~{}[]/|\\?!';

	// Check uppercase letters
	if (!/[A-Z]/.test(value)) {
		return Promise.reject(I18n.get('The password must contain capital letters'));
	}

	// Check lower case
	if (!/[a-z]/.test(value)) {
		return Promise.reject(I18n.get('The password must contain lowercase letters'));
	}

	// Check numbers
	if (!/[0-9]/.test(value)) {
		return Promise.reject(I18n.get('Password must contain numbers'));
	}

	// Check for special symbols
	if (![...specialCharacters].some((char) => value.includes(char))) {
		return Promise.reject(I18n.get('The password must contain at least one special character'));
	}

	return Promise.resolve();
}

export const Utils = { setLocalStorage, getLocalStorage, setRefreshToken, checkingPassword };

export const checkIfValidUUID = (str: string) => {
	const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
	return regexExp.test(str);
};

export const isEmpty = (obj: Object) => {
	for (const prop in obj) {
		if (Object.hasOwn(obj, prop)) {
			return false;
		}
	}
	return JSON.stringify(obj) === JSON.stringify({});
};

export const isNumeric = (value: string) => {
	if (typeof value != 'string') return false;
	return !isNaN(Number(value)) && !isNaN(parseFloat(value));
};
