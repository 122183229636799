import React from 'react';
import * as Icons from '@ant-design/icons';
import { Row, Col, Card, Space } from 'antd';

export function ApplyToolButton({ type, onClick, children, styles }) {
	const style = { fontSize: 30 };

	const icons = {
		line: <Icons.LineChartOutlined style={style} />,
		toTop: <Icons.ToTopOutlined style={style} />
	};

	return (
		<Card hoverable onClick={onClick} style={styles}>
			<Row align="middle" gutter={[18, 0]}>
				<Col>{icons[type] || icons.line}</Col>
				<Col>
					<Space direction="vertical">{children}</Space>
				</Col>
			</Row>
		</Card>
	);
}
