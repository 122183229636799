import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { AxiosErrorResponse, BaseReportsPaginated, BaseReportsPaginatedResponse, PaginatedData } from '@/types';

export type ListAllOwasDTO = BaseReportsPaginated;
export type ListAllOwasResponse = PaginatedData<BaseReportsPaginatedResponse>;

async function listOwasPaginated(params: ListAllOwasDTO): Promise<ListAllOwasResponse> {
	const { data } = await Api.get('/ergonomic-tool/owas', { params });
	return data;
}

export const useListOwasPaginated = ({
	limit,
	offset,
	sector_id,
	company_id,
	cellule_id,
	report_name,
	evaluator_id,
	created_at_end,
	workstation_id,
	organization_id,
	created_at_start,
	isSelectedReport,
	activity_id
}: ListAllOwasDTO) => {
	const enabled = !!limit && !!isSelectedReport && offset >= 0;
	const owasReport = useQuery<ListAllOwasResponse, AxiosErrorResponse>(
		[
			QUERY_KEYS.GET_OWAS_REPORTS_PAGINATED,
			[
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				evaluator_id,
				created_at_end,
				workstation_id,
				organization_id,
				created_at_start,
				activity_id
			]
		],
		() =>
			listOwasPaginated({
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				evaluator_id,
				created_at_end,
				workstation_id,
				organization_id,
				created_at_start,
				activity_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return owasReport;
};
