import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Description } from './styles';

export const Header: React.FC = () => (
	<Row justify="end">
		<Col span={6}>
			<Description>
				<span>{I18n.get('Left')}</span>
			</Description>
		</Col>
		<Col span={6}>
			<Description>
				<span>{I18n.get('Right')}</span>
			</Description>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
	</Row>
);
