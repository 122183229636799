import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { NameInput } from '../styles';

const { useFormInstance } = Form;

export function CreateSector() {
	const [sectorName, setSectorName] = useState('');

	const { resetFields, validateFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		creatingOrganizationData,
		isEditing,
		createOrganizationData,
		handleSector,
		treatFieldValidation
	} = useManageOrganization();

	async function handleCreateSector() {
		try {
			await validateFields(['sector_name']);
			const created = await createOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				sector_name: sectorName
			});

			setSectorName('');
			handleSector(created.id, resetFields, setFieldValue);
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	function handleSectorNameChange(name: string) {
		setSectorName(name);
	}

	return (
		<Col span={24}>
			<Row align="bottom" gutter={[12, 0]}>
				<Col span={20}>
					<NameInput
						disabled={isEditing}
						name="sector_name"
						label={I18n.get('Create sector')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid name')
							}
						]}
					>
						<Input
							disabled={isEditing}
							placeholder={I18n.get('Sector name')}
							onChange={(e) => handleSectorNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={2}>
					<Button
						disabled={isEditing}
						loading={creatingOrganizationData}
						type="primary"
						htmlType="submit"
						ghost={true}
						onClick={() => handleCreateSector()}
					>
						{I18n.get('Create')}
					</Button>
				</Col>
			</Row>
		</Col>
	);
}
