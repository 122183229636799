import { I18n } from '@aws-amplify/core';
import { Row, Switch } from 'antd';
import React from 'react';

import { DeactivateTokenModal } from './DeactivateTokenModal';
import { Field, Information } from '../../styles';
import { useTFAContext } from './context';

export const TwoFactorAuthentication: React.FC = () => {
	const { handleOpenGenerateTokenModal, handleOpenDeactivateTokenModal } = useTFAContext();
	const { checked2FA, user2FA, fecthingUser2FA, openGenerateTokenModal, openDeactivateTokenModal } = useTFAContext();

	function handleChange() {
		if (!user2FA.validated) {
			handleOpenGenerateTokenModal(!openGenerateTokenModal);
		} else {
			handleOpenDeactivateTokenModal(!openDeactivateTokenModal);
		}
	}

	return (
		<>
			<Field>{I18n.get('Two-factor authentication')}</Field>
			<Information>
				{I18n.get('When activated, you will have to type a validation code every time you login.')}
			</Information>
			<Row style={{ marginTop: '25px' }}>
				<Switch
					loading={fecthingUser2FA}
					defaultChecked={!!user2FA.validated}
					checked={!!user2FA.validated || checked2FA}
					onChange={handleChange}
				/>
				<Information style={{ marginLeft: '13px' }}>
					{user2FA.validated || checked2FA ? I18n.get('Deactivate') : I18n.get('Activate')}
				</Information>
			</Row>

			{openDeactivateTokenModal && <DeactivateTokenModal />}
		</>
	);
};
