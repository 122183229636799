import React from 'react';
import { Col, Row, Form, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Text } from '@/components/Typography';

import { ControlPeriod, MaxMinutesUsed, MaxVideosNumber, RenewalDay, RenewalMonth } from '../../Modals/components';
import { SubTitle, FlexCol, SwitchFormItem } from '../styles';

const { useWatch } = Form;

export function ProcessingControl() {
	const company_processing_control = useWatch('company_processing_control');
	const company_maximum_time = useWatch('company_maximum_time');
	const company_maximum_videos = useWatch('company_maximum_videos');
	const recurrence = useWatch('company_recurrence');

	function hasSomeSwitchChecked() {
		return company_maximum_time || company_maximum_videos;
	}

	function switchValidator() {
		if (!company_maximum_time && !company_maximum_videos) {
			return Promise.reject(I18n.get('Select a value'));
		}

		return Promise.resolve();
	}

	return (
		<Row gutter={[0, 15]}>
			<Col span={24}>
				<SubTitle>{I18n.get('Processing control')}</SubTitle>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Row align="middle" gutter={[10, 0]}>
							<Col>
								<SwitchFormItem name="company_processing_control" valuePropName="checked">
									<Switch />
								</SwitchFormItem>
							</Col>
							<Col>
								<Text style={{ fontSize: 15 }}>Use company minutes and/or number of videos</Text>
							</Col>
						</Row>
					</Col>
					{!company_processing_control && (
						<>
							<Col span={24} style={{ marginLeft: '10%' }}>
								<Row gutter={[0, 5]}>
									<FlexCol span={24}>
										<Row align="middle" gutter={[10, 0]}>
											<Col>
												<SwitchFormItem
													valuePropName="checked"
													name="company_maximum_time"
													rules={[{ validator: switchValidator }]}
												>
													<Switch size="small" />
												</SwitchFormItem>
											</Col>
											<Col>
												<Text style={{ fontSize: 13 }}>
													{I18n.get('Set the maximum processing time')}
												</Text>
											</Col>
										</Row>
									</FlexCol>
									<FlexCol span={24}>
										<Row align="middle" gutter={[10, 0]}>
											<Col>
												<SwitchFormItem
													valuePropName="checked"
													name="company_maximum_videos"
													rules={[{ validator: switchValidator }]}
												>
													<Switch size="small" />
												</SwitchFormItem>
											</Col>
											<Col>
												<Text style={{ fontSize: 13 }}>
													{I18n.get('Set a maximum number of videos to process')}
												</Text>
											</Col>
										</Row>
									</FlexCol>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[0, 15]}>
									{hasSomeSwitchChecked() && (
										<>
											<Col span={recurrence === 'yearly' ? 24 : 11}>
												<ControlPeriod name="company_recurrence" required />
											</Col>
											{recurrence === 'yearly' ? (
												<>
													<Col span={12}>
														<RenewalDay name="company_renewal_day" required />
													</Col>
													<Col span={12}>
														<RenewalMonth name="company_renewal_month" required />
													</Col>
												</>
											) : (
												<Col span={recurrence === 'yearly' ? 6 : 12}>
													<RenewalDay name="company_renewal_day" required />
												</Col>
											)}
										</>
									)}
									{company_maximum_time && (
										<Col span={24}>
											<MaxMinutesUsed name="company_max_minutes" required />
										</Col>
									)}
									{company_maximum_videos && (
										<Col span={24}>
											<MaxVideosNumber name="company_max_uploads" required />
										</Col>
									)}
								</Row>
							</Col>
						</>
					)}
				</Row>
			</Col>
		</Row>
	);
}
