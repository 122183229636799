import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Select, Skeleton } from 'antd';
import { CustomFormItem } from '../../styles';
import { useCustomReportManagerContext } from '../../context';

type OptionType = {
	label: string;
	value: string;
};

export function ScoreScaleSelect() {
	const { scoreScales } = useCustomReportManagerContext();

	const {
		data: customReportScoreScales,
		isFetching: fetchingScoreScales,
		isError: errorGettingScoreScales
	} = scoreScales;

	const scoreScaleOptions = customReportScoreScales?.map(({ id, description }) => ({
		value: id,
		label: I18n.get(description)
	}));

	function filterOption(option: OptionType | undefined, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Col span={24}>
			<CustomFormItem
				name={['custom_report_score_scale_id']}
				label={I18n.get('Risk scale')}
				labelCol={{ span: 24 }}
				rules={[
					{
						message: I18n.get('Insert a risk scale'),
						required: true
					}
				]}
			>
				{fetchingScoreScales ? (
					<Skeleton.Input active block size="small" />
				) : (
					<Select
						allowClear
						showSearch
						size="large"
						options={scoreScaleOptions}
						disabled={errorGettingScoreScales}
						placeholder={I18n.get('Risk scale')}
						filterOption={(input, option) => filterOption(option, input)}
					/>
				)}
			</CustomFormItem>
		</Col>
	);
}
