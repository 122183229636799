import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Moment } from 'moment';

import Api from '@/services/api';
import { Response } from '@/types';
import { customReportService } from './services';

export enum CreateREBAStatus {
	SUCCESS = 'success',
	FAILED = 'failed'
}

export type CreateRebaResponse = {
	status: CreateREBAStatus;
	message: string;
};

export type CreateREBADTO = {
	force: number;
	comment: string;
	coupling: number;
	sector_id: string;
	repetition: number;
	company_id: string;
	workstation_id: string;
	organization_id: string;
	collection_date: Moment;
	file_id: string | undefined;
};

async function createReport<T, Body>(parameters: Body): Promise<T> {
	const { data } = await Api.post('/ergonomic-tool/reba', parameters);
	return data;
}

export const useCreateREBA = () => {
	const queryClient = useQueryClient();
	return useMutation<CreateRebaResponse, AxiosError<Response>, CreateREBADTO>(
		(body) => createReport<CreateRebaResponse, CreateREBADTO>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to create information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
};
