import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'antd';

import { Summary } from '../Summary';
import { CustomReport } from '../CustomReport';
import { CustomReportStep } from '../CustomReportStep';
import { useGetCustomReportScoreScale } from '../hooks';
import { StepList } from '../CustomReportStep/context/types';
import { CustomReportStepKey } from '../CustomReportStepKey';
import { StepKeyList } from '../CustomReportStepKey/context/types';
import { useApplicationContext } from '@/context/Application';
import { States, Context, Methods, CustomReportManagerRouteParams, Step } from './types';

type CustomReportManagerProviderProps = {
	children: ReactNode;
};

const { useForm } = Form;
const CustomReportManagerContext = createContext<Context>({} as Context);

export const CustomReportManagerProvider: React.FC<Readonly<CustomReportManagerProviderProps>> = ({ children }) => {
	const [form] = useForm();
	const history = useHistory();
	const { organization, company } = useApplicationContext();
	const { report_id } = useParams<CustomReportManagerRouteParams>();

	const scoreScales = useGetCustomReportScoreScale({
		company_id: company?.id,
		organization_id: organization?.id
	});

	const [step, setStep] = useState<number>(0);
	const [selectedSteps, setSelectedSteps] = useState<StepList[]>([]);
	const [firstIncompleteStepId, setFirstIncompleteStepId] = useState<string>('');
	const [selectedStepId, setSelectedStepId] = useState<string>(selectedSteps[0]?.id);
	const [selectedStepKeys, setSelectedStepKeys] = useState<StepKeyList | undefined>(undefined);
	const hasSelectedStepKeys = selectedStepKeys?.[selectedStepId];
	const [selectedStepKeyId, setSelectedStepKeyId] = useState<string>(
		hasSelectedStepKeys ? selectedStepKeys[selectedStepId][0]?.id : ''
	);

	const steps: Step[] = [
		{
			name: 'custom_report',
			component: <CustomReport />
		},
		{
			name: 'custom_report_step',
			component: <CustomReportStep />
		},
		{
			name: 'custom_report_step_key',
			component: <CustomReportStepKey />
		},
		{
			name: 'custom_report_summary',
			component: <Summary />
		}
	];

	async function handleNextStep(): Promise<void> {
		form.resetFields(['step_name_filter']);
		form.resetFields(['step_key_name_filter']);
		await form.validateFields();
		setStep(step + 1);
	}

	function handlePreviousStep(): void {
		if (step === 0) {
			history.push('/settings/personalised-reports');
			return;
		}
		form.resetFields(['step_name_filter']);
		form.resetFields(['step_key_name_filter']);
		setStep(step - 1);
	}

	function handleShowSummaryClick(): void {
		const stepIdList = selectedSteps.map(({ id }) => id);
		const allStepsHaveStepKeys = checkIfAllStepsHaveStepKeys(stepIdList);
		if (allStepsHaveStepKeys.length === 0) {
			setStep(step + 1);
			return;
		}
		handleFirstIncompleteStepId(allStepsHaveStepKeys[0]);
	}

	function checkIfAllStepsHaveStepKeys(stepIdList: string[]): string[] {
		const incompleteStepsIds = stepIdList.filter((stepId) => {
			return !selectedStepKeys?.[stepId] || selectedStepKeys?.[stepId]?.length === 0;
		});

		return incompleteStepsIds;
	}

	function handleFirstIncompleteStepId(id: string): void {
		setFirstIncompleteStepId(id);
	}

	function handleSelectedSteps(stepList: StepList[]): void {
		setSelectedSteps(stepList);
		setSelectedStepId(stepList[0]?.id);
	}

	function handleStepsSequence(): void {
		const stepsCopy = [...selectedSteps];
		const mappedSteps: StepList[] = stepsCopy.map((step, index) => {
			let newSequence = index + 1;
			const workCondition = form.getFieldValue('has_work_conditions');
			const characteristics = form.getFieldValue('has_characteristics');
			if (workCondition) {
				newSequence += 1;
			}
			if (characteristics) {
				newSequence += 1;
			}
			return {
				...step,
				sequence: newSequence
			};
		});
		setSelectedSteps(mappedSteps);
	}

	function handleSelectedStepKeys(stepKeyList: StepKeyList): void {
		setSelectedStepKeys(stepKeyList);
		setSelectedStepKeyId(hasSelectedStepKeys ? stepKeyList[selectedStepId][0]?.id : '');
	}

	function handleSelectStep(id: string): void {
		setSelectedStepId(id);
	}

	const states: States = {
		step,
		steps,
		scoreScales,
		selectedSteps,
		selectedStepId,
		selectedStepKeys,
		selectedStepKeyId,
		reportId: report_id,
		firstIncompleteStepId
	};

	const methods: Methods = {
		handleNextStep,
		handleSelectStep,
		handlePreviousStep,
		handleStepsSequence,
		handleSelectedSteps,
		handleShowSummaryClick,
		handleSelectedStepKeys,
		handleFirstIncompleteStepId
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<CustomReportManagerContext.Provider value={context}>
			<Form
				form={form}
				initialValues={{
					has_file: true,
					has_work_conditions: true,
					has_characteristics: true
				}}
			>
				{children}
			</Form>
		</CustomReportManagerContext.Provider>
	);
};

export function useCustomReportManagerContext() {
	const context = useContext(CustomReportManagerContext);
	return context;
}
