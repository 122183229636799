import { I18n } from '@aws-amplify/core';
import { Col, Form, Row, Skeleton } from 'antd';
import React from 'react';


export function LoadingSkeleton() {
	return (
		<Row gutter={[16, 16]}>
			<Col xs={24}>
				<Form.Item labelCol={{ span: 24 }} label={I18n.get('Company')}>
					<Skeleton.Input block size="default" active />
				</Form.Item>
			</Col>
			<Col xs={24}>
				<Form.Item labelCol={{ span: 24 }} label={I18n.get('Industrial site')}>
					<Skeleton.Input block size="default" active />
				</Form.Item>
			</Col>
			<Col xs={24}>
				<Form.Item labelCol={{ span: 24 }} label={I18n.get('Sector')}>
					<Skeleton.Input block size="default" active />
				</Form.Item>
			</Col>
			<Col xs={24}>
				<Form.Item labelCol={{ span: 24 }} label={I18n.get('Cellule')}>
					<Skeleton.Input block size="default" active />
				</Form.Item>
			</Col>
			<Col xs={24}>
				<Form.Item labelCol={{ span: 24 }} label={I18n.get('Workstation')}>
					<Skeleton.Input block size="default" active />
				</Form.Item>
			</Col>
		</Row>
	);
}
