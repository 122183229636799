import styled from 'styled-components';

export const Title = styled.span`
	font-weight: 600;
	font-size: 1rem;
`;

export const SubTitle = styled.span`
	font-weight: 400;
	font-size: 0.76rem;
`;

export const WorkstationScoreTitle = styled.span`
	width: fit-content;
	margin: 0 2rem 0 auto;
	font-weight: 600;
	font-size: 0.66rem;
`;

