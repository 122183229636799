import React from 'react';
import { Pie } from 'react-chartjs-2';
import { DIAGNOSTIC_COLORS } from '@/utils/constants';

type PieChartProps = {
	scores: number[];
};

export const PieChart: React.FC<PieChartProps> = ({ scores }) => {
	const data = {
		datasets: [
			{
				backgroundColor: DIAGNOSTIC_COLORS,
				borderColor: DIAGNOSTIC_COLORS,
				borderWidth: 1,
				data: scores,
				datalabels: {
					display: false
				}
			}
		]
	};
	return <Pie data={data} />;
};
