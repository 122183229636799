import { useQuery } from '@tanstack/react-query';

import { customReportService } from './services';
import { GetCustomReportRequest } from './types/request';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export const useGetCustomReport = ({ organization_id, company_id, report_id }: GetCustomReportRequest) => {
	const enabled = !!organization_id && !!company_id && !!report_id;
	const customReport = useQuery(
		['custom_report', [organization_id, company_id, report_id]],
		() => customReportService.fetchCustomReport({ organization_id, company_id, report_id }),
		{
			enabled,
			retry: 0
		}
	);
	return customReport;
};

export const useGetCustomReportScoreScale = ({ organization_id, company_id }: BaseContext) => {
	const enabled = !!organization_id && !!company_id;
	const customReportScoreScale = useQuery(
		['custom_report_score_scale', [organization_id, company_id]],
		() => customReportService.fetchCustomReportScoreScale({ organization_id, company_id }),
		{
			enabled,
			retry: 0
		}
	);
	return customReportScoreScale;
};
