import React from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Col, Form, Input, Button } from 'antd';
import { FormInstance } from 'antd/es/form/Form';

interface LoginFormProps {
	formInstance: FormInstance;
	onFinish: () => void;
	isLoading: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({ formInstance, onFinish, isLoading = false }) => (
	<Col span={22}>
		<Form form={formInstance} layout="vertical" onFinish={onFinish}>
			<Form.Item
				name="email"
				hasFeedback
				rules={[
					{
						type: 'email',
						message: I18n.get('Enter a valid email')
					},
					{
						required: true,
						message: I18n.get('Enter your email')
					}
				]}
			>
				<Input placeholder={I18n.get('Work email')} prefix={<Icons.UserOutlined />} />
			</Form.Item>
			<Form.Item>
				<Button size="large" type="primary" htmlType="submit" loading={isLoading} style={{ width: '100%' }}>
					{I18n.get('Login')}
				</Button>
			</Form.Item>
		</Form>
	</Col>
);
