import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Header } from './Header';
import { DetailDescription, Details, DetailTitle } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

export const Detailed: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;
	const { liberty_mutual } = basic_information;

	function renderResult(): JSX.Element | undefined {
		return (
			<Row justify="space-between">
				<Col xs={23} style={{ paddingLeft: '1rem' }}>
					<Details>
						<Row>
							<Col xs={17}>
								<DetailTitle>{I18n.get('Activity')}:</DetailTitle>
							</Col>
							<Col>
								<DetailDescription>{I18n.get(liberty_mutual?.task_name)}</DetailDescription>
							</Col>
						</Row>
					</Details>
				</Col>
				{liberty_mutual?.object_weight && (
					<>
						<Divider style={{ margin: 0 }} type="horizontal" />
						<Col xs={23} style={{ paddingLeft: '1rem' }}>
							<Details>
								<Row>
									<Col xs={17}>
										<DetailTitle>{I18n.get('Weight')}:</DetailTitle>
									</Col>
									<Col>
										<DetailDescription>{I18n.get(liberty_mutual.object_weight)}</DetailDescription>
									</Col>
								</Row>
							</Details>
						</Col>
					</>
				)}
				<Divider style={{ margin: 0 }} type="horizontal" />
				<Col xs={23} style={{ paddingLeft: '1rem' }}>
					<Details>
						<Row>
							<Col xs={17}>
								<DetailTitle>
									{I18n.get('Frequency')}{' '}
									{`(${I18n.get(liberty_mutual?.frequency.type)} ${I18n.get('per')} ${I18n.get(
										liberty_mutual?.frequency.interval
									)})`}
									:
								</DetailTitle>
							</Col>
							<Col>
								<DetailTitle>{liberty_mutual?.frequency.value}</DetailTitle>
							</Col>
						</Row>
					</Details>
				</Col>
				{liberty_mutual?.hand_coupling && (
					<>
						<Divider style={{ margin: 0 }} type="horizontal" />
						<Col xs={23} style={{ paddingLeft: '1rem' }}>
							<Details>
								<Row>
									<Col xs={17}>
										<DetailTitle>{I18n.get('Hand coupling')}:</DetailTitle>
									</Col>
									<Col>
										<DetailTitle>{I18n.get(liberty_mutual.hand_coupling)}</DetailTitle>
									</Col>
								</Row>
							</Details>
						</Col>
					</>
				)}
			</Row>
		);
	}

	return (
		<Row>
			<Col xs={24}>
				<Header />
			</Col>
			<Divider style={{ margin: 0 }} />
			<Col xs={24}>{renderResult()}</Col>
		</Row>
	);
};
