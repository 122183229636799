import Types from './types';

const initState = {
	file: null,
	customized: {
		informations: {
			company: null,
			sector: null,
			workstation: null,
			collection_date: null,
			range_risk: null,
			is_valid: false
		},
		body_parts: {
			trunk: true,
			neck: true,
			left_lower_arm: true,
			right_lower_arm: true,
			left_upper_arm: true,
			right_upper_arm: true,
			left_knee: true,
			right_knee: true,
			left_ankle: true,
			right_ankle: true,
			left_hand: false,
			right_hand: false,
			hip: true,
			is_valid: true
		},
		parameters: {
			repetition: 1,
			force: 0,
			legs: 2,
			is_valid: true
		},
		range_risk_bands: null,
		current: 0
	},
	options: {
		signature: {
			checked: true,
			author: null
		},
		conclusion: {
			checked: true,
			text: null
		}
	},
	data: null,
	recovery_data: null,
	risk_de_gree: null
};

const reportsReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.NEXT_STEP: {
			return {
				...state,
				[action.payload]: {
					...state[action.payload],
					current: state[action.payload].current + 1
				}
			};
		}

		case Types.PREV_STEP: {
			return {
				...state,
				[action.payload]: {
					...state[action.payload],
					current: state[action.payload].current - 1
				}
			};
		}

		case Types.VALIDATION_STEP: {
			return {
				...state,
				customized: {
					...state.customized,
					[action.payload.step]: {
						...state.customized[action.payload.step],
						is_valid: action.payload.situation
					}
				}
			};
		}

		case Types.UPDATE_INFORMATIONS_CUSTOMIZED: {
			return {
				...state,
				customized: {
					...state.customized,
					informations: {
						...state.customized.informations,
						...action.payload
					}
				}
			};
		}

		case Types.UPDATE_BODY_PART_CUSTOMIZED: {
			return {
				...state,
				customized: {
					...state.customized,
					body_parts: {
						...state.customized.body_parts,
						...action.payload
					}
				}
			};
		}

		case Types.SET_FILE: {
			return {
				...state,
				file: action.payload
			};
		}

		case Types.CLEAR_REPORTS: {
			return initState;
		}

		case Types.UPDATE_PARAMETERS_CUSTOMIZED: {
			return {
				...state,
				customized: {
					...state.customized,
					parameters: {
						...state.customized.parameters,
						...action.payload
					}
				}
			};
		}

		case Types.SET_OPTIONS: {
			return {
				...state,
				options: {
					...state.options,
					...action.payload
				}
			};
		}

		case Types.SET_RISK_DEGREE: {
			return { ...state, risk_de_gree: action.payload };
		}

		case Types.SET_DATA: {
			return {
				...state,
				data: action.payload
			};
		}

		case Types.SET_RANGE_RISK_BANDS: {
			return {
				...state,
				customized: {
					...state.customized,
					range_risk_bands: action.payload
				}
			};
		}

		case Types.SET_RECOVERY_REPORT: {
			return {
				...state,
				recovery_data: action.payload,
				customized: {
					...state.customized,
					informations: {
						...state.customized.informations,
						...(action.payload.collection_date && { collection_date: action.payload.collection_date }),
						...(action.payload.workstation && { workstation: action.payload.workstation }),
						...(action.payload.sector && { sector: JSON.stringify(action.payload.sector) })
					}
				}
			};
		}

		default:
			return state;
	}
};

export default reportsReducer;
