import React from 'react';

interface NeedleSVGProps {
	width?: number;
	height?: number;
	margin?: string;
}

export const NeedleSVG: React.FC<NeedleSVGProps> = ({ height, margin = 0, width = '100%' }) => (
	<span style={{ display: 'flex', margin: margin }}>
		<svg width={width} height={height} viewBox="0 0 52 104" fill="none" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="26.4434" cy="78.4429" rx="25.5" ry="25.5" fill="#999999" />
			<path
				d="M24.9785 11.2834C25.3906 9.11451 28.4961 9.11452 28.9082 11.2834L37.7507 57.8195C37.9849 59.0517 37.0401 60.1929 35.7859 60.1929H18.1008C16.8466 60.1929 15.9019 59.0517 16.136 57.8195L24.9785 11.2834Z"
				fill="#999999"
			/>
		</svg>
	</span>
);
