import styled from 'styled-components';
import { Col, Steps } from 'antd';

interface StepsProps {
	color: string;
}

export const Container = styled(Col)`
	padding: 3rem 0;
`;

export const CustomSteps = styled(Steps)<StepsProps>`
	align-items: center;
	text-align: center;
	.ant-steps-small {
		text-align: center;
	}

	.ant-steps-icon {
		.step-hover {
			:hover {
				.step-checked-hovered {
					width: fit-content;
					height: fit-content;
					display: block;
					font-size: 1rem;
					cursor: pointer;
					background: none;
					margin-top: 25px;
					padding: 3px 10px;
					border-radius: 20px;
					border: solid 1px #2f54eb;
					background-color: #f0f2f5;
				}

				.step-number-hovered {
					width: fit-content;
					height: fit-content;
					display: block;
					font-size: 1rem;
					cursor: pointer;
					background: none;
					margin-top: 25px;
					padding: 3px 10px;
					border-radius: 20px;
					border: solid 1px #b4b5b8;
					background-color: #f0f2f5;
				}

				.disabled-step {
					cursor: not-allowed;
				}

				.consolidated {
					color: #b4b5b8;
					cursor: not-allowed;
				}

				.step-checked {
					display: none;
					transition: 0.5s ease;
				}

				.step-number {
					display: none;
					transition: 0.5s ease;
				}
			}

			.step-checked-hovered {
				display: none;
			}

			.step-number-hovered {
				display: none;
			}

			.step-checked {
				width: 32px;
				height: 32px;
				display: block;
				color: #2f54eb;
				font-size: 1rem;
				cursor: pointer;
				padding-top: 3px;
				margin-top: 25px;
				border-radius: 20px;
				border: solid 1px #2f54eb;
			}

			.step-number {
				width: 32px;
				height: 32px;
				display: block;
				color: #b4b5b8;
				font-size: 1rem;
				cursor: pointer;
				padding-top: 3px;
				margin-top: 25px;
				border-radius: 20px;
				border: solid 1px #b4b5b8;
			}
		}

		.step-name {
			color: #fff;
			height: fit-content;
			width: fit-content;
			display: block;
			font-size: 1rem;
			margin-top: 25px;
			padding: 3px 10px;
			border-radius: 20px;
			background: #2f54eb;
			@media (min-width: 2040px) {
				margin-left: 0px;
			}
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-tail::after {
				background-color: #2f54eb;
				width: 1px;
			}
		}
	}

	.ant-steps-item-container {
		.ant-steps-item-tail {
			margin-top: 29px;
			filter: contrast(0.5);
			width: 101% !important;
			padding: 35px 0 10px 0 !important;
			left: 0 !important;
		}
	}

	.ant-steps-item {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				margin: 0;
			}
		}
		.ant-steps-item-content {
			display: none;
			.ant-steps-item-title {
				display: none;
			}
		}
	}
`;
