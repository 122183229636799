import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, message, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import _ from 'lodash';

import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { Title } from '@/components/Typography';
import { ResultsContext } from '../context';
import { Response } from '@/types';
import { Hooks } from '../hooks';

const { TextArea } = Input;

export const Comment: React.FC = () => {
	const [text, setText] = useState<string | null>(null);
	const { consolidateClick, company_id, organization_id, file } = usePreliminaryAnalysis();
	const { changeTransaction, consolidated } = useContext(ResultsContext);
	const [messageApi, contextHolder] = message.useMessage();

	const comments = Hooks.useComments();

	const setComment = _.debounce((value) => {
		changeTransaction(true);
		setText(value);
	}, 1200);

	const onChange = (e: any) => setComment(e.target.value);

	const onSuccess = ({ status, message }: Response) => {
		messageApi.open({
			type: status,
			content: I18n.get(message),
			duration: 2.5
		});
	};

	const onError = () => {
		message.error(I18n.get('Fail to save the comments'));
	};

	const onSettled = () => changeTransaction(false);

	useEffect(() => {
		if (text !== null) {
			const parameters = {
				organizationId: organization_id,
				companyId: company_id,
				fileId: file?.id,
				id: file?.preliminary_analysis?.id,
				data: {
					comments: text
				}
			};

			comments.mutate(parameters, {
				onSuccess,
				onError,
				onSettled
			});
		}
	}, [text]);

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Col span={8}>
						<Title style={{ margin: 0 }} level={3}>
							Comment
						</Title>
						{contextHolder}
					</Col>
					<Col span={16}>
						<Form.Item name="comments" labelCol={{ span: 24 }}>
							<TextArea
								rows={4}
								maxLength={500}
								showCount
								onChange={onChange}
								disabled={comments.isLoading || consolidated || consolidateClick}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
