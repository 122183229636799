import { I18n } from '@aws-amplify/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';

import { Services } from './services';
import { ICreateWorkOnFile, ICreateWorkstation, WorkstationDTO } from './types';

export function useWorkstation() {
	const useCreateWorkstationOnFile = () => {
		const queryClient = useQueryClient();
		return useMutation((body: ICreateWorkOnFile) => Services.createWorkstationOnFile(body), {
			onError: (err: any) => {
				const typeError = err.response.data.details[0].split('" ');
				notification.error({
					message: `${I18n.get('Ops... something happened!')}`,
					description: `${I18n.get(typeError[1])}`
				});
			},
			onSuccess: () => {
				Promise.all([
					queryClient.invalidateQueries(['sectors']),
					queryClient.invalidateQueries(['workstations_list']),
					queryClient.invalidateQueries(['workstations'])
				]);
				message.success(I18n.get('Workstation created successfully'));
			}
		});
	};

	const useCreateWorkstation = () => {
		const queryClient = useQueryClient();
		return useMutation((body: ICreateWorkstation) => Services.create(body), {
			onError: (err: any) => {
				const typeError = err.response.data.details[0].split('" ');
				notification.error({
					message: `${I18n.get('Ops... something happened!')}`,
					description: `${I18n.get(typeError[1])}`
				});
			},
			onSuccess: () => {
				Promise.all([
					queryClient.invalidateQueries(['sectors']),
					queryClient.invalidateQueries(['workstations_list']),
					queryClient.invalidateQueries(['workstations'])
				]);
				message.success(I18n.get('Workstation created successfully'));
			}
		});
	};

	const useGetWorkstation = (organizationId: string, companyId: string, sectorId: string, workstationId: string) => {
		const workstation = useQuery(
			['workstation', organizationId, companyId, sectorId, workstationId],
			() => Services.getWorkstation({ organizationId, companyId, sectorId, workstationId }),
			{
				enabled: !!sectorId && !!organizationId && !!companyId && !!workstationId
			}
		);
		return {
			...workstation,
			data: workstation.data || ([] as WorkstationDTO[])
		};
	};

	return { useCreateWorkstationOnFile, useCreateWorkstation, useGetWorkstation };
}
