import React, { useContext, useEffect } from 'react';
import { Form, Select, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import hooks from '../../hooks';
import { MergeAEPContext } from '../../context';

const { useGetSectors } = hooks;
const { useFormInstance } = Form;

export function Sector() {
	const form = useFormInstance();

	const { organizationId, companyId, setSectorId, queryParams, setQueryParams, setWorkstation } =
		useContext(MergeAEPContext);

	const { isInitialLoading, data, isError, refetch } = useGetSectors(organizationId, companyId);

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to fetch sectors'));
		}
	}, [isError]);

	useEffect(() => {
		if (companyId.length) {
			refetch();
		}
	}, [organizationId, companyId, refetch]);

	function handleOnClearForm(): void {
		form.resetFields(['workstation']);
	}

	function handleOnClearHierarchy(): void {
		setWorkstation([]);
	}

	function handleOnChange(value: string): void {
		setSectorId(value);
		handleOnClearHierarchy();
		handleOnClearForm();
		setQueryParams({ ...queryParams, sectorId: value });
	}

	return (
		<Form.Item name="sectorId">
			<Select
				showSearch
				onChange={handleOnChange}
				loading={isInitialLoading}
				placeholder={I18n.get('Sector')}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={data.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
