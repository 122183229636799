import React from 'react';
import type { ButtonProps } from 'antd';
import { DownloadOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import * as S from './styles';

type Props = ButtonProps & {
	onClick?: () => void;
	children?: string;
};

export function Button({ children = '', onClick, ...props }: Props) {
	return (
		<S.CustomButton onClick={onClick} {...props}>
			{children}
		</S.CustomButton>
	);
}

type DownloadButtonProps = Props & {
	showIcon?: boolean;
};

export function DownloadButton({ children = '', onClick, showIcon = true, ...props }: Readonly<DownloadButtonProps>) {
	return (
		<S.DownloadButton
			{...props}
			onClick={onClick}
			icon={showIcon && <DownloadOutlined style={{ color: '#2F54EB' }} />}
		>
			{children}
		</S.DownloadButton>
	);
}

type ConsolidationButtonProps = Props & {
	showIcon?: boolean;
	freeze?: boolean;
};

export function ConsolidationButton({
	children = '',
	onClick,
	showIcon = true,
	freeze = false,
	...props
}: Readonly<ConsolidationButtonProps>) {
	return (
		<S.ConsolidationButton
			{...props}
			$freeze={freeze}
			onClick={onClick}
			icon={
				showIcon &&
				(freeze ? (
					<UnlockOutlined style={{ color: '#828282' }} />
				) : (
					<LockOutlined style={{ color: '#17A93B' }} />
				))
			}
		>
			{children}
		</S.ConsolidationButton>
	);
}
