import React from 'react';
import { Header } from './Header';
import { DatailForm } from './Form';
import * as S from './styles';

interface DetailsModalProps {
	isOpen?: boolean;
	onCancel: () => void;
}

export function DetailsModal({ isOpen = false, onCancel }: DetailsModalProps) {
	return (
		<S.Container
			open={isOpen}
			width="45vw"
			footer={null}
			centered={true}
			onCancel={onCancel}
			style={{ borderRadius: '10px' }}
		>
			<Header />
			<DatailForm />
		</S.Container>
	);
}
