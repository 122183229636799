import React from 'react';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
interface IButtonsProps {
	currentStep: number;
	steps: any;
	isLoading?: boolean;
	next: () => void;
	prev: () => void;
	onFinish?: () => void;
}

export const Buttons: React.FC<IButtonsProps> = ({ currentStep, steps, next, prev, isLoading }) => {
	return (
		<Row justify="center">
			{currentStep > 0 && (
				<Col span={'auto'}>
					<Button
						style={{
							margin: '0 8px',
							float: 'right'
						}}
						onClick={prev}
					>
						{I18n.get('Previous')}
					</Button>
				</Col>
			)}
			{currentStep < steps.length - 1 && (
				<Col span={'auto'}>
					<Button type="primary" onClick={next} style={{ float: 'left' }}>
						{I18n.get('Next')}
					</Button>
				</Col>
			)}
			{currentStep === steps.length - 1 && (
				<Col span={'auto'}>
					<Button type="primary" htmlType="submit" style={{ float: 'left' }} loading={isLoading}>
						{I18n.get('Finish')}
					</Button>
				</Col>
			)}
		</Row>
	);
};
