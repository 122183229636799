import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select } from 'antd';

import hooks from '../../hooks';
import { useApplicationContext } from '@/context/Application';

const { useGetSectors } = hooks;

type SectorSelectProps = {
	organizationId: string;
	companyId: string;
};

export function SectorSelect({ organizationId, companyId }: SectorSelectProps) {
	const { organization, company } = useApplicationContext();

	const {
		data: sectorsList,
		isLoading: gettingSectors,
		refetch
	} = useGetSectors(organizationId || organization.id, companyId || company.id);

	useEffect(() => {
		refetch();
	}, [organizationId, companyId]);

	return (
		<Form.Item name="sector">
			<Select
				showSearch
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				disabled={!companyId}
				loading={gettingSectors}
				placeholder={I18n.get('Sector')}
				options={sectorsList.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
