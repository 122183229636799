import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CreateReportDTO, CreateReportResponse } from '@/views/CreateReport/context/types';

type CreateKimPpError = AxiosError<any>;

async function createReport(parameters: CreateReportDTO) {
	const { data } = await Api.post('/ergonomic-tool/kim-pp', parameters);
	return data;
}

export const useCreateKimPp = () => {
	const queryClient = useQueryClient();
	return useMutation<CreateReportResponse, CreateKimPpError, CreateReportDTO>((payload) => createReport(payload), {
		onSuccess: async () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_KIM_PP_REPORT]);
		},
		onError: (err) => {
			const title = 'Oops! Something happened.';
			const message = err.response?.data?.message ?? 'Failed to create information';
			notification.error({
				message: I18n.get(title),
				description: I18n.get(message)
			});
		}
	});
};
