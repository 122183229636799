import styled from 'styled-components';
import { Button, Col, Divider, Form } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

type CustomDividerProps = {
	tool?: boolean;
};

export const CustomFormItem = styled(Form.Item)`
	margin: 0;

	.ant-form-item-label {
		label {
			font-size: 1rem;
		}
	}
`;

export const CustomButton = styled(Button)`
	height: max-content;
	padding: 0.5rem 2rem;
	border-radius: 0.5rem;
`;

export const CreateReportButton = styled(Button)`
	width: max-content;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.25rem;
	span {
		font-weight: 600;
	}
`;

export const CustomCol = styled(Col)`
	margin-bottom: 1rem;
`;

export const CustomPlusIcon = styled(PlusCircleFilled)`
	font-size: 1rem;
	margin-right: 0.75rem;
`;

export const CustomDivider = styled(Divider)<CustomDividerProps>`
	height: 100%;
	display: none;
	border-left: 2px solid #00000011;
	/* min-height: ${(props) => (props.tool ? '100vh' : '600px')}; */
	@media (min-width: 1200px) {
		display: block;
	}
`;
