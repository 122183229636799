import React, { Fragment, useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Chart } from './Chart';
import { Details, DetailTitle } from './styles';
import { BodyPartsDescription, BodyPartsDTO } from '../types';
import { RebaAnglesResultsDTO } from '@/components/views/PreliminaryAnalysis/types/dto/reba';

const orderBodyParts: string[] = [
	'neck',
	'trunk',
	'left_upper_arm',
	'right_upper_arm',
	'left_lower_arm',
	'right_lower_arm',
	'left_knee',
	'right_knee'
];

interface BodyPartsScoresProps {
	data: BodyPartsDTO;
	rebaAngles: RebaAnglesResultsDTO;
}

export const BodyPartsScores: React.FC<BodyPartsScoresProps> = ({ data, rebaAngles }) => {
	const [parts, setParts] = useState([]);

	function filteringOnlyParts(item: any) {
		return BodyPartsDescription[item as keyof typeof BodyPartsDescription];
	}

	function mountingPayload(item: any, index: number) {
		return {
			id: index + 1,
			original_name: item,
			name: BodyPartsDescription[item as keyof typeof BodyPartsDescription]
		};
	}

	function orderItemsBodyParts(a: any, b: any) {
		const indexA = orderBodyParts.indexOf(a.original_name);
		const indexB = orderBodyParts.indexOf(b.original_name);

		if (indexA === -1) {
			return 1;
		}

		if (indexB === -1) {
			return -1;
		}

		return indexA - indexB;
	}

	useEffect(() => {
		const array: any = Object.keys(data)
			.filter(filteringOnlyParts)
			.map(mountingPayload)
			.sort(orderItemsBodyParts)
			.reduce((acc: any, curr, i) => {
				const subArrayIndex = Math.floor(i / 3);
				if (!acc[subArrayIndex]) {
					acc[subArrayIndex] = [];
				}
				acc[subArrayIndex].push(curr);
				return acc;
			}, []);
		setParts(array);
	}, [data]);

	return (
		<>
			{parts.map((context: any, index: any, array: any) => {
				return (
					<>
						{context?.map((bodyPart: any, index: number, array: any) => {
							return (
								<Fragment key={bodyPart.id}>
									<Col span={7}>
										<Details>
											<Row style={{ marginBottom: '0.5rem' }}>
												<Col span={24}>
													<DetailTitle>{I18n.get(bodyPart.name)}:</DetailTitle>
												</Col>
											</Row>
											<Chart angles={rebaAngles} member={bodyPart.original_name} />
										</Details>
									</Col>
									{(index + 1) % 3 !== 0 && index !== array.length - 1 && (
										<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
									)}
								</Fragment>
							);
						})}
						{(index + 1) % 3 !== 0 && index !== array.length - 1 && <Divider style={{ margin: 0 }} />}
					</>
				);
			})}
		</>
	);
};
