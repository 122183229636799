import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';

import { ToolCard } from './ToolCard';
import { CustomFormItem } from '../../../styles';
import { CustomSubtitle, CustomTitle, CustomToolCol } from './styles';

const { useFormInstance, useWatch } = Form;

interface ToolProps {
	id: string;
	title: string;
	subtitle: string;
	icon?: JSX.Element;
}

export const Tool: React.FC<ToolProps> = ({ icon, id, title, subtitle }) => {
	const form = useFormInstance();
	const currentToolChecked = useWatch(['step_key', 'tools', id], form);

	function handleToolClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
		event.preventDefault();
		event.stopPropagation();
		form.setFieldValue(['step_key', 'tools', id], !currentToolChecked);
	}

	return (
		<Col xs={24} xxl={12} onClick={handleToolClick}>
			<CustomFormItem name={['step_key', 'tools', id]}>
				<ToolCard checked={currentToolChecked}>
					<Checkbox onClick={handleToolClick} checked={currentToolChecked}>
						{icon}
						<Row justify="start">
							<CustomToolCol xs={24}>
								<CustomTitle level={5}>{title}</CustomTitle>
							</CustomToolCol>
							{subtitle && (
								<Col>
									<CustomSubtitle>{subtitle}</CustomSubtitle>
								</Col>
							)}
						</Row>
					</Checkbox>
				</ToolCard>
			</CustomFormItem>
		</Col>
	);
};
