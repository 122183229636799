import React, { createContext, ReactNode, useContext, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';

import { useCustomReportManagerContext } from '../../context';
import { ChangeStepKeySequence, Context, Methods, States, StepKey, StepKeyList, StepKeyToAdd } from './types';

type CustomReportManagerStepKeyProviderProps = {
	children: ReactNode;
};

const CustomReportManagerStepKeyContext = createContext<Context>({} as Context);

export function CustomReportManagerStepKeyProvider({ children }: Readonly<CustomReportManagerStepKeyProviderProps>) {
	const { selectedStepId, selectedStepKeys, handleSelectedStepKeys, handleFirstIncompleteStepId } =
		useCustomReportManagerContext();

	const hasSelectedStepKeys = selectedStepKeys?.[selectedStepId] && selectedStepKeys[selectedStepId].length > 0;

	const [stepKeysList, setStepKeysList] = useState<StepKeyList>(hasSelectedStepKeys ? selectedStepKeys : {});

	function addStepKeyToStepKeyList(stepKey: StepKeyToAdd): void {
		handleFirstIncompleteStepId('');
		const stepKeys = stepKeysList?.[selectedStepId];
		if (!stepKeys) {
			const stepKeysListCopy = { ...stepKeysList };
			stepKeysListCopy[selectedStepId] = [
				{
					...stepKey,
					sequence: 1
				}
			];
			setStepKeysList(stepKeysListCopy);
			handleSelectedStepKeys(stepKeysListCopy);
			return;
		}
		const stepKeyListCopy = [...stepKeys];
		const existingStep = stepKeyListCopy.find(({ id }) => stepKey.id === id);
		if (existingStep) {
			return notification.error({
				message: `${I18n.get('Ops... something happened!')}`,
				description: `${I18n.get('Criterion has already been added')}`
			});
		}
		const newStep = defineNewStepKey(stepKey);
		stepKeyListCopy.push(newStep);
		const mappedStepKeys = mapStepKeysSequence(stepKeyListCopy);
		setStepKeysList({
			...stepKeysList,
			[selectedStepId]: mappedStepKeys
		});
		handleSelectedStepKeys({
			...stepKeysList,
			[selectedStepId]: mappedStepKeys
		});
	}

	function defineNewStepKey(stepKey: StepKeyToAdd): StepKey {
		const lastStepKey = stepKeysList[selectedStepId].pop();
		const newStepKey = {
			...stepKey,
			sequence: 1
		};
		if (lastStepKey) {
			const lastStepKeySequence = lastStepKey.sequence ?? 0;
			newStepKey.sequence = lastStepKeySequence;
		}
		return newStepKey;
	}

	function removeStepKeyFromStepKeyList(stepKey: StepKey): void {
		const stepKeysListCopy = { ...stepKeysList };
		const stepKeys = stepKeysList[selectedStepId];
		const objectIndexToRemove = stepKeys.findIndex(({ id }) => stepKey.id === id);
		stepKeys.splice(objectIndexToRemove, 1);
		const mappedStepKeys = mapStepKeysSequence(stepKeys);
		stepKeysListCopy[selectedStepId] = mappedStepKeys;
		setStepKeysList(stepKeysListCopy);
		handleSelectedStepKeys(stepKeysListCopy);
	}

	function changeStepKeyListSequence({ list, startIndex, endIndex }: ChangeStepKeySequence): void {
		const stepKeysListCopy = { ...list };
		const stepKeys = stepKeysList[selectedStepId];
		const [removed] = stepKeys.splice(startIndex, 1);
		stepKeys.splice(endIndex, 0, removed);
		const mappedStepKeys = mapStepKeysSequence(stepKeys);
		stepKeysListCopy[selectedStepId] = mappedStepKeys;
		setStepKeysList(stepKeysListCopy);
		handleSelectedStepKeys(stepKeysListCopy);
	}

	function mapStepKeysSequence(stepKeys: StepKey[]): StepKey[] {
		return stepKeys.map((stepKey, index) => ({
			...stepKey,
			sequence: index + 1
		}));
	}

	const states: States = {
		stepKeysList
	};

	const methods: Methods = {
		addStepKeyToStepKeyList,
		changeStepKeyListSequence,
		removeStepKeyFromStepKeyList
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<CustomReportManagerStepKeyContext.Provider value={context}>
			{children}
		</CustomReportManagerStepKeyContext.Provider>
	);
}

export function useCustomReportManagerStepKeyContext() {
	const context = useContext(CustomReportManagerStepKeyContext);
	return context;
}
