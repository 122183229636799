import { Tag } from 'antd';
import styled, { DefaultTheme } from 'styled-components';
import { RiskScale } from '@/styles/enum';
import { lightTheme } from '@/styles/themes/light';

interface TagRiskProps {
	$result: RiskScale;
	$isBrightBackground: boolean;
}

export const LibertyMutualTag = styled(Tag)`
	width: 100%;
	padding: 4px 0px;
	border: 1px solid #2f54eb;
	background-color: #eaeefd;

	span {
		color: #2f54eb;
		font-weight: 600;
	}
`;

export const TagRisk = styled(Tag)<TagRiskProps>`
	border-radius: 5px;
	background-color: ${({ $result, theme }) => setTagBackGroundColor(theme || lightTheme, $result)};
	span {
		color: ${({ $isBrightBackground }) => ($isBrightBackground ? 'black' : 'white')};
	}
`;

function setTagBackGroundColor(theme: DefaultTheme, $result: RiskScale) {
	if ($result === 0) {
		return theme.colors.riskScale[1];
	}
	return theme.colors.riskScale[$result] ?? theme.colors.riskScale[RiskScale.NOT_APPLICABLE];
}
