import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_PLAN } = QUERY_KEYS;

interface GetPlanParams {
	organization_id?: string;
	company_id?: string;
}

export type Plan = {
	name: string;
	max_users: number;
	max_minutes: number;
};

export interface PlanDTO {
	plan: Plan;
	special_plan: boolean;
	minutes_processed: number;
	minutes_available: number;
	processed_videos: number;
	canceled_on: Date | null;
	expiration_plan: Date | null;
}

async function getPlan(params: GetPlanParams): Promise<PlanDTO> {
	const { data } = await Api.get('/plan/customer/details', { params });
	return data;
}

export const useGetPlan = (params: GetPlanParams) => {
	return useQuery([GET_PLAN, params], () => getPlan(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
