import React from 'react';
import { Col, Space } from 'antd';

export const Icon: React.FC = () => (
	<Col span={24}>
		<Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
			<svg width="76" height="69" viewBox="0 0 76 69" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M47.0776 43.2523C45.8485 40.2772 44.0648 37.5748 41.8258 35.2956C39.5937 33.0099 36.9495 31.1875 34.0393 29.929C34.0132 29.9157 33.9872 29.909 33.9611 29.8957C38.0205 26.8995 40.6595 22.019 40.6595 16.5126C40.6595 7.3907 33.4268 0 24.5 0C15.5732 0 8.34053 7.3907 8.34053 16.5126C8.34053 22.019 10.9795 26.8995 15.0389 29.9024C15.0128 29.9157 14.9868 29.9224 14.9607 29.9357C12.0416 31.1941 9.42218 32.9985 7.17418 35.3023C4.93733 37.5832 3.15389 40.2851 1.92236 43.2589C0.712497 46.1703 0.0599916 49.2916 0.000162937 52.454C-0.00157619 52.5251 0.0106218 52.5958 0.0360384 52.662C0.0614551 52.7281 0.099576 52.7884 0.148155 52.8393C0.196734 52.8902 0.254788 52.9306 0.318897 52.9582C0.383005 52.9858 0.451871 53 0.521436 53H4.43098C4.71768 53 4.94574 52.767 4.95226 52.4807C5.08258 47.3405 7.10251 42.5265 10.6732 38.8778C14.3678 35.1025 19.2742 33.0251 24.5 33.0251C29.7258 33.0251 34.6322 35.1025 38.3268 38.8778C41.8975 42.5265 43.9174 47.3405 44.0477 52.4807C44.0543 52.7736 44.2823 53 44.569 53H48.4786C48.5481 53 48.617 52.9858 48.6811 52.9582C48.7452 52.9306 48.8033 52.8902 48.8518 52.8393C48.9004 52.7884 48.9385 52.7281 48.964 52.662C48.9894 52.5958 49.0016 52.5251 48.9998 52.454C48.9347 49.2714 48.2896 46.1753 47.0776 43.2523ZM24.5 27.9648C21.5092 27.9648 18.6943 26.773 16.5766 24.609C14.459 22.4451 13.2926 19.5687 13.2926 16.5126C13.2926 13.4564 14.459 10.58 16.5766 8.41608C18.6943 6.25214 21.5092 5.0603 24.5 5.0603C27.4908 5.0603 30.3057 6.25214 32.4234 8.41608C34.541 10.58 35.7074 13.4564 35.7074 16.5126C35.7074 19.5687 34.541 22.4451 32.4234 24.609C30.3057 26.773 27.4908 27.9648 24.5 27.9648Z"
					fill="#2F54EB"
				/>
				<circle cx="52.696" cy="45.696" r="21.696" fill="#2F54EB" stroke="white" stroke-width="1.5" />
				<mask id="path-3-inside-1_3745_2836" fill="white">
					<rect x="39.6816" y="38.666" width="26.9979" height="18.8076" rx="1" />
				</mask>
				<rect
					x="39.6816"
					y="38.666"
					width="26.9979"
					height="18.8076"
					rx="1"
					stroke="white"
					stroke-width="3"
					mask="url(#path-3-inside-1_3745_2836)"
				/>
				<path
					d="M47.1211 38.8771V36.6782C47.1211 34.4691 48.912 32.6782 51.1211 32.6782H55.5188C57.7279 32.6782 59.5188 34.4691 59.5188 36.6782V38.8771"
					stroke="white"
					stroke-width="1.5"
				/>
				<path
					d="M40.92 39.4971V39.4971C40.449 41.9296 41.8209 44.3426 44.1521 45.1818L50.0574 47.3077C50.4461 47.4476 50.7991 47.6717 51.0912 47.9638V47.9638C51.6175 48.4901 52.3313 48.7857 53.0756 48.7857H53.2818C54.0261 48.7857 54.7399 48.4901 55.2662 47.9638V47.9638C55.5583 47.6717 55.9113 47.4476 56.3 47.3077L62.2467 45.1669C64.589 44.3236 66.0311 41.9664 65.7154 39.4971V39.4971"
					stroke="white"
					stroke-width="1.5"
				/>
				<rect x="51.4922" y="46.3157" width="3.37474" height="3.37474" rx="1" fill="#2F54EB" stroke="white" />
			</svg>
		</Space>
	</Col>
);
