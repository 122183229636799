import { useState } from 'react';

const UNDEFINED = 'undefined';

export const useSessionStorage = (key: string, initialValue: boolean) => {
	const [storedValue, setStoredValue] = useState<boolean>(() => {
		if (typeof window === UNDEFINED) {
			return initialValue;
		}
		try {
			const item = window.sessionStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			return initialValue;
		}
	});

	const setValue = (value: boolean | ((val: boolean) => boolean)) => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			if (typeof window !== UNDEFINED) {
				window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
			}
		} catch (error) {
			console.log(error);
		}
	};
	return [storedValue, setValue] as const;
};
