import React from 'react';
import { Col, Form } from 'antd';

import { useGetActivities } from '@/hooks';
import { FormItem } from '../../../components/FormItem';
import { usePersonalisedReportContext } from '../../../../context';
import { ActivitySelect } from '@/components/ui/Inputs/ActivitySelect';
import { I18n } from '@aws-amplify/core';

const { useFormInstance, useWatch } = Form;

export const Activities: React.FC = () => {
	const form = useFormInstance();
	const { organization_id, company_id } = usePersonalisedReportContext();

	const workstationId = useWatch(['basic_information', 'workstation_id'], form);
	const activityId = useWatch(['basic_information', 'activity_id'], form);
	const fieldName = ['basic_information', 'activity_id'];

	const {
		data: activities,
		isLoading,
		isFetching,
		isError
	} = useGetActivities({
		organization_id,
		company_id,
		workstation_id: workstationId
	});

	function handleSelectActivities(id: string): void {
		form.setFieldValue(fieldName, id);
	}

	function handleClearActivities(): void {
		form.setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				fontSize="1rem"
				isRequired={true}
				title="Activity"
				namePath={fieldName}
				errorMessage={I18n.get('Select a activity')}
			>
				<ActivitySelect
					size="large"
					fieldName={fieldName}
					allowCreation
					companyId={company_id}
					organizationId={organization_id}
					workstationId={workstationId}
					activityId={activityId}
					loading={isFetching}
					options={activities}
					handleSelectActivity={handleSelectActivities}
					handleClearActivities={handleClearActivities}
					disabled={isLoading || isFetching || isError || !workstationId}
				/>
			</FormItem>
		</Col>
	);
};
