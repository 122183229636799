import React from 'react';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, TextInput } from '../../styles';
import { FieldsProps } from '../types';

export function District({ name, required = false }: Readonly<FieldsProps>) {
	return (
		<CustomFormItem
			name={name}
			labelCol={{ span: 24 }}
			label={I18n.get('Department')}
			rules={[{ required, message: I18n.get('Enter an department') }]}
		>
			<TextInput placeholder={I18n.get('Department')} />
		</CustomFormItem>
	);
}
