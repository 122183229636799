export const kinebotStepKeyExposureDescriptionMapper: { [key: string]: string } = {
	remotely: 'Remotely (>90% green)',
	rarely: 'Rarely (80 to 89% green)',
	irregular: 'Irregular (50 to 79% green)',
	occasionally: 'Occasionally (30 to 49% green)',
	frequent: 'Frequent (10 to 29% green)',
	to_be_continued: 'Continues (<9% green)'
};

export type Exposure = {
	id: string;
	name: string;
	description: string;
	score: number;
	is_active: boolean;
	created_at: Date;
	updated_at: Date;
};
