import Api from '@/services/api';
import {
	DashboardActionPlansCardsDTO,
	DashboardActionPlansStatusDTO,
	DashboardNioshDTO,
	DashboardQueryParams,
	DashboardRebaReportsDTO,
	DashboardStrainIndexDTO,
	DashboardSummaryDTO,
	DashboardWorkOganizationDTO,
	DashboardWorkstation,
	SectorDTO
} from './types';

export class DashboardService {
	reportExistsAndHasType(reports: DashboardStrainIndexDTO | DashboardNioshDTO, risk: string) {
		return Object.prototype.hasOwnProperty.call(reports.scores, risk) && reports.scores[risk].type;
	}

	async fetchSummary(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
		const baseUrl = `/dashboard/summary/${organizationId}/${companyId}?`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardSummaryDTO>(url);
		return data;
	}

	async fetchSectors(organizationId: string, companyId: string) {
		const url = `/sector/${organizationId}/${companyId}?name=&limit=${30}`;
		const { data } = await Api.get<SectorDTO[]>(url);
		return data;
	}

	async fetchNioshReports(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
		const baseUrl = `/dashboard/niosh/${organizationId}/${companyId}?`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardNioshDTO>(url);
		return data;
	}

	async fetchWorkOganizationReports(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
		const baseUrl = `/dashboard/work-organization/${organizationId}/${companyId}?`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardWorkOganizationDTO>(url);
		return data;
	}

	async fetchStrainIndexReports(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
		const baseUrl = `/dashboard/rsi/${organizationId}/${companyId}?`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardStrainIndexDTO>(url);
		return data;
	}

	async fetchRebaReports(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
		const baseUrl = `/dashboard/body-parts/${organizationId}/${companyId}?`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardRebaReportsDTO[]>(url);
		return data;
	}

	async fetchWorkstationsScores(
		organizationId: string,
		companyId: string,
		queryParams: DashboardQueryParams,
		offset?: number,
		limit?: number
	) {
		const baseUrl = `/dashboard/workstations/${organizationId}/${companyId}?offset=${offset}&limit=${limit}&`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardWorkstation[]>(url);
		return data;
	}

	async fetchActionPlansStatus(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
		const baseUrl = `/dashboard/action-plans-status/${organizationId}/${companyId}?`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardActionPlansStatusDTO>(url);
		return data;
	}

	async fetchActionPlansCards(
		organizationId: string,
		companyId: string,
		queryParams: DashboardQueryParams,
		offset?: number,
		limit?: number
	) {
		const baseUrl = `/dashboard/action-plans-cards/${organizationId}/${companyId}?offset=${offset}&limit=${limit}&`;
		const url = this.setUrl(baseUrl, queryParams);
		const { data } = await Api.get<DashboardActionPlansCardsDTO>(url);
		return data;
	}

	private setUrl(baseUrl: string, queryParams: DashboardQueryParams) {
		const {
			stressLevel,
			sectorId,
			celluleName,
			workstation,
			rsi,
			niosh,
			work_organization,
			workstations,
			reba,
			action_plan
		} = queryParams;
		let params = ``;

		if (sectorId) {
			params = `${params}sector_id=${sectorId}&`;
		}

		if (workstation) {
			params = `${params}workstation=${workstation}&`;
		}

		if (celluleName) {
			params = `${params}cellule_name=${celluleName}&`;
		}

		if (rsi) {
			params = `${params}rsi=${rsi}&`;
		}

		if (niosh) {
			params = `${params}niosh=${niosh}&`;
		}

		if (work_organization) {
			params = `${params}work_organization=${work_organization}&`;
		}

		if (workstations) {
			params = `${params}workstation_score=${workstations.score}&`;
			if (!workstation) {
				params = `${params}workstation=${workstations.workstation}&`;
			}
		}

		if (reba) {
			const body_part = reba.body_part.toLowerCase().replaceAll(' ', '_');
			params = `${params}reba_score=${reba.score}&`;
			params = `${params}reba_body_part=${body_part}&`;
		}

		if (action_plan) {
			params = `${params}action_plan=${action_plan}&`;
		}

		if (stressLevel && stressLevel.length > 0) {
			params = stressLevel.reduce((stressLevelParam, currentStressLevel) => {
				return `${stressLevelParam}stress_level_${currentStressLevel}=${currentStressLevel}&`;
			}, params);
		}

		return baseUrl + params;
	}
}
