import React, { useState, useEffect, useRef } from 'react';
import { I18n } from '@aws-amplify/core';
import { Modal, Input, message } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Organization } from './Organization';
import { Company } from './Company';
import { Footer } from './Footer';

import Api from '@/services/api';
import { useGetPlan } from '@/hooks/useGetPlan';
import { setOrganization, setCompany, clearOrganization } from '@/redux/organization/actions';
import { useApplicationContext } from '@/context/Application';
import { Body, AntIcon, SpinnerLoading } from './styles';

export function SelectProject() {
	const context = useApplicationContext();

	const dispatch = useDispatch();
	const inputRef = useRef(null);

	const [current, setCurrent] = useState(0);
	const [visible, setVisible] = useState(false);

	const [searchText, setSearchText] = useState('');

	const [createOrganization, setCreateOrganization] = useState(false);
	const [createCompany, setCreateCompany] = useState(false);

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const { data } = useGetPlan({ organization_id: organization?.id, company_id: company?.id });

	const itemsLimit = 15;

	async function getOrganizations({ search = '', limit = 10 }) {
		let url = `/organization/search?name=${search}&limit=${limit}`;
		const { data } = await Api.get(url);
		return data;
	}

	const organizationsParams = {
		search: searchText,
		limit: itemsLimit
	};

	const organizations = useQuery(['organizations', organizationsParams], () => getOrganizations(organizationsParams));

	async function getCompanies({ organization_id, search = '', limit = 10 }) {
		if (!organization_id) return;
		let url = `/company/search/${organization_id}?name=${search}&limit=${limit}`;
		const { data } = await Api.get(url);
		return data;
	}

	const companiesParams = {
		organization_id: organization?.id,
		search: searchText,
		limit: itemsLimit
	};

	const companies = useQuery(['companies', companiesParams], () => getCompanies(companiesParams), {
		enabled: !!organization
	});

	let filtered = searchText.length > 0;
	let showSearch =
		filtered ||
		(current === 0 && organizations?.data?.length > 0) ||
		(current === 1 && companies?.data?.length > 0);

	useEffect(() => {
		if (inputRef && inputRef.current) {
			setTimeout(() => inputRef.current.focus(), 0); // Because of AntDesign's Modal we have to use a timeout (https://github.com/vazco/uniforms/issues/279)
		}
	}, [visible]);

	const selectOrganization = (item) => {
		dispatch(setOrganization(item));
		context.setOrganization(item);
	};

	const selectCompany = (item) => {
		dispatch(setCompany({ organization: organization, company: item }));
		context.setCompany(item);
	};

	const onChangeSearch = (e) => {
		setSearchText(e.currentTarget.value);
	};

	const steps = [
		{
			name: 'company',
			content: (
				<Organization
					data={organizations?.data}
					onSelect={selectOrganization}
					visibleCreate={createOrganization}
					closeCreate={() => setCreateOrganization(false)}
					filtered={filtered}
					specialPlan={data?.special_plan}
				/>
			)
		},
		{
			name: 'industrial site',
			content: (
				<Company
					data={companies?.data}
					onSelect={selectCompany}
					visibleCreate={createCompany}
					closeCreate={() => setCreateCompany(false)}
					filtered={filtered}
					specialPlan={data?.special_plan}
				/>
			)
		}
	];

	const title = `Select the desired ${steps[current].name} below`;
	const inputPlaceholderText = I18n.get(`Search ${steps[current].name}`);

	function handleNextStep() {
		setCurrent((prev) => prev + 1);
		setSearchText('');
	}

	function handlePreviousStep() {
		setCurrent((prev) => prev - 1);
		setSearchText('');
		dispatch(clearOrganization());
	}

	useEffect(() => {
		!organization ? setVisible(true) : handleNextStep();
	}, [organization]);

	useEffect(() => {
		if (organization && company) {
			setCurrent(0);
			setVisible(false);
		}

		if (!organization && !company) {
			setCurrent(0);
		}
	}, [organization, company, current]);

	const Loading = () => <SpinnerLoading indicator={<AntIcon size={38} spin />} />;

	const Suspense = ({ loading, children }) => <Body>{loading ? <Loading /> : children}</Body>;

	if (organizations.isError || companies.isError) {
		message.error(I18n.get('Failed to fetch information'));
	}

	return (
		<Modal
			open={visible}
			closable={false}
			title={I18n.get(title)}
			footer={<Footer isFirstStep={current > 0} onClick={handlePreviousStep} />}
			bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
		>
			{showSearch && (
				<Input
					placeholder={inputPlaceholderText}
					style={{ marginBottom: '20px' }}
					onChange={onChangeSearch}
					value={searchText}
					ref={inputRef}
				/>
			)}
			<Suspense loading={current === 0 ? organizations.isLoading : companies.isLoading}>
				{steps[current].content}
			</Suspense>
		</Modal>
	);
}
