import React from 'react';
import { Icon } from './Icon';
import { Title } from './Title';
import { Content } from '../Content';
import { SubTitle } from './SubTitle';
import { Container } from '../Container';

export const Error: React.FC = () => (
	<Container withBackground={false}>
		<Content>
			<Icon />
			<Title />
			<SubTitle />
		</Content>
	</Container>
);
