import React, { CSSProperties } from 'react';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

import { Tool } from './Tool';
import { ToolsNames } from '@/types';
import { Title } from '@/components/Typography';
import { ToolList } from '../../../../context/types';
import { LibertyMutualIcon } from './LibertyMutualIcon';

type MappedErgonomicTools = {
	id: string;
	title: string;
	name: ToolsNames;
	subtitle: string;
	icon?: JSX.Element;
};

interface ToolsListProps {
	onCloseModalClick(): void;
	ergonomicTools: ToolList[] | undefined;
}

export const ToolsList: React.FC<ToolsListProps> = ({ ergonomicTools, onCloseModalClick }) => {
	const iconStyle: CSSProperties = {
		fontSize: '1.5rem'
	};

	const toolsIcon = {
		[ToolsNames.angle_time]: undefined,
		[ToolsNames.liberty_mutual]: <LibertyMutualIcon />,
		[ToolsNames.niosh]: <Icons.ToTopOutlined style={iconStyle} />,
		[ToolsNames.rula]: <Icons.PieChartOutlined style={iconStyle} />,
		[ToolsNames.reba]: <Icons.LineChartOutlined style={iconStyle} />,
		[ToolsNames.kim_pp]: <Icons.ColumnWidthOutlined style={iconStyle} />,
		[ToolsNames.strain_index]: <Icons.RetweetOutlined style={iconStyle} />,
		[ToolsNames.kim_mho]: <Icons.LikeOutlined style={{ ...iconStyle, transform: 'rotate(270deg)' }} />
	};

	const mappedTools: MappedErgonomicTools[] | undefined = ergonomicTools
		?.filter(({ name }) => toolsIcon[name])
		.map(({ id, name, title, subtitle }) => {
			return {
				id,
				name,
				title,
				subtitle,
				icon: toolsIcon[name]
			};
		});

	return (
		<Col xs={22} style={{ height: '100%' }}>
			<Row>
				<Col xs={24}>
					<Row style={{ padding: '1rem' }}>
						<Title level={3}>Selected tools</Title>
					</Row>
					<Row justify="start" gutter={[16, 16]}>
						{mappedTools?.map(({ icon, id, title, subtitle }) => (
							<Tool key={id} id={id} icon={icon} title={title} subtitle={subtitle} />
						))}
					</Row>
				</Col>
				<Col xs={24}>
					<Row justify="center" style={{ margin: '2rem 0' }}>
						<Button onClick={onCloseModalClick} type="primary" htmlType="submit">
							{I18n.get('Close')}
						</Button>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
