import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, DatePicker, Empty, Form, Input, Row, Select, Skeleton } from 'antd';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { debounce } from 'lodash';

import { MergeAEPContext } from './context';
import hooks from './hooks';

const { useGetOrganization } = hooks;
const { useFormInstance, useWatch } = Form;

export const Informations: React.FC = () => {
	const { setOrganizationId, setOrganizations } = useContext(MergeAEPContext);
	const [organizationSearch, setOrganizationSearch] = useState('');

	const organization_id = useWatch('organization');
	const { setFieldValue } = useFormInstance();

	const params = { name: organizationSearch };

	const {
		data: organizationsList,
		isLoading: gettingOrganizations,
		isError: organizationsError,
		isFetching: fetchingOrganizations
	} = useGetOrganization(params);

	useEffect(() => {
		setOrganizations(organizationsList);
		organization_id &&
			setFieldValue('organization', organizationsList.find(({ id }) => id === organization_id)?.name);
	}, [gettingOrganizations]);

	const handleSearch = useRef(
		debounce(async (value) => {
			setOrganizationSearch(value);
		}, 500)
	).current;

	function onChange(value: string) {
		setOrganizationId(value);
		setFieldValue('organization', organizationsList.find(({ id }) => id === value)?.name);
	}

	function onSearch(value: string) {
		handleSearch(value);
	}

	function notFoundContent() {
		if (fetchingOrganizations) {
			return <Skeleton active paragraph={{ width: '100%', rows: 4 }} title={false} />;
		}
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
	}

	return (
		<Col lg={12} xl={12}>
			<Row gutter={[10, 10]}>
				<Col xs={24} xl={24}>
					<Title level={5}>{I18n.get('Report Information')}</Title>
				</Col>
				<Col xs={24} xl={24}>
					<Row gutter={[10, 0]}>
						<Col xs={24} xl={12}>
							<Form.Item
								name="report_name"
								required
								rules={[{ required: true, message: I18n.get('Enter report name') }]}
								label={I18n.get('Report name')}
							>
								<Input placeholder={I18n.get('Enter report name')} />
							</Form.Item>
						</Col>
						<Col xs={24} xl={12}>
							<Form.Item
								name="organization"
								rules={[{ required: true, message: I18n.get('Select a Company') }]}
								label={I18n.get('Company')}
							>
								<Select
									allowClear
									showSearch
									onChange={onChange}
									onSearch={onSearch}
									filterOption={false}
									disabled={organizationsError}
									loading={gettingOrganizations}
									placeholder={I18n.get('Company')}
									notFoundContent={notFoundContent()}
									onClear={() => {
										setFieldValue('organization', undefined);
									}}
									options={organizationsList.map(({ id, name }) => ({ label: name, value: id }))}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row gutter={[10, 10]} style={{ paddingTop: '15px' }}>
				<Col xs={24} xl={24}>
					<Title level={5}>Date</Title>
				</Col>
				<Col xs={24} xl={12}>
					<Form.Item
						name="reference_date"
						label={I18n.get('Date of reference')}
						rules={[{ required: true, message: I18n.get('Select the reference date') }]}
					>
						<DatePicker
							format={'MM-YYYY'}
							style={{ width: '100%' }}
							picker="month"
							disabledDate={(current) => current && current.valueOf() >= Date.now()}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} xl={12}>
					<Form.Item
						name="date_report"
						label={I18n.get('Date of the report')}
						rules={[{ required: true, message: I18n.get('Select the report date') }]}
					>
						<DatePicker
							format={'DD-MM-YYYY'}
							style={{ width: '100%' }}
							disabledDate={(current) => current && current.valueOf() >= Date.now()}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
