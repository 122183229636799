import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

type FooterProps = {
	isUrlLogoOrganization: boolean;
	isUrlLogoCompany: boolean;
	handleModalClose: any;
	current: number;
	steps: any;
	next: any;
	queryUrl: string;
};

export function Footer({
	current,
	steps,
	next,
	queryUrl,
	isUrlLogoOrganization,
	isUrlLogoCompany,
	handleModalClose
}: FooterProps) {
	return (
		<Row>
			<Col span={24}>
				<Row justify="center" style={{ marginTop: '20px' }}>
					<Col span="auto">
						{current < steps.length - 1 && queryUrl !== 'organization' && (
							<Button
								style={{ width: '96px', borderRadius: '3px' }}
								type="primary"
								onClick={() => next()}
								disabled={isUrlLogoOrganization}
							>
								{I18n.get('Next')}
							</Button>
						)}

						{(current === steps.length - 1 || queryUrl === 'organization') && (
							<Button
								disabled={isUrlLogoCompany}
								type="primary"
								style={{ width: '128px', borderRadius: '3px' }}
								onClick={() => handleModalClose()}
							>
								{I18n.get('Close')}
							</Button>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
