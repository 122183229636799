import React from 'react';
import { Col, Row } from 'antd';

import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';

import { Container } from './styles';
import { ActionPlan } from '../types';
import { ActionPlanData } from './ActionPlanData';

type ActionPlansProps = {
	actionPlans: ActionPlan[];
};

export const ActionPlans: React.FC<ActionPlansProps> = ({ actionPlans }) => {
	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={3}>
						{I18n.get('Action plans')}
					</Title>
				</Row>
			</Col>

			<Col offset={1} span={23}>
				{actionPlans.map((actionPlan) => (
					<Container key={actionPlan.id}>
						<ActionPlanData actionPlan={actionPlan} />
					</Container>
				))}
			</Col>
		</Row>
	);
};
