import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Button } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import { ReactComponent as SafetyFilled } from '@/assets/icons/safety-filled.svg';
import { useApplicationContext } from '@/context/Application';
import { PrimaryButton } from '@/components/ui/Buttons/PrimaryButton';
import { PinCodeInput } from '@/components/ui/Inputs/PinCodeInput';
import { Auth2FAParams, MethodTypes } from '@/types/AuthUser';
import { Text, Title } from '@/components/Typography';
import { setSession } from '@/redux/auth/actions';
import Api from '@/services/api';

import { Container, IconContainer } from './styles';
import { useCheck2FA } from './hooks';

const { useForm } = Form;

export const Check2FAContent: React.FC = () => {
	const [form] = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<Auth2FAParams>();
	const { info_user, setUser } = useApplicationContext();

	const [authToken, setAuthToken] = useState('');
	const type = MethodTypes.AUTHENTICATOR;

	const { mutateAsync: check2FA, isLoading: check2FALoading } = useCheck2FA();

	function handleChange(token: string) {
		setAuthToken(token);
	}

	async function handleConfirmClick() {
		await form.validateFields(['verification_code']);
		const checked = await check2FA({
			user_id: params.user_id,
			data: { type, token: authToken }
		});

		if (checked.is_2fa_token_correct) {
			setUser({
				user: info_user.user,
				token: checked.token,
				refresh_token: checked.refresh_token
			});

			dispatch(
				setSession({
					user: info_user.user,
					token: checked.token,
					refresh_token: checked.refresh_token
				})
			);

			Api.defaults.headers.Authorization = `Bearer ${checked.token}`;

			history.push('/');
		}
	}

	const handleReturn = useCallback((): void => {
		history.push('/login');
	}, [history]);

	return (
		<Container xs={18} sm={18} md={12} lg={10} xl={8} xxl={6}>
			<Row justify="center">
				<IconContainer>
					<SafetyFilled />
				</IconContainer>
			</Row>
			<Row justify="center" style={{ marginTop: '1.5rem', textAlign: 'center' }}>
				<Title level={4}>Two-factor authentication</Title>
			</Row>
			<Row justify="center" style={{ marginTop: '0.5rem' }}>
				<Col span={24} style={{ textAlign: 'center', fontSize: '1rem', lineHeight: '1.3rem' }}>
					<Text>For security reasons, we need you to do your two-factor validation</Text>
				</Col>
			</Row>
			<Form form={form} style={{ marginTop: '2rem' }}>
				<Row justify="center" align="middle" style={{ height: '3.5rem' }}>
					<Form.Item
						style={{ margin: 0 }}
						noStyle
						name="verification_code"
						rules={[
							() => ({
								validator() {
									return authToken.length !== 6
										? Promise.reject(I18n.get('Enter the 6 digit code.'))
										: Promise.resolve();
								}
							})
						]}
					>
						<PinCodeInput allowedCharacters="numeric" onChange={handleChange} />
					</Form.Item>
				</Row>
				<Row style={{ marginTop: '1rem', fontSize: '0.9rem' }}>
					<a href={`/recovery-two-factor/${params.user_id}`}>{I18n.get('Reset token')}</a>
				</Row>
				<Row style={{ marginTop: '1rem' }}>
					<PrimaryButton
						loading={check2FALoading}
						disabled={authToken.length !== 6}
						onClick={handleConfirmClick}
						htmlType="submit"
					>
						{I18n.get('Confirm')}
					</PrimaryButton>
				</Row>
				<Row style={{ marginTop: '1rem' }}>
					<Col span={24}>
						<Button type="link" onClick={handleReturn}>
							{I18n.get('Return')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};
