import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RebaService } from './services';
import { IDefaultParameters, ICreate, IUpdate } from './types';

const service = new RebaService();

function useCreateReba() {
	const queryClient = useQueryClient();
	return useMutation((payload: ICreate) => service.create(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries(['reba-angles']);
		}
	});
}

function useUpdateReba() {
	const queryClient = useQueryClient();
	return useMutation((payload: IUpdate) => service.update(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries(['reba-angles']);
		}
	});
}

function useCalculateAngles() {
	return useMutation((payload: IDefaultParameters) => service.getCalculateAngles(payload));
}

export { useCreateReba, useUpdateReba, useCalculateAngles };
