import React from 'react';
import { Row, Col } from 'antd';
import { Container } from './styles';
import { Popover } from '@/components/Layout/Popover';
import { SelectProject } from '@/components/SelectProject';
import { ProjectSelect } from '@/components/Layout/ProjectSelect';

export const Header: React.FC = () => (
	<Container>
		<Row gutter={14} justify="end" style={{ margin: '0 36px' }}>
			<Col>
				<ProjectSelect />
			</Col>
			<Col>
				<Popover />
			</Col>
			<Col>
				<SelectProject />
			</Col>
		</Row>
	</Container>
);
