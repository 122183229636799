import styled from 'styled-components';

const ENUM_STRESS_LEVEL = ['#2CC852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];

type StressLevelStyles = {
	stressLevel: number;
	isActive: Boolean;
};

export const StressLevelContainer = styled.button<StressLevelStyles>`
	display: flex;
	flex-direction: column;
	background: none;
	padding: 0.6rem 0.5rem;
	border-radius: 0.25rem;
	justify-content: center;
	align-items: center;
	width: 2.5rem;

	border: ${({ isActive, stressLevel }) => {
		return isActive ? '1px solid' + ENUM_STRESS_LEVEL[stressLevel - 1] : '1px solid #d9d9d9;';
	}};

	color: ${({ isActive, stressLevel }) => {
		return isActive ? ENUM_STRESS_LEVEL[stressLevel - 1] : '#8c8c8c';
	}};

	span {
		justify-content: center;
	}

	&:hover {
		cursor: pointer;
		border: 1px solid ${({ stressLevel }) => ENUM_STRESS_LEVEL[stressLevel - 1]};
		color: ${({ stressLevel }) => ENUM_STRESS_LEVEL[stressLevel - 1]};
	}
`;

export const StressLevel = styled.div`
	margin-top: 0.25rem;
`;
