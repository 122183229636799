import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { Modal, UploadFile, notification } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/Application';
import { Context } from './index.types';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
	CompanyResponse,
	CreateOrganizationRequest,
	CreateRequest,
	DeleteOrganizationRequest,
	DeleteRequest,
	OrganizationDTO,
	OrganizationResponse,
	UpdateOrganizationRequest,
	UpdateRequest,
	UrlOrganizationFilter,
	ValidationError
} from './types';
import hooks from './hooks';

type ManageOrganizationProviderProps = {
	children: ReactNode;
};

const {
	useCreateOrganization,
	useUpdateOrganization,
	useDeleteOrganization,
	useCreate,
	useUpdate,
	useDelete,
	useGetOrganizations,
	useGetCompanies,
	useGetAllOrganizations
} = hooks;

const ManageOrganizationContext = React.createContext<Context>({} as Context);

export function ManageOrganizationProvider({ children }: ManageOrganizationProviderProps) {
	const [organizationId, setOrganizationId] = useState<string>('');
	const [companyId, setCompanyId] = useState<string>('');
	const [companyName, setCompanyName] = useState<string>('');
	const [sectorId, setSectorId] = useState<string>('');
	const [sectorName, setSectorName] = useState<string>('');
	const [workstationId, setWorkstationId] = useState<string>('');
	const [workstationName, setWorkstationName] = useState<string>('');
	const [organizations, setOrganizations] = useState<OrganizationDTO[]>([]);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isUrlLogoOrganization, setIsUrlLogoOrganization] = useState<boolean>(false);
	const [isUrlLogoCompany, setIsUrlLogoCompany] = useState<boolean>(false);
	const [isDisableSelect, setIsDisableSelect] = useState(true);
	const [organization, setOrganization] = useState<OrganizationDTO>({} as OrganizationDTO);
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const [queryParams, setQueryParams] = useState({} as UrlOrganizationFilter);

	const [maxPage, setMaxPage] = useState(10);
	const [page, setPage] = useState(0);

	const { data: allOrganizationsList } = useGetAllOrganizations();

	const {
		organization: localOrganization,
		setOrganization: setOrganizationContext,
		setCompany: setCompanyContext
	} = useApplicationContext();

	const { mutateAsync: manageOrganizationUpdate, isLoading: updatingOrganizationData } = useUpdate();
	const { mutateAsync: manageOrganizationDelete, isLoading: deletingOrganizationData } = useDelete();
	const { mutateAsync: manageOrganizationCreate, isLoading: creatingOrganizationData } = useCreate();
	const { mutateAsync: createOrganization } = useCreateOrganization();
	const { mutateAsync: updateOrganization } = useUpdateOrganization();
	const { mutateAsync: deleteOrganization } = useDeleteOrganization();

	const {
		data: organizationsList,
		refetch: organizationRefetch,
		isLoading: organizationsListLoading,
		isError: organizationsListError
	} = useGetOrganizations(maxPage, page, queryParams, localOrganization?.id);

	const { data: companiesList } = useGetCompanies(organizations[0]?.id || '');

	useEffect(() => {
		organizationRefetch();
	}, [page, queryParams]);

	useEffect(() => {
		if (organizations?.length > 0) {
			setOrganizationContext(organizationsList[0]);
			setCompanyContext(organizationsList[0].companies[0]);
		}
	}, [companiesList]);

	function handleOrganizationId(id: string): void {
		setOrganizationId(id);
	}

	function handleCompany(
		id: string,
		resetFields: (fields?: NamePath[] | undefined) => void,
		setFieldValue: (name: NamePath, value: any) => void
	) {
		resetFields(['sector_id', 'sector_name', 'workstation_id', 'workstation_name']);
		setFieldValue('company_name', '');
		setFieldValue('create_company_name', '');
		setFieldValue('company_id', id);
		setCompanyId(id);
		setSectorId('');
		setWorkstationId('');
	}

	function handleCompanyId(id: string): void {
		setCompanyId(id);
	}

	function handleCompanyName(name: string): void {
		setCompanyName(name);
	}

	function handleSector(
		id: string,
		resetFields: (fields?: NamePath[] | undefined) => void,
		setFieldValue: (name: NamePath, value: any) => void
	) {
		resetFields(['workstation_id', 'workstation_name']);
		setFieldValue('sector_name', '');
		setFieldValue('create_sector_name', '');
		setFieldValue('sector_id', id);
		setSectorId(id);
		setWorkstationId('');
	}

	function handleSectorId(id: string): void {
		setSectorId(id);
	}

	function handleSectorName(name: string): void {
		setSectorName(name);
	}

	function handleWorkstation(
		id: string,
		_: (fields?: NamePath[] | undefined) => void,
		setFieldValue: (name: NamePath, value: any) => void
	) {
		setFieldValue('workstation_name', '');
		setFieldValue('create_workstation_name', '');
		setFieldValue('workstation_id', id);
		setWorkstationId(id);
	}

	function handleWorkstationId(id: string): void {
		setWorkstationId(id);
	}

	function handleWorkstationName(name: string): void {
		setWorkstationName(name);
	}

	async function handleCreateOrganization(
		body: CreateOrganizationRequest
	): Promise<OrganizationResponse | undefined> {
		if (allOrganizationsList.find((elem) => elem.name === body.name)) {
			const content = I18n.get(`You will create a new organization with same name. Do you wish to proceed ?`);
			Modal.confirm({
				title: I18n.get('Warning!'),
				icon: <ExclamationCircleOutlined />,
				content: content,
				okType: 'danger',
				okText: I18n.get('Yes'),
				cancelText: I18n.get('Cancel'),
				onOk: async () => {
					return await createOrganization(body);
				}
			});
		} else {
			return await createOrganization(body);
		}
	}

	async function handleUpdateOrganization(
		body: UpdateOrganizationRequest
	): Promise<OrganizationResponse | undefined> {
		if (allOrganizationsList.find((elem) => elem.name === body.name)) {
			const content = I18n.get(`This name already exists. Do you wish to proceed ?`);
			Modal.confirm({
				title: I18n.get('Warning!'),
				icon: <ExclamationCircleOutlined />,
				content: content,
				okType: 'danger',
				okText: I18n.get('Yes'),
				cancelText: I18n.get('Cancel'),
				onOk: async () => {
					return await updateOrganization(body);
				}
			});
		} else {
			return await updateOrganization(body);
		}
	}

	async function handleDeleteOrganization(body: DeleteOrganizationRequest): Promise<OrganizationResponse> {
		const deleted = await deleteOrganization(body);
		if (localOrganization?.id === body.organization_id) {
			setIsLoading(true);
			organizationRefetch().then(({ data }) => {
				if (data) {
					setOrganizations(data);
					setIsLoading(false);
					window.location.reload();
				}
			});
		}
		return deleted;
	}

	async function createOrganizationData(body: CreateRequest): Promise<CompanyResponse> {
		return await manageOrganizationCreate(body);
	}

	async function updateOrganizationData(body: UpdateRequest): Promise<CompanyResponse> {
		return await manageOrganizationUpdate(body);
	}

	async function deleteOrganizationData(body: DeleteRequest): Promise<void> {
		await manageOrganizationDelete(body);
	}

	function treatFieldValidation(error: ValidationError) {
		if (error?.errorFields?.length > 0) {
			error.errorFields.forEach((error) => {
				notification.error({
					message: I18n.get(error.errors[0])
				});
			});
		} else {
			notification.error({
				message: I18n.get('Oops... Something went wrong')
			});
		}
	}

	function handleIsEditing(editing: boolean): void {
		setIsEditing(editing);
	}

	const contextValue: Context = {
		organizationId,
		companyId,
		companyName,
		sectorId,
		sectorName,
		workstationId,
		workstationName,
		updatingOrganizationData,
		deletingOrganizationData,
		creatingOrganizationData,
		organizationsList,
		isEditing,
		maxPage,
		page,
		queryParams,
		organizationsListError,
		organizationsListLoading,
		isLoading,
		isUrlLogoOrganization,
		isUrlLogoCompany,
		isDisableSelect,
		organization,
		fileList,
		setFileList,
		setOrganization,
		setIsDisableSelect,
		setIsUrlLogoOrganization,
		setIsUrlLogoCompany,
		setIsLoading,
		setQueryParams,
		setPage,
		setMaxPage,
		handleOrganizationId,
		handleCompany,
		handleCompanyId,
		handleCompanyName,
		handleSector,
		handleSectorId,
		handleSectorName,
		handleWorkstation,
		handleWorkstationId,
		handleWorkstationName,
		handleCreateOrganization,
		handleUpdateOrganization,
		handleDeleteOrganization,
		createOrganizationData,
		updateOrganizationData,
		deleteOrganizationData,
		treatFieldValidation,
		handleIsEditing,
		organizationRefetch
	};
	return <ManageOrganizationContext.Provider value={contextValue}>{children}</ManageOrganizationContext.Provider>;
}

export function useManageOrganization() {
	const context = useContext(ManageOrganizationContext);
	return context;
}
