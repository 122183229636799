import { ExhibitionSelect } from './Exhibition';
import { ProbabilitySelect } from './Probability';
import { ConsequenceSelect } from './Consequence';

const SelectOption = {
	ConsequenceSelect,
	ExhibitionSelect,
	ProbabilitySelect
};

export { SelectOption };
