import styled from 'styled-components';
import { Col, Divider, Modal, Row } from 'antd';

export const StepCardCol = styled(Col)`
	font-weight: 600;
	padding: 0.75rem 0;
	background: #2f54eb;
	border-radius: 0.5rem;

	span {
		color: #ffffff;
	}
`;

export const StepRowContainer = styled(Row)`
	background: #f9f9f9;
	border-radius: 0 0 1rem 1rem;
`;

export const StepKeyColContainer = styled(Col)`
	padding: 1rem 1rem 0 1rem;
`;

export const StepKeyCardRow = styled(Row)`
	height: 3rem;
	padding: 0.5rem 0;
	position: relative;
	background: #ffffff;
	margin-bottom: 1rem;
	border-radius: 0.7rem;
	border: 2px solid #e6e6e6;

	.ant-btn-link {
		span {
			color: inherit;
		}
	}

	span {
		color: #ffffff;
	}

	.ant-col {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const CustomDivider = styled(Divider)`
	min-height: 100%;
	border-left: 2px solid #f0f2f5;
`;

export const CustomDeleteButton = styled.button`
	top: 0;
	right: 0;
	width: 5%;
	height: 100%;
	cursor: pointer;
	position: absolute;
	background: #e74150;
	border: 2px solid #e6e6e600;
	border-radius: 0rem 0.5rem 0.5rem 0rem;

	:disabled {
		cursor: not-allowed;
		background: #d6d6d6;
	}

	transition: all 0.2s;
	:hover {
		filter: brightness(1.1);
		:disabled {
			filter: brightness(1);
		}
	}

	@media (max-width: 1024px) {
		width: 8%;
	}
`;

export const CustomModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 1.5rem;

		.ant-modal-close-x {
			width: 0;
			height: 0;
			line-height: 4.3rem;
			margin-right: 3.1rem;
			font-size: 0.8rem;
		}

		.ant-modal-body {
			padding: 0;
			height: 100%;
			display: flex;
			justify-content: center;
		}
	}
`;
