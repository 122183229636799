import { Title } from '@/components/Typography';
import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)`
	.ant-modal-content {
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		border-radius: 30px;
		background-color: #ffffff;
		flex-shrink: 0;
		padding-bottom: 50px;
		height: 481px;
		width: 598px;
	}
`;

export const TitleHeaderStyled = styled(Title)`
	padding: 34px 120px 0px 120px;
	color: #262626;
	text-align: center;
	font-weight: 600;
	width: 100%;
`;

export const SubTitleStyled = styled.span`
	color: #262626;
	font-weight: lighter;
	font-size: 22px;
	text-align: center;
	width: 100%;
`;

export const LabelStyled = styled.label`
	color: #909090;

	font-size: 15px;
`;
