import { useMutation } from '@tanstack/react-query';

import Api from '@/services/api';
import { AxiosErrorResponse, BaseContext } from '@/types';

type DownloadVideoDTO = BaseContext & {
	file_ids: string[];
};

async function downloadVideos(payload: DownloadVideoDTO): Promise<string[]> {
	const url = '/upload/bulk-create-url-download';
	const { data } = await Api.post<string[]>(url, payload);
	return data;
}

export const useBulkDownloadVideos = () => {
	return useMutation<string[], AxiosErrorResponse, DownloadVideoDTO>((payload) => downloadVideos(payload), {
		onSuccess: (videos) => {
			videos.forEach((data) => {
				let archive = document.createElement('a');
				archive.target = '_blank';
				archive.href = data;
				archive.click();
				archive.remove();
			});
		}
	});
};
