import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetSectors } from '@/hooks/useGetSectors';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';
import { PlaceholderText } from './styles';

const { useFormInstance, useWatch } = Form;

export function Sectors() {
	const form = useFormInstance();

	const fieldName = HierarchiesField.SECTOR;
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);
	const companyId = useWatch(HierarchiesField.COMPANY, form);

	const {
		data: sectors,
		isFetching: isGettingSectors,
		isError: errorGettingSectors
	} = useGetSectors(organizationId, companyId);

	function handleSelectSector(id: string) {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearSector() {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={fieldName} label={I18n.get('Sector')} labelCol={{ span: 24 }}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={sectors}
				loading={isGettingSectors}
				onClear={handleClearSector}
				onChange={handleSelectSector}
				placeholder={<PlaceholderText>Sector</PlaceholderText>}
				disabled={!companyId || !sectors || errorGettingSectors}
			/>
		</Form.Item>
	);
}
