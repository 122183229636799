import { Tag } from 'antd';
import styled, { DefaultTheme } from 'styled-components';

import { RiskScale } from '@/styles/enum';

type TagRiskProps = {
	$result: number;
	$isBrightBackground?: number;
};

export const TagRisk = styled(Tag)<TagRiskProps>`
	border-radius: 5px;
	background-color: ${({ $result, theme }) => setTagBackGroundColor(theme, $result)};
	span {
		color: ${({ $isBrightBackground }) => ($isBrightBackground ? 'black' : 'white')};
	}
`;

export const BoardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 450px;
	height: 100%;
	padding-bottom: 30px;
	padding-inline: 10px;
	border-inline: 1px dashed rgba(89, 89, 89, 0.3);
`;

export const Counter = styled.div`
	width: auto;
	min-width: 30px;
	height: 25px;
	background-color: #4156f2;
	color: white;
	border-radius: 5px;
	text-align: center;
	font-weight: bold;
	font-size: 0.8rem;
	line-height: 1.6rem;
	position: absolute;
	margin-right: 20px;
	margin-top: 28px;
	padding: 0 5px;
	right: 0px;
	top: 0px;
	cursor: default;
`;

export const ActionList = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0 10px 0 0;
	padding-left: 20px;

	@media (max-width: 1366px) {
		padding-left: 0px;
	}

	.infinite-scroll-component__outerdiv {
		width: 100%;
	}

	::-webkit-scrollbar {
		width: 5px;
		max-height: 100px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background-color: #4156f2;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 250px;
	max-width: 450px;
	width: 100%;
	height: 90px;
	padding: 10px;
	padding-right: 5px;
	padding-bottom: 5px;
	margin-block: 10px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0px 2px 2px 0px #00000040;

	@media (max-width: 1366px) {
		padding-right: 10px;
	}

	&:hover {
		box-shadow: 0px 4px 4px 0px #00000040;
	}
	transition: box-shadow ease 0.1s;
	&.overdue {
		background-color: #fff0f0;
	}
`;

export const DeleteCardBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	color: #b7babd;
	border-radius: 100%;
	cursor: pointer;
	background-color: transparent;
	box-shadow: none;
	border: 0.5px solid #d9d9d9;
	transition: background-color ease 0.15s;
	&:hover {
		background-color: #ffc3c2;
		color: #a8071a;
	}
`;

export const AddCardBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 30px;
	margin-bottom: 5px;
	font-size: 1rem;
	background-color: #f0f2f5;
	border-radius: 5px;
	cursor: pointer;
	border: none;
	box-shadow: 0px 2px 2px 0px #00000040;
	&:hover {
		box-shadow: 0px 4px 4px 0px #00000040;
	}
	transition: box-shadow ease 0.1s;
`;

export const CardTitle = styled.h3`
	font-size: 14px;
	font-weight: bold;
`;

export const CardDate = styled.p`
	margin: auto;
	text-align: center;
	font-size: 12px;
	&.overdue {
		font-weight: bold;
		color: #800000;
	}
`;

function setTagBackGroundColor(theme: DefaultTheme, $result: RiskScale) {
	if ($result === 0) {
		return theme.colors.riskScale[1];
	}
	return theme.colors.riskScale[$result] ?? theme.colors.riskScale[RiskScale.NOT_APPLICABLE];
}
