import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getActionPlans({ organizationId, companyId, actionPlansFilter, offset, limit, type }) {
	const url = actionPlansFilter
		? `/action_plan/?${actionPlansFilter}&board=${type}&offset=${offset}&limit=${limit}`
		: `/action_plan/?organization_id=${organizationId}&company_id=${companyId}&board=${type}&offset=${offset}&limit=${limit}`;
	const { data } = await Api.get(url);
	return data;
}

export function useActionPlans({ organizationId, companyId, actionPlansFilter, offset = 0, limit = 10, type }) {
	const actionPlans = useInfiniteQuery(
		['action_plans', organizationId, companyId, actionPlansFilter, type],
		async ({ pageParam = 0 }) =>
			getActionPlans({ organizationId, companyId, actionPlansFilter, offset: pageParam, limit, type }),
		{
			getNextPageParam: (lastPage) => {
				const nextPage = offset + 1;
				return nextPage * limit > lastPage.total_plans ? undefined : nextPage;
			}
		}
	);
	return actionPlans;
}

async function getTasks({ organizationId, actionPlanId }) {
	if (!organizationId || !actionPlanId) return [];
	let url = `/action_plan/${actionPlanId}/task?organization_id=${organizationId}`;

	const { data } = await Api.get(url);
	return data;
}

export function useTasks(payload) {
	const enabled = !!payload.organizationId && !!payload.actionPlanId;

	const tasks = useQuery(['tasks', payload], () => getTasks(payload), {
		enabled
	});
	return { tasks };
}

async function getHistory({ organizationId, actionPlanId }) {
	if (!organizationId || !actionPlanId) return [];
	let url = `/action_plan/history/${actionPlanId}?organization_id=${organizationId}`;

	const { data } = await Api.get(url);
	return data;
}

export function useHistory(payload) {
	const enabled = !!payload.organizationId && !!payload.actionPlanId;

	const history = useQuery(['action_plan_history', payload], () => getHistory(payload), {
		enabled
	});
	return { history };
}
