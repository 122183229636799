import { useMutation } from '@tanstack/react-query';
import { PayloadDownloadPDF } from './types';
import { Services } from './services';

const useDownloadPDF = () => {
	return useMutation((payload: PayloadDownloadPDF) => Services.downloadPDF(payload));
};

const useConsolidatePDF = () => {
	return useMutation((payload: PayloadDownloadPDF) => Services.consolidatePDF(payload));
};

export const Hooks = {
	useDownloadPDF,
	useConsolidatePDF
};
