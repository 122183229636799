import React from 'react';
import config from '@/config';
import { I18n } from '@aws-amplify/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Text } from '@/components/Typography';
import { useGetUserSingleSignOn } from './hooks';
import { setSession } from '@/redux/auth/actions';
import { useApplicationContext } from '@/context/Application';

const BASE_URL_API = config.api.url;
const REDIRECT_URL = BASE_URL_API + '/sso/login';

export const SingleSignOn: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { setUser } = useApplicationContext();
	const { isLoading, data, isError } = useGetUserSingleSignOn();

	const redirectToLogin = (): void => {
		history.replace(REDIRECT_URL);
	};

	if (data) {
		setUser(data);
		dispatch(setSession(data));
		history.push('/');
	}

	if (isLoading) {
		return <Text>{I18n.get('Loading')}...</Text>;
	}

	if (isError) {
		redirectToLogin();
	}

	return <Text>{I18n.get('Failed to load information')}</Text>;
};
