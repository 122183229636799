import React from 'react';
import { Row, Col } from 'antd';

import { LibertyMutualSVG } from '@/components/Icons';

export const LibertyMutualIcon: React.FC = () => (
	<Col xs={24}>
		<Row justify="center">
			<Col className="custom-icon" span={24}>
				<LibertyMutualSVG width={20} height={20} />
			</Col>
		</Row>
	</Col>
);
