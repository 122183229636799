import { Button, Col } from 'antd';
import styled from 'styled-components';

export const TitleCol = styled(Col)`
	margin-left: 0.5rem;
`;

export const CustomBackButton = styled(Button)`
	margin-top: 1rem;
	padding: 0 0.7rem;
	border-radius: 0.5rem;
	background-color: #cdd5f3;

	transition: all 0.2s;
	:hover {
		filter: brightness(0.9);
		background-color: #cdd5f3;
	}

	:focus {
		background-color: #cdd5f3 !important;
	}
`;
