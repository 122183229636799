import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { User } from '@/types';

interface Crendetials {
	user: User;
	token: string;
}

const QUERY_KEY = 'user-single-sign-on';

async function getUser(): Promise<Crendetials> {
	const { data } = await Api.get('/sso/me', { withCredentials: true });
	return data;
}

export const useGetUserSingleSignOn = () => {
	return useQuery([QUERY_KEY], () => getUser());
};
