import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { AuthService } from '@/services/authService';
import { Check2FARequest } from '@/types/AuthUser';

const authService = new AuthService();

function useCheck2FA() {
	const history = useHistory();

	return useMutation(({ data, user_id }: Check2FARequest) => authService.check(user_id, { data }), {
		onSuccess: () => {
			history.push('/');
		},
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
}

export { useCheck2FA };
