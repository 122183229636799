import React from 'react';
import { Input } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItem } from '../../../components/FormItem';

export const ReportName: React.FC = () => (
	<FormItem
		fontSize="1rem"
		title="Report name"
		isRequired={true}
		errorMessage={I18n.get('Insert the report name')}
		namePath={['basic_information', 'report_name']}
	>
		<Input size="large" placeholder={I18n.get('Report name')} />
	</FormItem>
);
