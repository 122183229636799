import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import {
	CreateCustomReport,
	CustomReportUpdateStepsSetting,
	CustomReportCreationStepsSetting,
	CustomReportUpdateStepKeysSetting,
	CustomReportCreationStepKeysSetting
} from '../hooks/types/request';
import { Card } from '../../Card';
import { CustomReport } from '@/types';
import { Preferences } from './Preferences';
import { CustomButton } from '../../styles';
import { FormItems } from '../context/types';
import { useCreateCustomReport } from '../hooks';
import { GeneralInformation } from './GeneralInformation';
import { SectionsAndCriteria } from './SectionsAndCriteria';
import { useCustomReportManagerContext } from '../../context';
import { useApplicationContext } from '@/context/Application';

const { useFormInstance } = Form;

type FormValuesPayload = {
	has_file: boolean;
	description: string;
	has_evaluator: boolean;
	has_work_conditions: boolean;
	has_characteristics: boolean;
	custom_report_score_scale_id: string;
	extended_description: string | undefined;
	steps_settings: CustomReportCreationStepsSetting[] | CustomReportUpdateStepsSetting[];
	step_keys_settings: CustomReportCreationStepKeysSetting[] | CustomReportUpdateStepKeysSetting[];
};

export const ReportInformation: React.FC = () => {
	const history = useHistory();
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { selectedSteps, selectedStepKeys } = useCustomReportManagerContext();

	const { mutateAsync: createCustomReport } = useCreateCustomReport();

	async function handleCreateClick(): Promise<void> {
		const {
			has_file,
			description,
			has_evaluator,
			has_work_conditions,
			has_characteristics,
			extended_description,
			custom_report_score_scale_id
		} = form.getFieldsValue(true) as FormItems;
		const steps_settings: CustomReportCreationStepsSetting[] = defineStepsSettingsForPayload();
		const step_keys_settings: CustomReportCreationStepKeysSetting[] = defineStepKeysSettinsForPayload();

		const payload: CreateCustomReport = definePayload({
			has_file,
			description,
			has_evaluator,
			steps_settings,
			step_keys_settings,
			has_work_conditions,
			has_characteristics,
			extended_description,
			custom_report_score_scale_id
		});

		const report = await createReport(payload);
		if (report?.id) {
			history.push('/settings/personalised-reports');
		}
	}

	function definePayload(formValues: FormValuesPayload): CreateCustomReport {
		return {
			...formValues,
			company_id: company?.id,
			organization_id: organization?.id,
			has_file: formValues.has_file ?? true,
			has_evaluator: formValues.has_evaluator ?? false,
			has_work_conditions: formValues.has_work_conditions ?? true,
			has_characteristics: formValues.has_characteristics ?? true,
			extended_description: formValues.extended_description ?? ''
		};
	}

	function defineStepKeysSettinsForPayload(): (
		| CustomReportCreationStepKeysSetting
		| CustomReportUpdateStepKeysSetting
	)[] {
		let stepKeySettings: (CustomReportCreationStepKeysSetting[] | CustomReportUpdateStepKeysSetting[])[] = [];

		for (const stepId in selectedStepKeys) {
			const step = selectedSteps.find(({ id }) => id === stepId);
			if (selectedStepKeys[stepId] && step) {
				const stepKey = selectedStepKeys[stepId];
				const stepKeySetting = stepKey.map(({ id, sequence, step_setting_id }) => ({
					sequence,
					id: step_setting_id,
					custom_report_step_key_id: id,
					step_sequence: step?.sequence
				}));
				stepKeySettings.push(stepKeySetting);
			}
		}
		return stepKeySettings.flat();
	}

	function defineStepsSettingsForPayload(): CustomReportCreationStepsSetting[] | CustomReportUpdateStepsSetting[] {
		return selectedSteps.map(({ id, sequence, step_setting_id }) => ({
			sequence,
			id: step_setting_id,
			custom_report_step_id: id
		}));
	}

	async function createReport(body: CreateCustomReport): Promise<CustomReport> {
		return await createCustomReport(body);
	}

	return (
		<Row justify="space-between" align="middle">
			<Col span={24}>
				<Card title="Summary">
					<Row align="top" justify="space-between" gutter={[16, 16]}>
						<Col xs={24} lg={12}>
							<GeneralInformation />
						</Col>
						<Col xs={24} lg={12}>
							<Preferences />
						</Col>
					</Row>
					<Row align="middle" justify="space-between" gutter={[16, 16]}>
						<Col span={24}>
							<SectionsAndCriteria />
						</Col>
						<Col span={24} style={{ textAlign: 'center' }}>
							<CustomButton type="primary" htmlType="submit" onClick={handleCreateClick}>
								{I18n.get('Create')}
							</CustomButton>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
};
