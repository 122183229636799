import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input, Row } from 'antd';

export const Description: React.FC = () => (
	<Col sm={6}>
		<Row>
			<Col xs={24}>
				<Form.Item labelCol={{ span: 24 }} name={['description']} label={I18n.get('Report name')}>
					<Input placeholder={I18n.get('Report name')} />
				</Form.Item>
			</Col>
		</Row>
	</Col>
);
