import React, { useContext } from 'react';

import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import { MergeAEPContext } from '../../context';

export const Analyst: React.FC = () => {
	const { organizationId, companyId, setAnalyst, queryParams, setQueryParams } = useContext(MergeAEPContext);

	function handleAnalystChange(analyst: string) {
		setAnalyst(analyst);
		setQueryParams({
			...queryParams,
			analyst
		});
	}

	return (
		<Form.Item name="analyst">
			<Input
				disabled={!organizationId || !companyId}
				placeholder={I18n.get('Analyst')}
				onChange={(event) => handleAnalystChange(event.target.value)}
			/>
		</Form.Item>
	);
};
