import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { CustomReportStepKeyResult, Response } from '@/types';
import { customReportService } from '../../../../hooks/services';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type SubStepKeyResult = {
	score: number | null;
	result: number | null;
	injuries?: string[] | null;
	exposure_id: string | null;
	consequence_id: string | null;
	probability_id: string | null;
	custom_report_sub_step_key_id?: string;
	sub_step_key_result_id?: string | null;
	custom_report_step_key_setting_id?: string | null;
};

export type GenericStepKeyResult = {
	id?: string;
	score: number | null;
	result: number | null;
	injuries?: string[] | null;
	exposure_id: string | null;
	consequence_id: string | null;
	probability_id: string | null;
	step_key_result_id?: string | null;
	sub_step_keys_results?: SubStepKeyResult[];
	custom_report_step_key_setting_id?: string | null;
};

export type CreateGenericStepKeyResultDTO = BaseContext & {
	step_setting_id?: string;
	custom_report_id: string;
	custom_report_result_id?: string;
	step_keys_results: GenericStepKeyResult[];
};

export type PersonalisedReportGenericStepKeyResultCreateResponse = CustomReportStepKeyResult;

async function createGenericStepKeyResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/step-key';
	const { data } = await Api.post<T>(url, payload);
	return data;
}

export function useCreateGenericStepKeyResult() {
	return useMutation<
		PersonalisedReportGenericStepKeyResultCreateResponse[],
		AxiosError<Response>,
		CreateGenericStepKeyResultDTO
	>(
		(body) =>
			createGenericStepKeyResult<
				PersonalisedReportGenericStepKeyResultCreateResponse[],
				CreateGenericStepKeyResultDTO
			>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message);
			}
		}
	);
}
