import styled from 'styled-components';

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`;

export const Icon = styled.div`
	display: inline-block;
`;
