import { I18n } from '@aws-amplify/core';
import React, { useState } from 'react';

import { Field, Information } from '../../styles';
import { ModalForm } from './Modal';

export const ChangePassword: React.FC = () => {
	const [visible, setVisible] = useState<boolean>(false);

	function handleChangePassword(): void {
		setVisible(!visible);
	}

	return (
		<>
			<Field>{I18n.get('Password')}</Field>
			<Information>************</Information>
			<a onClick={handleChangePassword} style={{ marginTop: '20px' }}>
				{I18n.get('Change password')}
			</a>
			<ModalForm visible={visible} onCloseModal={handleChangePassword} />
		</>
	);
};
