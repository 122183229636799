import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';

import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';

import { ClearFilter } from './styles';
import { DashboardCard } from '../styles';
import { FilterByCellule } from './FilterByCellule';
import { FilterBySector } from './FilterBySector';
import { FilterByStressLevel } from './FilterByStressLevel';
import { FilterByWorkstation } from './FilterByWorkstation';
import { useDashboardContext } from '../context';
import hooks from '@/components/Settings/Organizations/hooks';
import { useApplicationContext } from '@/context/Application';

const { useForm } = Form;
const { useGetWorkstations } = hooks;

export const FilterCard: React.FC = () => {
	const { handleFilterSelection, handleClearFilters } = useDashboardContext();
	const { organization, company } = useApplicationContext();

	const [form] = useForm();
	const [sectorId, setSectorId] = useState('');
	const [workstation, setWorkstation] = useState('');
	const [celluleName, setCelluleName] = useState('');
	const [clearFilter, setClearFilter] = useState(false);
	const [stressLevel, setStressLevel] = useState<number[]>([]);

	const { data, isLoading, isError, error } = useGetWorkstations(organization.id, company.id, sectorId);

	function handleFormValuesChange(changedValues: any) {
		const formFieldName = Object.keys(changedValues)[0];

		if (formFieldName === 'sector') {
			setSectorId(changedValues[formFieldName]);
		}
		if (formFieldName === 'cellule') {
			setCelluleName(changedValues[formFieldName]);
		}
		if (formFieldName === 'workstation') {
			setWorkstation(changedValues[formFieldName]);
		}
	}

	const workstations = { data, isLoading, isError, error };

	function handleSectorChange(sector: string) {
		setSectorId(sector);
		setWorkstation('');
		form.resetFields(['workstation']);
	}

	function handleCelluleChange(cellule: string) {
		setCelluleName(cellule);
	}

	function handleWorkstationChange(workstationChanged: string) {
		setWorkstation(workstationChanged);
	}

	function handleSelectStressLevel(level: number) {
		setClearFilter(false);
		const selected = stressLevel.includes(level) ? removeStressLevel(level) : addStressLevel(level);
		setStressLevel(selected);
	}

	function addStressLevel(level: number) {
		const levels = [...stressLevel];
		levels.push(level);

		return levels;
	}

	function removeStressLevel(level: number) {
		const levels = [...stressLevel];
		const index = levels.indexOf(level);
		levels.splice(index, 1);

		return levels;
	}

	function resetStates() {
		form.resetFields();
		setSectorId('');
		setCelluleName('');
		setWorkstation('');
		setStressLevel([]);
		handleClearFilters();
		setClearFilter(true);
	}

	function handleFilter() {
		handleFilterSelection({
			celluleName,
			sectorId,
			stressLevel,
			workstation
		});
	}

	return (
		<DashboardCard>
			<Form onValuesChange={handleFormValuesChange} form={form} layout="vertical" style={{ minHeight: '500px' }}>
				<Row justify="end">
					<Col>
						<ClearFilter onClick={resetStates}>{I18n.get('Clear')}</ClearFilter>
					</Col>
				</Row>
				<Row gutter={[8, 12]}>
					<Col span={24}>
						<FilterBySector onSectorChange={handleSectorChange} />
					</Col>
					<Col span={24}>
						<FilterByWorkstation
							onWorkstationChange={handleWorkstationChange}
							workstations={workstations}
						/>
					</Col>
					<Col span={24}>
						<FilterByCellule onCelluleChange={handleCelluleChange} />
					</Col>
					<Col span={24}>
						<FilterByStressLevel onSelectStressLevel={handleSelectStressLevel} clearFilter={clearFilter} />
					</Col>
					<Col span={24}>
						<Button
							disabled={!celluleName && !sectorId && stressLevel.length === 0 && !workstation}
							onClick={handleFilter}
							icon={<SearchOutlined />}
							type="primary"
						>
							{I18n.get('Search')}
						</Button>
					</Col>
				</Row>
			</Form>
		</DashboardCard>
	);
};
