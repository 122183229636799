import React from 'react';
import { Col, Divider, Row } from 'antd';

import { Title } from '@/components/Typography';

import { DataType } from '../Steps';
import { TopicDescription } from './TopicDescription';

type StepsDescriptionProps = {
	stepName: string;
	topics: DataType[];
};

export const StepsDescription: React.FC<StepsDescriptionProps> = ({ stepName, topics }) => {
	return (
		<Row gutter={[0, 8]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={3}>
						{stepName}
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row gutter={[0, 8]} style={{ marginLeft: '0.5rem' }}>
					<Col span={1}>
						<Divider
							type="vertical"
							style={{
								margin: '0 0 0 0.7rem',
								borderLeft: '4px solid #e6e8e8',
								height: '100%'
							}}
						/>
					</Col>
					{topics?.map((topic: any, index: number) => (
						<TopicDescription
							key={index}
							score={topic.score}
							topic={topic.description}
							riskScale={topic.risk_scale}
							hasDivider={topic.id !== topics.length}
							consequence={topic.consequence}
							probability={topic.probability}
							exhibition={topic.exhibition}
							injuries={topic.injuries}
						/>
					))}
				</Row>
			</Col>
		</Row>
	);
};
