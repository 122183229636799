import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { AxiosErrorResponse, BaseReportsPaginated, BaseReportsPaginatedResponse, PaginatedData } from '@/types';

export type ListAllRulaDTO = BaseReportsPaginated;

export type ListAllRulaResponse = PaginatedData<BaseReportsPaginatedResponse>;

async function listRulaPaginated(params: ListAllRulaDTO): Promise<ListAllRulaResponse> {
	const url = '/ergonomic-tool/rula/reports/list-all';
	const { data } = await Api.get<ListAllRulaResponse>(url, {
		params
	});
	return data;
}

export const useListRulaPaginated = ({
	limit,
	offset,
	sector_id,
	company_id,
	cellule_id,
	report_name,
	activity_id,
	evaluator_id,
	original_name,
	created_at_end,
	workstation_id,
	organization_id,
	created_at_start,
	isSelectedReport
}: ListAllRulaDTO) => {
	const enabled = !!limit && !!isSelectedReport && offset >= 0;
	const rebaReport = useQuery<ListAllRulaResponse, AxiosErrorResponse>(
		[
			QUERY_KEYS.GET_RULA_REPORTS_PAGINATED,
			[
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				activity_id,
				evaluator_id,
				original_name,
				created_at_end,
				workstation_id,
				organization_id,
				created_at_start
			]
		],
		() =>
			listRulaPaginated({
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				activity_id,
				evaluator_id,
				original_name,
				created_at_end,
				workstation_id,
				organization_id,
				created_at_start
			}),
		{
			enabled,
			retry: 0
		}
	);
	return rebaReport;
};
