import React from 'react';
import { Col, Row } from 'antd';

import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';

import { Body } from './Body';
import { Header } from './Header';
import { Anthropometry } from '../types';

type AnthropometryTableProps = {
	anthropometries: Anthropometry[] | undefined;
};

export const AnthropometryTable: React.FC<AnthropometryTableProps> = ({ anthropometries }) => {
	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={3}>
						{I18n.get('Anthropometry Table')}
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Header />
				<Body data={anthropometries} />
			</Col>
		</Row>
	);
};
