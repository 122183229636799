import React from 'react';
import { Col, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomFormItem } from '../../styles';

export function ExtendedDescription() {
	return (
		<Col span={24}>
			<CustomFormItem name={['extended_description']} label={I18n.get('Description')} labelCol={{ span: 24 }}>
				<Input.TextArea size="large" rows={4} showCount maxLength={500} />
			</CustomFormItem>
		</Col>
	);
}
