import { IRemoveImage, ISendImage, ISendlogoURL, SendImageDTO } from './types';
import Api from '@/services/api';

enum RequestType {
	SEND = 'send',
	REMOVE = 'remove'
}

const CONTENT_TYPE = { MULTIPART: 'multipart/form-data' };

export async function sendImage(payload: ISendImage) {
	const { formData, organizationId, companyId, type } = payload;

	const url = setUrlType({ type, organizationId, companyId, requestType: RequestType.SEND });

	const { data } = await Api.post<SendImageDTO>(url, formData, {
		headers: {
			'Content-Type': CONTENT_TYPE.MULTIPART
		},
		params: {
			organization_id: organizationId,
			company_id: companyId
		}
	});
	return data;
}

export async function removeImage({ organizationId, companyId, type }: IRemoveImage) {
	const url = setUrlType({ type, organizationId, companyId, requestType: RequestType.REMOVE });
	const { data } = await Api.delete<SendImageDTO>(url, {
		params: {
			organization_id: organizationId,
			company_id: companyId
		}
	});
	return data;
}

export function setUrlType(payload: ISendlogoURL) {
	const { type } = payload;

	const type_mapper = {
		organization: `logo-organization`,
		company: `logo-company`,
		customer: `logo-customer`
	};
	return `upload/${type_mapper[type]}`;
}
