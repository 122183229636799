import React, { createContext, ReactNode, useContext } from 'react';

import { useCustomReportManagerContext } from '../../context';
import { Context, Methods, States, StepKey, StepList } from './types';

type CustomReportManagerSummaryProviderProps = {
	children: ReactNode;
};

const CustomReportManagerSummaryContext = createContext<Context>({} as Context);

export function CustomReportManagerSummaryProvider({ children }: Readonly<CustomReportManagerSummaryProviderProps>) {
	const { selectedSteps, selectedStepKeys, handleSelectedSteps, handleSelectedStepKeys } =
		useCustomReportManagerContext();

	function removeStepFromStepList(step: StepList): void {
		if (isTheOnlyStepLeft()) {
			return;
		}
		const stepsListCopy = [...selectedSteps];
		const objectIndexToRemove = stepsListCopy.findIndex(({ id }) => step.id === id);
		stepsListCopy.splice(objectIndexToRemove, 1);
		const mappedSteps = mapStepsSequence(stepsListCopy);
		handleSelectedSteps(mappedSteps);
	}

	function removeStepKeyFromStepKeyList(stepKey: StepKey, stepId: string): void {
		const stepKeysListCopy = { ...selectedStepKeys };
		const stepKeys = selectedStepKeys?.[stepId];
		if (!stepKeys) {
			return;
		}
		if (isTheOnlyStepLeft() && isTheOnlyStepKeyLeft(stepKeys)) {
			return;
		}
		if (isTheOnlyStepKeyLeft(stepKeys)) {
			const stepToRemove = selectedSteps.find(({ id }) => id === stepId);
			if (stepToRemove) {
				removeStepFromStepList(stepToRemove);
			}
		}
		const objectIndexToRemove = stepKeys.findIndex(({ id }) => stepKey.id === id);
		stepKeys.splice(objectIndexToRemove, 1);
		const mappedStepKeys = mapStepKeysSequence(stepKeys);
		stepKeysListCopy[stepId] = mappedStepKeys;
		handleSelectedStepKeys(stepKeysListCopy);
	}

	function isTheOnlyStepLeft(): boolean {
		return selectedSteps.length === 1;
	}

	function isTheOnlyStepKeyLeft(stepKeys: StepKey[]): boolean {
		return stepKeys.length === 1;
	}

	function mapStepsSequence(stepsListCopy: StepList[]): StepList[] {
		return stepsListCopy.map((step, index) => ({
			...step,
			sequence: index + 1
		}));
	}

	function mapStepKeysSequence(stepKeys: StepKey[]): StepKey[] {
		return stepKeys.map((step, index) => ({
			...step,
			sequence: index + 1
		}));
	}

	const states: States = {};

	const methods: Methods = {
		isTheOnlyStepLeft,
		isTheOnlyStepKeyLeft,
		removeStepFromStepList,
		removeStepKeyFromStepKeyList
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<CustomReportManagerSummaryContext.Provider value={context}>
			{children}
		</CustomReportManagerSummaryContext.Provider>
	);
}

export function useCustomReportManagerSummaryContext() {
	const context = useContext(CustomReportManagerSummaryContext);
	return context;
}
