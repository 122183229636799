import styled, { css } from 'styled-components';
import { Col } from 'antd';

export const Container = styled(Col)`
	padding: 0.8rem;
`;

export const Title = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
`;

export const Value = styled.span<{ isBold: boolean }>`
	font-weight: 400;
	margin: 0.5rem 0;
	font-size: 0.8rem;

	${(props) =>
		props.isBold &&
		css`
			font-weight: 600;
			font-size: 1.2rem;
		`}
`;
