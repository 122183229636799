import { Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout.Content)`
	margin: 24px 16px;
	min-height: 280px;
	padding: 24px;
`;

export const Component = styled.div`
	position: relative;
	min-height: 70vh;
	padding: 10px;
`;
