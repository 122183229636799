import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Response, User } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type GetUsersDTO = BaseContext;
export type UserResponse = User;

async function getUsers({ organization_id, company_id }: GetUsersDTO) {
	const url = `/user/user-list/${organization_id}/${company_id}`;
	const { data } = await Api.get<UserResponse[]>(url);
	return data;
}

export function useGetUsers({ company_id, organization_id }: GetUsersDTO) {
	const users = useQuery<UserResponse[], AxiosError<Response>>(
		['responsible_users', { company_id, organization_id }],
		() => getUsers({ company_id, organization_id }),
		{
			retry: 0,
			enabled: !!company_id && !!organization_id
		}
	);
	return {
		...users,
		data: users.data ?? ([] as UserResponse[])
	};
}
