import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { Generate2FARequest, ResendTokenRequest, Validate2FARequest } from '@/types/AuthUser';
import { AuthService } from '@/services/authService';

const authService = new AuthService();

function useGenerate2FA() {
	return useMutation(({ user_id, type }: Generate2FARequest) => authService.generate2FA(user_id, type), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Two-factor authentication generated successfully'));
		}
	});
}

function useValidate2FA() {
	return useMutation(({ user_id, data }: Validate2FARequest) => authService.validate2FA(user_id, { data }), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Two-factor authentication validated successfully'));
		}
	});
}

function useResendToken() {
	return useMutation(({ user_id, data }: ResendTokenRequest) => authService.resendToken(user_id, { data }), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Token resent successfully'));
		}
	});
}

export { useGenerate2FA, useValidate2FA, useResendToken };
