import React from 'react';
import { Col, Row } from 'antd';

import { QrcodeOutlined } from '@ant-design/icons';
import { TFATips } from '@/types/AuthUser';

import { IconContainer, Tip } from './styles';

export const GenerateAuthApp2FA = () => {
	return (
		<Col>
			<IconContainer justify="center">
				<QrcodeOutlined />
			</IconContainer>
			<Row justify="center" style={{ marginBottom: '7.5rem' }}>
				<Tip style={{ width: '60%' }}>{TFATips.TIP_APP}</Tip>
			</Row>
		</Col>
	);
};
