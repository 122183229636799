import { ToolsNames } from '@/types';
import { ToolType } from '@/components/ToolCard/types';
import { IReportState, Tools } from '@/hooks/useSocket';
import { InformationsResponse } from '../../../../hooks';
import { ActionPlanWithTaskResponse, CalculateRiskResponse } from '../hooks';
import { AntdDropdownOption, DropdownOptions, ErgonomicToolResponse } from '../../../../context/types';

export const ergonomicToolReportSocketMapper: { [key in Tools]: ToolsNames } = {
	rula: ToolsNames.rula,
	reba: ToolsNames.reba,
	niosh: ToolsNames.niosh,
	kimMHO: ToolsNames.kim_mho,
	kimPushPull: ToolsNames.kim_pp,
	angleTime: ToolsNames.angle_time,
	strainIndex: ToolsNames.strain_index,
	libertyMutual: ToolsNames.liberty_mutual
};

export const ergonomicToolReportMapper: { [key in ToolsNames]: Tools } = {
	[ToolsNames.rula]: Tools.rula,
	[ToolsNames.reba]: Tools.reba,
	[ToolsNames.niosh]: Tools.niosh,
	[ToolsNames.kim_mho]: Tools.kimMHO,
	[ToolsNames.kim_pp]: Tools.kimPushPull,
	[ToolsNames.angle_time]: Tools.angleTime,
	[ToolsNames.strain_index]: Tools.strainIndex,
	[ToolsNames.liberty_mutual]: Tools.libertyMutual
};

export type States = {
	stepId: string;
	checked: boolean;
	stepKeyId: string;
	hasInjury: boolean;
	riskLevelData: {
		gettingRisk: boolean;
		errorCalculatingRisk: boolean;
		levelRisk: CalculateRiskResponse;
	};
	actionPlanData: {
		gettingActionPlan: boolean;
		errorGettingActionPlan: boolean;
		actionPlan?: ActionPlanWithTaskResponse;
	};
	stepKeySettingId: string;
	stepKeyFieldName: string[];
	ergonomicTools: ErgonomicToolResponse[];
};

export type Methods = {
	handleCreateTool(toolName: ToolType): Promise<void>;
	mapErgonomicTools(tools: IReportState, informations?: InformationsResponse): IReportState;
	mapStepKeyDropdownOptions(options: DropdownOptions): AntdDropdownOption[];
};

export type Context = States & Methods;
