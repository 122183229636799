import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_COMPANY_CONTRACT_PLANS } = QUERY_KEYS;

async function getOrganization(organization_id: string | null): Promise<any> {
	const url = `/contract-plan/organization/${organization_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetOrganization = (organization_id: string | null) => {
	return useQuery([GET_COMPANY_CONTRACT_PLANS, { organization_id }], () => getOrganization(organization_id), {
		enabled: !!organization_id
	});
};
