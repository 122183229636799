import { removeImage, sendImage } from './services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IErrorSendImage, ImageType } from './types';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';

export const useSendImage = (organizationId: string, companyId: string | undefined, type: ImageType) => {
	const queryClient = useQueryClient();
	return useMutation((formData: FormData) => sendImage({ formData, organizationId, companyId, type }), {
		onError(error: IErrorSendImage) {
			notification.error({
				message: `${I18n.get('Ops... something happened!')}`,
				description: `${I18n.get(error.response.data.message)}`
			});
		},
		onSuccess() {
			Promise.all([
				queryClient.invalidateQueries(['organizations']),
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['account-information'])
			]);
			notification.success({
				message: `${I18n.get('Uploaded picture')}`,
				description: `${I18n.get('Image successfully added')}`
			});
		}
	});
};

export const useDeleteImage = (organizationId: string, companyId: string | undefined, type: ImageType) => {
	const queryClient = useQueryClient();
	return useMutation(() => removeImage({ organizationId, companyId, type }), {
		onError: (error: IErrorSendImage) => {
			notification.error({
				message: `${I18n.get('Ops... something happened!')}`,
				description: `${I18n.get(error.response.data.message)}`
			});
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['account-information'])
			]);
			notification.success({
				message: `${I18n.get('Changed picture')}`,
				description: `${I18n.get('Image successfully changed')}`
			});
		}
	});
};
