import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Description, DetailTitle, Details, Score } from './styles';

interface PushPullProps {
	percentile_man_initial?: string;
	percentile_man_sustain?: string;
	percentile_woman_initial?: string;
	percentile_woman_sustain?: string;
}

export const PushPull: React.FC<PushPullProps> = ({
	percentile_man_initial,
	percentile_man_sustain,
	percentile_woman_initial,
	percentile_woman_sustain
}) => (
	<Row style={{ height: '100%' }}>
		<Col xs={12} style={{ paddingLeft: '1rem' }}>
			<Details>
				<Row gutter={[0, 20]}>
					<Col xs={24}>
						<DetailTitle>{I18n.get('Man')}</DetailTitle>
					</Col>
					<Col xs={24}>
						<Row align="middle">
							<Col xs={12}>
								<Row align="middle">
									<Col xs={24}>
										<Description style={{ fontSize: '1rem' }}>{I18n.get('Initial')}</Description>
									</Col>
									<Score>{percentile_man_initial}</Score>
								</Row>
							</Col>
							<Col xs={12}>
								<Row align="middle">
									<Col xs={24}>
										<Description style={{ fontSize: '1rem' }}>{I18n.get('Sustain')}</Description>
									</Col>
									<Score>{percentile_man_sustain}</Score>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: '0 0 -28px 0', height: '100%' }} type="vertical" />
		<Col xs={11} style={{ paddingLeft: '1rem' }}>
			<Details>
				<Row gutter={[0, 20]}>
					<Col xs={24}>
						<DetailTitle>{I18n.get('Woman')}</DetailTitle>
					</Col>
					<Col xs={24}>
						<Row align="middle">
							<Col xs={12}>
								<Row align="middle">
									<Col xs={24}>
										<Description style={{ fontSize: '1rem' }}>{I18n.get('Initial')}</Description>
									</Col>
									<Score>{percentile_woman_initial}</Score>
								</Row>
							</Col>
							<Col xs={12}>
								<Row align="middle">
									<Col xs={24}>
										<Description style={{ fontSize: '1rem' }}>{I18n.get('Sustain')}</Description>
									</Col>
									<Score>{percentile_woman_sustain}</Score>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Details>
		</Col>
	</Row>
);
