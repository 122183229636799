import React, { useState, useCallback } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Row, Col, Modal, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';

import { Sidebar } from './Sidebar';
import { TaskManager } from './TaskManager';
import { Description } from './Description';
import Spinner from '@/components/Layout/Spinner';
import { TitleContainer, EditButton } from './styles';

export function EditActionPlan({ actionPlan, responsible_users, visible, onClose, updateActionPlan }) {
	const [titleInput, setTitleInput] = useState('');
	const [editingTitle, setEditingTitle] = useState(false);
	const [reloadHistory, setReloadHistory] = useState(false);
	const [loadingHistory, setLoadingHistory] = useState(false);

	const organization = useSelector(({ organization }) => organization.organization);

	const triggerReloadHistory = () => {
		setReloadHistory((prev) => !prev);
	};

	const onEditTitle = () => {
		setTitleInput(I18n.get(actionPlan.title));
		setEditingTitle(true);
	};

	const onFinishEditing = async () => {
		const action_plan = { ...actionPlan, title: titleInput };
		const body = { action_plan, organizationId: organization?.id };

		updateActionPlan({ body }).then(() => {
			actionPlan.title = titleInput;
			setEditingTitle(false);
			triggerReloadHistory();
			setTitleInput('');
		});
	};

	const handleChange = (e) => {
		setTitleInput(e.target.value);
	};

	const editInputRef = useCallback((node) => {
		if (node) {
			node.focus();
		}
	}, []);

	return (
		<Modal
			style={{ top: 20 }}
			open={visible}
			destroyOnClose={true}
			onCancel={onClose}
			width="1000px"
			title={
				<TitleContainer>
					{editingTitle ? (
						<Input
							ref={editInputRef}
							style={{ fontSize: '1.4rem' }}
							value={titleInput}
							onChange={handleChange}
							onPressEnter={onFinishEditing}
							onBlur={onFinishEditing}
						></Input>
					) : (
						<>
							<h2 style={{ margin: '0px' }}>{I18n.get(actionPlan.title)}</h2>
							<EditButton onClick={onEditTitle}>
								<EditOutlined />
							</EditButton>
						</>
					)}
				</TitleContainer>
			}
			footer={null}
		>
			<Row style={{ height: 'auto' }}>
				<Col span={14}>
					<Description
						actionPlan={actionPlan}
						updateActionPlan={updateActionPlan}
						triggerReloadHistory={triggerReloadHistory}
					/>
					<TaskManager actionPlan={actionPlan} triggerReloadHistory={triggerReloadHistory} />
				</Col>
				<Col span={10}>
					{loadingHistory && <Spinner />}
					<Sidebar
						actionPlan={actionPlan}
						responsible_users={responsible_users}
						reloadHistory={reloadHistory}
						triggerReloadHistory={triggerReloadHistory}
						loadingHistory={loadingHistory}
						setLoadingHistory={setLoadingHistory}
						updateActionPlan={updateActionPlan}
					/>
				</Col>
			</Row>
		</Modal>
	);
}
