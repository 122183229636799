import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetThumbnailParams {
	organization_id: string;
	company_id: string;
	file_id: string;
}

interface GetThumbnailUrlResult {
	url: string;
}

async function getThumbnailURL({ file_id, ...params }: GetThumbnailParams): Promise<GetThumbnailUrlResult> {
	const url = `/upload/create-thumbnail-url/${file_id}`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetThumbnailURL = (params: GetThumbnailParams) => {
	return useQuery([QUERY_KEYS.GET_THUMBNAIL_URL, params], () => getThumbnailURL(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.file_id,
		select: (data) => data.url
	});
};
