import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';

import { Cellule } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import { CreateCelluleRequest } from '@/components/ui/Inputs/types/request/cellule';

export const useCreateCellule = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateCelluleRequest) => Api.post<Cellule>('/cellule', body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_CELLULES]);
			message.success(I18n.get('Activity created successfully'));
		}
	});
};
