import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Modal, Input, Form, Button, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import React from 'react';

import { Title } from '@/components/Typography';
import { useUser } from '@/hooks/useUser';
import { Utils } from '@/utils/index';

interface ModalFormProps {
	visible: boolean;
	onCloseModal: () => void;
}

export const ModalForm: React.FC<ModalFormProps> = ({ visible, onCloseModal }) => {
	const [form] = Form.useForm();
	const { changePassword } = useUser();

	function handleClose() {
		onCloseModal();
		form.resetFields();
	}

	async function handleSubmit() {
		const { current_password, password } = await form.validateFields();
		const parameters = { currentPassword: current_password, newPassword: password };
		changePassword.mutateAsync(parameters, {
			onSuccess: (data) => {
				handleClose();
				message.success(I18n.get(data.message));
			},
			onError: (error: any) => {
				const exception = error.response?.data || error;
				const msg = exception.details[0] || exception.message;
				message.error(I18n.get(msg));
			}
		});
	}

	function handleBlockingEvent(e: React.FormEvent<HTMLInputElement>) {
		e.preventDefault();
		return false;
	}

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			footer={[
				<Button key="cancel" onClick={handleClose}>
					{I18n.get('Cancel')}
				</Button>,
				<Button key="save" type="primary" onClick={handleSubmit} loading={changePassword.isLoading}>
					{I18n.get('Save')}
				</Button>
			]}
		>
			<Form form={form} layout="vertical">
				<Title level={4} style={{ paddingBottom: '1rem' }}>
					Change password
				</Title>
				<Form.Item
					label={I18n.get('Current password')}
					name="current_password"
					hasFeedback
					rules={[
						{
							required: true,
							message: I18n.get('Enter your current password')
						}
					]}
				>
					<Input.Password
						placeholder={I18n.get('Enter your current password')}
						iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
					/>
				</Form.Item>
				<Form.Item
					label={I18n.get('New password')}
					name="password"
					hasFeedback
					rules={[
						() => ({
							validator(_, value) {
								return Utils.checkingPassword(value);
							}
						})
					]}
				>
					<Input.Password
						placeholder={I18n.get('Enter your new password')}
						iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
					/>
				</Form.Item>
				<Form.Item
					label={I18n.get('Confirm password')}
					name="password_confirm"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: I18n.get('Repeat your new password')
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(I18n.get("The two passwords don't match, check"));
							}
						})
					]}
				>
					<Input.Password
						placeholder={I18n.get('Confirm new password')}
						iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
						autoComplete="off"
						onCopy={handleBlockingEvent}
						onDrag={handleBlockingEvent}
						onDrop={handleBlockingEvent}
						onPaste={handleBlockingEvent}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};
