import React from 'react';
import { Col, Form, Row } from 'antd';

import { Card } from '../../Card';
import { useCreateStep } from '../hooks';
import { Description } from './Description';
import { useCustomReportManagerStepContext } from '../context';
import { CustomButton, CustomPlusCircleFilled } from './styles';
import { useApplicationContext } from '@/context/Application';

const { useFormInstance, useWatch } = Form;

export const CreateStep: React.FC = () => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { addStepToStepList } = useCustomReportManagerStepContext();
	const description = useWatch(['step', 'description'], form);

	const { isLoading, mutateAsync: createStep } = useCreateStep();

	async function handleClick(): Promise<void> {
		if (!description) {
			return;
		}
		const created = await createStep({
			description,
			company_id: company?.id,
			organization_id: organization?.id
		});
		if (created?.id && created?.description) {
			form.setFieldValue(['step', 'description'], undefined);
			addStepToStepList({
				id: created.id,
				description: created.description
			});
		}
	}

	// return (
	// 	<Card title="Create section">
	// 		<Row justify="space-between" align="middle">
	// 			<Col xs={14} md={20}>
	// 				<Description creating={isLoading} />
	// 			</Col>
	// 			<Col xs={6} md={2}>
	// 				<CustomButton type="submit" onClick={handleClick} disabled={!description || isLoading}>
	// 					<CustomPlusCircleFilled inactive={!description || isLoading} />
	// 				</CustomButton>
	// 			</Col>
	// 		</Row>
	// 	</Card>
	// );

	return (
		<Card title="Create section">
			<Row justify="space-between" align="middle" gutter={[10, 0]}>
				<Col span={22}>
					<Description isLoading={isLoading} />
				</Col>
				<Col span={2} style={{ textAlign: 'end' }}>
					<CustomButton type="submit" onClick={handleClick} disabled={!description || isLoading}>
						<CustomPlusCircleFilled inactive={!description || isLoading} />
					</CustomButton>
				</Col>
			</Row>
		</Card>
	);
};
