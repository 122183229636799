import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Injury, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type GetInjuriesDTO = BaseContext & {
	is_default: boolean;
	has_injury?: boolean;
};

export type InjuryResponse = Pick<Injury, 'id' | 'name' | 'description'>;

async function getInjuries<T, Body>(payload: Body): Promise<T> {
	const url = `/ergonomic-tool/injuries`;
	const { data } = await Api.get<T>(url, {
		params: payload
	});
	return data;
}

export function useGetInjuries({ organization_id, company_id, has_injury, is_default }: GetInjuriesDTO) {
	const parameters = { organization_id, company_id, is_default };
	const injuries = useQuery<InjuryResponse[], AxiosError<Response>>(
		['injuries', parameters],
		() => getInjuries<InjuryResponse[], GetInjuriesDTO>(parameters),
		{
			retry: 0,
			enabled: !!organization_id && !!company_id && !!has_injury
		}
	);
	return {
		...injuries,
		data: injuries.data ?? ([] as InjuryResponse[])
	};
}
