import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import { Paragraph, Text, Title } from '@/components/Typography';
import { usePersonalisedReportContext } from '../../../context';
import { VideoContainer } from '@/components/VideoContainer';
import { Footer } from './Footer';
import * as I from './Inputs';

export function Informations() {
	const { personalisedReport, file_id } = usePersonalisedReportContext();

	const today = moment(personalisedReport?.basic_information?.created_at).format('L');

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={21}>
				<Row align="middle">
					<Col xs={12}>
						<Title level={4}>General informations</Title>
					</Col>
					<Col xs={12} style={{ textAlign: 'end' }}>
						<Text>Date of creation</Text>: {today}
					</Col>
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Paragraph>Enter the data related to the selected video below</Paragraph>
					</Col>
				</Row>

				{file_id && (
					<Row justify="center">
						<VideoContainer
							fileId={file_id}
							originalName={personalisedReport.basic_information?.file_original_name}
							uploadedDate={personalisedReport.basic_information?.file_uploaded_date}
						/>
					</Row>
				)}
				<Row justify="center">
					<Col xs={24} lg={18} xxl={12} style={{ marginTop: '1rem' }}>
						<Row gutter={[0, { xs: 50, sm: 40, md: 40, lg: 30, xl: 26 }]}>
							<I.ReportName />
							<I.Organizations />
							<I.Companies />
							<I.Sectors />
							<I.Cellules />
							<I.Workstations />
						</Row>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} lg={18} xxl={12} style={{ marginTop: '4rem' }}>
						<Row gutter={[0, { xs: 40, sm: 40, md: 40, lg: 30, xl: 26 }]}>
							{personalisedReport.has_evaluator && <I.Evaluators />}
							<I.CollectionDate />
							<I.Activities />
							<I.RoleName />
						</Row>
					</Col>
				</Row>
				<Footer />
			</Col>
		</Row>
	);
}
