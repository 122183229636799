import React, { useState, useEffect } from 'react';
import { Row, Col, Steps, Button } from 'antd';

import { ModalBorder } from './styles';
import { I18n } from '@aws-amplify/core';
import { Information, Neck, Trunk, UpperArm, LowerArm, Knee, Ankle, Hip } from './Steps';

export function CreateCustom({ form, visible, loading, onClose, onFinish }) {
	const [current, setCurrent] = useState(0);
	const [submit, setSubmit] = useState(false);
	const [band, setBand] = useState({});

	useEffect(() => {
		if (submit) {
			onFinish(band);
		}
		setSubmit(false);
	}, [band, onFinish, submit]);

	const steps = [
		{
			step: 'information',
			title: I18n.get('Information'),
			content: <Information form={form} />
		},
		{
			step: 'neck',
			title: I18n.get('Neck'),
			content: <Neck form={form} />
		},
		{
			step: 'trunk',
			title: I18n.get('Trunk'),
			content: <Trunk form={form} />
		},
		{
			step: 'upper_arm',
			title: I18n.get('UpperArm'),
			content: <UpperArm form={form} />
		},
		{
			step: 'lower_arm',
			title: I18n.get('LowerArm'),
			content: <LowerArm form={form} />
		},
		{
			step: 'knee',
			title: I18n.get('Knee'),
			content: <Knee form={form} />
		},
		{
			step: 'ankle',
			title: I18n.get('Ankle'),
			content: <Ankle form={form} />
		},
		{
			step: 'hip',
			title: I18n.get('Hip'),
			content: <Hip form={form} />
		}
	];

	const next = () => {
		setCurrent((current) => current + 1);
	};

	const prev = () => {
		setCurrent((current) => current - 1);
	};

	const FooterModal = () => (
		<Row justify="end">
			<Col>
				{current > 0 && (
					<Button style={{ margin: '0 8px' }} onClick={prev}>
						{I18n.get('Previous')}
					</Button>
				)}
			</Col>
			<Col>
				{current < steps.length - 1 && (
					<Button type="primary" onClick={onValidate}>
						{I18n.get('Next')}
					</Button>
				)}
				{current === steps.length - 1 && (
					<Button type="primary" loading={loading} onClick={onValidate}>
						{I18n.get('Finish')}
					</Button>
				)}
			</Col>
		</Row>
	);

	const onValidate = () => {
		form.validateFields()
			.then((values) => {
				const step = steps[current].step;

				if (current === 0) {
					setBand((current) => ({ ...current, [step]: values }));
				}

				if (current > 0) {
					let options = ['upper_arm', 'lower_arm', 'knee', 'ankle', 'hand'];

					if (options.includes(step)) {
						if (step === 'upper_arm') {
							setBand((current) => ({
								...current,
								body_parts: {
									...current.body_parts,
									left_upper_arm: values[step],
									right_upper_arm: values[step]
								}
							}));
						}

						if (step === 'lower_arm') {
							setBand((current) => ({
								...current,
								body_parts: {
									...current.body_parts,
									left_lower_arm: values[step],
									right_lower_arm: values[step]
								}
							}));
						}

						if (step === 'knee') {
							setBand((current) => ({
								...current,
								body_parts: {
									...current.body_parts,
									left_knee: values[step],
									right_knee: values[step]
								}
							}));
						}

						if (step === 'ankle') {
							setBand((current) => ({
								...current,
								body_parts: {
									...current.body_parts,
									left_ankle: values[step],
									right_ankle: values[step]
								}
							}));
						}
					} else {
						setBand((current) => ({
							...current,
							body_parts: { ...current.body_parts, ...values }
						}));
					}
				}

				if (current === steps.length - 1) {
					return setSubmit(true);
				}

				next();
			})
			.catch(() => {});
	};

	const onCancel = () => {
		onClose();
		setBand({});
		setCurrent(0);
		form.resetFields();
	};

	return (
		<ModalBorder width={1000} footer={null} visible={visible} onCancel={onCancel} destroyOnClose={true}>
			<Row>
				<Col sm={24}>
					<h1>{I18n.get('Customize bands of risk')}</h1>
				</Col>
				<Col sm={24} style={{ padding: '10px 0px 10px 0px' }}>
					<p>{I18n.get('Create custom risk strips according to your needs.')}</p>
				</Col>
				<Col sm={24} style={{ padding: '15px 10px 15px 10px' }}>
					<Steps size="small" current={current}>
						{steps.map((item) => (
							<Steps.Step key={item.title} title={item.title} />
						))}
					</Steps>
				</Col>
				<Col
					sm={24}
					style={{
						minHeight: '400px',
						padding: '35px 10px 15px 10px'
					}}
				>
					{steps[current].content}
				</Col>
				<Col sm={24}>
					<FooterModal />
				</Col>
			</Row>
		</ModalBorder>
	);
}
