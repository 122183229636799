import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { AxiosErrorResponse, BaseContext } from '@/types';

export type GetFileHierarchyDTO = BaseContext & {
	file_id?: string;
};

export type GetFileHierarchyResponse = {
	file_id: string;
	sector_id: string;
	company_id: string;
	sector_name: string;
	cellule_id?: string;
	company_name: string;
	cellule_name?: string;
	workstation_id: string;
	organization_id: string;
	workstation_name: string;
	organization_name: string;
	file_original_name: string;
};

async function getFileHierarchy(params: GetFileHierarchyDTO): Promise<GetFileHierarchyResponse> {
	const url = '/file/hierarchy';
	const { data } = await Api.get<GetFileHierarchyResponse>(url, {
		params
	});
	return data;
}

export const useGetFileHierarchy = ({ file_id, company_id, organization_id }: GetFileHierarchyDTO) => {
	const enabled = !!file_id && !!company_id && !!organization_id;
	const nioshReport = useQuery<GetFileHierarchyResponse, AxiosErrorResponse>(
		[QUERY_KEYS.GET_FILE_HIERARCHY, [organization_id, company_id, file_id]],
		() =>
			getFileHierarchy({
				file_id,
				company_id,
				organization_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return nioshReport;
};
