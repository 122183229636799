import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Modal } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { ToolsList } from './ToolsList';
import { StepKey } from '../../../context/types';
import { useCustomReportManagerSummaryContext } from '../../../context';
import { CustomDeleteButton, CustomDivider, CustomModal, StepKeyCardRow } from './styles';

interface StepKeyProps {
	stepId: string;
	stepKey: StepKey;
	stepKeys: StepKey[];
}

export const StepKeyCard: React.FC<StepKeyProps> = ({ stepKey, stepId, stepKeys }) => {
	const [isToolModalOpen, setIsToolModalOpen] = useState<boolean>(false);
	const { removeStepKeyFromStepKeyList, isTheOnlyStepKeyLeft, isTheOnlyStepLeft } =
		useCustomReportManagerSummaryContext();

	const { description, hasInjury, tools } = stepKey;

	const disabledDelete = isTheOnlyStepKeyLeft(stepKeys) && isTheOnlyStepLeft();

	const content = `Want to delete this criterion?${
		isTheOnlyStepKeyLeft(stepKeys) ? ' Deleting it will remove the section as well!' : ''
	}`;

	function handleDeleteClick(): void {
		Modal.confirm({
			title: I18n.get('Heads up!'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get(content),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: () => {
				removeStepKeyFromStepKeyList(stepKey, stepId);
			}
		});
	}

	function handleModalClick(): void {
		setIsToolModalOpen(!isToolModalOpen);
	}

	function showTools(): React.ReactNode {
		if (!tools || tools.length === 0) {
			return I18n.get('No tool selected');
		}
		return (
			<Button style={{ padding: 0 }} type="link" onClick={handleModalClick}>
				{I18n.get('Selected tools')}
			</Button>
		);
	}

	return (
		<StepKeyCardRow align="middle" gutter={[16, 16]}>
			<Col xs={6} xl={7} xxl={9}>
				{I18n.get(description)}
			</Col>
			<CustomDivider type="vertical" />
			<Col xs={5} xl={6}>
				{I18n.get(
					hasInjury
						? 'Indicate possible injuries/aggravations'
						: 'Do not indicate possible injuries/aggravations'
				)}
			</Col>
			<CustomDivider type="vertical" />
			<Col>{showTools()}</Col>
			<CustomDeleteButton style={{ padding: 0 }} disabled={disabledDelete} onClick={handleDeleteClick}>
				<DeleteOutlined style={{ fontSize: '1.2rem' }} />
			</CustomDeleteButton>
			<CustomModal
				centered
				width={600}
				footer={false}
				open={isToolModalOpen}
				onCancel={handleModalClick}
				bodyStyle={{
					height: 'max-content'
				}}
			>
				<ToolsList ergonomicTools={tools} onCloseModalClick={handleModalClick} />
			</CustomModal>
		</StepKeyCardRow>
	);
};
