import React from 'react';
import { Col, Row } from 'antd';

import { Paragraph, Title } from '@/components/Typography';
import { TextArea } from '../../components/TextArea';
import { TextInput } from './TextInput';
import { Footer } from './Footer';

export function WorkConditions() {
	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={21}>
				<Row align="middle">
					<Col xs={24}>
						<Title level={4}>Work Conditions</Title>
					</Col>
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Paragraph>Select the restrictions perceived in the evaluated working condition</Paragraph>
					</Col>
				</Row>
				<Row justify="center">
					<Col span={23}>
						<Row justify="start">
							<Col span={24}>
								<Title level={5}>Work schedule</Title>
							</Col>
							<TextInput
								type="hours"
								message="Insert work schedule"
								namePath={['work_conditions', 'work_hours']}
							/>
							<TextInput namePath={['work_conditions', 'work_minutes']} type="minutes" />
						</Row>
						<Row justify="center" gutter={[0, 10]}>
							<TextArea
								namePath={['work_conditions', 'place_description']}
								title="Summary description of the place"
							/>
							<TextArea
								namePath={['work_conditions', 'expected_task_description']}
								title="Simplified description of the expected task"
							/>
							<TextArea
								namePath={['work_conditions', 'performed_task_description']}
								title="Simplified description of the task performed"
							/>
						</Row>
					</Col>
				</Row>
				<Footer />
			</Col>
		</Row>
	);
}
