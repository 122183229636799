import React from 'react';
import { I18n } from '@aws-amplify/core';
import { ButtonProps } from 'antd';

import { HamburgerButton } from './styles';

interface OptionDropdownProps extends ButtonProps {
	children: React.ReactNode;
}

export function OptionDropdown({ children, ...props }: Readonly<OptionDropdownProps>) {
	return (
		<HamburgerButton type="link" {...props}>
			<span>{I18n.get(children)}</span>
		</HamburgerButton>
	);
}
