import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Moment } from 'moment';

import Api from '@/services/api';
import { ActionPlan, Response, TaskType } from '@/types';
import { customReportService } from '../../../../hooks/services';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type UpdateActionPlanTaskDTO = {
	id?: string;
	type: TaskType;
	description: string;
	is_completed?: boolean;
};

export type UpdateActionPlanDTO = BaseContext & {
	id?: string;
	board: string;
	title?: string;
	file_id?: string;
	deadline?: Moment;
	company_id: string;
	description?: string;
	organization_id: string;
	responsible_user_id: string;
	tasks: UpdateActionPlanTaskDTO[];
	custom_report_sub_step_key_id: string;
	custom_report_step_key_setting_id: string;
};

export type PersonalisedReportActionPlanUpdated = ActionPlan;

async function updateActionPlan<T, Body>(payload: Body): Promise<T> {
	const url = '/action_plan/custom-report/update';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useUpdateActionPlan() {
	const queryClient = useQueryClient();
	return useMutation<PersonalisedReportActionPlanUpdated, AxiosError<Response>, UpdateActionPlanDTO>(
		(body) => updateActionPlan<PersonalisedReportActionPlanUpdated, UpdateActionPlanDTO>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Action plan updated successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report_sub_step_key_action_plan']);
			}
		}
	);
}
