import React from 'react';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input, DatePicker, Switch, Select, type FormInstance } from 'antd';

import { Button } from '@/components/ui/Button';
import { useGetContractPlans } from '../hooks/useGetContractPlans';
import type { InitialValuesForm } from './types';

interface FormFilterProps {
	form: FormInstance;
	onClear: () => void;
	onSubmit: () => Promise<void>;
	initialValues: InitialValuesForm;
}

export function FormFilter({ form, initialValues, onSubmit, onClear }: FormFilterProps) {
	const { isLoading, data, isError } = useGetContractPlans();

	return (
		<Form form={form} initialValues={initialValues} layout="vertical">
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={6}>
					<Form.Item label={I18n.get('Corporate name')} name="organization_name">
						<Input placeholder={I18n.get('Corporate name')} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={6}>
					<Form.Item label={I18n.get('Industrial site')} name="company_name">
						<Input placeholder={I18n.get('Industrial site')} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={6}>
					<Form.Item label={I18n.get('Plan')} name="contract_plan_id">
						<Select
							showSearch
							allowClear
							options={data}
							loading={isLoading}
							placeholder={I18n.get('Plan')}
							disabled={(data && data.length === 0) || isError}
							filterOption={(input: string, option: any) => (option?.label ?? '')?.includes(input)}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={6}>
					<Form.Item label={I18n.get('Created in')} name="created_at">
						<DatePicker.RangePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={6}>
					<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
						<Form.Item name="has_limit_exceeded" valuePropName="checked">
							<Switch />
						</Form.Item>
						<span style={{ marginTop: '5px', marginLeft: '5px' }}>
							{I18n.get('Processing limit reached')}
						</span>
					</div>
				</Col>
			</Row>
			<Row gutter={[12, 10]}>
				<Col xs={24} sm={12} md={6} lg={6} xl={3}>
					<Button type="primary" onClick={onSubmit} icon={<SearchOutlined />}>
						{I18n.get('Search')}
					</Button>
				</Col>
				<Col xs={24} sm={12} md={6} lg={6} xl={3}>
					<Button type="ghost" onClick={onClear}>
						{I18n.get('Clear')}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
