import React from 'react';
import { Select } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';

export const ConsequenceSelect: React.FC = (props) => {
	const { organization_id, company_id } = usePreliminaryAnalysis();

	const parameters = {
		organizationId: organization_id,
		companyId: company_id
	};

	async function getConsequencies({ organizationId, companyId }: any) {
		const url = `/ergonomic-tool/consequences?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get(url);
		return data;
	}

	const { isLoading, data } = useQuery(['consequencies', parameters], () => getConsequencies(parameters), {
		enabled: !!organization_id && !!company_id
	});

	return (
		<Select {...props} style={{ width: '100%' }} loading={isLoading}>
			{data?.map((item: any, index: number) => (
				<Select.Option key={index} value={item.id}>
					{I18n.get(item.description)}
				</Select.Option>
			))}
		</Select>
	);
};
