import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import hooks from '../hooks';
import { RiskBar } from '../RiskBar';
import { DashboardCard } from '../styles';
import { useDashboardContext } from '../context';
import { ToolSubtitle, ToolTitle, Total, Value } from './styles';
import { emptyDataRisks, Risk, RiskBarOptions } from '../RiskBar/types';
import { useApplicationContext } from '@/context/Application';
import { ENUM_FIVE_SCORE_SCALE, ScoresScale } from '../types';

const { useDashboardWorkOganization } = hooks;

export const WorkOrganization: React.FC = () => {
	const { queryParams } = useDashboardContext();
	const { organization, company } = useApplicationContext();
	const { data: workOrganization } = useDashboardWorkOganization(organization.id, company.id, queryParams);

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'top'
		},
		orientation: 'horizontal'
	};

	function calculatePercentage(amount: number): string {
		return (amount / workOrganization.total) * 100 + '%';
	}

	function risksMapper(scores: ScoresScale[]): Risk[] {
		const risks: Risk[] = scores.map(({ amount, type, id, score }) => {
			const risk: Risk = {
				...ENUM_FIVE_SCORE_SCALE[type],
				id,
				value: amount,
				percentage: calculatePercentage(amount),
				score: score
			};
			risk.report = 'work_organization';
			return risk;
		});

		return risks.map((risk) => {
			const hasCaption = risk.id === 0 || risk.id === risks.length - 1;
			return {
				...risk,
				caption: hasCaption ? risk.caption : ''
			};
		});
	}

	return (
		<DashboardCard>
			<Row gutter={[0, 12]} align="middle" justify="center">
				<Col span={24}>
					<Row gutter={[0, 2]}>
						<Col span={24}>
							<ToolTitle>{I18n.get('Work Organization')}</ToolTitle>
						</Col>

						<Col span={24}>
							<ToolSubtitle>{I18n.get('Score by workstation')}</ToolSubtitle>
						</Col>
					</Row>
				</Col>

				<Col span={8}>
					<Total>{I18n.get('Total evaluations')}:</Total>
				</Col>
				<Col span={16}>
					{workOrganization.total ? <Value>{workOrganization.total}</Value> : <Value>0</Value>}
				</Col>

				<Col span={20}>
					{workOrganization?.total > 0 ? (
						<RiskBar risks={risksMapper(workOrganization.scores)} options={riskBarOptions} />
					) : (
						<RiskBar risks={emptyDataRisks} options={riskBarOptions} />
					)}
				</Col>
			</Row>
		</DashboardCard>
	);
};
