import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select } from 'antd';

import hooks from '../../hooks';
import { useApplicationContext } from '@/context/Application';

const { useGetCompanies } = hooks;

type CompaniesSelectProps = {
	organizationId: string;
};

export function CompaniesSelect({ organizationId }: CompaniesSelectProps) {
	const { organization } = useApplicationContext();
	const {
		data: companiesList,
		isLoading: gettingCompanies,
		refetch
	} = useGetCompanies(organizationId || organization.id);

	useEffect(() => {
		refetch();
	}, [organizationId]);

	return (
		<Form.Item name="company">
			<Select
				showSearch
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				disabled={companiesList.length === 0}
				loading={gettingCompanies}
				placeholder={I18n.get('Industrial Site')}
				options={companiesList.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
