import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useDashboardContext } from '../../context';
import { defaultOptions, Risk, RiskBarOptions } from '../types';
import {
	Caption,
	Container,
	RiskBarCaption,
	RiskBarContainer,
	RiskBarContent,
	RiskScale,
	Value,
	VerticalCaptionContainer,
	VerticalContent,
	VerticalRiskBarContainer
} from './styles';

interface VerticalProps {
	risks: Risk[];
	options?: RiskBarOptions;
	total?: number;
}

export const Vertical: React.FC<VerticalProps> = ({ risks, options = defaultOptions, total }) => {
	const { handleFilterSelection } = useDashboardContext();

	const range_map = [
		{ initial_value: 0, final_value: 19 },
		{ initial_value: 20, final_value: 69 },
		{ initial_value: 70, final_value: 199 },
		{ initial_value: 200, final_value: 400 },
		{ initial_value: 401, final_value: 10000 }
	];

	return (
		<Container orientation={options.orientation}>
			<VerticalContent>
				<VerticalRiskBarContainer valueDisplay={options.value?.display}>
					{risks.map((risk) => {
						return (
							risk.percentage !== '0%' && (
								<RiskBarContainer
									percentage={risk.percentage}
									orientation={options.orientation}
									key={risk.id}
									valueDisplay={options.value?.display}
								>
									<RiskBarContent
										orientation={options.orientation}
										align={options.caption.align}
										percentage={risk.percentage}
										captionDisplay={options.caption?.display}
										valueDisplay={options.value?.display}
									>
										<RiskScale
											onClick={() => {
												handleFilterSelection({
													stressLevel: [
														range_map.findIndex(
															(elem) =>
																elem.final_value >= risk.score! &&
																elem.initial_value <= risk.score!
														) + 1
													]
												});
											}}
											riskId={
												risks.find((elem) => elem.percentage === '0%') ? risk.id - 1 : risk.id
											}
											length={
												risks.find((elem) => elem.percentage === '0%')
													? risks.length - 1
													: risks.length
											}
											orientation={options.orientation}
											color={risk.trailColor}
										/>
									</RiskBarContent>
								</RiskBarContainer>
							)
						);
					})}
				</VerticalRiskBarContainer>
				<VerticalCaptionContainer valueDisplay={options.value?.display}>
					{risks.map((risk) => {
						return (
							risk.percentage !== '0%' &&
							(risk.caption || risk.value) && (
								<RiskBarCaption
									orientation={options.orientation}
									captionDisplay={options.caption.display}
									valueDisplay={options.value?.display}
									key={risk.id}
								>
									{risk.caption && (
										<Caption
											style={{
												fontWeight: risk.trailColor === '#d6d6d6' ? 600 : 400
											}}
										>
											{I18n.get(risk.caption)}
										</Caption>
									)}
									{risk.value && (
										<Value
											style={{
												color: risk.fontColor,
												marginLeft: options.value?.display ? '0' : '2rem'
											}}
										>
											{total
												? `${Math.round(
														(Number(risk.value.toString().split('%')[0]) * total) / 100
												  )} (${risk.value})`
												: risk.value}
										</Value>
									)}
								</RiskBarCaption>
							)
						);
					})}
				</VerticalCaptionContainer>
			</VerticalContent>
		</Container>
	);
};
