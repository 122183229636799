import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import React from 'react';

type AnalystProps = {
	organizationId: string;
	companyId: string;
};

export function Analyst({ organizationId, companyId }: AnalystProps) {
	return (
		<Form.Item name="analyst">
			<Input disabled={!organizationId || !companyId} placeholder={I18n.get('Analyst')} />
		</Form.Item>
	);
}
