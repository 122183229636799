import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}
	body {
		-webkit-font-smoothing: antialiased !important;
	}

	.disabled-row-table {
		background-color: #FAFAFA;
	}

	.ant-table {
        border-radius: 10px;
        .ant-table-thead {
            .ant-table-cell:first-child {
                border-radius: 10px 0 0 0;
            }
            .ant-table-cell:last-child {
                border-radius: 0 10px 0 0;
            }
        }
    }
`;
