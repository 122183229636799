import React from 'react';
import { Row } from 'antd';
import { Card } from './styles';

interface ContentProps {
	children: string | JSX.Element | JSX.Element[];
}

export const Content: React.FC<ContentProps> = ({ children }) => (
	<Card xs={22} sm={12} md={12} lg={10} xl={8} xxl={6}>
		<Row gutter={[0, 20]} justify="center" align="middle">
			{children}
		</Row>
	</Card>
);
