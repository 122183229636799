export enum FileStatus {
	CORRUPTED_FILE = 'CORRUPTED_FILE',
	EXTRACTED_DATA = 'EXTRACTED_DATA',
	NOT_PROCESSED = 'NOT_PROCESSED',
	PROCESSING = 'PROCESSING',
	PROCESSED = 'PROCESSED',
	IN_QUEUE = 'IN_QUEUE'
}

export type File = {
	id: string;
	original_name: string;
	generated_name: string;
	size: number;
	url: string;
	status: FileStatus;
	file_processed: null;
	workstation: string;
	duration: number;
	rulaScore: number;
	tool: string;
	blurFace: boolean;
	user_id: string;
	thumbnail: string;
	organization_id: string;
	company_id: string;
	sector_id: string;
	line_id: string;
	workstation_id: string;
	isActive: boolean;
	createdAt: Date;
	updatedAt: Date;
};
