import styled from 'styled-components';

export const Container = styled.div`
	border: 1px solid #d9d9d9;
	border-radius: 0.25rem;
	width: 100%;
	margin-top: 1rem;
	text-align: center;
`;

export const ConditionContainer = styled.div`
	padding: 0.5rem 0;
	border: 1px solid ${(props) => props.color};
	color: ${(props) => props.color};
	border-radius: 0.25rem;
	margin-top: 1rem;
	text-align: center;
	width: 100px;
	font-weight: 600;
`;
