export const stepDescription = 'Select the constraints perceived in the evaluated work condition';

export const attributesCheckable = {
	fatigue_complaints: 'fatigue_complaints',
	absenteeism: 'absenteeism',
	musculoskeletal_complaints: 'musculoskeletal_complaints',
	complaints_of_irritability: 'complaints_of_irritability',
	nonconforming_products: 'nonconforming_products',
	unexcused_absences: 'unexcused_absences',
	others: 'others'
};

export const riskLevelList = {
	low: 'low',
	moderate: 'moderate',
	high: 'high',
	very_high: 'very_high',
	extreme: 'extreme'
};

export const paletteMap = {
	[riskLevelList.low]: '#2CC852',
	[riskLevelList.moderate]: '#FFDE31',
	[riskLevelList.high]: '#F78A38',
	[riskLevelList.very_high]: '#E74150',
	[riskLevelList.extreme]: '#9B54E2'
};

export const injuriesKeys = {
	fatigue_complaints: 'fatigue_complaints',
	absenteeism: 'absenteeism',
	musculoskeletal_complaints: 'musculoskeletal_complaints',
	complaints_of_mental_overload: 'complaints_of_mental_overload',
	complaints_of_irritability: 'complaints_of_irritability',
	non_conforming_products: 'non_conforming_products',
	unexcused_absences: 'unexcused_absences',
	low_back_pain_complaints: 'low_back_pain_complaints',
	elbow_pain: 'elbow_pain',
	shoulder_pain: 'shoulder_pain',
	headache: 'headache',
	neck_pain: 'neck_pain',
	wrist_pain: 'wrist_pain',
	nonconforming_products: 'nonconforming_products',
	others: 'others'
};

export const injuriesMap = [
	{
		key: injuriesKeys.fatigue_complaints,
		value: 'Fatigue complaints'
	},
	{
		key: injuriesKeys.absenteeism,
		value: 'Absenteeism'
	},
	{
		key: injuriesKeys.musculoskeletal_complaints,
		value: 'Musculoskeletal complaints'
	},
	{
		key: injuriesKeys.complaints_of_mental_overload,
		value: 'Complaints of mental overload'
	},
	{
		key: injuriesKeys.complaints_of_irritability,
		value: 'Complaints of irritability'
	},
	{
		key: injuriesKeys.non_conforming_products,
		value: 'Nonconforming products'
	},
	{
		key: injuriesKeys.unexcused_absences,
		value: 'Unexcused absences'
	},
	{
		key: injuriesKeys.low_back_pain_complaints,
		value: 'Low back pain complaints'
	},
	{
		key: injuriesKeys.elbow_pain,
		value: 'Elbow pain'
	},
	{
		key: injuriesKeys.shoulder_pain,
		value: 'Shoulder pain'
	},
	{
		key: injuriesKeys.headache,
		value: 'Headache'
	},
	{
		key: injuriesKeys.neck_pain,
		value: 'Neck pain'
	},
	{
		key: injuriesKeys.wrist_pain,
		value: 'Wrist pain'
	},
	{
		key: injuriesKeys.others,
		value: 'Others'
	}
];

export const selfEvaluationKeys = {
	nothing_stressful: 'nothing_stressful',
	little_stressful: 'little_stressful',
	stressful: 'stressful',
	very_stressful: 'very_stressful',
	extremely_stressful: 'extremely_stressful'
};

export const selfEvaluationMap = [
	{
		value: selfEvaluationKeys.nothing_stressful,
		description: 'Nothing stressful'
	},
	{
		value: selfEvaluationKeys.little_stressful,
		description: 'Little stressful'
	},
	{
		value: selfEvaluationKeys.stressful,
		description: 'Stressful'
	},
	{
		value: selfEvaluationKeys.very_stressful,
		description: 'Very stressful'
	},
	{
		value: selfEvaluationKeys.extremely_stressful,
		description: 'Extremely stressful'
	}
];

export const baseFormName = 'preliminary_analysis';
