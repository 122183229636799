import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';

import { CustomFormItem } from '../../styles';
import { CustomInput } from './styles';

interface SearchStepNameProps {
	onSearchStepNameChange: (name: string) => void;
}

const { useFormInstance, useWatch } = Form;

export const SearchStepName: React.FC<SearchStepNameProps> = ({ onSearchStepNameChange }) => {
	const form = useFormInstance();
	const name = useWatch(['step_name_filter'], form);

	return (
		<Row justify="center">
			<Col xs={24}>
				<CustomFormItem name={['step_name_filter']}>
					<CustomInput
						allowClear
						size="large"
						value={name}
						bordered={false}
						placeholder={I18n.get('Search by section name...')}
						onChange={(event) => onSearchStepNameChange(event.target.value)}
						prefix={<SearchOutlined style={{ color: '#20202055', marginRight: '0.25rem' }} />}
					/>
				</CustomFormItem>
			</Col>
		</Row>
	);
};
