import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

type KimPpInput = {
	report_id?: string;
	file_id: string;
	current_step: number;
};

type KimPpInputStep0 = KimPpInput & {
	report_name: string;
	collection_date: Date | string;
	sector_id: string;
	cellule_id?: string;
	workstation_id: string;
	evaluator_id: string;
	file_id?: string;
	activity_id: string;
};

type KimPpInputStep1 = KimPpInput & {
	gender: number;
	pair: number;
	distance_or_duration: number;
};

type KimPpInputStep2 = KimPpInput & {
	vehicle: number;
	mass: number;
	driveway_conditions: number;
	inclination_or_stairs: number;
};

type KimPpInputStep3 = KimPpInput & {
	working_conditions: number[];
	properties: number[];
};

type KimPpInputStep4 = KimPpInput & {
	posture: number;
	temporal_distribution?: number;
};

type UpdateKimPpDTO = {
	organization_id: string;
	company_id: string;
	kim_pp_input: KimPpInputStep0 | KimPpInputStep1 | KimPpInputStep2 | KimPpInputStep3 | KimPpInputStep4;
}

type UpdateKimPpResponse = {
	id: string;
	company_id: string;
	organization_id: string;
	file_id?: string;
	report_name: string;
	collection_date: string;
	sector_id: string;
	cellule_id?: string;
	workstation_id: string;
	evaluator_id: string;
	duration?: number;
	left_force_frequency?: number;
	left_force_type?: string;
	right_force_frequency?: number;
	right_force_type?: string;
	arm_posture?: string;
	work_conditions?: string;
	temporal_distribution?: string;
	body_posture?: string;
};

type UpdateKimPpError = AxiosError<any>;

async function updateReport(parameters: UpdateKimPpDTO) {
	const { data } = await Api.put(`/ergonomic-tool/kim-pp/${parameters.kim_pp_input.report_id}`, parameters);
	return data;
}

export const useUpdateKimPp = () => {
	const queryClient = useQueryClient();
	return useMutation<UpdateKimPpResponse, UpdateKimPpError, UpdateKimPpDTO>((payload) => updateReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_KIM_PP_REPORT]);
			queryClient.invalidateQueries(['file']);
		}
	});
};
