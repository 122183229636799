import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Row, Col, notification } from 'antd';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { PrimaryButton } from '@/components/ui/Buttons/PrimaryButton';
import { Text, Title } from '@/components/Typography';
import { Utils } from '@/utils/index';

import { Container, CustomFormItem, CustomInputPassword, IconContainer } from './styles';
import { useResetPassword } from './hooks';

type RouteParams = {
	email: string;
	token: string;
};

export const ResetPasswordContent: React.FC = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const { token, email } = useParams<RouteParams>();

	const [submited, setSubmited] = useState(false);
	const resetPassword = useResetPassword();

	async function onFinish() {
		const { password } = await form.validateFields();

		const params = { email, token, password };

		resetPassword.mutate(params, {
			onSuccess: () => {
				const params = {
					message: I18n.get('Operation performed successfully'),
					description: I18n.get('Click here to continue'),
					duration: 0,
					closable: true,
					onClick: () => {
						notification.destroy();
						history.push('/login');
					}
				};
				setSubmited(true);
				notification.success(params);
			},
			onError: (err: any) => {
				const params = {
					message: I18n.get('Oops... Something went wrong!'),
					description: I18n.get(err.message)
				};
				notification.error(params);
			}
		});
	}

	function handleBlockingEvent(e: React.FormEvent<HTMLInputElement>) {
		e.preventDefault();
		return false;
	}

	return (
		<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
			<Container xs={18} sm={18} md={12} lg={10} xl={8} xxl={6}>
				<Row justify="center">
					<IconContainer>
						<Icons.LockFilled />
					</IconContainer>
				</Row>
				<Row justify="center">
					<Title level={4}>Create new password</Title>
				</Row>
				<Row justify="center" style={{ marginTop: '1rem' }}>
					<Col span={22} style={{ textAlign: 'center', fontSize: '1rem', lineHeight: '1.3rem' }}>
						<Text>Your new password must be different from previous used passwords</Text>
					</Col>
				</Row>
				<Form form={form} onFinish={onFinish} style={{ marginTop: '2rem' }}>
					<Col>
						<CustomFormItem
							name="password"
							hasFeedback
							rules={[
								() => ({
									validator(_, value) {
										return Utils.checkingPassword(value);
									}
								})
							]}
						>
							<CustomInputPassword
								size="large"
								placeholder={I18n.get('Password')}
								prefix={<Icons.LockOutlined className="site-form-item-icon" />}
							/>
						</CustomFormItem>
					</Col>
					<Col>
						<CustomFormItem
							name="confirm"
							dependencies={['password']}
							hasFeedback
							rules={[
								{
									required: true,
									message: I18n.get('Confirm new password')
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											I18n.get('The two passwords that you entered do not match')
										);
									}
								})
							]}
						>
							<CustomInputPassword
								size="large"
								placeholder={I18n.get('Confirm Password')}
								prefix={<Icons.LockOutlined className="site-form-item-icon" />}
								onCopy={handleBlockingEvent}
								onDrag={handleBlockingEvent}
								onDrop={handleBlockingEvent}
								onPaste={handleBlockingEvent}
							/>
						</CustomFormItem>
					</Col>
					<Form.Item style={{ marginTop: '2.5rem' }}>
						<PrimaryButton htmlType="submit" disabled={submited} loading={resetPassword.isLoading}>
							{I18n.get('Save')}
						</PrimaryButton>
					</Form.Item>
				</Form>
			</Container>
		</Row>
	);
};
