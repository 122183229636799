import React from 'react';
import { Col, Row } from 'antd';

import { Header } from './Header';
import { CustomFormContainer } from './styles';
import { usePersonalisedReportContext } from '../context';
import { StepsNavigation } from './Steps/StepsNavigation';

export const Report: React.FC = () => {
	const { currentStep, steps } = usePersonalisedReportContext();

	return (
		<Row justify="center">
			<Col xs={24} xxl={24}>
				<Row justify="start" align="top" gutter={[20, 0]}>
					<Col xs={6} lg={4} style={{ marginTop: '2rem' }}>
						<StepsNavigation currentStep={currentStep} />
					</Col>
					<Col xs={19}>
						<Row justify="start" gutter={[16, 24]}>
							<Col xs={24}>
								<Header />
							</Col>
							<CustomFormContainer xs={24}>{steps[currentStep]?.component}</CustomFormContainer>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
