import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

type DeleteCompanyResponse = {
	id: string;
	name: string;
	createdAt: Date;
	organization_id: string;
};

type DeleteCompanyParams = {
	organization_id: string;
	company_id?: string;
};

type CompanyResponse = {
	id: string;
	name: string;
	createdAt: Date;
	organization_id: string;
};

async function deleteCompany({ organization_id, company_id }: DeleteCompanyParams): Promise<CompanyResponse> {
	const url = `/company/${organization_id}/${company_id}`;
	const { data } = await Api.delete<CompanyResponse>(url);
	return data;
}

export const useDeleteCompany = () => {
	const queryClient = useQueryClient();
	return useMutation<DeleteCompanyResponse, AxiosError, DeleteCompanyParams>(
		(params: DeleteCompanyParams) => deleteCompany(params),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message || err.response.statusText));
			},
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_COMPANIES_CONTRACT_PLANS]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ORGANIZATIONS_CONTRACT_PLANS]);
				message.success(I18n.get('Company deleted successfully'));
			}
		}
	);
};
