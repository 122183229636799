import React from 'react';
import { Col } from 'antd';
import * as S from '../styles';

interface HeaderProps {
	children: string | string[];
}

export function Header({ children }: HeaderProps) {
	return (
		<Col span={24}>
			<S.Title>{children}</S.Title>
		</Col>
	);
}
