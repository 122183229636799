import styled from 'styled-components';
import { ContainerProps } from './types';

export const Container = styled.a<ContainerProps>`
	display: flex;
	font-size: 0.9rem !important;
	justify-content: ${({ align }) => align ?? 'end'};

	@media only screen and (min-width: 1600px) {
		font-size: 1rem;
	}
`;
