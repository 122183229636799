import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, InputNumber, Row, Space } from 'antd';

import { RequiredLabel } from '../../components/RequiredLabel';
import { CustomFormItem } from '../../components/styles';

const { useWatch, useFormInstance } = Form;

interface TextInputProps {
	type: string;
	message?: string;
	namePath: string[];
}

export const TextInput: React.FC<TextInputProps> = ({ message = '', namePath, type }) => {
	const form = useFormInstance();
	const work_hours = useWatch(['work_conditions', 'work_hours'], form) ?? 0;
	const work_minutes = useWatch(['work_conditions', 'work_minutes'], form) ?? 0;

	const total = work_hours + work_minutes;
	const timeInputMapper: {
		[key: string]: number;
	} = {
		minutes: 59,
		hours: 23
	};

	async function handleChange(): Promise<void> {
		await form.validateFields();
	}

	return (
		<Col sm={24} md={10} lg={9} xl={7} xxl={6}>
			<Row justify="start" align="middle">
				<Col>
					<CustomFormItem>
						<Space align="baseline" size="middle" wrap={true} style={{ height: '38px' }}>
							<Form.Item
								name={namePath}
								rules={[
									() => ({
										validator(): Promise<any> {
											if (total === 0) {
												return Promise.reject(I18n.get(message));
											}
											if (type === 'hours' && work_hours === 0) {
												return Promise.reject(I18n.get('Work hours must be greater than 1'));
											}
											return Promise.resolve();
										}
									})
								]}
							>
								<InputNumber
									min={0}
									size="large"
									placeholder="0"
									onChange={handleChange}
									style={{ width: '5rem' }}
									max={timeInputMapper[type]}
									value={type === 'hours' ? work_hours : work_minutes}
								/>
							</Form.Item>
							<RequiredLabel title={type} fontSize="1rem" />
						</Space>
					</CustomFormItem>
				</Col>
			</Row>
		</Col>
	);
};
