import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Probability, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type GetProbabilitiesDTO = BaseContext & {
	score_scale_id?: string;
};

export type ProbabilityResponse = Pick<Probability, 'id' | 'name' | 'description' | 'score'>;

async function getProbabilities<T, Body>(payload: Body): Promise<T> {
	const url = `/ergonomic-tool/probabilities`;
	const { data } = await Api.get<T>(url, {
		params: payload
	});
	return data;
}

export function useGetProbabilities({ organization_id, company_id, score_scale_id }: GetProbabilitiesDTO) {
	const parameters = { organization_id, company_id, score_scale_id };
	const probabilities = useQuery<ProbabilityResponse[], AxiosError<Response>>(
		['probabilities', parameters],
		() => getProbabilities<ProbabilityResponse[], GetProbabilitiesDTO>(parameters),
		{
			retry: 0,
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...probabilities,
		data: probabilities.data ?? ([] as ProbabilityResponse[])
	};
}
