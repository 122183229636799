import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useContractPlansContext } from '../../context';
import { Form, message } from 'antd';
import moment from 'moment';

import { ProcessingControl } from './ProcessingControl';
import { Information } from './Information';
import { Context, Methods, States } from './types';

import { useGetCompany, useUpdateCompany } from '../../hooks/index';
import _ from 'lodash';

const { useForm } = Form;

const EditCompanyContext = createContext<Context>({} as Context);

const steps = [
	{
		component: <Information />
	},
	{
		component: <ProcessingControl />
	}
];

interface EditCompanyProviderProps {
	children: ReactNode;
	onCancel(): void;
	companyId: string;
	organizationId?: string;
}

export function EditCompanyProvider({
	children,
	onCancel,
	companyId,
	organizationId
}: Readonly<EditCompanyProviderProps>) {
	const [form] = useForm();

	const [currentStep, setCurrentStep] = useState<number>(0);
	const [formData, setFormData] = useState<any>();

	const { queryUrl } = useContractPlansContext();

	const {
		isLoading: isLoadingInformations,
		data,
		error: errorGetCompany,
		isError: isErrorGetCompany
	} = useGetCompany(companyId);

	const {
		mutateAsync: updateCompany,
		isLoading: isUpdateingCompany,
		error: errorupdatecompany,
		isError: isErrorUpdateCompany
	} = useUpdateCompany();

	const isLoading = isLoadingInformations || isUpdateingCompany;
	const is_last_step = currentStep === steps.length - 1;
	const is_error = isErrorUpdateCompany || isErrorGetCompany;
	const error: any = errorupdatecompany || errorGetCompany;

	useEffect(() => {
		if (data) {
			const fields = [
				['company_name', data?.name],
				['company_information', Boolean(data?.business_information === null)],
				['company_address', data?.business_information?.address],
				['company_city', data?.business_information?.city],
				['company_district', data?.business_information?.district],
				['company_plan', data?.business_information?.contract_plan?.id],
				['company_nit', data?.business_information?.cnpj],

				['company_processing_control', Boolean(data?.plan === null)],
				['company_maximum_time', Boolean(data?.plan?.max_minutes)],
				['company_maximum_videos', Boolean(data?.plan?.max_upload)],
				['company_recurrence', data?.plan?.recurrence],
				[
					'company_renewal_day',
					data?.plan?.expiration_plan ? moment.utc(data?.plan?.expiration_plan).date() : null
				],
				[
					'company_renewal_month',
					data?.plan?.expiration_plan && data?.plan?.recurrence === 'yearly'
						? moment.utc(data?.plan?.expiration_plan).month() + 1
						: null
				],
				['company_max_minutes', data?.plan?.max_minutes ? Number(data?.plan?.max_minutes) / 60 : null],
				['company_max_uploads', data?.plan?.max_upload]
			];

			fields.forEach(([field, value]) => form.setFieldValue(field, value));
		}
	}, [data]);

	if (is_error || error) {
		message.error('Oops! Something happened.');
	}

	function clearFieldErrors(_changedValues: any, allValues: any) {
		const updatedFields = Object.keys(allValues)
			.filter((name) => form.getFieldError(name).length)
			.map((name) => ({ name, errors: [] }));
		form.setFields(updatedFields);
	}

	async function handleUpdateCompany(): Promise<any> {
		const values = form.getFieldsValue();

		const payload = {
			organization_id: queryUrl.get('organization_id') || organizationId,
			company_name: formData.company_name,
			company_id: companyId
		};

		if (!values.company_processing_control) {
			const plan = {
				recurrence: values.company_recurrence,
				max_minutes: values.company_max_minutes,
				max_upload: values.company_max_uploads,
				expiration_day: values.company_renewal_day,
				expiration_month: values.company_renewal_month
			};

			_.set(payload, 'plan', plan);
		}

		if (!formData.company_information) {
			const business_information = {
				nit: formData.company_nit,
				city: formData.company_city,
				address: formData.company_address,
				district: formData.company_district,
				contract_plan_id: formData.company_plan
			};

			_.set(payload, 'business_information', business_information);
		}

		return await updateCompany(payload);
	}

	function handlePreviousStep() {
		const changeStep = currentStep - 1;

		if (changeStep < 0) {
			return onCancel();
		}

		setCurrentStep(changeStep);
	}

	async function handleNextStep() {
		await form.validateFields();

		if (currentStep === 0) {
			setFormData(form.getFieldsValue());
		}

		if (currentStep === 1) {
			await handleUpdateCompany();
		}

		if (is_last_step) {
			return onCancel();
		}

		setCurrentStep(currentStep + 1);
	}

	function handleChangeStep(step: number): void {
		setCurrentStep(step);
	}

	const states: States = {
		steps,
		isLoading,
		currentStep
	};

	const methods: Methods = {
		onNextStep: handleNextStep,
		onStepChange: handleChangeStep,
		onPreviousStep: handlePreviousStep
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<EditCompanyContext.Provider value={context}>
			<Form onValuesChange={clearFieldErrors} form={form}>
				{children}
			</Form>
		</EditCompanyContext.Provider>
	);
}

export function useEditCompanyContext() {
	const context = useContext(EditCompanyContext);
	return context;
}
