import React from 'react';
import { Tabs, TabsProps } from 'antd';
import { I18n } from '@aws-amplify/core';

import { KinebotStepKey } from '../../components';
import { PersonalisedReportSubStepKeyProvider } from '../../components/KinebotStepKey/context';
import { CustomReportSubStepKeyResponse, PersonalisedReportStepKeysResponse } from '../../../hooks';
import { usePersonalisedReportContext } from '../../../context';

interface KinebotStepKeyProps {
	stepId: string;
	stepKey: PersonalisedReportStepKeysResponse;
	subStepKeys: CustomReportSubStepKeyResponse[];
}

export const KinebotStepKeyTabs: React.FC<KinebotStepKeyProps> = ({ stepKey, stepId, subStepKeys }) => {
	const { personalisedReport, file_id } = usePersonalisedReportContext();

	const items: TabsProps['items'] = subStepKeys.map((subStepKey) => ({
		forceRender: true,
		key: `${subStepKey.sequence + 1}`,
		label: I18n.get(subStepKey.title),
		children: (
			<PersonalisedReportSubStepKeyProvider
				stepId={stepId}
				key={stepKey.id}
				fileId={file_id}
				stepKey={stepKey}
				subStepKey={subStepKey}
				reba={personalisedReport.basic_information?.reba}
				stepKeyFieldName={[`${stepId}`, `${stepKey.id}`, 'kinebot', `${subStepKey.id}`]}
			>
				<KinebotStepKey key={subStepKey.id} />
			</PersonalisedReportSubStepKeyProvider>
		)
	}));

	return <Tabs defaultActiveKey="1" items={items} />;
};
