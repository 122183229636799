import React, { ReactNode } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Create, SelectId } from '../styles';
import { useManageOrganization } from '../../../context';
import { DeleteRequest, WorkstationDTO } from '../../../types';

const { useFormInstance } = Form;

type OptionType = {
	label: string;
	value: string;
};

interface SelectWorkstationProps {
	workstations: WorkstationDTO[];
	workstationName: string;
	onWorkstationNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
}

export const SelectWorkstation: React.FC<SelectWorkstationProps> = ({
	workstations,
	workstationName,
	onWorkstationNameChange,
	onEditing
}) => {
	const { resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		workstationId,
		sectorId,
		updatingOrganizationData,
		creatingOrganizationData,
		isEditing,
		handleWorkstationId,
		handleWorkstation,
		createOrganizationData,
		deleteOrganizationData,
		handleIsEditing
	} = useManageOrganization();

	async function handleCreateWorkstation() {
		if (workstations.find((elem) => elem.name === workstationName)) {
			const content = I18n.get(`You will create a new workstation with same name. Do you wish to proceed ?`);
			Modal.confirm({
				title: I18n.get('Warning!'),
				icon: <ExclamationCircleOutlined />,
				content: content,
				okType: 'danger',
				okText: I18n.get('Yes'),
				cancelText: I18n.get('Cancel'),
				onOk: async () => {
					const created = await createOrganizationData({
						organization_id: organizationId,
						company_id: companyId,
						sector_id: sectorId,
						workstation_name: workstationName
					});

					onWorkstationNameChange('');
					handleWorkstation(created.id, resetFields, setFieldValue);
				}
			});
		} else {
			const created = await createOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				sector_id: sectorId,
				workstation_name: workstationName
			});

			onWorkstationNameChange('');
			handleWorkstation(created.id, resetFields, setFieldValue);
		}
	}

	function handleEditWorkstation(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const editingWorkstation = workstations.find((workstation) => workstation.id === workstationId);
		if (editingWorkstation) {
			setFieldValue('edit_workstation_name', editingWorkstation.name);
			onWorkstationNameChange(editingWorkstation.name);
			handleIsEditing(true);
			onEditing(true);
		}
	}

	async function handleDeleteWorkstation(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const body: DeleteRequest = {
			organization_id: organizationId,
			company_id: companyId,
			sector_id: sectorId,
			workstation_id: workstationId
		};

		const content = I18n.get(
			`All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?`
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				deleteOrganizationData(body).then(() => {
					onWorkstationNameChange('');
					handleWorkstationId('');
					resetFields(['workstation_id']);
				});
			}
		});
	}

	function filterWorkstation(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectWorkstation(id: string) {
		handleWorkstation(id, resetFields, setFieldValue);
	}

	function handleClearWorkstation() {
		handleWorkstationId('');
	}

	function manageWorkstationDropdown(menu: ReactNode) {
		return (
			<Col span={24} style={{ margin: '0px 15px', paddingTop: '15px' }}>
				{menu}
				<Divider style={{ margin: '15px 0' }} />
				<Create>
					<Form.Item name="create_workstation_name" style={{ margin: '10px 0px' }}>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px'
							}}
							value={workstationName}
							placeholder={I18n.get('Create a new workstation')}
							onChange={(e) => onWorkstationNameChange(e.target.value)}
						/>
					</Form.Item>
					<Button
						style={{ marginBottom: '10px' }}
						onClick={handleCreateWorkstation}
						type="link"
						loading={creatingOrganizationData}
						disabled={!workstationName || workstationName.length < 3}
					>
						{I18n.get('Create workstation')}
					</Button>
				</Create>
			</Col>
		);
	}

	const workstationsList = workstations.map(({ id, name }) => ({
		label: name,
		value: id
	}));

	const actions = (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Col span={12}>
					<Button
						disabled={!workstationId || isEditing}
						size="middle"
						type="primary"
						ghost={true}
						onClick={(e) => handleEditWorkstation(e)}
						icon={<EditOutlined />}
					/>
				</Col>
				<Col span={12}>
					<Button
						disabled={!workstationId || isEditing}
						size="middle"
						type="primary"
						ghost={true}
						danger={true}
						onClick={(e) => handleDeleteWorkstation(e)}
						icon={<DeleteOutlined />}
					/>
				</Col>
			</Row>
		</Col>
	);

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<SelectId
						selected={!!workstationId || isEditing}
						name="workstation_id"
						label={I18n.get('Select a workstation')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								message: I18n.get('Please, select a workstation')
							}
						]}
					>
						<Select
							showSearch
							allowClear
							onClear={handleClearWorkstation}
							loading={updatingOrganizationData}
							options={workstationsList}
							filterOption={(input, option) => filterWorkstation(option, input)}
							onChange={(value: string) => handleSelectWorkstation(value)}
							placeholder={I18n.get('Select a workstation')}
							dropdownRender={(menu: ReactNode) => manageWorkstationDropdown(menu)}
						/>
					</SelectId>
				</Col>
				{actions}
			</Row>
		</Col>
	);
};
