import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

type ContractPlanDTO = {
	id: string;
	name: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date | null;
};

async function getPlans(): Promise<ContractPlanDTO[]> {
	const { data } = await Api.get('/contract-plan/plans');
	return data;
}

export const useGetContractPlans = () => {
	return useQuery([QUERY_KEYS.GET_PLANS_CONTRACT_PLANS], getPlans, {
		select: (plans) => plans?.map(({ id, name }) => ({ label: name, value: id }))
	});
};
