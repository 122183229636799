import { useQuery } from '@tanstack/react-query';
import { IUseBodyParts } from './types';
import { Services } from './services';

const useBodyParts = (data: IUseBodyParts) => {
	const { organization_id, company_id, file_id } = data;
	return useQuery(['reba-body-parts', data], () => Services.getBodyParts(data), {
		enabled: !!organization_id && !!company_id && !!file_id
	});
};

export const Hooks = {
	useBodyParts
};
