import React, { createContext, useContext, useReducer, useState } from 'react';
import { ApplicationReducerTypes } from './types';
import { ApplicationReducer } from './reducer';

// https://endertech.com/blog/using-reacts-context-api-for-global-state-managements

const storage = {
	organization: localStorage.getItem('organization'),
	company: localStorage.getItem('company'),
	info_user: localStorage.getItem('info_user')
};

const defaultState = {
	organization: JSON.parse(storage.organization),
	company: JSON.parse(storage.company),
	info_user: JSON.parse(storage.info_user),
	setOrganization: (item) => {},
	setCompany: (item) => {},
	setUser: (item) => {},
	clearProject: () => {}
};

const ApplicationContext = createContext();

export const ApplicationProvider = ({ children }) => {
	const [state, dispatch] = useReducer(ApplicationReducer, defaultState);
	const [logoChange, setLogoChange] = useState(false);

	function setOrganization(item) {
		dispatch({ type: ApplicationReducerTypes.SET_ORGANIZATION, payload: item });
	}

	function setCompany(item) {
		localStorage.setItem('organization', JSON.stringify(state.organization));
		localStorage.setItem('company', JSON.stringify(item));
		const info_user = localStorage.getItem('info_user');
		dispatch({ type: ApplicationReducerTypes.SET_COMPANY, payload: item });
		dispatch({ type: ApplicationReducerTypes.SET_USER, payload: JSON.parse(info_user) });
	}

	function setUser(item) {
		localStorage.setItem('info_user', JSON.stringify(item));
		dispatch({ type: ApplicationReducerTypes.SET_USER, payload: item });
	}

	function updateUser(item) {
		let new_object = {};

		if (state.info_user) {
			const { user, ...tokens } = state.info_user;
			const { logo, ...rest } = user;

			new_object = { user: { ...rest, logo: item }, ...tokens };

			localStorage.setItem('info_user', JSON.stringify(new_object));
			dispatch({ type: ApplicationReducerTypes.SET_USER, payload: new_object });
			setLogoChange(!logoChange);
		}
	}

	function updateCompany(item) {
		let new_object = {};
		if (state.company) {
			const { url_logo, ...rest } = state.company;

			new_object = { ...rest, url_logo: item };

			localStorage.setItem('company', JSON.stringify(new_object));
			dispatch({ type: ApplicationReducerTypes.SET_COMPANY, payload: new_object });
		}
	}

	function updateOrganization(item) {
		let new_object = {};
		if (state.organization) {
			const { url_logo, ...rest } = state.organization;

			new_object = { ...rest, url_logo: item };

			localStorage.setItem('organization', JSON.stringify(new_object));
			dispatch({ type: ApplicationReducerTypes.SET_ORGANIZATION, payload: new_object });
		}
	}

	function clearProject() {
		localStorage.removeItem('organization');
		localStorage.removeItem('company');
		dispatch({ type: ApplicationReducerTypes.CLEAR_PROJECT });
	}

	const context = {
		...state,
		logoChange,
		updateUser,
		setOrganization,
		setCompany,
		setUser,
		clearProject,
		updateCompany,
		updateOrganization
	};

	return <ApplicationContext.Provider value={context}>{children}</ApplicationContext.Provider>;
};

export function useApplicationContext() {
	const context = useContext(ApplicationContext);
	return context;
}
