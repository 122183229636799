import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/Application';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { CustomFormItem, CustomSelect } from '../styles';
import { OptionType } from '../types';
import { useGetUsers } from '@/hooks';

const { useFormInstance } = Form;

export const Users: React.FC = () => {
	const breakpoints = useBreakpoint();
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const { data, isLoading, isFetching, isError } = useGetUsers({
		organization_id: organization.id,
		company_id: company.id
	});
	const users = data?.rows;

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const handleSelectAll = (value: string[]) => {
		if (value?.includes('all') && users) {
			if (value.length === users.length + 1) {
				return [];
			}
			return users.filter((user) => !user.id.includes('all')).map((user) => user.id);
		}
		return value;
	};

	function handleClearUser() {
		setFieldValue(['user_ids'], undefined);
	}

	return (
		<Col xs={24}>
			<CustomFormItem name={['user_ids']} getValueFromEvent={handleSelectAll} label={I18n.get('Users')}>
				<CustomSelect
					showSearch
					allowClear
					mode="multiple"
					onClear={handleClearUser}
					maxTagCount={'responsive'}
					filterOption={(input, option: any) => filterOption(option, input)}
					size={!breakpoints.xxl ? 'small' : 'large'}
					options={users?.map(({ id, name }) => ({ label: name, value: id }))}
					placeholder={I18n.get('Please select one or more')}
					disabled={isLoading || isFetching || isError}
					loading={isLoading || isFetching || isError}
					showArrow={true}
				/>
			</CustomFormItem>
		</Col>
	);
};
