import styled from 'styled-components';

export const Details = styled.div`
	margin: 1rem 0 1.5rem 0;
`;

export const DetailTitle = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
`;

export const Percentage = styled.span`
	margin-left: 0.25rem;
	color: ${(props) => props.color};
	font-weight: 600;
`;
