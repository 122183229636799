import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Redirect, Error } from './components';
import { Spinner } from '@/components/Spinner';
import { setSession } from '@/redux/auth/actions';
import { useAuthToken, UserDataResponse } from '../hooks';
import { useApplicationContext } from '@/context/Application';

export const LoginCallback: React.FC = () => {
	const history = useHistory();
	const { hash } = useLocation();
	const dispatch = useDispatch();
	const { setUser } = useApplicationContext();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const params = new URLSearchParams(hash.replace('#', ''));
	const accessToken = params.get('access_token');

	const { isLoading: isLoadingAuthToken, mutateAsync: fetchCredentials, isError } = useAuthToken();

	const handleOnSuccess = useCallback(
		(data: UserDataResponse) => {
			setUser(data);
			dispatch(setSession(data));
			history.push('/');
		},
		[dispatch, history, setUser]
	);

	useEffect(() => {
		if (accessToken) {
			fetchCredentials(accessToken, {
				onSuccess(data) {
					handleOnSuccess(data);
				},
				onSettled: () => {
					setIsLoading(false);
				}
			});
		} else {
			history.push('/');
		}
	}, [accessToken, fetchCredentials, handleOnSuccess, history]);

	if (isLoading || isLoadingAuthToken) {
		return <Spinner size={50} />;
	}

	if (isError) {
		return <Error />;
	}

	return <Redirect />;
};
