import { message } from 'antd';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import {
	ChangeDefaultAuthTypeRequest,
	ConfirmPasswordRequest,
	Deactivate2FARequest,
	EnableAuthAppRequest,
	Generate2FARequest,
	ResendTokenRequest,
	Reset2FARequest,
	Validate2FARequest
} from './types/request';
import { Find2FADTO, GetForced2FADTO } from './types/response';
import { TFAService } from './service';

const tfaService = new TFAService();

function useFind2FA(company_id: string, organization_id: string) {
	const userTFA = useQuery(['user_2fa'], () => tfaService.find2FA({ company_id, organization_id }), {
		retry: 1,
		enabled: !!company_id && !!organization_id
	});

	return {
		...userTFA,
		data: userTFA.data || ({} as Find2FADTO)
	};
}

function useGenerate2FA() {
	const queryClient = useQueryClient();
	return useMutation(
		({ data, organization_id, company_id }: Generate2FARequest) =>
			tfaService.generate2FA({ data, organization_id, company_id }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Two-factor authentication generated successfully'));
			}
		}
	);
}

function useValidate2FA() {
	const queryClient = useQueryClient();
	return useMutation(
		({ data, organization_id, company_id }: Validate2FARequest) =>
			tfaService.validate2FA({ data, organization_id, company_id }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Two-factor authentication validated successfully'));
			}
		}
	);
}

function useChangeDefault2FA() {
	const queryClient = useQueryClient();
	return useMutation(
		({ company_id, organization_id, data }: ChangeDefaultAuthTypeRequest) =>
			tfaService.changeDefault2FA({ company_id, organization_id, data }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Two-factor authentication method changed successfully'));
			}
		}
	);
}

function useSendToken() {
	const queryClient = useQueryClient();
	return useMutation(
		({ data, organization_id, company_id }: ResendTokenRequest) =>
			tfaService.sendToken({ data, organization_id, company_id }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Token sent successfully'));
			}
		}
	);
}

function useResendToken() {
	const queryClient = useQueryClient();
	return useMutation(
		({ data, organization_id, company_id }: ResendTokenRequest) =>
			tfaService.resendToken({ data, organization_id, company_id }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Token resent successfully'));
			}
		}
	);
}

function useEnableAuthApp() {
	const queryClient = useQueryClient();
	return useMutation(
		({ company_id, organization_id }: EnableAuthAppRequest) =>
			tfaService.enableAuthApp({ company_id, organization_id }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Authenticator app enabled successfully'));
			}
		}
	);
}

function useDelete2FA() {
	const queryClient = useQueryClient();
	return useMutation(
		({ company_id, organization_id }: Deactivate2FARequest) =>
			tfaService.deactivate2FA({ company_id, organization_id }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Two-factor authentication deleted successfully'));
			}
		}
	);
}

function useReset2FA() {
	const queryClient = useQueryClient();
	return useMutation(
		({ data, organization_id, company_id }: Reset2FARequest) =>
			tfaService.reset2FA({ data, organization_id, company_id }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
				message.success(I18n.get('Two-factor authentication reset successfully'));
			}
		}
	);
}

function useConfirmPassword() {
	const queryClient = useQueryClient();
	return useMutation(
		({ email, password }: ConfirmPasswordRequest) => tfaService.confirmPassword({ email, password }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['user_2fa']);
			}
		}
	);
}

function useGetForced2FA() {
	const forced2FA = useQuery(['forced_2fa'], () => tfaService.getForced2FA(), {
		retry: 1
	});

	return {
		...forced2FA,
		data: forced2FA.data || ({} as GetForced2FADTO)
	};
}

export {
	useFind2FA,
	useReset2FA,
	useSendToken,
	useDelete2FA,
	useValidate2FA,
	useGenerate2FA,
	useResendToken,
	useGetForced2FA,
	useEnableAuthApp,
	useConfirmPassword,
	useChangeDefault2FA
};
