export interface LocalStorage {
	organization: string | null;
	company: string | null;
	user: string | null;
}

export enum ApplicationReducerTypes {
	CLEAR_PROJECT = 'CLEAR_PROJECT',
	SET_ORGANIZATION = 'SET_ORGANIZATION',
	SET_COMPANY = 'SET_COMPANY',
	SET_USER = 'SET_USER'
}

export type ActionType = {
	type: ApplicationReducerTypes;
	payload?: any;
};

export type State = {
	organization: any;
	company: any;
	user: any;
};
