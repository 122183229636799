import React from 'react';
import AuthCode from 'react-auth-code-input';

import { Container } from './styles';

interface PinCodeInputProps {
	value?: number;
	allowedCharacters?: string;
	onChange?: (code: string) => void;
}

export const PinCodeInput: React.FC<PinCodeInputProps> = ({ value, onChange, ...props }: any) => {
	return (
		<Container>
			<AuthCode value={value} onChange={onChange} {...props} />
		</Container>
	);
};
