import React from 'react';
import { Col, Form, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomItemSwitch } from './styles';
import { useCustomReportManagerContext } from '../../context';

const { useFormInstance, useWatch } = Form;

export const RequireCharacteristics: React.FC = () => {
	const form = useFormInstance();
	const characteristics = useWatch('has_characteristics', form);
	const { handleStepsSequence } = useCustomReportManagerContext();

	function handleChange(): void {
		handleStepsSequence();
	}

	return (
		<Col xs={24}>
			<CustomItemSwitch name={['has_characteristics']} label={I18n.get('Require characteristics')}>
				<Switch checked={characteristics} onChange={handleChange} />
			</CustomItemSwitch>
		</Col>
	);
};
