import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Divider } from 'antd';

import { Title } from '@/components/Typography';
import { BodyPartsScores } from './BodyPartsScores';
import { Container, Score, WorstScore } from './styles';
import { usePersonalisedReportResultContext } from '../../context';

export const Reba: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();

	const reba = personalisedReportResult?.tools?.reba;
	if (!reba?.data) {
		return null;
	}

	return (
		<Row justify="center">
			<Col xs={24}>
				<Row gutter={[0, 24]}>
					<Col offset={1} span={23}>
						<Row>
							<Title style={{ margin: 0 }} level={3}>
								{I18n.get('Movement by score (Kinebot/REBA)')}
							</Title>
						</Row>
					</Col>
					<Col offset={1} span={23}>
						<Row>
							<Container>
								<Col span={24}>
									<Row>
										<WorstScore>
											{I18n.get('Worst score')}: <Score>{reba.score_seconds}</Score>
										</WorstScore>
									</Row>
								</Col>
								<Divider style={{ margin: 0 }} />
								<Col span={24}>
									<Row justify="space-evenly">
										<BodyPartsScores />
									</Row>
								</Col>
							</Container>
						</Row>
					</Col>
				</Row>
			</Col>
			<Divider type="horizontal" />
		</Row>
	);
};
