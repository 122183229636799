import { Col, Input } from 'antd';
import styled from 'styled-components';

export const Container = styled(Col)`
	border-radius: 30px;
	padding: 2rem 4rem;
	box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.05), 3px 3px 4px 0px rgba(0, 0, 0, 0.05);
	background-color: #fff;

	button {
		width: 100%;
	}

	p {
		color: rgb(204, 204, 204);
		text-align: center;
		margin-top: 16px;
		font-size: 12px;
		display: flex;
		justify-content: space-between;
	}

	@media only screen and (max-width: 600px) {
		padding: 2rem 2.5rem;
	}
`;

export const IconContainer = styled(Col)`
	font-size: 4rem;
	color: #2f54eb;
`;

export const CustomInput = styled(Input)`
	padding: 2% 4% 2% 2%;
	border-radius: 3px;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.65);
	border: 1px solid #d9d9d9;

	.site-form-item-icon {
		margin-right: 0.5rem;
	}
`;
