import { ButtonProps } from 'antd';
import React from 'react';
import { CustomButton } from './styles';

interface PrimaryButtonProps extends ButtonProps {
	children: string;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({ children, ...props }) => {
	return (
		<CustomButton type="primary" {...props}>
			{children}
		</CustomButton>
	);
};
