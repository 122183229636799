import { combineReducers } from 'redux';
import plan from './plan';
import auth from './auth';
import user from './user';
import upload from './upload';
import reports from './reports';
import organization from './organization';

export const rootReducer = combineReducers({
	auth,
	user,
	plan,
	upload,
	reports,
	organization
});

export type RootState = ReturnType<typeof rootReducer>;
