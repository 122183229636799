import React from 'react';
import { Col, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { Title } from './styles';

export const Header = () => {
	return (
		<Row>
			<Col span={5}>
				<Title>{I18n.get('Location')}</Title>
			</Col>
			<Col span={5}>
				<Title>{I18n.get('Found')}</Title>
			</Col>
			<Col span={5}>
				<Title>{I18n.get('Foreseen')}</Title>
			</Col>
			<Col span={5}>
				<Title>{I18n.get('Condition')}</Title>
			</Col>
		</Row>
	);
};
