import React from 'react';
import { Col, Row, Skeleton } from 'antd';

import { ToolCard } from './ToolCard';
import { CustomCol } from './styles';

export const LoadingSkeleton: React.FC = () => {
	const fakeData = [1, 2, 3, 4];

	return (
		<Row align="middle" gutter={[8, 8]}>
			{fakeData.map((data) => (
				<Col key={data} xs={24} xxl={12}>
					<ToolCard loading={true}>
						<Skeleton.Image active />
						<Row justify="start" style={{ width: '100%' }} gutter={[16, 16]}>
							<CustomCol xs={24}>
								<Skeleton.Input size="small" active />
							</CustomCol>
							<CustomCol xs={24}>
								<Skeleton.Input size="small" active />
							</CustomCol>
						</Row>
					</ToolCard>
				</Col>
			))}
		</Row>
	);
};
