import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Response, CompanyDTO } from '@/types';

type OrganizationDTO = {
	id: string;
	name: string;
	url_logo: string;
	is_active: boolean;
	created_at: Date;
	updated_at: Date;
	companies: CompanyDTO[];
};

async function getOrganization(id: string): Promise<OrganizationDTO> {
	const url = `/organization/${id}`;
	const { data } = await Api.get<OrganizationDTO>(url);
	return data;
}

export const useGetOrganization = (id: string) => {
	const customReport = useQuery<OrganizationDTO, AxiosError<Response>>(['organizations', id], () => getOrganization(id), {
		enabled: !!id,
		retry: 0
	});
	return customReport;
};

