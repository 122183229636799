import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Label } from './styles';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { useGetOrganizations } from '@/hooks/useGetOrganizations';
import { hierarchyHandler, HierarchiesField } from '@/utils/HierarchyHandler';

const { useFormInstance } = Form;

export function Organizations() {
	const form = useFormInstance();

	const title = I18n.get('Company');
	const fieldName = HierarchiesField.ORGANIZATION;

	const {
		data: organizations,
		isFetching: isGettingOrganizations,
		isError: errorGettingOrganizations
	} = useGetOrganizations();

	function handleSelectOrganization(id: string): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			form.resetFields([...fieldsToReset, 'evaluator_id']);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearOrganization(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			form.resetFields([...fieldsToReset, 'evaluator_id']);
		}
	}

	return (
		<Form.Item name={fieldName} label={<Label>{title}</Label>}>
			<SelectWithCreation
				showSearch
				allowClear
				placeholder={title}
				selectOptions={organizations}
				loading={isGettingOrganizations}
				onClear={handleClearOrganization}
				onChange={handleSelectOrganization}
				disabled={!organizations || errorGettingOrganizations}
			/>
		</Form.Item>
	);
}
