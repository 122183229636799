import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import Can from '@/components/Can';
import { ActionsButtonsContainer } from './styles';
import { DownloadVideoIcon } from '../DownloadVideoIcon';

interface InlineProps {
	hasFile: boolean;
	disabled: boolean;
	disableDelete?: boolean;
	onEditReportClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onDeleteReport: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
	onDownloadPDFClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
	onDownloadVideoClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
}

export function Inline({
	hasFile,
	// disabled,
	onDeleteReport,
	onEditReportClick,
	onDownloadVideoClick,
	disableDelete
}: Readonly<InlineProps>) {
	const disabledProps: React.CSSProperties = {
		color: '#ffffff',
		backgroundColor: '#ff4d4f'
	};

	const permissionRole = Can('delete', 'report');

	return (
		<ActionsButtonsContainer align="middle" justify="space-evenly">
			<Col lg={5}>
				<Tooltip title={I18n.get('Edit')}>
					<Button type="ghost" onClick={onEditReportClick} icon={<EditOutlined />} />
				</Tooltip>
			</Col>
			<Col lg={5}>
				<Tooltip
					showArrow={hasFile}
					overlayInnerStyle={!hasFile ? disabledProps : undefined}
					title={I18n.get(!hasFile ? 'N/A' : 'Download video')}
				>
					<Button
						type="ghost"
						disabled={!hasFile}
						icon={<DownloadVideoIcon />}
						onClick={onDownloadVideoClick}
					/>
				</Tooltip>
			</Col>
			<Col lg={5}>
				<Tooltip title={I18n.get('Delete')}>
					<Button
						disabled={!permissionRole || disableDelete}
						onClick={onDeleteReport}
						danger
						type="ghost"
						icon={<DeleteOutlined />}
					/>
				</Tooltip>
			</Col>
		</ActionsButtonsContainer>
	);
}
