import React from 'react';
import { ModalProps } from 'antd';

import { ModalContainer } from './styles';

interface CustomModalProps extends ModalProps {}

export function CustomModal(props: Readonly<CustomModalProps>) {
	return <ModalContainer {...props}>{props.children}</ModalContainer>;
}
