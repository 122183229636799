import React from 'react';
import { Pie } from 'react-chartjs-2';

import { DIAGNOSTIC_COLORS } from '@/utils/constants';
import { AnglesType } from '../../../../PosturesAndMovements/types';

interface PieChartProps {
	scores: AnglesType;
}

export const PieChart: React.FC<PieChartProps> = ({ scores }) => {
	const data = {
		datasets: [
			{
				backgroundColor: DIAGNOSTIC_COLORS,
				borderColor: DIAGNOSTIC_COLORS,
				borderWidth: 1,
				data: scores,
				datalabels: {
					display: false
				}
			}
		]
	};

	const options = {
		plugins: {
			tooltip: {
				enabled: false // Hide tooltip
			}
		}
	};

	return <Pie data={data} options={options} />;
};
