import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Consequence, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type GetConsequencesDTO = BaseContext & {
	score_scale_id?: string;
};

export type ConsequenceResponse = Pick<Consequence, 'id' | 'name' | 'description' | 'score'>;

async function getConsequences<T, Body>(payload: Body): Promise<T> {
	const url = `/ergonomic-tool/consequences`;
	const { data } = await Api.get<T>(url, {
		params: payload
	});
	return data;
}

export function useGetConsequences({ organization_id, company_id, score_scale_id }: GetConsequencesDTO) {
	const parameters = { organization_id, company_id, score_scale_id };
	const consequences = useQuery<ConsequenceResponse[], AxiosError<Response>>(
		['consequences', parameters],
		() => getConsequences<ConsequenceResponse[], GetConsequencesDTO>(parameters),
		{
			retry: 0,
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...consequences,
		data: consequences.data ?? ([] as ConsequenceResponse[])
	};
}
