import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useSectors } from '@/hooks/v1';
import { FormItem } from '../../../components/FormItem';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect/select';
import { usePersonalisedReportContext } from '../../../../context';

const { useFormInstance, useWatch } = Form;

export const Sectors: React.FC = () => {
	const form = useFormInstance();
	const { organization_id, company_id } = usePersonalisedReportContext();

	const fieldName = ['basic_information', 'sector_id'];

	const sectorId = useWatch(fieldName, form);
	const companyId = useWatch(['basic_information', 'company_id'], form);

	const {
		sectors: { data: sectors, isLoading, isFetching, isError }
	} = useSectors({
		organizationId: organization_id,
		companyId: company_id
	});

	function handleSectorSelect(id: string): void {
		form.setFieldValue(fieldName, id);
		form.setFieldValue(['basic_information', 'workstation_id'], undefined);
	}

	function handleClearSector(): void {
		form.setFieldValue(fieldName, undefined);
		form.setFieldValue(['basic_information', 'workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				title="Sector"
				fontSize="1rem"
				isRequired={true}
				namePath={fieldName}
				errorMessage={I18n.get('Select a sector')}
			>
				<SectorSelect
					size="large"
					sectors={sectors}
					sectorId={sectorId}
					loading={isFetching}
					fieldName={fieldName}
					handleClearSector={handleClearSector}
					handleSelectSector={handleSectorSelect}
					disabled={isLoading || isFetching || isError || !companyId}
				/>
			</FormItem>
		</Col>
	);
};
