import Api from '@/services/api';

const getCustomerInformation = async () => {
	try {
		const { data } = await Api.get('/customer/info');
		return data;
	} catch (error) {
		throw error;
	}
};

export const Services = { getCustomerInformation };
