import { Col } from 'antd';
import styled from 'styled-components';

interface FieldContainerProps {
	disabled?: boolean;
}

export const FieldContainer = styled(Col)<FieldContainerProps>`
	opacity: ${({ disabled }) => (disabled ? '40%' : '100%')};
`;
