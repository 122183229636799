import React from 'react';
import { I18n } from '@aws-amplify/core';

import { usePersonalisedReportContext } from '../../../context';

export interface IconType {
	name: string;
	position: number;
	currentStep: number;
}

export const StepIcon: React.FC<IconType> = ({ currentStep, name, position }) => {
	const { personalisedReport, handleStepNagivationClick } = usePersonalisedReportContext();

	if (currentStep === position) {
		return <span className="step-name">{I18n.get(name)}</span>;
	}

	const disabled = isNotCompleted() && stepDifferenceIsGreaterThanOne(position) ? 'disabled-step' : '';

	function handleStepChange(): void {
		if (isConsolidated()) {
			return;
		}
		if (isNotCompleted() && stepDifferenceIsGreaterThanOne(position)) {
			return;
		}
		handleStepNagivationClick(position);
	}

	function defineClassNameForUnhoveredIcon(): string {
		if (isConsolidated()) {
			return `step-number consolidated ${disabled}`;
		}
		if (currentStep > position) {
			return 'step-checked';
		}
		return `step-number ${disabled}`;
	}

	function defineClassNameForHoveredIcon(): string {
		if (isConsolidated()) {
			return `step-number-hovered consolidated ${disabled}`;
		}
		if (currentStep < position) {
			return `step-number-hovered ${disabled}`;
		}
		return 'step-checked-hovered';
	}

	function isConsolidated(): boolean {
		return !!personalisedReport.consolidated;
	}

	function stepDifferenceIsGreaterThanOne(step: number) {
		return step > currentStep + 1;
	}

	function isNotCompleted(): boolean {
		return !personalisedReport.is_completed;
	}

	return (
		<div className="step-hover">
			<button className={defineClassNameForUnhoveredIcon()} onClick={() => handleStepChange()}>
				{position + 1}
			</button>
			<button className={defineClassNameForHoveredIcon()} onClick={() => handleStepChange()}>
				{I18n.get(name)}
			</button>
		</div>
	);
};
