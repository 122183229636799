import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { FieldsProps } from '../types';
import { CustomFormItem, TextInput } from '../../styles';

const { useFormInstance, useWatch } = Form;

export function Nit({ name, required = false }: Readonly<FieldsProps>) {
	const form = useFormInstance();
	const formValue = useWatch(name);

	function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
		let value = e.target.value;
		value = value.replace(/\D/g, '');
		form.setFieldValue(name, value);
	}

	async function handleNITValidator(): Promise<void> {
		if (required && !formValue) {
			return Promise.reject(I18n.get('Enter a NIT'));
		}

		return Promise.resolve();
	}

	return (
		<CustomFormItem
			name={name}
			label="NIT"
			labelCol={{ span: 24 }}
			rules={[{ required, validator: handleNITValidator }]}
		>
			<TextInput maxLength={13} onChange={handleOnChange} placeholder={I18n.get('000.000.000-0')} />
		</CustomFormItem>
	);
}
