import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';

import { useApplicationContext } from '@/context/Application';
import { setSession } from '@/redux/auth/actions';
import { AuthUserDTO } from '@/types/AuthUser';
import { useSignIn } from '@/hooks/v1/useSignIn';
import { LoginForm } from './Form';
import type { FormData } from './types';

export function Content() {
	const history = useHistory();
	const dispatch = useDispatch();
	const [form] = useForm<FormData>();
	const { setUser } = useApplicationContext();
	const { isLoading, mutateAsync, error } = useSignIn();

	async function handleOnSubmit(value: FormData): Promise<void> {
		const data = await mutateAsync(value);

		const { user, token, refresh_token } = data;

		setUser({ user, token, refresh_token });

		if (is2FALoginValidated(data)) {
			return history.push(`/check-two-factor/${user.id}`);
		}

		if (forced2FAIsEnabled(data)) {
			return history.push(`/generate-two-factor/${user.id}`);
		}

		dispatch(setSession({ user, token, refresh_token }));
		history.push('/');
	}

	function is2FALoginValidated(userSession: AuthUserDTO) {
		return userSession?.two_fa?.is_2fa_login_enabled && userSession?.two_fa?.validated;
	}

	function forced2FAIsEnabled(userSession: AuthUserDTO) {
		return !!userSession.user.force_two_fa;
	}

	return <LoginForm form={form} loading={isLoading} onFinish={handleOnSubmit} errors={error} />;
}
