import React from 'react';
import { I18n } from '@aws-amplify/core';

import { CustomInput } from './styles';
import { CustomFormItem } from '../../styles';

interface DescriptionProps {
	creating: boolean;
}

export const Description: React.FC<DescriptionProps> = ({ creating }) => (
	<CustomFormItem name={['step_key', 'description']}>
		<CustomInput disabled={creating} placeholder={I18n.get('Criterion title')} size="large" />
	</CustomFormItem>
);
