import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import Api from '@/services/api';
import { CreateReportDTO, CreateReportResponse } from '@/views/CreateReport/context/types';

type CreateNioshError = AxiosError<any>;

async function createReport(payload: CreateReportDTO): Promise<CreateReportResponse> {
	const { data } = await Api.post('/ergonomic-tool/niosh', payload);
	return data;
}

export const useCreateNiosh = () => {
	const queryClient = useQueryClient();
	return useMutation<CreateReportResponse, CreateNioshError, CreateReportDTO>((payload) => createReport(payload), {
		onSuccess: async () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_NIOSH_REPORT]);
		},
		onError: (err) => {
			const title = 'Oops! Something happened.';
			const message = err.response?.data?.message ?? 'Failed to create information';
			notification.error({ message: I18n.get(title), description: I18n.get(message) });
		}
	});
};
