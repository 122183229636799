export enum ScoreScales {
	RPN_SIX = 'rpn_six_levels',
	RPN_THREE = 'rpn_three_levels',
	WILLIAM_T_FINE = 'william_t_fine'
}

export type CustomReportScoreScale = {
	id: string;
	name: ScoreScales;
	description: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date | null;
};
