import Api from '@/services/api';
import { ChangePasswordParams, ChangePasswordDTO } from './types';

const setChangePassword = async ({ currentPassword, newPassword }: ChangePasswordParams) => {
	try {
		const payload = { current_password: currentPassword, password: newPassword };
		const { data } = await Api.put<ChangePasswordDTO>('/user/change-password', payload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const Services = { setChangePassword };
