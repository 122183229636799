import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TooltipItem
} from 'chart.js';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { I18n } from '@aws-amplify/core';
import { Bar } from 'react-chartjs-2';
import { Col, Row } from 'antd';
import { MinutesDetailsDTO } from '../hooks/types/response';

import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartCollectionProps {
	chartData?: MinutesDetailsDTO;
}

export const BarChartCollection: React.FC<BarChartCollectionProps> = ({ chartData = [] }) => {
	const breakpoints = useBreakpoint();
	const labels = Object.keys(chartData);
	const dataValues = Object.values(chartData);

	function formatTimeText(minutesProcessed: number) {
		if (!minutesProcessed) {
			return '0 m';
		}

		const duration = moment.duration(minutesProcessed, 'minutes');
		const seconds = duration.seconds();
		const minutes = duration.minutes();
		const hours = duration.hours();

		let message = '';

		if (hours) {
			message = `${hours}h `;
		}

		if (minutes || (hours && seconds)) {
			message += `${minutes}m `;
		}

		if (seconds) {
			message += `${seconds}s`;
		}

		return message;
	}

	const options = {
		responsive: true,
		animation: {
			duration: 0
		},
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: false
			},
			datalabels: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function (context: TooltipItem<'bar'>) {
						let label = context.dataset.label || '';

						if (context.parsed.y !== null) {
							label = formatTimeText(context.parsed.y);
						}
						return label;
					}
				}
			}
		},
		scales: {
			x: {
				grid: {
					display: false
				},
				ticks: {
					color: 'black'
				}
			},
			y: {
				title: {
					display: true,
					text: I18n.get('Minutes'),
					color: 'black'
				},
				ticks: {
					color: 'black'
				},
				max: Math.max(...dataValues) + Math.trunc(Math.max(...dataValues) * 0.1),
				grid: {
					lineWidth: 1,
					color: 'rgba(38, 38, 38, 0.50)'
				}
			}
		}
	};

	const data = {
		labels,
		datasets: [
			{
				data: dataValues,
				borderColor: '#2F54EB',
				backgroundColor: '#2F54EB',
				barPercentage: 0.2
			}
		]
	};

	return (
		<Row justify="center">
			<Col span={!breakpoints.xxl ? 19 : 22} style={{ marginRight: '2rem' }}>
				<Bar
					style={{ maxHeight: `${!breakpoints.xxl ? '150px' : '250px'}`, width: '100%' }}
					options={options}
					data={data}
				/>
			</Col>
		</Row>
	);
};
