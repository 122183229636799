import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Input } from 'antd';

interface FilterByCelluleProps {
	onCelluleChange: (cellule: string) => void;
}

export const FilterByCellule: React.FC<FilterByCelluleProps> = ({ onCelluleChange }) => (
	<Row gutter={[16, 8]}>
		<Col span={24}>
			<span>{I18n.get('Cellule')}</span>
		</Col>
		<Col span={24}>
			<Form.Item name="cellule">
				<Input placeholder={I18n.get('Cellule')} onChange={(event) => onCelluleChange(event.target.value)} />
			</Form.Item>
		</Col>
	</Row>
);
