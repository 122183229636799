import React from 'react';
import { Row, Col } from 'antd';
import { Paragraph, Title } from '@/components/Typography';

export function Header() {
	return (
		<Row justify="center" style={{ textAlign: 'center', padding: '0.8vh' }}>
			<Col span={24}>
				<Title level={3}>Minutes used</Title>
			</Col>
			<Col span={24}>
				<Paragraph>Track the minutes used on the platform during the desired period</Paragraph>
			</Col>
		</Row>
	);
}
