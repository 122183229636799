import React from 'react';
import { Button, Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import { BackButton } from '../../components/BackButton';
import { usePersonalisedReportResultContext } from './context';
import { usePersonalisedReportContext } from '../../../context';

export const Footer: React.FC = () => {
	const history = useHistory();
	const { isLoading, personalisedReportResult } = usePersonalisedReportResultContext();
	const { handlePreviousStep, report_id } = usePersonalisedReportContext();

	function handlePreviousClick(): void {
		handlePreviousStep();
	}

	function handleNextClick(): void {
		history.push(`/reporting/list/personalised-report/${report_id}`);
	}

	return (
		<Row>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				<Row justify="center">
					{!personalisedReportResult.consolidated && (
						<BackButton onPreviousClick={handlePreviousClick} disabled={isLoading} />
					)}
					<Col>
						<Button type="primary" htmlType="submit" disabled={isLoading} onClick={handleNextClick}>
							{I18n.get(personalisedReportResult.consolidated ? 'Close' : 'Finish')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
