import React from 'react';
import { Skeleton } from 'antd';

import { CustomListLoading } from './styles';

export const LoadingSkeleton: React.FC = () => {
	const fakeData = [1, 2, 3];

	return (
		<>
			{fakeData.map((id) => (
				<CustomListLoading key={id} xs={24}>
					<Skeleton.Input active block size="small" />
				</CustomListLoading>
			))}
		</>
	);
};
