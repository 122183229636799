import React from 'react';
import { Col, Form, Row } from 'antd';

import { MailOutlined, QrcodeOutlined } from '@ant-design/icons';
import { MethodTypes, TFATips } from '@/types/AuthUser';

import { IconContainer, Tip } from './styles';

const { useFormInstance } = Form;

export const PreAuthentication2FA: React.FC = () => {
	const { getFieldValue } = useFormInstance();
	const method = getFieldValue('method_type');

	return (
		<Col>
			<IconContainer justify="center">
				{method === MethodTypes.AUTHENTICATOR ? <QrcodeOutlined /> : <MailOutlined />}
			</IconContainer>
			<Row justify="center">
				{method === MethodTypes.AUTHENTICATOR ? (
					<Tip style={{ width: '75%' }}>{TFATips.TIP_APP}</Tip>
				) : (
					<Tip style={{ width: '65%' }}>{TFATips.TIP_EMAIL}</Tip>
				)}
			</Row>
		</Col>
	);
};
