import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetKimMhoDTO {
	organization_id: string;
	company_id: string;
	id: string;
}

export type KimMhoForceIntensity =
	'VERY_LOW' | 'MODERATE' | 'HIGH' | 'VERY_HIGH' | 'PEAK' | 'POWERFUL_HITTING'

type GetKimMhoResponse = {
	id: string;
	report_name: string;
	collection_date: string;
	organization_id: string;
	company_id: string;
	sector_id: string;
	cellule_id?: string;
	workstation_id: string;
	evaluator_id: string;
	file_id?: string;
	duration?: number;
	left_force_frequency?: number;
	left_force_type?: string;
	left_force_intensity?: KimMhoForceIntensity;
	right_force_frequency?: number;
	right_force_type?: string;
	right_force_intensity?: KimMhoForceIntensity;
	arm_posture?: string;
	work_conditions?: string;
	temporal_distribution?: string;
	body_posture?: string;
	current_step?: number;
	workstation: {
		name: string,
		id: string;
		sectors: {
			name: string,
			id: string;
		}
	},
	evaluator: {
		name: string;
		id: string
	}
};

type GetKimMhoError = AxiosError<any>;

export async function getKimMho(payload: GetKimMhoDTO) {
	const { organization_id, company_id, id } = payload;
	const url = `/ergonomic-tool/kim-mho/${id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetKimMho = ({ organization_id, company_id, id }: GetKimMhoDTO) => {
	const payload = { organization_id, company_id, id };
	return useQuery<GetKimMhoResponse, GetKimMhoError>(
		[QUERY_KEYS.GET_KIM_MHO_REPORT, payload],
		() => getKimMho(payload),
		{
			enabled: !!organization_id && !!company_id && !!id
		}
	);
};
