import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { customReportService } from './services';
import { CreateOrUpdate } from '../context/types';
import { CustomReportResult, Response } from '@/types';

export type PersonalisedReportResultUpdateResponse = CustomReportResult & {
	sector_id: string;
	company_id: string;
	organization_id: string;
};

async function updateResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useUpdateResult() {
	const queryClient = useQueryClient();
	return useMutation<PersonalisedReportResultUpdateResponse, AxiosError<Response>, CreateOrUpdate>(
		(body) => updateResult<PersonalisedReportResultUpdateResponse, CreateOrUpdate>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
}
