export type ErgonomicToolCategory = {
	id: string;
	name: string;
	description: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date | null;
};

export enum ErgonomicToolNames {
	LOAD = 'load',
	BIOMECHANICAL = 'biomechanical',
	REPEATABILITY = 'repeatability',
	ERGONOMIC_ANALYSIS = 'ergonomic_analysis',
	ADMINISTRATIVE = 'administrative_activities'
}
