import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
	.ant-modal-content {
		padding: 24px;
		box-shadow: none;
		min-height: 400px;
		border-radius: 32px;
	}
	.ant-modal-body {
		margin: 0;
		padding: 0 24px;
	}
`;
