import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Modal } from 'antd';

import { Title } from '@/components/Typography';
import { CreateActionPlan } from './Create';
import { EditActionPlan } from './EditActionPlan';
import { BoardColumn } from './BoardColumn';
import { Filter } from './Filter';
import { LexoRank } from './LexoRank';
import { Spinner } from '@/components/Spinner';

import { useUpdateActionPlan, useDeleteActionPlan } from '@/hooks/v1/useActionPlanMutations';
import { useApplicationContext } from '@/context/Application';
import { useCompanies } from '@/hooks/v1/useCompanies';
import { useSectors } from '@/hooks/v1/useSectors';
import { ACTION_PLAN_BOARDS } from '@/utils/enum';
import { ActionPlansContext } from './context';
import { useFiles } from '@/hooks/v1/useFiles';
import { useUsers } from '@/hooks/v1/useUsers';

const initState = {
	selectedCompanyId: '',
	selectedSectorId: '',
	setSelectedFileId: '',
	actionPlans: [],
	triggerReload: false,
	actionPlanEditModal: false,
	actionPlanCreateModal: false,
	currentActionPlan: {},
	creationBoard: '',
	currentFilter: '',
	isClearFilter: false,
	cardSource: {}
};

const lexorank = new LexoRank();

export function ActionPlans() {
	const [state, setState] = useState(initState);

	const history = useHistory();
	const { search } = useLocation();
	const { organization, company } = useApplicationContext();

	const columnOrder = ACTION_PLAN_BOARDS;

	const companies = useCompanies(organization?.id);

	const { sectors } = useSectors({
		organizationId: organization?.id,
		companyId: state.selectedCompanyId
	});

	const { files } = useFiles({
		organizationId: organization?.id,
		companyId: state.selectedCompanyId,
		sectorId: state.selectedSectorId
	});

	const { users: responsibleUsers } = useUsers({
		organizationId: organization?.id,
		companyId: company?.id
	});

	const { mutateAsync: deleteActionPlan } = useDeleteActionPlan();
	const { mutateAsync: updateActionPlan, isLoading } = useUpdateActionPlan();

	useEffect(() => {
		search && setActionPlanFromDash();
	}, []);

	function setActionPlanFromDash() {
		const id = search.split('id=')[1];

		const parameters = {
			organization_id: organization?.id,
			company_id: company?.id,
			...(id && { id })
		};
		const paramsMounting = new URLSearchParams(parameters);

		setState((prev) => ({
			...prev,
			currentFilter: paramsMounting.toString()
		}));
	}

	function setSelectedCompanyId(value) {
		setState((prev) => ({
			...prev,
			selectedCompanyId: value,
			selectedSectorId: null,
			selectedFileId: null
		}));
	}

	function setActionPlans(action_plans_list) {
		setState((prev) => {
			if (action_plans_list.length > 0) {
				const updatedActionPlans = prev.actionPlans.map((plan) => {
					const matchingPlan = action_plans_list.find((item) => item.id === plan.id);
					return matchingPlan ? matchingPlan : plan;
				});

				const newActionPlans = action_plans_list.filter((item) => {
					return !prev.actionPlans.some((plan) => plan.id === item.id);
				});

				return {
					...prev,
					actionPlans: [...updatedActionPlans, ...newActionPlans]
				};
			}
			return {
				...prev,
				actionPlans: []
			};
		});
	}

	function setSelectedSectorId(value) {
		setState((prev) => ({
			...prev,
			selectedSectorId: value,
			selectedFileId: null
		}));
	}

	function setSelectedFileId(value) {
		setState((prev) => ({ ...prev, selectedFileId: value }));
	}

	function setSelectedIsClearFilter(value) {
		setState((prev) => ({ ...prev, isClearFilter: value }));
	}

	function setSelectedcardSource(value) {
		setState((prev) => ({ ...prev, cardSource: value }));
	}

	function setTriggerReload() {
		setState((prev) => ({ ...prev, triggerReload: !prev.triggerReload }));
		search && history.push('/action-plans');
	}

	function onDeleteActionPlan(actionPlanId) {
		Modal.confirm({
			title: I18n.get('Warning!'),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Do you want to delete this action plan?'),
			async onOk() {
				const body = { actionPlanId, organizationId: organization?.id };
				await deleteActionPlan({ body });
			}
		});
	}
	async function updateActionPlanBoard(action_plan) {
		const body = { action_plan, organizationId: organization?.id };

		updateActionPlan({ body });
	}

	function onEditActionPlan(actionPlan) {
		setState((prev) => ({ ...prev, currentActionPlan: actionPlan }));
		setState((prev) => ({ ...prev, actionPlanEditModal: true }));
	}

	function showCreateActionPlan(board) {
		setState((prev) => ({
			...prev,
			creationBoard: board,
			actionPlanCreateModal: true
		}));
	}

	function onClose() {
		setState((prev) => ({ ...prev, actionPlanEditModal: false }));
		setState((prev) => ({ ...prev, actionPlanCreateModal: false }));
		setTriggerReload();
	}

	async function onFilterActionPlans(values) {
		if (!organization?.id) return;

		setActionPlans([]);

		const {
			title,
			responsible_user_id,
			deadline_status,
			deadline_date,
			company_id,
			sector_id,
			workstation,
			file_id,
			score
		} = values || {};

		const parameters = {
			organization_id: organization?.id,
			company_id: company_id ?? company?.id,
			...(title && { title }),
			...(responsible_user_id && { responsible_user_id }),
			...(deadline_status && { deadline_status }),
			...(deadline_date && { deadline_date }),
			...(score && { score }),
			...(sector_id && { sector_id }),
			...(workstation && { workstation }),
			...(file_id && { file_id })
		};

		const paramsMounting = new URLSearchParams(parameters);

		setState((prev) => ({
			...prev,
			currentFilter: paramsMounting.toString()
		}));
	}

	function calcProgress(tasks) {
		const completedTasks = tasks.filter((task) => task.is_completed === true);
		const progress = (completedTasks.length / tasks.length) * 100;
		return Math.round(progress);
	}

	function persistDraggedPosition({ source, destination, draggableId }) {
		const draggedItem = state.actionPlans.find((item) => item.id === draggableId);

		const destinationActionPlans = state.actionPlans?.filter(
			(item) => item.board === destination.droppableId.toUpperCase()
		);

		const toIndex = destination.index;
		let prev, next;

		if (source.droppableId !== destination.droppableId) {
			prev = destinationActionPlans[toIndex - 1];
			next = destinationActionPlans[toIndex];
		} else {
			const sameBoardOffset = toIndex < destinationActionPlans.indexOf(draggedItem) ? 0 : 1;
			prev = destinationActionPlans[toIndex - 1 + sameBoardOffset];
			next = destinationActionPlans[toIndex + sameBoardOffset];
		}

		const prevLexo = prev ? prev.lexo_rank : '0';
		const nextLexo = next ? next.lexo_rank : 'z';

		let [newLexoRank] = lexorank.insert(prevLexo, nextLexo);

		draggedItem.board = destination.droppableId.toUpperCase();
		draggedItem.lexo_rank = newLexoRank;

		return [state.actionPlans, draggedItem];
	}

	async function onDragEnd(result) {
		const { destination, source, draggableId } = result;

		setSelectedcardSource({ source: source.droppableId, destination: destination.droppableId });

		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const [actionPlans, draggedActionPlan] = persistDraggedPosition({
			source,
			destination,
			draggableId
		});

		actionPlans.sort((a, b) => (a.lexo_rank < b.lexo_rank ? -1 : a.lexo_rank > b.lexo_rank ? 1 : 0));

		setState((prev) => ({ ...prev, actionPlans }));
		updateActionPlanBoard(draggedActionPlan);
	}

	const handleActionPlan = (array) => {
		const getActionPlan = array.find((elem) => elem.id === search.slice(4));
		search && onEditActionPlan(getActionPlan);
	};

	useEffect(() => {
		state.actionPlans && handleActionPlan(state.actionPlans);
	}, [state.actionPlans]);

	const context = {
		...state,
		companies,
		sectors,
		files,
		responsibleUsers,
		setSelectedCompanyId,
		setSelectedSectorId,
		setSelectedFileId,
		setTriggerReload,
		setActionPlans,
		showCreateActionPlan,
		onDeleteActionPlan,
		onEditActionPlan,
		onFilterActionPlans,
		calcProgress,
		setSelectedIsClearFilter
	};

	if (!organization || !company) {
		return <Spinner />;
	}

	return (
		<ActionPlansContext.Provider value={context}>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Title level={3}>Action plans</Title>
				</Col>
				<Col span={24}>
					<Filter />
				</Col>
				<Col span={24}>
					<Row>
						<DragDropContext onDragEnd={onDragEnd}>
							{columnOrder.map((board) => (
								<BoardColumn
									key={board.id}
									type={board.type}
									title={board.name}
									bgColor={board.bg_color}
									isUpdatingCard={isLoading}
								/>
							))}
						</DragDropContext>
						<CreateActionPlan onClose={onClose} visible={state.actionPlanCreateModal} />
						{state.actionPlanEditModal && state.currentActionPlan && (
							<EditActionPlan
								onClose={onClose}
								calcProgress={calcProgress}
								actionPlan={state.currentActionPlan}
								responsible_users={responsibleUsers}
								visible={state.actionPlanEditModal}
								updateActionPlan={updateActionPlan}
							/>
						)}
					</Row>
				</Col>
			</Row>
		</ActionPlansContext.Provider>
	);
}
