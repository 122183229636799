import React from 'react';
import { Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import hooks from '../../hooks';

const { useGetOrganization } = hooks;

export function OrganizationsSelect() {
	const { data: organizations } = useGetOrganization();

	return (
		<Form.Item name="organization">
			<Select
				showSearch
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				disabled={organizations.length === 0}
				placeholder={I18n.get('Company')}
				options={organizations.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
