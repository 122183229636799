import { Button, Col, Row } from 'antd';
import styled from 'styled-components';

export const CustomRow = styled(Row)`
	width: 100%;
	position: relative;
	margin-bottom: 1rem;
`;

export const StepButton = styled.button`
	font-size: 1rem;
	cursor: pointer;
	height: max-content;
	background: #eaeefd;
	border-radius: 0.5rem;
	padding: 0.25rem 1.5rem;
	border: 2px solid #eaeefd;

	transition: all 0.5s;
	:hover {
		filter: brightness(0.9);
	}
`;

export const SummaryButton = styled(Button)`
	font-size: 1rem;
	height: max-content;
	border-radius: 0.5rem;
	padding: 0.25rem 1.5rem;
`;

export const StepListCol = styled(Col)`
	position: relative;
`;

export const StepListContainer = styled.div`
	width: 85%;
	max-height: 50px;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	flex-direction: row;
	display: inline-flex;

	::-webkit-scrollbar-track {
		border: none;
		padding: 0 2px;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 85%;
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 0.5rem;
		background-color: transparent;
		box-shadow: inset 0 0 6px #9c9c9c;
	}
`;

export const EmptyContainer = styled.div`
	min-width: 2rem;
	background-color: #ffffff;
`;

export const SummaryButtonContainer = styled.div`
	top: 50%;
	display: flex;
	right: 0.5rem;
	padding: 0rem 1rem;
	position: absolute;
	flex-direction: row;
	border: 2px solid rgba(255, 255, 255, 0.95);
	background: linear-gradient(275deg, #ffffff 4.53%, rgba(255, 255, 255, 0.95) 115.16%);
`;

export const ShadowContainer = styled.div`
	height: 100%;
	left: -0.5rem;
	position: absolute;
	box-shadow: 5px -1px 4px 1px rgba(0, 0, 0, 0.3);
`;
