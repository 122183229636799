import React from 'react';
import { Row, Col, Form } from 'antd';

import { Card } from '../../Card';
import { ToolsList } from './ToolsList';
import { Description } from './Description';
import { RequireTool } from './RequireTool';
import { ToolList } from '../context/types';
import { RequireInjury } from './RequireInjury';
import { CustomButton, CustomPlusCircleFilled } from './styles';
import { useCreateStepKey, useGetErgonomicTools } from '../hooks';
import { useCustomReportManagerStepKeyContext } from '../context';
import { useApplicationContext } from '@/context/Application';

const { useFormInstance, useWatch } = Form;

type DefineErgonomicTools = {
	ergonomic_tools: string[];
	selectedTools: ToolList[];
};

type SelectedTools = {
	[key: string]: boolean;
};

export const CreateStepKey: React.FC = () => {
	const form = useFormInstance();
	const hasTool = useWatch(['step_key', 'has_tool'], form);
	const { company, organization } = useApplicationContext();
	const description = useWatch(['step_key', 'description'], form);
	const { addStepKeyToStepKeyList } = useCustomReportManagerStepKeyContext();

	const { mutateAsync: createStep, isLoading } = useCreateStepKey();
	const {
		data: ergonomicTools,
		isLoading: gettingErgonomicTools,
		isFetching: fetchingErgonomicTools,
		isError: errorGettingErgonomicTools
	} = useGetErgonomicTools({
		hasTool,
		company_id: company?.id,
		organization_id: organization?.id
	});

	async function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
		event.preventDefault();
		event.stopPropagation();
		if (!description) {
			return;
		}
		const { has_injury, has_tool, tools } = form.getFieldValue(['step_key']);
		const { ergonomic_tools, selectedTools } = defineErgonomicTools(has_tool, tools);
		const body = {
			description,
			ergonomic_tools,
			company_id: company?.id,
			has_injury: has_injury ?? false,
			organization_id: organization?.id
		};

		const created = await createStep(body);
		if (created?.id) {
			form.resetFields(['step_key']);
			addStepKeyToStepKeyList({
				id: created.id,
				tools: selectedTools,
				hasInjury: created.has_injury,
				description: created.description
			});
		}
	}

	function defineErgonomicTools(has_tool: boolean, tools: SelectedTools): DefineErgonomicTools {
		const ergonomic_tools = [];
		const selectedTools = [];
		if (has_tool) {
			for (const toolId in tools) {
				if (tools[toolId]) {
					const ergonomicTool = ergonomicTools?.find(({ id }) => toolId === id);
					if (ergonomicTool) {
						const selectedTool: ToolList = {
							id: ergonomicTool?.id,
							name: ergonomicTool?.name,
							title: ergonomicTool?.title,
							subtitle: ergonomicTool?.subtitle
						};
						selectedTools.push(selectedTool);
					}
					ergonomic_tools.push(toolId);
				}
			}
		}
		return { ergonomic_tools, selectedTools };
	}

	return (
		<Card title="Create criterion">
			<Row justify="space-between" align="middle" gutter={[10, 18]}>
				<Col span={22}>
					<Description creating={isLoading} />
				</Col>
				<Col span={2}>
					<CustomButton type="submit" onClick={handleClick} disabled={!description || isLoading}>
						<CustomPlusCircleFilled inactive={!description || isLoading} />
					</CustomButton>
				</Col>
				<Col span={24}>
					<RequireInjury />
				</Col>
				<Col span={24}>
					<RequireTool />
				</Col>
				{hasTool && (
					<Col span={24}>
						<ToolsList
							ergonomicTools={ergonomicTools}
							isError={errorGettingErgonomicTools}
							isLoading={gettingErgonomicTools || fetchingErgonomicTools}
						/>
					</Col>
				)}
			</Row>
		</Card>
	);
};
