import React from 'react';
import { Col, Row } from 'antd';

import { PersonalisedReportStepKeyProvider } from '../../components/DefaultStepKey/context';
import { PersonalisedReportStepKeysResponse } from '../../../hooks';
import { CollapseCustom } from '../../components/CollapseCustom';
import { Paragraph, Title } from '@/components/Typography';
import { KinebotStepKeyTabs } from './KinebotStepKeyTabs';
import { DefaultStepKey } from '../../components';
import { Footer } from './Footer';
import { usePersonalisedReportContext } from '../../../context';
import { ToolsNames } from '@/types';

interface GenericStepProps {
	title: string;
	step_id: string;
	stepKeys: PersonalisedReportStepKeysResponse[];
}

const isStepKeyDisabled = (names: ToolsNames[], file_id: string | undefined) => {
	if(file_id || names.length === 0) {
		return false;
	}
	const require_video = [ToolsNames.reba, ToolsNames.angle_time, ToolsNames.rula];
	const tools_length = names.filter(name => !require_video.includes(name)).length;
	return !tools_length;
};

export function GenericStep({ step_id, title, stepKeys }: Readonly<GenericStepProps>) {
	const { file_id } = usePersonalisedReportContext();
	function renderStepKey(stepKey: PersonalisedReportStepKeysResponse, step_id: string): JSX.Element {
		let component = <DefaultStepKey />;
		let disabled = false;

		if (stepKey.sub_step_keys && stepKey.sub_step_keys.length > 0) {
			disabled = !file_id;
			component = (
				<KinebotStepKeyTabs
					key={stepKey.id}
					stepKey={stepKey}
					stepId={step_id}
					subStepKeys={stepKey.sub_step_keys}
				/>
			);
		}

		const isDisabled= isStepKeyDisabled(stepKey.ergonomic_tools.map(tool => tool.name), file_id);

		return (
			<PersonalisedReportStepKeyProvider
				key={stepKey.id}
				stepId={step_id}
				stepKey={stepKey}
				stepKeyFieldName={[`${step_id}`, `${stepKey.id}`]}
			>
				<CollapseCustom disabled={disabled || isDisabled} title={stepKey.description}>{component}</CollapseCustom>
			</PersonalisedReportStepKeyProvider>
		);
	}

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={23}>
				<Row align="middle">
					<Col xs={24}>
						<Title level={4}>{title}</Title>
					</Col>

					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Paragraph>Enter the data related to the selected video below</Paragraph>
					</Col>
				</Row>
				<Row align="middle" gutter={[0, 25]}>
					{stepKeys.map((stepKey) => {
						return renderStepKey(stepKey, step_id);
					})}
				</Row>
			</Col>
			<Footer step_id={step_id} />
		</Row>
	);
}
