import Api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

type PEAListDTO = {
	id: string;
	analyst_name: string;
	score: number;
	file_name: string;
	file_id: string;
	collection_date: Date;
	created_at: Date;
};

export function useGetPEAList(superPEAId: string, organizationId: string, companyId: string) {
	const list = useQuery<PEAListDTO[]>(['pea_by_super_pea_id'], () =>
		getPEAList(superPEAId, organizationId, companyId)
	);

	return {
		...list,
		data: list.data || []
	};
}

async function getPEAList(superPEAId: string, organizationId: string, companyId: string) {
	const url = `/ergonomic-tool/preliminary-analysis/list/super-pea/${superPEAId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<PEAListDTO[]>(url);
	return data;
}
