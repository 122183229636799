import React from 'react';
import { Row } from 'antd';
import { Background } from '@/components/ui/Background';

interface ContainerProps {
	children: string | JSX.Element | JSX.Element[];
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
	<>
		<Background />
		<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
			{children}
		</Row>
	</>
);
