import React from 'react';
import * as Icons from '@ant-design/icons';
import { Text } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Form, Input, Row } from 'antd';

export const Signatures: React.FC = () => {
	const onDelete = (key: any, remove: any, array: any) => {
		const [value] = array
			.filter(({ fieldKey }: any) => fieldKey === key)
			.map(({ name: index }: any) => ({ index }));
		remove(value.index);
	};
	const userInfo: any = localStorage.getItem('info_user');

	return (
		<Col xs={24} sm={18} lg={12} style={{ paddingTop: '20px' }}>
			<Row gutter={[0, 20]} style={{ marginBottom: '30px' }}>
				<Col xs={24} sm={18} lg={12}>
					<Text strong>{I18n.get('Add a new signature')}</Text>
				</Col>
			</Row>
			<Row gutter={[0, 20]}>
				<Col span={24}>
					<Form.List name={['signatures']}>
						{(fields, { add, remove }) => (
							<>
								<Row
									gutter={[10, 0]}
									align="middle"
									style={{
										alignItems: 'center'
									}}
									justify="center"
								>
									<Col xs={24} sm={18} lg={12}>
										<Form.Item
											name={[0, 'responsible']}
											label={I18n.get('Responsible name')}
											initialValue={JSON.parse(userInfo).user.name}
											rules={[
												() => ({
													validator(_, value) {
														return !value
															? Promise.reject(I18n.get('Enter a name of responsible'))
															: Promise.resolve();
													}
												})
											]}
										>
											<Input placeholder={I18n.get('Enter with a name of responsible')} />
										</Form.Item>
									</Col>
									<Col xs={23} sm={17} lg={11}>
										<Form.Item
											name={[0, 'role']}
											label={I18n.get('Role')}
											rules={[
												() => ({
													validator(_, value) {
														return !value
															? Promise.reject(I18n.get('Enter with a role'))
															: Promise.resolve();
													}
												})
											]}
										>
											<Input placeholder={I18n.get('Enter with a role')} />
										</Form.Item>
									</Col>
									<Col span={1}>
										<Button
											type="primary"
											disabled={fields.length > 5}
											icon={<Icons.PlusOutlined />}
											onClick={() => add()}
											style={{ marginTop: '7px' }}
											shape="circle"
										/>
									</Col>
								</Row>
								{fields.map(
									({ key, name, ...restField }, index, array) =>
										index > 0 && (
											<Row
												key={key}
												gutter={[10, 0]}
												align="middle"
												style={{
													alignItems: 'center'
												}}
												justify="center"
											>
												<Col span={12}>
													<Form.Item
														{...restField}
														name={[name, 'responsible']}
														rules={[
															() => ({
																validator(_, value) {
																	return !value
																		? Promise.reject(
																				I18n.get(
																					'Enter with a name of responsible'
																				)
																		  )
																		: Promise.resolve();
																}
															})
														]}
													>
														<Input
															placeholder={I18n.get('Enter with a name of responsible')}
														/>
													</Form.Item>
												</Col>
												<Col span={11}>
													<Form.Item
														{...restField}
														name={[name, 'role']}
														rules={[
															() => ({
																validator(_, value) {
																	return !value
																		? Promise.reject(I18n.get('Enter with a role'))
																		: Promise.resolve();
																}
															})
														]}
													>
														<Input placeholder={I18n.get('Enter with a role')} />
													</Form.Item>
												</Col>
												<Col
													span={1}
													style={{
														textAlign: 'center'
													}}
												>
													<Button
														type="link"
														danger
														onClick={() => onDelete(key, remove, array)}
														style={{ marginBottom: '22px', padding: '0' }}
													>
														<Icons.DeleteOutlined />
													</Button>
												</Col>
											</Row>
										)
								)}
							</>
						)}
					</Form.List>
				</Col>
			</Row>
		</Col>
	);
};
