import React from 'react';
import { Row } from 'antd';
import { Background } from '@/components/ui/Background';
import { Generate2FAContent } from './Generate2FAContent';

export const Generate2FA: React.FC = () => (
	<>
		<Background />
		<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
			<Generate2FAContent />
		</Row>
	</>
);
