import { Col, Form } from 'antd';
import styled from 'styled-components';

export const CustomItemSwitch = styled(Form.Item)`
	margin: 0;
	.ant-form-item-control {
		display: flex;
		flex-direction: row;
		justify-content: end;
	}
`;

export const CustomListDraggable = styled(Col)`
	padding: 0.75rem 1rem;
	margin-bottom: 0.5rem;
	border-radius: 0.5rem;
	border: 2px solid #e9e9e9;
	background-color: #f9f9f9;

	transition: all 0.2s;
	:hover {
		filter: brightness(0.95);
	}
`;

export const CustomCol = styled(Col)`
	max-height: 150px;
	overflow-y: auto;

	::-webkit-scrollbar-track {
		border: none;
		padding: 2px 0;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 0.5rem;
		background-color: transparent;
		box-shadow: inset 0 0 6px #9c9c9c;
	}
`;

export const CustomColSelectedStepsList = styled(Col)`
	/* height: 360px; */
	overflow-y: auto;

	::-webkit-scrollbar-track {
		border: none;
		padding: 2px 0;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 0.5rem;
		background-color: transparent;
		box-shadow: inset 0 0 6px #9c9c9c;
	}
`;

export const CustomButton = styled.button`
	border: none;
	cursor: pointer;
	width: max-content;
	height: max-content;
	background-color: transparent !important;
`;

export const StepDescriptionCol = styled(Col)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
