import styled from 'styled-components';
import { Col } from 'antd';

export const CustomFormContainer = styled(Col)`
	margin-left: 0;
	padding: 2rem 1rem;
	border-radius: 1rem;
	background-color: #f8f8f8;

	@media (min-width: 992px) {
		margin-left: 2rem;
	}
`;
