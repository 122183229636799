import styled from 'styled-components';

export const Topic = styled.span`
	font-size: 1rem;
	font-weight: 600;
	margin-right: 0.5rem;

	@media (min-width: 1600px) {
		font-size: 1.2rem;
	}
`;

export const Value = styled.span`
	font-size: 1rem;
	font-weight: 400;

	@media (min-width: 1600px) {
		font-size: 1.2rem;
	}
`;
