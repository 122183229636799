import { I18n } from '@aws-amplify/core';
import { DatePicker, Form } from 'antd';
import React from 'react';

type CreatedAtSelectProps = {
	organizationId: string;
	companyId: string;
};

export function CreatedAtSelect({ organizationId, companyId }: CreatedAtSelectProps) {
	return (
		<Form.Item label={I18n.get('Created At')} name="createdAt">
			<DatePicker.RangePicker
				disabled={!organizationId || !companyId}
				showTime={{ format: 'HH:mm:00' }}
				format="DD-MM-YYYY HH:mm:00"
				style={{ width: '100%' }}
			/>
		</Form.Item>
	);
}
