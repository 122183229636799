import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Card } from '../../Card';
import { SearchStepName } from './SearchStepName';
import { useGetCustomReportSteps } from '../hooks';
import { LoadingSkeleton } from './LoadingSkeleton';
import { StepButton, StepListCol, CustomList } from './styles';
import { useCustomReportManagerStepContext } from '../context';
import { CustomReportStepListResponse } from '../hooks/types/response';
import { useApplicationContext } from '@/context/Application';

export const SelectStep: React.FC = () => {
	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [searchingStep, setSearchingStep] = useState<boolean>(false);
	const [filteredName, setFilteredName] = useState<string | undefined>(undefined);

	const { company, organization } = useApplicationContext();
	const { addStepToStepList } = useCustomReportManagerStepContext();
	const {
		isSuccess,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
		data: stepsPages,
		isFetchingNextPage
	} = useGetCustomReportSteps({
		company_id: company?.id,
		description: filteredName,
		organization_id: organization?.id
	});

	function setReportName(name: string): void {
		clearTimeout(timer);

		const debounce = setTimeout((): void => {
			setFilteredName(name);
			setSearchingStep(false);
		}, 1000);

		setTimer(debounce);
	}

	function handleSearchStepNameChange(name: string): void {
		setSearchingStep(true);
		setReportName(name);
	}

	async function loadMoreData(): Promise<void> {
		if (hasNextPage && !isFetchingNextPage) {
			await fetchNextPage();
		}
	}

	function isLoadingStepsList(): boolean {
		return isLoading || isFetching || searchingStep || isFetchingNextPage;
	}

	function renderInfiniteScroll(steps: CustomReportStepListResponse) {
		return (
			<InfiniteScroll
				loader={<></>}
				next={loadMoreData}
				key={steps?.rows[0]?.id}
				dataLength={steps.count}
				scrollableTarget="stepsList"
				hasMore={!isFetchingNextPage && hasNextPage !== undefined ? hasNextPage : true}
			>
				{steps?.rows.map(({ id, description, steps_settings }) => (
					<StepButton
						key={id}
						onClick={() =>
							addStepToStepList({
								id,
								description,
								step_setting_id: steps_settings[0]?.id
							})
						}
					>
						<Row justify="center">
							<CustomList xs={24}>{I18n.get(description)}</CustomList>
						</Row>
					</StepButton>
				))}
			</InfiniteScroll>
		);
	}

	function hasNoSteps(): boolean {
		return stepsPages?.pages[0].count === 0 && isSuccess;
	}

	function renderSkeleton(): React.ReactNode {
		if (!stepsPages || !isSuccess || searchingStep) {
			return <LoadingSkeleton />;
		}
	}

	return (
		<Card title="Recommended sections">
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<SearchStepName onSearchStepNameChange={handleSearchStepNameChange} />
				</Col>
				<StepListCol span={24} id="stepsList">
					{renderSkeleton()}
					{stepsPages?.pages.map(renderInfiniteScroll)}
					{!hasNextPage && !hasNoSteps() && !isLoadingStepsList() && (
						<Divider plain>{I18n.get('No more sections available')}</Divider>
					)}
				</StepListCol>
				{hasNoSteps() && !isLoadingStepsList() && <Divider plain>{I18n.get('No sections available')}</Divider>}
			</Row>
		</Card>
	);
};
