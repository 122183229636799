export const ENUM_WORKPOPULATION_SCALE = {
	working_population_men: {
		caption: 'Men',
		fontColor: '#2F54EB',
		trailColor: '#2F54EB'
	},
	working_population_others: {
		caption: 'Others',
		fontColor: '#17A93B',
		trailColor: '#17A93B'
	},
	working_population_women: {
		caption: 'Women',
		fontColor: '#EFAC1E',
		trailColor: '#EFAC1E'
	}
};

export enum Gender {
	MEN = 'working_population_men',
	WOMEN = 'working_population_women',
	OTHERS = 'working_population_others'
}

export const WORKING_POPULATION = {
	MEN: Gender.MEN,
	WOMEN: Gender.WOMEN,
	OTHERS: Gender.OTHERS,
	TOTAL: 'total_working_population'
};
