import React from 'react';
import { CustomReportForm } from './CustomReportForm';
import { CustomReportManagerProvider } from './context';

export function PersonalisedReportsManager() {
	return (
		<CustomReportManagerProvider>
			<CustomReportForm />
		</CustomReportManagerProvider>
	);
}
