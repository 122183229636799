import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled(Col)`
	padding: 24px 20px;
	border-radius: 1rem;
	margin-bottom: 2rem;
	background-color: #f8f8f8;
`;

export const AddButton = styled.button`
	padding: 0;
	border: none;
	display: flex;
	color: #2f54eb;
	font-size: 40px;
	background: transparent;

	&:hover {
		cursor: pointer;
	}
`;

export const FlexCol = styled(Col)`
	display: flex;

	#company_max_minutes_check_help {
		display: none;
	}

	#company_max_uploads_check_help {
		display: none;
	}
`;
