import styled from 'styled-components';

export const DashboardContainer = styled.div`
	max-width: 1440px;
`;

export const Title = styled.h2`
	font-weight: 600;
`;

export const DashboardCard = styled.div`
	padding: 1.5rem 2rem;
	background-color: white;
	border-radius: 0.5rem;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
	height: 100%;
`;
