import React from 'react';
import { Col, Empty, Row } from 'antd';

import { Card } from './Card';
import { EmptyCol } from './styles';
import { LoadingSkeleton } from './LoadingSkeleton';
import { CustomReportResponse } from './hooks/types/response';

interface ListProps {
	isLoading: boolean;
	customReports?: CustomReportResponse[];
}

export const List: React.FC<ListProps> = ({ customReports, isLoading }) => {
	if (isLoading) {
		return <LoadingSkeleton />;
	}
	
	if (!customReports || customReports.length === 0) {
		return (
			<Row justify="center">
				<EmptyCol xs={24}>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</EmptyCol>
			</Row>
		);
	}
	return (
		<Row gutter={[32, 32]}>
			{customReports?.map(
				({ id, description, updated_at, created_by: { name }, settings: { extended_description } }) => (
					<Col key={id} xs={24} md={12} lg={10} xl={8} xxl={5}>
						<Card
							name={name}
							report_id={id}
							updated_at={updated_at}
							description={description}
							extended_description={extended_description}
						/>
					</Col>
				)
			)}
		</Row>
	);
};
