import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';

type UpdateReportCommentDTO = {
	organization_id: string;
	company_id: string;
	report_id: string;
	comment: string;
}

type UpdateReportCommentMutationDTO = {
	invalidateQueries?: (string | object | number)[];
	tool_route: string;
}

type UpdateReportCommentResponse = {
	id: string;
	comment: string;
};

type UpdateReportCommentError = AxiosError<any>;

async function updateReport(parameters: UpdateReportCommentDTO, tool: string) {
	const { data } = await Api.put(`/ergonomic-tool/${tool}/comment/${parameters.report_id}`, parameters);
	return data;
}

export const useUpdateReportComment = ({
	invalidateQueries,
	tool_route,
}: UpdateReportCommentMutationDTO) => {
	const queryClient = useQueryClient();
	return useMutation<UpdateReportCommentResponse, UpdateReportCommentError, UpdateReportCommentDTO>((payload) => updateReport(payload, tool_route), {
		onSuccess: () => {
			if (invalidateQueries) {
				queryClient.invalidateQueries(invalidateQueries);
			}
			message.success(I18n.get('Comment updated successfully'));
		},
		onError: (err) => {
			message.error(I18n.get(err?.response?.data?.message));
		},
	});
};
