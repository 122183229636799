import moment from 'moment';

export function formatFileSize(size: number) {
	if (size < 1024) return size + ' B';

	const sizePrefix = Math.floor(Math.log(size) / Math.log(1024));
	const number = size / Math.pow(1024, sizePrefix);
	const round = Math.round(number);

	const toFixedToOne = round < 100 ? number.toFixed(1) : round;
	const toFixedRoundedNumber = round < 10 ? number.toFixed(2) : toFixedToOne;

	const PrefixNumberSymbol = 'KMGTPEZY';
	const fileSizePrefix = PrefixNumberSymbol[sizePrefix - 1];

	return `${toFixedRoundedNumber} ${fileSizePrefix}B`;
}

export const convertSecondsToMinutes = (seconds: number) => {
	if (!seconds) return undefined;
	return `${moment.unix(seconds).utc().format('mm:ss')}`;
};

export function formatSecondsDuration(seconds: number) {
	const format = (val: number) => `0${Math.floor(val)}`.slice(-2);
	const hours = seconds / 3600;
	const minutes = (seconds % 3600) / 60;

	return [hours, minutes, seconds % 60].map(format).join(':');
}
