import Api from '@/services/api';
import { RefreshTokenBody, RefreshTokenResponse } from './types';

async function refreshToken(payload: RefreshTokenBody) {
	try {
		const { data } = await Api.post<RefreshTokenResponse>('auth/refresh-token', payload);
		return data;
	} catch (error) {
		throw error;
	}
}

export const Services = { refreshToken };
