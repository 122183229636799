import Api from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { BaseContext } from '@/components/ui/Inputs/types/request';

type ListCompaniesDTO = BaseContext & {
	searching_organization_id?: string;
};

async function getCompanies(params: ListCompaniesDTO) {
	const url = `/company/list`;
	const { data } = await Api.get(url, {
		params
	});
	return data;
}

export function useListCompanies({ organization_id, company_id, searching_organization_id }: ListCompaniesDTO) {
	const enabled = !!organization_id && !!company_id;

	const companies = useQuery(
		['companies_list', organization_id, company_id, searching_organization_id],
		() => getCompanies({ organization_id, company_id, searching_organization_id }),
		{
			enabled
		}
	);
	return companies;
}
