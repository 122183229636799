import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

type ReportInputBase = {
	report_id: string;
	file_id: string;
	current_step: number;
  };

  type ReportInputStep0 = ReportInputBase & {
	report_name: string;
	collection_date: Date;
	sector_id: string;
	cellule_id: string;
	workstation_id: string;
	evaluator_id: string;
	activity_id: string;
  };

  type ReportInputStep1 = ReportInputBase & {
	input_left_borg_scale: number;
	input_left_exertions: number;
	input_left_observation_time: number;
	input_left_exertion_duration: number;
	input_right_borg_scale: number;
	input_right_exertions: number;
	input_right_observation_time: number;
	input_right_exertion_duration: number;
  };

  type ReportInputStep2 = ReportInputBase & {
	input_left_wrist_posture: 'FLEXION' | 'EXTENSION';
	input_left_wrist_angle: number;
	input_left_daily_duration: number;
	input_right_wrist_posture: 'FLEXION' | 'EXTENSION';
	input_right_wrist_angle: number;
	input_right_daily_duration: number;
  };


type UpdateStrainIndexDTO = {
	organization_id: string;
	company_id: string;
	report_input: ReportInputStep0 | ReportInputStep1 | ReportInputStep2;
}

type UpdateStrainIndexResponse = {
	id: string;
	company_id: string;
	organization_id: string;
	file_id?: string;
};

type UpdateStrainIndexError = AxiosError<any>;

async function updateReport(parameters: UpdateStrainIndexDTO) {
	const { data } = await Api.put(`/ergonomic-tool/strain-index/${parameters.report_input.report_id}`, parameters);
	return data;
}

export const useUpdateStrainIndex = () => {
	const queryClient = useQueryClient();
	return useMutation<UpdateStrainIndexResponse, UpdateStrainIndexError, UpdateStrainIndexDTO>((payload) => updateReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_STRAIN_INDEX_REPORT]);
			queryClient.invalidateQueries(['file']);
		}
	});
};
