import React, { ReactElement, useState } from 'react';
import { Button, Divider, Form, Input, Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { GetCellulesResponse, useCreateCellule } from '@/hooks';
import { useApplicationContext } from '@/context/Application';
import { CreateCelluleRequest } from '../types/request/cellule';

const { useFormInstance } = Form;

type CelluleSelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	companyId?: string;
	organizationId?: string;
	celluleId?: string;
	fieldName: NamePath;
	sectorId: string;
	allowCreation?: boolean;
	handleClearCellule?: () => void;
	options: GetCellulesResponse | undefined;
	handleSelectCellule?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function CelluleSelector({
	loading,
	disabled,
	fieldName,
	companyId,
	organizationId,
	options = [],
	sectorId,
	celluleId,
	size = 'middle',
	allowCreation = true,
	handleSelectCellule,
	handleClearCellule
}: Readonly<CelluleSelectProps>) {
	const [celluleName, setCelluleName] = useState('');

	const { validateFields } = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { mutateAsync: createCellule, isLoading } = useCreateCellule();

	async function handleChange(id: string): Promise<void> {
		if (handleSelectCellule) {
			handleSelectCellule(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear(): void {
		if (handleClearCellule) {
			handleClearCellule();
		}
	}

	async function onCreateCellule(): Promise<void> {
		const body: CreateCelluleRequest = {
			company_id: companyId ?? company.id,
			organization_id: organizationId ?? organization.id,
			sector_id: sectorId as string,
			name: celluleName
		};

		const { data } = await createCellule(body);

		if (data?.id) {
			handleChange(data?.id);
		}

		setCelluleName('');
	}

	function handleCelluleNameChange(name: string): void {
		setCelluleName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}

		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						value={celluleName}
						style={{ flex: 'auto', borderRadius: '7px' }}
						onChange={(e) => handleCelluleNameChange(e.target.value)}
					/>
					<Button
						type="link"
						loading={isLoading}
						onClick={onCreateCellule}
						disabled={!celluleName || celluleName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			value={celluleId}
			onClear={handleClear}
			placeholder={I18n.get('Cellule')}
			onChange={(value: string) => handleChange(value)}
			dropdownRender={(menu) => showDropdownRender(menu)}
			filterOption={(input, option) => filterOption(option as OptionType, input)}
			options={options?.map((item) => ({ label: item.name, value: item.id }))}
		/>
	);
}
