import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';

import { PinCodeInput } from '@/components/ui/Inputs/PinCodeInput';
import { Text } from '@/components/Typography';
import { Spinner } from '@/components/Spinner';
import { I18n } from '@aws-amplify/core';

import { ValidateContainer, CodeContainer, ResendEmail, LinkButton } from './styles';
import { QRCode } from './Generate2FAModal/styles';
import { useTFAContext } from './context';

export const Validate = () => {
	const { generated2FA, fecthingUser2FA } = useTFAContext();
	const { handleResendToken } = useTFAContext();

	const [validationCode, setValidationCode] = useState('');

	function handleValidationCodeChange(code: string) {
		setValidationCode(code);
	}

	async function handleResendClick() {
		await handleResendToken();
	}

	if (fecthingUser2FA) {
		return <Spinner />;
	}

	if (generated2FA.message) {
		return (
			<ValidateContainer align="middle" justify="center">
				<Col span={24}>
					<Row justify="center" style={{ fontSize: '1.1rem', margin: '3rem 1rem 1rem' }}>
						<Text>Validation token</Text>
					</Row>
					<Row align="middle" justify="center" style={{ height: '3.5rem', marginBottom: '3rem' }}>
						<Form.Item
							name="validation_code"
							noStyle
							rules={[
								() => ({
									validator() {
										return validationCode.length !== 6
											? Promise.reject(I18n.get('Enter the 6 digit code.'))
											: Promise.resolve();
									}
								})
							]}
						>
							<PinCodeInput allowedCharacters="numeric" onChange={handleValidationCodeChange} />
						</Form.Item>
					</Row>
					<Row align="middle" justify="center">
						<ResendEmail>
							<span>
								If you have not received your code via email, you can try again by clicking{' '}
								<LinkButton onClick={handleResendClick}>{I18n.get('here')}.</LinkButton>
							</span>
						</ResendEmail>
					</Row>
				</Col>
			</ValidateContainer>
		);
	}

	return (
		<ValidateContainer align="middle" justify="center">
			<Col span={24}>
				<Row justify="center" style={{ width: '100%' }}>
					<Col span={24}>
						<CodeContainer justify="center" style={{ width: '100%' }}>
							<QRCode alt="QRCode" preview={false} src={generated2FA.qr_code_url} />
						</CodeContainer>
						<Row justify="center" style={{ fontSize: '1.1rem', margin: '1.1rem 1rem 1rem' }}>
							<Text>Validation token</Text>
						</Row>
						<Row align="middle" justify="center" style={{ height: '3.5rem', marginBottom: '3rem' }}>
							<Col span={10} style={{ height: '3.5rem' }}>
								<Form.Item
									name="validation_code"
									noStyle
									rules={[
										() => ({
											validator() {
												return validationCode.length !== 6
													? Promise.reject(I18n.get('Enter the 6 digit code.'))
													: Promise.resolve();
											}
										})
									]}
								>
									<PinCodeInput allowedCharacters="numeric" onChange={handleValidationCodeChange} />
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</ValidateContainer>
	);
};
