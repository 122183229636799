import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import type { BaseContext, Evaluator } from '@/types';

export type GetEvaluatorsDTO = BaseContext;

async function getEvaluators(params: BaseContext): Promise<Evaluator[]> {
	const url = '/evaluator/find-all';
	const { data } = await Api.get(url, { params });
	return data;
}

export const useEvaluators = (params: BaseContext) => {
	return useQuery(['evaluators', params], () => getEvaluators(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
