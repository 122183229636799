import styled from 'styled-components';

export const ButtonColor = styled.div`
	padding: 8px;
	cursor: pointer;
	background: #fff;
	border-radius: 1px;
	display: inline-block;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

export const ColorSelect = styled.div<{ color: string }>`
	width: 36px;
	height: 14px;
	border-radius: 2px;
	background: ${({ color }) => color};
`;

export const PopoverColor = styled.div`
	position: absolute;
	z-index: 2;
`;

export const CoverColor = styled.div`
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	position: fixed;
`;
