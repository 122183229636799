import React from 'react';
import { Button, MenuProps } from 'antd';
import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';

import Can from '@/components/Can';
import { OptionDropdown } from './OptionDropdown';
import { DownloadVideoIcon } from '../DownloadVideoIcon';
import { DeleteButtonContainer, Hamburger } from './styles';

interface HamburgerMenuProps {
	hasFile: boolean;
	disabled: boolean;
	onEditReportClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onDeleteReport: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
	onDownloadPDFClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
	onDownloadVideoClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
}

export function HamburgerMenu({
	hasFile,
	// disabled,
	onDeleteReport,
	onEditReportClick,
	onDownloadVideoClick
}: Readonly<HamburgerMenuProps>) {
	const permissionRole = Can('delete', 'report');

	const items: MenuProps['items'] = [
		{
			key: 1,
			icon: <EditOutlined />,
			label: <OptionDropdown onClick={onEditReportClick}>Edit</OptionDropdown>
		},
		{
			key: 2,
			disabled: !hasFile,
			icon: <DownloadVideoIcon height={15} width={15} />,
			label: (
				<OptionDropdown onClick={onDownloadVideoClick} style={{ marginLeft: '5px' }}>
					Download video
				</OptionDropdown>
			)
		},
		{
			key: 3,
			danger: true,
			disabled: !permissionRole,
			icon: <DeleteOutlined />,
			label: (
				<DeleteButtonContainer>
					<OptionDropdown onClick={onDeleteReport} disabled={!permissionRole}>
						Delete
					</OptionDropdown>
				</DeleteButtonContainer>
			)
		}
	];

	return (
		<Hamburger menu={{ items }} placement="bottom" trigger={['click']}>
			<Button type="link">
				<MenuOutlined />
			</Button>
		</Hamburger>
	);
}
