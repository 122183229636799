import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';

import * as S from './styles';
import { Separator } from './Separator';
import { Cell, Title, Value } from './Cell';

type Distance = string | null;
type Duration = string | null;

interface TableProps {
	data: {
		mass: number;
		note: number;
		vehicle: string;
		risk_range: string;
		distance: Distance;
		duration: Duration;
		measurements: string;
		possible_health_consequences: string;
		probability_of_physical_overload: string;
	};
}

export const Table: React.FC<TableProps> = ({
	data: {
		mass,
		note,
		vehicle,
		distance,
		duration,
		risk_range,
		measurements,
		probability_of_physical_overload,
		possible_health_consequences
	}
}) => {
	const handleDistanceOrDurationTitle = (distanceValue: Distance, durationValue: Duration) => {
		if (distanceValue) {
			return I18n.get('Distance');
		}

		if (durationValue) {
			return I18n.get('Duration');
		}

		return '-';
	};

	const handleDistanceOrDurationValue = (distanceValue: Distance, durationValue: Duration) => {
		if (distanceValue) {
			return `${distanceValue} ${I18n.get('meters')}`;
		}

		if (durationValue) {
			return `${durationValue} ${I18n.get('minutes')}`;
		}

		return '-';
	};

	return (
		<S.Container>
			<Col span={24}>
				<Row>
					<Cell span={6}>
						<S.Details>
							<Title>{handleDistanceOrDurationTitle(distance, duration)}:</Title>
							<Value>{handleDistanceOrDurationValue(distance, duration)}</Value>
						</S.Details>
					</Cell>
					<Separator />
					<Cell span={4}>
						<S.Details>
							<Title>{I18n.get('Mass')}:</Title>
							<Value>{mass} kg</Value>
						</S.Details>
					</Cell>
					<Separator />
					<Cell span={4}>
						<S.Details>
							<Title>{I18n.get('Vehicle')}:</Title>
							<Value>{I18n.get(vehicle)}</Value>
						</S.Details>
					</Cell>
					<Separator />
					<Cell span={6}>
						<S.Details>
							<Title>{I18n.get('Probability of physical overload')}:</Title>
							<Value>{I18n.get(probability_of_physical_overload)}</Value>
						</S.Details>
					</Cell>
				</Row>
			</Col>
			<Divider style={{ margin: 0 }} />
			<Col span={24}>
				<Row>
					<Cell span={6}>
						<S.Details>
							<Title>{I18n.get('Possible health consequences')}:</Title>
							<Value>{I18n.get(possible_health_consequences)}</Value>
						</S.Details>
					</Cell>
					<Separator />
					<Cell span={4}>
						<S.Details>
							<Title>{I18n.get('Risk Range')}:</Title>
							<Value isBold={true}>{I18n.get(risk_range)}</Value>
						</S.Details>
					</Cell>
					<Separator />
					<Cell span={4}>
						<S.Details>
							<Title>{I18n.get('Score')}:</Title>
							<Value isBold={true}>{note}</Value>
						</S.Details>
					</Cell>
					<Separator />
					<Cell span={6}>
						<S.Details>
							<Title>{I18n.get('Measurements')}:</Title>
							<Value>{I18n.get(measurements)}</Value>
						</S.Details>
					</Cell>
				</Row>
			</Col>
		</S.Container>
	);
};
