import React from 'react';
import { Col, Form, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomItemSwitch } from './styles';

const { useFormInstance, useWatch } = Form;

export const RequireVideo: React.FC = () => {
	const form = useFormInstance();
	const file = useWatch('has_file', form);

	return (
		<Col xs={24}>
			<CustomItemSwitch name={['has_file']} label={I18n.get('Require video')}>
				<Switch checked={file} />
			</CustomItemSwitch>
		</Col>
	);
};
