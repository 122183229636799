import React from 'react';
import { Collapse } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useForm } from 'antd/lib/form/Form';

import { FormFilter } from './Form';
import { useContractPlansContext } from '../context';
import type { FormData, InitialValuesForm } from './types';
import * as S from './styles';

export function Filter() {
	const [form] = useForm<FormData>();
	const { onFilter, handleOnResetFilter } = useContractPlansContext();

	async function handleOnSubmitForm(): Promise<void> {
		const values = await form.validateFields();
		onFilter(values);
	}

	function handleOnClearForm(): void {
		form.resetFields();
		handleOnResetFilter();
	}

	const initialValues: InitialValuesForm = { has_limit_exceeded: false };

	return (
		<S.Container>
			<Collapse>
				<Collapse.Panel key="1" header={I18n.get('Filter')}>
					<FormFilter
						form={form}
						onClear={handleOnClearForm}
						onSubmit={handleOnSubmitForm}
						initialValues={initialValues}
					/>
				</Collapse.Panel>
			</Collapse>
		</S.Container>
	);
}
