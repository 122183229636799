import { Col } from 'antd';
import styled, { css } from 'styled-components';

type CustomProps = {
	selected?: boolean;
	hasBody?: boolean;
	open?: boolean;
	disabled?: boolean;
};

const colorSelected = '#2F54EB';

const borderSelected = css`
	box-shadow: lightgrey 2px 2px 10px;
	border: 1px solid ${colorSelected};
`;

export const Header = styled(Col) <CustomProps>`
	cursor: ${(props) => {
		if (props.disabled) {
			return 'not-allowed'
		}

		return 'pointer'
	}};
	margin: 0px;
	font-size: 15px;
`;

export const Container = styled.div<CustomProps>`
	border-radius: 8px;
	user-select: none;
	border: 1px solid #ddd;
	background: ${(props) => (props.disabled ? '#efefef' : '#ffffff')};
	cursor: ${(props) => {
		if (props.disabled) {
			return 'not-allowed'
		}

		if (props.selected && props.hasBody) {
			return 'pointer'
		}

		return 'auto'
	}};
	padding: 1.5rem 1.2rem;
	margin-bottom: 0;
	transition: all 0.2s;

	:hover {
		filter: ${(props) => {
		if (props.disabled) {
			return 'none'
		}
		if (!props.selected || !props.hasBody) {
			return 'brightness(0.9)'
		}
		return 'brightness(1)'
	}};
	}

	${(props) => props.selected && borderSelected}
`;

export const Body = styled.div<CustomProps>`
	border-top: none;
	opacity: ${(props) => (props.open ? '1' : '0')};
	max-height: ${(props) => (props.open ? '100%' : '0')};
	overflow: hidden;
	padding: ${(props) => (props.open ? '1rem' : '0 1rem')};
	transition: all 0.3s;
	cursor: auto;
`;
