import React from 'react';
import { Col, Form } from 'antd';

import { CelluleSelector } from '@/components/ui/Inputs/CelulleSelect';
import { useGetCellules } from '@/hooks';
import { FormItem } from '../../../components/FormItem';
import { usePersonalisedReportContext } from '../../../../context';

const { useFormInstance, useWatch } = Form;

export const Cellules: React.FC = () => {
	const form = useFormInstance();
	const { organization_id, company_id } = usePersonalisedReportContext();

	const sectorId = useWatch(['basic_information', 'sector_id'], form);
	const celluleId = useWatch(['basic_information', 'cellule_id'], form);
	const fieldName = ['basic_information', 'cellule_id'];

	const {
		data: cellules,
		isLoading,
		isFetching,
		isError
	} = useGetCellules({
		organization_id,
		company_id,
		sector_id: sectorId
	});

	function handleSelectCellule(id: string): void {
		form.setFieldValue(fieldName, id);
	}

	function handleClearCellule(): void {
		form.setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				fontSize="1rem"
				isRequired={false}
				title="Cellule"
				namePath={fieldName}
			>
				<CelluleSelector
					size="large"
					fieldName={fieldName}
					allowCreation
					companyId={company_id}
					organizationId={organization_id}
					sectorId={sectorId}
					loading={isFetching}
					options={cellules}
					celluleId={celluleId}
					handleSelectCellule={handleSelectCellule}
					handleClearCellule={handleClearCellule}
					disabled={isLoading || isFetching || isError || !sectorId}
				/>
			</FormItem>
		</Col>
	);
};
