import { Button, Col } from 'antd';
import styled from 'styled-components';
import Icon from '@ant-design/icons';

export const Container = styled(Col)`
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 20rem;

	::-webkit-scrollbar {
		width: 4px;
		background: transparent;
	}

	::-webkit-scrollbar-track {
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		background: #e6e6e6;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const UserContainer = styled(Col)`
	padding: 2px 0 2px 2px;
	border-radius: 7px;
	background-color: #f8f8f8;
	border: 1px solid #00000033;
`;

export const EditButton = styled(Button)`
	border: none !important;
	box-shadow: none !important;
`;

export const Column = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ColNotFound = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const IconNotFound = styled(Icon)`
	font-size: 100px;
	width: 100px;
	height: 100px;
`;
