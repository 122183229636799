import styled from 'styled-components';

export const Status = styled.span`
	font-size: 0.8rem;
`;

export const StatusAmount = styled.span`
	font-weight: 600;
	font-size: 1rem;
`;
