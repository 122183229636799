import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Select } from 'antd';
import { WorkstationDTO } from '@/hooks/useWorkstation/types';

interface FilterByWorkstationProps {
	onWorkstationChange: (workstation: string) => void;
	workstations: { data: WorkstationDTO[]; error: unknown; isError: boolean; isLoading: boolean };
}

export const FilterByWorkstation: React.FC<FilterByWorkstationProps> = ({ onWorkstationChange, workstations }) => {
	return (
		<Row gutter={[16, 8]}>
			<Col span={24}>
				<span>{I18n.get('Workstation')}</span>
			</Col>
			<Col span={24}>
				<Form.Item name="workstation">
					<Select
						showSearch
						filterOption={(input, option) => (option?.label ?? '').includes(input)}
						disabled={workstations.isLoading}
						loading={workstations?.data?.length > 0 && workstations.isLoading}
						allowClear
						placeholder={I18n.get('Workstation')}
						options={workstations?.data.map(({ id, name }) => ({ label: name, value: id }))}
						onChange={onWorkstationChange}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
};
