import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled(Col)`
	padding: 24px 30px;
	background-color: #f8f8f8;
	border-radius: 1rem;
`;

export const AddButton = styled.button`
	padding: 0;
	border: none;
	display: flex;
	color: #2f54eb;
	font-size: 40px;
	background: transparent;

	&:hover {
		cursor: pointer;
	}
`;

export const Title = styled.span`
	font-size: 1.6rem;
	font-weight: 600;
	color: #262626;
`;

export const FlexCol = styled(Col)`
	font-size: 1.6rem;
	font-weight: 600;
	color: #262626;
`;
