import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetKimPpDTO {
	organization_id: string;
	company_id: string;
	id: string;
}

type GetKimPpResponse = {
	id: string
	report_name: string
	workstation?: string
	gender?: number
	pair?: number
	distance_or_duration?: number
	vehicle?: number
	mass?: number
	driveway_conditions?: number
	inclination_or_stairs?: number
	working_conditions?: number[]
	properties?: number[]
	posture?: number
	temporal_distribution?: number
	collection_date: string
	score?: number
	comment?: string
	isActive: boolean
	createdAt: string
	updatedAt: string
	file_id?: string
	cellule_id?: string
	workstation_id: string
	activity_id?: string
	evaluator_id?: string
	report_user_id: string
	sector_id: string
	workstations?: Workstations
	cellule?: Cellule
	activity?: Activity
	evaluator?: Evaluator
	option_direction?: string
	gender_input?: number
	pair_input?: number
	distance_or_duration_input?: number
	vehicle_input?: number
	mass_input?: number
	driveway_conditions_input?: number
	inclination_or_stairs_input?: number
	posture_input?: number
	temporal_distribution_input?: number
	labels?: Labels
	current_step: number
}

type Workstations = {
	name: string
	id: string
	sectors: Sectors
}

type Sectors = {
	name: string
	id: string
}

type Cellule = {
	name: string
	id: string
}

type Activity = {
	name: string
	id: string
}

type Evaluator = {
	name: string
	id: string
}

type Labels = {
	risk: Risk
	diagnostics: Diagnostics
	pair: string
	gender: string
	distance: string
	duration?: string
	vehicle: string
	mass: string
	driveway_conditions: DrivewayConditions
	inclination_or_stairs: InclinationOrStairs
	working_conditions: WorkingCondition[]
	properties: Property[]
	temporal_distribution: string
	posture: Posture
}

type Risk = {
	value: string
	title: string
	type: string
}

type Diagnostics = {
	PHYSICAL_OVERLOAD: string
	HEALTH_CONSEQUENCES: string
	MEASURES: string
}

type DrivewayConditions = {
	description: string
	indicator: string
}

type InclinationOrStairs = {
	description: string
	indicator: string
}

type WorkingCondition = {
	description: string
	indicator: Indicator
}

type Indicator = {
	success: boolean
	warning: boolean
	danger: boolean
}

type Property = {
	description: string
	indicator: Indicator
}

type Posture = {
	value: string
	description: string[]
	indicator: Indicator
}

type GetKimPpError = AxiosError<any>;

export async function getKimPp(payload: GetKimPpDTO) {
	const { organization_id, company_id, id } = payload;
	const url = `/ergonomic-tool/kim-pp/${id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetKimPp = ({ organization_id, company_id, id }: GetKimPpDTO) => {
	const payload = { organization_id, company_id, id };
	return useQuery<GetKimPpResponse, GetKimPpError>(
		[QUERY_KEYS.GET_KIM_PP_REPORT, payload],
		() => getKimPp(payload),
		{
			enabled: !!organization_id && !!company_id && !!id
		}
	);
};
