import React, { useContext } from 'react';

import { I18n } from '@aws-amplify/core';
import { DatePicker, Form } from 'antd';
import { MergeAEPContext } from '../../context';

export const CreatedAt: React.FC = () => {
	const { organizationId, companyId, setCreatedAt, queryParams, setQueryParams } = useContext(MergeAEPContext);

	function handleCreatedAtChange(createdAt: string[]) {
		setCreatedAt(createdAt);
		setQueryParams({
			...queryParams,
			createdAt
		});
	}

	return (
		<Form.Item label={I18n.get('Created At')} name="createdAt">
			<DatePicker.RangePicker
				disabled={!organizationId || !companyId}
				showTime={{ format: 'HH:mm:00' }}
				format="DD-MM-YYYY HH:mm:00"
				style={{ width: '100%' }}
				onChange={(_date, dateString) => handleCreatedAtChange(dateString)}
			/>
		</Form.Item>
	);
};
