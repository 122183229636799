import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface UpdateRebaDTO {
	force: number;
	comment: string;
	file_id: string;
	coupling: number;
	sector_id: string;
	company_id: string;
	repetition: number;
	collection_date: Date;
	workstation_id: string;
	organization_id: string;
}

type UpdateRebaResponse = {
	status: string;
	message: string;
	data: any;
};

type UpdateRebaError = AxiosError<any>;

async function updateReport(parameters: UpdateRebaDTO) {
	const { data } = await Api.put('/ergonomic-tool/reba/basic-info', parameters);
	return data;
}

export const useUpdateBasicInfoReba = () => {
	const queryClient = useQueryClient();
	return useMutation<UpdateRebaResponse, UpdateRebaError, UpdateRebaDTO>((payload) => updateReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_REBA_REPORT]);
			queryClient.invalidateQueries(['file']);
		}
	});
};
