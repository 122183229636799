import React from 'react';
import { I18n } from '@aws-amplify/core';
import { ColorPicker, normalizeRGBOpacity } from '@/components/ui';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, InputNumber, Form, Button, Tooltip } from 'antd';

export function Knee({ form }) {

	const styles = {
		inputNumber: {
			width: 150
		}
	};

	const initBand = { min: 0, max: 0, color: '#C1C1C1' };

	return (
		<Form form={form} layout="vertical">
			<Row gutter={[10, 10]}>
				<Col sm={24}>
					<h2 style={{ fontSize: '20px' }}>
						{I18n.get('Knee')}
					</h2>
				</Col>
				<Col sm={24}>
					<Form.List name="knee">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, fieldKey, ...restField }) => (
									<Row key={key} gutter={[20, 0]} type="flex" style={{ alignItems: "center" }}>
										<Col sm={8}>
											<Form.Item
												{...restField}
												name={[name, 'min']}
												fieldKey={[fieldKey, 'max']}
												label={I18n.get('Minimum angle')}
											>
												<InputNumber max="360" stringMode style={styles.inputNumber} />
											</Form.Item>
										</Col>
										<Col sm={8}>
											<Form.Item
												{...restField}
												name={[name, 'max']}
												fieldKey={[fieldKey, 'max']}
												label={I18n.get('Maximum angle')}
											>
												<InputNumber max="360" stringMode style={styles.inputNumber} />
											</Form.Item>
										</Col>
										<Col sm={4}>
											<Form.Item
												{...restField}
												name={[name, 'color']}
												label={I18n.get('Coloring')}
												fieldKey={[fieldKey, 'color']}
												normalize={normalizeRGBOpacity}
											>
												<ColorPicker />
											</Form.Item>
										</Col>
										<Col sm={4}>
											<Button type="link" danger onClick={() => remove(name)}>
												{I18n.get('Remove')}
											</Button>
										</Col>
									</Row>
								))}
								<Row type="flex" justify="center" align="middle">
									<Col sm={24} style={{ textAlign: 'center', paddingTop: '20px' }}>
										{!!(fields.length === 0) && <p>{I18n.get('Create new band')}</p>}
										<Tooltip title={I18n.get('Add new band')}>
											<Button
												size="large"
												type="primary"
												shape="circle"
												disabled={!!(fields.length > 5)}
												onClick={() => add(initBand)}
												icon={<PlusOutlined />}
											/>
										</Tooltip>
									</Col>
								</Row>
							</>
						)}
					</Form.List>
				</Col>
			</Row>
		</Form>
	);
}
