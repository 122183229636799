import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Label } from './styles';
import { useGetSectors } from '@/hooks/useGetSectors';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Sectors() {
	const form = useFormInstance();

	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);
	const companyId = useWatch(HierarchiesField.COMPANY, form);

	const title = I18n.get('Sector');
	const fieldName = HierarchiesField.SECTOR;

	const {
		data: sectors,
		isFetching: isGettingSectors,
		isError: errorGettingSectors
	} = useGetSectors(organizationId, companyId);

	function handleSelectSector(id: string): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);

		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearSector(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);

		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={fieldName} label={<Label>{title}</Label>}>
			<SelectWithCreation
				showSearch
				allowClear
				placeholder={title}
				selectOptions={sectors}
				loading={isGettingSectors}
				onClear={handleClearSector}
				onChange={handleSelectSector}
				disabled={!companyId || !sectors || errorGettingSectors}
			/>
		</Form.Item>
	);
}
