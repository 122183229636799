import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalContainer = styled(Modal)`
	.ant-modal-close-x {
		width: 0;
		height: 0;
		line-height: 4.3rem;
		margin-right: 3.1rem;
		font-size: 0.8rem;
	}

	.ant-modal-content {
		align-items: center;
		border-radius: 1.5rem;
	}

	.ant-modal-body {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 0;
	}
`;
