import React from 'react';
import { Row } from 'antd';
import { Content } from './Content';
import { Background } from '@/components/ui/Background';

export function Login() {
	return (
		<>
			<Background />
			<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
				<Content />
			</Row>
		</>
	);
}
