import React, { useState } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Divider, Button } from 'antd';

import Can from '@/components/Can';
import { DetailsModal } from './DetailsModal';
import { ENUM_TYPE_PLAN } from '@/utils/enum';
import { useGetPlan } from '@/hooks/useGetPlan';
import { useApplicationContext } from '@/context/Application';
import * as S from '../styles';

const PLAN_TYPES: Record<string, string> = ENUM_TYPE_PLAN;

export function Plan() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { organization, company } = useApplicationContext();
	const { data } = useGetPlan({ organization_id: organization?.id, company_id: company?.id });

	function formatTimeText(minutesProcessed: number): string {
		if (!minutesProcessed) {
			return '0 m';
		}

		const duration = moment.duration(minutesProcessed, 'minutes');
		const seconds = duration.seconds();
		const minutes = duration.minutes();
		const hours = duration.hours();

		let message = '';

		if (hours) {
			message = `${hours}h `;
		}

		if (minutes || (hours && seconds)) {
			message += `${minutes}m `;
		}

		if (seconds) {
			message += `${seconds}s`;
		}

		return message;
	}

	function handleOpenModalDetails(): void {
		setIsOpen(true);
	}

	function handleCloseModalDetails(): void {
		setIsOpen(false);
	}

	return (
		<S.Section>
			<S.TitleSection>{I18n.get('Plan')}</S.TitleSection>
			<Row>
				<Row style={{ width: '100%' }}>
					<S.Subsection span={14}>
						<S.Field>{I18n.get('Subscription type')}</S.Field>
						<S.Information>{data?.plan ? PLAN_TYPES[data.plan.name] : ''}</S.Information>
					</S.Subsection>
					<S.Subsection span={10}>
						<S.Field>{I18n.get('Expiration date')}</S.Field>
						<S.Information>{moment(data?.expiration_plan).format('L')}</S.Information>
					</S.Subsection>
				</Row>
				<Divider style={{ margin: '25px 0' }} />
				<S.Subsection>
					<S.Field>{I18n.get('Available time')}</S.Field>
					<S.Information>{data && formatTimeText(data.minutes_available)}</S.Information>
				</S.Subsection>
				<Divider style={{ margin: '25px 0' }} />
				<S.Subsection>
					<S.Field>{I18n.get('Time used')}</S.Field>
					<Row justify="space-between" align="middle">
						<Col span={23}>
							<S.Information>{data && formatTimeText(data.minutes_processed)}</S.Information>
						</Col>
						<Col span={1}>
							<Button
								type="link"
								size="large"
								onClick={handleOpenModalDetails}
								disabled={!Can('settings', 'billing')}
							>
								{I18n.get('See more details')}
							</Button>
							<DetailsModal isOpen={isOpen} onCancel={handleCloseModalDetails} />
						</Col>
					</Row>
				</S.Subsection>
			</Row>
		</S.Section>
	);
}
