import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Injury } from './Injury';
import { Description, Score } from './styles';
import { Title } from '@/components/Typography';
import { NotAnalyzedDescription } from '../NotAnalyzedDescription';
import { usePersonalisedReportStepKeyResultContext } from '../context';

export const RiskDescription: React.FC = () => {
	const { stepKey } = usePersonalisedReportStepKeyResultContext();
	const { result, exposure, consequence, probability, score } = stepKey;

	const stressLevelColorMapper = ['#b3b3b3', '#2cc852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];
	const stressDescriptionMapper = [
		'Not analysed',
		'Acceptable Risk',
		'Moderate Risk',
		'High Risk',
		'Very High Risk',
		'Extreme and Imminent Risk'
	];
	const color = stressLevelColorMapper[result];
	const riskDescription = stressDescriptionMapper[result];

	if (!result || result === 0) {
		return <NotAnalyzedDescription />;
	}

	return (
		<Row justify="space-between" style={{ marginBottom: '2rem', height: '75px' }}>
			<Col xs={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Consequence')}
					</Title>
				</Row>
				<Row>
					<Description>{I18n.get(consequence.description)}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: '100%' }} />
			<Col xs={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Probability')}
					</Title>
				</Row>
				<Row>
					<Description>{I18n.get(probability.description)}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: '100%' }} />
			<Col xs={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Exposure')}
					</Title>
				</Row>
				<Row>
					<Description>
						<Description>{I18n.get(exposure.description)}</Description>
					</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: '100%' }} />
			<Injury />
			<Col xs={4}>
				<Row>
					<Title style={{ margin: 0, color }} level={5}>
						{I18n.get(riskDescription)}
					</Title>
				</Row>
				<Row>
					<Score>
						{I18n.get('Score')}: {score}
					</Score>
				</Row>
			</Col>
		</Row>
	);
};
