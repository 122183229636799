import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { physicalOverloadMap, forceHandsMap, forceTransferMap } from '../contants';
import { DetailDescription, Details, DetailTitle } from './styles';
import { IChildrenProps } from '../types';

export const FirstRow: React.FC<IChildrenProps> = ({
	parameters: { risk_load, duration, left_force_intensity, right_force_intensity, force_transfer }
}) => (
	<Row justify={'space-between'}>
		<Col span={6} style={{ paddingLeft: '1rem' }}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Total duration per day worked')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>
						{duration} {I18n.get('hours')}.
					</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={4}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Force exerted on hand/fingers (L|R)')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>
						{I18n.get(forceHandsMap[left_force_intensity])} |{' '}
						{I18n.get(forceHandsMap[right_force_intensity])}
					</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={4}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Power transfer/gripping conditions')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>{I18n.get(forceTransferMap[force_transfer])}.</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={6}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Probability of physical overload')}</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>{I18n.get(physicalOverloadMap[risk_load].PHYSICAL_OVERLOAD)}</DetailDescription>
				</Row>
			</Details>
		</Col>
	</Row>
);
