import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Api from '@/services/api';

import type { CompanyDTO, Response } from '@/types';

async function getCompanies(organizationId: string): Promise<CompanyDTO[]> {
	const url = `/company/user_list/${organizationId}`;
	const { data } = await Api.get<CompanyDTO[]>(url);
	return data;
}

export function useCompanies(organizationId: string) {
	const enabled = !!organizationId;

	const companies = useQuery<CompanyDTO[], AxiosError<Response>>(
		['companies', organizationId],
		() => getCompanies(organizationId),
		{
			enabled
		}
	);
	return companies;
}
