import {
	ForceIntensity,
	ForceTranser,
	RiskLoad,
	RiskLoadItems,
	PhysicalOverLoad,
	HealthConsequences,
	Measures,
	ForceIntensityTitle,
	ForceTransferTitle,
	RiskLoadDescription
} from './types';

export const physicalOverloadMap = {
	[RiskLoad.LOW]: {
		[RiskLoadItems.PHYSICAL_OVERLOAD]: PhysicalOverLoad.LOW,
		[RiskLoadItems.HEALTH_CONSEQUENCES]: HealthConsequences.LOW,
		[RiskLoadItems.MEASURES]: Measures.LOW
	},
	[RiskLoad.SLIGHTLY_INCREASED]: {
		[RiskLoadItems.PHYSICAL_OVERLOAD]: PhysicalOverLoad.SLIGHTLY_INCREASED,
		[RiskLoadItems.HEALTH_CONSEQUENCES]: HealthConsequences.SLIGHTLY_INCREASED,
		[RiskLoadItems.MEASURES]: Measures.SLIGHTLY_INCREASED
	},
	[RiskLoad.SUBSTANTIALLY_INCREASED]: {
		[RiskLoadItems.PHYSICAL_OVERLOAD]: PhysicalOverLoad.SUBSTANTIALLY_INCREASED,
		[RiskLoadItems.HEALTH_CONSEQUENCES]: HealthConsequences.SUBSTANTIALLY_INCREASED,
		[RiskLoadItems.MEASURES]: Measures.SUBSTANTIALLY_INCREASED
	},
	[RiskLoad.HIGH]: {
		[RiskLoadItems.PHYSICAL_OVERLOAD]: PhysicalOverLoad.HIGH,
		[RiskLoadItems.HEALTH_CONSEQUENCES]: HealthConsequences.HIGH,
		[RiskLoadItems.MEASURES]: Measures.HIGH
	}
};

export const forceHandsMap: { [key in ForceIntensity]: ForceIntensityTitle } = {
	[ForceIntensity.VERY_LOW]: ForceIntensityTitle.VERY_LOW,
	[ForceIntensity.MODERATE]: ForceIntensityTitle.MODERATE,
	[ForceIntensity.HIGH]: ForceIntensityTitle.HIGH,
	[ForceIntensity.VERY_HIGH]: ForceIntensityTitle.VERY_HIGH,
	[ForceIntensity.PEAK]: ForceIntensityTitle.PEAK,
	[ForceIntensity.POWERFUL_HITTING]: ForceIntensityTitle.POWERFUL_HITTING
};

export const forceTransferMap: { [key in ForceTranser]: ForceTransferTitle } = {
	[ForceTranser.OPTIMUM]: ForceTransferTitle.OPTIMUM,
	[ForceTranser.RESTRICTED]: ForceTransferTitle.RESTRICTED,
	[ForceTranser.HINDERED]: ForceTransferTitle.HINDERED
};

export const riskLoadMap: { [key in RiskLoad]: RiskLoadDescription } = {
	[RiskLoad.LOW]: RiskLoadDescription.LOW,
	[RiskLoad.SLIGHTLY_INCREASED]: RiskLoadDescription.SLIGHTLY_INCREASED,
	[RiskLoad.SUBSTANTIALLY_INCREASED]: RiskLoadDescription.SUBSTANTIALLY_INCREASED,
	[RiskLoad.HIGH]: RiskLoadDescription.HIGH
};
