import { createContext } from 'react';

export const defaultState = {
	hasTransaction: false
};

const defaultContext = {
	...defaultState,
	changeTransaction: () => {}
};

export const ResultsContext = createContext<any>(defaultContext);
