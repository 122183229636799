import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const AntIcon = styled(LoadingOutlined)`
  font-size: ${props => props.size}px
`;

export const SpinnerLoading = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
