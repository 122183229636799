import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useApplicationContext } from '@/context/Application';
import { AuthUserDTO } from '@/types/AuthUser';

import {
	useFind2FA,
	useReset2FA,
	useDelete2FA,
	useSendToken,
	useGenerate2FA,
	useResendToken,
	useValidate2FA,
	useEnableAuthApp,
	useChangeDefault2FA,
	useConfirmPassword,
	useGetForced2FA
} from './hooks';

import { Find2FADTO, Generate2FADTO } from './types/response';
import { TFATypes } from './types/request';
import { Context } from './index.types';

interface TFAProviderProps {
	children: ReactNode;
}

export const TFAContext = createContext<Context>({} as Context);

export const TFAProvider = ({ children }: TFAProviderProps) => {
	const storageUser = localStorage.getItem('info_user') || '';
	const parsedUser: AuthUserDTO = JSON.parse(storageUser);
	const { company, organization } = useApplicationContext();

	const [currentStep, setCurrentStep] = useState(0);
	const [checked2FA, setChecked2FA] = useState(false);
	const [openActionModal, setOpenActionModal] = useState(false);
	const [correctPassword, setCorrectPassword] = useState(false);
	const [currentActionStep, setCurrentActionStep] = useState(0);
	const [recoveryTokens, setRecoveryTokens] = useState<string[]>([]);
	const [user2FA, setUser2FA] = useState<Find2FADTO>({} as Find2FADTO);
	const [openGenerateTokenModal, setOpenGenerateTokenModal] = useState(false);
	const [openDeactivateTokenModal, setOpenDeactivateTokenModal] = useState(false);
	const [selected2FAType, setSelected2FAType] = useState<TFATypes>('AUTHENTICATOR');
	const [generated2FA, setGenerated2FA] = useState<Generate2FADTO>({} as Generate2FADTO);

	const { mutateAsync: reset2FA } = useReset2FA();
	const { mutateAsync: delete2FA } = useDelete2FA();
	const { mutateAsync: sendToken } = useSendToken();
	const { mutateAsync: generate2FA } = useGenerate2FA();
	const { mutateAsync: validate2FA } = useValidate2FA();
	const { mutateAsync: resendToken } = useResendToken();
	const { mutateAsync: enableAuthApp } = useEnableAuthApp();
	const { mutateAsync: confirmPassword } = useConfirmPassword();
	const { mutateAsync: changeDefault2FA } = useChangeDefault2FA();
	const {
		data: user2FADTO,
		isFetching: fecthingUser2FA,
		isError: errorGettingUser2FA
	} = useFind2FA(company?.id, organization?.id);
	const { data: forced2FA } = useGetForced2FA();

	useEffect(() => {
		function createdButNotValidated() {
			return user2FADTO?.id?.length > 0 && !user2FADTO.validated;
		}

		if (errorGettingUser2FA) {
			setGenerated2FA({} as Generate2FADTO);
			setUser2FA({} as Find2FADTO);
			setChecked2FA(false);
		}

		if (!fecthingUser2FA && !errorGettingUser2FA) {
			if (createdButNotValidated()) {
				setGenerated2FA(user2FADTO);
				setCurrentStep(2);
			}

			setUser2FA(user2FADTO);
		}
	}, [fecthingUser2FA, openActionModal, openGenerateTokenModal, errorGettingUser2FA]);

	function handle2FAChecked() {
		setChecked2FA(!checked2FA);
	}

	function handleSelectedType(type: TFATypes) {
		setSelected2FAType(type);
	}

	function handleResetStep() {
		setCurrentStep(0);
		setCurrentActionStep(0);
	}

	function handlePreviousStep() {
		setCurrentStep(currentStep - 1);
	}

	function handleNextStep() {
		setCurrentStep(currentStep + 1);
	}

	function handleOpenGenerateTokenModal(isOpen: boolean) {
		setOpenGenerateTokenModal(isOpen);
	}

	function handleOpenDeactivateTokenModal(isOpen: boolean) {
		setOpenDeactivateTokenModal(isOpen);
	}

	function handleOpenActionModal(isOpen: boolean) {
		setOpenActionModal(isOpen);
	}

	function handleCorrectPassword(isPasswordCorrect: boolean) {
		setCorrectPassword(isPasswordCorrect);
	}

	async function handleGenerate2FA() {
		const generated = await generate2FA({
			company_id: company.id,
			organization_id: organization.id,
			data: { type: selected2FAType }
		});

		setGenerated2FA(generated);

		return generated;
	}

	async function handleValidate2FA(type: TFATypes, token: string) {
		const validatedToken = await validate2FA({
			company_id: company.id,
			organization_id: organization.id,
			data: { type, token }
		});

		setCurrentStep(currentStep + 1);
		setRecoveryTokens(validatedToken.tokens_array);

		return validatedToken;
	}

	async function handleDelete2FA() {
		const deleted = await delete2FA({
			company_id: company.id,
			organization_id: organization.id
		});

		setCurrentStep(0);
		setCurrentActionStep(0);
		setOpenActionModal(false);
		setOpenGenerateTokenModal(false);

		return deleted;
	}

	async function handleChangeDefault2FA(type: TFATypes) {
		const changed = await changeDefault2FA({
			company_id: company.id,
			organization_id: organization.id,
			data: { type }
		});

		setGenerated2FA(changed);

		if (changed.message?.includes('changed')) {
			setOpenActionModal(false);
		} else {
			setCurrentActionStep(currentActionStep + 1);
		}

		return changed;
	}

	async function handleEnableAuthApp() {
		const enabled = await enableAuthApp({
			company_id: company.id,
			organization_id: organization.id
		});

		setGenerated2FA(enabled);
		setOpenActionModal(false);

		return enabled;
	}

	async function handleResendToken(email = '') {
		const userEmail = parsedUser.user.email;

		const generated = await resendToken({
			company_id: company.id,
			organization_id: organization.id,
			data: { email: email || userEmail }
		});

		return generated;
	}

	async function handleSendToken(email = parsedUser.user.email) {
		const generated = await sendToken({
			company_id: company.id,
			organization_id: organization.id,
			data: { email }
		});

		return generated;
	}

	async function handleReset2FA(type: TFATypes, token: string) {
		const reset = await reset2FA({
			company_id: company.id,
			organization_id: organization.id,
			data: { type, token }
		});

		if (reset.message || reset.qr_code_url) {
			setCurrentActionStep(1);
		}

		return reset;
	}

	async function handleConfirmPassword(email: string, password: string) {
		const confirmed = await confirmPassword({
			email,
			password
		});

		setCorrectPassword(confirmed.correct_password);

		return confirmed;
	}

	const context: Context = {
		user: parsedUser.user,
		user2FA,
		forced2FA,
		checked2FA,
		currentStep,
		generated2FA,
		recoveryTokens,
		fecthingUser2FA,
		selected2FAType,
		openActionModal,
		correctPassword,
		currentActionStep,
		openGenerateTokenModal,
		openDeactivateTokenModal,
		handleNextStep,
		handleReset2FA,
		handleSendToken,
		handleResetStep,
		handleDelete2FA,
		handle2FAChecked,
		handleGenerate2FA,
		handleValidate2FA,
		handleResendToken,
		handlePreviousStep,
		handleSelectedType,
		handleEnableAuthApp,
		handleOpenActionModal,
		handleConfirmPassword,
		handleCorrectPassword,
		handleChangeDefault2FA,
		handleOpenGenerateTokenModal,
		handleOpenDeactivateTokenModal
	};

	return <TFAContext.Provider value={context}>{children}</TFAContext.Provider>;
};

export function useTFAContext() {
	const context = useContext(TFAContext);
	return context;
}
