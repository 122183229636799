type ActionPlanStatuses = {
	id: number;
	status: string;
	caption: string;
	color: string;
};

export const actionPlanStatuses: ActionPlanStatuses[] = [
	{
		id: 1,
		status: 'to_do',
		caption: 'To Do',
		color: '#F78A38'
	},
	{
		id: 2,
		status: 'doing',
		caption: 'Doing',
		color: '#FFDE31'
	},
	{
		id: 3,
		status: 'done',
		caption: 'Done',
		color: '#2CC852'
	}
];
