import React, { useEffect, useState } from 'react';
import { Form } from 'antd';

import { SecondPage } from './Steps/SecondPage';
import { FirstPage } from './Steps/FirstPage';
import { useContractPlansContext } from '../context';
import { ModalStyled } from './styles';
import { Footer } from './footer';

const { useForm } = Form;

interface LogosModalProps {
	open: boolean;
	onCancel: () => void;
}

export function LogosModal({ open, onCancel }: Readonly<LogosModalProps>) {
	const { queryUrl, setIsUrlLogoOrganization, isUrlLogoOrganization, setIsUrlLogoCompany, isUrlLogoCompany } =
		useContractPlansContext();
	const [form] = useForm();

	const [current, setCurrent] = useState(0);

	useEffect(() => {
		queryUrl?.get('type') === 'organization' && setIsUrlLogoOrganization(true);
		queryUrl?.get('type') === 'company' && setIsUrlLogoCompany(true);

		if (queryUrl?.get('type') === 'company') {
			setCurrent(current + 1);
		}
	}, [queryUrl]);

	const steps = [
		{
			content: (
				<FirstPage
					url_logo={queryUrl?.get('url_logo')}
					id={queryUrl?.get('organization_id')}
					isUrlLogoOrganization={isUrlLogoOrganization}
					setIsUrlLogoOrganization={setIsUrlLogoOrganization}
					type={queryUrl?.get('type')}
				/>
			)
		},
		{
			content: <SecondPage />
		}
	];

	const next = () => {
		setCurrent(current + 1);
	};

	const initialValues: any = {
		company_name: ''
	};

	function handleModalClose() {
		onCancel();
		setCurrent(0);
		form.resetFields();
		setIsUrlLogoCompany(false);
		setIsUrlLogoOrganization(false);
	}

	return (
		<Form form={form} initialValues={initialValues} layout="vertical" style={{ minHeight: '600px' }}>
			<ModalStyled width={598} open={open} onCancel={handleModalClose} footer={null}>
				<>{steps[current]?.content}</>
				<Footer
					current={current}
					steps={steps}
					next={next}
					isUrlLogoOrganization={isUrlLogoOrganization}
					isUrlLogoCompany={isUrlLogoCompany}
					handleModalClose={handleModalClose}
					queryUrl={queryUrl?.get('type')}
				/>
			</ModalStyled>
		</Form>
	);
}
