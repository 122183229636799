import React from 'react';
import { Row } from 'antd';

import { Background } from '@/components/ui/Background';

import { Check2FAContent } from './Check2FAContent';

export const Check2FA: React.FC = () => {
	return (
		<>
			<Background />
			<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
				<Check2FAContent />
			</Row>
		</>
	);
};
