import React, { useState } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Button, Table, Space, Tooltip } from 'antd';
import Icon, { DownOutlined, RightOutlined, DeleteOutlined, EditOutlined, PictureOutlined } from '@ant-design/icons';

import { useGetOrganizations, type Contract } from '../hooks/useGetOrganizations';
import { PaginationCustom } from '../../../ui/Pagination';
import { ReactComponent as UsersSvg } from './users.svg';
import { useContractPlansContext } from '../context';
import { ExpandedRowRender } from './ExpandedRow';
import { Spinner } from '@/components/Spinner';
import { ColumnsType } from 'antd/lib/table';
import {
	OrganizationDTO,
	DeleteModalProps,
	LogoModalProps,
	UsersModalProps,
	ComapnyEditModalProps,
	ModalProps
} from '../types';
import * as S from './styles';

const formatNumber = (number: number) => {
	const locale = navigator.language || 'en-US';
	const formatter = new Intl.NumberFormat(locale, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 2
	});
	return formatter.format(number);
};

const formatMinutes = (minutes: number) => {
	const hours = Number(minutes || 0) / 60;
	return formatNumber(hours);
};

export function OrganizationsList() {
	const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
	const { filter, setUrl, handleSetLimit, handleSetOffset } = useContractPlansContext();
	const { isLoading, data, isError } = useGetOrganizations(filter);

	const handleModal = ({ name, type, organization_id, company_id, url_logo, modal }: ModalProps) => {
		organization_id && setUrl('organization_id', organization_id);
		company_id && setUrl('company_id', company_id);
		url_logo && setUrl('url_logo', url_logo);
		setUrl('modal', modal);
		name && setUrl('name', name);
		type && setUrl('type', type);
	};

	const onDelete = (props: DeleteModalProps) => handleModal({ ...props, modal: 'delete' });

	const onModalLogo = (props: LogoModalProps) => handleModal({ ...props, modal: 'logo' });

	const onModalUser = (props: UsersModalProps) => handleModal({ ...props, modal: 'users' });

	const onModalEdit = (props: UsersModalProps) => handleModal({ ...props, modal: 'edit' });

	const onModalCompanyEdit = (props: ComapnyEditModalProps) => handleModal({ ...props, modal: 'edit-company' });

	const columns: ColumnsType<Contract> = [
		{
			title: I18n.get('Corporate name'),
			dataIndex: 'name',
			key: 'name',
			align: 'left',
			width: '20%',
			render: (name: string) => name
		},
		{
			title: I18n.get('Industrial sites'),
			dataIndex: 'total_companies',
			key: 'companies',
			align: 'center',
			width: '20%',
			responsive: ['lg'],
			render: (total_companies: number) => total_companies
		},
		{
			title: I18n.get('Minutes used (Limit)'),
			key: 'used_minutes',
			align: 'center',
			width: '15%',
			render: (item: OrganizationDTO) => {
				const usedMinutes = formatMinutes(item?.used_minutes);
				const maxMinutes = formatMinutes(item?.max_minutes);
				return (
					<>
						{`${usedMinutes} ${I18n.get(
							`in the ${item?.recurrence === 'monthly' ? 'month' : 'year'}`
						)} (${maxMinutes})`}
					</>
				);
			}
		},
		{
			title: I18n.get('Uploaded videos (Limit)'),
			key: 'used_minutes',
			align: 'center',
			width: '15%',
			render: (item: OrganizationDTO) => {
				return (
					<>
						{item?.processed_videos}{' '}
						{I18n.get(`in the ${item?.recurrence === 'monthly' ? 'month' : 'year'}`)} ({item?.max_upload})
					</>
				);
			}
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			width: '15%',
			responsive: ['lg'],
			render: (created_at: string) => moment(created_at).format('L')
		},
		{
			title: I18n.get('Actions'),
			key: 'actions',
			align: 'center',
			width: '10%',
			render: (item: OrganizationDTO) => (
				<S.ContainerActions>
					<Space size="middle">
						<Tooltip placement="bottomRight" title={I18n.get('Edit company')}>
							<Button
								ghost
								type="primary"
								onClick={() =>
									onModalEdit({
										name: item.name,
										type: 'organization',
										organization_id: item.organization_id
									})
								}
								icon={<EditOutlined style={{ fontSize: '20px' }} />}
							/>
						</Tooltip>
						<Tooltip placement="bottomRight" title={I18n.get('Manage users')}>
							<Button
								ghost
								type="primary"
								onClick={() =>
									onModalUser({
										name: item.name,
										type: 'organization',
										organization_id: item.organization_id
									})
								}
								icon={
									<Icon
										component={UsersSvg as React.ForwardRefExoticComponent<any>}
										style={{ fontSize: '20px' }}
									/>
								}
							/>
						</Tooltip>
						<Tooltip placement="bottomRight" title={I18n.get('Upload logos')}>
							<Button
								ghost
								type="primary"
								onClick={() =>
									onModalLogo({
										type: 'organization',
										organization_id: item.organization_id,
										url_logo: item.url_logo
									})
								}
								icon={<PictureOutlined style={{ fontSize: '20px' }} />}
							/>
						</Tooltip>
						<Tooltip placement="bottomRight" title={I18n.get('Delete company')}>
							<Button
								ghost
								danger
								disabled={item?.is_active === 0}
								onClick={() =>
									onDelete({
										name: item.name,
										type: 'organization',
										organization_id: item.organization_id
									})
								}
								icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
							/>
						</Tooltip>
					</Space>
				</S.ContainerActions>
			)
		}
	];

	function handleExpandRow(open: boolean, row: OrganizationDTO): void {
		setExpandedRowKeys((prev) =>
			open ? [...prev, row.organization_id] : prev.filter((key) => key !== row.organization_id)
		);
	}

	function handleExpandIcon({ expanded, onExpand, record }: any): JSX.Element {
		return (
			<Button
				type="text"
				style={{ width: '80%' }}
				icon={expanded ? <DownOutlined /> : <RightOutlined />}
				onClick={(e: any) => onExpand(record, e)}
			/>
		);
	}

	function expandedRowRender(record: OrganizationDTO) {
		return (
			<ExpandedRowRender
				record={record}
				columns={columns}
				onDelete={onDelete}
				onModalLogo={onModalLogo}
				onModalUser={onModalUser}
				onModalCompanyEdit={onModalCompanyEdit}
			/>
		);
	}

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <div>{I18n.get('Unable to show results')}</div>;
	}

	return (
		<S.Container>
			<Table
				rowKey="organization_id"
				columns={columns}
				dataSource={data?.rows}
				expandable={{
					expandedRowKeys,
					expandedRowRender,
					onExpand: handleExpandRow,
					expandIcon: handleExpandIcon
				}}
				pagination={false}
			/>
			<PaginationCustom
				page={filter.page}
				loading={isLoading}
				total={data?.total}
				maxPage={filter.limit}
				setPage={handleSetOffset}
				setMaxPage={handleSetLimit}
			/>
		</S.Container>
	);
}
