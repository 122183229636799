import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type CalculateRiskDTO = BaseContext & {
	exposure_id: string;
	step_key_id: string;
	consequence_id: string;
	probability_id: string;
	custom_report_score_scale_id: string;
};

export type CalculateRiskResponse = {
	score: number;
	result: number;
	exposure_score: number;
	consequence_score: number;
	probability_score: number;
};

async function calculateRisk<T, Body>(payload: Body): Promise<T> {
	const url = `/custom-report/result/step-key/calculate-risk`;
	const { data } = await Api.get<T>(url, {
		params: payload
	});
	return data;
}

export function useCalculateCustomReportStepKeyRisk({
	company_id,
	exposure_id,
	step_key_id,
	consequence_id,
	probability_id,
	organization_id,
	custom_report_score_scale_id
}: CalculateRiskDTO) {
	const parameters = {
		company_id,
		exposure_id,
		step_key_id,
		consequence_id,
		probability_id,
		organization_id,
		custom_report_score_scale_id
	};
	const risk = useQuery<CalculateRiskResponse, AxiosError<Response>>(
		['personalised_report_step_key_risk', parameters],
		() => calculateRisk<CalculateRiskResponse, CalculateRiskDTO>(parameters),
		{
			retry: 0,
			enabled:
				!!company_id &&
				!!exposure_id &&
				!!step_key_id &&
				!!consequence_id &&
				!!probability_id &&
				!!organization_id &&
				!!custom_report_score_scale_id
		}
	);
	return {
		...risk,
		data: risk.data ?? ({} as CalculateRiskResponse)
	};
}
