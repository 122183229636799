import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { customReportService } from './services';
import { CreateCustomReport, UpdateCustomReport } from './types/request';

export const useCreateCustomReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateCustomReport) => customReportService.createCustomReport(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(err?.response?.data?.message)
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['custom_reports']);
			message.success(I18n.get('Custom report created successfully'));
		}
	});
};

export const useUpdateCustomReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateCustomReport) => customReportService.updateCustomReport(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(err?.response?.data?.message)
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['custom_reports']);
			message.success(I18n.get('Custom report updated successfully'));
		}
	});
};
