import { logger } from 'redux-logger';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { legacy_createStore as createStore, applyMiddleware, Middleware } from 'redux';
import { rootReducer } from '@/redux';
import { EnvironmentEnum } from '@/utils/enum';

const middlewares: Middleware[] = [thunk as ThunkMiddleware];

if (process.env.NODE_ENV === EnvironmentEnum.DEVELOPMENT) {
	middlewares.push(logger as Middleware);
}

export function configureStore() {
	return createStore(rootReducer, applyMiddleware(...middlewares));
}

const store = configureStore();

export default store;
