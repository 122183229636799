import React from 'react';
import { Row } from 'antd';
import { Background } from '@/components/ui/Background';
import { ForgotPasswordContent } from './ForgotPasswordContent';

export const ForgotPassword: React.FC = () => (
	<>
		<Background />
		<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
			<ForgotPasswordContent />
		</Row>
	</>
);
