import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { LabelStyled, SubTitleStyled, TitleHeaderStyled } from '../../styles';
import { UploadLogo } from '@/components/ui/UploadLogos';
import { useContractPlansContext } from '../../../context';

type FirstPageProps = {
	id: string;
	type: any;
	url_logo: string;
	setIsUrlLogoOrganization: any;
	isUrlLogoOrganization: boolean;
};

export function FirstPage({ url_logo, id, setIsUrlLogoOrganization, isUrlLogoOrganization, type }: FirstPageProps) {
	const { fileList, setFileList } = useContractPlansContext();

	return (
		<Row justify="center" gutter={[24, 0]}>
			<Col span={24}>
				<Row justify="center" align="middle">
					<Col span="auto">
						<TitleHeaderStyled level={1}>{I18n.get('Upload logos')}</TitleHeaderStyled>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row justify={'center'} gutter={[0, 20]}>
					<Col>
						<SubTitleStyled>{I18n.get('Upload your company logo')}</SubTitleStyled>
					</Col>
				</Row>
				<Row justify={'center'} style={{ marginTop: '21px' }}>
					<Col>
						<UploadLogo
							url={url_logo}
							organizationId={id}
							type={type}
							fileList={fileList}
							setFileList={setFileList}
							setBlockButton={setIsUrlLogoOrganization}
							blockButton={isUrlLogoOrganization}
							isDisable={false}
						/>
					</Col>
				</Row>
				<Row justify={'center'} style={{ marginTop: '10px' }}>
					<Col>
						<LabelStyled>{I18n.get('Upload files')}</LabelStyled>
					</Col>
				</Row>
				<Row justify={'center'}>
					<Col>
						<LabelStyled>{I18n.get('.jpeg or .png up to 5MB')}.</LabelStyled>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
