import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { DetailDescription, Details, DetailTitle } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

export const FirstRow: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;
	const { kim_pp } = basic_information;

	return (
		<Row justify="space-between">
			<Col span={6} style={{ paddingLeft: '1rem' }}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{kim_pp?.distance ? I18n.get('Distance') : I18n.get('Duration')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>
							{kim_pp?.distance ?? kim_pp?.duration}
							{kim_pp?.distance ? I18n.get('meters') : I18n.get('minutes')}
						</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={4}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Mass')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>{kim_pp?.mass} kg</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={4}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Vehicle')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>{I18n.get(kim_pp?.vehicle)}</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={6}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Probability of physical overload')}</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>{I18n.get(kim_pp?.probability_of_physical_overload)}</DetailDescription>
					</Row>
				</Details>
			</Col>
		</Row>
	);
};
