export enum OWAS_QUERY_KEYS {
	GET_LOAD = 'get-owas-load',
	GET_TASKS = 'get-owas-tasks',
	GET_RESULT = 'get-owas-result',
	GET_FRAMES = 'get-owas-frames',
	GET_REPORT = 'get-owas-report',
	GET_CATEGORIES = 'get-owas-categories'
}

export enum STEPS_FORM_FIELD {
	INFORMATION = 'information',
	EVALUATION = 'evaluation'
}

export enum EVALUATION_FORM_FIELDS {
	TASK_NAME = 'task_id',
	LOAD = 'load',
	MANUAL_TOOLS = 'manual_tools',
	TORSO = 'torso',
	UPPER_LIMBS = 'upper_limbs',
	LOWER_LIMBS = 'lower_limbs'
}

export enum OWAS_CATEGORIES {
	TORSO = 'torso',
	UPPER_LIMBS = 'upper_limbs',
	LOWER_LIMBS = 'lower_limbs'
}

export enum OWAS_SUB_CATEGORIES {
	BENT = 'bent',
	WALKING = 'walking',
	SITTING = 'sitting',
	STRAIGHT = 'straight',
	KNEELING = 'kneeling',
	ONE_ARM_UP = 'one_arm_up',
	TWO_ARMS_UP = 'two_arms_up',
	TWO_ARMS_DOWN = 'two_arms_down',
	LESS_THAN_10KG = 'less_than_10kg',
	MORE_THAN_20KG = 'more_than_20kg',
	BENT_AND_ROTATED = 'bent_and_rotated',
	BETWEEN_10KG_20KG = 'between_10kg_20kg',
	STRAIGHT_AND_ROTATED = 'straight_and_rotated',
	STANDING_ON_ONE_STRAIGHT_LEG = 'standing_on_one_straight_legs',
	STANDING_ON_TWO_STRAIGHT_LEGS = 'standing_on_two_straight_legs',
	STANDING_OR_SQUATTING_ON_ONE_BENT_LEG = 'standing_or_squatting_on_one_bent_leg',
	STANDING_OR_SQUATTING_ON_TWO_FLEXED_LEGS = 'standing_or_squatting_on_two_flexed_legs'
}
