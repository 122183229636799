import { RoomKey, RoomValue, SocketUrl } from '@/types';
import { Utils } from './index';
import config from '../config';

const ENDPOINT: SocketUrl = config.api.ws;

interface ISocketConfig {
	path: string;
	transports: Array<string>;
	query: {
		organization_id: string;
		company_id: string;
	};
	auth: {
		token: string;
	};
	reconnection: boolean;
	reconnectionDelay: number;
	reconnectionDelayMax: number;
	reconnectionAttempts: any;
}

const roomOptions: { [key in RoomKey]: RoomValue } = {
	[RoomKey.REPORT_STATUS]: RoomValue.REPORT_STATUS,
	[RoomKey.FILE_STATUS]: RoomValue.FILE_STATUS
};

export const socketConfig = (organizationId: string, companyId: string): ISocketConfig => {
	const { token } = Utils.getLocalStorage('info_user');
	return {
		query: { organization_id: organizationId, company_id: companyId },
		path: `/${config.path}/socket`,
		transports: ['websocket'],
		auth: {
			token
		},
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		reconnectionAttempts: Infinity
	};
};

export const getRoom = (room: RoomKey): string => {
	const path = roomOptions[room];
	const url = `${ENDPOINT}${path}`;
	return url;
};
