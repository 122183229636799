import React, { useEffect } from 'react';
import { Col, Form, Row } from 'antd';

import { CustomModal } from '@/components/ui/CustomModal';

import { CustomTitle, Header } from './Generate2FAModal/styles';
import { ConfirmPasswordModal } from './ConfirmPasswordModal';
import { Deactivate } from './Deactivate';
import { useTFAContext } from './context';
import { ActionsForm } from './styles';

const { useForm } = Form;

export const DeactivateTokenModal = () => {
	const [form] = useForm();
	const { openDeactivateTokenModal, correctPassword } = useTFAContext();
	const { handleResetStep, handle2FAChecked, handleCorrectPassword, handleOpenDeactivateTokenModal } =
		useTFAContext();

	useEffect(() => {
		handleCorrectPassword(false);
	}, []);

	function handleClose() {
		form.resetFields();
		handleResetStep();
		handle2FAChecked();
		handleCorrectPassword(false);
		handleOpenDeactivateTokenModal(false);
	}

	if (!correctPassword) {
		return <ConfirmPasswordModal />;
	}

	return (
		<CustomModal
			open={openDeactivateTokenModal}
			onCancel={handleClose}
			width={750}
			bodyStyle={{ height: 600 }}
			footer={false}
			centered
		>
			<ActionsForm form={form} layout="vertical">
				<Header>
					<Col span={24}>
						<CustomTitle>Deactivate two-factor authentication</CustomTitle>
					</Col>
				</Header>
				<Row>
					<Col span={24}>
						<Deactivate />
					</Col>
				</Row>
			</ActionsForm>
		</CustomModal>
	);
};
