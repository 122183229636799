import React from 'react';
import { Col, Row } from 'antd';

import { TitleCol } from '../styles';
import { Text } from '@/components/Typography';

export function Header() {
	return (
		<Col span={24}>
			<Row>
				<Col span={16}>
					<Text style={{ fontSize: '0.8rem', fontWeight: 600, marginLeft: 10 }}>Name</Text>
				</Col>
				<TitleCol span={5}>
					<Text style={{ fontSize: '0.8rem', fontWeight: 600 }}>Position</Text>
				</TitleCol>
			</Row>
		</Col>
	);
}
