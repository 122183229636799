import React, { Fragment, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

import * as S from './styles';

const OPACITY_COLOR_BANDS = 0.3;

export const normalizeRGBOpacity = ({ rgb }: { rgb: { r: string; g: string; b: string } }) =>
	`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${OPACITY_COLOR_BANDS})`;

interface ColorPickerProps {
	value: string;
	onChange: (color: ColorResult) => {};
}

export function ColorPicker({ value, onChange }: Readonly<ColorPickerProps>) {
	const [state, setState] = useState({
		displayColorPicker: false,
		color: value || '#000'
	});

	const handleClick = () => {
		setState((current) => ({ ...current, displayColorPicker: !current.displayColorPicker }));
	};

	const handleClose = () => {
		setState((current) => ({ ...current, displayColorPicker: false }));
	};

	const handleChange = (color: ColorResult) => {
		if (onChange) onChange(color);
		setState((current) => ({ ...current, color: color.hex }));
	};

	return (
		<Fragment>
			<S.ButtonColor onClick={handleClick}>
				<S.ColorSelect color={state.color} />
			</S.ButtonColor>
			{state.displayColorPicker ? (
				<S.PopoverColor>
					<S.CoverColor onClick={handleClose} />
					<SketchPicker color={state.color} onChange={handleChange} />
				</S.PopoverColor>
			) : null}
		</Fragment>
	);
}
