import styled from 'styled-components';
import { Typography } from 'antd';

interface WrapperTitleProps {
	isbold?: string;
}

export const WrapperTitle = styled(Typography.Title)<WrapperTitleProps>`
	${({ isbold }) => isbold === 'false' && `font-weight: normal !important;`};
`;
