import React from 'react';

interface FilesMoreDetailsSVGProps extends React.HTMLProps<HTMLSpanElement> {}

export function FilesMoreDetailsSVG(props: Readonly<FilesMoreDetailsSVGProps>) {
	return (
		<span {...props} style={{ display: 'flex', ...props.style }}>
			<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg">
				<path d="M16 20L30 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M16 1L30 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M16 24.5L30 24.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M16 5.5L30 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M16 29L30 29" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M16 10L30 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
				<rect width="11" height="11" rx="1" fill="currentColor" />
				<rect y="18" width="11" height="12" rx="1" fill="currentColor" />
			</svg>
		</span>
	);
}
