import { ModalProps } from 'antd';
import React from 'react';

import { ModalContainer } from './styles';

interface CustomModalProps extends ModalProps {
	width: number;
	children: React.ReactNode;
}

export const CustomModal: React.FC<CustomModalProps> = ({ children, width, ...props }) => {
	return (
		<ModalContainer width={width} {...props}>
			{children}
		</ModalContainer>
	);
};
