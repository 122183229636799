import React from 'react';
import { Col } from 'antd';
import * as Typography from '@/components/Typography';

export const Title: React.FC = () => (
	<Col span={24}>
		<Typography.Title level={4} style={{ textAlign: 'center' }}>
			Single Sign-On
		</Typography.Title>
	</Col>
);
