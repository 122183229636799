import React, { ReactNode } from 'react';

import { CardContainer } from './styles';

interface ToolCardProps {
	loading?: boolean;
	checked?: boolean;
	children: ReactNode;
}

export const ToolCard: React.FC<ToolCardProps> = ({ children, checked, loading }) => (
	<CardContainer skeleton={loading} checked={checked}>
		{children}
	</CardContainer>
);
