import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { Container } from './styles';
import { FirstRow } from './FirstRow';
import { SecondRow } from './SecondRow';
import { Title } from '@/components/Typography';

import { usePersonalisedReportResultContext } from '../../context';

export const KimPushPull: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();

	const kim_pp = personalisedReportResult?.tools?.kim_pp;
	if (!kim_pp) {
		return null;
	}

	return (
		<Row justify="center">
			<Col xs={24}>
				<Row gutter={[0, 24]}>
					<Col offset={1} span={23}>
						<Row>
							<Title style={{ margin: 0 }} level={3}>
								{I18n.get('Push and Pull (KIM)')}
							</Title>
						</Row>
					</Col>

					<Col offset={1} span={23}>
						<Row>
							<Container>
								<Col span={24}>
									<FirstRow />
								</Col>
								<Divider style={{ margin: 0 }} />
								<Col span={24}>
									<SecondRow />
								</Col>
							</Container>
						</Row>
					</Col>
				</Row>
			</Col>
			<Divider type="horizontal" />
		</Row>
	);
};
