import Api from '@/services/api';

const organizationService = {
	listOrganizationByUser: async () => {
		try {
			const { data, status } = await Api.get('/organization/user_list');
			if (status !== 200 && !data) {
				throw new Error('Bad request');
			}
			return data;
		} catch (err) {
			throw err;
		}
	},
	listCompanyByUser: async (parameters) => {
		try {
			const organization_id = parameters;
			const url = `/company/user_list/${organization_id}`;
			const { data, status } = await Api.get(url);
			if (status !== 200 || !data) {
				throw new Error('Bad request');
			}
			return data;
		} catch (err) {
			throw err;
		}
	},
	createOrganization: async (parameters) => {
		try {
			const url = '/organization/create';
			const { status, data } = await Api.post(url, parameters);
			if (status !== 200 && !data) {
				throw new Error('Internal server error');
			}
			return data;
		} catch (err) {
			throw err;
		}
	},
	createCompany: async (parameters) => {
		try {
			const { data, status } = await Api.post('/company/create', parameters);
			if (status !== 200 && !data) {
				throw new Error('Bad request');
			}
			return data;
		} catch (err) {
			throw err;
		}
	},

	getOrganization: async () => {
		try {
			const { data, status } = await Api.get('/organization/user_list');
			if (status !== 200 && !data) {
				throw new Error('Bad request');
			}
			return data;
		} catch (err) {
			throw err;
		}
	},
	getCompanies: async (parameters) => {
		try {
			const { organization_id } = parameters;
			const url = `/company/user_list/${organization_id}`;
			const { data, status } = await Api.get(url);
			if (status !== 200 || !data) {
				throw new Error('Bad request');
			}
			return data;
		} catch (err) {
			throw err;
		}
	},
	getSectors: async (parameters) => {
		try {
			const { organization_id, company_id } = parameters;
			const url = `/company/${organization_id}/${company_id}/sector`;
			const { data, status } = await Api.get(url);
			if (status !== 200 || !data) {
				throw new Error('Bad request');
			}
			return data;
		} catch (err) {
			throw err;
		}
	}
};

export default organizationService;
