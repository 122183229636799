import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Form, message } from 'antd';
import { type OrganizationPlanDTO, useCreateOrganizationPlan } from '@/components/Settings/ContractPlans/hooks';
import { type OrganizationDTO, useCreateOrganization } from '@/components/Settings/ContractPlans/hooks';
import { ProcessingControl } from './Steps/ProcessingControl';
import { Informations } from './Steps/Informations';
import { Context, Methods, States } from './types';
import { Companies } from './Steps/Companies';

const { useForm } = Form;

const CreateOrganizationContext = createContext<Context>({} as Context);

const steps = [
	{
		component: <Informations />
	},
	{
		component: <ProcessingControl />
	},
	{
		component: <Companies />
	}
];

interface CreateOrganizationProviderProps {
	children: ReactNode;
	onCancel(): void;
}

export function CreateOrganizationProvider({ children, onCancel }: Readonly<CreateOrganizationProviderProps>) {
	const [form] = useForm();

	const [currentStep, setCurrentStep] = useState<number>(0);
	const [plan, setPlan] = useState<OrganizationPlanDTO>({} as OrganizationPlanDTO);
	const [organization, setOrganization] = useState<OrganizationDTO>({} as OrganizationDTO);

	const {
		mutateAsync: createOrganization,
		isLoading: isCreatingOrganization,
		error: errorCreateOrganization,
		isError: isErrorCreateOrganization
	} = useCreateOrganization();
	const {
		mutateAsync: createOrganizationPlan,
		isLoading: isCreatingOrganizationPlan,
		error: errorCreatePlan,
		isError: isErrorCreatePlan
	} = useCreateOrganizationPlan();

	const is_last_step = currentStep === steps.length - 1;
	const isLoading = isCreatingOrganization || isCreatingOrganizationPlan;
	const is_error = isErrorCreateOrganization || isErrorCreatePlan;
	const error: any = errorCreatePlan || errorCreateOrganization;

	if (is_error || error) {
		message.error('Oops! Something happened.');
	}

	function handleChangeStep(step: number): void {
		setCurrentStep(step);
	}

	async function handleCreateOrganization(): Promise<any> {
		const values = await form.validateFields();

		const payload = {
			nit: values.nit,
			city: values.city,
			address: values.address,
			district: values.district,
			company_name: values.name,
			contract_plan_id: values.plan
		};

		return await createOrganization(payload);
	}

	async function handleCreatePlan(organization_id: string) {
		const stepValues = form.getFieldsValue();

		const payload = {
			organization_id,
			recurrence: stepValues.recurrence,
			max_minutes: stepValues.max_minutes,
			max_uploads: stepValues.max_uploads,
			expiration_day: stepValues.renewal_day,
			expiration_month: stepValues.renewal_month
		};

		return await createOrganizationPlan(payload);
	}

	function handlePreviousStep() {
		const changeStep = currentStep - 1;

		if (changeStep < 0) {
			return onCancel();
		}

		setCurrentStep(changeStep);
	}

	async function handleNextStep() {
		await form.validateFields();

		if (currentStep === 0) {
			const createdOrganization = await handleCreateOrganization();
			setOrganization(createdOrganization);
		}

		if (currentStep === 1) {
			const plan = await handleCreatePlan(organization.id);
			setPlan(plan);
		}

		if (is_last_step) {
			return onCancel();
		}

		setCurrentStep(currentStep + 1);
	}

	function handleClearFieldErrors(_changedValues: any, allValues: any) {
		const updatedFields = Object.keys(allValues)
			.filter((name) => form.getFieldError(name).length)
			.map((name) => ({ name, errors: [] }));

		form.setFields(updatedFields);
	}

	const states: States = {
		plan,
		steps,
		isLoading,
		currentStep,
		organization
	};

	const methods: Methods = {
		onNextStep: handleNextStep,
		onStepChange: handleChangeStep,
		onPreviousStep: handlePreviousStep
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<CreateOrganizationContext.Provider value={context}>
			<Form onValuesChange={handleClearFieldErrors} form={form}>
				{children}
			</Form>
		</CreateOrganizationContext.Provider>
	);
}

export function useCreateOrganizationContext() {
	const context = useContext(CreateOrganizationContext);
	return context;
}
