import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Bottom = styled.div`
	.bottom-buttons.ant-col {
		position: absolute;
		bottom: 0;
	}
`;
