import { Form } from 'antd';
import styled from 'styled-components';

export const CustomItemSwitch = styled(Form.Item)`
	margin: 0;
	.ant-form-item-control {
		display: flex;
		flex-direction: row;
		justify-content: end;
	}
	.ant-form-item-label {
		label {
			font-size: 1rem;
			::after {
				display: none;
			}
		}
	}
`;
