import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Container } from './styles';
import { Detailed } from './Detailed';
import { OverallScore } from './OverallScore';
import { Title } from '@/components/Typography';
import { usePersonalisedReportResultContext } from '../../context';

export const LibertyMutual: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();

	const liberty_mutual = personalisedReportResult?.tools?.liberty_mutual;
	if (!liberty_mutual) {
		return null;
	}

	return (
		<Row justify="center">
			<Col xs={24}>
				<Row gutter={[0, 24]}>
					<Col offset={1} span={23}>
						<Row>
							<Title style={{ margin: 0 }} level={3}>
								{I18n.get('Material handling')} {I18n.get('(Liberty Mutual)')}
							</Title>
						</Row>
					</Col>
					<Col offset={1} span={23}>
						<Container>
							<Row style={{ height: '200px' }}>
								<Col span={12}>
									<Detailed />
								</Col>
								<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
								<Col span={11}>
									<OverallScore />
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Col>
			<Divider type="horizontal" />
		</Row>
	);
};
