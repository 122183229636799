import { Input } from 'antd';
import styled from 'styled-components';
import { PlusCircleFilled } from '@ant-design/icons';

interface CustomPlusCircleFilledProps {
	inactive?: boolean;
}

export const CustomInput = styled(Input)`
	/* margin-left: 1rem; */
	border-radius: 0.5rem;
	border: 2px solid #e9e9e9;
`;

export const CustomButton = styled.button`
	border: none;
	width: max-content;
	height: max-content;
	background-color: transparent !important;

	transition: all 0.2s;
	:hover {
		svg {
			cursor: pointer;
			filter: brightness(1.2);
		}
	}
	:hover[disabled] {
		svg {
			cursor: not-allowed;
			filter: brightness(1);
		}
	}
`;

export const CustomPlusCircleFilled = styled(PlusCircleFilled)<CustomPlusCircleFilledProps>`
	font-size: 32px;
	color: ${({ inactive }) => (inactive ? '#d6d6d6' : '#2F54EB')};
	@media (min-width: 1280px) {
		font-size: 42px;
	}
`;
