import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useQueryParams } from './hooks/index';
import type { Context, Methods, States, Filter } from './types';

interface ContractPlansProviderProps {
	children: ReactNode;
}

const ContractPlansContext = React.createContext<Context>({} as Context);

export function ContractPlansProvider({ children }: Readonly<ContractPlansProviderProps>) {
	const [isUrlLogoOrganization, setIsUrlLogoOrganization] = useState<boolean>(false);
	const [isUrlLogoCompany, setIsUrlLogoCompany] = useState<boolean>(false);
	const [filter, setFilter] = useState<Filter>({ page: 1, limit: 10 });
	const [queryUrl, setQueryUrl] = useState<URLSearchParams>();
	const [fileList, setFileList] = useState<any[]>([]);

	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const { setQueryParam, removeProp } = useQueryParams();

	useEffect(() => {
		if (query && !queryUrl) {
			setQueryUrl(query);
		}
	}, []);

	function handleSetOffset(value: number): void {
		setFilter((prev) => ({ ...prev, page: value }));
	}

	function handleSetLimit(value: number): void {
		setFilter((prev) => ({ ...prev, limit: value }));
	}

	function onFilter(values: any): void {
		const { organization_name, has_limit_exceeded, company_name, contract_plan_id, created_at } = values || {};

		const startOfDay = created_at ? new Date(created_at[0]).setUTCHours(0, 0, 0, 0) : null;
		const endOfDay = created_at ? new Date(created_at[1]).setUTCHours(23, 59, 59, 999) : null;

		const params = {
			...(has_limit_exceeded && { has_limit_exceeded }),
			...(organization_name && { organization_name }),
			...(startOfDay && { start_date: startOfDay }),
			...(contract_plan_id && { contract_plan_id }),
			...(endOfDay && { end_date: endOfDay }),
			...(company_name && { company_name })
		};

		setFilter((prev) => ({ ...prev, ...params }));
	}

	function handleOnResetFilter(): void {
		setFilter({ page: 1, limit: 10 });
	}

	function setUrl(key: string, value: string): void {
		setQueryParam(key, value, query, setQueryUrl);
	}

	function removePropQuery(key: string): void {
		removeProp(key, query, setQueryUrl);
	}

	const states: States = {
		setIsUrlLogoOrganization,
		isUrlLogoOrganization,
		setIsUrlLogoCompany,
		isUrlLogoCompany,
		setFileList,
		fileList,
		queryUrl,
		filter
	};

	const methods: Methods = {
		setUrl,
		onFilter,
		setQueryUrl,
		removePropQuery,
		handleSetLimit,
		handleSetOffset,
		handleOnResetFilter
	};

	const context: Context = {
		...states,
		...methods
	};

	return <ContractPlansContext.Provider value={context}>{children}</ContractPlansContext.Provider>;
}

export function useContractPlansContext() {
	const context = useContext(ContractPlansContext);
	return context;
}
