import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Api from '../services/api';
import { PrivateRoutes } from './PrivateRoutes';
import Spinner from '../components/Layout/Spinner';
import { Container } from '../components/Container';
import { controlSpinnerRoute, isAuthenticated } from '../redux/auth/actions';

import { SingleSignOnLogin } from '@/pages/SingleSignOn/Login';
import { LoginCallback } from '@/pages/SingleSignOn/Callback';

import { Login } from '../pages/Login';
import { Check2FA } from '@/pages/Check2FA';
import { Reset2FA } from '@/pages/Reset2FA';
import { Generate2FA } from '@/pages/Generate2FA';
import { SingleSignOn } from '../pages/SingleSignOn';
import { ResetPassword } from '../pages/ResetPassword';
import { ForgotPassword } from '../pages/ForgotPassword';

const CustomRouter = ({ contextComponent, component: Component, isPrivate, path, ...rest }) => {
	const isLoading = useSelector((state) => state.auth.spinnerRoute);
	const isAuthenticated = useSelector((state) => state.auth.authenticated);

	const Provider = contextComponent;

	if (isLoading) {
		return <Spinner />;
	}

	if (isPrivate && !isAuthenticated) {
		return <Redirect to="/login" />;
	}

	if (path !== '/' && isAuthenticated) {
		return <Redirect to="/" />;
	}

	return (
		<Route path={path}>
			{!contextComponent ? (
				<Component {...rest} />
			) : (
				<Provider>
					<Component {...rest} />
				</Provider>
			)}
		</Route>
	);
};

const Routes = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		/* Verificando se usuário já tem uma sessão  */

		const info_user = JSON.parse(localStorage.getItem('info_user'));

		if (info_user && info_user.token) {
			Api.defaults.headers.Authorization = `Bearer ${info_user.token}`;
			dispatch(isAuthenticated(true));
		}

		dispatch(controlSpinnerRoute());
	}, [dispatch]);

	return (
		<Switch>
			<CustomRouter exact path="/sso/login" component={SingleSignOnLogin} />
			<CustomRouter exact path="/sso/login/callback" component={LoginCallback} />
			<CustomRouter exact path="/login" component={Login} />
			<CustomRouter exact path="/login/callback" component={SingleSignOn} />
			<CustomRouter exact path="/forgot-password" component={ForgotPassword} />
			<CustomRouter exact path="/check-two-factor/:user_id" component={Check2FA} />
			<CustomRouter exact path="/recovery-two-factor/:user_id" component={Reset2FA} />
			<CustomRouter exact path="/generate-two-factor/:user_id/:type?/:qr_code_url?" component={Generate2FA} />
			<CustomRouter exact path="/password-reset/:token/:email" component={ResetPassword} />
			<CustomRouter isPrivate path="/" children={PrivateRoutes} component={Container} />
		</Switch>
	);
};

export default Routes;
