import React from 'react';
import { AiOutlineCloudUpload, AiOutlineWarning, AiOutlineSmile } from 'react-icons/ai';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { I18n } from '@aws-amplify/core';

import * as S from './styles';

const FILE_MAX_SIZE: number | string = process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE_GB ?? 2;
const LIMIT_MAX_FILES = process.env.REACT_APP_MAX_FILES ?? 10;
const LIMIT_SIZE = Number(FILE_MAX_SIZE) * 1024 ** 3;

interface DropProps {
	onUpload: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
	disabled: boolean;
	rejectMessage: (value: FileRejection[]) => void;
}

export function Drop({ onUpload, disabled, rejectMessage }: Readonly<DropProps>) {
	const acceptMimeType = {
		'video/mp4': [],
		'video/avi': [],
		'video/MP2T': [],
		'video/mpeg': [],
		'video/3gpp': [],
		'video/x-flv': [],
		'video/x-ms-wmv': [],
		'video/quicktime': [],
		'video/x-msvideo': [],
		'video/x-matroska': [],
		'application/x-mpegURL': []
	};

	function renderFeedback(isDragActive: boolean, isDragReject: boolean) {
		if (!isDragActive) {
			return (
				<S.ContainerMessage style={{ flexDirection: 'column' }}>
					<AiOutlineCloudUpload
						size={40}
						color={!disabled ? '#3956f8' : '#999999'}
						style={{ alignSelf: 'center' }}
					/>
					{I18n.get('Add files or drag')}
					<S.MaxSize>{`${I18n.get('Max size')} : ${FILE_MAX_SIZE} GB`}</S.MaxSize>
					<S.MaxSize>{`${I18n.get('Max files')} : ${LIMIT_MAX_FILES}`}</S.MaxSize>
				</S.ContainerMessage>
			);
		}

		if (isDragReject && !disabled) {
			return (
				<S.ContainerMessage>
					<AiOutlineWarning size={40} color="#deb009" style={{ alignSelf: 'center' }} />
					{I18n.get('Unsupported file')}
					{I18n.get(' or too many files')}
				</S.ContainerMessage>
			);
		}

		return (
			<S.ContainerMessage>
				<AiOutlineSmile size={40} color="#3AC430" style={{ alignSelf: 'center' }} />
				{I18n.get('Drop files here')}
			</S.ContainerMessage>
		);
	}

	return (
		<Dropzone
			minSize={0}
			onDrop={onUpload}
			disabled={disabled}
			maxSize={LIMIT_SIZE}
			accept={acceptMimeType}
			onDropRejected={(error) => rejectMessage(error)}
			maxFiles={Number(LIMIT_MAX_FILES)}
		>
			{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
				<S.DropContainer {...getRootProps()} disabled={disabled}>
					<input {...getInputProps()} />
					{renderFeedback(isDragActive, isDragReject)}
				</S.DropContainer>
			)}
		</Dropzone>
	);
}
