import { Divider, Input } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-wrap: nowrap;
	padding: 8px;
`;

export const DividerStyled = styled(Divider)`
	margin: 4px 0;
`;

export const InputStyled = styled(Input)`
	flex: auto;
	border-radius: 7px;
`;
