import styled from 'styled-components';

export const Title = styled.h2`
	font-weight: 600;
`;

export const SubTitle = styled.h3`
	font-weight: 400;
	margin: 0;
`;

export const Value = styled.span`
	display: flex;
	justify-content: flex-end;
	font-weight: 600;
	font-size: 1rem;
`;

export const Summary = styled.div`
	padding: 1rem 2rem;
	height: 90%;
`;
