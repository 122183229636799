import Api from '@/services/api';
import { URLDownloadDTO } from './types';

async function downloadPDF(payload: any) {
	const url = '/ergonomic-tool/preliminary-analysis/document/pdf';
	const { data } = await Api.post<URLDownloadDTO>(url, payload);
	return data;
}

async function consolidatePDF(payload: any) {
	const url = '/ergonomic-tool/preliminary-analysis/consolidate-pdf';
	const { data } = await Api.put<URLDownloadDTO>(url, payload);
	return data;
}

export const Services = {
	downloadPDF,
	consolidatePDF
};
