import React from 'react';
import { Col, Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useApplicationContext } from '@/context/Application';

import { URL_LOGO } from '@/utils/constants';
import { PROFILE_DEFAULT } from '@/utils/enum';
import { TitleContainer, KinebotLogoContainer } from './styles';
import { usePersonalisedReportContext } from '@/components/views/PersonalisedReports/PersonalisedReport/context';

export const Header: React.FC = () => {
	const { organization, company } = useApplicationContext();
	const { personalisedReport } = usePersonalisedReportContext();
	const company_logo = company?.url_logo !== PROFILE_DEFAULT.URL ? company?.url_logo : undefined;
	const profile_logo = company_logo ?? organization?.url_logo;

	return (
		<Row>
			<Col span={24}>
				<Row align="middle" justify="space-between" gutter={[40, 0]}>
					<Col span={4}>
						<KinebotLogoContainer>
							<Image preview={{ visible: false, mask: false }} src={URL_LOGO} />
						</KinebotLogoContainer>
					</Col>
					<Col span={16}>
						<TitleContainer>
							<span>{I18n.get('Result')}</span>
							<span id="pea">{I18n.get(personalisedReport?.description)}</span>
						</TitleContainer>
					</Col>
					<Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
						<Image
							src={profile_logo}
							preview={false}
							width="auto"
							height="30%"
							style={{ maxHeight: '70px', borderRadius: '50%', minHeight: '100px' }}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
