import React from 'react';
import { Vertical } from './Vertical';
import { Horizontal } from './Horizontal';
import { defaultOptions, Risk, RiskBarOptions } from './types';

interface RiskBarProps {
	risks: Risk[];
	options?: RiskBarOptions;
	total?: number | 0;
}

const VERTICAL_POSITION = 'vertical';

export const RiskBar: React.FC<RiskBarProps> = ({ risks, options = defaultOptions, total }) => {
	const defaultProps = { risks, options };

	if (options.orientation === VERTICAL_POSITION) {
		return <Vertical {...defaultProps} total={total} />;
	}

	return <Horizontal {...defaultProps} />;
};
