import { Collapse, Form, Row, Col, Button, Select, Input } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { SearchOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { ACTION_PLAN_DATE_STATUS, ACTION_PLAN_DEADLINE } from '@/utils/enum';
import { useApplicationContext } from '@/context/Application';
import hooks from '@/components/Settings/Organizations/hooks';
import { ActionPlansContext } from './context';

const { useForm } = Form;
const { Option } = Select;
const { useGetWorkstations } = hooks;

export function Filter() {
	const [form] = useForm();
	const queryClient = useQueryClient();
	const { organization } = useApplicationContext();

	const {
		companies,
		selectedCompanyId,
		setSelectedCompanyId,
		sectors,
		selectedSectorId,
		setSelectedSectorId,
		files,
		setSelectedFileId,
		responsibleUsers,
		onFilterActionPlans,
		setSelectedIsClearFilter,
		currentFilter
	} = useContext(ActionPlansContext);

	const workstations = useGetWorkstations(organization.id, selectedCompanyId, selectedSectorId);

	function resetFieldsForm(field) {
		const options = {
			company_id: ['sector_id', 'workstation', 'file_id'],
			sector_id: ['workstation', 'file_id']
		};

		form.resetFields(options[field] || []);
	}

	const resetQuery = () => {
		const action_board_type = ['TO DO', 'DOING', 'DONE'];
		const key = 'action_plans';

		action_board_type.forEach((elem) => {
			queryClient.resetQueries([key, organization.id, currentFilter, elem]);
		});
	};

	function onClearFilter() {
		form.resetFields();
		setSelectedCompanyId(null);
		setSelectedSectorId(null);
		onFilterActionPlans();
		setSelectedIsClearFilter(true);
		resetQuery();
	}

	async function onFinish() {
		resetQuery();
		const values = await form.validateFields();
		onFilterActionPlans(values);
		setSelectedIsClearFilter(true);
	}

	return (
		<Collapse>
			<Collapse.Panel header={I18n.get('Filters')}>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<Row gutter={[10, 0]}>
						<Col xs={24} sm={6}>
							<Form.Item name="title" label={I18n.get('Action plan title')}>
								<Input placeholder={I18n.get('Title')} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={5}>
							<Form.Item name="responsible_user_id" label={I18n.get('Person responsible')}>
								<Select
									showSearch
									allowClear
									style={{ width: '100%' }}
									placeholder={I18n.get('Select an option')}
									optionFilterProp="children"
								>
									{responsibleUsers.data?.map((person, index) => (
										<Option key={person.id} value={person.id}>
											{person.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={5}>
							<Form.Item name="deadline_status" label={I18n.get('Status')}>
								<Select
									allowClear
									showSearch
									style={{ width: '100%' }}
									placeholder={I18n.get('Select an option')}
									optionFilterProp="children"
								>
									{ACTION_PLAN_DATE_STATUS.map((option) => (
										<Select.Option key={option.value} value={option.value}>
											{I18n.get(option.description)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={4}>
							<Form.Item name="score" label={I18n.get('Order by worst score')}>
								<Select
									allowClear
									style={{ width: '100%' }}
									placeholder={I18n.get('Select an option')}
									optionFilterProp="children"
								>
									{ACTION_PLAN_DEADLINE.map((option) => (
										<Select.Option key={option.value} value={option.value}>
											{I18n.get(option.description)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={4}>
							<Form.Item name="deadline_date" label={I18n.get('Order by deadline')}>
								<Select
									allowClear
									style={{ width: '100%' }}
									placeholder={I18n.get('Select an option')}
									optionFilterProp="children"
								>
									{ACTION_PLAN_DEADLINE.map((option) => (
										<Select.Option key={option.value} value={option.value}>
											{I18n.get(option.description)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={6}>
							<Form.Item name="company_id" label={I18n.get('Industrial site')}>
								<Select
									allowClear
									showSearch
									onChange={(value) => {
										setSelectedCompanyId(value);
										resetFieldsForm('company_id');
									}}
									style={{ width: '100%' }}
									optionFilterProp="children"
									placeholder={I18n.get('Select an industrial site')}
								>
									{companies.data?.map((company, key) => (
										<Select.Option key={key} value={company.id}>
											{company.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={5}>
							<Form.Item name="sector_id" label={I18n.get('Sector')}>
								<Select
									allowClear
									showSearch
									onChange={(value) => {
										setSelectedSectorId(value);
										resetFieldsForm('sector_id');
									}}
									style={{ width: '100%' }}
									optionFilterProp="children"
									disabled={!selectedCompanyId}
									placeholder={I18n.get('Select a sector')}
								>
									{sectors.data?.map((sector, key) => (
										<Select.Option key={key} value={sector.id}>
											{sector.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={5}>
							<Form.Item label={I18n.get('Workstation')} name="workstation">
								<WorkstationSelect
									workstations={workstations?.data}
									sectorId={selectedSectorId}
									loading={sectors.loading}
									noCreate={true}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item name="file_id" label={I18n.get('File')}>
								<Select
									allowClear
									showSearch
									onChange={setSelectedFileId}
									style={{ width: '100%' }}
									optionFilterProp="children"
									placeholder={I18n.get('Select a file')}
									disabled={!selectedSectorId}
								>
									{files.data?.map((file, key) => (
										<Select.Option key={key} value={file.id}>
											{file.original_name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={6} style={{ paddingTop: '20px' }}>
						<Col sm={3}>
							<Button
								type="primary"
								htmlType="submit"
								style={{ width: '100%' }}
								icon={<SearchOutlined />}
							>
								{I18n.get('Search')}
							</Button>
						</Col>
						<Col sm={3}>
							<Button onClick={onClearFilter} style={{ width: '100%' }}>
								{I18n.get('Clear')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Collapse.Panel>
		</Collapse>
	);
}
