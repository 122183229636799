import styled from 'styled-components';
import { Row } from 'antd';

export const Container = styled(Row)`
	border-radius: 0.25rem;
	border: 1px solid #d9d9d9;
`;

export const Details = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;
