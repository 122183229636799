import { useMutation, useQuery } from '@tanstack/react-query';

import { GetCompaniesRequest, MinutesDetailsRequest, OrganizationFilter } from './types/request';
import { MinutesDetailsApiService } from './services/api';
import { OrganizationsDTO } from './types/response';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';

const minutesDetailsApiService = new MinutesDetailsApiService();

export const useGetOrganizations = (params: OrganizationFilter) => {
	const report = useQuery(['organization_ids'], () => minutesDetailsApiService.getOrganizations(params));

	return {
		...report,
		data: report?.data ?? ([] as OrganizationsDTO[])
	};
};

export const useGetCompanies = () => {
	return useMutation((body: GetCompaniesRequest) => minutesDetailsApiService.getCompanies(body), {
		onError: (err: any) => {
			notification.error({
				message: `${I18n.get('Ops... something happened!')}`,
				description: `${I18n.get(err.response.data.message)}`
			});
		}
	});
};

export const useGetMinutesDetails = () => {
	return useMutation((body: MinutesDetailsRequest) => minutesDetailsApiService.getMinutesDetails(body), {
		onError: () => {
			notification.error({
				message: `${I18n.get('Ops... something happened!')}`,
				description: `${I18n.get('Error in searching for user information')}`
			});
		}
	});
};
