import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { customReportService } from './services';
import { CreateCustomReportStepRequest, GetCustomReportStepsRequest } from './types/request';

export const useGetCustomReportSteps = ({
	limit = 4,
	company_id,
	description,
	organization_id,
	custom_report_id
}: GetCustomReportStepsRequest) => {
	const enabled = !!organization_id && !!company_id;
	const customReportSteps = useInfiniteQuery(
		['custom_report_steps', [organization_id, company_id, description, limit, custom_report_id]],
		async ({ pageParam = 0 }) =>
			customReportService.fetchCustomReportSteps({
				limit,
				company_id,
				description,
				organization_id,
				custom_report_id,
				offset: pageParam ?? 0
			}),
		{
			enabled,
			retry: 0,
			getNextPageParam: (lastPage) => {
				return lastPage.next_page;
			}
		}
	);
	return customReportSteps;
};

export const useCreateStep = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateCustomReportStepRequest) => customReportService.createStep(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(err?.response?.data?.message)
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['custom_report_steps']);
			message.success(I18n.get('Section created successfully'));
		}
	});
};
