import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined } from '@ant-design/icons';

import { ModalStyled, FooterRow, RoundButton } from './styles';
import { useContractPlansContext } from '../context';
import { Title, Text } from '@/components/Typography';
import { useDeleteOrganization, useDeleteCompany } from '../hooks/index';

type ModalDeleteProps = {
	open: boolean;
	onCancel: () => void;
};

export function ModalDelete({ open, onCancel }: ModalDeleteProps) {
	const { queryUrl } = useContractPlansContext();
	const deleteOrganization = useDeleteOrganization();
	const deleteCompany = useDeleteCompany();

	async function handleDelete() {
		queryUrl.get('type') === 'organization' &&
			deleteOrganization.mutateAsync({ organization_id: queryUrl.get('organization_id') || null });
		queryUrl.get('type') === 'company' &&
			deleteCompany.mutateAsync({
				organization_id: queryUrl.get('organization_id') || null,
				company_id: queryUrl.get('company_id') || null
			});
		onCancel();
	}

	return (
		<ModalStyled width={480} footer={null} open={open} onCancel={onCancel} centered>
			<>
				<Row justify="center">
					<DeleteOutlined
						style={{
							color: '#E74150',
							fontSize: 70
						}}
					/>
				</Row>
				<Row justify="center">
					<Col span={20} style={{ marginTop: 10 }}>
						<Title className="paragraph-modal" style={{ fontSize: '24px', textAlign: 'center' }}>
							{queryUrl?.get('type') === 'organization'
								? I18n.get('Delete company')
								: I18n.get('Delete industrial site')}
						</Title>
					</Col>
				</Row>
				<Row justify="center">
					<Col span={20} style={{ marginTop: 10, textAlign: 'center' }}>
						<Text style={{ fontSize: '18px', width: '100%' }}>
							{I18n.get('Do you really want to delete')}{' '}
							{queryUrl?.get('type') === 'organization'
								? I18n.get('the company')
								: I18n.get('the industrial site')}{' '}
							"{queryUrl?.get('name')}"?
						</Text>
					</Col>
				</Row>
				<FooterRow>
					<Col style={{ marginTop: 10, marginRight: 20 }}>
						<RoundButton block key="back" onClick={onCancel} style={{ marginTop: 10 }}>
							{I18n.get('Cancel')}
						</RoundButton>
					</Col>
					<Col style={{ marginTop: 10 }}>
						<RoundButton block type="primary" onClick={() => handleDelete()} style={{ marginTop: 10 }}>
							{I18n.get('Yes')}
						</RoundButton>
					</Col>
				</FooterRow>
			</>
		</ModalStyled>
	);
}
