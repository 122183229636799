import { Row } from 'antd';
import React from 'react';

import { Background } from '@/components/ui/Background';

import { ResetPasswordContent } from './ResetPasswordContent';

export const ResetPassword: React.FC = () => {
	return (
		<>
			<Background />
			<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
				<ResetPasswordContent />
			</Row>
		</>
	);
};
