import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Spinner } from './Spinner';
import { Title } from '@/components/Typography';
import { Risk, ScoreContainer, AllInfosContainer } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

type RiskLevelResultMapper = {
	[key: number]: string;
};

const colors = ['#B3B3B3', '#2CC852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];
const backgrounds = ['#B3B3B333', '#2CC85233', '#FFDE3133', '#F78A3833', '#E7415033', '#9B54E233'];

const riskLevels: RiskLevelResultMapper = {
	0: 'Not analysed',
	1: 'Acceptable risk',
	2: 'Moderate risk',
	3: 'High Risk',
	4: 'Very high risk',
	5: 'Serious and imminent risk'
};

export const Score: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;

	if (!basic_information) {
		return null;
	}

	const normalize = basic_information.result ?? 0;
	const color = colors[normalize];
	const background = backgrounds[normalize];
	const nullScore = basic_information.worst_score;
	const riskLevel = riskLevels[normalize];
	const sum = basic_information.sum_score > 0 ? basic_information.sum_score : '-';

	return (
		<>
			<Col xl={5} xxl={4}>
				<Row>
					<Col span={12}>
						<Title level={3}>{I18n.get('Score')}</Title>
					</Col>
				</Row>
				<Row align="middle" style={{ height: '224px' }}>
					<Col span={20}>
						<Row justify="center" gutter={[0, 10]}>
							<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
								<Title level={4}>{I18n.get("Score's sum")}:</Title>
							</Col>
							<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
								<ScoreContainer>
									<span>{sum}</span>
								</ScoreContainer>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col xl={6} xxl={7}>
				<Row>
					<Col span={24} style={{ textAlign: 'start' }}>
						<Title level={3}>{I18n.get('Worst score')}</Title>
					</Col>
				</Row>
				<Row justify="center">
					<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
						<Risk color={color}>{I18n.get(riskLevel)}</Risk>
					</Col>
					<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
						<AllInfosContainer>
							<Spinner level={normalize} />
						</AllInfosContainer>
					</Col>
					{!!nullScore && (
						<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
							<ScoreContainer color={color} backgroundColor={background}>
								<span>{nullScore}</span>
							</ScoreContainer>
						</Col>
					)}
				</Row>
			</Col>
		</>
	);
};
