import React from 'react';
import { Button, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import { onChangeProjectSelected } from '@/redux/organization/actions';
import { useApplicationContext } from '@/context/Application';

export const ProjectSelect: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { clearProject } = useApplicationContext();
	const organization = useSelector((state: RootStateOrAny) => state.organization);

	function handleOnClick(): void {
		history.push('/');
		dispatch(onChangeProjectSelected());
		clearProject();
	}

	const DealingProject = () => {
		if (organization?.organization?.name && organization?.company?.name) {
			return `${organization?.organization?.name} / ${organization?.company?.name}`;
		}

		if (organization?.organization?.name) {
			return `${organization?.organization?.name}`;
		}

		return I18n.get('Select project');
	};

	return (
		<Tooltip title={I18n.get('Select company')} overlayStyle={{ position: 'fixed' }}>
			<Button type="primary" onClick={handleOnClick} ghost>
				<DealingProject />
				<SyncOutlined style={{ paddingLeft: '5px' }} />
			</Button>
		</Tooltip>
	);
};
