import { useMutation } from '@tanstack/react-query';
import { AuthService } from '../../services/authService';
import Api from '@/services/api';

const Service = new AuthService();

export function useSignIn() {
	return useMutation(Service.signIn, {
		onSuccess: ({ token }) => {
			Api.defaults.headers.Authorization = `Bearer ${token}`;
		}
	});
}
