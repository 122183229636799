import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Form, message } from 'antd';

import { useContractPlansContext } from '../../context';
import { ProcessingControl } from './Steps/ProcessingControl';
import type { Context, Methods, States } from './types';
import { Informations } from './Steps/Informations';
import { Companies } from './Steps/Companies';
import type { Steps } from '../../types';
import {
	useUpdateOrganizationPlan,
	useGetOrganization,
	useUpdateOrganization,
	useCreateOrganizationPlan,
	useCreateOrganization
} from '../../hooks/index';

const { useForm } = Form;

const updateOrganizationContext = createContext<Context>({} as Context);

const steps: Steps = [
	{
		component: <Informations />
	},
	{
		component: <ProcessingControl />
	},
	{
		component: <Companies />
	}
];

interface UpdateOrganizationProviderProps {
	children: ReactNode;
	onCancel(): void;
}

export function UpdateOrganizationProvider({ children, onCancel }: Readonly<UpdateOrganizationProviderProps>) {
	const [form] = useForm();
	const { queryUrl } = useContractPlansContext();

	const organization_id = queryUrl && queryUrl.get('organization_id');

	const {
		mutateAsync: createOrganization,
		isLoading: isCreatingOrganization,
		error: errorCreateOrganization,
		isError: isErrorCreateOrganization
	} = useCreateOrganization();
	const {
		isLoading: isLoadingInformations,
		data,
		error: errorGetorganization,
		isError: isErrorGetorganization
	} = useGetOrganization(organization_id);
	const {
		mutateAsync: updateOrganization,
		isLoading: isUpdatingOrganization,
		error: errorUpdateOrganization,
		isError: isErrorUpdateOrganization
	} = useUpdateOrganization();
	const {
		mutateAsync: updateOrganizationPlan,
		isLoading: isUpdatingOrganizationPlan,
		error: errorUpdateOrganizationPlan,
		isError: isErrorUpdateOrganizationPlan
	} = useUpdateOrganizationPlan();
	const {
		mutateAsync: createOrganizationPlan,
		isLoading: isCreatingOrganizationPlan,
		error: errorCreateOrganizationPlan,
		isError: isErrorCreateOrganizationPlan
	} = useCreateOrganizationPlan();

	const [currentStep, setCurrentStep] = useState<number>(0);

	const is_last_step = currentStep === steps.length - 1;
	const isLoading =
		isUpdatingOrganization ||
		isLoadingInformations ||
		isUpdatingOrganizationPlan ||
		isCreatingOrganizationPlan ||
		isCreatingOrganization;
	const is_error =
		isErrorGetorganization ||
		isErrorUpdateOrganization ||
		isErrorUpdateOrganizationPlan ||
		isErrorCreateOrganizationPlan ||
		isErrorCreateOrganization;
	const error: any =
		errorGetorganization ||
		errorUpdateOrganization ||
		errorUpdateOrganizationPlan ||
		errorCreateOrganizationPlan ||
		errorCreateOrganization;

	useEffect(() => {
		if (data) {
			const fields = [
				['name', data?.organization?.name || data?.company_name || data?.name],
				['address', data?.address],
				['city', data?.city],
				['district', data?.district],
				['plan', data?.contract_plan?.id],
				['nit', data?.cnpj],
				['maximum_time', Boolean(data?.organization?.plan?.max_minutes)],
				['maximum_videos', Boolean(data?.organization?.plan?.max_upload)],
				['recurrence', data?.organization?.plan?.recurrence],
				[
					'renewal_day',
					data?.organization?.plan?.expiration_plan
						? moment.utc(data?.organization?.plan?.expiration_plan).date()
						: null
				],
				[
					'renewal_month',
					data?.organization?.plan?.expiration_plan && data?.organization?.plan?.recurrence === 'yearly'
						? moment.utc(data?.organization?.plan?.expiration_plan).month() + 1
						: null
				],
				[
					'max_minutes',
					data?.organization?.plan?.max_minutes ? Number(data?.organization?.plan?.max_minutes) / 60 : ''
				],
				['max_uploads', data?.organization?.plan?.max_upload],
				['company_recurrence', data?.organization?.plan?.recurrence],
				['company_renewal_day', moment.utc(data?.organization?.plan?.expiration_plan).date()]
			];

			fields.forEach(([field, value]) => form.setFieldValue(field, value));
		}
	}, [data]);

	if (is_error || error) {
		message.error('Oops! Something happened.');
	}

	async function handleUpdateOrganization(): Promise<any> {
		const stepValues = await form.validateFields();

		const payload = {
			organization_id: queryUrl.get('organization_id'),
			nit: stepValues.nit,
			city: stepValues.city,
			address: stepValues.address,
			district: stepValues.district,
			company_name: stepValues.name,
			contract_plan_id: stepValues.plan
		};

		if (!Boolean(data?.organization)) {
			return await createOrganization(payload);
		} else {
			return await updateOrganization(payload);
		}
	}

	async function handleUpdatePlan() {
		const stepValues = form.getFieldsValue();

		const payload = {
			organization_id: queryUrl.get('organization_id'),
			expiration_day: stepValues.renewal_day,
			recurrence: stepValues.recurrence,
			max_minutes: stepValues.max_minutes,
			max_uploads: stepValues.max_uploads,
			expiration_month: stepValues.renewal_month
		};

		if (Boolean(data?.organization?.plan)) {
			return await updateOrganizationPlan(payload);
		} else {
			return await createOrganizationPlan(payload);
		}
	}

	function handlePreviousStep() {
		const changeStep = currentStep - 1;

		if (changeStep < 0) {
			return onCancel();
		}

		setCurrentStep(changeStep);
	}

	async function handleNextStep() {
		await form.validateFields();

		if (currentStep === 0) {
			await handleUpdateOrganization();
		}

		if (currentStep === 1) {
			const updatedPlan = await handleUpdatePlan();
			form.setFieldValue('company_recurrence', updatedPlan.recurrence);
			form.setFieldValue('company_renewal_day', moment(updatedPlan.expiration_plan).date() + 1);
		}

		if (is_last_step) {
			return onCancel();
		}

		setCurrentStep(currentStep + 1);
	}

	function handleChangeStep(step: number): void {
		setCurrentStep(step);
	}

	function clearFieldErrors(_changedValues: any, allValues: any) {
		const updatedFields = Object.keys(allValues)
			.filter((name) => form.getFieldError(name).length)
			.map((name) => ({ name, errors: [] }));
		form.setFields(updatedFields);
	}

	const states: States = {
		steps,
		isLoading,
		currentStep
	};

	const methods: Methods = {
		onNextStep: handleNextStep,
		onStepChange: handleChangeStep,
		onPreviousStep: handlePreviousStep
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<updateOrganizationContext.Provider value={context}>
			<Form onValuesChange={clearFieldErrors} form={form}>
				{children}
			</Form>
		</updateOrganizationContext.Provider>
	);
}

export function useUpdateOrganizationContext() {
	const context = useContext(updateOrganizationContext);
	return context;
}
