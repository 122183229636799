import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';

interface GetUsersDTO {
	organization_id?: string;
	company_id?: string;
}

export type OrganizationUser = {
	id: string;
	name: string;
	role: string;
};

async function getOrganizationUsers({ organization_id, company_id }: GetUsersDTO): Promise<OrganizationUser[]> {
	const url = organization_id
		? `/contract-plan/organization/${organization_id}/users`
		: `/contract-plan/company/${company_id}/users?organization_id=${organization_id}`;

	const { data } = await Api.get(url);
	return data;
}

export const useGetOrganizationUsers = (params: GetUsersDTO) => {
	return useQuery([QUERY_KEYS.GET_ORGANIZATION_USERS_CONTRACT_PLANS, params], () => getOrganizationUsers(params), {
		enabled: !!params.organization_id || !!params.company_id
	});
};
