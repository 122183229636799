import React from 'react';

interface DownloadVideoSVGProps extends React.HTMLProps<HTMLSpanElement> {
	margin?: string;
	disabled?: boolean;
}

export function DownloadVideoSVG({
	height,
	width,
	margin = '0',
	disabled = false,
	...props
}: Readonly<DownloadVideoSVGProps>) {
	return (
		<span {...props} style={{ display: 'flex', margin: margin, ...props.style }} >
			<svg width={width} height={height} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M23.077 27H3.498c-.208-.063-.423-.111-.623-.194-.996-.41-1.55-1.294-1.55-2.463-.002-4.068 0-8.136-.004-12.205 0-.164-.025-.333-.072-.49C.856 10.338.454 9.03.058 7.72c-.174-.574.047-1 .605-1.17L19.473.79C20.306.534 21.139.264 21.97 0h.421c.376.16.557.46.67.845.381 1.305.786 2.603 1.181 3.904.222.731.035 1.095-.687 1.317L8.235 10.761c-.091.028-.181.061-.272.091l.016.046h16.209c.734 0 1.031.302 1.031 1.045v4.086c0 2.777.003 5.554 0 8.331 0 1.215-.665 2.165-1.746 2.52-.13.043-.264.08-.395.119l-.001.001zM3.007 12.598v11.695c0 .731.29 1.02 1.017 1.02h18.5c.708 0 1.011-.3 1.011-1.006V12.89v-.292H3.007zm8.813-4.7c-.027-.043-.036-.07-.054-.084-.836-.668-1.67-1.339-2.513-1.996-.071-.055-.216-.06-.309-.031-.865.255-1.725.522-2.586.787l-1.33.408c.903.72 1.765 1.408 2.634 2.088.058.046.183.041.264.018.571-.167 1.14-.344 1.708-.519l2.187-.67h-.001zm-.723-2.777c.903.72 1.757 1.404 2.619 2.078.064.05.197.051.283.025.846-.25 1.69-.512 2.535-.77l1.354-.416c-.032-.049-.042-.075-.06-.09-.836-.667-1.67-1.337-2.512-1.994-.07-.054-.213-.055-.307-.028-.471.132-.936.285-1.405.428-.818.25-1.636.502-2.506.767zM21.614 1.9c-1.478.453-2.935.9-4.409 1.35.038.052.049.077.068.092.836.667 1.671 1.337 2.513 1.997.06.047.18.056.259.035.328-.089.653-.196.98-.297l1.428-.437-.838-2.74zM5.75 9.758c-.01-.03-.012-.042-.017-.047-.855-.686-1.71-1.371-2.568-2.054-.043-.034-.117-.066-.164-.053-.366.106-.729.222-1.114.344l.835 2.738 3.028-.927z"
					fill={disabled ? '#AAAAAA' : 'currentColor'}
				/>
				<path
					d="M9.83 18.944c0-.949-.002-1.898 0-2.846.002-.854.652-1.232 1.39-.807 1.639.946 3.277 1.893 4.914 2.841.77.447.767 1.187-.006 1.634-1.637.949-3.277 1.896-4.915 2.842-.725.419-1.379.045-1.383-.793-.004-.958 0-1.915 0-2.873v.002zm1.705-1.524v3.056c.888-.515 1.745-1.01 2.638-1.53l-2.638-1.526z"
					fill={disabled ? '#AAAAAA' : 'currentColor'}
				/>
			</svg>
		</span>
	);
}
