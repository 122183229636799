import React from 'react';

import * as S from './styles';
import { useUpdateOrganizationContext } from '../context';

export function Content() {
	const { steps, currentStep } = useUpdateOrganizationContext();

	return <S.Container span={24}>{steps[currentStep]?.component}</S.Container>;
}
