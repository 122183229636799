import React, { useCallback, useLayoutEffect, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Layout, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import Api from '@/services/api';
import { Logo } from './Logo';
import { Sider } from './Sider';
import { Header } from './Header';
import { Content } from './Content';
import { MenuOptions } from './Menu';
import { setPlanDetails } from '@/redux/plan/actions';
import { useApplicationContext } from '@/context/Application';

interface ContainerProps {
	children: () => JSX.Element;
}

export function Container({ children }: ContainerProps) {
	const dispatch = useDispatch();
	const { organization, company } = useApplicationContext();

	const hasPlan = useSelector((state: any) => state.plan.has_plan);
	const isExpired = useSelector((state: any) => state.plan.expired);
	const hasError = useSelector((state: any) => state.plan.has_error);
	const isCanceled = useSelector((state: any) => state.plan.canceled);

	const getPlan = useCallback(() => {
		if (organization?.id && company?.id) {
			Api.get('/plan/customer/details', {
				params: { organization_id: organization?.id, company_id: company?.id }
			})
				.then((res) => {
					dispatch(setPlanDetails(res.data));
				})
				.catch((err) => {
					dispatch(setPlanDetails(err.response.data));
				});
		}
	}, [organization?.id, company?.id, dispatch]);

	const alertAboutPlan = useCallback(() => {
		if (hasError) {
			const message = I18n.get('Oops... Something went wrong!');
			notification.warning({ message });
			return;
		}

		if (!hasPlan) {
			const message = I18n.get('Plan not found');
			notification.warning({ message });
			return;
		}

		if (isCanceled) {
			const message = I18n.get('Your plan has been canceled');
			const description = I18n.get('Upgrade your plan and continue using one platform');
			notification.error({ message, description });
		}

		if (isExpired) {
			const message = I18n.get('Your plan has expired');
			const description = I18n.get('Renew it to continue using');
			notification.warning({ message, description });
		}
	}, [isExpired, isCanceled, hasError, hasPlan]);

	useLayoutEffect(() => {
		getPlan();
	}, [getPlan]);

	useEffect(() => {
		alertAboutPlan();
	}, [alertAboutPlan]);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider>
				<Logo />
				<MenuOptions />
			</Sider>
			<Layout>
				<Header />
				<Content>{children}</Content>
			</Layout>
		</Layout>
	);
}
