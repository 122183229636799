import React from 'react';
import { Col, Form, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomItemSwitch } from './styles';

const { useFormInstance, useWatch } = Form;

export const RequireEvaluator: React.FC = () => {
	const form = useFormInstance();
	const evaluator = useWatch('has_evaluator', form);

	return (
		<Col xs={24}>
			<CustomItemSwitch name={['has_evaluator']} label={I18n.get('Require evaluator')}>
				<Switch checked={evaluator} />
			</CustomItemSwitch>
		</Col>
	);
};
