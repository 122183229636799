import React from 'react';
import { Row, Col } from 'antd';

import * as S from './styles';
import { URL_LOGO } from '@/utils/constants';
import PlayIcon from '@/assets/svg/PlayVideo';
import type { FilesListResponse } from '@/hooks';
import { useGetThumbnailURL } from '@/hooks/useGetThumbnailURL';

interface ThumbnailProps {
	file: FilesListResponse;
	onShowVideoModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export function Thumbnail({ file, onShowVideoModal }: Readonly<ThumbnailProps>) {
	const { data: thumbnail } = useGetThumbnailURL({
		organization_id: file.organization!.id,
		company_id: file.company!.id,
		file_id: file!.id
	});

	return (
		<Col lg={6} xxl={4} style={{ marginRight: 20 }}>
			<Row justify="center" style={{ height: '100%' }}>
				<S.ImageContainer onClick={onShowVideoModal}>
					<Row style={{ width: '100%', position: 'relative' }}>
						<Col span={24}>
							<S.CustomImage preview={false} src={thumbnail ?? URL_LOGO} />
						</Col>
						<S.IconWrapper span={24}>
							<PlayIcon />
						</S.IconWrapper>
					</Row>
				</S.ImageContainer>
			</Row>
		</Col>
	);
}
