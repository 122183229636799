import { useMutation } from '@tanstack/react-query';

import Api from '@/services/api';
import { AxiosErrorResponse, BaseContext } from '@/types';

type DownloadVideoDTO = BaseContext & {
	file_id?: string;
	original_name?: string;
};

async function downloadVideo(payload: DownloadVideoDTO): Promise<string> {
	const url = '/upload/create-url-download';
	const { data } = await Api.post<string>(url, payload);
	return data;
}

export const useDownloadVideo = () => {
	return useMutation<string, AxiosErrorResponse, DownloadVideoDTO>((payload) => downloadVideo(payload), {
		onSuccess: (data) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
