import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { customReportService } from '../../../../hooks/services';
import { CustomReportResultWorkCondition, Response } from '@/types';
import { CreateWorkConditionResultDTO } from './useCreateWorkConditionResult';

export type UpdateWorkConditionResultDTO = CreateWorkConditionResultDTO & {
	work_condition_id?: string;
};

export type PersonalisedReportWorkConditionResultUpdateResponse = CustomReportResultWorkCondition;

async function updateWorkConditionResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/work-condition';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useUpdateWorkConditionResult() {
	const queryClient = useQueryClient();
	return useMutation<
		PersonalisedReportWorkConditionResultUpdateResponse,
		AxiosError<Response>,
		UpdateWorkConditionResultDTO
	>(
		(body) =>
			updateWorkConditionResult<
				PersonalisedReportWorkConditionResultUpdateResponse,
				UpdateWorkConditionResultDTO
			>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
}
