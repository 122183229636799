import styled from 'styled-components';

export const Description = styled.span`
	color: #b8b8b8;
	font-weight: 600;
	font-size: 0.9rem;
`;

export const Score = styled.span`
	font-weight: 600;
	font-size: 0.75rem;
`;
