import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { SuperPEAService } from './services';
import {
	CompanyDTO,
	DeleteSuperPEARequest,
	OrganizationDTO,
	SectorDTO,
	SuperPEAListDTO,
	SuperPEAQueryParams
} from './types';

const superPEAService = new SuperPEAService();

function useDownloadPDF() {
	return useMutation((payload: any) => superPEAService.generateUrlDownload(payload));
}

function useGetSuperPEAList(queryParams: SuperPEAQueryParams) {
	const list = useQuery<SuperPEAListDTO[]>(['super_pea_list', queryParams], () =>
		superPEAService.getPEAList(queryParams)
	);
	return {
		...list,
		data: list.data || []
	};
}

function useGetOrganization() {
	const organizations = useQuery<OrganizationDTO[]>(['organizations_list'], () => superPEAService.getOrganizations());
	return {
		...organizations,
		data: organizations.data ?? []
	};
}

function useGetCompanies(organizationId: string) {
	const companies = useQuery<CompanyDTO[]>(['companies_list'], () => superPEAService.getCompanies(organizationId));
	return {
		...companies,
		data: companies.data ?? []
	};
}

function useGetSectors(organizationId: string, companyId: string) {
	const sectors = useQuery<SectorDTO[]>({
		queryKey: ['sectors_list'],
		queryFn: () => superPEAService.getSectors(organizationId, companyId),
		retry: 0
	});

	return {
		...sectors,
		data: sectors.data ?? []
	};
}

function useDeleteSuperPEA() {
	const queryClient = useQueryClient();
	return useMutation(
		({ companyId, organizationId, superPEAId }: DeleteSuperPEARequest) =>
			superPEAService.deleteSuperPEA(superPEAId, organizationId, companyId),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['super_pea_list']);
				message.success(I18n.get('EWA deleted successfully'));
			}
		}
	);
}

const Hooks = {
	useDownloadPDF,
	useGetSuperPEAList,
	useGetOrganization,
	useGetCompanies,
	useGetSectors,
	useDeleteSuperPEA
};

export default Hooks;
