import styled from 'styled-components';

export const ClearFilter = styled.h4`
	font-size: 1rem;
	font-weight: 400;
	color: #1890ff;

	transition: filter 0.2s;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
		filter: brightness(0.8);
	}
`;
