import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { Activity } from '@/types/entities';
import { QUERY_KEYS } from '@/utils/query-keys';
import { CreateActivityRequest } from '@/components/ui/Inputs/types/request/activity';

export const useCreateActivity = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateActivityRequest) => Api.post<Activity>('/activity', body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTIVITIES]);
			message.success(I18n.get('Activity created successfully'));
		}
	});
};
