import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { customReportService } from '../../../../hooks/services';
import { CustomReportResultCharacteristic, Response } from '@/types';
import { CreateCharacteristicResultDTO } from './useCreateCharacteristicResult';

export type PersonalisedReportCharacteristicResultUpdateResponse = CustomReportResultCharacteristic;

export type UpdateCharacteristicResultDTO = CreateCharacteristicResultDTO & {
	characteristic_id?: string;
};

async function updateCharacteristicResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/characteristic';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useUpdateCharacteristicResult() {
	const queryClient = useQueryClient();
	return useMutation<
		PersonalisedReportCharacteristicResultUpdateResponse,
		AxiosError<Response>,
		UpdateCharacteristicResultDTO
	>(
		(body) =>
			updateCharacteristicResult<
				PersonalisedReportCharacteristicResultUpdateResponse,
				UpdateCharacteristicResultDTO
			>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
};
