import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
	Reba,
	Niosh,
	Injury,
	Response,
	AngleTime,
	KimPushPull,
	StrainIndex,
	ScoreScales,
	CustomReport,
	LibertyMutual,
	CustomReportStep,
	KimManualHandling,
	CustomReportResult,
	CustomReportSetting,
	CustomReportStepKey,
	CustomReportSubStepKey,
	CustomReportStepSetting,
	CustomReportStepKeySetting,
	CustomReportResultWorkCondition,
	CustomReportResultCharacteristic
} from '@/types';
import Api from '@/services/api';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { ErgonomicToolResponse } from '../context/types';

export type GetReportDTO = BaseContext & {
	file_id?: string;
	id?: string;
	report_id: string;
	current_sequence: number | null | string;
};

export type NioshResponse = Pick<Niosh, 'id' | 'file_id' | 'lifting_index' | 'updatedAt'>;

export type StrainIndexResponse = Pick<
	StrainIndex,
	'id' | 'file_id' | 'updatedAt' | 'score_left_rsi' | 'score_right_rsi'
>;

export type KimMhoResponse = Pick<KimManualHandling, 'id' | 'file_id' | 'risk_load' | 'updatedAt' | 'risk_score'>;

export type KimPpResponse = Pick<KimPushPull, 'id' | 'file_id' | 'mass' | 'score' | 'updatedAt'>;

export type RebaBasicResponse = Pick<Reba, 'id' | 'file_id' | 'force' | 'coupling' | 'repetition'>;
export type RebaBodyPartScores = {
	id: number;
	text: string;
	level: number;
	percentage: number;
};
export type RebaData = {
	id: number;
	name: string;
	scores: RebaBodyPartScores[];
};
export type RebaDataResponse = RebaData[];
export type RebaResponse = RebaBasicResponse & {
	data: RebaDataResponse[];
};

export type AngleTimeResponse = Pick<AngleTime, 'id' | 'file_id' | 'range_risk_id'>;

export type LibertyMutualResponse = Pick<
	LibertyMutual,
	| 'id'
	| 'file_id'
	| 'percentile_man'
	| 'percentile_woman'
	| 'percentile_man_initial'
	| 'percentile_man_sustain'
	| 'percentile_woman_initial'
	| 'percentile_woman_sustain'
>;

export type InformationsResponse = {
	rula: null;
	file_id?: string;
	created_at?: Date;
	sector_id?: string;
	role_name?: string;
	company_id: string;
	sector_name?: string;
	evaluator_id?: string;
	report_name?: string;
	company_name: string;
	activity_name?: string;
	activity_id?: string;
	cellule_id?: string;
	evaluator_name?: string;
	workstation_id?: string;
	organization_id: string;
	collection_date?: Date;
	workstation_name?: string;
	organization_name: string;
	file_uploaded_date?: Date;
	reba: RebaResponse | null;
	file_original_name?: string;
	niosh: NioshResponse | null;
	kim_pp: KimPpResponse | null;
	kim_mho: KimMhoResponse | null;
	angle_time: AngleTimeResponse | null;
	strain_index: StrainIndexResponse | null;
	liberty_mutual: LibertyMutualResponse | null;
};

export type CharacteristicsResponse = Pick<
	CustomReportResultCharacteristic,
	| 'id'
	| 'working_population_men'
	| 'working_population_women'
	| 'total_working_population'
	| 'working_population_others'
	| 'worker_self_evaluation_id'
	| 'particularities_description'
	| 'worker_verbalization_description'
>;

export type WorkConditionBasicResponse = Pick<
	CustomReportResultWorkCondition,
	'id' | 'place_description' | 'custom_report_result_id' | 'expected_task_description' | 'performed_task_description'
>;

export type WorkConditionResponse = WorkConditionBasicResponse & {
	work_hours: number;
	work_minutes: number;
};

export type CustomReportResponse = Pick<CustomReport, 'id' | 'name' | 'description'>;
export type CustomReportStepSettingResponse = Pick<CustomReportStepSetting, 'sequence'> & {
	custom_report_step_setting_id: string;
};
export type CustomReportStepResponse = Pick<CustomReportStep, 'id' | 'name' | 'description'>;
export type CustomReportStepKeySettingResponse = Pick<CustomReportStepKeySetting, 'sequence'> & {
	injuries: string[];
	ergonomic_tools: ErgonomicToolResponse[];
	custom_report_step_key_setting_id: string;
};
export type CustomReportSettingsResponse = Pick<CustomReportSetting, 'has_evaluator' | 'has_file'> & {
	custom_report_setting_id: string;
};
export type CustomReportStepKeyResponse = Pick<CustomReportStepKey, 'id' | 'name' | 'description' | 'has_injury'>;
export type CustomReportSubStepKeyBasicResponse = Pick<
	CustomReportSubStepKey,
	'id' | 'name' | 'title' | 'description' | 'sequence'
>;
export type InjuryBasicResponse = Pick<Injury, 'id' | 'name' | 'description'>;
export type CustomReportSubStepKeyResponse = CustomReportSubStepKeyBasicResponse & {
	sub_step_key_result_id?: string;
	sub_step_key_injuries: InjuryBasicResponse[];
};

export type PersonalisedReportStepKeysResponse = CustomReportStepKeyResponse &
	CustomReportStepKeySettingResponse & {
		checked: boolean;
		step_key_result_id?: string;
		sub_step_keys?: CustomReportSubStepKeyResponse[];
	};
export type PersonalisedReportStepsResponse = CustomReportStepResponse &
	CustomReportStepSettingResponse & {
		step_keys: PersonalisedReportStepKeysResponse[];
	};
export type PersonalisedReportSettingsResponse = CustomReportResponse & CustomReportSettingsResponse;

export type PersonalisedReportResultBasicResponse = Pick<CustomReportResult, 'id' | 'comment'>;

export type PersonalisedReportResponse = PersonalisedReportResultBasicResponse &
	PersonalisedReportSettingsResponse & {
		result_id?: string;
		report_name: string;
		total_steps: number;
		current_step: number;
		is_completed: boolean;
		consolidated?: boolean;
		current_step_name: string;
		score_scale_name: ScoreScales;
		custom_report_score_scale_id: string;
		work_conditions?: WorkConditionResponse;
		basic_information?: InformationsResponse;
		steps: PersonalisedReportStepsResponse[];
		characteristics?: CharacteristicsResponse;
	};

async function getReport<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/fill-out';
	const { data } = await Api.get<T>(url, {
		params: payload
	});
	return data;
}

export function useGetReport({ organization_id, company_id, report_id, file_id, current_sequence, id }: GetReportDTO) {
	const parameters = { organization_id, company_id, report_id, file_id, current_sequence, id };
	const report = useQuery<PersonalisedReportResponse, AxiosError<Response>>(
		['personalised_report', parameters],
		() => getReport<PersonalisedReportResponse, GetReportDTO>(parameters),
		{
			enabled: !!organization_id && !!company_id && !!report_id && (!!file_id || !!id),
			retry: 0
		}
	);
	return {
		...report,
		data: report.data ?? ({} as PersonalisedReportResponse)
	};
}
