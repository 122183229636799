import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';
import { Header } from './Header';
import { OverallScoreParameters, Diagnostics } from '../types';
import { Description, Details, DetailTitle, Score } from './styles';

interface IOverallScoreProps {
	parameters: OverallScoreParameters;
}

export const OverallScore: React.FC<IOverallScoreProps> = ({ parameters: { left, right, risk } }) => (
	<Row>
		<Col span={24}>
			<Header />
		</Col>
		<Divider style={{ margin: 0 }} />
		<Col span={24}>
			<Row>
				<Col span={12} style={{ paddingLeft: '1rem' }}>
					<Details>
						<Row gutter={[0, 20]}>
							<Col span={24}>
								<DetailTitle>{I18n.get('Left')}</DetailTitle>
							</Col>
							<Col span={24}>
								<Score>{left}</Score>
							</Col>
							<Col span={24}>
								<Description>{I18n.get(Diagnostics[risk.left])}</Description>
							</Col>
						</Row>
					</Details>
				</Col>
				<Divider style={{ margin: '0 0 -28px 0', height: 'auto' }} type={'vertical'} />
				<Col span={11} style={{ paddingLeft: '1rem' }}>
					<Details>
						<Row gutter={[0, 20]}>
							<Col span={24}>
								<DetailTitle>{I18n.get('Right')}</DetailTitle>
							</Col>
							<Col span={24}>
								<Score>{right}</Score>
							</Col>
							<Col span={24}>
								<Description>{I18n.get(Diagnostics[risk.right])}</Description>
							</Col>
						</Row>
					</Details>
				</Col>
			</Row>
		</Col>
	</Row>
);
