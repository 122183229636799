import { I18n } from '@aws-amplify/core';
import { Row, Divider } from 'antd';
import React from 'react';

import { Customer } from '@/types/Account';

import { Field, Information, Section, Subsection, TitleSection } from '../styles';

interface AddressProps {
	data: Customer;
}

export const Address: React.FC<AddressProps> = ({ data }) => {
	return (
		<Section>
			<TitleSection>{I18n.get('Address')}</TitleSection>
			<Row>
				<Subsection>
					<Field>{I18n.get('Street name')}</Field>
					<Information>{data.address}</Information>
				</Subsection>
				<Divider style={{ margin: '25px 0' }} />
				<Row style={{ width: '100%' }}>
					<Subsection span={16}>
						<Field>{I18n.get('City')}</Field>
						<Information>{data.city}</Information>
					</Subsection>
					<Subsection span={8}>
						<Field>{I18n.get('State')}</Field>
						<Information>{data.state}</Information>
					</Subsection>
				</Row>
				<Divider style={{ margin: '25px 0' }} />
				<Subsection>
					<Field>{I18n.get('Country')}</Field>
					<Information>{data.country}</Information>
				</Subsection>
			</Row>
		</Section>
	);
};
