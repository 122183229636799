import Api from '@/services/api';
import { Response } from '@/types';
import { BaseContextAepType } from './types';

async function saveComments(payload: BaseContextAepType) {
	const { organizationId, companyId, id, fileId } = payload;
	const body = {
		organization_id: organizationId,
		company_id: companyId,
		data: payload.data,
		file_id: fileId,
		id,
	};
	const { data } = await Api.put<Response>('/ergonomic-tool/preliminary-analysis/save-comments', body);
	return data;
}

export const Services = { saveComments };
