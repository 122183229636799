import Api from '@/services/api';

import { GetCustomReportRequest } from './types/request';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { CustomReportApiServiceInterface } from './servicesInterfaces';
import { CustomReportResponse, CustomReportScoreScaleResponse } from './types/response';

export class CustomReportService implements CustomReportApiServiceInterface {
	async fetchCustomReport(params: GetCustomReportRequest): Promise<CustomReportResponse> {
		const url = '/custom-report/find-one/settings';
		const { data } = await Api.get<CustomReportResponse>(url, {
			params
		});
		return data;
	}

	async fetchCustomReportScoreScale(params: BaseContext): Promise<CustomReportScoreScaleResponse[]> {
		const url = '/custom-report/score-scale/find-all';
		const { data } = await Api.get<CustomReportScoreScaleResponse[]>(url, {
			params
		});
		return data;
	}
}

export const customReportService = new CustomReportService();
