import Api from '@/services/api';

import { CustomReportStepKey } from '@/types';
import { CustomReportStepKeyListResponse, ErgonomicToolsResponse } from './types/response';
import { CustomReportStepKeyApiServiceInterface } from './servicesInterfaces';
import { CreateCustomReportStepKeyRequest, GetCustomReportStepKeysRequest, GetErgonomicTools } from './types/request';

export class CustomReportStepKeyService implements CustomReportStepKeyApiServiceInterface {
	async fetchCustomReportStepKeys(params: GetCustomReportStepKeysRequest): Promise<CustomReportStepKeyListResponse> {
		const url = '/custom-report/step-key/find-all';
		const { data } = await Api.get<CustomReportStepKeyListResponse>(url, {
			params
		});
		return data;
	}

	async fetchErgonomicTools(params: GetErgonomicTools): Promise<ErgonomicToolsResponse[]> {
		const url = '/ergonomic-tool/find-all';
		const { data } = await Api.get<ErgonomicToolsResponse[]>(url, {
			params
		});
		return data;
	}

	async createStepKey(body: CreateCustomReportStepKeyRequest): Promise<CustomReportStepKey> {
		const url = '/custom-report/step-key';
		const { data } = await Api.post<CustomReportStepKey>(url, body);
		return data;
	}
}

export const customReportStepKeyService = new CustomReportStepKeyService();
