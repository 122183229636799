import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Step } from './Step';
import * as S from './styles';
import { Card } from '../../Card';
import { useCustomReportManagerContext } from '../../context';

export const SelectedSteps: React.FC = () => {
	const { selectedSteps } = useCustomReportManagerContext();

	return (
		<S.CustomRow justify="space-between" align="middle">
			<Col xs={24}>
				<Card title="Selected sections">
					<Row align="middle" justify="space-between" gutter={[16, 16]}>
						<S.StepListCol xs={24}>
							<S.StepListContainer>
								{selectedSteps.map(({ id, description }) => (
									<Step key={id} id={id} description={description} />
								))}
								<S.EmptyContainer />
							</S.StepListContainer>
						</S.StepListCol>
						<S.SummaryButtonContainer>
							<S.SummaryButton type="primary">{I18n.get('Summary')}</S.SummaryButton>
						</S.SummaryButtonContainer>
					</Row>
				</Card>
			</Col>
		</S.CustomRow>
	);
};
