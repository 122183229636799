import React from 'react';
import moment from 'moment';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { ActionPlanTask } from './ActionPlanTask';
import { ActionPlanTaskResponse, ActionPlanWithTaskResponse } from '../../../hooks';
import { ActionPlanContent, ActionPlanTitle, ActioPlanContainer, DeadlineContainer, Title } from './styles';

type ActionPlanDataProps = {
	actionPlan: ActionPlanWithTaskResponse;
};

export const ActionPlanData: React.FC<ActionPlanDataProps> = ({ actionPlan }) => {
	function calculateDeadline(fullDate: Date): number {
		return moment(fullDate).diff(moment(), 'days');
	}

	function isPluralDays(fullDate: Date) {
		return calculateDeadline(fullDate) > 1 || calculateDeadline(fullDate) < -1;
	}

	function mountTasksList(tasks?: ActionPlanTaskResponse[]): React.ReactNode {
		if (!tasks || tasks.length === 0) {
			return I18n.get('No task available');
		}

		return tasks.map((task) => <ActionPlanTask key={task.id} task={task} />);
	}

	return (
		<ActioPlanContainer>
			<Col xs={24}>
				<ActionPlanTitle>{I18n.get(actionPlan.title)}</ActionPlanTitle>
			</Col>
			<ActionPlanContent xs={24}>
				<Row align="top">
					<Col xl={10} xxl={11}>
						<Row gutter={[0, 8]}>
							<Col xs={24}>
								<Row>
									<Title>{I18n.get('Person responsible')}</Title>
								</Row>
								<Row>{actionPlan.responsible_user}</Row>
							</Col>
							<Divider style={{ margin: '0.25rem 0' }} />
							<Col xs={24}>
								<Row align="middle">
									<Col xs={12}>
										<Row>
											<Title>{I18n.get('Deadline')}</Title>
										</Row>
										<Row>
											<span>
												{calculateDeadline(actionPlan.deadline.full_date)}{' '}
												{I18n.get(isPluralDays(actionPlan.deadline.full_date) ? 'days' : 'day')}
											</span>
										</Row>
									</Col>
									<Col xs="auto">
										<DeadlineContainer>
											<Col>{actionPlan.deadline.day}</Col>
											<Divider type="vertical" style={{ height: 'auto', margin: '0 0.5rem' }} />
											<Col>{actionPlan.deadline.month}</Col>
											<Divider type="vertical" style={{ height: 'auto', margin: '0 0.5rem' }} />
											<Col>{actionPlan.deadline.year}</Col>
										</DeadlineContainer>
									</Col>
								</Row>
							</Col>
							<Divider style={{ margin: '0.25rem 0' }} />
							<Col xs={24}>
								<Row>
									<Title>{I18n.get('Description')}</Title>
								</Row>
								<Row>{actionPlan.description}</Row>
							</Col>
						</Row>
					</Col>
					<Divider type="vertical" style={{ height: '200px', margin: '0 2rem' }} />
					<Col xs={10}>
						<Row style={{ width: '100%' }} gutter={[0, 32]}>
							<Col xs={24}>
								<Title>{I18n.get('Tasks')}</Title>
							</Col>
							<Col xs={24} style={{ maxHeight: '180px', overflowY: 'auto' }}>
								{mountTasksList(actionPlan.action_plan_task)}
							</Col>
						</Row>
					</Col>
				</Row>
			</ActionPlanContent>
		</ActioPlanContainer>
	);
};
