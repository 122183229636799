import React from 'react';
import { Radio, Skeleton, Space } from 'antd';

export const LoadingSkeleton: React.FC = () => {
	const fakeData = [1, 2, 3, 4, 5];

	return (
		<Radio.Group>
			<Space direction="vertical">
				{fakeData.map((data) => (
					<Skeleton.Input key={data} active />
				))}
			</Space>
		</Radio.Group>
	);
};
