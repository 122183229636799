import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, Select, List, Row, Col, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { SidebarContainer, SideItemTitle, ScrollableContainer } from './styles';
import { useHistory } from '@/hooks/v1/useActionPlans';
import { ActionPlansContext } from '../context';
import { Text } from '@/components/Typography';

export function Sidebar({ actionPlan, responsible_users, reloadHistory, triggerReloadHistory, updateActionPlan }) {
	const [sectorName, setSectorName] = useState(null);
	const [fileName, setFileName] = useState(null);

	const { companies, setSelectedCompanyId, sectors, setSelectedSectorId, files, setSelectedFileId } =
		useContext(ActionPlansContext);

	useEffect(() => {
		setSelectedCompanyId(actionPlan?.company_id);
		setSelectedSectorId(actionPlan?.sector_id);
		setSelectedFileId(actionPlan?.file_id);
	}, []);

	useEffect(() => {
		setSectorName(sectors?.data?.find((elem) => elem.id === actionPlan?.sector_id));
		setFileName(files?.data?.find((elem) => elem.id === actionPlan?.file_id));
	}, [sectors]);

	const organization = useSelector(({ organization }) => organization.organization);

	const { history } = useHistory({
		actionPlanId: actionPlan?.id,
		organizationId: organization?.id
	});

	async function onResponsibleChange(actionPlan, responsible_user_id) {
		const action_plan = { ...actionPlan, responsible_user_id };
		const body = { action_plan, organizationId: organization?.id };

		updateActionPlan({ body }).then(() => {
			triggerReloadHistory();
		});
	}

	async function onDeadlineChange(actionPlan, deadline) {
		const action_plan = { ...actionPlan, deadline };
		const body = { action_plan, organizationId: organization?.id };

		updateActionPlan({ body }).then(() => {
			triggerReloadHistory();
		});
	}

	const truncateText = (str, charactersLimit) => {
		return str.length > charactersLimit ? str.slice(0, charactersLimit - 1) + '...' : str;
	};

	useEffect(() => {
		history.refetch();
	}, [actionPlan, reloadHistory]);

	const style = {
		display: 'block',
		width: '100%',
		textAlign: 'center',
		marginBottom: '5px'
	};
	return (
		<SidebarContainer>
			<Row span={24}>
				<Col span={12}>
					<SideItemTitle>{I18n.get('Company')}</SideItemTitle>
					<Text style={style}>{organization?.name}</Text>
				</Col>
				<Col span={12}>
					<SideItemTitle>{I18n.get('Deadline')}</SideItemTitle>
					<DatePicker
						allowClear={false}
						onChange={(deadline) => onDeadlineChange(actionPlan, deadline)}
						style={{
							width: '70%',
							marginLeft: '40px',
							marginBottom: '10px'
						}}
						format={'DD/MM/YYYY'}
						defaultValue={moment(actionPlan.deadline)}
						placeholder={I18n.get('Date')}
						disabledDate={(current) => current < moment().subtract(1, 'day')}
					/>
				</Col>
			</Row>
			<SideItemTitle style={{ width: '93%' }}>{I18n.get('Person responsible')}</SideItemTitle>
			<Select
				onChange={(person) => onResponsibleChange(actionPlan, person)}
				style={{
					width: '70%',
					marginLeft: '40px',
					marginBottom: '10px'
				}}
				defaultValue={actionPlan.responsible_user_id}
			>
				{responsible_users.data?.map((person, index) => (
					<Select.Option key={index} value={person.id}>
						{person.name}
					</Select.Option>
				))}
			</Select>
			<Row span={24}>
				<Col span={12}>
					<SideItemTitle>{I18n.get('Industrial site')}</SideItemTitle>
					<Text style={style}>{actionPlan.company_name ?? '-'}</Text>
				</Col>
				<Col span={12}>
					<SideItemTitle>{I18n.get('Sector')}</SideItemTitle>
					<Text style={style}>{sectorName?.name}</Text>
				</Col>
			</Row>

			{actionPlan?.analyst_name && (
				<>
					<Row span={24}>
						<Col span={12}>
							<SideItemTitle>{I18n.get('Cellule')}</SideItemTitle>
							<Tooltip title={actionPlan?.cellule_name}>
								<Text ellipsis={true} style={style}>
									{actionPlan?.cellule_name}
								</Text>
							</Tooltip>
						</Col>
						<Col span={12}>
							<SideItemTitle>{I18n.get('Activity')}</SideItemTitle>
							<Tooltip title={actionPlan?.activity_name}>
								<Text ellipsis={true} style={style}>
									{actionPlan?.activity_name}
								</Text>
							</Tooltip>
						</Col>
					</Row>
				</>
			)}
			<Row span={24}>
				<Col span={12}>
					<SideItemTitle>{I18n.get('Workstation')}</SideItemTitle>
					<Text style={style}>{fileName?.workstations?.name}</Text>
				</Col>
				<Col span={12}>
					<SideItemTitle>{I18n.get('File')}</SideItemTitle>
					<Tooltip title={fileName?.original_name}>
						<Text ellipsis={true} style={{ marginLeft: '20px', width: '85%' }}>
							{fileName?.original_name}
						</Text>
					</Tooltip>
				</Col>
			</Row>
			{!history.isLoading && (
				<React.Fragment>
					<SideItemTitle style={{ width: '93%' }}>{I18n.get('History')}</SideItemTitle>
					<ScrollableContainer style={{ maxHeight: '190px' }}>
						<List
							size="small"
							dataSource={history?.data}
							renderItem={(item) => (
								<List.Item extra={moment(item.event_date).format('DD/MM/YY')}>
									<List.Item.Meta
										title={truncateText(
											`${I18n.get(item.event)}: "${I18n.get(
												item.responsible_user?.name ?? item.event_value
											)}"`,
											40
										)}
										description={I18n.get('by ') + item.user.name}
									/>
								</List.Item>
							)}
						></List>
					</ScrollableContainer>
				</React.Fragment>
			)}
		</SidebarContainer>
	);
}
