import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Col, Descriptions, Row } from 'antd';
import { Text } from '@/components/Typography';

import { CustomImageSkeleton } from './styles';
import { useGetDownloadVideoURL } from '@/hooks/v1';
import { useApplicationContext } from '@/context/Application';

const { Item } = Descriptions;

interface VideoContainerProps {
	fileId: string;
	uploadedDate?: Date;
	originalName?: string;
}

export const VideoContainer = ({ fileId, originalName, uploadedDate }: VideoContainerProps) => {
	const { organization, company } = useApplicationContext();

	const {
		data: video,
		isError: errorGettingVideo,
		isInitialLoading: gettingVideo
	} = useGetDownloadVideoURL({
		file_id: fileId,
		company_id: company?.id,
		original_name: originalName,
		organization_id: organization?.id
	});

	function renderVideo() {
		if (gettingVideo) {
			return <CustomImageSkeleton active />;
		}
		if (errorGettingVideo) {
			return <Text>Oops... Something went wrong!</Text>;
		}
		return (
			<Col span={24}>
				<video style={{ borderRadius: '15px', margin: '1rem 0' }} loop width="100%" controls>
					<source src={video} type="video/webm" />
					<track src="" kind="captions" srcLang="en" label="english_captions"></track>
				</video>
			</Col>
		);
	}

	return (
		<Col xs={24} sm={16} xxl={12}>
			<Row justify="center" style={{ marginBottom: '1rem' }}>
				{renderVideo()}
			</Row>
			<Descriptions column={1} size="small">
				<Item label={I18n.get('File')} labelStyle={{ fontWeight: 'bold' }}>
					{originalName}
				</Item>
				<Item label={I18n.get('Date')} labelStyle={{ fontWeight: 'bold' }}>
					{moment(uploadedDate).format('L')}
				</Item>
			</Descriptions>
		</Col>
	);
};
