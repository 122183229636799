import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Table } from './Components/Table';
import { Title } from '@/components/Typography';

export type KimPushPullDTO = {
	id: string;
	note: number;
	mass: number;
	duration: null;
	vehicle: string;
	distance: string;
	risk_range: string;
	measurements: string;
	possible_health_consequences: string;
	probability_of_physical_overload: string;
};

export interface KimPushPullProps {
	data: KimPushPullDTO;
}

export function KimPushPull({ data }: KimPushPullProps) {
	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Title style={{ margin: 0 }} level={3}>
					{I18n.get('Push and Pull (KIM)')}
				</Title>
			</Col>
			<Col offset={1} span={23}>
				<Table data={data} />
			</Col>
		</Row>
	);
}
