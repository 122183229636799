import React from 'react';
import { Col, Row, Skeleton } from 'antd';

import { CardContainer } from './Card/styles';

export const LoadingSkeleton: React.FC = () => {
	const fakeData = [1, 2, 3, 4];

	return (
		<Row gutter={[32, 32]}>
			{fakeData.map((id) => (
				<Col key={id} xs={24} md={12} lg={10} xl={8} xxl={5}>
					<CardContainer>
						<Row justify="center">
							<Skeleton.Image active />
						</Row>
						<Skeleton active />
					</CardContainer>
				</Col>
			))}
		</Row>
	);
};
