import React from 'react';
import { Checkbox, Space } from 'antd';
import { I18n } from '@aws-amplify/core';

import { injuriesMap } from '../../contants';

export const InjuriesGroup = ({ options = [], ...props }) => {
	const possibleInjuries = injuriesMap
		.filter((item) => options.includes(item.key))
		.sort(({ key: r }, { key: t }) => options.indexOf(r) - options.indexOf(t));

	return (
		<Checkbox.Group {...props}>
			<Space direction="vertical">
				{possibleInjuries.map((item, index) => (
					<Checkbox key={index} value={item.key}>
						{I18n.get(item.value)}
					</Checkbox>
				))}
			</Space>
		</Checkbox.Group>
	);
};
