import React, { useContext, useEffect } from 'react';
import { Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import Hooks from '../../hooks';
import { MergeAEPContext } from '../../context';

const { useGetOrganization } = Hooks;
const { useFormInstance } = Form;

export function Organization() {
	const { organizationId } = useContext(MergeAEPContext);

	const { data: organizationsList, isRefetching } = useGetOrganization({
		name: '',
		id: organizationId
	});

	const { setFieldValue } = useFormInstance();

	useEffect(() => {
		if (organizationId) {
			setFieldValue('organizationId', organizationsList.find(({ id }) => id === organizationId)?.name);
		}
	}, [organizationId, isRefetching, organizationsList, setFieldValue]);

	return (
		<Form.Item name="organizationId">
			<Select showSearch filterOption={false} disabled showArrow={false} placeholder={I18n.get('Company')} />
		</Form.Item>
	);
}
