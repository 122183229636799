import React from 'react';
import { Row, Col, ColProps } from 'antd';
import * as S from './styles';

interface CellProps {
	children: JSX.Element;
}

interface TitleProps {
	children: JSX.Element[];
}

interface ValueProps {
	children: any;
	isBold?: boolean;
}

export const Title: React.FC<TitleProps> = ({ children }) => (
	<Col span={24}>
		<S.Title>{children}</S.Title>
	</Col>
);

export const Value: React.FC<ValueProps> = ({ children, isBold = false }) => (
	<Col span={24}>
		<S.Value isBold={isBold}>{children}</S.Value>
	</Col>
);

export const Cell: React.FC<CellProps & ColProps> = ({ children, ...props }) => (
	<S.Container {...props}>
		<Row>{children}</Row>
	</S.Container>
);
