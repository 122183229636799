import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetStrainIndexDTO {
	organization_id: string;
	company_id: string;
	id: string;
}

type GetStrainIndexError = AxiosError<any>;

export type GetStrainIndexResponse = {
	id: string;
	report_name: string;
	input_left_borg_scale: number;
	input_left_exertions: number;
	input_left_observation_time: number;
	input_left_exertion_duration: number;
	input_left_wrist_posture: string;
	input_left_wrist_angle: number;
	input_left_daily_duration: number;
	score_left_borg_scale: number;
	score_left_efforts_per_minute: number;
	score_left_exertion_duration: number;
	score_left_wrist_posture: number;
	score_left_daily_duration: number;
	score_left_rsi: number;
	left_risk: string;
	input_right_borg_scale: number;
	input_right_exertions: number;
	input_right_observation_time: number;
	input_right_exertion_duration: number;
	input_right_wrist_posture: string;
	input_right_wrist_angle: number;
	input_right_daily_duration: number;
	score_right_borg_scale: number;
	score_right_efforts_per_minute: number;
	score_right_exertion_duration: number;
	score_right_wrist_posture: number;
	score_right_daily_duration: number;
	score_right_rsi: number;
	right_risk: string;
	collection_date: Date;
	comment: null;
	isActive: boolean;
	createdAt: Date;
	updatedAt: Date;
	file_id?: string;
	cellule_id: string;
	workstation_id: string;
	activity_id: string;
	evaluator_id: string;
	report_user_id: string;
	report_user: ReportUser;
	evaluator: Cellule;
	activity: Activity;
	cellule: Cellule;
	workstation: Workstation;
	current_step: number;
}

type Activity = {
	id: string;
	name: string;
	workstation_id: string;
}

type Cellule = {
	id: string;
	name: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	sector_id?: string;
	company_id?: string;
}

type ReportUser = {
	id: string;
	name: string;
}

type Workstation = {
	id: string;
	name: string;
	sector_id?: string;
	sectors: Sectors;
}

type Sectors = {
	name: string;
	id: string;
	company: Company;
}

type Company = {
	id: string;
	name: string;
	Organization: Organization;
}

type Organization = {
	name: string;
	id: string;
}


export async function getStrainIndex(payload: GetStrainIndexDTO) {
	const { organization_id, company_id, id } = payload;
	const url = `/ergonomic-tool/strain-index/${id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetStrainIndex = ({ organization_id, company_id, id }: GetStrainIndexDTO) => {
	const payload = { organization_id, company_id, id };
	return useQuery<GetStrainIndexResponse, GetStrainIndexError>(
		[QUERY_KEYS.GET_STRAIN_INDEX_REPORT, payload],
		() => getStrainIndex(payload),
		{
			enabled: !!organization_id && !!company_id && !!id
		}
	);
};
