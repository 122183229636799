import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormInstance } from 'antd';
import hooks from './hooks';

import { Hooks as ConsolidatePDFHooks } from './Form/Steps/Result/DownloadPDF/hooks';
import { Utils } from './Form/Steps/PosturesAndMovements/utils';
import { useApplicationContext } from '@/context/Application';
import { useCreateReport, useSearchParams } from '@/hooks';
import { Context } from './index.types';
import { BaseContext } from '@/types';
import { FileTools } from './types/dto/file';

const { useFindOrCreateAnalysis, useGetExhibitions, useRebaAngles, useGetResults } = hooks;
const { useConsolidatePDF } = ConsolidatePDFHooks;

type UrlParams = {
	file_id: string;
};

type PreliminaryAnalysisProviderProps = {
	children: ReactNode;
};

const PreliminaryAnalysisContext = createContext<Context>({} as Context);

const TOOL_URL = {
	reba: 'reba',
	niosh: 'niosh',
	kim_mho: 'kim-mho',
	kim_push_pull: 'kim-pp',
	strain_index: 'strain-index'
};

export function PreliminaryAnalysisProvider({ children }: PreliminaryAnalysisProviderProps) {
	const { file_id } = useParams<UrlParams>();
	const [consolidateClick, setConsolidateClick] = useState(false);
	const { organization, company } = useApplicationContext();
	const { organization_id = organization?.id, company_id = company?.id } = useSearchParams<BaseContext>();

	const { data: file } = useFindOrCreateAnalysis(organization_id, company_id, file_id);
	const preliminaryAnalysis = useGetResults(organization_id, company_id, file_id);
	const createReport = useCreateReport();
	const consolidate = useConsolidatePDF();

	const {
		data: exhibitions,
		isLoading: gettingExhibitions,
		isError: errorGettingExhibitions
	} = useGetExhibitions(organization_id, company_id, file_id);

	const {
		data: angles,
		isFetching: gettingAngles,
		isError: errorGettingAngles
	} = useRebaAngles(organization_id, company_id, file?.id);

	function getLevelRiskFields(form: FormInstance<any>, baseFormName: string, formName: string[], stepName: string[]) {
		const exhibition: string = form.getFieldValue([baseFormName, ...formName, ...stepName, 'exhibition_id']);
		const consequence: string = form.getFieldValue([baseFormName, ...formName, ...stepName, 'consequence_id']);
		const probability: string = form.getFieldValue([baseFormName, ...formName, ...stepName, 'probability_id']);

		return { exhibition, consequence, probability };
	}

	function calculateBodyPartPercentage(member: number[]) {
		return Utils.calculatePercentage(member);
	}

	async function handleCreateTool(toolName: FileTools) {
		let id = file[toolName]?.id;

		if (!id) {
			const result = await createReport.mutateAsync({
				file_id: file?.id ?? undefined,
				tool: `/ergonomic-tool/${TOOL_URL[toolName]}`,
				activity_id: file?.preliminary_analysis?.activity_id,
				evaluator_id: file?.preliminary_analysis?.evaluator_id,
				collection_date: file?.preliminary_analysis?.collection_date,
				cellule_id: file?.preliminary_analysis?.cellule_id,
				report_name: file?.preliminary_analysis?.report_name,
				workstation_id: file?.workstation_id,
				sector_id: file?.sector_id,
				organization_id,
				company_id
			});

			id = result.file_id ?? result.id;
		}

		const url = `/reporting/${toolName}/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		window.open(url, '_blank');
	}

	const contextValue: Context = {
		file,
		company_id,
		organization_id,
		exhibitionList: {
			exhibitions,
			gettingExhibitions,
			errorGettingExhibitions
		},
		rebaAngles: {
			angles,
			gettingAngles,
			errorGettingAngles
		},
		handleCreateTool,
		preliminaryAnalysis,
		getLevelRiskFields,
		calculateBodyPartPercentage,
		consolidate,
		consolidateClick,
		setConsolidateClick
	};

	return <PreliminaryAnalysisContext.Provider value={contextValue}>{children}</PreliminaryAnalysisContext.Provider>;
}

export function usePreliminaryAnalysis() {
	const context = useContext(PreliminaryAnalysisContext);
	return context;
}
