import styled from 'styled-components';

export const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	z-index: 0;
	overflow: hidden;
	filter: blur(5px);
`;

export const SvgContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	top: 65%;
	left: 10%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%); /* for IE 9 */
	-webkit-transform: translate(-50%, -50%); /* for Safari */
	width: 100%;
	height: 100%;
	margin: 0;
	opacity: 0.9;

	svg {
		transform: scale(150%);
	}

	@media (min-width: 1600px) {
		top: 85%;
		left: 15%;

		svg {
			transform: scale(140%);
		}
	}

	@media (min-width: 2000px) {
		top: 95%;
		left: 20%;

		svg {
			transform: scale(140%);
		}
	}
`;

export const Mask = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: linear-gradient(138deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
`;
