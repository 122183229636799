import React from 'react';
import { Col, Row } from 'antd';

import { ReactComponent as SafetyFilled } from '@/assets/icons/safety-filled.svg';
import { Text, Title } from '@/components/Typography';

import { Force2FAContainer } from './styles';

export const Force2FAWarning: React.FC = () => {
	return (
		<Force2FAContainer>
			<Row justify="center" align="middle" style={{ marginTop: '2rem', color: '#2f54eb' }}>
				<SafetyFilled />
			</Row>
			<Row justify="center" style={{ marginTop: '1.5rem', textAlign: 'center' }}>
				<Title level={4}>Two-factor authentication</Title>
			</Row>
			<Row justify="center" style={{ marginTop: '0.5rem' }}>
				<Col xl={23} xxl={20} style={{ textAlign: 'center', fontSize: '1rem', lineHeight: '1.3rem' }}>
					<Text>For security reasons, we need you to enable your two-factor validation</Text>
				</Col>
			</Row>
			<Row justify="center" style={{ marginTop: '2rem', marginBottom: '4rem' }}>
				<Col style={{ textAlign: 'center', fontSize: '0.9rem', lineHeight: '1.3rem' }}>
					<Text>
						Your company is requesting that all users have two-factor authentication enabled. Proceed to
						activate yours and login.
					</Text>
				</Col>
			</Row>
		</Force2FAContainer>
	);
};
