import { Button, DatePicker, Form, Radio, Select } from 'antd';
import styled from 'styled-components';

const { Item } = Form;

export const CustomFormItem = styled(Item)`
	height: 2.25rem;
	label {
		font-weight: 800;
	}
	@media (min-width: 1200px) {
		height: 3rem;
	}
`;

export const RadioGroup = styled(Radio.Group)`
	label {
		font-weight: 500;
	}
	width: 100%;
	text-align: center;
	border-radius: 8px;
	background-color: #f5f5f5;
	border-top: 1px solid rgba(38, 38, 38, 0.1);
	border-bottom: 1px solid rgba(38, 38, 38, 0.1);
	border-right: 1px solid rgba(38, 38, 38, 0.1);
`;
export const RadioButton = styled(Radio.Button)`
	width: 33%;
	border-radius: 8px;
	margin: 0 0px;
	border: none;
	background-color: #f5f5f5;

	&:not(:first-child)::before {
		margin: 6px -1px;
		background-color: #e2e6f4;
		height: 13px;
		width: 2px;
	}

	@media (min-width: 1500px) {
		&:not(:first-child)::before {
			margin: 7px -1px;
			background-color: #e2e6f4;
			height: 25px;
			width: 2px;
		}
	}

	&:hover {
		&:not(:first-child)::before {
			width: 1px;
			background-color: #e2e6f4;
		}
	}
	&:first-child {
		border-radius: 8px;
	}
	&:last-child {
		border-radius: 8px;
	}
`;

export const FilterButton = styled(Button)`
	width: 90px;
	border-radius: 8px;
	margin: 0.5rem 0 0 0;
	@media (min-width: 1400px) {
		width: 115px;
		border-radius: 8px;
		margin: 1rem 0 0 0;
	}
`;
export const CustomSelect = styled(Select)`
	.ant-select-selector {
		border-radius: 7px !important;
	}
`;

export const CustomDatePicker = styled(DatePicker)`
	border-radius: 7px;
`;
