import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Form, Input, Row, Modal } from 'antd';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Confirm, NameInput } from '../styles';
import { useManageOrganization } from '../../../context';
import { WorkstationDTO } from '../../../types';

const { useFormInstance } = Form;

interface EditWorkstationProps {
	workstations: WorkstationDTO[];
	workstationName: string;
	onEditing: (isEditing: boolean) => void;
	onWorkstationNameChange: (name: string) => void;
}

export const EditWorkstation: React.FC<EditWorkstationProps> = ({
	workstations,
	workstationName,
	onWorkstationNameChange,
	onEditing
}) => {
	const [editingName] = useState(workstationName);
	const {
		updateOrganizationData,
		treatFieldValidation,
		handleIsEditing,
		companyId,
		organizationId,
		sectorId,
		workstationId
	} = useManageOrganization();

	const { setFieldValue, validateFields } = useFormInstance();

	function handleWorkstationNameChange(name: string) {
		onWorkstationNameChange(name);
	}

	function handleCancel() {
		onWorkstationNameChange('');
		handleIsEditing(false);
		onEditing(false);
		setFieldValue('workstation_id', workstationId);
	}

	async function handleUpdateWorkstation(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		try {
			if (workstations.find((elem) => elem.name === workstationName)) {
				const content = I18n.get(`This name already exists. Do you wish to proceed ?`);
				Modal.confirm({
					title: I18n.get('Warning!'),
					icon: <ExclamationCircleOutlined />,
					content: content,
					okType: 'danger',
					okText: I18n.get('Yes'),
					cancelText: I18n.get('Cancel'),
					onOk: async () => {
						updateOrganizationData({
							organization_id: organizationId,
							company_id: companyId,
							sector_id: sectorId,
							workstation_id: workstationId,
							name: workstationName
						}).then(({ id }) => {
							onWorkstationNameChange('');
							handleIsEditing(false);
							onEditing(false);
							setFieldValue('workstation_id', id);
							setFieldValue('edit_workstation_name', '');
						});
					}
				});
			} else {
				updateOrganizationData({
					organization_id: organizationId,
					company_id: companyId,
					sector_id: sectorId,
					workstation_id: workstationId,
					name: workstationName
				}).then(({ id }) => {
					onWorkstationNameChange('');
					handleIsEditing(false);
					onEditing(false);
					setFieldValue('workstation_id', id);
					setFieldValue('edit_workstation_name', '');
				});
			}

			await validateFields(['edit_workstation_name']);
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_workstation_name"
						label={I18n.get('Edit workstation')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								message: I18n.get('Please, enter a valid workstation name')
							}
						]}
					>
						<Input
							style={{
								flex: 'auto'
							}}
							value={workstationName}
							onChange={(e) => handleWorkstationNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Col span={12}>
							<Button
								disabled={!companyId || !workstationId}
								size="middle"
								type="primary"
								ghost={true}
								danger={true}
								onClick={() => handleCancel()}
								icon={<CloseOutlined />}
							/>
						</Col>
						<Confirm span={12}>
							<Button
								disabled={
									!companyId || !workstationId || !workstationName || editingName === workstationName
								}
								size="middle"
								type="primary"
								ghost={true}
								onClick={(e) => handleUpdateWorkstation(e)}
								icon={<CheckOutlined />}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
