import { AxiosError } from 'axios';
import { notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { CreateReportDTO, CreateReportResponse } from '@/views/CreateReport/context/types';

type CreateReportError = AxiosError<any>;

export function handleReportError(err: AxiosError<any>) {
	const title = 'Oops! Something happened.';
	const method = err?.config?.method === 'put' ? 'update' : 'create';
	const message = err.response?.data?.message ?? `Failed to ${method} information`;
	notification.error({
		message: I18n.get(title),
		description: I18n.get(message)
	});
}

async function createReport(parameters: CreateReportDTO) {
	const { tool, ...payload } = parameters;
	const { data } = await Api.post(tool, payload);
	return data;
}

export const useCreateReport = () => {
	const queryClient = useQueryClient();
	return useMutation<CreateReportResponse, CreateReportError, CreateReportDTO>((payload) => createReport(payload), {
		onSuccess: async (data) => {
			if (data.file_id) {
				queryClient.invalidateQueries(['file']);
			}
			queryClient.invalidateQueries([QUERY_KEYS.GET_RULA_REPORT]);
		},
		onError: handleReportError
	});
};
