import styled from 'styled-components';
import { Card } from 'antd';

const ACTIVE_COLOR = '#2CC852';
const INATIVE_COLOR = '#E74150';

interface IContainerProps {
	isactive: boolean;
}

export const Container = styled(Card)<IContainerProps>`
	margin-top: 10px;
	color: ${({ isactive }) => (isactive ? ACTIVE_COLOR : INATIVE_COLOR)};
	border-color: ${({ isactive }) => (isactive ? ACTIVE_COLOR : INATIVE_COLOR)};
	:hover {
		border-color: ${({ isactive }) => (isactive ? ACTIVE_COLOR : INATIVE_COLOR)};
	}
`;
