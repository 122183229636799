import Api from '@/services/api';

import {
	Find2FARequest,
	Reset2FARequest,
	SendTokenRequest,
	Generate2FARequest,
	ResendTokenRequest,
	Validate2FARequest,
	Deactivate2FARequest,
	EnableAuthAppRequest,
	ConfirmPasswordRequest,
	ChangeDefaultAuthTypeRequest
} from './types/request';

import {
	Find2FADTO,
	Reset2FADTO,
	SendTokenDTO,
	Generate2FADTO,
	ResendTokenDTO,
	Validate2FADTO,
	GetForced2FADTO,
	Deactivate2FADTO,
	EnableAuthAppDTO,
	ConfirmPasswordDTO,
	ChangeDefaultAuthTypeDTO
} from './types/response';

export class TFAService {
	public async find2FA({ company_id, organization_id }: Find2FARequest): Promise<Find2FADTO> {
		const url = `/2fa?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<Find2FADTO>(url);
		return data;
	}

	public async generate2FA(body: Generate2FARequest): Promise<Generate2FADTO> {
		const url = '/2fa';
		const { data } = await Api.post<Generate2FADTO>(url, body);
		return data;
	}

	public async validate2FA(body: Validate2FARequest): Promise<Validate2FADTO> {
		const url = '/2fa';
		const { data } = await Api.put<Validate2FADTO>(url, body);
		return data;
	}

	public async changeDefault2FA(body: ChangeDefaultAuthTypeRequest): Promise<ChangeDefaultAuthTypeDTO> {
		const url = '/2fa/change-default-type';
		const { data } = await Api.put<ChangeDefaultAuthTypeDTO>(url, body);
		return data;
	}

	public async resendToken(body: ResendTokenRequest): Promise<ResendTokenDTO> {
		const url = '/2fa/resend';
		const { data } = await Api.post<ResendTokenDTO>(url, body);
		return data;
	}

	public async sendToken(body: SendTokenRequest): Promise<SendTokenDTO> {
		const url = '/2fa/send-token';
		const { data } = await Api.post<SendTokenDTO>(url, body);
		return data;
	}

	public async enableAuthApp(body: EnableAuthAppRequest): Promise<EnableAuthAppDTO> {
		const url = '/2fa/enable-auth-app';
		const { data } = await Api.put<EnableAuthAppDTO>(url, body);
		return data;
	}

	public async deactivate2FA({ company_id, organization_id }: Deactivate2FARequest): Promise<Deactivate2FADTO> {
		const url = `/2fa?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.delete<Deactivate2FADTO>(url);
		return data;
	}

	public async reset2FA(body: Reset2FARequest): Promise<Reset2FADTO> {
		const url = '/2fa/reset';
		const { data } = await Api.put<Reset2FADTO>(url, body);
		return data;
	}

	public async confirmPassword(body: ConfirmPasswordRequest): Promise<ConfirmPasswordDTO> {
		const url = '/2fa/confirm-password';
		const { data } = await Api.post<ConfirmPasswordDTO>(url, body);
		return data;
	}

	public async getForced2FA(): Promise<GetForced2FADTO> {
		const url = '/2fa/force';
		const { data } = await Api.get<GetForced2FADTO>(url);
		return data;
	}
}
