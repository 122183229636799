import React, { ReactNode } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { Step } from './Step';
import * as S from './styles';
import { Card } from '../../Card';
import { Text } from '@/components/Typography';
import { useCustomReportManagerContext } from '../../context';
import { useCustomReportManagerStepKeyContext } from '../context';

export const SelectedSteps: React.FC = () => {
	const { stepKeysList } = useCustomReportManagerStepKeyContext();
	const { selectedSteps, selectedStepId, firstIncompleteStepId, handleSelectedStepKeys, handleShowSummaryClick } =
		useCustomReportManagerContext();

	function handleSummaryClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
		event.preventDefault();
		event.stopPropagation();
		handleSelectedStepKeys(stepKeysList);
		handleShowSummaryClick();
	}

	function showIncompleteStepErrorMessage(): ReactNode {
		if (firstIncompleteStepId) {
			return (
				<S.ErrorMessageCol xs={6}>
					<Row align="bottom" gutter={[8, 8]}>
						<Col>
							<ExclamationCircleOutlined style={{ color: '#ffffff' }} />
						</Col>
						<Col>
							<Text>Incomplete section</Text>
						</Col>
					</Row>
				</S.ErrorMessageCol>
			);
		}
	}

	return (
		<S.CustomRow justify="space-between" align="middle">
			<Col xs={24}>
				<Card title="Selected sections">
					<Row align="top" justify="space-between" gutter={[16, 16]}>
						<S.StepListCol xs={24}>
							<S.StepListContainer>
								{selectedSteps.map(({ id, description }) => (
									<Step key={id} id={id} selected={selectedStepId === id} description={description} />
								))}
								<S.EmptyContainer />
							</S.StepListContainer>
						</S.StepListCol>
						<S.SummaryButtonContainer>
							<S.StepButton onClick={handleSummaryClick}>{I18n.get('Summary')}</S.StepButton>
						</S.SummaryButtonContainer>
					</Row>
				</Card>
			</Col>
			{showIncompleteStepErrorMessage()}
		</S.CustomRow>
	);
};
