import React, { Fragment } from 'react';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { Statuses } from '../../types';
import { Status, StatusAmount } from './styles';
import { actionPlanStatuses } from '../ActionPlanStatuses';

type PlansStatusProps = {
	statuses: Statuses[];
};

export const PlansStatus: React.FC<PlansStatusProps> = ({ statuses }) => {
	function findStatus(actionPlanStatus: string) {
		const status = statuses.find((status) => status.status === actionPlanStatus);
		return status;
	}

	return (
		<>
			{actionPlanStatuses.map((actionPlanStatus, index) => {
				return (
					<Fragment key={actionPlanStatus.id}>
						<Col span={5}>
							<Row
								gutter={[0, 4]}
								align="top"
								style={{
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								<Col
									style={{
										display: 'flex',
										alignItems: 'flex-start'
									}}
								>
									<Status>{I18n.get(actionPlanStatus.caption)}</Status>
								</Col>
							</Row>
							<Row
								align="top"
								style={{
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								<Col
									style={{
										display: 'flex',
										alignItems: 'flex-start'
									}}
								>
									<StatusAmount
										style={{
											color: actionPlanStatus?.color
										}}
									>
										{statuses?.length ? findStatus(actionPlanStatus.status)?.amount || 0 : 0}
									</StatusAmount>
								</Col>
							</Row>
						</Col>
						{index < actionPlanStatuses.length - 1 && (
							<Divider type="vertical" style={{ height: 'auto' }}></Divider>
						)}
					</Fragment>
				);
			})}
		</>
	);
};
