import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled(Modal)`
	.ant-modal-content {
		border-radius: 30px;
		@media (min-width: 1600px) {
			min-height: 780px;
		}
	}
	.ant-modal-close-x {
		margin: 10px 10px 0 0;
	}
`;
