import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Switch } from 'antd';

import { useGetContractPlans } from '@/components/Settings/ContractPlans/hooks/index';
import { Address, City, District, EditableField, Nit, Plan } from '../components';
import { SwitchFormItem, SubTitle } from '../styles';
import { Text } from '@/components/Typography';

const { useWatch } = Form;

export function Information() {
	const { data } = useGetContractPlans();
	const company_information = useWatch('company_information');

	return (
		<Row gutter={[0, 15]} style={{ marginBottom: 30 }}>
			<Col span={24}>
				<EditableField
					required
					name="company_name"
					title="Industrial site"
					errorMessage="Enter a industrial site name"
				/>
			</Col>
			<Col span={24}>
				<SubTitle>{I18n.get('Information')}</SubTitle>
			</Col>
			<Col span={24}>
				<Row align="middle" gutter={[10, 0]}>
					<Col>
						<SwitchFormItem name="company_information" valuePropName="checked">
							<Switch />
						</SwitchFormItem>
					</Col>
					<Col>
						<Text style={{ fontSize: 15 }}>{I18n.get('Use the same company information')}</Text>
					</Col>
				</Row>
			</Col>
			{!company_information && (
				<Col span={24}>
					<Row gutter={[40, 20]}>
						<Col span={24}>
							<Address name="company_address" required />
						</Col>
						<Col span={12}>
							<City name="company_city" required />
						</Col>
						<Col span={12}>
							<District name="company_district" required />
						</Col>
						<Col span={12}>
							<Plan name="company_plan" options={data} required />
						</Col>
						<Col span={12}>
							<Nit name="company_nit" required />
						</Col>
					</Row>
				</Col>
			)}
		</Row>
	);
}
