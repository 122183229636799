import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import { Text, Title } from '@/components/Typography';

export const CardContainer = styled.div`
	width: 100%;
	gap: 1.5rem;
	display: flex;
	user-select: none;
	position: relative;
	border-radius: 2rem;
	padding: 2rem;
	background-color: #fff;
	flex-direction: column;
`;

export const ActionsEllipsisContainer = styled(Button)`
	z-index: 1;
	top: 1.5rem;
	right: 1rem;
	border: none;
	gap: 0.25rem;
	display: flex;
	padding: 0 0.5rem;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	transition: filter 0.2s;
	&:hover {
		cursor: pointer;
		filter: brightness(1.5);
	}
`;

export const ActionsEllipsisDot = styled.span`
	height: 5px;
	width: 5px;
	border-radius: 50%;
	display: inline-block;
	background-color: #262626;
`;

export const TitleCol = styled(Col)`
	text-align: center;
`;

export const CustomTitle = styled(Title)`
	/* margin-bottom: 0 !important;
	margin-left: 8px; */
`;

export const SubtitleRow = styled(Row)`
	margin-bottom: 1rem;
`;

export const Subtitle = styled(Text)`
	font-size: 1rem;
	font-weight: 600;
`;

export const TextContent = styled(Text)`
	font-size: 1rem;
	font-weight: 400;
	margin-left: 0.25rem;
`;
