import React, { useEffect } from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { DefaultOptionType } from 'antd/lib/select';
import { BaseOptionType } from 'antd/lib/cascader';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { CustomFormItem, CustomSelect } from '../styles';
import { useGetCompanies } from '../../hooks';
import { OptionType } from '../types';

const { useFormInstance, useWatch } = Form;

export const Companies: React.FC = () => {
	const form = useFormInstance();
	const breakpoints = useBreakpoint();
	const organizationIds = useWatch('organization_ids', form);

	const { mutateAsync, isLoading, data: companies, isError } = useGetCompanies();

	function filterOption(option: OptionType | DefaultOptionType | BaseOptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	useEffect(() => {
		organizationIds?.length > 0 && getCompanies();
	}, [organizationIds]);

	const getCompanies = async () => {
		await mutateAsync({ organization_ids: organizationIds });
	};

	const handleSelectAll = (value: string[]) => {
		if (value?.includes('all') && companies) {
			if (value.length === companies.length + 1) {
				return [];
			}
			return companies.filter((company) => !company.id.includes('all')).map((company) => company.id);
		}
		return value;
	};

	function companyDisable() {
		const isOrganizationIds = organizationIds?.length === 0;
		return isLoading || isOrganizationIds;
	}

	return (
		<Col xs={24}>
			<CustomFormItem
				name={['company_ids']}
				label={I18n.get('Industrial site')}
				getValueFromEvent={handleSelectAll}
				rules={[
					{
						required: true,
						message: I18n.get('Select the industrial site')
					}
				]}
				style={{ height: 'auto' }}
			>
				<CustomSelect
					allowClear
					showSearch={true}
					mode="multiple"
					filterOption={(input, option) => filterOption(option, input)}
					placeholder={I18n.get('Please select one or more')}
					size={!breakpoints.xxl ? 'small' : 'large'}
					maxTagCount={'responsive'}
					options={companies?.map(({ id, name }) => ({ label: name, value: id }))}
					disabled={companyDisable() || isError}
					loading={isLoading}
					showArrow={true}
				/>
			</CustomFormItem>
		</Col>
	);
};
