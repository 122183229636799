import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_COMPANIES_CONTRACT_PLANS, GET_ORGANIZATIONS_CONTRACT_PLANS } = QUERY_KEYS;

type Plan = {
	recurrence: string;
	max_minutes: number;
	max_uploads: number;
	expiration_date: Date;
};

type BussinessInformation = {
	nit: string;
	city: string;
	address: string;
	district: string;
	contract_plan_id: string;
};

type CompaniesParams = {
	plan?: Plan;
	company_name: string;
	organization_id: string;
	business_information?: BussinessInformation;
};

type CompaniesResponse = {
	company: {
		id: string;
		name: string;
		createdAt: Date;
		updatedAt: Date;
		isActive: boolean;
		organization_id: string;
	};
	plan: Plan | null;
};

async function createCompany(payload: CompaniesParams): Promise<CompaniesResponse> {
	const { data } = await Api.post('/contract-plan/company', payload);
	return data;
}

export const useCreateCompany = () => {
	const queryClient = useQueryClient();
	return useMutation(createCompany, {
		onSuccess: () => {
			queryClient.invalidateQueries([GET_COMPANIES_CONTRACT_PLANS]);
			queryClient.invalidateQueries([GET_ORGANIZATIONS_CONTRACT_PLANS]);
		}
	});
};
