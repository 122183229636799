import styled from 'styled-components';
import { Col } from 'antd';

export const Container = styled(Col)`
	border-radius: 30px;
	padding: 3% 4.5%;
	box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.05), 3px 3px 4px 0px rgba(0, 0, 0, 0.05);
	background-color: #fff;

	button {
		width: 100%;
	}

	p {
		color: rgb(204, 204, 204);
		text-align: center;
		margin-top: 16px;
		font-size: 12px;
		display: flex;
		justify-content: space-between;
	}
`;

export const IconContainer = styled(Col)`
	color: #2f54eb;
`;
