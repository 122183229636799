import { Col } from 'antd';
import styled from 'styled-components';

export const PreviousCol = styled(Col)`
	padding: 0;
	margin-right: 1rem;

	@media (min-width: 576px) {
		margin-right: 4rem;
	}

	#work_schedule_hours_help {
		position: absolute;
	}
`;
