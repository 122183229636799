import { useQuery } from '@tanstack/react-query';
import type { GetUsersDTO } from '@/types/dto/user.dto';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { UserRole } from '@/types/User';
import { ErrorResponse } from '@/types';
import Api from '@/services/api';

export interface UserDTO {
	id: string;
	name: string;
	email: string;
	role: UserRole;
	is_active: boolean;
}

export type UserListResponse = {
	rows: UserDTO[];
	count: number;
};

async function getUsers({ organization_id, company_id, filter, page, limit }: GetUsersDTO): Promise<UserListResponse> {
	const url = `/user_management/user_list_by_costumer/${organization_id}/${company_id}`;
	const { data } = await Api.get(url, { params: { ...filter, page, limit } });
	return data;
}

export function useGetUsers(params: GetUsersDTO) {
	return useQuery<UserListResponse, ErrorResponse>([QUERY_KEYS.GET_USERS, params], () => getUsers(params));
}
