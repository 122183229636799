import React from 'react';
import { Row } from 'antd';
import { Background } from '@/components/ui/Background';

interface ContainerProps {
	children: string | JSX.Element | JSX.Element[];
	withBackground?: boolean;
}

type ContentProps = Pick<ContainerProps, 'children'>;
type ContentWithBackgroundProps = Pick<ContainerProps, 'children'>;

const Content: React.FC<ContentProps> = ({ children }) => (
	<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
		{children}
	</Row>
);

const ContentWithBackground: React.FC<ContentWithBackgroundProps> = ({ children }) => (
	<>
		<Background />
		<Content>{children}</Content>
	</>
);

export const Container: React.FC<ContainerProps> = ({ children, withBackground = true }) => {
	if (withBackground) {
		return <ContentWithBackground>{children}</ContentWithBackground>;
	}
	return <Content>{children}</Content>;
};
