import { Modal, Row, Button } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
	.ant-modal-content {
		padding: 30px 0;
		border-radius: 30px;
		min-width: 400px;
		max-width: 550px;
	}
`;

export const FooterRow = styled(Row)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const RoundButton = styled(Button)`
	border-radius: 6px;
	width: 110px !important;
	height: 35px !important;
`;
