import { Text } from '@/components/Typography';
import styled from 'styled-components';

export const TextCustom = styled(Text)`
	text-align: justify;
	width: 100%;
	display: block;
	margin: 20px 0px;
	padding: 0 10px;
`;
