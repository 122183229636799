import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useEditCompanyContext } from '../context';
import { CustomButton } from '../../styles';
import * as S from './styles';

export function Footer() {
	const { currentStep, onNextStep, onPreviousStep } = useEditCompanyContext();

	const buttons_steps = [
		{
			previous: 'Cancel',
			next: 'Next'
		},
		{
			previous: 'Back',
			next: 'Save'
		}
	];

	return (
		<S.Container span={24}>
			<Row justify="center" align="middle" gutter={[15, 0]}>
				<Col>
					<CustomButton type="ghost" onClick={onPreviousStep}>
						{I18n.get(buttons_steps[currentStep].previous)}
					</CustomButton>
				</Col>
				<Col>
					<CustomButton type="primary" onClick={onNextStep}>
						{I18n.get(buttons_steps[currentStep].next)}
					</CustomButton>
				</Col>
			</Row>
		</S.Container>
	);
}
