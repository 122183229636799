import React from 'react';
import { Col, Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { RequiredLabel } from './RequiredLabel';
import { OptionType } from './KinebotStepKey/types';
import { CustomFormItem } from './KinebotStepKey/styles';
import { usePersonalisedReportContext } from '../../context';
import { AntdDropdownOption, DropdownOptions } from '../../context/types';

const { useFormInstance, useWatch } = Form;

interface ProbabilitiesProps {
	stepKeyFieldName: string[];
	mapDropdownOptions: (options: DropdownOptions) => AntdDropdownOption[];
}

export const Probabilities: React.FC<ProbabilitiesProps> = ({ stepKeyFieldName, mapDropdownOptions }) => {
	const form = useFormInstance();
	const { probabilitiesData } = usePersonalisedReportContext();

	const fieldName = [...stepKeyFieldName, 'probability_id'];
	const probabilityId = useWatch(fieldName, form);

	function handleProbabilitySelect(id: string): void {
		form.setFieldValue(fieldName, id);
	}

	function handleClearProbability(): void {
		form.setFieldValue(fieldName, undefined);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Probability" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the probability')
					}
				]}
			>
				<Select
					showSearch
					allowClear
					size="large"
					value={probabilityId}
					onClear={handleClearProbability}
					placeholder={I18n.get('Probability')}
					disabled={probabilitiesData.errorGettingProbabilities}
					onChange={(value: string) => handleProbabilitySelect(value)}
					filterOption={(input, option) => filterOption(option, input)}
					options={mapDropdownOptions(probabilitiesData.probabilities)}
					loading={probabilitiesData.gettingProbabilities || probabilitiesData.fetchingProbabilities}
				/>
			</CustomFormItem>
		</Col>
	);
};
