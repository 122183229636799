import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5% 0;
	border-radius: 3px;
	font-weight: 600;
	font-size: 1rem;
	border: 1px solid #2f54eb;
	background: #2f54eb;
`;
