import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Draggable } from 'react-beautiful-dnd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import { useCustomReportManagerStepContext } from '../context';
import { CustomListDraggable, CustomButton, StepDescriptionCol } from './styles';

interface StepCardProps {
	id: string;
	index: number;
	description: string;
}

export const StepCard: React.FC<StepCardProps> = ({ id, index, description }) => {
	const { removeStepFromStepList } = useCustomReportManagerStepContext();
	function handleRemoveSelectedStep(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		event.stopPropagation();
		removeStepFromStepList({
			id,
			description,
			sequence: index
		});
	}

	return (
		<Draggable draggableId={id} key={id} index={index}>
			{({ innerRef, draggableProps, dragHandleProps }) => (
				<Row key={id} justify="center" {...draggableProps} {...dragHandleProps} ref={innerRef}>
					<CustomListDraggable xs={24}>
						<Row align="middle" justify="space-between">
							<Col xs={2} xl={1}>
								<MenuOutlined style={{ fontSize: '0.8rem' }} />
							</Col>
							<StepDescriptionCol xs={20}>{I18n.get(description)}</StepDescriptionCol>
							<Col xs={2} xl={1}>
								<CustomButton onClick={(event) => handleRemoveSelectedStep(event)}>
									<CloseOutlined
										style={{
											fontSize: '0.8rem',
											color: '#C6C6C4'
										}}
									/>
								</CustomButton>
							</Col>
						</Row>
					</CustomListDraggable>
				</Row>
			)}
		</Draggable>
	);
};
