import React from 'react';
import { Col, Space } from 'antd';
import * as Typography from '@/components/Typography';

export const SubTitle: React.FC = () => (
	<Col span={24}>
		<Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
			<Typography.Title level={5} style={{ fontWeight: 400 }}>
				The server encountered an unexpected situation.
			</Typography.Title>
		</Space>
		<Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', paddingTop: '1.5rem' }}>
			<Typography.Title level={5} style={{ fontWeight: 300 }}>
				500
			</Typography.Title>
		</Space>
	</Col>
);
