import React from 'react';
import { Col, Divider, Row } from 'antd';

import { Header } from './Header';
import { PushPull } from './PushPull';
import { LiftLowerCarry } from './LiftLowerCarry';
import { usePersonalisedReportResultContext } from '../../../context';

export const OverallScore: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;
	const { liberty_mutual } = basic_information;

	function renderResult(): JSX.Element {
		if (liberty_mutual?.percentile_man && liberty_mutual?.percentile_woman) {
			return (
				<LiftLowerCarry
					percentile_man={liberty_mutual.percentile_man}
					percentile_woman={liberty_mutual.percentile_woman}
				/>
			);
		}
		return (
			<PushPull
				percentile_man_initial={liberty_mutual?.percentile_man_initial}
				percentile_man_sustain={liberty_mutual?.percentile_man_sustain}
				percentile_woman_initial={liberty_mutual?.percentile_woman_initial}
				percentile_woman_sustain={liberty_mutual?.percentile_woman_sustain}
			/>
		);
	}

	return (
		<Row>
			<Col span={24}>
				<Header />
			</Col>
			<Divider style={{ margin: 0 }} />
			<Col span={24}>{renderResult()}</Col>
		</Row>
	);
};
