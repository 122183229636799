import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomInput, Label } from './styles';

export function FileName() {
	const fieldName = 'original_name';
	const title = I18n.get('File name');

	return (
		<Form.Item name={[fieldName]} label={<Label>{title}</Label>}>
			<CustomInput placeholder={title} />
		</Form.Item>
	);
}
