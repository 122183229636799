import React from 'react';
import { Container, Component } from './styles';
import { WrapperBreadcrumb } from '@/components/Layout/Breadcrumb';

interface ContentProps {
	children: () => JSX.Element;
}

export const Content: React.FC<ContentProps> = ({ children }) => (
	<Container>
		<WrapperBreadcrumb />
		<Component>{children()}</Component>
	</Container>
);
