import { CheckCircleFilled, ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import React from 'react';

type StressLevelOptions = {
	id: number;
	level: number;
	img: JSX.Element;
};

export const stressLevel: StressLevelOptions[] = [
	{
		id: 1,
		level: 1,
		img: <CheckCircleFilled style={{ fontSize: 20, width: '1.75rem' }} />
	},
	{
		id: 2,
		level: 2,
		img: <ExclamationCircleFilled style={{ fontSize: 20, width: '1.75rem' }} />
	},
	{
		id: 3,
		level: 3,
		img: <ExclamationCircleFilled style={{ fontSize: 20, width: '1.75rem' }} />
	},
	{
		id: 4,
		level: 4,
		img: <WarningFilled style={{ fontSize: 20, width: '1.75rem' }} />
	},
	{
		id: 5,
		level: 5,
		img: <WarningFilled style={{ fontSize: 20, width: '1.75rem' }} />
	}
];
