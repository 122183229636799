import React from 'react';
import { Col, Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

const LIBERTY_MUTUAL_ICON = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/liberty-mutual-icon.png';

export const LibertyMutualIcon: React.FC = () => (
	<Col xs={2} xxl={3} style={{ margin: 0, padding: 0 }}>
		<Row justify="center">
			<Col span={24} style={{ margin: 0, padding: 0 }}>
				<Image src={LIBERTY_MUTUAL_ICON} width="90%" preview={false} alt={I18n.get('Material handling')} />
			</Col>
		</Row>
	</Col>
);
