import React from 'react';
import { Col, Row } from 'antd';
import { Paragraph, Title } from '@/components/Typography';

import { Footer } from './Footer';
import { TextArea } from '../../components/TextArea';
import { WorkerSelfEvaluation, WorkingPopulation } from './Inputs';

export const Characteristics: React.FC = () => (
	<Row justify="center" gutter={[5, 20]}>
		<Col xs={21}>
			<Row align="middle">
				<Col xs={24}>
					<Title level={4}>Characteristics</Title>
				</Col>
				<Col xs={24} style={{ marginBottom: '20px' }}>
					<Paragraph>Select the restrictions perceived in the evaluated characteristics</Paragraph>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={23}>
					<WorkingPopulation />
					<Row justify="center" gutter={[0, 10]} style={{ marginBottom: '2rem' }}>
						<TextArea
							namePath={['characteristics', 'particularities_description']}
							title="Particularities / Production / Working regime"
						/>
						<TextArea
							title="Worker's verbalization"
							namePath={['characteristics', 'worker_verbalization_description']}
						/>
					</Row>
					<WorkerSelfEvaluation />
				</Col>
			</Row>
			<Footer />
		</Col>
	</Row>
);
