import hooks from '@/components/Settings/Organizations/hooks';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { Form } from 'antd';
import React from 'react';

type WorkstationProps = {
	organizationId: string;
	companyId: string;
	sectorId: string;
};

const { useGetWorkstations } = hooks;

export function Workstation({ organizationId, companyId, sectorId }: WorkstationProps) {
	const workstations = useGetWorkstations(organizationId, companyId, sectorId);
	return (
		<Form.Item name="workstation">
			<WorkstationSelect
				workstations={workstations?.data}
				sectorId={sectorId}
				loading={!!sectorId && workstations?.isLoading}
				noCreate={true}
			/>
		</Form.Item>
	);
}
