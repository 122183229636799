import React from 'react';

interface LibertyMutualSVGProps {
	width: number;
	height: number;
	margin?: string;
	disabled?: boolean;
}

export const LibertyMutualSVG: React.FC<LibertyMutualSVGProps> = ({ height, width, margin = 0, disabled = false }) => (
	<span style={{ display: 'flex', margin }}>
		<svg width={width} height={height} viewBox="0 0 52 46" fill="none">
			<rect
				rx={3}
				fill="none"
				width={35.64}
				height={23.2}
				strokeWidth={4}
				transform="matrix(-1 0 0 1 50 23)"
				stroke={disabled ? '#AAAAAA' : 'currentColor'}
			/>
			<path
				fill={disabled ? '#AAAAAA' : 'currentColor'}
				stroke={disabled ? '#AAAAAA' : 'currentColor'}
				d="M6.23 14.416c4.762-6.085 11.845-9.282 19.012-9.318l.012-3.868c0-.393.46-.61.761-.369l7.736 6.104a.475.475 0 010 .743l-7.742 6.11c-.308.242-.767.024-.761-.369V9.588a20.36 20.36 0 00-2.345.15 19.706 19.706 0 00-7.191 2.34 19.78 19.78 0 00-5.947 5.094 19.683 19.683 0 00-3.51 6.998 19.698 19.698 0 00-.393 8.514H1.335A24.083 24.083 0 016.23 14.416z"
			/>
		</svg>
	</span>
);
