import * as React from 'react';

type WhenProps = {
	children: React.ReactNode;
	isTrue?: boolean;
	limit?: number;
};

export const RenderWhen = ({ limit = 1, isTrue = true, children }: WhenProps) => {
	const list: React.ReactNode[] = [];

	if (isTrue !== true) {
		return null;
	}

	React.Children.map(children, (child: any) => {
		const { isTrue: isChildTrue } = child?.props || {};

		if (isChildTrue === true && list.length < limit) {
			list.push(child);
		}
	});

	return <>{list}</>;
};

RenderWhen.If = ({ children }: any) => children;
