import React from 'react';
import { Row, Col, Divider } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { CreateStep } from './CreateStep';
import { SelectStep } from './SelectStep';
import { SelectedStepsList } from './SelectedStepsList';
import { CustomReportManagerStepProvider } from './context';

export function CustomReportStep() {
	const breakpoints = useBreakpoint();
	return (
		<CustomReportManagerStepProvider>
			<Row justify="space-between" style={{ height: 'fit-content' }} gutter={[32, 32]}>
				<Col xs={24} sm={24} md={24} lg={12} xl={11} xxl={11}>
					<Row justify="center" gutter={[32, 32]}>
						<Col span={24}>
							<CreateStep />
						</Col>
						<Col span={24}>
							<SelectStep />
						</Col>
					</Row>
				</Col>
				{breakpoints.lg && breakpoints.xl && (
					<Col lg={2} xl={2} style={{ textAlign: 'center' }}>
						<Divider type="vertical" style={{ height: '100%' }} />
					</Col>
				)}
				<Col xs={24} sm={24} md={24} lg={12} xl={11} xxl={11}>
					<SelectedStepsList />
				</Col>
			</Row>
		</CustomReportManagerStepProvider>
	);
}
