import React, { Fragment } from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Chart } from './Chart';
import { Details, DetailTitle } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

export const BodyPartsScores: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;
	const { reba } = basic_information;

	return (
		<>
			{reba?.data.map((reba, index, array) => (
				<Fragment key={index}>
					{reba?.map((bodyPart, index, array) => (
						<Fragment key={bodyPart.id}>
							<Col span={7}>
								<Details>
									<Row style={{ marginBottom: '0.5rem' }}>
										<Col span={24}>
											<DetailTitle>{I18n.get(bodyPart.name)}:</DetailTitle>
										</Col>
									</Row>
									<Chart scores={bodyPart.scores} />
								</Details>
							</Col>
							{(index + 1) % 3 !== 0 && index !== array.length - 1 && (
								<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
							)}
						</Fragment>
					))}
					{(index + 1) % 3 !== 0 && index !== array.length - 1 && <Divider style={{ margin: 0 }} />}
				</Fragment>
			))}
		</>
	);
};
