import { message } from 'antd';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import { GetForced2FADTO } from './types/response';
import { Force2FARequest } from './types/request';
import { Force2FAService } from './service';

const force2FAService = new Force2FAService();

function useGetForced2FA() {
	const forced2FA = useQuery(['forced_2fa'], () => force2FAService.getForced2FA(), {
		retry: 1
	});

	return {
		...forced2FA,
		data: forced2FA.data || ({} as GetForced2FADTO)
	};
}

function useForce2FA() {
	const queryClient = useQueryClient();
	return useMutation(
		({ company_id, organization_id, force_two_fa }: Force2FARequest) =>
			force2FAService.force2FA({ company_id, organization_id, force_two_fa }),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: ({ force_two_fa }) => {
				queryClient.invalidateQueries(['forced_2fa']);
				const successMessage = !!force_two_fa
					? 'Forced two-factor authentication enabled successfully'
					: 'Forced two-factor authentication disabled successfully';
				message.success(I18n.get(successMessage));
			}
		}
	);
}

export { useGetForced2FA, useForce2FA };
