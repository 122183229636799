import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { Button } from '@/components/ui/Button';
import * as S from './styles';

interface FooterProps {
	onCancel: () => void;
}

export function Footer({ onCancel }: FooterProps) {
	const history = useHistory();

	return (
		<S.Container span={24}>
			<Row justify="center" align="middle" gutter={[15, 0]}>
				<Col>
					<Button type="ghost" onClick={() => onCancel()}>
						{I18n.get('Close')}
					</Button>
				</Col>
				<Col>
					<Button style={{ width: '100%' }} type="primary" onClick={() => history.push('/users')}>
						{I18n.get('Manage users')}
					</Button>
				</Col>
			</Row>
		</S.Container>
	);
}
