import styled from 'styled-components';

export const Details = styled.div`
	padding: 1.5rem;
`;

export const DetailTitle = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
`;

export const Score = styled.span`
	font-weight: 600;
	font-size: 1.5rem;
`;

export const Description = styled.span`
	font-weight: 400;
	font-size: 0.8rem;
`;
