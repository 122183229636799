import React from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { DefaultOptionType } from 'antd/lib/select';
import { BaseOptionType } from 'antd/lib/cascader';
import { Col, Form } from 'antd';

import { CustomFormItem, CustomSelect } from '../styles';
import { useGetOrganizations } from '../../hooks';
import { I18n } from '@aws-amplify/core';
import { OptionType } from '../types';

const { useFormInstance } = Form;

export const Organizations: React.FC = () => {
	const { data: organizations, isLoading, isFetching, isError } = useGetOrganizations({});
	const breakpoints = useBreakpoint();
	const form = useFormInstance();

	function filterOption(option: OptionType | DefaultOptionType | BaseOptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const handleSelectAll = (value: string[]) => {
		if (value?.includes('all') && organizations) {
			if (value.length === organizations.length + 1) {
				return [];
			}
			return organizations.filter((org) => !org.id.includes('all')).map((org) => org.id);
		}
		return value;
	};

	function handleClearOrganization() {
		form.setFieldValue(['organization_ids'], undefined);
		form.setFieldValue(['company_ids'], undefined);
	}

	return (
		<Col xs={24}>
			<CustomFormItem
				label={I18n.get('Company')}
				name={['organization_ids']}
				getValueFromEvent={handleSelectAll}
				rules={[
					{
						required: true,
						message: I18n.get('Select the company')
					}
				]}
				style={{ height: 'auto' }}
			>
				<CustomSelect
					allowClear
					showSearch={true}
					mode="multiple"
					filterOption={(input, option) => filterOption(option, input)}
					placeholder={I18n.get('Please select one or more')}
					size={!breakpoints.xxl ? 'small' : 'large'}
					maxTagCount={'responsive'}
					options={organizations?.map(({ id, name }) => ({ label: name, value: id }))}
					disabled={isLoading || isFetching || isError}
					loading={isLoading || isFetching || isError}
					onClear={handleClearOrganization}
					showArrow={true}
				/>
			</CustomFormItem>
		</Col>
	);
};
