import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
	const { pathname } = useLocation();

	const removeQuery = (key: string, query: any) => {
		query.delete(key);

		return query;
	};

	const addQuery = (key: string, value: string, query: any) => {
		query.set(key, value);

		return query;
	};

	const setQueryParam = (key: string, value: string, query: any, setQueryUrl: any) => {
		const newQueryParam = addQuery(key, value, query);

		window.history.pushState('', '', `${pathname}?${newQueryParam.toString()}`);
		setQueryUrl(newQueryParam);
	};

	const clearAll = (setQueryUrl: any) => {
		window.history.pushState('', '', `${pathname}`);
		setQueryUrl();
	};

	const removeProp = (key: string, query: any, setQueryUrl: any) => {
		const newQueryParam = removeQuery(key, query);

		window.history.pushState('', '', `${pathname}?${newQueryParam.toString()}`);
		setQueryUrl(newQueryParam);
	};

	return { setQueryParam, clearAll, removeProp };
};
