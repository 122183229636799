import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Label } from './styles';
import { useEvaluators } from '@/hooks';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Evaluators() {
	const form = useFormInstance();

	const fieldName = 'evaluator_id';
	const title = I18n.get('Evaluator');

	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);

	const {
		data: evaluators,
		isFetching: isGettingEvaluators,
		isError: errorGettingEvaluators
	} = useEvaluators({
		company_id: companyId,
		organization_id: organizationId
	});

	function handleSelectCompany(id: string) {
		if (id) {
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearCompany() {
		form.resetFields([fieldName]);
	}

	return (
		<Form.Item name={[fieldName]} label={<Label>{title}</Label>}>
			<SelectWithCreation
				showSearch
				allowClear
				placeholder={title}
				selectOptions={evaluators}
				onClear={handleClearCompany}
				loading={isGettingEvaluators}
				onChange={handleSelectCompany}
				disabled={!evaluators || !companyId || errorGettingEvaluators}
			/>
		</Form.Item>
	);
}
