import React, { useEffect, useState, useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Progress, Input, List, message, Row, Col, Form, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
	PlusOutlined,
	ExpandOutlined,
	CheckCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	CheckOutlined,
	StopOutlined
} from '@ant-design/icons';
import { TaskManagerContainer, AddTask, TaskList, taskIconStyle, ActionButton, ScrollableContainer } from './styles';
import { useSelector } from 'react-redux';
import Spinner from '@/components/Layout/Spinner';

import { useTasks } from '@/hooks/v1/useActionPlans';
import { useCreateTask, useUpdateTask, useDeleteTask } from '@/hooks/v1/useActionPlanMutations';
import { ActionPlansContext } from '../context';
import { useForm } from 'antd/lib/form/Form';

const conditionMapper = {
	ELIMINATE: 'Eliminate',
	CONTROL: 'Control',
	COMPENSATE: 'Compensate'
};

export function TaskManager({ actionPlan, triggerReloadHistory }) {
	const [form] = useForm();

	const selectOptions = [
		{
			value: 'ELIMINATE',
			label: I18n.get('Eliminate')
		},
		{
			value: 'CONTROL',
			label: I18n.get('Control')
		},
		{
			value: 'COMPENSATE',
			label: I18n.get('Compensate')
		}
	];
	const [editing, setEditing] = useState(Array(actionPlan.tasks.length).fill(false));

	const [triggerReloadTasks, setTriggerReloadTasks] = useState(false);
	const [taskInput, setTaskInput] = useState('');
	const [taskEditInput, setTaskEditInput] = useState('');

	const { calcProgress } = useContext(ActionPlansContext);
	const organization = useSelector(({ organization }) => organization.organization);

	const { tasks } = useTasks({
		organizationId: organization?.id,
		actionPlanId: actionPlan?.id
	});

	const { mutateAsync: createTask } = useCreateTask();
	const { mutateAsync: updateTask } = useUpdateTask();
	const { mutateAsync: deleteTask } = useDeleteTask();

	const [taskType, setTaskType] = useState('');
	const [taskEditType, setTaskEditType] = useState('');

	useEffect(() => {
		tasks.refetch();
	}, [triggerReloadTasks]);

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
	};

	const onCreateTask = async (value) => {
		if (!taskInput) return;

		const task = { description: value.task_title, type: value.condition, action_plan_id: actionPlan.id };
		const body = { task, actionPlanId: actionPlan.id, organizationId: organization?.id };
		createTask({ body })
			.then((res) => {
				triggerReloadHistory();
			})
			.catch(() => setTriggerReloadTasks((prev) => !prev))
			.finally(() => {
				setTaskInput('');
				setTaskType('');
				onReset();
			});
	};

	const handleToggleTask = (e, task, taskIndex) => {
		e.stopPropagation();
		if (editing[taskIndex] === true) return;
		onToggleTask(task);
	};

	const onToggleTask = (task) => {
		const body = {
			task: { ...task, is_completed: !task.is_completed },
			actionPlanId: actionPlan?.id,
			organizationId: organization?.id
		};

		updateTask({ body })
			.then(() => triggerReloadHistory())
			.catch(() => setTriggerReloadTasks((prev) => !prev));
	};

	const handleDeleteTask = (e, task, taskIndex) => {
		e.stopPropagation();
		if (editing[taskIndex] === false || !editing[taskIndex]) {
			onDeleteTask(task);
		} else {
			setEditing((prev) => [...prev.slice(0, taskIndex), false, ...prev.slice(taskIndex + 1)]);
			setTriggerReloadTasks((prev) => !prev);
		}
	};

	const onDeleteTask = async (task) => {
		const body = {
			taskId: task?.id,
			actionPlanId: actionPlan?.id,
			organizationId: organization?.id
		};

		deleteTask({ body })
			.then(() => triggerReloadHistory())
			.catch(() => setTriggerReloadTasks((prev) => !prev));
	};

	const onStartEditTask = (e, task, taskIndex) => {
		e.stopPropagation();

		if (editing[taskIndex] === true) {
			onFinishEditTask(task, taskIndex);
			return;
		}

		if (editing.some((item) => item === true)) {
			message.warn(I18n.get('Finish editing your other task first'));
			return;
		}

		form.setFieldsValue({ task_title_edit: task.description, condition_edit: task.type });

		setTaskEditInput(task.description);
		setTaskEditType(task.type);

		setEditing((prev) => [...prev.slice(0, taskIndex), true, ...prev.slice(taskIndex + 1)]);
	};

	const onFinishEditTask = async (task, taskIndex) => {
		setEditing((prev) => [...prev.slice(0, taskIndex), false, ...prev.slice(taskIndex + 1)]);

		const body = {
			task: { ...task, description: taskEditInput, type: taskEditType },
			actionPlanId: actionPlan?.id,
			organizationId: organization?.id
		};

		updateTask({ body })
			.then(() => triggerReloadHistory())
			.catch(() => setTriggerReloadTasks((prev) => !prev))
			.finally(() => {
				setTaskEditInput('');
				setTaskEditType('');
			});
	};

	const TaskIcon = ({ task, taskIndex }) => (
		<React.Fragment>
			{task.is_completed ? (
				<CheckCircleOutlined
					onClick={(e) => handleToggleTask(e, task, taskIndex)}
					style={{
						...taskIconStyle,
						color: 'green'
					}}
				></CheckCircleOutlined>
			) : (
				<ExpandOutlined
					onClick={(e) => handleToggleTask(e, task, taskIndex)}
					style={taskIconStyle}
				></ExpandOutlined>
			)}
		</React.Fragment>
	);

	const EditButton = ({ task, taskIndex }) => (
		<ActionButton
			onClick={(e) => onStartEditTask(e, task, taskIndex)}
			style={{ cursor: 'pointer' }}
			className={editing[taskIndex] ? 'confirm-btn' : 'edit-btn'}
		>
			{editing[taskIndex] ? <CheckOutlined /> : <EditOutlined />}
		</ActionButton>
	);

	const DeleteButton = ({ task, taskIndex }) => (
		<ActionButton
			onClick={(e) => handleDeleteTask(e, task, taskIndex)}
			style={{ cursor: 'pointer' }}
			className="delete-btn"
		>
			{editing[taskIndex] ? <StopOutlined /> : <DeleteOutlined />}
		</ActionButton>
	);

	const onReset = () => {
		form.resetFields();
	};

	return (
		<>
			{tasks.isLoading ? (
				<Spinner />
			) : (
				<TaskManagerContainer>
					<Progress percent={calcProgress(tasks?.data)}></Progress>
					<AddTask>
						<Form
							form={form}
							name="add-tasks"
							onFinish={onCreateTask}
							style={{ width: '100%', marginTop: '20px' }}
						>
							<Row justify="center" gutter={20}>
								<Col span={12}>
									<Form.Item name="task_title" style={{ margin: '0' }}>
										<Input
											value={taskInput}
											onChange={(e) => handleInputChange(e, setTaskInput)}
											placeholder={I18n.get('Add a new task')}
											maxLength={500}
											showCount
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Tooltip title={I18n.get('Select a condition')}>
										<Form.Item name="condition" style={{ margin: '0' }}>
											<Select
												disabled={taskInput?.length === 0}
												onChange={(value) => setTaskType(value)}
												placeholder={I18n.get('Select a condition')}
												options={selectOptions}
											/>
										</Form.Item>
									</Tooltip>
								</Col>
								<Col span={2}>
									<Button
										type="primary"
										htmlType="submit"
										shape="circle"
										disabled={
											taskType?.length === 0 || taskInput?.length === 0 || editing.includes(true)
										}
										icon={<PlusOutlined />}
									></Button>
								</Col>
							</Row>
						</Form>
					</AddTask>
					<TaskList>
						<ScrollableContainer>
							<List
								size="small"
								dataSource={tasks?.data}
								renderItem={(task, taskIndex) => (
									<List.Item
										actions={[
											<EditButton
												type="primary"
												htmlType="submit"
												key={taskIndex}
												task={task}
												taskIndex={taskIndex}
											/>,
											<DeleteButton key={taskIndex} task={task} taskIndex={taskIndex} />
										]}
									>
										<TaskIcon task={task} taskIndex={taskIndex}></TaskIcon>
										{editing[taskIndex] ? (
											<Form form={form} name="edit-tasks" style={{ width: '100%' }}>
												<Row
													gutter={10}
													align="middle"
													justify="center"
													style={{ margin: '0' }}
												>
													<Col span={14}>
														<Form.Item
															name="task_title_edit"
															style={{ margin: '0' }}
															initialValue={tasks.data[taskIndex].description}
														>
															<TextArea
																showCount
																maxLength={500}
																value={taskEditInput}
																onChange={(e) => handleInputChange(e, setTaskEditInput)}
																onPressEnter={() => onFinishEditTask(task, taskIndex)}
																style={{ marginRight: '5px' }}
															/>
														</Form.Item>
													</Col>
													<Col span={10}>
														<Form.Item
															name="condition_edit"
															style={{ margin: '0' }}
															initialValue={tasks.data[taskIndex].type}
														>
															<Select
																value={taskEditType}
																onClick={(e) => e.stopPropagation()}
																onChange={(value) => setTaskEditType(value)}
																options={selectOptions}
																placeholder={I18n.get('Select a condition')}
																style={{ marginRight: '5px' }}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Form>
										) : (
											<Row justify={'start'} align={'middle'} style={{ width: '100%' }}>
												<Col span={14} style={{ textAlign: 'justify', width: '100%' }}>
													{task.description}
												</Col>
												<Col span={10} style={{ textAlign: 'center' }}>
													{I18n.get(conditionMapper[task.type])}
												</Col>
											</Row>
										)}
									</List.Item>
								)}
							/>
						</ScrollableContainer>
					</TaskList>
				</TaskManagerContainer>
			)}
		</>
	);
}
