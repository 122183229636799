import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { CustomReportResult, Response } from '@/types';
import { customReportService } from '../../../../hooks/services';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type PersonalisedReportCalculateScoresDTO = BaseContext & {
	custom_report_id: string;
	custom_report_result_id?: string;
};

export type PersonalisedReportCalculateScoresResponse = CustomReportResult;

async function calculateScores<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/score';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useCalculateScores() {
	const queryClient = useQueryClient();
	return useMutation<
		PersonalisedReportCalculateScoresResponse,
		AxiosError<Response>,
		PersonalisedReportCalculateScoresDTO
	>(
		(body) =>
			calculateScores<PersonalisedReportCalculateScoresResponse, PersonalisedReportCalculateScoresDTO>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
}
