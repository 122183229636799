import { useLocation } from 'react-router-dom';

type SearchParams = {
	[key: string]: string | undefined;
};

export function useSearchParams<T extends SearchParams>(): T {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const searchParams: T = Object.fromEntries(params.entries()) as T;
	return searchParams || {};
}
