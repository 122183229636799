const Types = {
	MODAL_SELECT: 'MODAL_SELECT', // Deprecado
	REQUEST_LOADING: 'REQUEST_LOADING',
	LIST_ORG_SUCCESS: 'LIST_ORG_SUCCESS',
	LIST_ORG_FAILURE: 'LIST_ORG_FAILURE',
	LIST_COMPANY_SUCCESS: 'LIST_COMPANY_SUCCESS',
	LIST_COMPANY_FAILURE: 'LIST_COMPANY_FAILURE',
	SET_ORGANIZATION: 'SET_ORGANIZATION',
	SET_COMPANY: 'SET_COMPANY',
	REQUEST_LOADING_SELECT: 'REQUEST_LOADING_SELECT',
	REQUEST_LOADING_SELECT_COMPANY: 'REQUEST_LOADING_SELECT_COMPANY',
	LIST_SELECT_FAILURE: 'LIST_ORG_SELECT_FAILURE',

	MODAL_SELECT_PROJECT: 'MODAL_SELECT_PROJECT',
	MODAL_SELECT_PROJECT_LOADING: 'MODAL_SELECT_PROJECT_LOADING',

	// Refactor
	CHANGE_PROJECT: 'CHANGE_PROJECT',
	COMPANIES_FILTER: 'COMPANIES_FILTER',
	COMPANIES_FILTER_LOADING: 'COMPANIES_FILTER_LOADING',
	COMPANIES_FILTER_ERROR: 'COMPANIES_FILTER',

	CREATE_ORGANIZATION_LOADING: 'CREATE_ORGANIZATION_LOADING',
	CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
	CREATE_ORGANIZATION_ERROR: 'CREATE_ORGANIZATION_ERROR',

	CREATE_COMPANY_LOADING: 'CREATE_COMPANY_LOADING',
	CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
	CREATE_COMPANY_ERROR: 'CREATE_COMPANY_ERROR',

	GET_ORGANIZATION_LOADING: 'GET_ORGANIZATION_LOADING',
	GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
	GET_ORGANIZATION_ERROR: 'GET_ORGANIZATION_ERROR',

	GET_LIST_COMPANIES_LOADING: 'GET_LIST_COMPANIES_LOADING',
	GET_LIST_COMPANIES_SUCCESS: 'GET_LIST_COMPANIES_SUCCESS',
	GET_LIST_COMPANIES_ERROR: 'GET_LIST_COMPANIES_ERROR',

	CLEAR_ORGANIZATION: 'CLEAR_ORGANIZATION',
	CLEAR_LIST_COMPANIES: 'CLEAR_LIST_COMPANIES',

	CLEAR_NEW_ORGANIZATION: 'CLEAR_NEW_ORGANIZATION',
	CLEAR_NEW_COMPANY: 'CLEAR_NEW_COMPANY',

	LIST_ORGANIZATION_LOADING: 'LIST_ORGANIZATION_LOADING',
	LIST_ORGANIZATION_SUCCESS: 'LIST_ORGANIZATION_SUCCESS',
	LIST_ORGANIZATION_ERROR: 'LIST_ORGANIZATION_ERROR',

	LIST_COMPANIES_LOADING: 'LIST_COMPANIES_LOADING',
	LIST_COMPANIES_SUCCESS: 'LIST_COMPANIES_SUCCESS',
	LIST_COMPANIES_ERROR: 'LIST_COMPANIES_ERROR',

	LIST_SECTORS_LOADING: 'LIST_SECTORS_LOADING',
	LIST_SECTORS_SUCCESS: 'LIST_SECTORS_SUCCESS',
	LIST_SECTORS_ERROR: 'LIST_SECTORS_ERROR',

	CHANGE_PROJECT_SELECTED: 'CHANGE_PROJECT_SELECTED'
};

export default Types;
