import { I18n } from '@aws-amplify/core';
import { Row, Col } from 'antd';
import React from 'react';

import { useAccountInformation } from '@/hooks/useAccountInformation';
import { Title } from '@/components/Typography';
import { Spinner } from '@/components/Spinner';

import { TFAProvider } from './Security/TwoFactorAuthentication/context';
import { Security } from './Security';
import { Container } from './styles';
import { Profile } from './Profile';
import { Address } from './Address';
import { Plan } from './Plan';

export const Account: React.FC = () => {
	const { isLoading, data, isError } = useAccountInformation();

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <h2>Internal server error</h2>;
	}

	return (
		<Row>
			<Title level={3}>{I18n.get('Account overview')}</Title>
			<Container gutter={[50, 40]}>
				<Col lg={24} xxl={12}>
					<Profile data={data.user} />
				</Col>
				<Col lg={24} xxl={12}>
					<Address data={data} />
				</Col>
				<Col lg={24} xxl={12}>
					<Plan />
				</Col>
				<Col lg={24} xxl={12}>
					<TFAProvider>
						<Security />
					</TFAProvider>
				</Col>
			</Container>
		</Row>
	);
};
