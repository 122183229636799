import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Card } from '../../Card';
import { CustomButton } from '../../styles';
import { RequireVideo } from './RequireVideo';
import { RequireEvaluator } from './RequireEvaluator';
import { useCustomReportManagerContext } from '../../context';
import { RequireWorkCondition } from './RequireWorkCondition';
import { RequireCharacteristics } from './RequireCharacteristics';

export function Settings() {
	const { handleNextStep } = useCustomReportManagerContext();
	return (
		<Row justify="space-between" style={{ display: 'flex', height: '100%' }} gutter={[0, { sm: 10 }]}>
			<Col span={24}>
				<Card title="Settings">
					<Row gutter={[0, 20]}>
						<RequireVideo />
						<RequireWorkCondition />
						<RequireCharacteristics />
						<RequireEvaluator />
					</Row>
				</Card>
			</Col>
			<Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
				<CustomButton type="primary" onClick={handleNextStep} style={{ textAlign: 'center' }}>
					{I18n.get('Forward')}
				</CustomButton>
			</Col>
		</Row>
	);
}
