import React from 'react';
import { Button } from 'antd';
import { I18n } from '@aws-amplify/core';

interface FooterProps {
	isFirstStep: boolean;
	onClick: () => void;
}

export function Footer({ isFirstStep, onClick }: Readonly<FooterProps>) {
	return <>{isFirstStep && <Button onClick={onClick}>{I18n.get('Previous')}</Button>}</>;
}
