import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';
import moment from 'moment';

import { Topic, Value } from './styles';
import { Title } from '@/components/Typography';
import { usePersonalisedReportResultContext } from '../../../context';
import { usePersonalisedReportContext } from '@/components/views/PersonalisedReports/PersonalisedReport/context';

export const Summary: React.FC = () => {
	const { personalisedReport } = usePersonalisedReportContext();
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;

	if (!basic_information) {
		return null;
	}

	return (
		<Col span={12} offset={1}>
			<Row>
				<Title level={3}>Company</Title>
			</Row>
			<Row align="middle" style={{ height: '224px' }}>
				<Row align="top" style={{ width: '100%' }}>
					<Col span={12}>
						<Row>
							<Topic>{I18n.get('Company')}:</Topic>
							<Value>{basic_information.company_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Sector')}:</Topic>
							<Value>{basic_information.sector_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Cellule')}:</Topic>
							<Value>{!basic_information.cellule_name ? 'N/A' : basic_information.cellule_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Workstation')}:</Topic>
							<Value>{basic_information.workstation_name}</Value>
						</Row>
						{personalisedReport.has_evaluator && (
							<Row>
								<Topic>{I18n.get('Analyst')}:</Topic>
								<Value>
									{!basic_information.evaluator_name ? 'N/A' : basic_information.evaluator_name}
								</Value>
							</Row>
						)}
					</Col>
					<Col span={11}>
						<Row>
							<Topic>{I18n.get('File')}:</Topic>
							<Value>{basic_information.file_original_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Date')}:</Topic>
							<Value>
								{basic_information.collection_date &&
									moment(basic_information.collection_date).format('L')}
							</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Job')}:</Topic>
							<Value>{basic_information.activity_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Function')}:</Topic>
							<Value>{basic_information.role_name}</Value>
						</Row>
					</Col>
				</Row>
			</Row>
		</Col>
	);
};
