import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Label } from './styles';
import { useCompanies } from '@/hooks/v1';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Companies() {
	const form = useFormInstance();

	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);

	const title = I18n.get('Industrial site');
	const fieldName = HierarchiesField.COMPANY;

	const {
		data: companies,
		isFetching: isGettingCompanies,
		isError: errorGettingCompanies
	} = useCompanies(organizationId);

	function handleSelectCompany(id: string) {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			form.resetFields([...fieldsToReset, 'evaluator_id']);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearCompany() {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			form.resetFields([...fieldsToReset, 'evaluator_id']);
		}
	}

	return (
		<Form.Item name={fieldName} label={<Label>{title}</Label>}>
			<SelectWithCreation
				showSearch
				allowClear
				value={companyId}
				placeholder={title}
				selectOptions={companies}
				loading={isGettingCompanies}
				onClear={handleClearCompany}
				onChange={handleSelectCompany}
				disabled={!organizationId || !companies || errorGettingCompanies}
			/>
		</Form.Item>
	);
}
