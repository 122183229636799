import Api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { QUERY_KEYS } from '@/utils/query-keys';

const { GET_ORGANIZATIONS_CONTRACT_PLANS } = QUERY_KEYS;

type DeleteOrganizationResponse = {
	id: string;
	name: string;
	createdAt: Date;
	organization_id: string;
};

type DeleteOrganizationParams = {
	organization_id: string;
	company_id?: string;
};

type OrganizationResponse = {
	id: string;
	name: string;
	createdAt: Date;
	organization_id: string;
};

async function deleteOrganization({ organization_id }: any) {
	const url = `/organization/${organization_id}`;
	const { data } = await Api.delete<OrganizationResponse>(url);
	return data;
}

export const useDeleteOrganization = () => {
	const queryClient = useQueryClient();
	return useMutation<DeleteOrganizationResponse, AxiosError, DeleteOrganizationParams>(
		(params: DeleteOrganizationParams) => deleteOrganization(params),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message || err.response.statusText));
			},
			onSuccess: () => {
				queryClient.invalidateQueries([GET_ORGANIZATIONS_CONTRACT_PLANS]);
				message.success(I18n.get('Organization deleted successfully'));
			}
		}
	);
};
