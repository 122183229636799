import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const WorkstationCard = styled.div`
	border: 1px solid #d9d9d9;
	background-color: white;
	padding: 1rem 1.2rem;
	border-radius: 0.5rem;
	margin: 0 1rem 1rem 0;
`;

export const WorkstationTitle = styled.span`
	font-size: 0.76rem;
	font-weight: 400;
`;

export const WorkstationScoreValue = styled.span`
	font-weight: 700;
	font-size: 1rem;
`;

export const LinkCustom = styled(Link)`
	font-size: 0.76rem;
	font-weight: 400;
	cursor: pointer;
	color: black;

	:hover {
		text-decoration: underline;
	}
`;
