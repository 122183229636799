import { I18n } from '@aws-amplify/core';
import { Row, Divider } from 'antd';
import React from 'react';

import Can from '@/components/Can';

import { Generate2FAModal } from './TwoFactorAuthentication/Generate2FAModal';
import { TwoFactorAuthentication } from './TwoFactorAuthentication';
import { useTFAContext } from './TwoFactorAuthentication/context';
import { Section, Subsection, TitleSection } from '../styles';
import { ChangePassword } from './ChangePassword';
import { ForceTwoFA } from './ForceTwoFA';

export const Security: React.FC = () => {
	const hasPermission = Can('settings', 'force_2fa');
	const { user2FA } = useTFAContext();

	return (
		<Section>
			<TitleSection>{I18n.get('Security')}</TitleSection>
			<Row>
				<Subsection>
					<ChangePassword />
				</Subsection>
				<Divider style={{ margin: '25px 0' }} />
				<Subsection>
					<TwoFactorAuthentication />
					<Generate2FAModal />
				</Subsection>
				{hasPermission && user2FA.validated && (
					<>
						<Divider style={{ margin: '25px 0' }} />
						<Subsection>
							<ForceTwoFA />
						</Subsection>
					</>
				)}
			</Row>
		</Section>
	);
};
