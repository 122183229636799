import styled from 'styled-components';
import { Form, Skeleton } from 'antd';

const VIDEO_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-black.svg';

const { Item } = Form;

type CustomFormItemProps = {
	fontSize?: string;
};

export const CustomFormInputs = styled(Item)<CustomFormItemProps>`
	label {
		font-weight: bold;
		font-size: ${({ fontSize }) => fontSize ?? '1rem'};
	}

	height: 2.25rem;
	@media (min-width: 1200px) {
		height: 3rem;
	}
	.ant-form-item-explain-error {
		position: absolute;
		white-space: nowrap;
	}

	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
		&::before {
			display: none;
		}
	}
`;

export const CustomFormItem = styled(Item)<CustomFormItemProps>`
	label {
		font-weight: bold;
		font-size: ${({ fontSize }) => fontSize ?? ''};
	}
	.ant-form-item-explain-error {
		position: absolute;
		margin-top: 3px;
		width: 40vw;
	}
	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}
`;

export const CustomImageSkeleton = styled(Skeleton.Image)`
	.ant-skeleton-image {
		background-image: url(${VIDEO_ICON});
		background-blend-mode: lighten;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 40%;
		padding: 5rem;
		opacity: 0.6;

		svg {
			display: none;
		}
	}
`;
