import styled from 'styled-components';

type UploadContainerProps = {
	noPicture?: boolean;
	url?: string;
	isCompany?: boolean;
};

export const Container = styled.div`
	border: 1px #c4c4c4 solid;
	border-radius: 50%;
	width: 126px;
	height: 126px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const UploadContainer = styled.div<UploadContainerProps>`
	border: 1px #c4c4c4 solid;
	border-radius: 50%;
	width: 118px;
	height: 118px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(${({ url }) => url});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	img {
		width: 110px;
		border-radius: 50%;
		border: 1px #c4c4c4 solid;
	}
	.anticon-edit {
		position: relative;
		right: 0px;
		top: 0px;
		width: 110px;
		height: 110px;
		color: rgba(0, 0, 0, 0);
		${({ isCompany }) => isCompany && 'display:none'}
	}

	${({ noPicture }) => (noPicture ? '.anticon-edit:hover' : '.anticon-edit')} {
		${({ isCompany }) => isCompany && 'display:none'}
		background-color: #c6c6c6;
		border-radius: 50%;
		opacity: 0.8;
		width: 118px;
		height: 118px;
		color: #262626;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 33px;
			height: 33px;
		}
	}
`;
