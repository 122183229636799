import React from 'react';
import { Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { LeftOutlined } from '@ant-design/icons';

import { CustomCol } from '../styles';
import { CustomBackButton } from './styles';
import { Title } from '@/components/Typography';
import { useCustomReportManagerContext } from '../context';

export const StepTitle: React.FC = () => {
	const { handlePreviousStep } = useCustomReportManagerContext();

	return (
		<CustomCol xs={24}>
			<Title level={4}>New report</Title>
			<Row justify="start">
				<CustomBackButton type="link" onClick={handlePreviousStep} icon={<LeftOutlined />}>
					{I18n.get('Back')}
				</CustomBackButton>
			</Row>
		</CustomCol>
	);
};
