import React, { ReactNode } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Divider, Input, Modal, Select, Button } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useGetPlan } from '@/hooks/useGetPlan';
import { useManageOrganization } from '../../../context';
import { useApplicationContext } from '@/context/Application';
import type { CompanyDTO, DeleteRequest } from '../../../types';
import { Create, SelectId } from '../styles';

const { useFormInstance } = Form;

type OptionType = {
	label: string;
	value: string;
};

interface SelectCompanyProps {
	companies: CompanyDTO[];
	companyName: string;
	onCompanyNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
}

export function SelectCompany({ companies, companyName, onCompanyNameChange, onEditing }: SelectCompanyProps) {
	const { resetFields, setFieldValue } = useFormInstance();
	const {
		organizationId,
		companyId,
		updatingOrganizationData,
		creatingOrganizationData,
		isEditing,
		handleCompanyId,
		handleCompany,
		deleteOrganizationData,
		createOrganizationData,
		handleIsEditing,
		organizationRefetch,
		setIsLoading
	} = useManageOrganization();

	const { data } = useGetPlan({ organization_id: organizationId, company_id: companyId });

	const { setCompany: setCompanyContext } = useApplicationContext();

	async function handleCreateCompany() {
		if (companies.find((elem) => elem.name === companyName)) {
			const content = I18n.get(`You will create a new company with same name. Do you wish to proceed ?`);
			Modal.confirm({
				title: I18n.get('Warning!'),
				icon: <ExclamationCircleOutlined />,
				content: content,
				okType: 'danger',
				okText: I18n.get('Yes'),
				cancelText: I18n.get('Cancel'),
				onOk: async () => {
					const created = await createOrganizationData({
						organization_id: organizationId,
						company_name: companyName
					});

					onCompanyNameChange('');
					handleCompany(created.id, resetFields, setFieldValue);
				}
			});
		} else {
			const created = await createOrganizationData({
				organization_id: organizationId,
				company_name: companyName
			});

			onCompanyNameChange('');
			handleCompany(created.id, resetFields, setFieldValue);
		}
	}

	function handleEditCompany(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const editingCompany = companies.find((company) => company.id === companyId);

		if (editingCompany) {
			setFieldValue('edit_company_name', editingCompany.name);
			onCompanyNameChange(editingCompany.name);
			handleIsEditing(true);
			onEditing(true);
		}
	}

	async function handleDeleteCompany(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const body: DeleteRequest = {
			organization_id: organizationId,
			company_id: companyId
		};

		const content = I18n.get(
			`All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?`
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				checkDeleteCompany(body, companyId);
			}
		});
	}

	async function checkDeleteCompany(body: any, companyId: string) {
		const deleted = await deleteOrganizationData(body);
		onCompanyNameChange('');
		handleCompanyId('');
		resetFields();
		if (companyId === body.company_id) {
			setIsLoading(true);
			organizationRefetch().then(({ data }) => {
				if (data) {
					setCompanyContext(data[0].companies[0]);
					setIsLoading(false);
					window.location.reload();
				}
			});
		}
		return deleted;
	}

	function filterCompany(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectCompany(id: string) {
		handleCompany(id, resetFields, setFieldValue);
	}

	function manageCompanyDropdown(menu: ReactNode) {
		return (
			<Col span={24} style={{ margin: '0px 15px', paddingTop: '15px' }}>
				{menu}
				<Divider style={{ margin: '15px 0' }} />
				<Create>
					<Form.Item name="create_company_name" style={{ margin: '10px 0px' }}>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px'
							}}
							placeholder={I18n.get('Create a new industrial site')}
							value={companyName}
							onChange={(e) => onCompanyNameChange(e.target.value)}
						/>
					</Form.Item>
					<Button
						style={{ marginBottom: '10px' }}
						onClick={handleCreateCompany}
						type="link"
						loading={creatingOrganizationData}
						disabled={!companyName || companyName.length < 3 || data?.special_plan}
					>
						{I18n.get('Create industrial site')}
					</Button>
				</Create>
			</Col>
		);
	}

	const companiesList = companies.map(({ id, name }) => ({
		label: name,
		value: id
	}));

	const actions = (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Col span={12}>
					<Button
						ghost
						size="middle"
						type="primary"
						icon={<EditOutlined />}
						onClick={handleEditCompany}
						disabled={!companyId || isEditing || data?.special_plan}
					/>
				</Col>
				<Col span={12}>
					<Button
						ghost
						danger
						size="middle"
						type="primary"
						icon={<DeleteOutlined />}
						onClick={handleDeleteCompany}
						disabled={!companyId || isEditing || data?.special_plan}
					/>
				</Col>
			</Row>
		</Col>
	);

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<SelectId
						name="company_id"
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						selected={!!companyId || isEditing}
						label={I18n.get('Select an industrial site')}
						rules={[
							{
								message: I18n.get('Please, select an industrial site')
							}
						]}
					>
						<Select
							showSearch
							allowClear
							onClear={() => handleCompanyId('')}
							options={companiesList}
							loading={updatingOrganizationData}
							filterOption={(input, option) => filterCompany(option, input)}
							onChange={(value: string) => handleSelectCompany(value)}
							placeholder={I18n.get('Select an industrial site')}
							dropdownRender={(menu: ReactNode) => manageCompanyDropdown(menu)}
						/>
					</SelectId>
				</Col>
				{actions}
			</Row>
		</Col>
	);
}
