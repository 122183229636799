import React from 'react';
import { Row, Col } from 'antd';
import { Header } from './Header';
import { Filter } from './Filter';
import { OrganizationsList } from './List';
import { ModalLocation } from './ModalLocation';
import { ContractPlansProvider } from './context';

export function ContractPlans() {
	return (
		<ContractPlansProvider>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Header />
				</Col>
				<Col span={24}>
					<Filter />
				</Col>
				<Col span={24}>
					<OrganizationsList />
				</Col>
				<Col>
					<ModalLocation />
				</Col>
			</Row>
		</ContractPlansProvider>
	);
}
