import styled from 'styled-components';
import { Button } from 'antd';

export const CustomButton = styled(Button)`
	width: max-content;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.2rem;
	font-size: 1rem;
	height: 40px;

	span {
		font-weight: 600;
	}
`;

export const DownloadButton = styled(CustomButton)`
	color: #2f54eb;
	border: 1px solid #2f54eb;
	height: 40px;
	font-size: 1rem;

	&:hover {
		color: #2f54eb;
		border: 1px solid #2f54eb;
	}
`;

interface ConsolidationButtonProps {
	$freeze?: boolean;
}

export const ConsolidationButton = styled(CustomButton)<ConsolidationButtonProps>`
	color: ${({ $freeze }) => ($freeze ? '#828282' : '#17a93b')};
	border: 1px solid ${({ $freeze }) => ($freeze ? '#828282' : '#17a93b')};
	height: 40px;
	font-size: 1rem;

	&:hover {
		color: ${({ $freeze }) => ($freeze ? '#828282' : '#17a93b')};
		border: 1px solid ${({ $freeze }) => ($freeze ? '#828282' : '#17a93b')};
	}

	&:focus {
		color: ${({ $freeze }) => ($freeze ? '#828282' : '#17a93b')};
		border: 1px solid ${({ $freeze }) => ($freeze ? '#828282' : '#17a93b')};
	}
`;
