import React from 'react';
import { Col, Divider, Row } from 'antd';

import { Container } from './styles';
import { FirstRow } from './FirstRow';
import { SecondRow } from './SecondRow';
import { IKimManualHandling } from './types';
import { Title } from '@/components/Typography';
interface KimManualHandlingProps {
	data: IKimManualHandling;
}

export const KimManualHandling: React.FC<KimManualHandlingProps> = ({ data }) => {
	const parameters = {
		duration: data.duration,
		risk_load: data.risk_load,
		risk_score: data.risk_score,
		force_transfer: data.force_transfer,
		left_force_intensity: data.left_force_intensity,
		right_force_intensity: data.right_force_intensity
	};

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={3}>
						Manual Handling (KIM)
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row>
					<Container>
						<Col span={24}>
							<FirstRow parameters={parameters} />
						</Col>
						<Divider style={{ margin: 0 }} />
						<Col span={24}>
							<SecondRow parameters={parameters} />
						</Col>
					</Container>
				</Row>
			</Col>
		</Row>
	);
};
