import React, { useContext } from 'react';

import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import { MergeAEPContext } from '../../context';

export const FileName: React.FC = () => {
	const { organizationId, companyId, setFileName, queryParams, setQueryParams } = useContext(MergeAEPContext);

	function handleFileNameChange(fileName: string) {
		setFileName(fileName);
		setQueryParams({
			...queryParams,
			fileName
		});
	}

	return (
		<Form.Item name="fileName">
			<Input
				disabled={!organizationId || !companyId}
				placeholder={I18n.get('File Name')}
				onChange={(event) => handleFileNameChange(event.target.value)}
			/>
		</Form.Item>
	);
};
