import Types from './types';

const initState = {
	changePassword: {
		loading: false,
		data: null,
		error: null
	},
	expirationPlan: {
		expired: false,
		visible: false
	}
};

const userReducer = (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Types.EXPIRATION_PLAN_NOTIFICATION: {
			return {
				...state,
				expirationPlan: {
					...state.expirationPlan,
					...action.payload
				}
			};
		}

		case Types.SET_CHANGE_PASSWORD_LOADING: {
			return {
				...state,
				changePassword: {
					...state.changePassword,
					loading: !state.changePassword.loading
				}
			};
		}

		case Types.SET_CHANGE_PASSWORD_SUCCESS: {
			return {
				...state,
				changePassword: {
					...state.changePassword,
					data: payload
				}
			};
		}

		case Types.SET_CHANGE_PASSWORD_ERROR: {
			return {
				...state,
				changePassword: {
					...state.changePassword,
					error: payload
				}
			};
		}

		case Types.CLEAR_STATE_USER: {
			return initState;
		}

		default:
			return state;
	}
};

export default userReducer;
