import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useEvaluators } from '@/hooks';
import { FormItem } from '../../../components/FormItem';
import { EvaluatorSelect } from '@/components/ui/Inputs/EvaluatorSelect';
import { usePersonalisedReportContext } from '../../../../context';

const { useFormInstance, useWatch } = Form;

export const Evaluators: React.FC = () => {
	const form = useFormInstance();
	const { organization_id, company_id } = usePersonalisedReportContext();

	const fieldName = ['basic_information', 'evaluator_id'];
	const companyId = useWatch(['basic_information', 'company_id'], form);
	const evaluatorId = useWatch(fieldName, form);

	const {
		data: evaluators,
		isLoading,
		isFetching,
		isError
	} = useEvaluators({
		organization_id,
		company_id
	});

	function handleEvaluatorSelect(id: string): void {
		form.setFieldValue(fieldName, id);
	}

	function handleClearEvaluator(): void {
		form.setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				fontSize="1rem"
				title="Evaluator"
				isRequired={true}
				namePath={fieldName}
				errorMessage={I18n.get('Select an evaluator')}
			>
				<EvaluatorSelect
					size="large"
					loading={isFetching}
					companyId={companyId}
					organizationId={organization_id}
					fieldName={fieldName}
					evaluators={evaluators}
					evaluatorId={evaluatorId}
					handleClearEvaluators={handleClearEvaluator}
					handleSelectEvaluator={handleEvaluatorSelect}
					disabled={isLoading || isFetching || isError}
				/>
			</FormItem>
		</Col>
	);
};
