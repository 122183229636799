import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { PieChart } from './Piechart';
import { Text } from '@/components/Typography';
import { DIAGNOSTIC_COLORS } from '@/utils/constants';
import { RebaBodyPartScores } from '../../../hooks';

interface ChartsProps {
	scores: RebaBodyPartScores[];
}

export const Chart: React.FC<ChartsProps> = ({ scores }) => (
	<Row align="middle" justify="space-between">
		<Col span={12}>
			<Row>
				{scores?.map(({ id, level, text, percentage }) => (
					<Col key={id} span={24}>
						<span style={{ fontSize: '12px' }}>
							{I18n.get(text)}:{' '}
							<Text strong style={{ color: DIAGNOSTIC_COLORS[level] }}>
								{percentage}%
							</Text>
						</span>
					</Col>
				))}
			</Row>
		</Col>
		<Col span={8}>
			<PieChart scores={scores?.map(({ percentage }) => percentage ?? 0)} />
		</Col>
	</Row>
);
