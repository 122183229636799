import React from 'react';
import { I18n } from '@aws-amplify/core';

import { SelectFieldProps } from '../types';
import { CustomFormItem } from '../../styles';
import { CustomSelect } from '@/components/Settings/Account/Plan/DetailsModal/Form/styles';

export function Plan({ name, options = [], required = false }: Readonly<SelectFieldProps>) {
	return (
		<CustomFormItem
			name={name}
			labelCol={{ span: 24 }}
			label={I18n.get('Plan')}
			rules={[{ required, message: I18n.get('Enter a plan') }]}
		>
			<CustomSelect placeholder={I18n.get('Plan')} options={options} />
		</CustomFormItem>
	);
}
