import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Response } from '@/types';
import { customReportService } from '../../../../hooks/services';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type DeleteActionPlanDTO = BaseContext & {
	id?: string;
};

export type PersonalisedReportDeletedActionPlan = {
	status: string;
	message: string;
	data: {
		action_plan_id: string;
	};
};

async function deleteActionPlan(payload: DeleteActionPlanDTO): Promise<PersonalisedReportDeletedActionPlan> {
	const url = `/action_plan/${payload.id}`;
	const { data } = await Api.delete<PersonalisedReportDeletedActionPlan>(url, {
		params: payload
	});
	return data;
}

export function useDeleteActionPlan() {
	const queryClient = useQueryClient();
	return useMutation<PersonalisedReportDeletedActionPlan, AxiosError<Response>, DeleteActionPlanDTO>(
		(body) => deleteActionPlan(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to delete action plan';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Action plan deleted successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report_action_plan']);
			}
		}
	);
}
