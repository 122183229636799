import { HierarchiesField } from './enum';

interface HierarchyHandlerInterface {
	getHierarchiesFieldsToClear(currentHierarchy: HierarchiesField): HierarchiesField[] | undefined;
}

export class HierarchyHandler implements HierarchyHandlerInterface {
	private hierarchiesFieldsArray = [
		HierarchiesField.ORGANIZATION,
		HierarchiesField.COMPANY,
		HierarchiesField.SECTOR,
		HierarchiesField.CELLULE,
		HierarchiesField.WORKSTATION,
		HierarchiesField.ACTIVITY
	];

	public getHierarchiesFieldsToClear(currentHierarchy: HierarchiesField): HierarchiesField[] | undefined {
		const index = this.hierarchiesFieldsArray.indexOf(currentHierarchy);
		if (index < 0) {
			return;
		}
		return this.hierarchiesFieldsArray.slice(index);
	}
}

export const hierarchyHandler = new HierarchyHandler();
