import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useUsersByCustomer } from '@/hooks/v1/useCustomerInformationUsers';
import { useApplicationContext } from '@/context/Application';
import { UserSelect } from '@/components/ui/Inputs/UserSelect';

const { useFormInstance, useWatch } = Form;

export const CreatedByUserId: React.FC = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const infoUser = localStorage.getItem('info_user') ?? '';
	const user = JSON.parse(infoUser)?.user;

	const { customer_id: customer_information_id } = user;
	const userId = useWatch('created_by_user_id', form);

	const {
		data: users,
		isLoading,
		isFetching,
		isError
	} = useUsersByCustomer({
		organization_id: organization?.id,
		company_id: company?.id,
		customer_information_id
	});

	function handleUserSelect(id: string): void {
		form.setFieldValue(['created_by_user_id'], id);
	}

	function handleClearUsers(): void {
		form.setFieldValue(['created_by_user_id'], undefined);
	}

	return (
		<Col sm={6}>
			<Row>
				<Col xs={24}>
					<Form.Item name={['created_by_user_id']} label={I18n.get('User')} labelCol={{ span: 24 }}>
						<UserSelect
							users={users}
							userId={userId}
							loading={isFetching}
							fieldName="created_by_user_id"
							handleSelectUser={handleUserSelect}
							handleClearUsers={handleClearUsers}
							disabled={isLoading || isFetching || isError}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
