import React from 'react';
import { Col, Row } from 'antd';

import { StepKeyCard } from './StepKeyCard';
import { Text } from '@/components/Typography';
import { StepList } from '../../../context/types';
import { useCustomReportManagerContext } from '../../../../context';
import { StepCardCol, StepKeyColContainer, StepRowContainer } from './styles';

interface StepCardProps {
	step: StepList;
}

export const StepCard: React.FC<StepCardProps> = ({ step }) => {
	const { selectedStepKeys } = useCustomReportManagerContext();

	const { id, description } = step;

	return (
		<Col key={id} xs={24}>
			<StepRowContainer gutter={[16, 0]}>
				<StepCardCol xs={24}>
					<Row justify="center">
						<Text>{description}</Text>
					</Row>
				</StepCardCol>
				<Col xs={24}>
					<Row>
						<StepKeyColContainer xs={24}>
							{selectedStepKeys?.[id]?.map((stepKey, _index, stepKeys) => (
								<StepKeyCard key={stepKey.id} stepId={id} stepKey={stepKey} stepKeys={stepKeys} />
							))}
						</StepKeyColContainer>
					</Row>
				</Col>
			</StepRowContainer>
		</Col>
	);
};
