import { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';

import {
	Reba,
	Niosh,
	RoomKey,
	AngleTime,
	KimPushPull,
	StrainIndex,
	LibertyMutual,
	SOCKET_EVENTS,
	KimManualHandling,
	BaseContext
} from '@/types';
import { socketConfig, getRoom } from '@/utils/socket';
import { useApplicationContext } from '@/context/Application';

export type NioshResponse = Pick<Niosh, 'id' | 'activity_id' | 'file_id' | 'lifting_index' | 'updatedAt'>;

export type StrainIndexResponse = Pick<
	StrainIndex,
	'id' | 'file_id' | 'activity_id' | 'updatedAt' | 'score_left_rsi' | 'score_right_rsi'
>;

export type KimMhoResponse = Pick<KimManualHandling, 'id' | 'file_id' | 'activity_id' | 'risk_load' | 'updatedAt' | 'risk_score'>;

export type KimPpResponse = Pick<KimPushPull, 'id' | 'file_id' | 'activity_id' | 'mass' | 'score' | 'updatedAt'>;

export type RebaResponse = Pick<Reba, 'id' | 'file_id' | 'activity_id' | 'force' | 'coupling' | 'score_seconds' | 'repetition'>;

export type AngleTimeResponse = Pick<AngleTime, 'id' | 'file_id' | 'activity_id' | 'range_risk_id'>;

export type LibertyMutualResponse = Pick<
	LibertyMutual,
	| 'id'
	| 'file_id'
	| 'activity_id'
	| 'percentile_man'
	| 'percentile_woman'
	| 'percentile_man_initial'
	| 'percentile_man_sustain'
	| 'percentile_woman_initial'
	| 'percentile_woman_sustain'
>;

export type ErgonomicToolReportResponse =
	| RebaResponse
	| NioshResponse
	| KimPpResponse
	| KimMhoResponse
	| AngleTimeResponse
	| StrainIndexResponse
	| LibertyMutualResponse;

export type ReportEvent = {
	status?: boolean;
	data?: ErgonomicToolReportResponse;
};

export enum Tools {
	rula = 'rula',
	reba = 'reba',
	niosh = 'niosh',
	kimMHO = 'kimMHO',
	angleTime = 'angleTime',
	strainIndex = 'strainIndex',
	kimPushPull = 'kimPushPull',
	libertyMutual = 'libertyMutual'
}

export type IReportState = {
	[key in Tools]: ReportEvent;
};

interface SocketReportParams extends BaseContext { }

const useSocketReport = (params?: SocketReportParams): IReportState => {
	const socketRef = useRef<any>();
	const { organization, company } = useApplicationContext();
	const { company_id = company?.id, organization_id = organization?.id } = params ?? {};

	const [state, setState] = useState<IReportState>({
		rula: {},
		reba: {},
		niosh: {},
		kimMHO: {},
		angleTime: {},
		strainIndex: {},
		kimPushPull: {},
		libertyMutual: {}
	});

	function setNiosh(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, niosh: msg }));
	}

	function setKimPushPull(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, kimPushPull: msg }));
	}

	function setKimMHO(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, kimMHO: msg }));
	}

	function setStrainIndex(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, strainIndex: msg }));
	}

	function setAngleTime(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, angleTime: msg }));
	}

	function setReba(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, reba: msg }));
	}

	function setLibertyMutual(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, libertyMutual: msg }));
	}

	useEffect(() => {
		if (company_id && organization_id) {
			const room = getRoom(RoomKey.REPORT_STATUS);
			const config = socketConfig(organization_id, company_id);

			socketRef.current = io(room, config);
			socketRef.current.on(SOCKET_EVENTS.REBA_REPORT, setReba);
			socketRef.current.on(SOCKET_EVENTS.NIOSH_REPORT, setNiosh);
			socketRef.current.on(SOCKET_EVENTS.KIM_MHO_REPORT, setKimMHO);
			socketRef.current.on(SOCKET_EVENTS.KIM_PP_REPORT, setKimPushPull);
			socketRef.current.on(SOCKET_EVENTS.ANGLE_TIME_REPORT, setAngleTime);
			socketRef.current.on(SOCKET_EVENTS.STRAIN_INDEX_REPORT, setStrainIndex);
			socketRef.current.on(SOCKET_EVENTS.LIBERTY_MUTUAL_REPORT, setLibertyMutual);
		}
		return () => socketRef.current?.disconnect();
	}, [company_id, organization_id, state]);

	return { ...state };
};

const useSocketFile = () => {
	const socketRef = useRef<any>();
	const [file, setFile] = useState<any>(null);
	const { organization, company } = useApplicationContext();

	function updateFile(file: any) {
		setFile(file);
	}

	useEffect(() => {
		if (organization && company) {
			const room = getRoom(RoomKey.FILE_STATUS);
			const config = socketConfig(organization.id, company.id);

			socketRef.current = io(room, config);
			socketRef.current.on(SOCKET_EVENTS.UPDATE_FILE_STATUS, updateFile);
		}
		return () => socketRef.current?.disconnect();
	}, [organization, company]);

	return { file };
};

export { useSocketFile, useSocketReport };
