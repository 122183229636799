import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Description } from './styles';

export const NotAnalyzedDescription: React.FC = () => {
	return (
		<Row align={'middle'} style={{ marginBottom: '1rem' }}>
			<Col span={5}>
				<Description>{I18n.get('Not analysed')}</Description>
			</Col>
		</Row>
	);
};
