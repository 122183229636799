import { Col } from 'antd';
import styled from 'styled-components';
import { Text, Title } from '@/components/Typography';

export const CustomCol = styled(Col)`
	margin: 0 0 1rem 1rem;
	overflow-wrap: break-word;
`;

export const SectionTitle = styled(Title)`
	margin-bottom: 0 !important;
`;

export const CustomTitle = styled(Text)`
	font-weight: 600;
	font-size: 0.9rem;
`;

export const Data = styled(Text)`
	font-weight: 400;
	font-size: 0.8rem;
`;
