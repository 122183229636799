import React from 'react';
import { Col, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomFormItem } from '../../styles';

export function Description() {
	return (
		<Col span={24}>
			<CustomFormItem
				name={['description']}
				labelCol={{ span: 24 }}
				label={I18n.get('Report name')}
				rules={[
					{
						message: I18n.get('Insert a report name'),
						required: true
					}
				]}
			>
				<Input placeholder={I18n.get('Report name')} size="large" />
			</CustomFormItem>
		</Col>
	);
}
