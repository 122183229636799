import React from 'react';
import { Col, Row } from 'antd';

import { SelectedSteps } from './SelectedSteps';
import { ReportInformation } from './ReportInformation';
import { CustomReportManagerSummaryProvider } from './context';

export const Summary: React.FC = () => (
	<CustomReportManagerSummaryProvider>
		<Row justify="space-between" style={{ height: 'fit-content' }} gutter={[32, 32]}>
			<Col xs={24}>
				<SelectedSteps />
			</Col>
			<Col xs={24}>
				<ReportInformation />
			</Col>
		</Row>
	</CustomReportManagerSummaryProvider>
);
