import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getFiles({ organizationId, companyId, sectorId }) {
	if (!organizationId || !companyId || !sectorId) return [];
	const url = `/upload/${organizationId}/${companyId}/${sectorId}`;
	const { data } = await Api.get(url);
	return data;
}

export function useFiles(payload) {
	const enabled = !!payload.organizationId && !!payload.companyId && !!payload.sectorId;

	const files = useQuery(['files', payload], () => getFiles(payload), { enabled });
	return { files };
}
