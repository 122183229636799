import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';

import { Card } from '../../Card';
import { EmptyList } from './EmptyList';
import { StepKeyCard } from './StepKeyCard';
import { CustomButton } from '../../styles';
import { CustomColSelectedStepKeysList } from './styles';
import { useCustomReportManagerContext } from '../../context';
import { useCustomReportManagerStepKeyContext } from '../context';

const { useFormInstance } = Form;

export const SelectedStepKeysList: React.FC = () => {
	const form = useFormInstance();
	const { stepKeysList, changeStepKeyListSequence } = useCustomReportManagerStepKeyContext();
	const { selectedStepId, selectedSteps, handleShowSummaryClick, handleSelectStep } = useCustomReportManagerContext();

	function handleDragEnd(result: DropResult): void {
		const { destination, source } = result;

		if (!destination) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		changeStepKeyListSequence({
			list: stepKeysList,
			startIndex: source.index,
			endIndex: destination.index
		});
	}

	async function handleButtonClick(): Promise<void> {
		await form.validateFields();
		const currentSelectedStep = selectedSteps.find(({ id }) => id === selectedStepId);
		const workCondition = form.getFieldValue('has_work_conditions');
		const characteristics = form.getFieldValue('has_characteristics');
		let stepsToSubtract = 0;
		if (workCondition) {
			stepsToSubtract += 1;
		}
		if (characteristics) {
			stepsToSubtract += 1;
		}
		if (currentSelectedStep) {
			const nextSelectedStepSequence = currentSelectedStep.sequence + 1;
			const nextSelectedStep = selectedSteps.find(({ sequence }) => sequence === nextSelectedStepSequence);

			if (nextSelectedStep && isStepSequenceLessThanTotalSteps(nextSelectedStepSequence, stepsToSubtract)) {
				handleSelectStep(nextSelectedStep.id);
				const element = document.getElementById('true');
				element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				return;
			}
		}

		if (currentSelectedStep && currentSelectedStep.sequence - stepsToSubtract === selectedSteps.length) {
			handleShowSummaryClick();
		}
	}

	function isStepSequenceLessThanTotalSteps(nextSelectedStepSequence: number, stepsToSubtract: number): boolean {
		return nextSelectedStepSequence - stepsToSubtract <= selectedSteps.length;
	}

	if (!stepKeysList?.[selectedStepId] || stepKeysList[selectedStepId].length === 0) {
		return <EmptyList />;
	}

	return (
		<Row justify="center">
			<Col span={24}>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Card title="Selected criteria">
						<Row justify="center" gutter={[0, 20]}>
							<Col span={24}>
								<Droppable droppableId={stepKeysList[selectedStepId][0]?.id}>
									{({ droppableProps, innerRef, placeholder }) => (
										<CustomColSelectedStepKeysList xs={24} ref={innerRef} {...droppableProps}>
											{stepKeysList?.[selectedStepId]?.map((stepKey, index) => (
												<StepKeyCard key={stepKey.id} stepKey={stepKey} index={index} />
											))}
											{placeholder}
										</CustomColSelectedStepKeysList>
									)}
								</Droppable>
							</Col>
							<Col span={24} style={{ textAlign: 'center' }}>
								<CustomButton
									type="primary"
									onClick={handleButtonClick}
									disabled={stepKeysList[selectedStepId]?.length === 0}
								>
									{I18n.get('Forward')}
								</CustomButton>
							</Col>
						</Row>
					</Card>
				</DragDropContext>
			</Col>
		</Row>
	);
};
