import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Description } from './styles';

export const Header: React.FC = () => (
	<Row>
		<Col span={24}>
			<Description>
				<span>{I18n.get('Risk range')}</span>
			</Description>
		</Col>
	</Row>
);
