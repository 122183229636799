import styled from 'styled-components';
import { Col, Row } from 'antd';

export const Description = styled.label`
	margin: 2px;
	opacity: 0.7;
`;

export const Container = styled(Row)`
	padding: 2rem;
`;

export const Section = styled.div`
	height: 100%;
	padding: 46px 55px 42px 55px;
	border-radius: 20px;
	box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
	background-color: #ffffff;

	@media (max-width: 992px) {
		padding: 36px 35px;
	}
`;

export const TitleSection = styled.h4`
	margin-bottom: 40px;
	font-size: 25px;
	font-weight: 600;
`;

export const Subsection = styled(Col)`
	display: flex;
	flex-direction: column;
	width: 80%;

	a {
		font-size: 18px;
	}
`;

export const Field = styled.label`
	margin-bottom: 15px;
	line-height: 22px;
	font-size: 22px;
	font-weight: 400;
`;

export const Information = styled.span`
	line-height: 22px;
	font-size: 18px;
	color: gray;
`;
