import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { Response } from '@/types';

export type GetCustomReportDTO = BaseContext & {
	custom_report_id?: string;
};

type StepSetting = {
	id: string;
	sequence: number;
	custom_report_id: string;
	custom_report_step_id: string;
};

export type CustomReportResponse = {
	id: string;
	name: string;
	description: string;
	created_by_user_id: string;
	settings: {
		has_file: boolean;
		has_evaluator: boolean;
	},
	steps_settings: StepSetting[],
	created_at: Date;
	updated_at: Date;
	deleted_at: Date | null;
};

async function getCustomReport(params: GetCustomReportDTO): Promise<CustomReportResponse> {
	const url = '/custom-report/find-one/settings';
	const { data } = await Api.get<CustomReportResponse>(url, {
		params: {
			...params,
			report_id: params.custom_report_id
		}
	});
	return data;
}

export const useGetCustomReport = ({ organization_id, company_id, custom_report_id }: GetCustomReportDTO) => {
	const enabled = !!organization_id && !!company_id && !!custom_report_id;
	const customReport = useQuery<CustomReportResponse, AxiosError<Response>>(
		['custom_report', [organization_id, company_id, custom_report_id]],
		() => getCustomReport({ organization_id, company_id, custom_report_id }),
		{
			enabled,
			retry: 0
		}
	);
	return customReport;
};
