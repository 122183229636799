import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { customReportStepKeyService } from './services';
import { CreateCustomReportStepKeyRequest, GetCustomReportStepKeysRequest, GetErgonomicTools } from './types/request';

export const useGetCustomReportStepKeys = ({
	limit = 4,
	company_id,
	description,
	organization_id,
	custom_report_step_setting_id
}: GetCustomReportStepKeysRequest) => {
	const enabled = !!organization_id && !!company_id && !!limit;
	const customReportStepKeys = useInfiniteQuery(
		['custom_report_step_keys', [organization_id, company_id, description, limit, custom_report_step_setting_id]],
		async ({ pageParam = 0 }) =>
			customReportStepKeyService.fetchCustomReportStepKeys({
				limit,
				company_id,
				description,
				organization_id,
				offset: pageParam ?? 0,
				custom_report_step_setting_id
			}),
		{
			enabled,
			retry: 0,
			getNextPageParam: (lastPage) => {
				return lastPage.next_page;
			}
		}
	);
	return customReportStepKeys;
};

export const useGetErgonomicTools = ({ company_id, organization_id, hasTool }: GetErgonomicTools) => {
	const enabled = !!organization_id && !!company_id && !!hasTool;
	const ergonomicTools = useQuery(
		['ergonomic_tools', [organization_id, company_id]],
		async () =>
			customReportStepKeyService.fetchErgonomicTools({
				company_id,
				organization_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return ergonomicTools;
};

export const useCreateStepKey = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateCustomReportStepKeyRequest) => customReportStepKeyService.createStepKey(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(err?.response?.data?.message)
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['custom_report_step_keys']);
			message.success(I18n.get('Criterion created successfully'));
		}
	});
};
