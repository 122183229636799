import { Row, Typography, Button, Form } from 'antd';
import styled, { css } from 'styled-components';
import * as Icons from '@ant-design/icons';

import { PaletterMapper, RiskMap } from './types';

const { Item } = Form;
const { Title, Text } = Typography;

const paletteMap: PaletterMapper = {
	[RiskMap.LOW]: '#2CC852',
	[RiskMap.MODERATE]: '#FFDE31',
	[RiskMap.HIGH]: '#F78A38',
	[RiskMap.VERY_HIGH]: '#E74150',
	[RiskMap.EXTREME]: '#9B54E2'
};

const darkColor = '#262626';
const emptyColor = '#B3B3B3';

export const Container = styled(Row)<{ $levelRisk: number }>`
	background: ${({ $levelRisk }) => ($levelRisk ? paletteMap[$levelRisk] : emptyColor)};
	border-radius: 5px;
	padding: 5%;
`;

export const stylesIcon = css<{ $isModerate?: boolean }>`
	color: ${({ $isModerate }) => ($isModerate ? darkColor : 'white')};
	font-size: 20;
`;

export const styleModerate = css<{ $levelRisk: number }>`
	color: ${({ $levelRisk }) => ($levelRisk === RiskMap.MODERATE ? darkColor : 'white')} !important;
`;

export const CircleFilledIcon = styled(Icons.CheckCircleFilled)`
	${stylesIcon}
`;

export const ExclamationFilledIcon = styled(Icons.ExclamationCircleFilled)`
	${stylesIcon}
`;

export const WarningFilledIcon = styled(Icons.WarningFilled)`
	${stylesIcon}
`;

export const TitleCard = styled(Title)`
	${styleModerate}
`;

export const ResumeCard = styled(Text)`
	${styleModerate}
`;

export const ButtonCard = styled(Button)<{ $levelRisk: number }>`
	float: right;

	span {
		color: ${({ $levelRisk }) => ($levelRisk === RiskMap.MODERATE ? darkColor : paletteMap[$levelRisk])};
	}
`;

export const CustomFormItem = styled(Item)`
	width: 100%;
	margin-bottom: 0.75rem;
	.ant-form-item-label {
		font-weight: 600;
		overflow: visible;
	}
	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
		&::before {
			display: none;
		}
	}
	.ant-form-item-control-input-content {
		.ant-select-selector {
			border-radius: 3px;
		}
	}
`;

export const TitleContainer = styled.span`
	font-weight: 600;
`;

export const ScoreTitle = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
`;

export const Score = styled.span`
	color: #2f54eb;
	font-weight: 600;
	font-size: 0.9rem;
	margin-left: 0.25rem;
	border-radius: 0.25rem;
	padding: 0.05rem 0.5rem;
	border: 1px solid #2f54eb;
	background-color: #2f54eb0d;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const CustomButton = styled(Button)`
	padding: 0.25rem;
	margin-bottom: 0.5rem;
	width: 6rem;
	height: auto;
	font-weight: 600;
	font-size: 0.8rem;
	white-space: break-spaces;
`;
