import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Description } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

export const Header: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;
	const { niosh } = basic_information;
	if (!niosh) {
		return null;
	}

	const conclusionTexts = {
		VERY_HIGH: {
			text: [
				`The weight to be lifted`,
				` [${niosh.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${niosh.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${niosh.lifting_index}] `,
				`is more than 3.`
			],
			conclusion:
				' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.'
		},
		HIGH: {
			text: [
				`The weight to be lifted`,
				` [${niosh.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${niosh.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${niosh.lifting_index}] `,
				`is more than 2 and less than 3.`
			],
			conclusion:
				' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.'
		},
		MODERATE: {
			text: [
				`The weight to be lifted`,
				` [${niosh.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${niosh.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${niosh.lifting_index}] `,
				`is more than 1,5 and less than 2.`
			],
			conclusion: ' There is a moderate risk. These values indicate that this job is somewhat stressful.'
		},
		LOW: {
			text: [
				`The weight to be lifted`,
				` [${niosh.mass_m} kg] `,
				`is greater than the recommended weight limit (RWL)`,
				` [${niosh.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${niosh.lifting_index}] `,
				`is more than 1 and less than 1,5.`
			],
			conclusion:
				' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.'
		},
		VERY_LOW: {
			text: [
				`The weight to be lifted`,
				` [${niosh.mass_m} kg] `,
				`is less than the recommended weight limit (RWL)`,
				` [${niosh.recommended_weight_limit} kg].`,
				` The lifting index (LI)`,
				` [${niosh.lifting_index}] `,
				`is less than 1.`
			],
			conclusion:
				' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.'
		}
	};

	return (
		<Col span={24}>
			<Row>
				<Description>{conclusionTexts[niosh.risk].text.map((text) => I18n.get(text))}</Description>
			</Row>
		</Col>
	);
};
