import styled from 'styled-components';
import { Input, Col, Row } from 'antd';

export const CustomInput = styled(Input)`
	border-radius: 0.5rem;
	border: 2px solid #e9e9e9;
	background-color: #ffffffe6;
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	transition: all 0.2s;
	.ant-input-affix-wrapper ::placeholder {
		color: #20202055 !important;
		opacity: 1 !important;
	}
	:hover {
		border: 2px solid #e9e9e9 !important;
		padding: 0.5rem 1rem;
		background-color: #ffffffe6;
		filter: brightness(0.98);
	}
`;

export const CustomList = styled(Col)`
	overflow: hidden;
	white-space: nowrap;
	padding: 0.75rem 1rem;
	margin-bottom: 0.5rem;
	border-radius: 0.5rem;
	text-overflow: ellipsis;
	border: 2px solid #e9e9e9;

	transition: all 0.1s;
	:hover {
		color: #fff;
		cursor: pointer;
		filter: brightness(1.2);
		background-color: #2f54eb;

		button {
			color: #fff;
			cursor: pointer;
			filter: brightness(1.2);
			background-color: #2f54eb;
		}
	}
`;

export const CustomListLoading = styled(Col)`
	overflow: hidden;
	white-space: nowrap;
	padding: 0.75rem 1rem;
	margin-bottom: 0.5rem;
	border-radius: 0.5rem;
	text-overflow: ellipsis;
	border: 2px solid #e9e9e9;
`;

export const StepKeysListCol = styled(Col)`
	max-height: 150px;
	overflow-y: auto;

	::-webkit-scrollbar-track {
		border: none;
		padding: 2px 0;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 0.5rem;
		background-color: transparent;
		box-shadow: inset 0 0 6px #9c9c9c;
	}
`;

export const CustomRow = styled(Row)`
	padding: 0 0.5rem;
`;

export const StepKeyButton = styled.button`
	width: 100%;
	border: none;
	user-select: none;
	text-align: start;
	height: max-content;
	background-color: transparent !important;
`;
