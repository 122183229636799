import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { stressLevel } from './StressLevel';
import { StressLevel, StressLevelContainer } from './styles';

type SelectedStressLevel = {
	isActive: boolean;
	id: number;
};

type FilterByStressLevelProps = {
	onSelectStressLevel: (stressLevel: number) => void;
	clearFilter: boolean;
};

export const FilterByStressLevel: React.FC<FilterByStressLevelProps> = ({ onSelectStressLevel, clearFilter }) => {
	const [selectedStressLevel, setSelectedStressLevel] = useState<SelectedStressLevel[]>([
		{ id: 1, isActive: false },
		{ id: 2, isActive: false },
		{ id: 3, isActive: false },
		{ id: 4, isActive: false },
		{ id: 5, isActive: false }
	]);

	useEffect(() => {
		if (clearFilter) {
			setSelectedStressLevel([
				{ id: 1, isActive: false },
				{ id: 2, isActive: false },
				{ id: 3, isActive: false },
				{ id: 4, isActive: false },
				{ id: 5, isActive: false }
			]);
		}
	}, [clearFilter]);

	function checkIfIsActive(level: number) {
		return selectedStressLevel.filter((stress) => stress.id === level).some((stress) => stress.isActive);
	}

	function handleStressLevelClick(level: number) {
		const selected = selectedStressLevel.map((stress) => {
			if (stress.id === level) {
				stress.isActive = !stress.isActive;
			}
			return stress;
		});
		setSelectedStressLevel(selected);
		onSelectStressLevel(level);
	}

	return (
		<Row gutter={[16, 8]}>
			<Col span={24}>
				<span>{I18n.get('Risk Level')}</span>
			</Col>
			<Col span={20}>
				<Row gutter={[4, 4]}>
					{stressLevel.map((stress) => (
						<Col span={4} key={stress.id}>
							<StressLevelContainer
								isActive={checkIfIsActive(stress.level)}
								stressLevel={stress.level}
								onClick={() => handleStressLevelClick(stress.level)}
							>
								<div>{stress.img}</div>
								<StressLevel>{stress.level}</StressLevel>
							</StressLevelContainer>
						</Col>
					))}
				</Row>
			</Col>
		</Row>
	);
};
