import React from 'react';
import { PreviousCol } from '../Steps/styles';
import { Button } from 'antd';
import { I18n } from '@aws-amplify/core';

interface BackButtonProps {
	loading?: boolean;
	disabled: boolean;
	onPreviousClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ disabled, loading = false, onPreviousClick }) => {
	function handlePreviousClick(): void {
		onPreviousClick();
	}

	return (
		<PreviousCol>
			<Button type="default" onClick={handlePreviousClick} disabled={disabled} loading={loading}>
				{I18n.get('Previous')}
			</Button>
		</PreviousCol>
	);
};
