import { Collapse } from 'antd';
import styled from 'styled-components';

export const CollapseContainer = styled(Collapse)`
	border-radius: 10px;
	overflow: hidden;
	border: none;

	.ant-collapse-item {
		border: none;

		.ant-collapse-header {
			background-color: #f8f8f8;

			.ant-collapse-header-text {
				width: 100%;
			}
		}

		.ant-collapse-content {
			border: none;

			.ant-collapse-content-box {
				padding: 1.5rem 2rem 2rem 2rem;
			}
		}
	}

	.ant-collapse-item-active {
		border-radius: 0.5rem;
	}
`;
