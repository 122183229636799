import { Button, Col, Form, Image, Radio, Row } from 'antd';
import { Text, Title } from '@/components/Typography';
import styled from 'styled-components';

interface MethodRadioProps {
	disabled: boolean;
}

export const Generate2FAForm = styled(Form)`
	height: 100%;
`;

export const Header = styled(Row)`
	margin-top: 3.75rem;
	margin-bottom: 1rem;
	text-align: center;
`;

export const CustomTitle = styled(Title)`
	font-size: 2rem !important;
`;

export const Subtitle = styled(Row)`
	font-weight: 400;
	font-size: 1rem;
	margin-bottom: 0.5rem;
`;

export const MethodRadioGroup = styled(Radio.Group)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.ant-radio-wrapper-checked {
		color: #fff;
		background-color: #2f54eb;
		box-shadow: -4px -4px 4px 0px rgba(0, 0, 0, 0.08), 4px 4px 4px 0px rgba(0, 0, 0, 0.08);
	}
`;

export const MethodRadioButton = styled(Radio)<MethodRadioProps>`
	align-items: center;
	text-align: justify;
	align-items: center;
	width: 72%;
	padding: 0 2.2rem;
	height: 9rem;
	margin-bottom: 2rem;
	margin-right: 0;
	border: none;
	border-radius: 1rem;
	background-color: #f5f5f5;
	color: #262626;
	opacity: ${(props) => (props.disabled ? '0.5' : 'none')};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

	.info {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	.ant-radio {
		display: none;
	}
`;

export const MethodInformations = styled(Col)`
	width: 85%;
	line-height: 1rem;

	span {
		font-size: 0.8rem;
	}
`;

export const MethodIcon = styled.div`
	display: flex;
	align-items: center;
	font-size: 2.5rem;
	margin-right: 1.5rem;
`;

export const MethodTitle = styled.div`
	margin-bottom: 0.6rem;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

export const IconContainer = styled(Row)`
	margin: 2rem;
	font-size: 5.5rem;
	color: #2f54eb;
`;

export const WarningContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 1.5% 1.5%;
	margin-top: 1.5rem;
	font-size: 3rem;
	border-radius: 50%;
	color: #fff;
	background-color: #2f54eb;
`;

export const QRCode = styled(Image)`
	width: 80%;
	border-radius: 0.25rem;
`;

export const Tip = styled(Text)`
	text-align: center;
	font-size: 1rem;
	font-weight: 400;
`;

export const Footer = styled(Row)`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const ConfirmButton = styled(Button)`
	width: 15rem;
	height: 2.2rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.37rem;

	&:disabled {
		box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
	}
`;

export const CustomButton = styled(Button)`
	display: flex;
	align-items: center;
	padding: 1.1rem 1.2rem;
	border-radius: 0.2rem;
	font-size: 1rem;
`;
