import Api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';

type Plan = {
	recurrence: string;
	max_minutes: number;
	max_uploads: number;
	expiration_date: Date;
};

type BussinessInformation = {
	nit: string;
	city: string;
	address: string;
	district: string;
	contract_plan_id: string;
};

type UpdateCompanyParams = {
	plan?: Plan;
	company_name: string;
	organization_id: string;
	business_information?: BussinessInformation;
};

async function updateCompany(body: UpdateCompanyParams) {
	const url = '/contract-plan/company/';
	const { data } = await Api.put<any>(url, body);
	return data;
}

export const useUpdateCompany = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateCompanyParams) => updateCompany(body), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_COMPANIES_CONTRACT_PLANS]);
		}
	});
};
