import React from 'react';
import { Col, Row, Skeleton } from 'antd';

export function SkeletonList() {
	return (
		<Row gutter={[0, 12]}>
			<Col span={24}>
				<Skeleton.Input active block style={{ borderRadius: '0.5rem' }} />
			</Col>
			<Col span={24}>
				<Skeleton.Input active block style={{ borderRadius: '0.5rem' }} />
			</Col>
			<Col span={24}>
				<Skeleton.Input active block style={{ borderRadius: '0.5rem' }} />
			</Col>
		</Row>
	);
}
