import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DefaultOptionType } from 'antd/lib/select';
import { Col, Form, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ReactComponent as SafetyFilled } from '@/assets/icons/safety-filled.svg';
import { PrimaryButton } from '@/components/ui/Buttons/PrimaryButton';
import { PinCodeInput } from '@/components/ui/Inputs/PinCodeInput';
import { Text, Title } from '@/components/Typography';
import { Auth2FAParams } from '@/types/AuthUser';

import { Container, IconContainer } from './styles';
import { useReset2FA } from './hooks';

const { useForm } = Form;

export const Reset2FAContent: React.FC = () => {
	const [form] = useForm();
	const history = useHistory();
	const { user_id } = useParams<Auth2FAParams>();

	const { mutateAsync: reset2FA, isLoading: reset2FALoading } = useReset2FA();

	function checkFormErrors() {
		const fields_changed = form.isFieldsTouched(true);
		const form_errors = form.getFieldsError().some(({ errors }) => errors.length > 0);

		if (!fields_changed || form_errors) {
			return true;
		}

		return false;
	}

	async function handleConfirmClick() {
		await form.validateFields(['recovery_token', 'token_type']);

		const values = form.getFieldsValue();

		const reset = await reset2FA({
			user_id,
			data: { type: values.token_type, token: values.recovery_token }
		});

		if (reset.qr_code_url) {
			const encoded_qr_code = encodeURIComponent(reset.qr_code_url);
			history.push(`/generate-two-factor/${user_id}/${values.token_type}/${encoded_qr_code}`);
		}
	}

	const options: DefaultOptionType[] = [
		{
			value: 'AUTHENTICATOR',
			label: I18n.get('Authenticator app')
		}
	];

	return (
		<Container xs={18} sm={18} md={12} lg={10} xl={8} xxl={6}>
			<Row justify="center">
				<IconContainer>
					<SafetyFilled />
				</IconContainer>
			</Row>
			<Row justify="center" style={{ marginTop: '1.5rem', textAlign: 'center' }}>
				<Title level={4}>Two-factor authentication</Title>
			</Row>
			<Row justify="center" style={{ marginTop: '0.5rem' }}>
				<Col
					lg={22}
					xl={20}
					xxl={23}
					style={{ textAlign: 'center', fontSize: '0.95rem', lineHeight: '1.3rem' }}
				>
					<Text>
						Select a desired new method and enter one of the recovery tokens to perform the recovery
					</Text>
				</Col>
			</Row>
			<Form form={form} style={{ marginTop: '2rem' }}>
				<Row justify="center" style={{ marginTop: '2rem' }}>
					<Form.Item
						name="token_type"
						noStyle
						rules={[
							{
								required: true
							}
						]}
					>
						<Select
							placeholder={I18n.get('Authentication method')}
							options={options}
							style={{ width: '55%' }}
						/>
					</Form.Item>
				</Row>
				<Row>
					<Text style={{ marginTop: '2rem' }}>Recovery token</Text>
				</Row>
				<Row justify="center" align="middle" style={{ height: '3.5rem', marginTop: '0.5rem' }}>
					<Form.Item
						name="recovery_token"
						noStyle
						rules={[
							() => ({
								validator(_, value) {
									return value.length !== 6
										? Promise.reject(I18n.get('Enter the 6 digit code.'))
										: Promise.resolve();
								}
							})
						]}
					>
						<PinCodeInput allowedCharacters="numeric" />
					</Form.Item>
				</Row>

				<Row style={{ marginTop: '2rem' }}>
					<Form.Item noStyle shouldUpdate>
						{() => {
							const has_errors = checkFormErrors();

							return (
								<PrimaryButton
									loading={reset2FALoading}
									disabled={has_errors}
									onClick={handleConfirmClick}
									htmlType="submit"
								>
									{I18n.get('Confirm')}
								</PrimaryButton>
							);
						}}
					</Form.Item>
				</Row>
			</Form>
		</Container>
	);
};
