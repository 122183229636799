import { Button, Form, Input, Row } from 'antd';
import styled from 'styled-components';

import { Text } from '@/components/Typography';

const { Item } = Form;

export const ActionsForm = styled(Form)`
	height: 100%;
`;

export const ChangeDefaultContainer = styled(Row)`
	min-height: 40vh;
`;

export const EnableAuthContainer = styled(Row)`
	min-height: 40vh;
`;

export const EnableAuthDescription = styled.span`
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
`;

export const ResetTokenContainer = styled(Row)`
	min-height: 40vh;
`;

export const ResetTokenDescription = styled.span`
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
`;

export const RecoveryToken = styled(Item)`
	margin-top: 1rem;
	min-width: 10rem;
	text-align: center;

	.ant-form-item-explain-error {
		text-align: center;
	}
`;
export const RecoveryTokenInput = styled(Input)`
	text-align: center;
`;

export const DeactivateContainer = styled(Row)``;

export const WarningContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 1.5% 1.5%;
	margin-top: 2rem;
	font-size: 3rem;
	border-radius: 50%;
	color: #fff;
	background-color: #2f54eb;
`;

export const DeactivateTokenDescription = styled.span`
	font-size: 1.1rem;
	font-weight: 400;
	text-align: center;
`;

export const ValidateContainer = styled(Row)`
	width: 100%;
`;

export const CodeContainer = styled(Row)`
	.ant-image {
		display: flex;
		justify-content: center;
	}
`;

export const ResendEmail = styled(Text)`
	width: 50%;
	margin-top: 1.5rem;
	margin-bottom: 4.85rem;
	text-align: center;
	font-size: 1rem;
	color: #909090;
`;

export const LinkButton = styled.button`
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	color: #2f54eb;
	cursor: pointer;
`;

export const Countdown = styled.span`
	color: #2c2c2c77;
`;

export const Description = styled.div`
	width: 70%;
	margin-bottom: 1.8rem;
	text-align: center;
	font-size: 0.8rem;
	color: #262626;
`;

export const ConfirmButton = styled(Button)`
	width: 80%;
	height: 2.2rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.37rem;

	&:disabled {
		box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
	}
`;
