import styled from 'styled-components';

export const ActionDropdown = styled.button`
	color: #2F54EB;
	border: none;
	background: none;
	cursor: pointer;
	min-width: 150px;

	transition: filter 0.2s;
	&:hover {
		filter: brightness(1.2);
	}

	&:focus {
		background: none;
		border: none;
	}
`;
