import React from 'react';
import { Col } from 'antd';
import ReactPlayer from 'react-player';

import { FilesListResponse } from '@/hooks';
import { useGetDownloadVideoURL } from '@/hooks/v1';

interface PlayerProps {
	file: FilesListResponse;
}

export function Player({ file }: Readonly<PlayerProps>) {
	// const [isPlaying, setIsPlaying] = useState<boolean>(false);

	const {
		data: video,
	} = useGetDownloadVideoURL({
		file_id: file.id,
		company_id: file.company?.id,
		original_name: file.original_name,
		organization_id: file.organization?.id
	});

	// function moreTime(time: number): number {
	// 	return time;
	// }

	// function handlePlayButton() {
	// 	setIsPlaying(!isPlaying);
	// }

	return (
		<Col span={24}>
			<ReactPlayer
				loop
				url={video}
				width="100%"
				light={false}
				controls={true}
				// height={350}
				// onSeek={() => 5}
				// playing={isPlaying}
				// onReady={(info) => console.log({ info })}
			/>
			{/* <Button onClick={handlePlayButton}>{isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}</Button>
			<Button onClick={() => moreTime(5)}>+5</Button> */}
		</Col>
	);
}
