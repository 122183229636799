import React, { SVGProps } from "react";

function PlayVideo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      fill="none"
      viewBox="0 0 62 62"
	  {...props}
    >
      <g filter="url(#filter0_b_2179_246)">
        <circle
          cx="31"
          cy="31"
          r="31"
          fill="#E9E9E9"
          fillOpacity="0.2"
        ></circle>
      </g>
      <path
        fill="#2F54EB"
        d="M17 48.743V15.257c0-3.15 3.473-5.064 6.136-3.382l26.51 16.743c2.485 1.57 2.485 5.194 0 6.764l-26.51 16.743C20.473 53.807 17 51.893 17 48.743z"
      ></path>
      <defs>
        <filter
          id="filter0_b_2179_246"
          width="64"
          height="64"
          x="-1"
          y="-1"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="0.5"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2179_246"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2179_246"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default PlayVideo;
