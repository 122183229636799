import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Switch } from 'antd';

import { useGetContractPlans } from '@/components/Settings/ContractPlans/hooks/index';
import { Address, City, District, Nit, Plan } from '../../../../components';
import { CustomFormItem, SubTitle } from '../../../../styles';
import { Text } from '@/components/Typography';

const { useWatch, useFormInstance } = Form;

export function Information() {
	const form = useFormInstance();
	const { data } = useGetContractPlans();

	const isCompanyInfoChecked = useWatch('company_info_check');

	useEffect(() => {
		form.setFieldValue('company_info_check', true);
	}, []);

	return (
		<Row gutter={[0, 15]}>
			<Col span={24}>
				<SubTitle>{I18n.get('Information')}</SubTitle>
			</Col>
			<Col span={24}>
				<Row align="middle" gutter={[10, 0]}>
					<Col>
						<CustomFormItem name="company_info_check">
							<Switch checked={isCompanyInfoChecked} />
						</CustomFormItem>
					</Col>
					<Col>
						<Text style={{ fontSize: 15 }}>{I18n.get('Use the same company information')}</Text>
					</Col>
				</Row>
			</Col>
			{!isCompanyInfoChecked && (
				<Col span={24}>
					<Row gutter={[40, 20]}>
						<Col span={24}>
							<Address name="company_address" required />
						</Col>
						<Col span={12}>
							<City name="company_city" required />
						</Col>
						<Col span={12}>
							<District name="company_district" required />
						</Col>
						<Col span={12}>
							<Plan name="company_plan" options={data} required />
						</Col>
						<Col span={12}>
							<Nit name="company_nit" required />
						</Col>
					</Row>
				</Col>
			)}
		</Row>
	);
}
