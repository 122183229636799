import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { AxiosErrorResponse, BaseContext, Cellule } from '@/types';

export type GetCellulesDTO = BaseContext & {
	sector_id: string;
};

export type GetCellulesResponse = Cellule[];

async function getCellules(params: GetCellulesDTO): Promise<GetCellulesResponse> {
	const url = '/cellule/find-all';
	const { data } = await Api.get<GetCellulesResponse>(url, {
		params
	});
	return data;
}

export const useGetCellules = ({ sector_id, company_id, organization_id }: GetCellulesDTO) => {
	const enabled = !!sector_id && !!company_id && !!organization_id;
	const nioshReport = useQuery<GetCellulesResponse, AxiosErrorResponse>(
		[QUERY_KEYS.GET_CELLULES, [organization_id, company_id, sector_id]],
		() =>
			getCellules({
				sector_id,
				company_id,
				organization_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return nioshReport;
};
