import Api from '@/services/api';
import { ICreateWorkOnFile, ICreateWorkstation, IGetRequest } from './types';

async function create(payload: ICreateWorkstation) {
	const url = '/workstation/create';
	const { data } = await Api.post(url, payload);
	return data;
}

async function getWorkstation({ organizationId, companyId, sectorId, workstationId }: IGetRequest) {
	const url = `workstation/${organizationId}/${companyId}/${sectorId}/${workstationId}`;
	const { data } = await Api.get(url);
	return data;
}

async function createWorkstationOnFile(payload: ICreateWorkOnFile) {
	const url: string = '/workstation/create/file';
	const { data } = await Api.post(url, payload);
	return data;
}

export const Services = {
	create,
	getWorkstation,
	createWorkstationOnFile
};
