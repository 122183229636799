import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetSectors } from '@/hooks/useGetSectors';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Sectors() {
	const form = useFormInstance();
	const field_name = HierarchiesField.SECTOR;
	const organization_id = useWatch(HierarchiesField.ORGANIZATION, form);
	const company_id = useWatch(HierarchiesField.COMPANY, form);

	const {
		data: sectors,
		isFetching: isGettingSectors,
		isError: errorGettingSectors
	} = useGetSectors(organization_id, company_id);

	function handleSelectSector(id: string) {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(field_name, id);
		}
	}

	function handleClearSector(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={field_name} label={I18n.get('Sector')}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={sectors}
				loading={isGettingSectors}
				onClear={handleClearSector}
				onChange={handleSelectSector}
				placeholder={I18n.get('Sector')}
				disabled={!company_id || !sectors || errorGettingSectors}
			/>
		</Form.Item>
	);
}
