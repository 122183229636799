import React from 'react';
import { I18n } from '@aws-amplify/core';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CircularProgressbar } from 'react-circular-progressbar';
import { List, Popconfirm, Checkbox, Row, Col, Tooltip } from 'antd';
import { MdDeleteForever, MdCheckCircle, MdError, MdVideocam } from 'react-icons/md';

import { Container } from './styles';
import { DataParameters, UploadedFiles } from '../types';

interface ListUploadProps {
	files: UploadedFiles[];
	onDelete: (id: string) => void;
	onUpdate: (id: string, data: DataParameters) => void;
}
interface DeleteButtomProps {
	item: UploadedFiles;
	onDelete: (id: string) => void;
}

export const ListUpload: React.FC<ListUploadProps> = ({ files, onDelete, onUpdate }) => {
	return (
		<Container>
			{files?.length > 0 && (
				<List
					header={<HeaderList />}
					dataSource={files}
					renderItem={(item: any) => (
						<List.Item key={item.id}>
							<Row align="middle" justify="center" style={{ width: '100%' }}>
								<Col
									sm={17}
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap'
									}}
								>
									<MdVideocam size={30} color="#7159c1" style={{ textAlign: 'center' }} />
									<strong style={{ paddingLeft: '16px' }}>{item.name}</strong>
								</Col>
								<Col sm={2} style={{ textAlign: 'center' }}>
									<Checkbox
										checked={item.blur}
										disabled={item.blocking}
										onChange={({ target }) =>
											onUpdate(item.id, {
												blur: target.checked
											})
										}
									/>
								</Col>
								<Col sm={3} style={{ textAlign: 'center' }}>
									{!item.uploaded && !item.error && (
										<CircularProgressbar
											styles={{
												root: { width: 24 },
												path: { stroke: '#7159c1' }
											}}
											strokeWidth={10}
											value={item.progress}
										/>
									)}
									{!item.error && item.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
									{item.error && (
										<>
											<Tooltip title={I18n.get('Error')}>
												<MdError
													style={{ margin: ' 0 0.5rem 0 1.6rem ' }}
													size={24}
													color="#e57878"
												/>
											</Tooltip>
											<DeleteButtom onDelete={onDelete} item={item} />
										</>
									)}

									{!item.progress && <DeleteButtom onDelete={onDelete} item={item} />}
								</Col>
							</Row>
						</List.Item>
					)}
				/>
			)}
		</Container>
	);
};

const HeaderList = () => (
	<Row align="middle" justify="center">
		<Col sm={17}>
			<span style={{ marginLeft: '48px' }}>{I18n.get('Description')}</span>
		</Col>

		<Col sm={2} style={{ textAlign: 'center' }}>
			<span>{I18n.get('Blur')}</span>
		</Col>
		<Col sm={3} style={{ textAlign: 'center' }}>
			<span>{I18n.get('Actions')}</span>
		</Col>
	</Row>
);

const DeleteButtom = ({ onDelete, item }: DeleteButtomProps) => {
	return (
		<Popconfirm
			okText="Ok"
			placement="topRight"
			title={I18n.get('Are you sure?')}
			cancelText={I18n.get('Cancel')}
			onConfirm={() => onDelete(item.id)}
			icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
		>
			<Tooltip title={I18n.get('Delete')}>
				<MdDeleteForever size={24} color="#e57878" style={{ marginRight: 22, cursor: 'pointer' }} />
			</Tooltip>
		</Popconfirm>
	);
};
