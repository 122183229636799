import React from 'react';
import { Col, Form, Row } from 'antd';

import { FormItems } from '../../context/types';
import { CustomCol, CustomTitle, Data, SectionTitle } from './styles';

const { useFormInstance } = Form;

export const Preferences: React.FC = () => {
	const form = useFormInstance();

	const { has_evaluator, has_file } = form.getFieldsValue(true) as FormItems;

	return (
		<Row>
			<CustomCol xs={24}>
				<SectionTitle level={4}>Preferences</SectionTitle>
			</CustomCol>
			<CustomCol xs={24}>
				<Row>
					<Col xs={24}>
						<CustomTitle>Require video</CustomTitle>
					</Col>
					<CustomCol xs={24}>
						<Data>{has_file ? 'Yes' : 'No'}</Data>
					</CustomCol>
				</Row>
				<Row>
					<Col xs={24}>
						<CustomTitle>Require evaluator</CustomTitle>
					</Col>
					<CustomCol xs={24}>
						<Data>{has_evaluator ? 'Yes' : 'No'}</Data>
					</CustomCol>
				</Row>
			</CustomCol>
		</Row>
	);
};
