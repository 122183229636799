import React, { useContext, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form } from 'antd';
import moment from 'moment';

import { MergeAEPContext } from '../context';
import { TableList } from './TableList';
import { TextCustom } from './styles';
import { PEAListDTO } from '../types';
import { Filter } from './Filter';
import hooks from '../hooks';

const { useGetPEAList } = hooks;

interface ChecklistProps {
	onClearFilter: (organizationId?: string) => void;
}

export const Checklist: React.FC<ChecklistProps> = ({ onClearFilter }) => {
	const [page, setPage] = useState<number>(0);
	const [limit, setLimit] = useState<number>(10);

	const {
		queryParams,
		organizationId,
		data: { pea_list },
		setQueryParams
	} = useContext(MergeAEPContext);

	const {
		data: PEAList,
		isFetching,
		refetch
	} = useGetPEAList(
		{
			...queryParams,
			organizationId
		},
		page,
		limit
	);

	function handlePaginationChange(currentPage: number, pageSize: number) {
		setPage(currentPage - 1);
		setLimit(pageSize);
	}

	function handleFilter() {
		const element = document.getElementById('tableList');
		element?.scrollIntoView({ behavior: 'smooth' });
		setQueryParams({ ...queryParams, clearFilter: false });
		refetch();
	}

	function mountingPEA(pea: PEAListDTO) {
		const dateOfCollection = moment(pea.collection_date).format('DD-MM-YYYY');
		const createdAt = moment(pea.created_at).format('DD-MM-YYYY');
		return {
			key: pea.id,
			id: pea.id,
			dateOfCollection,
			analystName: pea.analyst_name,
			fileName: pea.file_name,
			score: pea.worst_score,
			createdAt
		};
	}

	return (
		<Col lg={24} xl={20} xxl={16}>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Filter onFilter={handleFilter} onClearFilter={onClearFilter} />
				</Col>
				<Col>
					<TextCustom>
						{
							"To generate this document, it's necessary that the EWA's must be consolidated, so the list below only shows the consolidated EWA's."
						}
					</TextCustom>
				</Col>
				<Col span={24} id="tableList">
					<Form.Item
						name="pea_list"
						rules={[
							() => ({
								validator() {
									return !pea_list || pea_list.length === 0 || !pea_list[0]
										? Promise.reject(I18n.get('Select a file'))
										: Promise.resolve();
								}
							})
						]}
					>
						<TableList
							total={PEAList.total}
							isLoading={isFetching}
							peaList={PEAList.result.map(mountingPEA)}
							onPaginationChange={handlePaginationChange}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
