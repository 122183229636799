import React from 'react';
import { Row } from 'antd';

import { StepIcon } from './StepIcon';
import { Container, CustomSteps } from './styles';
import { usePersonalisedReportContext } from '../../../context';

interface StepsNavigationProps {
	currentStep: number;
}

export const StepsNavigation: React.FC<StepsNavigationProps> = ({ currentStep }) => {
	const { stepsNavigation } = usePersonalisedReportContext();

	const color = '#2f54eb';

	const mappedSteps = stepsNavigation.map((item, index) => {
		item.icon = (
			<StepIcon key={item.id} position={index} currentStep={currentStep} name={item.description ?? ''} />
		);
		return item;
	});

	return (
		<Row justify="center">
			<Container xs={24}>
				<CustomSteps
					size="small"
					color={color}
					items={mappedSteps}
					direction="vertical"
					current={currentStep}
				/>
			</Container>
		</Row>
	);
};
