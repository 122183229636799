import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetCellules } from '@/hooks/useGetCellules';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Cellules() {
	const form = useFormInstance();
	const field_name = HierarchiesField.CELLULE;
	const sector_id = useWatch(HierarchiesField.SECTOR, form);
	const company_id = useWatch(HierarchiesField.COMPANY, form);
	const organization_id = useWatch(HierarchiesField.ORGANIZATION, form);

	const {
		data: cellules,
		isFetching: isGettingCellules,
		isError: errorGettingCellules
	} = useGetCellules({
		organization_id,
		company_id,
		sector_id
	});

	function handleSelectCellule(id: string): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(field_name, id);
		}
	}

	function handleClearCellule(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={field_name} label={I18n.get('Cellule')}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={cellules}
				loading={isGettingCellules}
				onClear={handleClearCellule}
				onChange={handleSelectCellule}
				placeholder={I18n.get('Cellule')}
				disabled={!sector_id || !cellules || errorGettingCellules}
			/>
		</Form.Item>
	);
}
