import styled from 'styled-components';

const STRESS_LEVEL = [
	'translateX(-25px) translateY(30px) rotate(-90deg)',
	'translateX(-15px) translateY(10px) rotate(-40deg)',
	'translateX(0) translateY(0) rotate(0)',
	'translateX(15px) translateY(10px) rotate(40deg)',
	'translateX(30px) translateY(30px) rotate(90deg)'
];

type StressLevelSyle = {
	level: number;
};

export const SpeedometerContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	height: 100%;
`;

export const Scale = styled.div`
	position: absolute;
	top: -90px;
	width: 12rem;

	@media (max-width: 1600px) {
		width: 10rem;
	}
`;

export const NeedleContainer = styled.div<StressLevelSyle>`
	position: absolute;
	width: 50px;
	height: 100px;
	top: -50px;
	z-index: 10;
	transform: ${(props) => STRESS_LEVEL[props.level]};
`;
