import React from 'react';
import { Row } from 'antd';

import { Score } from './Score';
import { Summary } from './Summary';

export const SummaryInformation: React.FC = () => (
	<Row>
		<Summary />
		<Score />
	</Row>
);
