import { Button, Col } from 'antd';
import styled from 'styled-components';

export const CreateButton = styled(Button)`
	width: 100%;
	display: flex;
	color: #2f54eb;
	padding: 20px 0;
	margin-top: 10px;
	align-items: center;
	border-radius: 18px;
	height: max-content;
	justify-content: center;
	background: transparent;
	border: solid 2px #2f54eb;
`;

export const Item = styled(Col)`
	font-size: 1rem;
	border-radius: 0.5rem;
	border: solid 2px #e6e6e6;
`;

export const Column = styled(Col)`
	padding: 8px 12px;
`;

export const Actions = styled(Col)`
	padding: 4px 15px;
	border-left: solid 1px #e6e8e8;
`;

export const ActionButton = styled.button`
	width: 100%;
	border: none;
	display: flex;
	font-size: 1.5rem;
	align-items: center;
	height: max-content;
	justify-content: center;
	background: transparent;

	&:hover {
		cursor: pointer;
	}
`;
