import React, { useEffect, useState } from 'react';
import { Form } from 'antd';

import { InitialValues, LogosModalProps } from '../types';
import { useManageOrganization } from '../context';
import { SecondPage } from './Steps/SecondPage';
import { FirstPage } from './Steps/FirstPage';
import { ModalStyled } from './styles';
import { Footer } from './footer';

const { useForm } = Form;

export const LogosModal: React.FC<LogosModalProps> = ({ isModalOpen, onModalOpen }) => {
	const [form] = useForm();

	const {
		handleCompanyId,
		isUrlLogoOrganization,
		organizationsList,
		organizationId,
		isUrlLogoCompany,
		organization,
		setOrganization,
		setIsUrlLogoCompany,
		setIsUrlLogoOrganization,
		fileList
	} = useManageOrganization();

	const [current, setCurrent] = useState(0);

	useEffect(() => {
		!organization.url_logo && setIsUrlLogoOrganization(true);
	}, []);

	useEffect(() => {
		const findOrganization = organizationsList.find((elem) => elem.id === organizationId);
		findOrganization && setOrganization(findOrganization);
	}, [fileList, organizationsList]);

	useEffect(() => {
		handleCompanyId('');
	}, []);

	const steps = [
		{
			content: <FirstPage url_logo={organization.url_logo} id={organization.id} />
		},
		{
			content: <SecondPage />
		}
	];

	const next = () => {
		setCurrent(current + 1);
	};

	const back = () => {
		setIsUrlLogoCompany(true);
		setCurrent(current - 1);
		form.resetFields();
	};

	const initialValues: InitialValues = {
		company_name: ''
	};

	function handleModalClose() {
		form.resetFields();
		onModalOpen(!isModalOpen);
		setIsUrlLogoCompany(false);
		setIsUrlLogoOrganization(false);
	}

	return (
		<Form form={form} initialValues={initialValues} layout="vertical" style={{ minHeight: '600px' }}>
			<ModalStyled width={598} open={isModalOpen} onCancel={() => handleModalClose()} footer={null}>
				<>{steps[current].content}</>
				<Footer
					current={current}
					steps={steps}
					back={back}
					next={next}
					isUrlLogoOrganization={isUrlLogoOrganization}
					isUrlLogoCompany={isUrlLogoCompany}
					handleModalClose={handleModalClose}
				/>
			</ModalStyled>
		</Form>
	);
};
