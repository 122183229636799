import React from 'react';
import { Row, Col } from 'antd';
import { DownloadVideoSVG } from '@/components/Icons';

interface DownloadVideoIconProps extends React.HTMLProps<HTMLSpanElement> {}

export function DownloadVideoIcon({ width = 20, height = 20, ...props }: Readonly<DownloadVideoIconProps>) {
	return (
		<Col>
			<Row justify="center">
				<Col className="custom-icon">
					<DownloadVideoSVG width={width} height={height} {...props} />
				</Col>
			</Row>
		</Col>
	);
}
