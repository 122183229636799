import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Title, Paragraph } from '@/components/Typography';

import {
	ChartContainer,
	ENUM_WORKPOPULATION_SCALE,
	Stress,
	StressLevel,
	StressLevelChart,
	StressLevelContainer,
	WorkingPopulationContainer
} from './styles';
import { selfEvaluationMap } from '../../../contants';
import { RiskBar } from '@/components/Dashboard/RiskBar';
import { Risk, RiskBarOptions } from '@/components/Dashboard/RiskBar/types';

type ChartsContentType = {
	workingPopulation: WorkingPopulation;
	stresslevel: string;
};
type WorkingPopulation = {
	men: number;
	women: number;
	others: number;
	total: number;
};

export const ChartsContent: React.FC<ChartsContentType> = ({ workingPopulation, stresslevel }) => {
	const percentage = 100;

	const stressLevelPercentage: { [key: string]: number } = {
		nothing_stressful: 20,
		little_stressful: 40,
		stressful: 60,
		very_stressful: 80,
		extremely_stressful: 100
	};

	const stressPercentage = percentage - (stresslevel !== null ? stressLevelPercentage[stresslevel] : 0);

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'right'
		},
		value: {
			align: 'center',
			display: 'bottom'
		},
		orientation: 'vertical'
	};

	enum Gender {
		MEN = 'men',
		WOMEN = 'women',
		OTHERS = 'others'
	}

	const WORKING_POPULATION = {
		TOTAL: 'total',
		MEN: Gender.MEN,
		WOMEN: Gender.WOMEN,
		OTHERS: Gender.OTHERS
	};

	function riskMapper(workingPopulationObject = {} as WorkingPopulation) {
		let risks: Risk[] = [];
		let id = 0;
		for (const worker in workingPopulationObject) {
			if (worker !== WORKING_POPULATION.TOTAL) {
				const gender = worker as Gender;

				if (workingPopulationObject[gender] > 0) {
					const mappedWorkingPopulation: Risk = {
						id,
						...ENUM_WORKPOPULATION_SCALE[gender],
						percentage: workingPopulationObject[gender] + '%',
						value: workingPopulationObject[gender] + '%'
					};

					risks.push(mappedWorkingPopulation);
					id += 1;
				}
			}
		}

		return risks.sort((a, b) => b.id - a.id);
	}

	return (
		<Row justify={'space-around'} style={{ height: '100%' }}>
			<Col span={10}>
				<WorkingPopulationContainer>
					<Row justify={'center'} align={'middle'}>
						<Col xl={24} xxl={16}>
							<Title style={{ margin: 0, textAlign: 'center' }} level={5}>
								{I18n.get('Working Population')}
							</Title>
						</Col>

						<Col span={24}>
							<ChartContainer style={{ marginTop: '1rem' }}>
								<RiskBar
									options={riskBarOptions}
									risks={riskMapper(workingPopulation)}
									total={workingPopulation['total']}
								/>
							</ChartContainer>
						</Col>
						{workingPopulation['total'] > 0 && (
							<Col span={24} style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}>
								<Paragraph>
									{I18n.get('Total')}: {workingPopulation['total']} {I18n.get('workers')}
								</Paragraph>
							</Col>
						)}
					</Row>
				</WorkingPopulationContainer>
			</Col>
			<Divider type="vertical" style={{ margin: 0, height: 'auto' }} />
			<Col span={10}>
				<StressLevelContainer>
					<Row justify={'center'} align={'middle'} gutter={[0, 12]}>
						<Col xl={20} xxl={14}>
							<Title style={{ margin: 0, textAlign: 'center' }} level={5}>
								{I18n.get('Stress Level')}
							</Title>
						</Col>

						<Col span={24}>
							<ChartContainer percentage={stressPercentage}>
								<StressLevelChart id="chart" percentage={stressPercentage} />
								<StressLevel id="level" />
							</ChartContainer>
						</Col>

						<Col span={24} style={{ textAlign: 'center' }}>
							<Stress>
								{I18n.get(
									`${selfEvaluationMap.find((elem) => elem.value === stresslevel)?.description}`
								)}
							</Stress>
						</Col>
					</Row>
				</StressLevelContainer>
			</Col>
		</Row>
	);
};
