import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { CreateReportDTO, CreateReportResponse } from '@/views/CreateReport/context/types';

type CreateStrainIndexError = AxiosError<any>;

async function createReport(parameters: CreateReportDTO) {
	const { data } = await Api.post('/ergonomic-tool/strain-index', parameters);
	return data;
}

export const useCreateStrainIndex = () => {
	const queryClient = useQueryClient();
	return useMutation<CreateReportResponse, CreateStrainIndexError, CreateReportDTO>(
		(payload) => createReport(payload),
		{
			onSuccess: async () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_STRAIN_INDEX_REPORT]);
			}
		}
	);
};
