import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { PlusCircleFilled } from '@ant-design/icons';

import { CompanyDTO, useDeleteCompany, useGetCompanies } from '@/components/Settings/ContractPlans/hooks/index';
import { useCreateOrganizationContext } from '../../context';
import { EditCompanyModal } from '../../../EditCompany';
import { DeleteModal } from '../../../DeleteModal';
import { CreateCompany } from './CreateCompany';
import { Text } from '@/components/Typography';
import { CreateButton } from './styles';
import { List } from './List';

export function Companies() {
	const { organization } = useCreateOrganizationContext();

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isCreating, setIsCreating] = useState<boolean>(false);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);
	const [company, setCompany] = useState<CompanyDTO>({} as CompanyDTO);

	const { data, isLoading: isGettingCompanies } = useGetCompanies(organization.id);
	const { mutateAsync: deleteCompany } = useDeleteCompany();

	function handleToggleCreate() {
		setIsCreating(!isCreating);
	}

	function handleToggleDelete() {
		setIsDeleting(!isDeleting);
	}

	function handleToggleEdit() {
		setIsEditing(!isEditing);
	}

	function handleOnClickEdit(company: CompanyDTO) {
		handleToggleEdit();
		setCompany(company);
	}

	function handleOnClickDelete(company: CompanyDTO) {
		handleToggleDelete();
		setCompany(company);
	}

	async function handleDeleteCompany() {
		await deleteCompany({
			company_id: company.company_id,
			organization_id: organization.id
		});

		handleToggleDelete();
	}

	if (data?.length === 0 && !isCreating) {
		return (
			<CreateButton onClick={handleToggleCreate}>
				<Row gutter={[0, 8]}>
					<Col span={24}>
						<PlusCircleFilled style={{ fontSize: 45 }} />
					</Col>
					<Col span={24}>
						<Text style={{ color: '#2f54eb', fontSize: 18 }}>{I18n.get('Add a new industrial site')}</Text>
					</Col>
				</Row>
			</CreateButton>
		);
	}

	return (
		<Row gutter={[0, 20]}>
			<List
				items={data}
				onEdit={handleOnClickEdit}
				onDelete={handleOnClickDelete}
				isLoading={isGettingCompanies}
			/>
			<CreateCompany isCreating={isCreating} isLoading={isGettingCompanies} onClick={handleToggleCreate} />
			<Col>
				<EditCompanyModal
					open={isEditing}
					onCancel={handleToggleEdit}
					companyId={company.company_id}
					organizationId={organization.id}
				/>
				<DeleteModal
					open={isDeleting}
					onCancel={handleToggleDelete}
					onConfirm={handleDeleteCompany}
					description={`${I18n.get('Do you really want to delete')} "${company.name}"?`}
				/>
			</Col>
		</Row>
	);
}
