import Api from '@/services/api';

import { CustomReportApiServiceInterface } from './servicesInterfaces';
import { CustomReport } from '@/types';
import { CreateCustomReport, UpdateCustomReport } from './types/request';

export class CustomReportService implements CustomReportApiServiceInterface {
	async createCustomReport(payload: CreateCustomReport): Promise<CustomReport> {
		const url = '/custom-report';
		const { data } = await Api.post<CustomReport>(url, payload);
		return data;
	}

	async updateCustomReport(payload: UpdateCustomReport): Promise<CustomReport> {
		const url = '/custom-report';
		const { data } = await Api.put<CustomReport>(url, payload);
		return data;
	}
}

export const customReportService = new CustomReportService();
