import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from './context';
import { OrganizationDTO } from './types';
import { Confirm } from './ManageOrganizationModal/Manage/styles';

const { useForm } = Form;

type EditOrganizationProps = {
	onEditing: (isEditing: boolean) => void;
	editingOrganizationId: string;
	organization: OrganizationDTO;
};

export const EditOrganization: React.FC<EditOrganizationProps> = ({
	onEditing,
	editingOrganizationId,
	organization
}) => {
	const [form] = useForm();

	const [organizationName, setOrganizationName] = useState('');

	const { handleUpdateOrganization } = useManageOrganization();

	function handleOrganizationNameChange(name: string) {
		setOrganizationName(name);
	}

	function handleCancel() {
		setOrganizationName('');
		onEditing(false);
	}

	function handleSubmit() {
		handleUpdateOrganization({
			name: organizationName,
			organization_id: editingOrganizationId
		});
		onEditing(false);
	}

	return (
		<Form
			form={form}
			initialValues={{ editing_organization_name: organization.name }}
			style={{
				width: '100%',
				margin: '0'
			}}
		>
			<Row align="middle" justify="center" style={{ width: '100%', position: 'relative', left: '24px' }}>
				<Col span={16} style={{ marginRight: '0.66rem' }}>
					<Form.Item
						name="editing_organization_name"
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid organization name')
							}
						]}
					>
						<Input
							style={{
								flex: 'auto',
								textAlign: 'center'
							}}
							placeholder={I18n.get('Organization name')}
							value={organizationName}
							onChange={(e) => handleOrganizationNameChange(e.target.value)}
						/>
					</Form.Item>
				</Col>
				<Col span={2} style={{ marginRight: '0.66rem' }}>
					<Button
						size="small"
						type="primary"
						ghost={true}
						danger={true}
						onClick={() => handleCancel()}
						icon={<CloseOutlined />}
					/>
				</Col>
				<Confirm span={2}>
					<Button
						disabled={!organizationName || organization.name === organizationName}
						size="small"
						type="primary"
						ghost={true}
						htmlType="submit"
						onClick={() => handleSubmit()}
						icon={<CheckOutlined />}
					/>
				</Confirm>
			</Row>
		</Form>
	);
};
