import { DefaultTheme } from 'styled-components';
import { FontSize, RiskScale, Size } from '../enum';

export const lightTheme: DefaultTheme = {
	title: 'light',
	colors: {
		text: '#fff',
		primary: '#333',
		secondary: '#C62E65',
		background: '#fff',
		riskScale: {
			[RiskScale.NOT_APPLICABLE]: '#b3b3b3',
			[RiskScale.ACCEPTABLE]: '#2CC852',
			[RiskScale.MODERATE]: '#F8D627',
			[RiskScale.HIGH]: '#F78A38',
			[RiskScale.VERY_HIGH]: '#E74150',
			[RiskScale.SERIOUS_AND_IMMINENT]: '#9B54E2'
		}
	},
	fonts: {
		[Size.SMALL]: FontSize[Size.SMALL],
		[Size.MEDIUM]: FontSize[Size.MEDIUM],
		[Size.LARGE]: FontSize[Size.LARGE],
		primary: '#333',
		secondary: '#C62E65',
		background: '#fff',
		text: '#fff'
	}
};
