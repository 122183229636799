import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomRangePicker } from './styles';

export function CreatedAt() {
	return (
		<Form.Item name={['created_at']} label={I18n.get('Upload date')}>
			<CustomRangePicker
				format="L"
				allowClear
				disabledDate={(current) => current && current.valueOf() >= Date.now()}
			/>
		</Form.Item>
	);
}
