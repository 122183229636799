import React from 'react';
import { I18n } from '@aws-amplify/core';

import { UpdateOrganizationProvider } from './context';
import { Content } from './Content';
import { Header } from '../Header';
import { Footer } from './Footer';
import * as S from '../styles';

interface EditOrganizationModalProps {
	open: boolean;
	onCancel: () => void;
}

export function EditOrganizationModal({ open, onCancel }: Readonly<EditOrganizationModalProps>) {
	return (
		<S.CustomModal centered destroyOnClose open={open} onCancel={onCancel} footer={null}>
			<UpdateOrganizationProvider onCancel={onCancel}>
				<S.Container>
					<Header>{I18n.get('Edit company')}</Header>
					<S.ContentWrapper>
						<Content />
					</S.ContentWrapper>
					<S.FooterWrapper>
						<Footer />
					</S.FooterWrapper>
				</S.Container>
			</UpdateOrganizationProvider>
		</S.CustomModal>
	);
}
