import React from 'react';
import { Col, DatePicker } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItem } from '../../../components/FormItem';

export const CollectionDate: React.FC = () => (
	<Col xs={24}>
		<FormItem
			fontSize="1rem"
			isRequired={true}
			title="Collection date"
			namePath={['basic_information', 'collection_date']}
			errorMessage={I18n.get('Enter the collection date')}
		>
			<DatePicker allowClear size="large" format="L" style={{ width: '100%' }} />
		</FormItem>
	</Col>
);
