import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Form, InputNumber, Row } from 'antd';

import { CustomInputFormField } from './CustomInputFormField';
import { TotalWorkingPopulation } from './TotalWorkingPopulation';
import { WorkingPopulationOption } from '../../../../context/types';

const { useFormInstance } = Form;

export const WorkingPopulation: React.FC = () => {
	const form = useFormInstance();

	const workingPopulations: WorkingPopulationOption[] = [
		{
			id: 1,
			title: 'Men',
			isRequired: true,
			errorMessage: 'Working population must be greater than 0',
			namePath: ['characteristics', 'working_population_men']
		},
		{
			id: 2,
			title: 'Women',
			isRequired: true,
			namePath: ['characteristics', 'working_population_women']
		},
		{
			id: 3,
			title: 'Others',
			isRequired: true,
			namePath: ['characteristics', 'working_population_others']
		}
	];

	async function handleChange(): Promise<void> {
		await form.validateFields();
	}

	return (
		<Row justify="start" style={{ marginBottom: '2rem' }}>
			<Col span={24}>
				<Title level={5}>Working population</Title>
			</Col>
			<Col>
				<Row>
					{workingPopulations.map(({ id, title, namePath, errorMessage, isRequired }) => (
						<Col xs={6} key={id}>
							<CustomInputFormField
								title={title}
								namePath={namePath}
								message={errorMessage}
								isRequired={isRequired}
							>
								<InputNumber onChange={handleChange} min={0} placeholder="0" />
							</CustomInputFormField>
						</Col>
					))}
					<TotalWorkingPopulation />
				</Row>
			</Col>
		</Row>
	);
};
