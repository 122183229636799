import moment from 'moment';

import {
	Payload,
	FormFields,
	CommonParams,
	StepFormFields,
	StepKeyFormFields,
	InformationsFormFields,
	WorkConditionsFormFields,
	CharacteristicsFormFields,
	KinebotStepKey
} from './types';
import {
	CreateOrUpdate,
	CreateOrUpdateWorkCondition,
	CreateOrUpdateCharacteristic,
	CreateOrUpdateGenericStepKeyResult
} from '../types';
import {
	CustomReportSubStepKeyResponse,
	GenericStepKeyResult,
	PersonalisedReportStepKeysResponse,
	SubStepKeyResult
} from '../../hooks';
import { CreateOrUpdateTypeMapper, ICreateOrUpdateTypeMapper } from './createOrUpdateTypeMapper';

type ICreateOrUpdate = ICreateOrUpdateTypeMapper & {
	mapPayload(data: FormFields, commonParams: CommonParams): Payload | undefined;
};

export class CreateOrUpdateService extends CreateOrUpdateTypeMapper implements ICreateOrUpdate {
	public mapPayload(data: FormFields, commonParams: CommonParams): Payload | undefined {
		if (!data) {
			return undefined;
		}
		if (this.isCreateOrUpdateInformations(data)) {
			return this.mapInformations(data, commonParams);
		}

		if (this.isCreateOrUpdateWorkCondition(data)) {
			return this.mapWorkCondition(data, commonParams);
		}

		if (this.isCreateOrUpdateCharacteristics(data)) {
			return this.mapCharacteristics(data, commonParams);
		}

		return this.mapStepsAndStepKeys(data, commonParams);
	}

	private mapStepsAndStepKeys(data: StepFormFields, commonParams: CommonParams): CreateOrUpdateGenericStepKeyResult {
		const { report_id, result_id, step_setting_id, stepKeys } = commonParams;
		const stepKeysResults: GenericStepKeyResult[] = this.mapStepKeysResults(data, stepKeys);
		const body: CreateOrUpdateGenericStepKeyResult = {
			step_setting_id,
			custom_report_id: report_id,
			step_keys_results: stepKeysResults,
			custom_report_result_id: result_id,
			company_id: commonParams.company_id,
			organization_id: commonParams.organization_id
		};
		return body;
	}

	private mapStepKeysResults(
		data: StepFormFields,
		stepKeys?: PersonalisedReportStepKeysResponse[]
	): GenericStepKeyResult[] {
		const stepKeysResults: GenericStepKeyResult[] = [];
		for (const stepKey in data) {
			const stepKeyValues: StepKeyFormFields = data[stepKey];
			const currentStepKey = stepKeys?.find(({ id }) => id === stepKey);
			let stepKeysResult: GenericStepKeyResult = {} as GenericStepKeyResult;
			if (!!stepKeyValues.checked && currentStepKey) {
				if (!stepKeyValues.kinebot) {
					stepKeysResult = this.mapStepKeyResult(currentStepKey, stepKeyValues);
				} else {
					stepKeysResult = this.mapEmptyStepKeyResult(currentStepKey);
					stepKeysResult.sub_step_keys_results = this.mapSubStepKeyResults(
						currentStepKey,
						stepKeyValues.kinebot
					);
				}
			} else {
				stepKeysResult = this.mapEmptyStepKeyResult(currentStepKey);
			}
			stepKeysResults.push(stepKeysResult);
		}

		return stepKeysResults;
	}

	private mapSubStepKeyResults(
		currentStepKey: PersonalisedReportStepKeysResponse,
		kinebotStepKey: KinebotStepKey
	): SubStepKeyResult[] {
		const subStepKeys = currentStepKey.sub_step_keys;
		const stepKeysResults: SubStepKeyResult[] = [];
		for (const subStepKey in kinebotStepKey) {
			const subStepKeyValues: StepKeyFormFields = kinebotStepKey[subStepKey];
			const currentSubStepKey = subStepKeys?.find(({ id }) => id === subStepKey);
			let subStepKeysResult: SubStepKeyResult;
			if (currentStepKey) {
				subStepKeysResult = this.mapSubStepKeyResult(currentSubStepKey, subStepKeyValues, currentStepKey);
			} else {
				subStepKeysResult = this.mapEmptySubStepKeyResult(currentSubStepKey, currentStepKey);
			}
			stepKeysResults.push(subStepKeysResult);
		}
		return stepKeysResults;
	}

	private mapSubStepKeyResult(
		currentSubStepKey: CustomReportSubStepKeyResponse | undefined,
		subStepKeyValues: StepKeyFormFields,
		currentStepKey: PersonalisedReportStepKeysResponse
	): SubStepKeyResult {
		return {
			score: subStepKeyValues.score,
			result: subStepKeyValues.result,
			injuries: subStepKeyValues.injuries,
			exposure_id: subStepKeyValues.exposure_id,
			consequence_id: subStepKeyValues.consequence_id,
			probability_id: subStepKeyValues.probability_id,
			custom_report_sub_step_key_id: currentSubStepKey?.id,
			sub_step_key_result_id: currentSubStepKey?.sub_step_key_result_id,
			custom_report_step_key_setting_id: currentStepKey?.custom_report_step_key_setting_id
		};
	}

	private mapEmptySubStepKeyResult(
		currentSubStepKey: CustomReportSubStepKeyResponse | undefined,
		currentStepKey: PersonalisedReportStepKeysResponse
	): SubStepKeyResult {
		return {
			score: null,
			result: null,
			injuries: [],
			exposure_id: null,
			consequence_id: null,
			probability_id: null,
			custom_report_sub_step_key_id: currentSubStepKey?.id,
			sub_step_key_result_id: currentSubStepKey?.sub_step_key_result_id,
			custom_report_step_key_setting_id: currentStepKey?.custom_report_step_key_setting_id
		};
	}

	private mapStepKeyResult(
		currentStepKey: PersonalisedReportStepKeysResponse,
		stepKeyValues: StepKeyFormFields
	): GenericStepKeyResult {
		return {
			id: currentStepKey.id,
			score: stepKeyValues.score,
			result: stepKeyValues.result,
			injuries: stepKeyValues.injuries,
			exposure_id: stepKeyValues.exposure_id,
			consequence_id: stepKeyValues.consequence_id,
			probability_id: stepKeyValues.probability_id,
			step_key_result_id: currentStepKey.step_key_result_id,
			custom_report_step_key_setting_id: currentStepKey.custom_report_step_key_setting_id
		};
	}

	private mapEmptyStepKeyResult(
		currentStepKey: PersonalisedReportStepKeysResponse | undefined
	): GenericStepKeyResult {
		const emptyStepKeyResult: GenericStepKeyResult = {
			score: null,
			result: null,
			injuries: [],
			exposure_id: null,
			probability_id: null,
			consequence_id: null,
			id: currentStepKey?.id,
			step_key_result_id: currentStepKey?.step_key_result_id,
			custom_report_step_key_setting_id: currentStepKey?.custom_report_step_key_setting_id
		};
		if (currentStepKey?.sub_step_keys) {
			const emptySubStepKeys: SubStepKeyResult[] = currentStepKey.sub_step_keys.map((currentSubStepKey) =>
				this.mapEmptySubStepKeyResult(currentSubStepKey, currentStepKey)
			);
			emptyStepKeyResult.sub_step_keys_results = emptySubStepKeys;
		}
		return emptyStepKeyResult;
	}

	private mapCharacteristics(
		data: CharacteristicsFormFields,
		commonParams: CommonParams
	): CreateOrUpdateCharacteristic {
		const { report_id, result_id, step_setting_id, workstation_id } = commonParams;
		const {
			working_population_men,
			total_working_population,
			working_population_women,
			worker_self_evaluation_id,
			working_population_others,
			particularities_description,
			worker_verbalization_description
		} = data;
		return {
			workstation_id,
			step_setting_id,
			working_population_men,
			total_working_population,
			working_population_women,
			worker_self_evaluation_id,
			working_population_others,
			custom_report_id: report_id,
			particularities_description,
			worker_verbalization_description,
			custom_report_result_id: result_id,
			company_id: commonParams.company_id,
			organization_id: commonParams.organization_id
		};
	}

	private mapWorkCondition(data: WorkConditionsFormFields, commonParams: CommonParams): CreateOrUpdateWorkCondition {
		const { report_id, result_id, step_setting_id, workstation_id } = commonParams;
		const { work_hours, work_minutes, place_description, expected_task_description, performed_task_description } =
			data;
		return {
			work_hours,
			work_minutes,
			workstation_id,
			step_setting_id,
			place_description,
			expected_task_description,
			performed_task_description,
			custom_report_id: report_id,
			custom_report_result_id: result_id,
			company_id: commonParams.company_id,
			organization_id: commonParams.organization_id
		};
	}

	private mapInformations(data: InformationsFormFields, commonParams: CommonParams): CreateOrUpdate {
		const { report_id, file_id, result_id, step_setting_id } = commonParams;
		const {
			role_name,
			company_id,
			cellule_id,
			activity_id,
			report_name,
			evaluator_id,
			workstation_id,
			organization_id,
			collection_date
		} = data;
		return {
			file_id,
			role_name,
			result_id,
			cellule_id,
			activity_id,
			report_name,
			evaluator_id,
			workstation_id,
			step_setting_id,
			custom_report_id: report_id,
			collection_date: moment(collection_date).toDate(),
			company_id: company_id ?? commonParams.company_id,
			organization_id: organization_id ?? commonParams.organization_id
		};
	}
}
