import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import type { CustomReportResult, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { customReportService } from '../../../../hooks/services';

export type PersonalisedReportUpdatedCommentResponse = CustomReportResult;

export type PersonalisedReportResultUpdateCommentDTO = BaseContext & {
	comment: string;
	custom_report_result_id?: string;
};

async function saveComments<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/comment';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useUpdateComment() {
	return useMutation<
		PersonalisedReportUpdatedCommentResponse,
		AxiosError<Response>,
		PersonalisedReportResultUpdateCommentDTO
	>(
		(body) =>
			saveComments<PersonalisedReportUpdatedCommentResponse, PersonalisedReportResultUpdateCommentDTO>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Comment updated successfully';
				customReportService.showSuccessMessage(message);
			}
		}
	);
}
