import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { PieChart } from '../PieChart';
import { Diagnostics } from '@/types/Reba';
import { Text } from '@/components/Typography';
import { DIAGNOSTIC_COLORS } from '@/utils/constants';
import { BodyPartsDescription, BodyPartsDTO } from '../../types';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';

type PercentageItemProps = {
	index: number;
	value: string;
};

type Members = keyof typeof BodyPartsDescription;

interface ChartProps {
	angles: BodyPartsDTO;
	member: Members;
}

export const Chart: React.FC<ChartProps> = ({ angles, member }) => {
	const { calculateBodyPartPercentage } = usePreliminaryAnalysis();
	const mappedAngle = angles[member].map((data) => data || 0);

	const scales = Object.values(Diagnostics);
	const percentages = calculateBodyPartPercentage(mappedAngle);

	const PercentageItem = ({ value, index }: PercentageItemProps) => (
		<Col key={index} span={24}>
			<span style={{ fontSize: '12px' }}>
				{I18n.get(scales[index])}:{' '}
				<Text strong style={{ color: DIAGNOSTIC_COLORS[index] }}>
					{value}%
				</Text>
			</span>
		</Col>
	);

	return (
		<Row align="middle" justify="space-between">
			<Col span={12}>
				<Row>
					{percentages?.map((value: string, i: number) => (
						<PercentageItem key={i} value={value} index={i} />
					))}
				</Row>
			</Col>
			<Col span={8}>
				<PieChart scores={angles[member]?.map((data) => data || 0)} />
			</Col>
		</Row>
	);
};
