import React from 'react';

const defaultState = {
	selectedCompanyId: null,
	selectedSectorId: null,
	setSelectedFileId: null,
	actionPlans: null,
	currentActionPlan: null,
	creationBoard: null,
	actionPlanCreateModal: false,
	actionPlanEditModal: false,
	triggerReload: null
};

const defaultContext = {
	...defaultState,
	users: {},
	companies: {},
	sectors: {},
	files: {},
	responsibleUsers: {},
	setSelectedCompanyId: () => {},
	setSelectedSectorId: () => {},
	setSelectedFileId: () => {},
	onFilterActionPlans: () => {},
	setTriggerReload: () => {},
	onEditActionPlan: () => {},
	onDeleteActionPlan: () => {},
	showCreateActionPlan: () => {},
	calcProgress: () => {}
};

export const ActionPlansContext = React.createContext(defaultContext);
