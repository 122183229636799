import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItems } from '../../context/types';
import { useCustomReportManagerContext } from '../../../context';
import { CustomCol, CustomTitle, Data, SectionTitle } from './styles';

const { useFormInstance } = Form;

export const GeneralInformation: React.FC = () => {
	const form = useFormInstance();
	const {
		scoreScales: { data: customReportScoreScale }
	} = useCustomReportManagerContext();

	const { custom_report_score_scale_id, description, extended_description } = form.getFieldsValue(true) as FormItems;

	const selectedScoreScale = customReportScoreScale?.find(({ id }) => id === custom_report_score_scale_id);
	return (
		<Row>
			<CustomCol xs={24}>
				<SectionTitle level={4}>General informations</SectionTitle>
			</CustomCol>
			<CustomCol xs={24}>
				<Row>
					<Col xs={24}>
						<CustomTitle>Report name</CustomTitle>
					</Col>
					<CustomCol xs={24}>
						<Data>{description}</Data>
					</CustomCol>
				</Row>
				<Row>
					<Col xs={24}>
						<CustomTitle>Risk scale</CustomTitle>
					</Col>
					<CustomCol xs={24}>
						<Data>{I18n.get(selectedScoreScale?.description ?? 'N/A')}</Data>
					</CustomCol>
				</Row>
				<Row>
					<Col xs={24}>
						<CustomTitle>Description</CustomTitle>
					</Col>
					<CustomCol xs={24}>
						<Data>{extended_description ?? 'N/A'}</Data>
					</CustomCol>
				</Row>
			</CustomCol>
		</Row>
	);
};
