import React from 'react';
import { ExclamationOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import { Text, Title } from '@/components/Typography';

import { WarningContainer } from './styles';

interface RecoveryTokensProps {
	recoveryTokens: Array<string>;
}

export const RecoveryTokens: React.FC<RecoveryTokensProps> = ({ recoveryTokens }) => {
	return (
		<>
			<Row justify="center" style={{ marginTop: '2rem' }}>
				<WarningContainer>
					<ExclamationOutlined style={{ padding: 0, margin: 0 }} />
				</WarningContainer>
			</Row>
			<Row justify="center" style={{ marginTop: '1.5rem' }}>
				<Col>
					<Title level={4}>Recovery tokens</Title>
				</Col>
			</Row>
			<Row justify="center" style={{ marginTop: '1rem' }}>
				<Col sm={21} xxl={20} style={{ textAlign: 'center' }}>
					<Text>
						Here are your recovery codes, which will be requested should you need to recover your account.
						Keep in mind that they will not be accessible again after this moment, so it's crucial that you
						save them securely.
					</Text>
				</Col>
			</Row>
			<Row justify="center" style={{ margin: '1.5rem 0 2.5rem 0' }}>
				<Row justify="center" gutter={[0, 10]} style={{ width: '75%' }}>
					{recoveryTokens.map((token, index) => (
						<Col key={index} span={8} style={{ textAlign: 'center' }}>
							<Title level={4} style={{ marginBottom: '0' }}>
								{token}
							</Title>
						</Col>
					))}
				</Row>
			</Row>
		</>
	);
};
