import React from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Form, Select, SelectProps } from 'antd';

import { CompanyDTO } from '@/components/ui/Inputs/types/response/company';
import { I18n } from '@aws-amplify/core';

const { useFormInstance } = Form;

type CompanySelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	companyId?: string;
	handleClearCompany?: () => void;
	companies?: CompanyDTO[];
	handleSelectCompany?: (id: string) => void;
	mode?: 'multiple' | 'tags';
} & SelectProps;

type OptionType = { label: string; value: string } | undefined;

export function CompanySelect({
	loading,
	disabled,
	companies,
	companyId,
	size = 'middle',
	handleClearCompany,
	handleSelectCompany,
	mode = undefined,
	...props
}: Readonly<CompanySelectProps>) {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string) {
		if (handleSelectCompany) {
			handleSelectCompany(id);
			await validateFields(['company_id']);
		}
	}

	function handleClear() {
		if (handleClearCompany) {
			handleClearCompany();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Select
			{...props}
			mode={mode}
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			onChange={handleChange}
			value={companyId ?? undefined}
			placeholder={mode ? I18n.get('Please select one or more') : I18n.get('Industrial site')}
			filterOption={(input, option: OptionType) => filterOption(option, input)}
			options={companies?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
