import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Draggable } from 'react-beautiful-dnd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import { CustomListDraggable, CustomButton, StepKeyDescriptionCol } from './styles';
import { useCustomReportManagerStepKeyContext } from '../context';
import { StepKey } from '../context/types';

interface StepCardProps {
	index: number;
	stepKey: StepKey;
}

export const StepKeyCard: React.FC<StepCardProps> = ({ stepKey, index }) => {
	const { removeStepKeyFromStepKeyList } = useCustomReportManagerStepKeyContext();

	const { id, description } = stepKey;

	function handleRemoveSelectedStep(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		event.preventDefault();
		event.stopPropagation();
		removeStepKeyFromStepKeyList(stepKey);
	}

	return (
		<Draggable draggableId={id} key={id} index={index}>
			{({ innerRef, draggableProps, dragHandleProps }) => (
				<Row key={id} justify="center" {...draggableProps} {...dragHandleProps} ref={innerRef}>
					<CustomListDraggable xs={24}>
						<Row align="middle" justify="space-between">
							<Col xs={2} xl={1}>
								<MenuOutlined style={{ fontSize: '0.8rem' }} />
							</Col>
							<StepKeyDescriptionCol xs={20}>{I18n.get(description)}</StepKeyDescriptionCol>
							<Col xs={2} xl={1}>
								<CustomButton onClick={(event) => handleRemoveSelectedStep(event)}>
									<CloseOutlined
										style={{
											fontSize: '0.8rem',
											color: '#C6C6C4'
										}}
									/>
								</CustomButton>
							</Col>
						</Row>
					</CustomListDraggable>
				</Row>
			)}
		</Draggable>
	);
};
