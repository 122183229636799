import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

type CreateOrganizationPlanParams = {
	recurrence: string;
	max_minutes: number;
	max_uploads: number;
	expiration_day: Date;
	organization_id: string;
};

export type OrganizationPlanDTO = {
	id: string;
	name: string;
	amount: number;
	updatedAt: Date;
	createdAt: Date;
	is_active: true;
	max_users: number;
	max_upload: number;
	recurrence: string;
	max_minutes: number;
	expiration_plan: Date;
	amount_yearly: number;
	organization_id: string;
};

async function createOrganizationPlan(payload: CreateOrganizationPlanParams) {
	const { data } = await Api.post('/contract-plan/organization/plan', payload);
	return data;
}

export const useCreateOrganizationPlan = () => {
	return useMutation(createOrganizationPlan);
};
