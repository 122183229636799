import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { PrimaryButton } from '@/components/ui/Buttons/PrimaryButton';
import { setSession } from '@/redux/auth/actions';
import { Auth2FAParams } from '@/types/AuthUser';

import { CustomButton } from './styles';

interface ButtonsProps {
	currentStep: number;
	handleNextStep: () => void;
	handlePreviousStep: () => void;
}

const { useFormInstance } = Form;

export const Buttons: React.FC<ButtonsProps> = ({ currentStep, handleNextStep, handlePreviousStep }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { info_user } = useApplicationContext();
	const { qr_code_url } = useParams<Auth2FAParams>();
	const { isFieldsTouched, getFieldsError } = useFormInstance();

	const is_generating_2fa = !qr_code_url && (currentStep === 2 || currentStep === 3);
	const is_recovering_2fa = qr_code_url && currentStep === 3;

	function checkFormErrors() {
		const fields_changed = isFieldsTouched(true);
		const form_errors = getFieldsError().some(({ errors }) => errors.length > 0);

		if (!fields_changed || form_errors) {
			return true;
		}

		return false;
	}

	function handleFinish2FA() {
		dispatch(
			setSession({
				user: info_user.user,
				token: info_user.token,
				refresh_token: info_user.refresh_token
			})
		);

		history.push('/');
	}

	return (
		<>
			{currentStep === 1 && (
				<Col span={12}>
					<Form.Item noStyle shouldUpdate>
						{({ getFieldValue }) => {
							const method_type = getFieldValue('method_type');

							return (
								<PrimaryButton disabled={!method_type} onClick={handleNextStep}>
									{I18n.get('Confirm')}
								</PrimaryButton>
							);
						}}
					</Form.Item>
				</Col>
			)}
			{is_generating_2fa && (
				<Row justify="center" style={{ height: '4rem', width: '100%' }}>
					<Col span={4} style={{ marginRight: '2rem' }}>
						<CustomButton onClick={handlePreviousStep}>{I18n.get('Previous')}</CustomButton>
					</Col>
					<Col span={5}>
						{currentStep === 2 && (
							<CustomButton type="primary" onClick={handleNextStep}>
								{I18n.get('Next')}
							</CustomButton>
						)}
						{currentStep === 3 && (
							<Form.Item noStyle shouldUpdate>
								{() => {
									const has_errors = checkFormErrors();

									return (
										<CustomButton type="primary" htmlType="submit" disabled={has_errors}>
											{I18n.get('Next')}
										</CustomButton>
									);
								}}
							</Form.Item>
						)}
					</Col>
				</Row>
			)}
			{is_recovering_2fa && (
				<Row justify="center" style={{ height: '4rem', width: '100%' }}>
					<Col span={12}>
						<Form.Item noStyle shouldUpdate>
							{() => {
								const has_errors = checkFormErrors();

								return (
									<PrimaryButton type="primary" htmlType="submit" disabled={has_errors}>
										{I18n.get('Next')}
									</PrimaryButton>
								);
							}}
						</Form.Item>
					</Col>
				</Row>
			)}
			{currentStep === 4 && (
				<Col span={12}>
					<PrimaryButton onClick={handleFinish2FA}>{I18n.get('Finish')}</PrimaryButton>
				</Col>
			)}
		</>
	);
};
