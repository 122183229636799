import React from 'react';
import { Row, Col } from 'antd';
import { Container } from './Container';
import { Spinner } from '@/components/Spinner';
import * as Typography from '@/components/Typography';

export const Redirect: React.FC = () => (
	<Container>
		<Row gutter={[0, 40]} justify="center" align="middle">
			<Col span={24}>
				<Spinner size={50} />
			</Col>
			<Col span={24}>
				<Typography.Text>You are being redirected</Typography.Text>
			</Col>
		</Row>
	</Container>
);
