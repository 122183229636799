import React from 'react';
import { Col, Form, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomItemSwitch } from './styles';
import { useCustomReportManagerContext } from '../../context';

const { useFormInstance, useWatch } = Form;

export const RequireWorkCondition: React.FC = () => {
	const form = useFormInstance();
	const workCondition = useWatch('has_work_conditions', form);
	const { handleStepsSequence } = useCustomReportManagerContext();

	function handleChange(): void {
		handleStepsSequence();
	}

	return (
		<Col xs={24}>
			<CustomItemSwitch name={['has_work_conditions']} label={I18n.get('Require work condition')}>
				<Switch checked={workCondition} onChange={handleChange} />
			</CustomItemSwitch>
		</Col>
	);
};
