import Api from '@/services/api';
import { IDefaultParameters, ICreate, IUpdate, DefaultResponseDTO } from './types';

export class RebaService {
	public async create(payload: ICreate) {
		const url = '/ergonomic-tool/reba';
		const { data } = await Api.post(url, payload);
		return data;
	}

	public async update(payload: IUpdate) {
		const url = '/ergonomic-tool/reba';
		const { data } = await Api.put(url, payload);
		return data;
	}

	public async getCalculateAngles(payload: IDefaultParameters) {
		const url: string = '/ergonomic-tool/reba/calculate-angles';
		const { data } = await Api.post<DefaultResponseDTO>(url, payload);
		return data;
	}
}
