import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Card } from '../../Card';
import { CustomButton } from '../../styles';
import { CustomColSelectedStepKeysList } from './styles';

export const EmptyList: React.FC = () => (
	<Row justify="center">
		<Card title="Selected criteria">
			<Row justify="center" gutter={[0, 18]}>
				<CustomColSelectedStepKeysList xs={24}>
					<Divider plain>{I18n.get('No criteria selected')}</Divider>
				</CustomColSelectedStepKeysList>
				<Col style={{ display: 'flex', alignItems: 'flex-end' }}>
					<Row justify="end">
						<CustomButton type="primary" disabled={true}>
							{I18n.get('Forward')}
						</CustomButton>
					</Row>
				</Col>
			</Row>
		</Card>
	</Row>
);
