import React, { useEffect, useState } from 'react';
import { Col, Divider, Progress, Row, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import Hooks from '../../hooks';
import { colorMapper, DashboardQueryParams } from '../../types';
import { useApplicationContext } from '@/context/Application';
import { ActionPlanCardContainer, ActionPlanDeadline, ActionPlanTitle, LinkCustom } from './styles';

const { useDashboardActionPlansCards } = Hooks;
interface ActionPlansProps {
	queryParams: DashboardQueryParams;
}

// https://stackoverflow.com/questions/67699376/react-infinite-scroll-component-stopped-working-after-one-call-loadmore-only

export const ActionPlans: React.FC<ActionPlansProps> = ({ queryParams }) => {
	const [offset, setOffset] = useState(0);
	const { organization, company } = useApplicationContext();
	const { data, isError, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } = useDashboardActionPlansCards(
		organization.id,
		company.id,
		queryParams,
		offset
	);

	useEffect(() => {
		if (data.pages?.length > 0) {
			setOffset(data.pages.length - 1);
		}
	}, [data?.pages]);

	async function loadMoreData() {
		if (hasNextPage) {
			await fetchNextPage();
			setOffset(offset + 1);
		}
	}

	if (isError) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<Col id="actionPlansList" span={24} style={{ maxHeight: '300px', overflowY: 'auto' }}>
			{isSuccess &&
				data.pages?.map(
					({ action_cards, total_plans }) =>
						total_plans > 0 && (
							<InfiniteScroll
								key={action_cards[0]?.id}
								dataLength={data.pages.reduce((acc, page) => acc + page.action_cards.length, 0)}
								pullDownToRefreshThreshold={50}
								next={loadMoreData}
								hasMore={!isFetchingNextPage && hasNextPage !== undefined ? hasNextPage : true}
								loader={<Skeleton paragraph={{ rows: 1 }} active />}
								scrollableTarget="actionPlansList"
							>
								{action_cards.map((actionCard, index) => {
									return (
										<LinkCustom
											key={index}
											to={{
												pathname: '/action-plans',
												search: `?id=${actionCard.id}`
											}}
										>
											<ActionPlanCardContainer
												key={actionCard.id}
												color={colorMapper[actionCard.result] + '33'}
											>
												<Row justify={'space-between'}>
													<Col span={20}>
														<ActionPlanTitle>{I18n.get(actionCard.card)}</ActionPlanTitle>
													</Col>
													<Col span={'auto'}>
														<ActionPlanDeadline>
															{moment(actionCard.deadline).format('DD/MM/YYYY')}
														</ActionPlanDeadline>
													</Col>
												</Row>
												<Row>
													<Col flex={1}>
														<Progress
															showInfo={false}
															trailColor="#00000033"
															percent={actionCard.completed}
															strokeColor={colorMapper[actionCard.result]}
														/>
													</Col>
												</Row>
											</ActionPlanCardContainer>
										</LinkCustom>
									);
								})}
							</InfiniteScroll>
						)
				)}
			{!hasNextPage && <Divider plain>{I18n.get('No more action plans available')}</Divider>}
		</Col>
	);
};
