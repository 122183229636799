import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { usePreliminaryAnalysis } from '../../../context';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

export const Footer = ({ current, steps, onNext, onPrev, isLoading }) => {
	const history = useHistory();
	const { preliminaryAnalysis, consolidateClick } = usePreliminaryAnalysis();

	function onChange() {
		history.push('/reporting');
	}

	return (
		<>
			{current > 0 && !preliminaryAnalysis.data?.consolidated && (
				<Button
					style={{
						margin: '0 8px'
					}}
					disabled={preliminaryAnalysis.isFetching || consolidateClick}
					onClick={onPrev}
				>
					{I18n.get('Previous')}
				</Button>
			)}
			{current < steps.length - 1 && (
				<Button type="primary" onClick={onNext}>
					{I18n.get('Next')}
				</Button>
			)}
			{current === steps.length - 1 && (
				<Button
					type="primary"
					htmlType="submit"
					onClick={onChange}
					loading={isLoading}
					disabled={preliminaryAnalysis.isFetching || consolidateClick}
					style={{ marginTop: '35px' }}
				>
					{I18n.get('Finish')}
				</Button>
			)}
		</>
	);
};

Footer.propTypes = {
	current: PropTypes.number.isRequired,
	steps: PropTypes.array.isRequired,
	onNext: PropTypes.func.isRequired,
	onPrev: PropTypes.func.isRequired
};
