import React, { ReactNode } from 'react';
import { MailOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Form } from 'antd';

import { MethodTypes } from '@/types/AuthUser';
import { I18n } from '@aws-amplify/core';

import {
	MethodIcon,
	MethodInformations,
	MethodRadioButton,
	MethodRadioGroup,
	MethodTitle,
	MethodsContainer
} from './styles';

interface DataType {
	title: string;
	description: string;
	icon: ReactNode;
	type: string;
	disabled: boolean;
}

export const Methods: React.FC = () => {
	const list: DataType[] = [
		{
			title: 'Authenticator app',
			description:
				'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login.',
			icon: <QrcodeOutlined />,
			type: MethodTypes.AUTHENTICATOR,
			disabled: false
		},
		{
			title: 'E-mail',
			description:
				'With this method, you will receive the login code in your registered email. At the time of login, you must fill in the indicated field with the code sent to your email.',
			icon: <MailOutlined />,
			type: MethodTypes.EMAIL,
			disabled: true
		}
	];

	return (
		<MethodsContainer justify="center">
			<Form.Item noStyle name="method_type">
				<MethodRadioGroup>
					{list.map((item) => (
						<MethodRadioButton key={item.title} value={item.type} disabled={item.disabled}>
							<div className="info">
								<MethodIcon>{item.icon}</MethodIcon>
								<MethodInformations>
									<MethodTitle>{I18n.get(item.title)}</MethodTitle>
									<span>{I18n.get(item.description)}</span>
								</MethodInformations>
							</div>
						</MethodRadioButton>
					))}
				</MethodRadioGroup>
			</Form.Item>
		</MethodsContainer>
	);
};
