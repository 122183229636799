import { BaseContextType } from '@/types';
import Api from '@/services/api';

import { ActionPlanTaskDTO, ChecklistActionPlanDTO } from './types/dto/actionPlan';
import { RebaAnglesResultsDTO, RebaError, RebaResultsDTO } from './types/dto/reba';
import { PreliminaryAnalysisByStepName } from './types/dto/preliminaryAnalysis';
import { ResultsDTO } from './Form/Steps/Result/types';
import { ExhibitionDTO } from './types/dto/exhibition';
import { FileDTO } from './types/dto/file';
import { UserDTO } from './types/dto/user';
import {
	UpdateChecklistActionPlanRequest,
	CreateChecklistActionPlanRequest,
	GetActionPlanByStepIdRequest,
	GetTasksRequest
} from './types/request/actionPlan';

type BaseContext = {
	organizationId: string;
	companyId: string;
	fileId?: string;
	id?: string;
	payload?: any;
};

type BaseContextWithId = {
	organizationId: string;
	companyId: string;
	fileId?: string;
	id?: string;
};

async function getFile({ organizationId, companyId, fileId }: BaseContext) {
	const url = `/upload/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

async function getSectors({ organizationId, companyId }: BaseContext) {
	const url = `/sector/${organizationId}/${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

async function setUpdateStep({ organizationId, companyId, fileId, payload }: BaseContext) {
	const url = `/ergonomic-tool/preliminary-analysis/update-data/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.put(url, payload);
	return data;
}

async function setCalculteRisk({ organizationId, companyId, fileId, id, payload }: BaseContext) {
	const url = '/ergonomic-tool/preliminary-analysis/calculate-risk';
	const body = {
		organization_id: organizationId,
		company_id: companyId,
		file_id: fileId,
		id,
		parameters: payload
	};
	const { data } = await Api.post(url, body);
	return data;
}

async function getFindOrCreateAnalysis({ organizationId, companyId, fileId }: BaseContext) {
	const url = `/ergonomic-tool/preliminary-analysis/find-or-create/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<FileDTO>(url);
	return data;
}

async function getPreliminaryAnalysisList({ organizationId, companyId }: BaseContext) {
	const url = `/ergonomic-tool/preliminary-analysis/?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

async function getExhibitions({ organizationId, companyId }: BaseContext) {
	const url = `/ergonomic-tool/exposures?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<ExhibitionDTO[]>(url);
	return data;
}

async function getResults({ organizationId, companyId, fileId }: BaseContext) {
	const url = `/ergonomic-tool/preliminary-analysis/results/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<ResultsDTO>(url);
	return data;
}

async function createChecklistActionPlan(body: CreateChecklistActionPlanRequest) {
	const url = `/action_plan/checklist`;
	const { data } = await Api.post<{ newActionPlan: ChecklistActionPlanDTO }>(url, body);

	return data;
}

async function updateChecklistActionPlan(body: UpdateChecklistActionPlanRequest) {
	const url = `/action_plan/checklist/:action_plan_id`;
	const { data } = await Api.put<ChecklistActionPlanDTO>(url, body);

	return data;
}

async function getPreliminaryAnalysisByStepName({ companyId, organizationId, fileId, id, payload }: BaseContext) {
	let query = `organization_id=${organizationId}&company_id=${companyId}`;

	if (fileId) {
		query = `${query}&file_id=${fileId}`;
	}
	if (id) {
		query = `${query}&id=${id}`;
	}

	const url = `/ergonomic-tool/preliminary-analysis/step_name/${payload}?${query}`;
	const { data } = await Api.get<PreliminaryAnalysisByStepName>(url);

	return data;
}

async function getUsers({ organizationId, companyId }: BaseContext) {
	const url = `/user/user-list/${organizationId}/${companyId}`;
	const { data } = await Api.get<UserDTO[]>(url);
	return data;
}

async function getRebaAngles({ organizationId, companyId, fileId }: BaseContextType) {
	const url = `/ergonomic-tool/reba/score-parts/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<RebaAnglesResultsDTO>(url);
	return data;
}

async function getTasks({ actionPlanId, organizationId, companyId }: GetTasksRequest) {
	const url = `/action_plan/${actionPlanId}/task?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<ActionPlanTaskDTO[]>(url);
	return data;
}

async function getActionPlanByStepId({ stepId, organizationId, companyId }: GetActionPlanByStepIdRequest) {
	const url = `/action_plan/checklist/${stepId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<ChecklistActionPlanDTO>(url);
	return data;
}

async function getReba({ organizationId, companyId, fileId }: BaseContextType) {
	const url = `/ergonomic-tool/reba/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<RebaResultsDTO | RebaError>(url);
	return data;
}

async function createStepDefault({ organizationId, companyId, fileId, id }: BaseContextWithId) {
	const url = '/ergonomic-tool/preliminary-analysis/create-step-default';
	const body = {
		organization_id: organizationId,
		company_id: companyId,
		file_id: fileId,
		id,
	};
	const { data } = await Api.post(url, body);
	return data;
}

const Services = {
	getFile,
	getSectors,
	getExhibitions,
	getResults,
	getRebaAngles,
	setUpdateStep,
	setCalculteRisk,
	getFindOrCreateAnalysis,
	getPreliminaryAnalysisList,
	createChecklistActionPlan,
	updateChecklistActionPlan,
	getPreliminaryAnalysisByStepName,
	getActionPlanByStepId,
	getTasks,
	getUsers,
	getReba,
	createStepDefault
};

export default Services;
