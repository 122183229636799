import styled from 'styled-components';

export const Description = styled.span`
	font-size: 0.9rem;
	color: #2cc852;
	font-weight: 600;
`;

export const Score = styled.span`
	font-size: 0.75rem;
	font-weight: 600;
`;
