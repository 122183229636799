import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Spin } from 'antd';

import { useManageOrganization } from '../../../context';
import { SelectCompany } from './SelectCompany';
import hooks from '../../../hooks';

const { useGetCompanies } = hooks;

export const ManageCompany = () => {
	const { organizationId, organization } = useManageOrganization();

	const {
		data: companies,
		isError: errorGettingCompanies,
		isLoading: gettingCompanies,
		isFetching: fetchingCompanies,
		refetch: companyRefetch
	} = useGetCompanies(organizationId);

	if (errorGettingCompanies) {
		return I18n.get('Oops... Something went wrong!');
	}

	if (fetchingCompanies || gettingCompanies) {
		const antIcon = <LoadingOutlined style={{ fontSize: 50, marginBottom: '120px' }} spin />;
		return <Spin indicator={antIcon} />;
	}

	return <SelectCompany companies={companies} companyRefetch={companyRefetch} organization={organization} />;
};
