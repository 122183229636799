import styled from 'styled-components';

export const RiskDataContainer = styled.div`
	padding: 0.66rem 0;
	height: 100%;
`;

export const Caption = styled.h4`
	font-weight: 400;
	text-align: left;
`;

export const Value = styled.h4`
	font-weight: 600;
	text-align: right;
`;
