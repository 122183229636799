import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row } from 'antd';
import _ from 'lodash';

import { useContractPlansContext } from '@/components/Settings/ContractPlans/context';
import { useCreateCompany } from '@/components/Settings/ContractPlans/hooks/index';
import { ProcessingControl } from './ProcessingControl';
import { PrimaryField } from '../../../../components';
import { CustomButton } from '../../../../styles';
import { Information } from './Information';
import * as S from './styles';

const { useFormInstance } = Form;

interface CreateCompanyProps {
	isCreating: boolean;
	isLoading: boolean;
	onClick(): void;
}

export function CreateCompany({ isCreating, isLoading, onClick }: Readonly<CreateCompanyProps>) {
	const form = useFormInstance();
	const { queryUrl } = useContractPlansContext();

	const organizationId = queryUrl?.get('organization_id');

	const { mutateAsync: createCompany } = useCreateCompany();

	function resetFields() {
		form.setFieldValue('address', '');
		form.setFieldValue('company_nit', '');
		form.setFieldValue('company_plan', '');
		form.setFieldValue('company_name', '');
		form.setFieldValue('company_city', '');
		form.setFieldValue('company_address', '');
		form.setFieldValue('company_district', '');
		form.setFieldValue('company_max_uploads', '');
		form.setFieldValue('company_max_minutes', '');
	}

	async function handleSubmit() {
		const values = await form.validateFields();

		const payload = {
			organization_id: organizationId,
			company_name: values.company_name
		};

		if (!values.processing_control_check) {
			const plan = {
				recurrence: values.company_recurrence,
				max_minutes: values.company_max_minutes,
				max_uploads: values.company_max_uploads,
				expiration_day: values.company_renewal_day,
				expiration_month: values.company_renewal_month
			};

			_.set(payload, 'plan', plan);
		}

		if (!values.company_info_check) {
			const business_information = {
				nit: values.company_nit,
				city: values.company_city,
				address: values.company_address,
				district: values.company_district,
				contract_plan_id: values.company_plan
			};

			_.set(payload, 'business_information', business_information);
		}

		await createCompany(payload);

		resetFields();
		onClick();
	}

	if (isLoading) {
		return <></>;
	}

	if (!isCreating) {
		return (
			<Col span={24} style={{ marginBottom: '2rem' }}>
				<Row justify="center">
					<Col>
						<S.AddButton onClick={onClick}>
							<PlusCircleFilled />
						</S.AddButton>
					</Col>
				</Row>
			</Col>
		);
	}

	return (
		<S.Container>
			<Row justify="center" gutter={[0, 30]}>
				<Col span={24}>
					<PrimaryField
						required
						name="company_name"
						title={I18n.get('Industrial site')}
						errorMessage={I18n.get('Enter a industrial site')}
					/>
				</Col>
				<Col span={24}>
					<Information />
				</Col>
				<Col span={24}>
					<ProcessingControl />
				</Col>
				<Col>
					<CustomButton onClick={handleSubmit} type="primary">
						{I18n.get('Add')}
					</CustomButton>
				</Col>
			</Row>
		</S.Container>
	);
}
