import styled from 'styled-components';

export const AllInfosContainer = styled.div`
	margin: 70px;
	display: flex;
	align-items: center;

	@media (max-width: 1600px) {
		margin: 60px 70px 60px 70px;
	}
`;

export const ScoreContainer = styled.div<any>`
	padding: 0.5rem 1rem;
	border-radius: 4px;
	text-align: center;
	font-size: 1rem;
	font-weight: 600;
	width: 100%;
	max-width: 150px;
	border: 1px solid ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};

	span {
		color: ${(props) => props.color};
	}

	@media (max-width: 1600px) {
		scale: calc(0.8);
	}
`;

export const Risk = styled.span<any>`
	font-size: 1rem;
	font-weight: 600;
	margin-top: 1rem;
	color: ${(props) => props.color};

	@media (max-width: 1600px) {
		font-size: 0.9rem;
	}
`;
