import { AuthService } from '@/services/authService';
import { useMutation } from '@tanstack/react-query';

const authService = new AuthService();

function useForgotPassword() {
	return useMutation((email: string) => authService.forgotPassword(email));
}

export { useForgotPassword };
