import Api from '@/services/api';
import { IUseBodyParts, BodyPartsDTO } from './types';

export const Services = {
	getBodyParts: async (payload: IUseBodyParts) => {
		const { organization_id, company_id, file_id } = payload;
		const url = `/ergonomic-tool/reba/body-parts/angles/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<BodyPartsDTO>(url);
		return data;
	}
};
