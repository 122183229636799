import React from 'react';
import { Button } from 'antd';
import { I18n } from '@aws-amplify/core';

interface OptionDropdownProps {
	children: string;
	disabled?: boolean;
	onClick: React.MouseEventHandler<HTMLElement>;
}

export const OptionDropdown: React.FC<OptionDropdownProps> = ({
	onClick,
	children,
	disabled = false
}): JSX.Element => (
	<Button type="link" disabled={disabled} onClick={onClick}>
		<span>{I18n.get(children)}</span>
	</Button>
);
