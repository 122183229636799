import React from 'react';
import { Col, Row } from 'antd';

import { MailOutlined } from '@ant-design/icons';
import { TFATips } from '@/types/AuthUser';

import { IconContainer, Tip } from './styles';

export const GenerateEmail2FA = () => {
	return (
		<Col>
			<IconContainer justify="center">
				<MailOutlined />
			</IconContainer>
			<Row justify="center" style={{ marginBottom: '7.5rem' }}>
				<Tip style={{ width: '52%' }}>{TFATips.TIP_EMAIL}</Tip>
			</Row>
		</Col>
	);
};
