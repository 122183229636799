import { Text } from '@/components/Typography';
import { Modal, Button } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
	.ant-modal-content {
		padding: 30px 0;
		border-radius: 30px;
		min-width: 400px;
		max-width: 550px;
	}
`;

export const RoundButton = styled(Button)`
	font-size: 1rem;
	border-radius: 6px;
	width: 110px !important;
	height: 35px !important;
`;

export const Description = styled(Text)`
	width: 100%;
	display: flex;
	font-size: 1.4rem;
	text-align: center;
`;
