import React from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Link } from '@/components/Link';

export const Redirect: React.FC = () => (
	<Col span={24}>
		<Link align="center" path="/login">
			{I18n.get('Return to login without SSO')}
		</Link>
	</Col>
);
