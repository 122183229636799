import styled from 'styled-components';

export const Container = styled.div`
	border: 1px solid #d9d9d9;
	border-radius: 0.25rem;
	width: 100%;
`;

export const WorstScore = styled.div`
	padding: 0.75rem 1rem;
	font-weight: 600;
	font-size: 0.8rem;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Score = styled.div`
	font-size: 1.2rem;
	font-weight: 600;
	margin-left: 1rem;
`;
