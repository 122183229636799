import React from 'react';
import { Row, Col, Divider } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { CreateStepKey } from './CreateStepKey';
import { SelectStepKey } from './SelectStepKey';
import { SelectedSteps } from './SelectedSteps';
import { SelectedStepKeysList } from './SelectedStepKeysList';
import { CustomReportManagerStepKeyProvider } from './context';

export function CustomReportStepKey() {
	const breakpoints = useBreakpoint();
	return (
		<CustomReportManagerStepKeyProvider>
			<Row justify="space-between" gutter={[32, 32]}>
				<Col span={24}>
					<SelectedSteps />
				</Col>
				<Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
					<Row justify="center" gutter={[32, 32]}>
						<Col span={24}>
							<CreateStepKey />
						</Col>
						<Col span={24}>
							<SelectStepKey />
						</Col>
					</Row>
				</Col>
				{breakpoints.lg && breakpoints.xl && (
					<Col lg={2} xl={2} style={{ textAlign: 'center' }}>
						<Divider type="vertical" style={{ height: '100%' }} />
					</Col>
				)}
				<Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
					<SelectedStepKeysList />
				</Col>
			</Row>
		</CustomReportManagerStepKeyProvider>
	);
}
