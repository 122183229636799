import styled from 'styled-components';
import { Button, Col, Row } from 'antd';

import { Text } from '@/components/Typography';

export const CardContainer = styled.div`
	gap: 1.5rem;
	display: flex;
	height: 22rem;
	user-select: none;
	position: relative;
	border-radius: 2rem;
	padding: 2.5rem 1.5rem;
	background-color: #fff;
	flex-direction: column;

	#not-hovered {
		display: block;
	}
`;

export const ActionsEllipsisContainer = styled(Button)`
	top: 1.5rem;
	right: 1rem;
	border: none;
	gap: 0.25rem;
	display: flex;
	z-index: 1;
	padding: 0 0.5rem;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	transition: filter 0.2s;
	&:hover {
		cursor: pointer;
		filter: brightness(1.5);
		background-color: #2f54eb;
		#dot {
			background-color: #ffffff;
		}
		button {
			background-color: #2f54eb;
		}
	}
`;

export const ActionsEllipsisDot = styled.span`
	width: 5px;
	height: 5px;
	border-radius: 50%;
	display: inline-block;
	background-color: #262626;
`;

export const TitleCol = styled(Col)`
	text-align: center;
`;

export const Title = styled(Text)`
	font-weight: 600;
	overflow: hidden;
	font-size: 1.2rem;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
`;

export const SubtitleRow = styled(Row)`
	margin-bottom: 1rem;
`;

export const Subtitle = styled(Text)`
	font-size: 1rem;
	font-weight: 600;
`;

export const TextContent = styled(Text)`
	font-size: 1rem;
	font-weight: 400;
	margin-left: 0.25rem;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;
