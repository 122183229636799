import * as React from 'react';

export function Chair(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 60 57" xmlns="http://www.w3.org/2000/svg" fill="transparent" {...props}>
			<path
				strokeWidth={4}
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M2 28H57.5M6 28V50H54.5V28H6Z"
			/>
			<path
				strokeWidth={4}
				d="M12 50V55H13V50"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				strokeWidth={4}
				d="M48 50V55H49V50"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				strokeWidth={4}
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M26.5 27V18.75C26.5 16.6789 28.1789 15 30.25 15V15C32.3211 15 34 16.6789 34 18.75V27"
			/>
			<path
				strokeWidth={4}
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M25.5 21.5H15.5V2H45V21.5H35"
			/>
		</svg>
	);
}
