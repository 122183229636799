import React from 'react';
import { Col, Row, Form } from 'antd';

import { I18n } from '@aws-amplify/core';

import { ConfirmButton, CustomButton } from './styles';
import { NamePath } from 'antd/lib/form/interface';
import { useTFAContext } from '../context';

interface ButtonsProps {
	resetFields: (fields?: NamePath[] | undefined) => void;
	totalSteps: number;
}

const { useFormInstance } = Form;

export const Buttons: React.FC<ButtonsProps> = ({ resetFields, totalSteps }) => {
	const { validateFields, getFieldValue, isFieldsTouched, getFieldsError } = useFormInstance();

	const { currentStep, generated2FA, selected2FAType, currentActionStep } = useTFAContext();
	const {
		handlePreviousStep,
		handleResetStep,
		handleOpenGenerateTokenModal,
		handleNextStep,
		handleGenerate2FA,
		handleValidate2FA,
		handleOpenActionModal
	} = useTFAContext();

	function handlePreviousClick() {
		resetFields();
		handlePreviousStep();
	}

	async function handleGenerateClick() {
		if (generated2FA.id) {
			return handleNextStep();
		}

		const generated = await handleGenerate2FA();
		if (generated) {
			handleNextStep();
		}
	}

	function handleOkClick() {
		resetFields();
		handleOpenGenerateTokenModal(false);
		handleResetStep();
	}

	function validateTokenField() {
		const fields_touched = isFieldsTouched(true);
		const has_errors = getFieldsError().some(({ errors }) => errors.length > 0);

		if (!fields_touched || has_errors) {
			return true;
		}

		return false;
	}

	async function handleValidateClick() {
		await validateFields(['validation_code']);

		const token = getFieldValue('validation_code');

		const validated = await handleValidate2FA(selected2FAType, token);

		if (validated.tokens_array.length > 0) {
			handleNextStep();
		} else {
			handleOpenGenerateTokenModal(false);
		}

		if (currentActionStep === 1) {
			handleOpenActionModal(false);
		}
	}

	return (
		<>
			{currentStep >= 1 && currentStep < totalSteps && (
				<Row justify="center" style={{ marginBottom: '6rem', width: '100%' }}>
					<Col>
						<CustomButton onClick={handlePreviousClick} style={{ marginRight: '2rem' }}>
							{I18n.get('Back')}
						</CustomButton>
					</Col>
					{currentStep === 1 && (
						<Col>
							<CustomButton onClick={handleGenerateClick} type="primary">
								{I18n.get('Next')}
							</CustomButton>
						</Col>
					)}
					{currentStep === 2 && (
						<Form.Item shouldUpdate>
							{() => (
								<CustomButton
									type="primary"
									htmlType="submit"
									onClick={handleValidateClick}
									disabled={validateTokenField()}
								>
									{I18n.get('Confirm')}
								</CustomButton>
							)}
						</Form.Item>
					)}
				</Row>
			)}
			{currentStep === totalSteps && (
				<Col flex={1}>
					<Row justify="center">
						<ConfirmButton htmlType="submit" onClick={handleOkClick} type="primary">
							{I18n.get('Confirm')}
						</ConfirmButton>
					</Row>
				</Col>
			)}
		</>
	);
};
