import React, { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { Confirm, NameInput } from '../styles';
import { useManageOrganization } from '../../../context';
import { CompanyDTO } from '../../../types';

const { useFormInstance } = Form;

interface EditCompanyProps {
	companies: CompanyDTO[];
	companyName: string;
	onEditing: (isEditing: boolean) => void;
	onCompanyNameChange: (name: string) => void;
}

export const EditCompany: React.FC<EditCompanyProps> = ({ companies, companyName, onCompanyNameChange, onEditing }) => {
	const [editingName] = useState(companyName);
	const { updateOrganizationData, treatFieldValidation, handleIsEditing, companyId, organizationId } =
		useManageOrganization();

	const { setFieldValue } = useFormInstance();

	function handleCompanyNameChange(name: string) {
		onCompanyNameChange(name);
	}

	function handleCancel() {
		onCompanyNameChange('');
		onEditing(false);
		handleIsEditing(false);
		setFieldValue('company_id', companyId);
	}

	async function handleUpdateCompany(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		try {
			if (companies.find((elem) => elem.name === companyName)) {
				const content = I18n.get(`This name already exists. Do you wish to proceed ?`);
				Modal.confirm({
					title: I18n.get('Warning!'),
					icon: <ExclamationCircleOutlined />,
					content: content,
					okType: 'danger',
					okText: I18n.get('Yes'),
					cancelText: I18n.get('Cancel'),
					onOk: async () => {
						updateOrganizationData({
							organization_id: organizationId,
							company_id: companyId,
							name: companyName
						}).then(({ id }) => {
							onCompanyNameChange('');
							onEditing(false);
							handleIsEditing(false);
							setFieldValue('company_id', id);
							setFieldValue('edit_company_name', '');
						});
					}
				});
			} else {
				updateOrganizationData({
					organization_id: organizationId,
					company_id: companyId,
					name: companyName
				}).then(({ id }) => {
					onCompanyNameChange('');
					onEditing(false);
					handleIsEditing(false);
					setFieldValue('company_id', id);
					setFieldValue('edit_company_name', '');
				});
			}
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_company_name"
						label={I18n.get('Edit industrial site')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								message: I18n.get('Please, enter a valid industrial site name')
							}
						]}
					>
						<Input
							style={{
								flex: 'auto'
							}}
							value={companyName}
							onChange={(e) => handleCompanyNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Col span={12}>
							<Button
								disabled={!companyId}
								size="middle"
								type="primary"
								danger={true}
								ghost={true}
								onClick={() => handleCancel()}
								icon={<CloseOutlined />}
							/>
						</Col>
						<Confirm span={12}>
							<Button
								disabled={!companyId || !companyName || editingName === companyName}
								size="middle"
								type="primary"
								ghost={true}
								onClick={(e) => handleUpdateCompany(e)}
								icon={<CheckOutlined />}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
