import React from 'react';
import { Col, Space } from 'antd';
import * as Typography from '@/components/Typography';

export const SubTitle: React.FC = () => (
	<Col span={22}>
		<Space direction="horizontal" style={{ width: '100%', textAlign: 'center' }}>
			<Typography.Text>
				Your organization uses single sign-on (SSO). Use your corporate email to access Kinebot.
			</Typography.Text>
		</Space>
	</Col>
);
