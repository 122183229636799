import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import Api from '@/services/api';

interface Parameters {
	organization_id: string;
	company_id: string;
	file_id: string;
	id: string;
	locale: string;
}

async function getStrainIndexReportPDF(payload: Parameters): Promise<string> {
	const url = '/ergonomic-tool/strain-index/document/pdf';
	const { data } = await Api.post(url, payload);
	return data;
}

export const useStrainIndexReportDownloadPDF = () => {
	return useMutation(getStrainIndexReportPDF, {
		onSuccess: (url) => {
			window.open(url);
			message.success(I18n.get('Document created successfully'));
		},
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};
