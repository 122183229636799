import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { SubTitleStyled, TitleHeaderStyled } from '../../styles';
import { ManageCompany } from '../../Manage/ManageCompany';

export function SecondPage() {
	return (
		<Row justify="center" gutter={[24, 0]}>
			<Col span={24}>
				<Row justify="center" align="middle">
					<Col span="auto">
						<TitleHeaderStyled level={1}>{I18n.get('Upload logos')}</TitleHeaderStyled>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row justify={'center'} gutter={[0, 20]}>
					<Col>
						<SubTitleStyled>{I18n.get('Upload your industrial site logo')}</SubTitleStyled>
					</Col>
				</Row>
				<Row justify={'center'} style={{ marginTop: '44px' }}>
					<ManageCompany />
				</Row>
			</Col>
		</Row>
	);
}
