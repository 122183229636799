import React from 'react';
import { Row, Col, Divider } from 'antd';
import { Settings } from './Settings';
import { GeneralInformations } from './GeneralInformations';

export function CustomReport() {
	return (
		<Row justify="space-between" gutter={[32, 32]}>
			<Col xs={24} sm={24} xl={13} xxl={11}>
				<GeneralInformations />
			</Col>
			<Col>
				<Divider type="vertical" style={{ height: '100%' }} />
			</Col>
			<Col xs={24} sm={24} xl={10} xxl={11}>
				<Settings />
			</Col>
		</Row>
	);
}
