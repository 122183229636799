import styled from 'styled-components';

export const Container = styled.div`
	.ant-collapse {
		border-radius: 10px !important;
		overflow: hidden;
		border: none;
	}

	.ant-select-selector {
		border-radius: 6px !important;
	}

	.ant-collapse-item {
		border: none;
	}

	input {
		border-radius: 6px;
	}

	.ant-picker {
		border-radius: 6px;
	}

	.ant-btn {
		border-radius: 6px;
		width: 100%;
	}
`;
