import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_ORGANIZATIONS_CONTRACT_PLANS } = QUERY_KEYS;

type CreateOrganizationPlanParams = {
	recurrence: string;
	max_minutes: number;
	max_uploads: number;
	expiration_day: number;
	organization_id: string;
};

async function updateOrganizationPlan(body: CreateOrganizationPlanParams) {
	const url = `/contract-plan/organization/plan`;
	const { data } = await Api.put<any>(url, body);
	return data;
}

export const useUpdateOrganizationPlan = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateOrganizationPlanParams) => updateOrganizationPlan(body), {
		onSuccess: () => {
			queryClient.invalidateQueries([GET_ORGANIZATIONS_CONTRACT_PLANS]);
		}
	});
};
