import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Card } from '../../Card';
import { CustomButton } from '../../styles';
import { CustomColSelectedStepsList } from './styles';

export function EmptyList() {
	return (
		<Row justify="center" style={{ height: '100%' }}>
			<Col span={24}>
				<Card title="Selected sections">
					<Row justify="center" gutter={[0, 20]}>
						<CustomColSelectedStepsList span={24}>
							<Divider plain>{I18n.get('No sections selected')}</Divider>
						</CustomColSelectedStepsList>
						<Col span={24} style={{ textAlign: 'center' }}>
							<CustomButton type="primary" disabled={true}>
								{I18n.get('Forward')}
							</CustomButton>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
}
