import { Row } from 'antd';
import styled from 'styled-components';

export const CustomTableContainer = styled(Row)`
	cursor: pointer;

	.ant-table {
		table {
			border-collapse: collapse;
		}
		.selected-row {
			background-color: #eaeefd;
			border: 2px solid #2f54eb !important;

			.ant-table-cell-row-hover {
				background-color: #eaeefd;
			}
		}
	}
`;
