import { ApplicationReducerTypes, State, ActionType } from './types';

export const ApplicationReducer = (state: State, action: ActionType) => {
	switch (action.type) {
		case ApplicationReducerTypes.CLEAR_PROJECT:
			return {
				organization: null,
				company: null,
				info_user: null,
				user: null
			};

		case ApplicationReducerTypes.SET_ORGANIZATION:
			return {
				...state,
				organization: action.payload
			};

		case ApplicationReducerTypes.SET_COMPANY:
			return {
				...state,
				company: action.payload
			};

		case ApplicationReducerTypes.SET_USER:
			return {
				...state,
				info_user: action.payload,
				user: action.payload.user
			};

		default:
			return state;
	}
};
