import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, PaginationProps, Row, Skeleton } from 'antd';

import { Text } from '@/components/Typography';
import { PaginationStyled, SelectStyled } from './styles';

type PageOption = {
	value: number;
	label: string;
};

interface PaginationStyledProps extends PaginationProps {
	loading?: boolean;
	pageOptions?: PageOption[];
	onRowsPerPageChange: (rows: number) => void;
}

export const PaginationCustom: React.FC<PaginationStyledProps> = ({
	loading,
	pageSize,
	pageOptions,
	onRowsPerPageChange,
	showSizeChanger = true,
	...props
}) => {
	const defaultOptions: PageOption[] = [
		{
			value: 5,
			label: '5'
		},
		{
			value: 10,
			label: '10'
		},
		{
			value: 20,
			label: '20'
		},
		{
			value: 30,
			label: '30'
		},
		{
			value: 50,
			label: '50'
		},
		{
			value: 100,
			label: '100'
		},
		{
			value: 200,
			label: '200'
		}
	];

	function handleRowsPerPageChange(rows: any) {
		onRowsPerPageChange(rows);
	}

	if (loading) {
		return (
			<Row justify="space-between" style={{ marginTop: '1rem', padding: '0 1rem' }}>
				<Col>{showSizeChanger && <Skeleton.Input />}</Col>
				<Col>
					<Skeleton.Input />
					<Skeleton.Input />
				</Col>
			</Row>
		);
	}

	return (
		<Row justify="space-between" style={{ marginTop: '1rem', padding: '0 1rem' }}>
			<Col>
				{showSizeChanger && (
					<>
						<Text>{I18n.get('Items per page')}</Text>
						<SelectStyled
							defaultValue={pageSize}
							onChange={handleRowsPerPageChange}
							options={pageOptions ?? defaultOptions}
						/>
					</>
				)}
			</Col>
			<Col>
				<PaginationStyled pageSize={pageSize} showSizeChanger={false} hideOnSinglePage={false} {...props} />
			</Col>
		</Row>
	);
};
