import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { KimMhoForceIntensity } from './useGetKimMho';
import { QUERY_KEYS } from '@/utils/query-keys';

type ReportInputs = {
	file_id?: string;
	report_id: string;
	report_name?: string;
	collection_date?: string;
	sector_id?: string;
	cellule_id?: string;
	workstation_id?: string;
	evaluator_id?: string;
	activity_id?: string;
	duration?: number;
	left_force_frequency?: number;
	left_force_type?: string;
	left_force_intensity?: KimMhoForceIntensity;
	right_force_frequency?: number;
	right_force_type?: string;
	right_force_intensity?: KimMhoForceIntensity;
	arm_posture?: string;
	work_conditions?: string;
	temporal_distribution?: string;
	body_posture?: string;
}

type UpdateKimMhoDTO = {
	organization_id: string;
	company_id: string;
	report_input: ReportInputs;
}

type UpdateKimMhoResponse = {
	id: string;
	company_id: string;
	organization_id: string;
	file_id?: string;
	report_name: string;
	collection_date: string;
	sector_id: string;
	cellule_id?: string;
	workstation_id: string;
	evaluator_id: string;
	duration?: number;
	left_force_frequency?: number;
	left_force_type?: string;
	left_force_intensity?: KimMhoForceIntensity;
	right_force_frequency?: number;
	right_force_type?: string;
	right_force_intensity?: KimMhoForceIntensity;
	arm_posture?: string;
	work_conditions?: string;
	temporal_distribution?: string;
	body_posture?: string;
};

type UpdateKimMhoError = AxiosError<any>;

async function updateReport(parameters: UpdateKimMhoDTO) {
	const { data } = await Api.put(`/ergonomic-tool/kim-mho/${parameters.report_input.report_id}`, parameters);
	return data;
}

export const useUpdateKimMho = () => {
	const queryClient = useQueryClient();
	return useMutation<UpdateKimMhoResponse, UpdateKimMhoError, UpdateKimMhoDTO>((payload) => updateReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_KIM_MHO_REPORT]);
			queryClient.invalidateQueries(['file']);
		}
	});
};
