import styled from 'styled-components';

export const Container = styled.div`
	.ant-table {
		overflow: hidden;
		border-radius: 10px !important;
	}

	tr.ant-table-expanded-row > td,
	tr.ant-table-expanded-row:hover > td {
		background: #fbfbfb !important;
		padding: 0px;
	}
`;

export const ContainerActions = styled.div`
	.ant-btn-background-ghost {
		border: none !important;
		box-shadow: none !important;
	}
`;
