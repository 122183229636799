import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, notification } from 'antd';
import { LockFilled, UserOutlined } from '@ant-design/icons';

import { useForgotPassword } from './hooks';
import { Text, Title } from '@/components/Typography';
import { PrimaryButton } from '@/components/ui/Buttons/PrimaryButton';
import { Container, CustomInput, IconContainer } from './styles';

const { useForm } = Form;

export const ForgotPasswordContent: React.FC = () => {
	const [form] = useForm();
	const [submited, setSubmited] = useState(false);
	const forgotPassword = useForgotPassword();

	async function onFinish() {
		const { email } = await form.validateFields();

		forgotPassword.mutate(email, {
			onSuccess: () => {
				const params = {
					message: I18n.get('Operation performed successfully'),
					description: I18n.get('Check your email inbox'),
					duration: 0
				};
				setSubmited(true);
				notification.success(params);
			},
			onError: () => {
				const params = {
					message: I18n.get('Oops... Something went wrong!')
				};
				notification.error(params);
			}
		});
	}

	return (
		<Container xs={24} sm={18} md={12} lg={10} xl={8} xxl={6}>
			<Row justify="center">
				<IconContainer>
					<LockFilled />
				</IconContainer>
			</Row>
			<Row justify="center">
				<Title level={4}>Forgot your password?</Title>
			</Row>
			<Row justify="center" style={{ marginTop: '0.8rem' }}>
				<Col xxl={23} style={{ textAlign: 'center', fontSize: '1rem', lineHeight: '1.3rem' }}>
					<Text>We will send you an e-mail to regain access to your account, rest assured!</Text>
				</Col>
			</Row>
			<Form form={form} onFinish={onFinish} style={{ marginTop: '0.8rem' }}>
				<Col sm={24}>
					<Form.Item
						name="email"
						style={{ paddingTop: '25px' }}
						rules={[
							{
								type: 'email',
								message: I18n.get('Please enter a valid e-mail address')
							},
							{
								required: true,
								message: I18n.get('Enter an e-mail')
							}
						]}
					>
						<CustomInput
							size="large"
							placeholder="E-mail"
							prefix={<UserOutlined className="site-form-item-icon" />}
						/>
					</Form.Item>
				</Col>
				<Col sm={24}>
					<Form.Item style={{ paddingTop: '0.2rem' }}>
						<PrimaryButton htmlType="submit" disabled={submited} loading={forgotPassword.isLoading}>
							{I18n.get('Send')}
						</PrimaryButton>
					</Form.Item>
				</Col>
			</Form>
			<Row style={{ marginTop: '2rem' }}>
				<a href="/login" style={{ fontSize: '0.85rem', marginBottom: '2rem' }}>
					{I18n.get('Back to login')}
				</a>
			</Row>
		</Container>
	);
};
