import Api from '@/services/api';

import { Force2FADTO, GetForced2FADTO } from './types/response';
import { Force2FARequest } from './types/request';

export class Force2FAService {
	public async getForced2FA(): Promise<GetForced2FADTO> {
		const url = '/2fa/force';
		const { data } = await Api.get<GetForced2FADTO>(url);
		return data;
	}

	public async force2FA(body: Force2FARequest): Promise<Force2FADTO> {
		const url = `/2fa/force?organization_id=${body.organization_id}&company_id=${body.company_id}`;
		const { data } = await Api.put<Force2FADTO>(url, body);
		return data;
	}
}
