import React, { useEffect, useState } from 'react';
import { Col, Form, Result, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/Application';
import { PinCodeInput } from '@/components/ui/Inputs/PinCodeInput';
import { Auth2FAParams, MethodTypes } from '@/types/AuthUser';
import { Spinner } from '@/components/Spinner';
import { Text } from '@/components/Typography';

import { CodeContainer, LinkButton, QRCode, ResendEmail } from './styles';
import { useGenerate2FA, useResendToken } from './hooks';

const { useFormInstance } = Form;

export const Validate2FA: React.FC = () => {
	const { getFieldValue } = useFormInstance();
	const { user_id, type, qr_code_url } = useParams<Auth2FAParams>();
	const { user } = useApplicationContext();

	const decoded_qr_code = qr_code_url ? decodeURIComponent(qr_code_url) : '';
	const [QRCodeUrl, setQRCodeUrl] = useState(decoded_qr_code);

	const type_form_value = getFieldValue('method_type');
	const method_type = type ? type : type_form_value;

	const { mutateAsync: generate2FA, isLoading: generateLoading, isError: generateError } = useGenerate2FA();
	const { mutateAsync: resendToken } = useResendToken();

	async function handleResendEmailClick() {
		const email = user.email;

		await resendToken({
			user_id,
			data: { email }
		});
	}

	async function generateToken() {
		const data = await generate2FA({
			user_id,
			type: method_type
		});

		if (data.qr_code_url) {
			setQRCodeUrl(data.qr_code_url);
		}
	}

	useEffect(() => {
		generateToken();
	}, []);

	if (generateLoading) {
		return (
			<Col style={{ margin: '10rem 0' }}>
				<Spinner />
			</Col>
		);
	}

	if (generateError) {
		return (
			<Col style={{ margin: '4rem 0' }}>
				<Result status="error" title={I18n.get('Oops!')} subTitle={I18n.get('Unable to generate token')} />
			</Col>
		);
	}

	if (method_type === MethodTypes.EMAIL) {
		return (
			<>
				<Row justify="center" style={{ marginTop: '3rem' }}>
					<Text style={{ fontSize: '1.5rem' }}>Validation token</Text>
				</Row>
				<Row justify="center" align="middle" style={{ height: '3.5rem', marginTop: '1.2rem' }}>
					<Col span={14} style={{ height: '100%' }}>
						<Form.Item
							noStyle
							name="validation_code"
							rules={[
								() => ({
									validator(_, value) {
										return value.length !== 6
											? Promise.reject(I18n.get('Enter the 6 digit code.'))
											: Promise.resolve();
									}
								})
							]}
						>
							<PinCodeInput allowedCharacters="numeric" />
						</Form.Item>
					</Col>
				</Row>
				<Row justify="center" style={{ margin: '5.5rem 0 4.3rem 0' }}>
					<ResendEmail>
						{I18n.get('If you have not received your code via email, you can try again by clicking')}{' '}
						<LinkButton type="link" onClick={handleResendEmailClick}>
							{I18n.get('here')}.
						</LinkButton>
					</ResendEmail>
				</Row>
			</>
		);
	}

	return (
		<>
			<CodeContainer justify="center">
				<QRCode alt="QRCode" preview={false} src={QRCodeUrl} />
			</CodeContainer>
			<Row justify="center" style={{ marginTop: '1.5rem' }}>
				<Text style={{ fontSize: '1.5rem' }}>Validation token</Text>
			</Row>
			<Row justify="center" align="middle" style={{ height: '3.5rem', marginTop: '1rem', marginBottom: '3rem' }}>
				<Col span={14} style={{ height: '100%' }}>
					<Form.Item
						noStyle
						name="validation_code"
						rules={[
							() => ({
								validator(_, value) {
									return value.length !== 6
										? Promise.reject(I18n.get('Enter the 6 digit code.'))
										: Promise.resolve();
								}
							})
						]}
					>
						<PinCodeInput allowedCharacters="numeric" />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};
