import { Checkbox } from 'antd';
import styled from 'styled-components';

export const CustomCheckboxGroup = styled(Checkbox.Group)`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	.ant-checkbox-group-item {
		display: flex;
		flex-direction: row;
	}
`;
