import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ActioPlanContainer = styled(Row)`
	padding: 1.5rem;
	width: 100%;
	font-size: 1rem;
`;

export const ActionPlanTitle = styled.span`
	font-weight: 600;
	font-size: 1.2rem;
`;

export const ActionPlanContent = styled(Col)`
	padding: 1rem 0 0 1rem;
	width: 100%;
`;

export const Title = styled.span`
	font-weight: 600;
`;

export const DeadlineContainer = styled(Row)`
	border: 1px solid #d9d9d9;
	border-radius: 0.25rem;
	padding: 0.25rem 0.5rem;
`;
