import React, { useEffect } from 'react';
import { Col, Form, Row, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ControlPeriod, MaxMinutesUsed, MaxVideosNumber, RenewalDay, RenewalMonth } from '../../../components';
import { SubTitle, SwitchFormItem } from '../../../styles';
import { Text } from '@/components/Typography';
import { FlexCol } from './styles';

const { useWatch, useFormInstance } = Form;

export function ProcessingControl() {
	const form = useFormInstance();

	const is_max_time_checked = useWatch('max_time_check');
	const is_max_videos_checked = useWatch('max_uploads_check');
	const recurrence = useWatch('recurrence');

	function hasSomeSwitchChecked() {
		return is_max_time_checked || is_max_videos_checked;
	}

	function switchValidator() {
		if (!is_max_time_checked && !is_max_videos_checked) {
			return Promise.reject(I18n.get('Select a value'));
		}

		return Promise.resolve();
	}

	useEffect(() => {
		form.setFieldValue('max_time_check', false);
		form.setFieldValue('max_uploads_check', false);
	}, []);

	return (
		<Row gutter={[0, 20]}>
			<Col span={24}>
				<SubTitle>{I18n.get('Processing control')}</SubTitle>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 20]}>
					<FlexCol span={24}>
						<Row align="middle" gutter={[10, 0]}>
							<Col>
								<SwitchFormItem name="max_time_check" rules={[{ validator: switchValidator }]}>
									<Switch checked={is_max_time_checked} />
								</SwitchFormItem>
							</Col>
							<Col>
								<Text style={{ fontSize: 15 }}>{I18n.get('Set the maximum processing time')}</Text>
							</Col>
						</Row>
					</FlexCol>
					<FlexCol span={24}>
						<Row align="middle" gutter={[10, 0]}>
							<Col>
								<SwitchFormItem name="max_uploads_check" rules={[{ validator: switchValidator }]}>
									<Switch checked={is_max_videos_checked} />
								</SwitchFormItem>
							</Col>
							<Col>
								<Text style={{ fontSize: 15 }}>
									{I18n.get('Set a maximum number of videos to process')}
								</Text>
							</Col>
						</Row>
					</FlexCol>
				</Row>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 15]}>
					{hasSomeSwitchChecked() && (
						<>
							<Col span={recurrence === 'yearly' ? 24 : 11}>
								<ControlPeriod name="recurrence" required />
							</Col>
							{recurrence === 'yearly' ? (
								<>
									<Col span={12}>
										<RenewalDay name="renewal_day" required />
									</Col>
									<Col span={12}>
										<RenewalMonth name="renewal_month" required />
									</Col>
								</>
							) : (
								<Col span={recurrence === 'yearly' ? 6 : 12}>
									<RenewalDay name="renewal_day" required />
								</Col>
							)}
						</>
					)}
					{is_max_time_checked && (
						<Col span={24}>
							<MaxMinutesUsed name="max_minutes" required />
						</Col>
					)}
					{is_max_videos_checked && (
						<Col span={24}>
							<MaxVideosNumber name="max_uploads" required />
						</Col>
					)}
				</Row>
			</Col>
		</Row>
	);
}
