import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Form, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useCustomReportManagerContext } from '../../context';
import { ChangeStepSequence, Context, Methods, States, StepList, StepToAdd } from './types';

interface CustomReportManagerStepProviderProps {
	children: ReactNode;
}

type NewStep = {
	id: string;
	sequence: number;
	description: string;
	step_setting_id?: string;
	custom_report_id?: string;
};

const { useFormInstance } = Form;

const CustomReportManagerStepContext = createContext<Context>({} as Context);

export const CustomReportManagerStepProvider: React.FC<CustomReportManagerStepProviderProps> = ({ children }) => {
	const form = useFormInstance();
	const { selectedSteps, selectedStepKeys, handleSelectedSteps, handleSelectedStepKeys } =
		useCustomReportManagerContext();

	const hasSelectedSteps = selectedSteps && selectedSteps.length > 0;
	const [stepsList, setStepsList] = useState<StepList[]>(hasSelectedSteps ? selectedSteps : []);

	useEffect(() => {
		handleSelectedSteps(stepsList);
	}, [stepsList]);

	function addStepToStepList(step: StepToAdd): void {
		const stepsListCopy = [...stepsList];
		const existingStep = stepsListCopy.find(({ id }) => step.id === id);
		if (existingStep) {
			return notification.error({
				message: `${I18n.get('Ops... something happened!')}`,
				description: `${I18n.get('Section has already been added')}`
			});
		}
		const newStep = defineNewStep(step);
		stepsListCopy.push(newStep);
		const mappedSteps = mapStepsSequence(stepsListCopy);
		setStepsList(mappedSteps);
		handleSelectedSteps(mappedSteps);
	}

	function defineNewStep(step: StepToAdd): NewStep {
		const lastStep = stepsList.pop();
		const lastStepSequence = lastStep?.sequence ?? 1;
		const workCondition = form.getFieldValue('has_work_conditions');
		const characteristics = form.getFieldValue('has_characteristics');
		let stepsToAdd = 1;
		if (workCondition) {
			stepsToAdd += 1;
		}
		if (characteristics) {
			stepsToAdd += 1;
		}
		const newStep = {
			...step,
			sequence: lastStepSequence + stepsToAdd
		};
		return newStep;
	}

	function removeStepFromStepList(step: StepList): void {
		const stepsListCopy = [...stepsList];
		const objectIndexToRemove = stepsListCopy.findIndex(({ id }) => step.id === id);
		stepsListCopy.splice(objectIndexToRemove, 1);
		const mappedSteps = mapStepsSequence(stepsListCopy);
		setStepsList(mappedSteps);
		handleSelectedSteps(mappedSteps);
		if (selectedStepKeys) {
			selectedStepKeys[step.id] = [];
			handleSelectedStepKeys(selectedStepKeys);
		}
	}

	function changeStepListSequence({ list, startIndex, endIndex }: ChangeStepSequence): void {
		const stepsListCopy = [...list];
		const [removed] = stepsListCopy.splice(startIndex, 1);
		stepsListCopy.splice(endIndex, 0, removed);
		const mappedSteps = mapStepsSequence(stepsListCopy);
		setStepsList(mappedSteps);
		handleSelectedSteps(mappedSteps);
	}

	function mapStepsSequence(stepsListCopy: StepList[]): NewStep[] {
		return stepsListCopy.map((step, index) => {
			const workCondition = form.getFieldValue('has_work_conditions');
			const characteristics = form.getFieldValue('has_characteristics');
			let newSequence = index + 1;
			if (workCondition) {
				newSequence += 1;
			}
			if (characteristics) {
				newSequence += 1;
			}
			return {
				...step,
				sequence: newSequence
			};
		});
	}

	const states: States = {
		stepsList
	};

	const methods: Methods = {
		addStepToStepList,
		removeStepFromStepList,
		changeStepListSequence
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<CustomReportManagerStepContext.Provider value={context}>{children}</CustomReportManagerStepContext.Provider>
	);
};

export function useCustomReportManagerStepContext() {
	const context = useContext(CustomReportManagerStepContext);
	return context;
}
