import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Confirm, NameInput } from '../styles';
import { useManageOrganization } from '../../../context';
import { SectorDTO } from '../../../types';

const { useFormInstance } = Form;

interface EditSectorProps {
	sectors: SectorDTO[];
	sectorName: string;
	onSectorNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
}

export const EditSector: React.FC<EditSectorProps> = ({ sectors, sectorName, onSectorNameChange, onEditing }) => {
	const [editingName] = useState(sectorName);
	const { updateOrganizationData, treatFieldValidation, handleIsEditing, companyId, organizationId, sectorId } =
		useManageOrganization();

	const { setFieldValue, validateFields } = useFormInstance();

	function handleSectorNameChange(name: string) {
		onSectorNameChange(name);
	}

	function handleCancel() {
		onSectorNameChange('');
		handleIsEditing(false);
		onEditing(false);
		setFieldValue('sector_id', sectorId);
	}

	async function handleUpdateSector(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		try {
			if (sectors.find((elem) => elem.name === sectorName)) {
				const content = I18n.get(`This name already exists. Do you wish to proceed ?`);
				Modal.confirm({
					title: I18n.get('Warning!'),
					icon: <ExclamationCircleOutlined />,
					content: content,
					okType: 'danger',
					okText: I18n.get('Yes'),
					cancelText: I18n.get('Cancel'),
					onOk: async () => {
						await validateFields(['edit_sector_name']);

						updateOrganizationData({
							organization_id: organizationId,
							company_id: companyId,
							sector_id: sectorId,
							name: sectorName
						}).then(({ id }) => {
							onSectorNameChange('');
							handleIsEditing(false);
							onEditing(false);
							setFieldValue('sector_id', id);
							setFieldValue('edit_sector_name', '');
						});
					}
				});
			} else {
				await validateFields(['edit_sector_name']);

				updateOrganizationData({
					organization_id: organizationId,
					company_id: companyId,
					sector_id: sectorId,
					name: sectorName
				}).then(({ id }) => {
					onSectorNameChange('');
					handleIsEditing(false);
					onEditing(false);
					setFieldValue('sector_id', id);
					setFieldValue('edit_sector_name', '');
				});
			}
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_sector_name"
						label={I18n.get('Edit sector')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								message: I18n.get('Please, enter a valid sector name')
							}
						]}
					>
						<Input
							style={{
								flex: 'auto'
							}}
							value={sectorName}
							onChange={(e) => handleSectorNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Col span={12}>
							<Button
								ghost
								danger
								size="middle"
								type="primary"
								onClick={handleCancel}
								icon={<CloseOutlined />}
								disabled={!companyId || !sectorId}
							/>
						</Col>
						<Confirm span={12}>
							<Button
								disabled={!companyId || !sectorId || !sectorName || editingName === sectorName}
								size="middle"
								type="primary"
								ghost={true}
								onClick={(e) => handleUpdateSector(e)}
								icon={<CheckOutlined />}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
