import { ResultRisk } from '@/types';

type Risk = ResultRisk.SAFE | ResultRisk.HAZARDOUS;

export interface StrainIndexDTO {
	left_risk: Risk;
	right_risk: Risk;
	score_left_rsi: number;
	score_right_rsi: number;
	score_left_borg_scale: number;
	score_right_borg_scale: number;
	score_left_exertion_duration: number;
	score_right_exertion_duration: number;
	score_left_efforts_per_minute: number;
	score_right_efforts_per_minute: number;
	score_left_wrist_posture: number;
	score_right_wrist_posture: number;
	score_left_daily_duration: number;
	score_right_daily_duration: number;
}

type Guidance = {
	left: number;
	right: number;
};

export type OverallScoreParameters = Guidance & {
	risk: {
		left: Risk;
		right: Risk;
	};
};

export type OverallDetailedParameters = {
	intensity_of_exertion: Guidance;
	exertion_duration: Guidance;
	efforts_per_minute: Guidance;
	wrist_posture: Guidance;
	daily_duration: Guidance;
};

export enum Diagnostics {
	SAFE = 'Job is probably safe',
	HAZARDOUS = 'Job is probably hazardous'
}
