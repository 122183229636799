import React from 'react';
import { Dropdown, Modal } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { CustomButton } from './styles';
import { useDeleteReport } from './hooks';
import { OptionDropdown } from './OptionDropdown';
import { DeleteReportRequest } from './hooks/types/request';
import { useApplicationContext } from '@/context/Application';
import { ActionsEllipsisContainer, ActionsEllipsisDot } from './Card/styles';

interface ActionsProps {
	report_id: string;
}

export const Actions: React.FC<ActionsProps> = ({ report_id }) => {
	const { organization, company } = useApplicationContext();
	const { mutateAsync: deleteReport } = useDeleteReport();

	function handleDeleteFile(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
		event.stopPropagation();
		event.preventDefault();

		const body: DeleteReportRequest = {
			organization_id: organization?.id,
			company_id: company?.id,
			report_id
		};

		const content = I18n.get(`Want to delete this report?`);

		Modal.confirm({
			title: I18n.get('Heads up!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				await deleteReport(body);
			}
		});
	}

	const items = [
		{
			key: 1,
			danger: true,
			icon: <DeleteOutlined />,
			label: (
				<CustomButton>
					<OptionDropdown onClick={handleDeleteFile}>Delete</OptionDropdown>
				</CustomButton>
			)
		}
	];

	return (
		<Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
			<ActionsEllipsisContainer>
				<ActionsEllipsisDot id="dot" />
				<ActionsEllipsisDot id="dot" />
				<ActionsEllipsisDot id="dot" />
			</ActionsEllipsisContainer>
		</Dropdown>
	);
};
