import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Title } from '@/components/Typography';

import { Container } from './styles';
import { ChartsContent } from './ChartsContent';
import { Particularities } from './Particularities';
import { usePersonalisedReportResultContext } from '../../context';

export const Characters: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();

	const characteristics = personalisedReportResult?.characteristics;
	if (!characteristics) {
		return null;
	}

	return (
		<Row gutter={[0, 24]} justify="center">
			<Col offset={1} span={23}>
				<Row>
					<Title level={3} style={{ margin: 0 }}>
						Characteristics
					</Title>
				</Row>
			</Col>
			<Col offset={1} xs={23}>
				<Row gutter={[30, 0]}>
					<Col xl={13} xxl={14}>
						<Container>
							<Particularities />
						</Container>
					</Col>
					<Col xl={11} xxl={10}>
						<Container>
							<ChartsContent />
						</Container>
					</Col>
				</Row>
			</Col>
			<Divider type="horizontal" />
		</Row>
	);
};
