import Api from '@/services/api';

import { CustomReportResponse } from './types/response';
import { CustomReportsApiServiceInterface } from './servicesInterfaces';
import { DeleteReportRequest, GetCustomReportsRequest } from './types/request';

export class CustomReportsService implements CustomReportsApiServiceInterface {
	async fetchCustomReports({
		organization_id,
		company_id,
		filter
	}: GetCustomReportsRequest): Promise<CustomReportResponse[]> {
		const url = '/custom-report/find-all';
		const { data } = await Api.get<CustomReportResponse[]>(url, {
			params: {
				...filter,
				organization_id,
				company_id
			}
		});
		return data;
	}

	async deleteReport(params: DeleteReportRequest): Promise<CustomReportResponse> {
		const url = '/custom-report';
		const { data } = await Api.delete<CustomReportResponse>(url, {
			params
		});
		return data;
	}
}

export const customReportsService = new CustomReportsService();
