export enum ForceIntensity {
	VERY_LOW = 'VERY_LOW',
	MODERATE = 'MODERATE',
	HIGH = 'HIGH',
	VERY_HIGH = 'VERY_HIGH',
	PEAK = 'PEAK',
	POWERFUL_HITTING = 'POWERFUL_HITTING'
}

export enum ForceIntensityTitle {
	VERY_LOW = 'Very low',
	MODERATE = 'Moderate',
	HIGH = 'High',
	VERY_HIGH = 'Very high',
	PEAK = 'Peak forces',
	POWERFUL_HITTING = 'Powerful hitting'
}

export enum ForceTranser {
	OPTIMUM = 'OPTIMUM',
	RESTRICTED = 'RESTRICTED',
	HINDERED = 'HINDERED'
}

export enum ForceTransferTitle {
	OPTIMUM = 'Optimum force application',
	RESTRICTED = 'Restricted force application',
	HINDERED = 'Force application considerably hindered'
}

export enum RiskLoad {
	LOW = 'LOW',
	SLIGHTLY_INCREASED = 'SLIGHTLY_INCREASED',
	SUBSTANTIALLY_INCREASED = 'SUBSTANTIALLY_INCREASED',
	HIGH = 'HIGH'
}

export enum RiskLoadDescription {
	LOW = 'Low',
	SLIGHTLY_INCREASED = 'Slightly increased',
	SUBSTANTIALLY_INCREASED = 'Substantially increased',
	HIGH = 'High'
}

export enum RiskLoadItems {
	PHYSICAL_OVERLOAD = 'PHYSICAL_OVERLOAD',
	HEALTH_CONSEQUENCES = 'HEALTH_CONSEQUENCES',
	MEASURES = 'MEASURES'
}

export enum PhysicalOverLoad {
	LOW = 'Physical overload is unlikely.',
	SLIGHTLY_INCREASED = 'Physical overload is possible for less resilient persons.',
	SUBSTANTIALLY_INCREASED = 'Physical overload is possible for normally resilient persons.',
	HIGH = 'Physical overload is likely.'
}

export enum HealthConsequences {
	LOW = 'No health risk is to be expected.',
	SLIGHTLY_INCREASED = 'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.',
	SUBSTANTIALLY_INCREASED = 'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.',
	HIGH = 'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.'
}

export enum Measures {
	LOW = 'None.',
	SLIGHTLY_INCREASED = 'For less resilient persons, workplace redesign and other prevention measures may be helpful.',
	SUBSTANTIALLY_INCREASED = 'Workplace redesign and other prevention measures should be considered.',
	HIGH = 'Workplace redesign measures are necessary. Other prevention measures should be considered.'
}

export interface IKimManualHandling {
	duration: number;
	risk_score: number;
	risk_load: RiskLoad;
	force_transfer: ForceTranser;
	left_force_intensity: ForceIntensity;
	right_force_intensity: ForceIntensity;
}

export interface IChildrenProps {
	parameters: IKimManualHandling;
}
