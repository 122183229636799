import styled from 'styled-components';

export const DropContainer = styled.div.attrs({ className: 'dropzone' })<{ disabled: string }>`
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;

	min-height: 250px;
	border: 1px solid #ddd;
	border-radius: 4px;
	outline: none;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	transition: height 0.2s ease;
`;

export const UploadMessage = styled.p`
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 80px 0;
`;

export const ContainerMessage = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`;

export const MaxSize = styled.span`
	width: 100%;
	font-size: 11px;
	text-align: center;
`;
