import { Link } from 'react-router-dom';
import styled from 'styled-components';
interface ActionPlanCardStyles {
	color: string;
}

export const ActionPlanCardContainer = styled.div<ActionPlanCardStyles>`
	background-color: ${(props) => props.color || 'white'};
	border: 1px solid #d9d9d9;
	padding: 0.5rem 1.33rem;
	border-radius: 0.5rem;
	margin: 0 1rem 1rem 0;

	:hover {
		transform: scale(0.98);
		transition: 200ms;
	}
`;

export const ActionPlanTitle = styled.span`
	font-size: 0.9rem;
	font-weight: 600;
`;

export const ActionPlanDeadline = styled.span`
	font-size: 0.76rem;
	font-weight: 400;
`;

export const ActionPlanPercentage = styled.span`
	font-size: 0.76rem;
	font-weight: 400;
`;

export const LinkCustom = styled(Link)`
	cursor: pointer;
	color: black;
`;
