import React, { CSSProperties } from 'react';
import * as Icons from '@ant-design/icons';

import { Tool } from './Tool';
import { ToolsNames } from '@/types';
import { Text } from '@/components/Typography';
import { LoadingSkeleton } from './LoadingSkeleton';
import { LibertyMutualIcon } from './LibertyMutualIcon';
import { ErgonomicToolsResponse } from '../../hooks/types/response';
import { Row } from 'antd';

interface ToolsListProps {
	isError: boolean;
	isLoading: boolean;
	ergonomicTools: ErgonomicToolsResponse[] | undefined;
}

type MappedErgonomicTools = {
	id: string;
	title: string;
	name: ToolsNames;
	subtitle: string;
	icon?: JSX.Element;
};

export const ToolsList: React.FC<ToolsListProps> = ({ isLoading, isError, ergonomicTools }) => {
	if (isLoading) {
		return <LoadingSkeleton />;
	}

	const iconStyle: CSSProperties = {
		fontSize: '1.5rem'
	};

	const toolsIcon = {
		[ToolsNames.angle_time]: undefined,
		[ToolsNames.rula]: <Icons.PieChartOutlined style={iconStyle} />,
		[ToolsNames.reba]: <Icons.LineChartOutlined style={iconStyle} />,
		[ToolsNames.niosh]: <Icons.ToTopOutlined style={iconStyle} />,
		[ToolsNames.kim_pp]: <Icons.ColumnWidthOutlined style={iconStyle} />,
		[ToolsNames.kim_mho]: <Icons.LikeOutlined style={{ ...iconStyle, transform: 'rotate(270deg)' }} />,
		[ToolsNames.liberty_mutual]: <LibertyMutualIcon />,
		[ToolsNames.strain_index]: <Icons.RetweetOutlined style={iconStyle} />
	};

	const mappedTools: MappedErgonomicTools[] | undefined = ergonomicTools
		?.filter(({ name }) => toolsIcon[name])
		.map(({ id, name, title, subtitle }) => {
			return {
				id,
				name,
				title,
				subtitle,
				icon: toolsIcon[name]
			};
		});

	if (isError || !mappedTools || mappedTools.length === 0) {
		return (
			<Row align="middle" gutter={[16, 16]}>
				<Text>Oops... Something went wrong!</Text>
			</Row>
		);
	}

	return (
		<Row align="middle" gutter={[16, 16]}>
			{mappedTools?.map(({ icon, id, title, subtitle }) => (
				<Tool key={id} id={id} icon={icon} title={title} subtitle={subtitle} />
			))}
		</Row>
	);
};
