import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ANSIReportSVG = () => (
	<svg viewBox="0 0 60 57" fill="transparent" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_2446_3796)">
			<path
				d="M34.8612 9.90841C35.3623 9.40361 35.0288 9.05974 34.5266 8.55595C34.0245 8.05216 33.5872 7.75698 33.0861 8.26178L30.6683 10.6143L29.6476 9.59244C29.1782 9.05773 28.7672 9.32282 28.2353 9.79475C27.7034 10.2667 27.2913 10.6087 27.7608 11.1434L29.6476 13.3548C29.8824 13.6222 30.2168 13.7801 30.5714 13.791C30.926 13.802 31.2694 13.665 31.52 13.4125L34.8612 9.90841Z"
				fill="currentColor"
			/>
			<path
				d="M54.0514 31.4242C54.5535 31.928 54.9318 32.4096 54.4307 32.9144L51.0448 36.2807C50.7941 36.5332 50.4508 36.6702 50.0962 36.6593C49.7415 36.6483 49.4072 36.4904 49.1724 36.223L47.2461 34.0881C46.7767 33.5534 47.2281 33.1349 47.7601 32.663C48.292 32.191 48.7029 31.8443 49.1724 32.379L50.193 33.4825L52.6122 31.09C53.1133 30.5852 53.5492 30.9204 54.0514 31.4242Z"
				fill="currentColor"
			/>
			<path
				d="M15.4198 32.8728C15.9209 32.368 15.504 31.928 15.0019 31.4242C14.4997 30.9204 14.1024 30.5436 13.6013 31.0484L11.1435 33.4825L10.1229 32.379C9.6534 31.8443 9.24245 32.191 8.71053 32.663C8.17862 33.1349 7.72716 33.5534 8.19662 34.0881L10.1229 36.223C10.3577 36.4904 10.692 36.6483 11.0466 36.6593C11.4013 36.6702 11.7446 36.5332 11.9952 36.2807L15.4198 32.8728Z"
				fill="currentColor"
			/>
			<path
				d="M11.0005 29.1773C11.0874 28.7199 11.0517 28.8982 11.0874 28.7199C13.1028 18.6572 18.7115 13.1675 25.3749 11.5229C25.6667 11.4509 25.701 11.4492 26.1241 11.3566C26.5472 11.2639 26.8259 12.0644 26.9085 12.5624C26.9911 13.0605 27.1975 13.7716 26.6895 13.8753C26.1815 13.979 26.1422 13.9939 25.8764 14.0616C20.1839 15.5114 15.4198 19.7799 13.6299 29.1357C13.5894 29.3474 13.6299 29.1357 13.5186 29.6763C13.4074 30.2168 12.5692 29.9754 12.1151 29.8842C11.6102 29.7827 10.9135 29.6347 11.0005 29.1773Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M37.9128 38.7653C39.5634 39.562 41.0662 40.6551 42.3523 41.9943C45.1681 44.9186 46.761 48.7785 46.8637 52.895C46.8651 52.952 46.8555 53.0088 46.8354 53.0619C46.8154 53.1151 46.7853 53.1635 46.747 53.2043C46.7087 53.2452 46.6629 53.2776 46.6124 53.2998C46.5618 53.322 46.5075 53.3334 46.4527 53.3333H43.5752C43.3542 53.3333 43.1693 53.1516 43.1641 52.9217C43.0614 49.821 41.8539 46.918 39.7369 44.7154C37.5223 42.4113 34.578 41.1443 31.4488 41.1443C29.909 41.1398 28.3836 41.4531 26.9609 42.0661C25.5383 42.6791 24.2466 43.5796 23.1607 44.7154C21.0385 46.918 19.831 49.821 19.7334 52.9217C19.7283 53.1516 19.5433 53.3333 19.3223 53.3333H16.4449C16.39 53.3334 16.3357 53.322 16.2852 53.2998C16.2346 53.2776 16.1888 53.2452 16.1505 53.2043C16.1122 53.1635 16.0822 53.1151 16.0621 53.0619C16.0421 53.0088 16.0324 52.952 16.0338 52.895C16.1366 48.7785 17.7295 44.9186 20.535 41.9943C21.8207 40.6546 23.3236 39.5614 24.9745 38.7653C22.652 36.8032 21.1619 33.8094 21.1619 30.4521C21.1619 24.6035 25.6784 19.8508 31.2843 19.7599C36.9776 19.669 41.7254 24.5286 41.7254 30.4521C41.7254 33.8094 40.2404 36.8032 37.9128 38.7653ZM31.4488 37.2951C29.6915 37.2951 28.0369 36.584 26.7986 35.2903C26.1771 34.6488 25.6862 33.8836 25.3552 33.0404C25.0241 32.1973 24.8597 31.2933 24.8717 30.3826C24.8871 28.6291 25.5603 26.9344 26.7369 25.678C27.9701 24.3629 29.6195 23.6305 31.382 23.6091C33.1239 23.5931 34.8144 24.2987 36.0578 25.5658C37.3321 26.8649 38.031 28.6023 38.031 30.4521C38.031 32.2804 37.3476 33.9965 36.1041 35.2903C35.494 35.9281 34.7685 36.4336 33.9694 36.7777C33.1703 37.1219 32.3136 37.2977 31.4488 37.2951Z"
				fill="currentColor"
			/>
			<path
				d="M51.2099 29.1357C51.123 28.6783 51.1587 28.8566 51.123 28.6783C49.1076 18.6156 43.4989 13.1259 36.8354 11.4813C36.5436 11.4093 36.5093 11.4076 36.0862 11.315C35.6631 11.2223 35.3845 12.0228 35.3019 12.5209C35.2193 13.0189 35.0129 13.73 35.5209 13.8337C36.0288 13.9375 36.0681 13.9523 36.3339 14.02C42.0264 15.4698 46.7906 19.7383 48.5805 29.0941C48.621 29.3058 48.5805 29.0941 48.6917 29.6347C48.8029 30.1752 49.6412 29.9338 50.0953 29.8426C50.6002 29.7411 51.2968 29.5931 51.2099 29.1357Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export const ANSIReportIcon = (props: Partial<CustomIconComponentProps>) => {
	return <Icon {...props} component={ANSIReportSVG} />;
};
