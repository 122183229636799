import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { usePersonalisedReportResultContext } from '../../../context';
import { HeaderTitle, TableBodyContainer, TableContainer, TableHeaderContainer } from './styles';

export const DescriptionTable: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { work_conditions } = personalisedReportResult;

	if (!work_conditions) {
		return null;
	}

	return (
		<TableContainer>
			<TableHeaderContainer>
				<Row justify="space-between">
					<Col span={7}>
						<HeaderTitle>{I18n.get('Summary description of the place')}</HeaderTitle>
					</Col>
					<Col span={7}>
						<HeaderTitle>{I18n.get('Simplified description of the expected task')}</HeaderTitle>
					</Col>
					<Col span={7}>
						<HeaderTitle>{I18n.get('Simplified description of the task performed')}</HeaderTitle>
					</Col>
				</Row>
			</TableHeaderContainer>
			<TableBodyContainer>
				<Row justify="space-between">
					<Col span={7} style={{ margin: '1rem 0' }}>
						<span>{work_conditions.place_description ? work_conditions.place_description : 'N/A'}</span>
					</Col>
					<Divider style={{ margin: 0, borderLeft: '1px solid #e6e8e8', height: 'auto' }} type="vertical" />
					<Col span={7} style={{ margin: '1rem 0' }}>
						<span>
							{work_conditions.expected_task_description
								? work_conditions.expected_task_description
								: 'N/A'}
						</span>
					</Col>
					<Divider style={{ margin: 0, borderLeft: '1px solid #e6e8e8', height: 'auto' }} type="vertical" />
					<Col span={7} style={{ margin: '1rem 0' }}>
						<span>
							{work_conditions.performed_task_description
								? work_conditions.performed_task_description
								: 'N/A'}
						</span>
					</Col>
				</Row>
			</TableBodyContainer>
		</TableContainer>
	);
};
