import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;

	div {
		display: flex;
		justify-content: center;
		height: 100%;
	}

	input {
		width: 100%;
		height: 100%;
		margin-right: 0.5rem;
		border-radius: 3px;
		border: 1.5px solid #c4c4c4;
		font-size: 1.2rem;
		text-align: center;

		&:focus {
			border-color: #5070f2;
			outline: 0;
			-webkit-box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2);
			box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2);
			transition: 0.2s;
		}
	}
`;
