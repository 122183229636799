import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { DetailDescription, Details, DetailTitle } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

export const FirstRow: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;
	const { kim_mho } = basic_information;
	if (!kim_mho) {
		return null;
	}

	return (
		<Row justify="space-between">
			<Col span={6} style={{ paddingLeft: '1rem' }}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Total duration per day worked')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>
							{kim_mho.duration} {I18n.get('hours')}.
						</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={4}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Force exerted on hand/fingers (L|R)')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>
							{I18n.get(kim_mho.left_force_intensity)} | {I18n.get(kim_mho.right_force_intensity)}
						</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={4}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Power transfer/gripping conditions')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>{I18n.get(kim_mho.force_transfer)}.</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={6}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Probability of physical overload')}</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>{I18n.get(kim_mho.risk_load)}</DetailDescription>
					</Row>
				</Details>
			</Col>
		</Row>
	);
};
