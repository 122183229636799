import React from 'react';
import { Row, Col, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { Container } from './styles';

type Type = 'line' | 'toTop' | string;

interface ApplyToolProps {
	type: Type;
	isActive: boolean;
	onClick: React.MouseEventHandler<HTMLElement>;
	children: JSX.Element[];
}

const icons: Record<Type, JSX.Element> = {
	line: <Icons.LineChartOutlined style={{ fontSize: 30 }} />,
	toTop: <Icons.ToTopOutlined style={{ fontSize: 30 }} />
};

export const ApplyTool: React.FC<ApplyToolProps> = ({ type = 'line', onClick, children = [], isActive = false }) => (
	<Container hoverable onClick={onClick} isactive={isActive}>
		<Row align="middle" gutter={[18, 0]}>
			<Col>{icons[type]}</Col>
			<Col>
				<Space direction="vertical">
					{React.Children.map(children, (child) => (
						<>{child}</>
					))}
				</Space>
			</Col>
		</Row>
	</Container>
);
