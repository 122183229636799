import React from 'react';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, CustomInputNumber } from '../../styles';
import { FieldsProps } from '../types';

export function MaxMinutesUsed({ name, required = false }: Readonly<FieldsProps>) {
	return (
		<CustomFormItem
			name={name}
			labelCol={{ span: 24 }}
			label={I18n.get('Maximum minutes of use')}
			rules={[{ required, message: I18n.get('Enter a maximum minutes of use') }]}
		>
			<CustomInputNumber min={0} placeholder={'000'} />
		</CustomFormItem>
	);
}
