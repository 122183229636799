import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomDivider, CustomInput } from './styles';

interface OptionsMenuWithCreationProps {
	creatingData?: boolean;
	optionsMenu: React.ReactNode;
	onCreate: (name: string) => void;
}

export function OptionsMenuWithCreation({
	onCreate,
	optionsMenu,
	creatingData = false
}: Readonly<OptionsMenuWithCreationProps>) {
	const [currentName, setCurrentName] = useState<string>('');

	function handleChangeName(name: string): void {
		setCurrentName(name);
	}

	function handleCreate(name: string): void {
		onCreate(name);
	}

	return (
		<Row gutter={[4, 4]}>
			<Col xs={24}>{optionsMenu}</Col>
			<CustomDivider />
			<Col xs={24}>
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<CustomInput onChange={(e) => handleChangeName(e.target.value)} />
					<Button
						type="link"
						loading={creatingData}
						onClick={() => handleCreate(currentName)}
						disabled={!currentName || currentName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</Col>
		</Row>
	);
}
