import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import type { BaseContext, File, FileStatus } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

export type FilterRequest = {
	limit: number;
	offset: number;
	line_id?: string;
	task_id?: string;
	sector_id?: string;
	report_name?: string;
	workstation_id?: string;
	created_at_end?: string;
	organization_id?: string;
	created_at_start?: string;
	company_id_filter?: string;
	searching_organization_id?: string;
	order_option?: string;
	order_by_name?: string;
	order_by_uploader?: string;
	status?: FileStatus;
	exclude_tool?: {
		name: string;
		id?: string;
	};
};

export type FileListRequest = BaseContext & {
	filter?: FilterRequest;
};

export type FileResponse = Pick<
	File,
	'id' | 'original_name' | 'duration' | 'size' | 'status' | 'createdAt' | 'updatedAt' | 'thumbnail'
>;

export type FilesListResponse = FileResponse & {
	company_id: string;
	organization_id: string;
	workstation_id: string;
	sector_id: string;
	organization?: {
		id: string;
		name: string;
	};
	user?: {
		name: string;
	};
	company?: {
		id: string;
		name: string;
	};
	sector: {
		id: string;
		name: string;
	};
	workstations: {
		id: string;
		name: string;
		line: {
			id: string;
			name: string;
		};
	};
	preliminary_analysis: {
		worst_score: number;
		consolidated: boolean;
	};
};

export type FileListDTO = {
	count: number;
	rows: FilesListResponse[];
};

async function getFileSummaryList({ organization_id, company_id, filter }: FileListRequest): Promise<FileListDTO> {
	const url = `file`;
	const params = { organization_id, company_id, ...filter };

	const { data } = await Api.get<FileListDTO>(url, {
		params
	});
	return data;
}

export function useListFiles({ organization_id, company_id, filter }: FileListRequest) {
	const enabled = !!organization_id && !!company_id;
	const summaryList = useQuery(
		[QUERY_KEYS.GET_FILES_LIST, [organization_id, company_id, filter]],
		() => getFileSummaryList({ organization_id, company_id, filter }),
		{
			enabled
		}
	);
	return {
		...summaryList,
		data: summaryList.data ?? ({} as FileListDTO)
	};
}
