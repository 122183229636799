import { Divider, Row } from 'antd';
import styled from 'styled-components';

export const CustomDivider = styled(Divider)`
	height: 100%;
	margin: 0 0 0 0.7rem;
	border-left: 4px solid #e6e8e8;
`;

export const CustomRow = styled(Row)`
	margin-left: 0.5rem;

	.ant-col-24:last-child {
		.ant-divider-vertical {
			display: none;
		}
	}
`;
