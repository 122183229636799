import React from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { StepButton } from './styles';
import { useCustomReportManagerContext } from '../../context';

interface StepProps {
	id: string;
	description: string;
}

export const Step: React.FC<StepProps> = ({ description, id }) => {
	const { handleSelectStep, handlePreviousStep } = useCustomReportManagerContext();

	function handleStepClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
		event.preventDefault();
		event.stopPropagation();
		handleSelectStep(id);
		handlePreviousStep();
	}

	return (
		<Col style={{ paddingBottom: '0.5rem' }}>
			<StepButton onClick={handleStepClick}>{I18n.get(description)}</StepButton>
		</Col>
	);
};
