import styled from 'styled-components';

export const TaskContainer = styled.div`
	font-size: 1rem;
	margin-right: 1rem;
`;

export const Topic = styled.span`
	color: #d9d9d9;
	margin-right: 0.5rem;
`;

export const Title = styled.span`
	font-weight: 600;
`;
