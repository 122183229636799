import React, { useContext } from 'react';
import { Row, Col, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/lib/table';

import Spinner from '@/components/Layout/Spinner';
import { Title } from '@/components/Typography';
import { MergeAEPContext } from './context';
import hooks from './hooks';

const { useGetPEAList } = hooks;

type TableData = {
	key: string;
	id: string;
	analyst: string;
	score: number;
};

export const Conclusion: React.FC = () => {
	const {
		data: { pea_list },
		organizationId,
		queryParams
	} = useContext(MergeAEPContext);

	const {
		data: PEAList,
		isLoading,
		isError
	} = useGetPEAList(
		{
			...queryParams,
			organizationId
		},
		0,
		100
	);

	const columns: ColumnsType<TableData> = [
		{
			title: I18n.get('ID'),
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			ellipsis: true,
			render: (text: string) => text.split('-')[0]
		},
		{
			title: I18n.get('Analyst'),
			dataIndex: 'analyst',
			key: 'analyst',
			align: 'center',
			ellipsis: true
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'score',
			key: 'score',
			align: 'center',
			ellipsis: true
		}
	];

	if (isLoading) {
		return (
			<Col xs={24} sm={18} lg={12} style={{ minHeight: '20rem' }}>
				<Spinner />
			</Col>
		);
	}

	if (isError) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<Col xs={24} sm={18} lg={12}>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Title level={5}>{I18n.get('Selected EWA')}</Title>
				</Col>
				<Col span={24}>
					<Table
						rowKey="key"
						pagination={{ pageSize: 5 }}
						dataSource={PEAList.result
							.filter((pea) => pea_list?.some((selectedPEA) => selectedPEA === pea.id))
							.map((pea) => ({
								key: pea.id,
								id: pea.id,
								analyst: pea.analyst_name,
								score: pea.worst_score
							}))}
						columns={columns}
					/>
				</Col>
			</Row>
		</Col>
	);
};
