import React from 'react';
import { Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { ExhibitionDTO } from '@/components/views/PreliminaryAnalysis/types/dto/exhibition';

interface ExhibitionSelectProps {
	exhibitions?: ExhibitionDTO[];
}

export const ExhibitionSelect: React.FC<ExhibitionSelectProps> = (props) => {
	const { exhibitionList } = usePreliminaryAnalysis();
	const { gettingExhibitions, exhibitions } = exhibitionList;

	const exhibitionsToShow = props?.exhibitions ?? exhibitions;

	return (
		<Select {...props} style={{ width: '100%' }} loading={gettingExhibitions}>
			{exhibitionsToShow?.map((item) => (
				<Select.Option key={item.id} value={item.id}>
					{I18n.get(item.description)}
				</Select.Option>
			))}
		</Select>
	);
};
