import React from 'react';
import { Row, Col } from 'antd';
import { CardContainer, CustomTitle } from './styles';

interface CardProps {
	title?: string;
	children: React.ReactNode;
}

export function Card({ title = '', children }: CardProps) {
	return (
		<CardContainer>
			<Row gutter={[0, 18]}>
				<Col span={24}>
					<CustomTitle level={4}>{title}</CustomTitle>
				</Col>
				<Col span={24}>{children}</Col>
			</Row>
		</CardContainer>
	);
}
