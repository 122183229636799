import React from 'react';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, RadioButton, RadioGroup } from '../../styles';
import { FieldContainer } from '../styles';
import { FieldsProps } from '../types';

enum RECURRENCE_VALUES {
	MONTHLY = 'monthly',
	YEARLY = 'yearly'
}

interface ControlPeriodProps extends FieldsProps {
	disabled?: boolean;
}

export function ControlPeriod({ name, required = false, disabled = false }: Readonly<ControlPeriodProps>) {
	const options = [
		{
			value: RECURRENCE_VALUES.MONTHLY,
			title: I18n.get('Monthly')
		},
		{
			value: RECURRENCE_VALUES.YEARLY,
			title: I18n.get('Annually')
		}
	];

	return (
		<FieldContainer disabled={disabled}>
			<CustomFormItem
				name={name}
				labelCol={{ span: 24 }}
				initialValue={options[0].value}
				label={I18n.get('Control period')}
				rules={[{ required, message: I18n.get('Enter a control period') }]}
			>
				<RadioGroup disabled={disabled} defaultValue={options[0].value} buttonStyle="solid">
					{options.map(({ value, title }) => (
						<RadioButton value={value}>{title}</RadioButton>
					))}
				</RadioGroup>
			</CustomFormItem>
		</FieldContainer>
	);
}
