import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export type UserOrganizationPermission = {
	organization_permission: {
		[key: string]: string[];
	};
};

export type OrganizationsWithUserPermissions = {
	user_organization_permission: UserOrganizationPermission;
	master_permissions: any;
	total: number;
	id: string;
};

export type PermissionListDTO = {
	name: string;
	offset: number;
	limit: number;
	user_id?: string;
	isCreating: boolean;
};

async function getAllOrganizationsWithUserPermissions(
	params: PermissionListDTO,
	pageParam: number
): Promise<OrganizationsWithUserPermissions> {
	const url = '/user_management/user/permissions';
	params.offset = pageParam;
	const { data } = await Api.get<OrganizationsWithUserPermissions>(url, { params });
	return data;
}

export const useGetAllOrganizationsWithUserPermissions = (params: PermissionListDTO) => {
	const summary = useInfiniteQuery<OrganizationsWithUserPermissions, AxiosError<Response>>(
		[QUERY_KEYS.GET_PERMISSIONS, params.user_id, params.name],
		async ({ pageParam = 0 }) => getAllOrganizationsWithUserPermissions(params, pageParam),
		{
			enabled: !!params.user_id || params.isCreating,
			getNextPageParam: (lastPage) => {
				const nextPage = params.offset + 1;
				return nextPage * params.limit > lastPage.total ? undefined : nextPage;
			}
		}
	);

	return {
		...summary,
		data: summary.data || ({} as InfiniteData<OrganizationsWithUserPermissions>)
	};
};
