import React, { useEffect, useState } from 'react';
import { LogoutOutlined, InfoCircleOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { PROFILE_DEFAULT } from '@/utils/enum';
import { AvatarUser } from './AvatarUser';
import { I18n } from '@aws-amplify/core';

interface MenuPopoverProps {
	onLogout: () => void;
	onMyAccount: () => void;
}

type KeyToHandlerType = Record<string, () => void>;

export const MenuPopover: React.FC<MenuPopoverProps> = ({ onLogout, onMyAccount }) => {
	const { logoChange, info_user } = useApplicationContext();
	const localStorageLogo = info_user?.user.logo;
	const [logo, setLogo] = useState(localStorageLogo || PROFILE_DEFAULT.URL);

	useEffect(() => {
		localStorageLogo && setLogo(localStorageLogo || PROFILE_DEFAULT.URL);
	}, [logoChange, localStorageLogo]);

	const keyToHandler: KeyToHandlerType = {
		logout: onLogout,
		account: onMyAccount
	};

	const onClick: MenuProps['onClick'] = ({ key }) => {
		const handler = keyToHandler[key];
		handler && handler();
	};

	const items: MenuProps['items'] = [
		{
			key: 'user',
			icon: <AvatarUser size="large" src={logo} />,
			children: [
				{ label: I18n.get('My account'), key: 'account', icon: <InfoCircleOutlined /> },
				{ label: I18n.get('Logout'), key: 'logout', icon: <LogoutOutlined /> }
			]
		}
	];

	return <Menu items={items} onClick={onClick} mode="horizontal" />;
};
