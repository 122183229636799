import React from 'react';
import { Form, InputNumber } from 'antd';

import { CustomInputFormField } from './CustomInputFormField';

const { useWatch, useFormInstance } = Form;

export const TotalWorkingPopulation: React.FC = () => {
	const form = useFormInstance();
	const men = useWatch(['characteristics', 'working_population_men'], form);
	const women = useWatch(['characteristics', 'working_population_women'], form);
	const others = useWatch(['characteristics', 'working_population_others'], form);

	const total = men + women + others;

	form.setFieldValue(['characteristics', 'total_working_population'], total ?? 1);

	return (
		<CustomInputFormField namePath={['characteristics', 'total_working_population']} title="Total">
			<InputNumber disabled={true} value={total ?? 1} />
		</CustomInputFormField>
	);
};
