import React from 'react';
import { Select } from 'antd';
import { I18n } from '@aws-amplify/core';

export function FileSelect({ files, loading, disabled, ...restProps }) {
	const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	return (
		<Select
			showSearch
			placeholder={I18n.get('File')}
			optionFilterProp="children"
			filterOption={filterOption}
			loading={loading}
			disabled={disabled}
			{...restProps}
			dropdownRender={(menu) => <div>{menu}</div>}
		>
			{files?.data?.map((item, key) => (
				<Select.Option key={key} value={item.id}>
					{item.original_name}
				</Select.Option>
			))}
		</Select>
	);
}
