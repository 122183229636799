import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { PieChart } from './Piechart';
import { Text } from '@/components/Typography';
import { RebaBodyPartScores } from '../../../hooks';
import { DIAGNOSTIC_COLORS } from '@/utils/constants';
import { usePersonalisedReportContext } from '../../../context';
import { scoreScaleExposures } from '../../../context/constants';
import { usePersonalisedReportSubStepKeyContext } from './context';

interface ChartsProps {
	scores?: RebaBodyPartScores[];
}
const { useWatch, useFormInstance } = Form;

export const Chart: React.FC<ChartsProps> = ({ scores }) => {
	const form = useFormInstance();
	const {
		exposuresData: { exposures },
		personalisedReport: { score_scale_name }
	} = usePersonalisedReportContext();
	const { stepKeyFieldName } = usePersonalisedReportSubStepKeyContext();

	const exposureId = useWatch([...stepKeyFieldName, 'exposure_id'], form);

	if (!scores) {
		return null;
	}

	function defineExposureIdBasedOnRebaAcceptablePercentage(acceptablePercentage: number) {
		const exposureNameToIdMapper = exposures.reduce((nameToId, currentExposure) => {
			const exposure = {
				...nameToId,
				[currentExposure.name]: currentExposure.id
			};
			return exposure;
		}, {} as { [key: string]: string });

		if (acceptablePercentage <= 9) {
			return exposureNameToIdMapper[scoreScaleExposures[score_scale_name].EXTREME];
		}
		if (acceptablePercentage <= 29) {
			return exposureNameToIdMapper[scoreScaleExposures[score_scale_name].HIGH];
		}
		if (acceptablePercentage <= 49) {
			return exposureNameToIdMapper[scoreScaleExposures[score_scale_name].MEDIUM_HIGH];
		}
		if (acceptablePercentage <= 79) {
			return exposureNameToIdMapper[scoreScaleExposures[score_scale_name].MEDIUM];
		}
		if (acceptablePercentage <= 89) {
			return exposureNameToIdMapper[scoreScaleExposures[score_scale_name].LOW];
		}
		return exposureNameToIdMapper[scoreScaleExposures[score_scale_name].ACCEPTBALE];
	}

	if (!exposureId) {
		const id = defineExposureIdBasedOnRebaAcceptablePercentage(scores[1].percentage);
		form.setFieldValue([...stepKeyFieldName, 'exposure_id'], id);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Text strong>{I18n.get('Exposure')}:</Text>
			</Col>
			<Col span={24}>
				<Row gutter={[20, 20]} align="middle">
					<Col span={8}>
						<PieChart scores={scores?.map(({ percentage }) => percentage ?? 0)} />
					</Col>
					<Col span={14}>
						<Row>
							{scores?.map(({ id, level, text, percentage }) => (
								<Col key={id} span={24}>
									<span style={{ fontSize: '12px' }}>
										{I18n.get(text)}:{' '}
										<Text strong style={{ color: DIAGNOSTIC_COLORS[level] }}>
											{percentage}%
										</Text>
									</span>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
