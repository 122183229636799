import styled from 'styled-components';

type StressLevelStyle = {
	percentage?: number;
};

export const WorkingPopulationContainer = styled.div`
	padding: 1rem 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const StressLevelContainer = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const ChartContainer = styled.div<StressLevelStyle>`
	height: 230px;
	min-height: 180px;
	display: flex;
	justify-content: center;
	position: relative;

	#level {
		width: 100%;
		position: absolute;
		width: 1.5rem;
		z-index: 10;
		background: #e6e8e8;
		border: 1px solid e6e8e8;
		border-radius: ${(props) => (props.percentage === 100 ? '1rem' : '1rem 1rem 0 0')};
		height: ${(props) => props.percentage + '%'};
	}
`;

export const StressLevelChart = styled.div<StressLevelStyle>`
	background: rgb(44, 200, 82);
	background: linear-gradient(
		0deg,
		rgba(44, 200, 82, 1) 0%,
		rgba(255, 222, 49, 1) 25%,
		rgba(247, 138, 56, 1) 50%,
		rgba(231, 65, 80, 1) 75%,
		rgba(155, 84, 226, 1) 100%
	);
	width: ${(props) => (props.percentage === 100 ? '1rem' : '1.5rem')};
	border-radius: 1rem;
	height: ${(props) => (props.percentage === 100 ? '90%' : '100%')};
	position: absolute;
	top: 1px;
`;

export const StressLevel = styled.div``;

export const Stress = styled.span`
	font-size: 1rem;
	font-weight: 600;
`;

export const ENUM_WORKPOPULATION_SCALE = {
	men: {
		caption: 'Men',
		fontColor: '#2F54EB',
		trailColor: '#2F54EB'
	},
	others: {
		caption: 'Others',
		fontColor: '#17A93B',
		trailColor: '#17A93B'
	},
	women: {
		caption: 'Women',
		fontColor: '#EFAC1E',
		trailColor: '#EFAC1E'
	}
};
