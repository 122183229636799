import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled(Col)`
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 5px;

	::-webkit-scrollbar {
		width: 4px;
		background: transparent;
	}

	::-webkit-scrollbar-track {
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		background: #e6e6e6;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
