import React from 'react';
import { Form } from 'antd';
import { Spinner } from '@/components/Spinner';
import { useGetProviderUser } from '../hooks/useGetProviderUser';
import { Container, Content, Title, SubTitle, LoginForm, Redirect, Icon } from './components';

const { useForm } = Form;

export const SingleSignOnLogin: React.FC = () => {
	const [form] = useForm();
	const { isLoading, mutate: fetchProviderUser, isSuccess } = useGetProviderUser();

	async function handleSubmitForm(): Promise<void> {
		const { email } = await form.validateFields();
		fetchProviderUser(email);
	}

	if (isSuccess) {
		return <Spinner size={25} />;
	}

	return (
		<Container>
			<Content>
				<Icon />
				<Title />
				<SubTitle />
				<LoginForm formInstance={form} onFinish={handleSubmitForm} isLoading={isLoading} />
				<Redirect />
			</Content>
		</Container>
	);
};
