import React, { ReactNode, useEffect } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CustomModal } from '@/components/ui/CustomModal';

import { CustomTitle, Footer, Generate2FAForm, Header, Subtitle } from './styles';
import { ConfirmPasswordModal } from '../ConfirmPasswordModal';
import { GenerateAuthApp2FA } from './GenerateAuthApp2FA';
import { GenerateEmail2FA } from './GenerateEmail2FA';
import { RecoveryTokens } from './RecoveryTokens';
import { useTFAContext } from '../context';
import { Validate } from '../Validate';
import { Methods } from './Methods';
import { Buttons } from './Buttons';

const { useForm } = Form;

interface StepsProps {
	content: ReactNode;
}

export const Generate2FAModal = () => {
	const [form] = useForm();
	const {
		openGenerateTokenModal,
		currentStep,
		user2FA,
		generated2FA,
		selected2FAType,
		correctPassword,
		recoveryTokens
	} = useTFAContext();
	const { handleDelete2FA, handleResetStep, handleOpenGenerateTokenModal, handleCorrectPassword } = useTFAContext();

	useEffect(() => {
		handleCorrectPassword(false);
	}, []);

	function handleClose() {
		if (generated2FA.id) {
			Modal.confirm({
				title: I18n.get('Heads up! Closing now will delete the token you generated. Want to continue?'),
				icon: <ExclamationCircleOutlined />,
				okText: I18n.get('Confirm'),
				cancelText: I18n.get('Cancel'),
				okType: 'danger',
				onOk: async () => {
					handleConfirmClose();
					await handleDelete2FA();
				}
			});
		} else {
			handleConfirmClose();
		}
	}

	function handleConfirmClose() {
		form.resetFields();
		handleCorrectPassword(false);
		handleOpenGenerateTokenModal(false);

		if (!user2FA.id || !!user2FA.validated) {
			handleResetStep();
		}
	}

	const steps: StepsProps[] = [
		{
			content: <Methods />
		},
		{
			content: selected2FAType === 'EMAIL' ? <GenerateEmail2FA /> : <GenerateAuthApp2FA />
		},
		{
			content: <Validate />
		},
		{
			content: <RecoveryTokens />
		}
	];

	if (correctPassword || recoveryTokens.length > 0) {
		return (
			<CustomModal
				open={openGenerateTokenModal}
				onCancel={handleClose}
				width={750}
				bodyStyle={{ height: 600 }}
				footer={false}
				centered
			>
				<Generate2FAForm form={form}>
					<Header>
						<Col span={24}>
							<CustomTitle>{I18n.get('Enable two-factor authentication')}</CustomTitle>
						</Col>
						{currentStep === 0 && (
							<Col span={24}>
								<Subtitle justify="center">
									{I18n.get('Select a method to generate your two-factor authentication')}
								</Subtitle>
							</Col>
						)}
					</Header>
					<Row justify="center">{steps[currentStep].content}</Row>
					<Footer>
						<Buttons resetFields={form.resetFields} totalSteps={steps.length - 1} />
					</Footer>
				</Generate2FAForm>
			</CustomModal>
		);
	}

	return <ConfirmPasswordModal />;
};
