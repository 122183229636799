import React from 'react';
import { Col, Divider, Row } from 'antd';

import { Body } from './Body';
import { Header } from './Header';
import { Container } from './styles';
import { Title } from '@/components/Typography';
import { usePersonalisedReportResultContext } from '../../context';

export const Niosh: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();

	const niosh = personalisedReportResult?.tools?.niosh;
	if (!niosh) {
		return null;
	}

	return (
		<Row justify="center">
			<Col xs={24}>
				<Row gutter={[0, 24]}>
					<Col offset={1} span={23}>
						<Row>
							<Title style={{ margin: 0 }} level={3}>
								Cargo Handling (NIOSH)
							</Title>
						</Row>
					</Col>
					<Col offset={1} span={23}>
						<Row>
							<Container>
								<Header />
								<Divider style={{ margin: 0 }} />
								<Body />
							</Container>
						</Row>
					</Col>
				</Row>
			</Col>
			<Divider type="horizontal" />
		</Row>
	);
};
