import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Label } from './styles';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField } from '@/utils/HierarchyHandler';
import { useGetActivities } from '@/hooks/useGetActivities';

const { useFormInstance, useWatch } = Form;

export function Activities() {
	const form = useFormInstance();

	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);
	const workstationId = useWatch(HierarchiesField.WORKSTATION, form);

	const title = I18n.get('Activity');
	const fieldName = HierarchiesField.ACTIVITY;

	const {
		data: activities,
		isFetching: isGettingEvaluators,
		isError: errorGettingEvaluators
	} = useGetActivities({
		company_id: companyId,
		organization_id: organizationId,
		workstation_id: workstationId
	});

	function handleSelect(id: string): void {
		id && form.setFieldValue(fieldName, id);
	}

	function handleClear(): void {
		form.resetFields([fieldName]);
	}

	return (
		<Form.Item name={[fieldName]} label={<Label>{title}</Label>}>
			<SelectWithCreation
				showSearch
				allowClear
				placeholder={title}
				onClear={handleClear}
				onChange={handleSelect}
				selectOptions={activities}
				loading={isGettingEvaluators}
				disabled={!activities || !workstationId || errorGettingEvaluators}
			/>
		</Form.Item>
	);
}
