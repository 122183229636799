import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Response, OrganizationDTO } from '@/types';

async function getOrganizations(): Promise<OrganizationDTO[]> {
	const url = '/organization';
	const { data } = await Api.get<OrganizationDTO[]>(url);
	return data;
}

export const useGetOrganizations = () => {
	const customReport = useQuery<OrganizationDTO[], AxiosError<Response>>(['organizations'], () => getOrganizations(), {
		retry: 0
	});
	return customReport;
};
