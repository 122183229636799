import styled from 'styled-components';

export const ToolTitle = styled.span`
	font-size: 1rem;
	font-weight: 600;
	line-height: 1rem;
`;

export const ToolSubtitle = styled.span`
	font-size: 0.76rem;
	font-weight: 400;
`;

export const Total = styled.div`
	font-size: 0.66rem;
	font-weight: 400;
	margin-left: 0.66rem;
`;

export const Value = styled.div`
	font-weight: 600;
`;

export const RiskCaption = styled.div`
	font-size: 0.66rem;
	font-weight: 400;
`;

export const RiskValue = styled.div`
	font-size: 0.75rem;
	font-weight: 600;
`;
