import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { User } from '@/types';
import { CustomerInformationRequest } from '@/components/ui/Inputs/types/request/customer_information';

type UserResponse = Pick<User, 'id' | 'name'>;

async function getUsersByCustomer(params: CustomerInformationRequest): Promise<UserResponse[]> {
	const url = `/user/customer`;
	const { data } = await Api.get<UserResponse[]>(url, {
		params
	});
	return data;
}

export function useUsersByCustomer({
	organization_id,
	company_id,
	customer_information_id
}: CustomerInformationRequest) {
	const enabled = !!organization_id && !!company_id && !!customer_information_id;

	const users = useQuery(
		['users_by_customer', { organization_id, company_id, customer_information_id }],
		() => getUsersByCustomer({ organization_id, company_id, customer_information_id }),
		{ enabled }
	);
	return users;
}
