import React, { ReactNode, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { PrimaryButton } from '@/components/ui/Buttons/PrimaryButton';
import { Text, Title } from '@/components/Typography';
import { setSession } from '@/redux/auth/actions';
import { Auth2FAParams } from '@/types/AuthUser';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';

import { PreAuthentication2FA } from './PreAuthentication2FA';
import { Force2FAWarning } from './Force2FAWarning';
import { RecoveryTokens } from './RecoveryTokens';
import { Validate2FA } from './Validate2FA';
import { useValidate2FA } from './hooks';
import { Container } from './styles';
import { Buttons } from './Buttons';
import { Methods } from './Methods';

interface StepsProps {
	content: ReactNode;
}

const { useForm } = Form;

export const Generate2FAContent: React.FC = () => {
	const [form] = useForm();
	const history = useHistory();
	const dispatch = useDispatch();

	const { info_user, setUser } = useApplicationContext();
	const { user_id, qr_code_url, type } = useParams<Auth2FAParams>();

	const decoded_qr_code = qr_code_url ? decodeURIComponent(qr_code_url) : '';

	const [currentStep, setCurrentStep] = useState(decoded_qr_code ? 3 : 0);
	const [recoveryTokens, setRecoveryTokens] = useState<Array<string>>([]);

	const { mutateAsync: validate2FA } = useValidate2FA();

	function handleNextStep() {
		setCurrentStep(currentStep + 1);
	}

	function handlePreviousStep() {
		setCurrentStep(currentStep - 1);
	}

	async function OnFinish() {
		const form_values = form.getFieldsValue(['validation_code', 'method_type']);

		const two_fa_type = type ? type : form_values.method_type;
		const token = form_values.validation_code;

		const validated = await validate2FA({
			user_id,
			data: {
				type: two_fa_type,
				token
			}
		});

		setUser({
			user: info_user.user,
			token: validated.token,
			refresh_token: validated.refresh_token
		});

		Api.defaults.headers.Authorization = `Bearer ${validated.token}`;

		if (validated.tokens_array.length === 0) {
			dispatch(
				setSession({
					user: info_user.user,
					token: validated.token,
					refresh_token: validated.refresh_token
				})
			);

			return history.push('/');
		}

		setRecoveryTokens(validated.tokens_array);
		handleNextStep();
	}

	const steps: StepsProps[] = [
		{
			content: <Force2FAWarning />
		},
		{
			content: <Methods />
		},
		{
			content: <PreAuthentication2FA />
		},
		{
			content: <Validate2FA />
		},
		{
			content: <RecoveryTokens recoveryTokens={recoveryTokens} />
		}
	];

	if (currentStep === 0) {
		return (
			<Container xs={18} sm={18} md={12} lg={10} xl={8} xxl={6}>
				<Row justify="center">{steps[0].content}</Row>
				<Row>
					<Col span={24} style={{ marginBottom: '1rem' }}>
						<PrimaryButton onClick={handleNextStep}>{I18n.get('Continue')}</PrimaryButton>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<Container xs={20} sm={20} md={16} lg={14} xl={12} xxl={9} style={{ height: '35.68rem' }}>
			<Form form={form} onFinish={OnFinish}>
				<Row justify="center" style={{ marginTop: '1.2rem' }}>
					<Col span={24} style={{ textAlign: 'center' }}>
						<Title level={3}>Enable two-factor authentication</Title>
					</Col>
					{currentStep === 1 && (
						<Col span={24} style={{ textAlign: 'center', marginTop: '1rem' }}>
							<Text style={{ fontSize: '1rem' }}>
								Select a method to generate your two-factor authentication
							</Text>
						</Col>
					)}
				</Row>
				{steps[currentStep].content}
				<Row justify="center">
					<Buttons
						currentStep={currentStep}
						handleNextStep={handleNextStep}
						handlePreviousStep={handlePreviousStep}
					/>
				</Row>
			</Form>
		</Container>
	);
};
