import { Button, Col, Image, Radio, Row } from 'antd';
import styled from 'styled-components';

import { Text } from '@/components/Typography';

interface MethodRadioProps {
	disabled: boolean;
}

export const Container = styled(Col)`
	border-radius: 30px;
	padding: 2rem 4rem;
	box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.05), 3px 3px 4px 0px rgba(0, 0, 0, 0.05);
	background-color: #fff;

	button {
		width: 100%;
	}
`;

// Force 2FA Step
export const Force2FAContainer = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

// Method Step
export const MethodsContainer = styled(Row)`
	margin-top: 2.5rem;
`;

export const MethodRadioGroup = styled(Radio.Group)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.ant-radio-wrapper-checked {
		color: #fff;
		background-color: #2f54eb;
		box-shadow: -4px -4px 4px 0px rgba(0, 0, 0, 0.08), 4px 4px 4px 0px rgba(0, 0, 0, 0.08);
	}
`;

export const MethodRadioButton = styled(Radio)<MethodRadioProps>`
	align-items: center;
	text-align: justify;
	width: 90% !important;
	padding: 0.8rem 1.5rem;
	min-height: 8.5rem;
	margin-bottom: 1.8rem;
	margin-right: 0;
	border: none;
	border-radius: 1rem;
	background-color: #f5f5f5;
	color: #262626;
	opacity: ${(props) => (props.disabled ? '0.5' : 'none')};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

	.info {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	.ant-radio {
		display: none;
	}
`;

export const MethodIcon = styled.div`
	display: flex;
	align-items: center;
	font-size: 2.5rem;
	margin-right: 1.5rem;
`;

export const MethodInformations = styled(Col)`
	width: 85%;
	line-height: 1rem;

	span {
		font-size: 0.8rem;
	}
`;

export const MethodTitle = styled.div`
	margin-bottom: 0.6rem;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

// Pre-authentication Step
export const IconContainer = styled(Row)`
	margin-top: 3rem;
	font-size: 9rem;
	color: #2f54eb;
`;

export const Tip = styled(Text)`
	margin: 3.5rem 0;
	text-align: center;
	font-size: 1rem;
	font-weight: 400;
`;

export const CustomButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5% 0;
	height: 60%;
	border-radius: 3px;
	font-size: 1rem;
`;

// Validation Step
export const CodeContainer = styled(Row)`
	margin-top: 1rem;

	.ant-image {
		display: flex;
		justify-content: center;
	}
`;

export const QRCode = styled(Image)`
	width: 80%;
	border-radius: 0.25rem;
`;

export const ResendEmail = styled(Text)`
	width: 75%;
	text-align: center;
	font-size: 1.25rem;
	color: #909090;
`;

export const LinkButton = styled(Button)`
	width: auto !important;
	margin: 0;
	padding: 0;
	font-size: 1.25rem;
`;

// Recovery Tokens Step
export const WarningContainer = styled(Col)`
	display: flex;
	justify-content: center;
	padding: 1rem 1rem;
	font-size: 3.5rem;
	border-radius: 50%;
	color: #fff;
	background-color: #2f54eb;
`;
