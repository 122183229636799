import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_ORGANIZATIONS_CONTRACT_PLANS } = QUERY_KEYS;

interface CreateOrganizationParams {
	nit: string;
	city: string;
	address: string;
	district: string;
	company_name: string;
	contract_plan_id: string;
	organization_id: string;
}

async function updateOrganization(payload: CreateOrganizationParams) {
	const { organization_id, ...body } = payload;
	const { data } = await Api.put(`/contract-plan/organization/${organization_id}`, body);
	return data;
}

export const useUpdateOrganization = () => {
	const queryClient = useQueryClient();
	return useMutation(updateOrganization, {
		onSuccess: () => {
			queryClient.invalidateQueries([GET_ORGANIZATIONS_CONTRACT_PLANS]);
		}
	});
};
