import React from 'react';
import { Row, Col } from 'antd';
import { ToolCard } from './ToolCard';
import { CustomSubtitle, CustomTitle } from './styles';

interface ToolProps {
	id: string;
	title: string;
	subtitle: string;
	icon?: JSX.Element;
}

export const Tool: React.FC<ToolProps> = ({ icon, title, subtitle }) => (
	<Col xs={24} lg={12}>
		<ToolCard>
			{icon}
			<Row justify="start">
				<Col xs={24}>
					<CustomTitle>{title}</CustomTitle>
				</Col>
				{subtitle && (
					<Col>
						<CustomSubtitle>{subtitle}</CustomSubtitle>
					</Col>
				)}
			</Row>
		</ToolCard>
	</Col>
);
