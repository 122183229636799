import { Modal, Row, Button, Col } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
	.ant-modal-close-x {
		font-size: 11px;
		line-height: 4.2rem;
		margin-right: 0.8rem;
	}

	.ant-modal-content {
		max-height: 30rem;
		align-items: center;
		border-radius: 1rem;
	}
`;

export const FooterRow = styled(Row)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const RoundButton = styled(Button)`
	border-radius: 6px;
	width: 110px !important;
	height: 35px !important;
`;

export const TitleCol = styled(Col)`
	display: flex;
	justify-content: center;
`;
