import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Button, Col, DatePicker, Form, Input, InputRef, Modal, Row, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { ChecklistActionPlanDTO } from '@/components/views/PreliminaryAnalysis/types/dto/actionPlan';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import hooks from '@/components/views/PreliminaryAnalysis/hooks';
import Spinner from '@/components/Layout/Spinner';
import { Title } from '@/components/Typography';
import {
	ActionPlanTaskRequest,
	CreateChecklistActionPlanRequest,
	TaskListProps,
	UpdateChecklistActionPlanRequest
} from '@/components/views/PreliminaryAnalysis/types/request/actionPlan';

import { EditButton } from './styles';
import { Tasks } from './Tasks';

interface ActionPlanProps {
	stepName: string[];
	stepId: string;
	stepKeyDescription: string;
	visible: boolean;
	onClose: () => void;
	actionPlan: ChecklistActionPlanDTO;
}

const { useForm } = Form;

const { useCreateActionPlan, useUpdateActionPlan, useGetUsers, useFindTasks } = hooks;

export const ActionPlanModal: React.FC<ActionPlanProps> = ({
	stepName,
	visible,
	onClose,
	actionPlan,
	stepId,
	stepKeyDescription
}): JSX.Element => {
	const { file, organization_id, company_id } = usePreliminaryAnalysis();

	const { data: fetchedTasks, isLoading, isStale } = useFindTasks(actionPlan?.id, organization_id, company_id);

	const { data: responsible_users, isFetching: fetchingUsers } = useGetUsers(organization_id, company_id);

	const [tasksBody, setTasksBody] = useState<ActionPlanTaskRequest[]>([]);
	const [responsibleUser, setResponsibleUser] = useState(actionPlan.responsible_user_id);
	const [deadline, setDeadline] = useState(moment(actionPlan.deadline));
	const [description, setDescription] = useState(actionPlan.description);
	const [actionPlanTitle, setActionPlanTitle] = useState(actionPlan.title || stepKeyDescription);
	const [titleInput, setTitleInput] = useState('');
	const [editingTitle, setEditingTitle] = useState(false);

	const [form] = useForm();

	const initialValues = {
		responsible_user: responsible_users.find((user) => user.id === actionPlan.responsible_user_id)?.id,
		deadline: actionPlan.deadline ? moment(actionPlan?.deadline) : '',
		description: actionPlan?.description
	};

	const { mutateAsync: createActionPlan } = useCreateActionPlan();

	const { mutateAsync: updateActionPlan } = useUpdateActionPlan();

	function handleFormValuesChange(changedValues: any) {
		const formFieldName = Object.keys(changedValues)[0];

		if (formFieldName === 'responsible_user') {
			setResponsibleUser(changedValues[formFieldName]);
		}
		if (formFieldName === 'deadline') {
			setDeadline(changedValues[formFieldName]);
		}
		if (formFieldName === 'description') {
			setDescription(changedValues[formFieldName]);
		}
	}

	function handleTasksChange(tasks: TaskListProps[]) {
		const body: ActionPlanTaskRequest[] = tasks.map((task) => {
			const mappedTask: ActionPlanTaskRequest = {
				description: task.description,
				is_completed: task.isCompleted,
				type: task.type
			};

			if (task.taskId) {
				mappedTask.task_id = task.taskId;
			}

			return mappedTask;
		});

		setTasksBody(body);
		form.resetFields(['task_title', 'condition']);
	}

	function onEditTitle() {
		setTitleInput(actionPlanTitle);
		setEditingTitle(true);
	}

	async function onFinishEditing() {
		if (!titleInput) return;

		setActionPlanTitle(titleInput);
		setEditingTitle(false);
		setTitleInput('');
	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setTitleInput(event.target.value);
	}

	const editInputRef = useCallback((node: InputRef) => {
		if (node) {
			node.focus();
		}
	}, []);

	async function createChecklistActionPlan() {
		if (!actionPlan?.id) {
			const body: CreateChecklistActionPlanRequest = {
				company_id: company_id,
				organization_id: organization_id,
				step_name: JSON.stringify(stepName),
				step_id: stepId,
				file_id: file?.id,
				id: file?.preliminary_analysis?.id,
				action_plan: {
					title: actionPlanTitle,
					board: 'TO DO',
					responsible_user_id: responsibleUser,
					deadline: moment(deadline).toDate(),
					description
				}
			};
			if (tasksBody.length > 0) {
				body.tasks = tasksBody;
			}
			createActionPlan({ body }).then(() => {
				onClose();
			});
		} else {
			const body: UpdateChecklistActionPlanRequest = {
				company_id: company_id,
				organization_id: organization_id,
				action_plan_id: actionPlan.id,
				action_plan: {
					title: actionPlanTitle,
					deadline: moment(deadline).toDate() || actionPlan.deadline,
					description: description || actionPlan.description,
					responsible_user_id: responsibleUser || actionPlan.responsible_user_id
				}
			};
			if (tasksBody.length > 0) {
				body.tasks = tasksBody.map((task) => {
					return {
						...task,
						id: task.task_id
					};
				});
			}
			updateActionPlan({ body }).then(() => {
				onClose();
			});
		}
	}

	return (
		<Modal open={visible} onCancel={onClose} width={750} footer={false} centered>
			<Form
				onFinish={createChecklistActionPlan}
				onValuesChange={handleFormValuesChange}
				form={form}
				initialValues={initialValues}
				layout="vertical"
				style={{ minHeight: '500px' }}
			>
				<Row justify={'center'}>
					<Col span={24}>
						<Title level={5}>Create action plan</Title>
					</Col>
					<Col span={22}>
						<Row justify={'center'} gutter={[10, 0]} style={{ marginTop: '1rem' }}>
							<Col span={24}>
								<Title style={{ marginBottom: '1rem' }} level={5}>
									{editingTitle ? (
										<Form.Item>
											<Input
												ref={editInputRef}
												style={{ fontSize: '1.4rem' }}
												value={I18n.get(titleInput)}
												onChange={(e) => handleChange(e)}
												onPressEnter={onFinishEditing}
												onBlur={onFinishEditing}
											></Input>
										</Form.Item>
									) : (
										<Row align={'top'}>
											<Col span={'auto'}>
												<Title style={{ margin: '0' }} level={5}>
													{I18n.get(actionPlanTitle)}
												</Title>
											</Col>
											<Col span={2}>
												<EditButton onClick={onEditTitle}>
													<EditOutlined />
												</EditButton>
											</Col>
										</Row>
									)}
								</Title>
							</Col>
							<Col span={12}>
								<Form.Item
									rules={[{ required: true, message: I18n.get('Please enter a responsible') }]}
									name={'responsible_user'}
									style={{ paddingTop: '10px' }}
									label={I18n.get('Person responsible')}
								>
									<Select
										allowClear
										style={{
											width: '100%'
										}}
										loading={fetchingUsers}
										placeholder={I18n.get('Person responsible')}
										value={
											responsible_users.find((user) => user.id === actionPlan.responsible_user_id)
												?.id
										}
									>
										{responsible_users?.map((user) => (
											<Select.Option key={user.id}>{user.name}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									rules={[{ required: true, message: I18n.get('Please enter a valid date') }]}
									name={'deadline'}
									style={{ paddingTop: '10px' }}
									label={I18n.get('Deadline')}
								>
									<DatePicker
										onChange={() => { }}
										style={{ width: '100%' }}
										placeholder={I18n.get('Deadline')}
										format={'L'}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name={'description'}
									style={{ margin: '0' }}
									label={I18n.get(
										'Describe the situation found and the preventive measures already implemented (if exist)'
									)}
								>
									<Input.TextArea
										rows={2}
										showCount
										maxLength={500}
										placeholder={I18n.get('Enter a description for your action plan.')}
										autoSize={{
											minRows: 3,
											maxRows: 5
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={22}>
						<Row justify={'center'} gutter={[10, 0]} style={{ marginTop: '1rem' }}>
							<Col span={24}>
								<Title level={5}>{I18n.get('Tasks')}</Title>
							</Col>
							{isLoading && !isStale ? (
								<Spinner />
							) : (
								<Tasks fetchedTasks={fetchedTasks} onTasksChange={handleTasksChange} />
							)}
						</Row>
					</Col>
					<Col span={24}>
						<Button
							type="primary"
							htmlType="submit"
							style={{ float: 'right', margin: '1rem 30px 0 0.5rem' }}
						>
							{I18n.get('Save')}
						</Button>
						<Button onClick={onClose} style={{ float: 'right', marginTop: '1rem' }}>
							{I18n.get('Cancel')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};
