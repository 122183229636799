import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import hooks from '@/components/views/PreliminaryAnalysis/hooks';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ActionPlanModal } from '../ActionPlanModal';

import {
	ButtonCard,
	CircleFilledIcon,
	Container,
	ExclamationFilledIcon,
	ResumeCard,
	TitleCard,
	WarningFilledIcon
} from './styles';

export const enumRiskMap = {
	low: 1,
	moderate: 2,
	high: 3,
	very_high: 4,
	extreme: 5
};

const { useFindPreliminaryAnalysisByStepName, useFindActionPlanByStepId } = hooks;

export const RiskLevelCard = ({ step, value }) => {
	const [levelRisk, setLevelRisk] = useState(value);
	const [createActionPlan, setCreateActionPlan] = useState(false);
	const { file, organization_id, company_id } = usePreliminaryAnalysis();

	const { data: preliminaryAnalysis, isFetching: fetchingPreliminaryAnaliysis } =
		useFindPreliminaryAnalysisByStepName(
			organization_id,
			company_id,
			file?.id,
			JSON.stringify(step),
			levelRisk,
			file?.preliminary_analysis?.id
		);

	const {
		data: actionPlan,
		isFetching: fetchingActionPlan,
		refetch
	} = useFindActionPlanByStepId(preliminaryAnalysis.stepId, organization_id, company_id);

	const iconList = {
		[enumRiskMap.low]: <CircleFilledIcon />,
		[enumRiskMap.moderate]: <ExclamationFilledIcon ismoderate="true" />,
		[enumRiskMap.high]: <ExclamationFilledIcon />,
		[enumRiskMap.very_high]: <WarningFilledIcon />,
		[enumRiskMap.extreme]: <WarningFilledIcon />
	};

	const titleList = {
		[enumRiskMap.low]: 'Acceptable',
		[enumRiskMap.moderate]: 'Moderate',
		[enumRiskMap.high]: 'High',
		[enumRiskMap.very_high]: 'Very High',
		[enumRiskMap.extreme]: 'Serious and Imminent'
	};

	const descriptionList = {
		[enumRiskMap.low]: 'Ok',
		[enumRiskMap.moderate]: 'Improvement action',
		[enumRiskMap.high]: 'Immediate action for improvement and reassess',
		[enumRiskMap.very_high]: 'Perform Ergonomic Work Analysis',
		[enumRiskMap.extreme]: 'Stop the activity and carry out an Ergonomic Work Analysis'
	};

	useEffect(() => {
		setLevelRisk(value);
	}, [value]);

	function onCreateActionPlan() {
		setCreateActionPlan((prev) => !prev);
		refetch();
	}

	return (
		<Container gutter={[10, 0]} $levelRisk={levelRisk}>
			<Col span={3} style={{ textAlign: 'center' }}>
				{iconList[levelRisk] || <ExclamationFilledIcon />}
			</Col>
			<Col span={21}>
				<Row>
					<Col span={24}>
						<TitleCard $levelRisk={levelRisk} level={5}>
							{I18n.get(!levelRisk ? 'Fill in the details above' : `${titleList[levelRisk]} Risk`)}
						</TitleCard>
					</Col>
					<Col span={24}>
						<ResumeCard $levelRisk={levelRisk}>{I18n.get(descriptionList[levelRisk])}</ResumeCard>
					</Col>
					<Col span={24} style={{ paddingTop: '5%', ...(!levelRisk && { display: 'none' }) }}>
						<ButtonCard
							key={step}
							loading={fetchingActionPlan}
							$levelRisk={levelRisk}
							size="small"
							onClick={onCreateActionPlan}
						>
							{actionPlan?.id && !fetchingPreliminaryAnaliysis && !fetchingActionPlan
								? I18n.get('Update action plan')
								: I18n.get('Create action plan')}
						</ButtonCard>
					</Col>
				</Row>
			</Col>

			{step?.length > 0 && !fetchingActionPlan && (
				<ActionPlanModal
					key={step}
					stepName={step}
					stepId={preliminaryAnalysis.stepId}
					stepKeyDescription={preliminaryAnalysis.stepKeyDescription}
					visible={createActionPlan}
					onClose={onCreateActionPlan}
					actionPlan={actionPlan}
				/>
			)}
		</Container>
	);
};

RiskLevelCard.propTypes = {
	levelRisk: PropTypes.oneOf([1, 2, 3, 4, 5])
};
