import React from 'react';
import { Input } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItem } from '../../../components/FormItem';

export const RoleName = () => (
	<FormItem
		title="Role"
		fontSize="1rem"
		isRequired={true}
		errorMessage={I18n.get('Insert the role')}
		namePath={['basic_information', 'role_name']}
	>
		<Input size="large" placeholder={I18n.get('Role')} />
	</FormItem>
);
