import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem } from '../styles';
import { CustomCheckboxGroup } from './styles';
import { RequiredLabel } from '../../RequiredLabel';
import { usePersonalisedReportSubStepKeyContext } from '../context';

const { useFormInstance, useWatch } = Form;

export const Injuries: React.FC = () => {
	const form = useFormInstance();
	const { injuries, stepKeyFieldName } = usePersonalisedReportSubStepKeyContext();

	const fieldName = [...stepKeyFieldName, 'injuries'];
	const injuries_ids = useWatch(fieldName, form);

	const options = injuries?.map(({ id, description }) => ({
		value: id,
		label: `${I18n.get(description)}`
	}));

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Possible injuries" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the injury')
					}
				]}
			>
				<CustomCheckboxGroup options={options} value={injuries_ids} />
			</CustomFormItem>
		</Col>
	);
};
