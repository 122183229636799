import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PlusCircleFilled } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Row, Col } from 'antd';
import moment from 'moment';

import { List } from './List';
import { Filter } from './Filter';
import { useGetCustomReports } from './hooks';
import { Title } from '@/components/Typography';
import { FilterRequest, FormFields } from './hooks/types/request';
import { useApplicationContext } from '@/context/Application';
import * as S from './styles';

export function PersonalisedReportsList() {
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const queryParams = convertURLParamToObject(searchParams);
	const { organization, company } = useApplicationContext();
	const [filter, setFilter] = useState<FilterRequest>(queryParams);
	const { data: customReports, isFetching: isLoading } = useGetCustomReports({
		filter,
		company_id: company?.id,
		organization_id: organization?.id
	});

	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	function mapFilterInitialValues(queryParams: FilterRequest) {
		if (Object.keys(queryParams)?.length > 0) {
			const { created_at_start, created_at_end } = queryParams;

			const created_at =
				created_at_start && created_at_end ? [moment(created_at_start), moment(created_at_end)] : undefined;

			const initialValues: FormFields = {
				...queryParams,
				created_at
			};
			return initialValues;
		}
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		return Object.fromEntries(searchParams.entries());
	}

	function handleFilterClick(filterData: FilterRequest) {
		setFilter(filterData);
	}

	function handleOnCreateReport(): void {
		history.push('/settings/personalised-reports/manage/');
	}

	return (
		<Row gutter={[0, 8]}>
			<Col span={24}>
				<Row justify="space-around" align="middle">
					<Col span={12}>
						<Title level={3}>Personalised reports</Title>
					</Col>
					<Col span={12}>
						<S.CreateButton type="primary" onClick={handleOnCreateReport} icon={<PlusCircleFilled />}>
							{I18n.get('New report')}
						</S.CreateButton>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Filter onFilterClick={handleFilterClick} customReports={customReports} initialValues={initialValues} />
			</Col>
			<Col span={24}>
				<List isLoading={isLoading} customReports={customReports} />
			</Col>
		</Row>
	);
}
