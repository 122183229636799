export enum Diagnostics {
	NOT_IDENTIFIED = 'Not identified',
	SAFE = 'Acceptable',
	INFO = 'Investigate',
	WARNING = 'Investigate and change soon',
	DANGER = 'Investigate and change now'
}

export type NotIdentified = Diagnostics.NOT_IDENTIFIED;
export type Safe = Diagnostics.SAFE;
export type Info = Diagnostics.INFO;
export type Warning = Diagnostics.WARNING;
export type Danger = Diagnostics.DANGER;
