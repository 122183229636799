import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_ORGANIZATIONS_CONTRACT_PLANS } = QUERY_KEYS;

export interface GetOrganizationsParams {
	page: number;
	limit: number;
}

export type Contract = any;

export type GetOrganizationsResponse = {
	total: number;
	limit: number;
	rows: Contract[];
	current_page: number;
};

async function getOrganizations(params: GetOrganizationsParams): Promise<GetOrganizationsResponse> {
	const { data } = await Api.get('/contract-plan/organization', { params });
	return data;
}

export const useGetOrganizations = (params: GetOrganizationsParams) => {
	return useQuery([GET_ORGANIZATIONS_CONTRACT_PLANS, params], () => getOrganizations(params));
};
