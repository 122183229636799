import { Text } from '@/components/Typography';
import { Col, Image, Row } from 'antd';
import styled from 'styled-components';

interface PlayerRowContainerProps {
	$isRowSelected: boolean;
}

export const PlayerRowContainer = styled(Row)<PlayerRowContainerProps>`
	padding: 10px;
	cursor: pointer;
	position: relative;
	border-radius: 10px;
	border: ${({ $isRowSelected }) => ($isRowSelected ? '2px solid #2F54EB' : 'none')};
	background-color: ${({ $isRowSelected }) => ($isRowSelected ? '#EAEEFD' : '#ffffff}')};
`;

export const ImageContainer = styled(Col)`
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;

	.ant-image {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media (max-width: 1600px) {
		height: 100%;
	}
`;

export const IconWrapper = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
`;

export const CustomImage = styled(Image)`
	cursor: pointer;
	object-fit: cover;
`;

export const ActionsWrapper = styled(Col)`
	position: absolute;
	top: 8px;
	right: 0;
`;

export const Label = styled(Text)`
	margin-right: 5px;
`;
