export type DataType = {
	id: number;
	description: string;
	risk_scale: number;
	score: number;
	consequence: {
		id: string;
		name: string;
		description: string;
	};
	probability: {
		id: string;
		name: string;
		description: string;
	};
	exhibition: {
		id: string;
		name: string;
		description: string;
	};
	injuries: Array<{ id: string; name: string; description: string }>;
};

export const stepNameDescription: any = {
	workday: 'Workday and concentration at work',
	kind_of_work: 'Kind of work'
};

export const steps: any = {
	workday: 'Workday and concentration at work',
	kind_of_work: 'Kind of work',
	postures_and_movements: 'Postures and movements',
	influence_of_environment: 'Influence of workspace and task factors',
	psychosocial_influence: 'Influence of psychosocial factors',
	influence_of_env_factors: 'Influence of environmental factors',
	organizational_influences: 'Influence of organizational factors'
};
