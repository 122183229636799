import React, { useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Droppable } from 'react-beautiful-dnd';
import { Col, Divider, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';

import { BoardContainer, Counter, ActionList, AddCardBtn } from './styles';
import { useApplicationContext } from '@/context/Application';
import { FILTER_PARAMS, FILTER_SORT_TYPE } from '@/utils/enum';
import { useActionPlans } from '@/hooks/v1/useActionPlans';
import { ActionPlansContext } from './context';
import { Spinner } from '@/components/Spinner';
import { ActionCard } from './ActionCard';

export function BoardColumn({ title, bgColor, type }) {
	const {
		showCreateActionPlan,
		onDeleteActionPlan,
		onEditActionPlan,
		calcProgress,
		setActionPlans,
		actionPlans,
		currentFilter,
		isClearFilter,
		setSelectedIsClearFilter
	} = useContext(ActionPlansContext);

	const [offset, setOffset] = useState(0);
	const [actionPlansSort, setActionPlansSort] = useState([]);

	const { organization, company } = useApplicationContext();

	const {
		data,
		isError,
		isSuccess,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isLoading,
		dataUpdatedAt,
		isInitialLoading,
		remove
	} = useActionPlans({
		organizationId: organization.id,
		companyId: company?.id,
		actionPlansFilter: currentFilter ?? undefined,
		type,
		offset
	});

	useEffect(() => {
		!isInitialLoading && remove();
	}, []);

	useEffect(() => {
		if (isClearFilter) {
			setOffset(0);
			setSelectedIsClearFilter(false);
		}
	}, [currentFilter]);

	useEffect(() => {
		const action_cards_compare = data?.pages[0]?.action_cards.length > 0;

		if (action_cards_compare) {
			const array_action_plans = data.pages[data.pages.length - 1].action_cards;
			array_action_plans.length > 0 && setActionPlans(array_action_plans);
		}
	}, [data?.pages]);

	const findOrderBy = (type) => {
		return (
			currentFilter.includes(type) &&
			currentFilter
				.split('&')
				.find((elem) => elem.includes(type))
				.split('=')[1]
		);
	};

	const sortBy = (array, variable) => {
		const orderScore = findOrderBy(FILTER_SORT_TYPE.SCORE);
		const orderDeadLine = findOrderBy(FILTER_SORT_TYPE.DEADLINE_DATE);

		if (orderScore === FILTER_PARAMS.DESC || orderDeadLine === FILTER_PARAMS.DESC) {
			return array.sort((a, b) => {
				const compare_one = a[`${variable}`] > b[`${variable}`];
				const compare_two = a[`${variable}`] < b[`${variable}`] ? 1 : 0;

				return compare_one ? -1 : compare_two;
			});
		}

		return array.sort((a, b) => {
			const compare_one = a[`${variable}`] < b[`${variable}`];
			const compare_two = a[`${variable}`] > b[`${variable}`] ? 1 : 0;

			return compare_one ? -1 : compare_two;
		});
	};

	const setActionPlansFilter = (actionPlans) => {
		const is_dead_line = currentFilter.includes(FILTER_SORT_TYPE.DEADLINE_DATE);
		const is_worse_score = currentFilter.includes(FILTER_SORT_TYPE.SCORE);

		const filted_action_card = actionPlans.filter((elem) => elem.board === type);

		is_dead_line && !is_worse_score && setActionPlansSort(sortBy(filted_action_card, FILTER_SORT_TYPE.DEADLINE));

		is_worse_score && !is_dead_line && setActionPlansSort(sortBy(filted_action_card, FILTER_SORT_TYPE.SCORE));

		is_worse_score && is_dead_line && setActionPlansSort(sortBy(filted_action_card, FILTER_SORT_TYPE.SCORE));

		!is_dead_line && !is_worse_score && setActionPlansSort(sortBy(filted_action_card, FILTER_SORT_TYPE.LEXO_RANK));
	};

	useMemo(() => {
		if (actionPlans) {
			setActionPlansFilter(actionPlans);
		}
	}, [actionPlans, dataUpdatedAt]);

	async function loadMoreData(index) {
		if (hasNextPage && data.pages.length - 1 === index) {
			await fetchNextPage().then(setOffset(offset + 1));
		}
	}
	const handleOnDelete = (e, id) => {
		e.stopPropagation();
		onDeleteActionPlan(id);
	};

	const handleHasMore = (isFetchingNextPage, hasNextPage) => {
		return !isFetchingNextPage && hasNextPage !== undefined ? hasNextPage : true;
	};
	const showLoadingSpinner = () => {
		return <Spinner />;
	};

	if (isError) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<Col sm={8} md={8} xl={8} xs={8}>
			<BoardContainer style={{ backgroundColor: bgColor }}>
				<Divider style={{ fontSize: '1rem', fontWeight: 'bold' }}>{I18n.get(title)}</Divider>
				<Counter>{data?.pages[0]?.total_plans}</Counter>
				<AddCardBtn onClick={() => showCreateActionPlan(title)}>
					<PlusCircleOutlined />
				</AddCardBtn>
				<Droppable key={type} droppableId={title}>
					{(provided) => (
						<ActionList
							id={`actionPlansListPage${title}`}
							ref={provided.innerRef}
							{...provided.droppableProps}
							style={{ maxHeight: '50rem', overflowY: 'auto' }}
						>
							{isLoading
								? showLoadingSpinner()
								: isSuccess &&
								  data.pages?.map(
										({ action_cards, total_plans }, index, array) =>
											total_plans > 0 &&
											index === array.length - 1 && (
												<InfiniteScroll
													key={action_cards[0]?.id + type}
													dataLength={data?.pages?.reduce(
														(acc, page) => acc + page.action_cards.length,
														0
													)}
													pullDownToRefreshThreshold={50}
													next={() => loadMoreData(index)}
													hasMore={handleHasMore(isFetchingNextPage, hasNextPage)}
													loader={<Skeleton paragraph={{ rows: 2 }} active />}
													scrollableTarget={`actionPlansListPage${title}`}
												>
													{actionPlansSort?.map((actionPlan, index) => (
														<ActionCard
															key={actionPlan.id}
															id={actionPlan.id}
															index={index}
															getNextPageParam
															actionPlan={actionPlan}
															onDelete={(e) => handleOnDelete(e, actionPlan.id)}
															onEdit={onEditActionPlan}
															calcProgress={calcProgress}
														/>
													))}
													{provided.placeholder}
												</InfiniteScroll>
											)
								  )}
						</ActionList>
					)}
				</Droppable>
			</BoardContainer>
		</Col>
	);
}
