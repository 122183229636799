import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Card } from '../../Card';
import { StepKeyToAdd } from '../context/types';
import { LoadingSkeleton } from './LoadingSkeleton';
import { SearchStepName } from './SearchStepKeyName';
import { useGetCustomReportStepKeys } from '../hooks';
import { useCustomReportManagerContext } from '../../context';
import { useCustomReportManagerStepKeyContext } from '../context';
import { StepKeyButton, StepKeysListCol, CustomList } from './styles';
import { useApplicationContext } from '@/context/Application';
import { CustomReportStepKeyListResponse } from '../hooks/types/response';

export const SelectStepKey: React.FC = () => {
	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [searchingStep, setSearchingStep] = useState<boolean>(false);
	const [filteredName, setFilteredName] = useState<string | undefined>(undefined);

	const { company, organization } = useApplicationContext();
	const { selectedSteps, selectedStepId } = useCustomReportManagerContext();
	const { addStepKeyToStepKeyList } = useCustomReportManagerStepKeyContext();

	const selectedStep = selectedSteps.find(({ id }) => id === selectedStepId);

	const {
		isSuccess,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
		data: stepsPages,
		isFetchingNextPage
	} = useGetCustomReportStepKeys({
		company_id: company?.id,
		description: filteredName,
		organization_id: organization?.id,
		custom_report_step_setting_id: selectedStep?.step_setting_id
	});

	function setReportName(name: string): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setFilteredName(name);
			setSearchingStep(false);
		}, 1000);

		setTimer(debounce);
	}

	function handleSearchStepNameChange(name: string): void {
		setSearchingStep(true);
		setReportName(name);
	}

	async function loadMoreData(): Promise<void> {
		if (hasNextPage && !isFetchingNextPage) {
			await fetchNextPage();
		}
	}

	function isLoadingStepsList(): boolean {
		return isLoading || isFetching || searchingStep || isFetchingNextPage;
	}

	function renderSkeleton(): React.ReactNode {
		if (!stepsPages || !isSuccess || searchingStep) {
			return <LoadingSkeleton />;
		}
	}

	function handleStepKeyClick(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		stepKeyToAdd: StepKeyToAdd
	): void {
		event.preventDefault();
		event.stopPropagation();
		addStepKeyToStepKeyList(stepKeyToAdd);
	}

	function renderInfiniteScroll(stepKeys: CustomReportStepKeyListResponse) {
		return (
			<InfiniteScroll
				loader={<></>}
				next={loadMoreData}
				key={stepKeys?.rows[0]?.id}
				dataLength={stepKeys.count}
				scrollableTarget="stepKeysList"
				hasMore={!isFetchingNextPage && hasNextPage !== undefined ? hasNextPage : true}
			>
				{stepKeys?.rows.map(({ id, has_injury, description, ergonomic_tools, step_keys_settings }) => (
					<StepKeyButton
						key={id}
						onClick={(event) =>
							handleStepKeyClick(event, {
								id,
								description,
								hasInjury: has_injury,
								tools: ergonomic_tools.map(({ id, name, subtitle, title }) => ({
									id,
									name,
									title,
									subtitle
								})),
								step_setting_id: step_keys_settings[0]?.id
							})
						}
					>
						<Row justify="center">
							<CustomList xs={24}>{I18n.get(description)}</CustomList>
						</Row>
					</StepKeyButton>
				))}
			</InfiniteScroll>
		);
	}

	function hasNoSteps(): boolean {
		return stepsPages?.pages[0].count === 0 && isSuccess;
	}

	return (
		<Card title="Recommended criteria">
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<SearchStepName onSearchStepNameChange={handleSearchStepNameChange} />
				</Col>
				<StepKeysListCol span={24} id="stepKeysList">
					{renderSkeleton()}
					{stepsPages?.pages.map((steps) => renderInfiniteScroll(steps))}
					{!hasNextPage && !hasNoSteps() && !isLoadingStepsList() && (
						<Divider plain>{I18n.get('No more criteria available')}</Divider>
					)}
				</StepKeysListCol>
				{hasNoSteps() && !isLoadingStepsList() && <Divider plain>{I18n.get('No criteria available')}</Divider>}
			</Row>
		</Card>
	);
};
