import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useCreateOrganizationContext } from '../context';
import { CustomButton } from '../../styles';
import * as S from './styles';

export function Footer() {
	const { currentStep, onNextStep, onPreviousStep } = useCreateOrganizationContext();

	const is_first_step = currentStep === 0;

	const buttonsSteps = [
		{
			previous: 'Cancel',
			next: 'Next'
		},
		{
			previous: 'Back',
			next: 'Next'
		},
		{
			previous: 'Back',
			next: 'Close'
		}
	];

	return (
		<S.Container span={24}>
			<Row justify="center" align="middle" gutter={[15, 0]}>
				{is_first_step && (
					<Col>
						<CustomButton type="ghost" onClick={onPreviousStep}>
							{I18n.get(buttonsSteps[currentStep].previous)}
						</CustomButton>
					</Col>
				)}
				<Col>
					<CustomButton type="primary" onClick={onNextStep}>
						{I18n.get(buttonsSteps[currentStep].next)}
					</CustomButton>
				</Col>
			</Row>
		</S.Container>
	);
}
