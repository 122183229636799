import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { NameInput } from '../styles';

const { useFormInstance } = Form;

export function CreateWorkstation() {
	const [workstationName, setWorkstationName] = useState('');

	const { validateFields, resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		sectorId,
		creatingOrganizationData,
		isEditing,
		handleWorkstation,
		createOrganizationData,
		treatFieldValidation
	} = useManageOrganization();

	async function handleCreateWorkstation() {
		try {
			await validateFields(['workstation_name']);
			const created = await createOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				sector_id: sectorId,
				workstation_name: workstationName
			});

			setWorkstationName('');
			handleWorkstation(created.id, resetFields, setFieldValue);
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	function handleWorkstationNameChange(name: string) {
		setWorkstationName(name);
	}

	return (
		<Col span={24}>
			<Row align="bottom" gutter={[12, 0]}>
				<Col span={20}>
					<NameInput
						disabled={isEditing}
						name="workstation_name"
						label={I18n.get('Create workstation')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid name')
							}
						]}
					>
						<Input
							disabled={isEditing}
							placeholder={I18n.get('Workstation name')}
							onChange={(e) => handleWorkstationNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={2}>
					<Button
						disabled={isEditing}
						loading={creatingOrganizationData}
						type="primary"
						ghost={true}
						htmlType="submit"
						onClick={() => handleCreateWorkstation()}
					>
						{I18n.get('Create')}
					</Button>
				</Col>
			</Row>
		</Col>
	);
}
