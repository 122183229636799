import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetContractPlans } from '@/components/Settings/ContractPlans/hooks/index';
import { Address, City, District, Nit, Plan, PrimaryField } from '../../../components';
import { SubTitle } from '../../../styles';

export function Informations() {
	const { data } = useGetContractPlans();

	return (
		<Row gutter={[0, 25]}>
			<Col span={24}>
				<PrimaryField
					required
					name="name"
					title={I18n.get('Corporate name')}
					errorMessage={I18n.get('Enter a corporate name')}
				/>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<SubTitle>{I18n.get('Information')}</SubTitle>
					</Col>
					<Col>
						<Row gutter={[40, 15]}>
							<Col span={24}>
								<Address name="address" required />
							</Col>
							<Col span={12}>
								<City name="city" required />
							</Col>
							<Col span={12}>
								<District name="district" required />
							</Col>
							<Col span={12}>
								<Plan name="plan" options={data} required />
							</Col>
							<Col span={12}>
								<Nit name="nit" required />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
