import React from 'react';
import moment from 'moment';
import { Grid, Table, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/lib/table';

import { ActionsForFile, FileType } from '../ActionsForFile';
import { riskCalc, riskLevels } from '@/utils/riskRange';
import { CustomTableContainer } from './styles';
import { fileStatusMapper } from '@/utils/enum';
import { Text } from '@/components/Typography';
import { FilesListResponse } from '@/hooks';
import { TagRisk } from '../../styles';
import { FileStatus } from '@/types';

export interface ListWithoutPlayerProps {
	loading: boolean;
	selectedRows: string[];
	files: FilesListResponse[];
	onEditFile: (id: string) => void;
	onSelectRow: (id: string) => void;
	onDeleteFile: (id: string) => Promise<void>;
	onDownloadVideo: (file: FileType) => Promise<void>;
	onRedirectPreliminary: (id: FilesListResponse) => void;
	onShowReportOptionsReports: (file: FilesListResponse) => void;
}

export function ListWithoutPlayer({
	files,
	loading,
	onEditFile,
	onSelectRow,
	onDeleteFile,
	selectedRows,
	onDownloadVideo,
	onRedirectPreliminary,
	onShowReportOptionsReports
}: Readonly<ListWithoutPlayerProps>) {
	function handleRowClick(record: FilesListResponse) {
		onSelectRow(record.id);
	}

	function rowClassName(record: FilesListResponse) {
		return selectedRows.includes(record.id) ? 'selected-row' : '';
	}

	const breakPoints = Grid.useBreakpoint();

	const columns: ColumnsType<FilesListResponse> = [
		{
			title: <Text>Name</Text>,
			dataIndex: 'original_name',
			ellipsis: true,
			key: 'original_name',
			align: 'center',
			render: (original_name) => (
				<Tooltip title={original_name}>
					<Text>{original_name}</Text>
				</Tooltip>
			)
		},
		{
			title: <Text>Company</Text>,
			dataIndex: 'organization',
			ellipsis: true,
			key: 'organization',
			align: 'center',
			responsive: ['xl'],
			render: (text) => <Text>{text ? text.name : '-'}</Text>
		},
		{
			title: <Text>Industrial Site</Text>,
			dataIndex: 'company',
			ellipsis: true,
			key: 'company',
			align: 'center',
			responsive: ['xl'],
			render: (text) => <Text>{text ? text.name : '-'}</Text>
		},
		{
			title: <Text>Sector</Text>,
			dataIndex: 'sector',
			ellipsis: true,
			key: 'sector',
			align: 'center',
			responsive: ['xxl'],
			render: (text) => <Text>{text ? text.name : '-'}</Text>
		},
		{
			title: <Text>Workstation</Text>,
			dataIndex: 'workstations',
			ellipsis: false,
			key: 'workstations',
			align: 'center',
			responsive: ['xxl'],
			render: (text) => (
				<Tooltip title={text?.name}>
					<Text ellipsis={true}>{text?.name ?? '-'}</Text>
				</Tooltip>
			)
		},
		{
			title: <Text>Upload date</Text>,
			dataIndex: 'createdAt',
			ellipsis: false,
			key: 'createdAt',
			align: 'center',
			responsive: ['xl'],
			render: (date) => moment(date).format('L')
		},
		{
			title: <Text>Status </Text>,
			dataIndex: 'status',
			ellipsis: true,
			key: 'status',
			align: 'center',
			responsive: ['xl'],
			render: (status: FileStatus) => `${I18n.get(fileStatusMapper[status] || '-')}`
		},

		{
			title: <Text>Rating</Text>,
			dataIndex: 'preliminary_analysis',
			ellipsis: false,
			key: 'preliminary_analysis',
			align: 'center',
			render: (num) =>
				num?.consolidated ? (
					<TagRisk
						style={{ marginRight: 0 }}
						$result={riskCalc(num.worst_score)}
						$isBrightBackground={riskCalc(num.worst_score)}
					>
						<Tooltip title={I18n.get(`${riskLevels[riskCalc(num.worst_score)]}`)}>
							{riskCalc(num.worst_score) === 5 ? (
								<Text ellipsis={true}>Serious risk</Text>
							) : (
								<Text ellipsis={true}>{riskLevels[riskCalc(num.worst_score)]}</Text>
							)}
						</Tooltip>
					</TagRisk>
				) : (
					<Text>Not assigned</Text>
				)
		},
		{
			title: <Text>Actions</Text>,
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			width: breakPoints.xxl ? undefined : 80,
			render: (_, file) => (
				<ActionsForFile
					file={file}
					onEdit={onEditFile}
					onDelete={onDeleteFile}
					onDownload={onDownloadVideo}
					isDropDown={!breakPoints.xxl}
					onRedirectPreliminary={onRedirectPreliminary}
					onShowReportOptionsReports={onShowReportOptionsReports}
				/>
			)
		}
	];

	return (
		<CustomTableContainer>
			<Table
				rowKey="id"
				columns={columns}
				loading={loading}
				dataSource={files}
				pagination={false}
				rowClassName={rowClassName}
				onRow={(record: FilesListResponse) => ({
					onClick: () => {
						handleRowClick(record);
					}
				})}
			/>
		</CustomTableContainer>
	);
}
