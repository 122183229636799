import React from 'react';
import { Row, Col } from 'antd';

interface ContentProps {
	children: string | JSX.Element | JSX.Element[];
}

export const Content: React.FC<ContentProps> = ({ children }) => (
	<Col xs={22} sm={14} md={10} lg={10} xl={6}>
		<Row gutter={[0, 20]} justify="center" align="middle">
			{children}
		</Row>
	</Col>
);
