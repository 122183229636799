import React, { useState } from 'react';
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import _ from 'lodash';

import { CustomReportResponse } from '../../PersonalisedReport/hooks';
import { FilterRequest, FormFields } from '../hooks/types/request';
import { CreatedByUserId } from './CreatedByUserId';
import { Description } from './Description';
import { CustomCollapse } from './styles';
import { CreatedAt } from './CreatedAt';

const { useForm } = Form;
const { Panel } = Collapse;
enum COLLAPSBILE_ENUM {
	ICON = 'icon',
	HEADER = 'header',
	DISABLED = 'disabled'
}

interface FilterProps {
	initialValues: FormFields;
	customReports?: CustomReportResponse[];
	onFilterClick(filterData: FilterRequest): void;
}

export const Filter: React.FC<FilterProps> = ({ onFilterClick, customReports, initialValues }) => {
	const [form] = useForm();
	const history = useHistory();
	const { search } = useLocation();
	const [disabled, setDisabled] = useState(true);
	const searchParams = new URLSearchParams(search);

	function handleFieldsChange(_changedFields: any, allFields: any[]): void {
		const allFieldsEmpty = allFields?.filter((field) => !!field.value);
		if (allFieldsEmpty.length === 0) {
			setDisabled(true);
			return;
		}
		setDisabled(false);
	}

	function handleClear(): void {
		history.push({
			pathname: '/settings/personalised-reports',
			search: '?cleared=true'
		});
		form.resetFields();
	}

	function handleFinish(values: FormFields): void {
		const { created_at, created_by_user_id, description } = values;
		const params: FilterRequest = {
			description,
			created_by_user_id,
			created_at_end: undefined,
			created_at_start: undefined
		};

		if (created_at) {
			formatStartAndEndDate(created_at, params);
		}

		updateURLParams(params);
		onFilterClick(params);
	}

	function formatStartAndEndDate(created_at: moment.Moment[], params: FilterRequest): void {
		const [start_date, end_date] = created_at;
		const start = moment(start_date).format();
		const end = moment(end_date).format();
		params.created_at_start = start;
		params.created_at_end = end;
	}

	function updateURLParams(params: FilterRequest): void {
		for (const key in params) {
			searchParams.delete('cleared');
			searchParams.delete(key);
			if (Object.hasOwn(params, key)) {
				const typedKey: keyof FilterRequest = key as any;
				const param = params[typedKey];
				if (param && !_.isArray(param)) {
					searchParams.set(key, param);
				} else if (_.isArray(param)) {
					searchParams.set(key, JSON.stringify([...param]));
				}
			}
		}

		history.push({
			pathname: '/settings/personalised-reports',
			search: searchParams.toString()
		});
	}

	const collapsible: CollapsibleType = isCustomReportListEmpty()
		? COLLAPSBILE_ENUM.DISABLED
		: COLLAPSBILE_ENUM.HEADER;

	function isCustomReportListEmpty() {
		return !customReports || customReports.length === 0;
	}

	return (
		<CustomCollapse collapsible={collapsible} defaultActiveKey={search ? '1' : '0'}>
			<Panel key="1" header={I18n.get('Filter')}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleFinish}
					initialValues={initialValues}
					onFieldsChange={handleFieldsChange}
				>
					<Col xs={24}>
						<Row gutter={8}>
							<Description />
							<CreatedByUserId />
							<CreatedAt />
						</Row>
					</Col>
					<Row gutter={8} style={{ paddingTop: '20px' }}>
						<Col sm={4}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={disabled}
								icon={<SearchOutlined />}
								style={{ width: '100%' }}
							>
								{I18n.get('Search')}
							</Button>
						</Col>
						<Col sm={4}>
							<Button style={{ width: '100%' }} onClick={handleClear}>
								{I18n.get('Clear')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Panel>
		</CustomCollapse>
	);
};
