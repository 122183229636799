import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { customReportService } from '../../../../hooks/services';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { CustomReportResultWorkCondition, Response } from '@/types';

export type CreateWorkConditionResultDTO = BaseContext & {
	work_hours: number;
	work_minutes: number;
	workstation_id?: string;
	step_setting_id?: string;
	custom_report_id: string;
	place_description?: string;
	custom_report_result_id?: string;
	expected_task_description?: string;
	performed_task_description?: string;
};

export type PersonalisedReportWorkConditionResultCreateResponse = CustomReportResultWorkCondition;

async function createWorkConditionResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/work-condition';
	const { data } = await Api.post<T>(url, payload);
	return data;
}

export function useCreateWorkConditionResult() {
	const queryClient = useQueryClient();
	return useMutation<
		PersonalisedReportWorkConditionResultCreateResponse,
		AxiosError<Response>,
		CreateWorkConditionResultDTO
	>(
		(body) =>
			createWorkConditionResult<
				PersonalisedReportWorkConditionResultCreateResponse,
				CreateWorkConditionResultDTO
			>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
}
