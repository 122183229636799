import Api from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { WorkerSelfEvaluation } from '@/types/entities/WorkerSelfEvaluation';

type WorkerSelfEvaluationResponse = Pick<WorkerSelfEvaluation, 'id' | 'name' | 'description' | 'score'>;

async function getWorkerSelfEvaluations(params: BaseContext): Promise<WorkerSelfEvaluationResponse[]> {
	const url = '/ergonomic-tool/self-evaluation/find-all';
	const { data } = await Api.get<WorkerSelfEvaluationResponse[]>(url, {
		params
	});
	return data;
}

export function useWorkerSelfEvaluations({ organization_id, company_id }: BaseContext) {
	const enabled = !!organization_id && !!company_id;

	const workerSelfEvaluations = useQuery(
		['worker_self_evaluations', organization_id],
		() => getWorkerSelfEvaluations({ organization_id, company_id }),
		{
			enabled
		}
	);
	return {
		...workerSelfEvaluations,
		data: workerSelfEvaluations?.data ?? ([] as WorkerSelfEvaluationResponse[])
	};
}
