import React from 'react';
import { Col, Space } from 'antd';

export const Icon: React.FC = () => (
	<Col span={24}>
		<Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
			<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="39" cy="39" r="37.4" fill="#2F54EB" stroke="#2F54EB" stroke-width="3.2" />
				<path
					d="M42.2523 18.9902L41.7884 43.6973H37.1497L36.515 18.9902H42.2523ZM39.5423 54.4883C38.5658 54.4883 37.7357 54.179 37.0521 53.5605C36.3685 52.9258 36.0267 52.1608 36.0267 51.2656C36.0267 50.3379 36.3685 49.5485 37.0521 48.8975C37.7357 48.2464 38.5658 47.9209 39.5423 47.9209C40.4863 47.9209 41.3001 48.2464 41.9837 48.8975C42.6836 49.5485 43.0335 50.3379 43.0335 51.2656C43.0335 52.1608 42.6836 52.9258 41.9837 53.5605C41.3001 54.179 40.4863 54.4883 39.5423 54.4883Z"
					fill="white"
				/>
			</svg>
		</Space>
	</Col>
);
