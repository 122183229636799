import React, { useState } from 'react';
import { Col, Row, Divider, UploadFile } from 'antd';

import { Field, Information, Section, Subsection, TitleSection } from '../styles';
import { useApplicationContext } from '@/context/Application';
import { UploadLogo } from '@/components/ui/UploadLogos';
import { I18n } from '@aws-amplify/core';
import { User } from '@/types/Account';

interface ProfileProps {
	data: User;
}

const roles: Record<string, string> = {
	USER: 'User',
	ADMIN: 'Admin',
	MASTER: 'Master',
	SUPERVISOR: 'Supervisor'
};

export const Profile: React.FC<ProfileProps> = ({ data }) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const { organization, company, info_user } = useApplicationContext();

	return (
		<Section>
			<Row>
				<Col md={15} lg={17} xxl={16}>
					<TitleSection>{I18n.get('Profile')}</TitleSection>
					<Row>
						<Subsection>
							<Field>{I18n.get('Name')}</Field>
							<Information>{data.name}</Information>
						</Subsection>
						<Divider style={{ margin: '25px 0' }} />
						<Subsection>
							<Field>{I18n.get('Email')}</Field>
							<Information>{data.email}</Information>
						</Subsection>
						<Divider style={{ margin: '25px 0' }} />
						<Subsection>
							<Field>{I18n.get('Account type')}</Field>
							<Information>{roles[data.role]}</Information>
						</Subsection>
					</Row>
				</Col>
				<Col md={2} lg={2} xxl={4} offset={2} style={{ marginTop: '15px' }}>
					<UploadLogo
						organizationId={organization?.id}
						companyId={company?.id}
						url={info_user?.user.logo}
						type={'customer'}
						fileList={fileList}
						setFileList={setFileList}
						isDisable={false}
					/>
				</Col>
			</Row>
		</Section>
	);
};
