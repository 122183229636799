import React from 'react';
import { Collapse } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CollapseContainer } from './styles';

const { Panel } = Collapse;

interface FilterCollapseProps {
	children: React.ReactNode;
	isCollapseOpen?: boolean;
}

export function FilterCollapse({ children, isCollapseOpen = true }: Readonly<FilterCollapseProps>) {
	return (
		<CollapseContainer defaultActiveKey={isCollapseOpen ? '1' : '0'}>
			<Panel key="1" header={I18n.get('Filter')}>
				{children}
			</Panel>
		</CollapseContainer>
	);
}
