import { ScoreScales } from '@/types';

export type ScoreScalesExposure = {
	[key in ScoreScales]: {
		ACCEPTBALE: string;
		LOW: string;
		MEDIUM: string;
		MEDIUM_HIGH: string;
		HIGH: string;
		EXTREME: string;
	};
};

export const scoreScaleExposures: ScoreScalesExposure = {
	[ScoreScales.RPN_THREE]: {
		ACCEPTBALE: 'low',
		LOW: 'low',
		MEDIUM: 'medium',
		MEDIUM_HIGH: 'medium',
		HIGH: 'high',
		EXTREME: 'high'
	},
	[ScoreScales.RPN_SIX]: {
		ACCEPTBALE: 'one_to_ten',
		LOW: 'eleven_to_twenty',
		MEDIUM: 'twenty_one_to_thirty',
		MEDIUM_HIGH: 'thirty_one_to_fifty',
		HIGH: 'fifty_one_to_seventy_five',
		EXTREME: 'seventy_six_to_a_hundred'
	},
	[ScoreScales.WILLIAM_T_FINE]: {
		ACCEPTBALE: 'remotely',
		LOW: 'rarely',
		MEDIUM: 'irregular',
		MEDIUM_HIGH: 'occasionally',
		HIGH: 'frequent',
		EXTREME: 'to_be_continued'
	}
};
