import { Button, Col, Row } from 'antd';
import styled from 'styled-components';

interface CustomButtonProps {
	incomplete?: boolean;
}

export const CustomRow = styled(Row)`
	width: 100%;
	position: relative;
`;

export const StepButton = styled(Button)<CustomButtonProps>`
	font-size: 1rem;
	height: max-content;
	border-radius: 0.5rem;
	padding: 0.25rem 1.5rem;
	color: ${({ incomplete }) => incomplete && '#262626'};
	border: ${({ incomplete }) => incomplete && '2px solid #E74150'};
	background-color: ${({ incomplete }) => incomplete && '#E741504D'};
	:not([disabled]) {
		:hover {
			border: ${({ incomplete }) => incomplete && '2px solid #E74150 !important'};
			background-color: ${({ incomplete }) => incomplete && '#E741504D !important'};
		}
		:active {
			border: ${({ incomplete }) => incomplete && '2px solid #E74150 !important'};
			background-color: ${({ incomplete }) => incomplete && '#E74150 !important'};
		}
		:focus {
			border: ${({ incomplete }) => incomplete && '2px solid #E74150 !important'};
			background-color: ${({ incomplete }) => incomplete && '#E74150 !important'};
		}
	}
`;

export const StepListCol = styled(Col)`
	position: relative;
`;

export const StepListContainer = styled.div`
	width: 85%;
	overflow-x: scroll;
	overflow-y: hidden;
	flex-direction: row;
	display: inline-flex;

	::-webkit-scrollbar-track {
		border: none;
		padding: 0 2px;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 85%;
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 0.5rem;
		background-color: transparent;
		box-shadow: inset 0 0 6px #9c9c9c;
	}
`;

export const EmptyContainer = styled.div`
	min-width: 2rem;
	background-color: #ffffff;
`;

export const SummaryButtonContainer = styled.div`
	display: flex;
	right: 0.5rem;
	padding: 0rem 1.5rem;
	position: absolute;
	flex-direction: row;
	border: 2px solid rgba(255, 255, 255, 0.95);
	background: linear-gradient(275deg, #ffffff 4.53%, rgba(255, 255, 255, 0.95) 115.16%);
`;

export const ShadowContainer = styled.div`
	height: 100%;
	left: -0.5rem;
	position: absolute;
	box-shadow: 5px -1px 4px 1px rgba(0, 0, 0, 0.3);
`;

export const ErrorMessageCol = styled(Col)`
	top: 15%;
	left: 43%;
	position: absolute;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	backdrop-filter: blur(15px);
	background: linear-gradient(89deg, rgba(231, 65, 80, 0.85) 6.33%, rgba(217, 17, 35, 0.9) 96.55%);

	span {
		color: #ffffff;
		font-size: 1rem;
		font-weight: 600;
		text-align: center;
		font-style: normal;
		line-height: normal;

		@media (max-width: 1280px) {
			font-size: 0.8rem;
		}
	}
`;
