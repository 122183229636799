import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_ORGANIZATIONS_CONTRACT_PLANS } = QUERY_KEYS;

interface CreateOrganizationParams {
	nit: string;
	city: string;
	address: string;
	district: string;
	company_name: string;
	contract_plan_id: string;
}

export type OrganizationDTO = {
	id: string;
	name: string;
	updatedAt: Date;
	createdAt: Date;
	isActive: true;
};

async function createOrganization(payload: CreateOrganizationParams): Promise<OrganizationDTO> {
	const { data } = await Api.post('/contract-plan/organization', payload);
	return data;
}

export const useCreateOrganization = () => {
	const queryClient = useQueryClient();
	return useMutation(createOrganization, {
		onSuccess: () => {
			queryClient.invalidateQueries([GET_ORGANIZATIONS_CONTRACT_PLANS]);
		}
	});
};
