import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getUsers({ organizationId, companyId }) {
	if (!organizationId || !companyId) return [];
	const url = `/user/user-list/${organizationId}/${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

export function useUsers(payload) {
	const enabled = !!payload.organizationId && !!payload.companyId;

	const users = useQuery(['users', payload], () => getUsers(payload), {
		enabled
	});
	return { users };
}
