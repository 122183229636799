import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Container } from './styles';
import { Title } from '@/components/Typography';
import { ActionPlanData } from './ActionPlanData';
import { usePersonalisedReportResultContext } from '../../context';

export const ActionPlans: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();

	const action_plans = personalisedReportResult?.basic_information?.action_plans;
	if (!action_plans || action_plans.length === 0) {
		return null;
	}

	return (
		<Row justify="center">
			<Col xs={24}>
				<Row gutter={[0, 24]}>
					<Col offset={1} span={23}>
						<Row>
							<Title style={{ margin: 0 }} level={3}>
								{I18n.get('Action plans')}
							</Title>
						</Row>
					</Col>

					<Col offset={1} span={23}>
						{action_plans.map((actionPlan) => (
							<Container key={actionPlan.id}>
								<ActionPlanData actionPlan={actionPlan} />
							</Container>
						))}
					</Col>
				</Row>
			</Col>
			<Divider type="horizontal" />
		</Row>
	);
};
