import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';

import { Card } from '../../Card';
import { StepCard } from './StepCard';
import { EmptyList } from './EmptyList';
import { CustomButton } from '../../styles';
import { CustomColSelectedStepsList } from './styles';
import { useCustomReportManagerContext } from '../../context';
import { useCustomReportManagerStepContext } from '../context';

const { useFormInstance } = Form;

export const SelectedStepsList: React.FC = () => {
	const form = useFormInstance();
	const { handleNextStep } = useCustomReportManagerContext();
	const { stepsList, changeStepListSequence } = useCustomReportManagerStepContext();

	function handleDragEnd(result: DropResult): void {
		const { destination, source } = result;
		if (!destination) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		changeStepListSequence({
			list: stepsList,
			startIndex: source.index,
			endIndex: destination.index
		});
	}

	async function handleButtonClick(): Promise<void> {
		await form.validateFields();
		handleNextStep();
	}

	if (stepsList.length === 0) {
		return <EmptyList />;
	}

	return (
		<Row justify="center" style={{ height: '100%' }}>
			<Col span={24}>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Card title="Selected sections">
						<Row justify="center" gutter={[0, 20]}>
							<Col span={24}>
								<Droppable droppableId={stepsList[0]?.id}>
									{({ droppableProps, innerRef, placeholder }) => (
										<CustomColSelectedStepsList xs={24} ref={innerRef} {...droppableProps}>
											{stepsList?.map(({ id, description }, index) => (
												<StepCard id={id} key={id} index={index} description={description} />
											))}
											{placeholder}
										</CustomColSelectedStepsList>
									)}
								</Droppable>
							</Col>
							<Col span={24} style={{ textAlign: 'center' }}>
								<CustomButton
									type="primary"
									onClick={handleButtonClick}
									disabled={stepsList.length === 0}
								>
									{I18n.get('Forward')}
								</CustomButton>
							</Col>
						</Row>
					</Card>
				</DragDropContext>
			</Col>
		</Row>
	);
};
