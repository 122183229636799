import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { File } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetNioshParams {
	organization_id: string;
	company_id: string;
	id: string;
}

type GetNioshError = AxiosError<any>;

type GetNioshResponse = {
	id: string;
	report_name?: string;
	report_type?: 'NIOSH' | 'ISO_11228';
	gender?: string;
	age?: string;
	workers?: number;
	hands?: number;
	coupling?: string;
	frequency?: string;
	duration?: string;
	mass_m?: string;
	distance_dc?: number;
	dc_factor?: string;
	distance_vc?: number;
	vc_factor?: string;
	distance_h?: number;
	h_factor?: string;
	angle_a?: number;
	a_factor?: string;
	coupling_factor?: string;
	frequency_factor?: string;
	one_handed_factor?: string;
	multiple_workers_factor?: string;
	reference_weight?: number;
	recommended_weight_limit?: string;
	lifting_index?: string;
	risk?: string;
	collection_date: Date | string;
	comment?: string;
	consolidated: boolean;
	isActive: boolean;
	createdAt: Date;
	updatedAt: Date;
	evaluator_id?: string;
	file_id?: string;
	cellule_id?: string;
	workstation_id?: string;
	activity_id?: string;
	report_user_id: string;
	report_user: ReportUser;
	file?: File;
	evaluator?: Cellule;
	activity?: Activity;
	cellule?: Cellule;
	workstation?: Workstation;
	critical_factors: CriticalFactor[];
	current_step?: number;
};

type Activity = {
	id: string;
	name: string;
	workstation_id: string;
};

type Cellule = {
	id: string;
	name: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	sector_id?: string;
	company_id?: string;
};

type ReportUser = {
	id: string;
	name: string;
};

type Workstation = {
	id: string;
	name: string;
	sector_id?: string;
	sectors: Sectors;
};

type Sectors = {
	name: string;
	id: string;
	company: Company;
};

type Company = {
	id: string;
	name: string;
	url_logo: string;
	Organization: Organization;
};

type Organization = {
	name: string;
	url_logo: string;
	id: string;
};

type CriticalFactor = {
	title: string;
	label: string;
	value: number;
	suggestion: string;
};

async function getNiosh(payload: GetNioshParams) {
	const { id, ...params } = payload;
	const { data } = await Api.get(`/ergonomic-tool/niosh/${id}`, { params });
	return data;
}

export const useGetNiosh = (params: GetNioshParams) => {
	return useQuery<GetNioshResponse, GetNioshError>([QUERY_KEYS.GET_NIOSH_REPORT, params], () => getNiosh(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.id
	});
};
