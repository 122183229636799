import React from 'react';
import { Row, Col } from 'antd';
import { StepTitle } from './StepTitle';
import { useCustomReportManagerContext } from './context';

export const CustomReportForm: React.FC = () => {
	const { steps, step } = useCustomReportManagerContext();
	return (
		<Row justify="center">
			<StepTitle />
			<Col xs={24}>{steps[step].component}</Col>
		</Row>
	);
};
