import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { User, Plan } from '@/types';

type AccessTokenType = string;

interface TwoFactorAuth {}

export interface UserDataResponse {
	plan: Plan;
	refresh_token: string;
	token: string;
	two_fa: TwoFactorAuth;
	user: User;
}

interface ErrorResponse {
	message: string;
}

const getAuthToken = async (accessToken: AccessTokenType): Promise<UserDataResponse> => {
	const { data } = await Api.post('/sso/token', { access_token: accessToken });
	return data;
};

export const useAuthToken = () => {
	return useMutation<UserDataResponse, AxiosError<ErrorResponse>, AccessTokenType>(getAuthToken);
};
