import React from 'react';
import { I18n } from '@aws-amplify/core';
import { CustomInput } from './styles';
import { CustomFormItem } from '../../styles';

interface DescriptionProps {
	isLoading?: boolean;
}

export function Description({ isLoading = false }: DescriptionProps) {
	return (
		<CustomFormItem name={['step', 'description']}>
			<CustomInput placeholder={I18n.get('Section title')} size="large" disabled={isLoading} />
		</CustomFormItem>
	);
}
