import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MenuPopover } from './Menu';
import authActions from '@/redux/auth/actions';

export const Popover: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const handleRedirectMyAccount = useCallback((): void => {
		history.push('/settings/account');
	}, [history]);

	const onLogout = useCallback((): void => {
		dispatch(authActions.logout());
	}, [dispatch]);

	return <MenuPopover onLogout={onLogout} onMyAccount={handleRedirectMyAccount} />;
};
