import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form } from 'antd';
import moment from 'moment';

import { Context, Methods, States } from './types';
import { usePersonalisedReportContext } from '../../../../context';
import { AntdDropdownOption, DropdownOptions } from '../../../../context/types';
import { useCalculateCustomReportStepKeyRisk, useGetActionPlan } from '../hooks';
import { CustomReportSubStepKeyResponse, PersonalisedReportStepKeysResponse, RebaResponse } from '../../../../hooks';

const { useFormInstance, useWatch } = Form;

type PersonalisedReportSubStepKeyProviderProps = {
	stepId: string;
	fileId?: string;
	children: ReactNode;
	stepKeyFieldName: string[];
	reba?: RebaResponse | null;
	subStepKey: CustomReportSubStepKeyResponse;
	stepKey: PersonalisedReportStepKeysResponse;
};

const PersonalisedReportSubStepKeyContext = createContext<Context>({} as Context);

export const PersonalisedReportSubStepKeyProvider: React.FC<Readonly<PersonalisedReportSubStepKeyProviderProps>> = ({
	reba,
	fileId,
	stepId,
	stepKey,
	children,
	subStepKey,
	stepKeyFieldName
}) => {
	const form = useFormInstance();
	const { company_id, organization_id } = usePersonalisedReportContext();
	const { personalisedReport } = usePersonalisedReportContext();
	const exposureId = useWatch([...stepKeyFieldName, 'exposure_id'], form);
	const consequenceId = useWatch([...stepKeyFieldName, 'consequence_id'], form);
	const probabilityId = useWatch([...stepKeyFieldName, 'probability_id'], form);

	const {
		data: levelRisk,
		isLoading: gettingLevelRisk,
		isFetching: fetchingLevelRisk,
		isError: errorCalculatingRisk
	} = useCalculateCustomReportStepKeyRisk({
		company_id,
		organization_id,
		exposure_id: exposureId,
		step_key_id: subStepKey.id,
		consequence_id: consequenceId,
		probability_id: probabilityId,
		custom_report_score_scale_id: personalisedReport.custom_report_score_scale_id
	});

	const {
		data: actionPlan,
		isFetching: fetchingActionPlan,
		isLoading: gettingActionPlan,
		isError: errorGettingActionPlan
	} = useGetActionPlan({
		exposureId,
		company_id,
		probabilityId,
		consequenceId,
		file_id: fileId,
		organization_id,
		custom_report_sub_step_key_id: subStepKey.id,
		custom_report_result_id: personalisedReport.result_id as string,
		custom_report_step_key_setting_id: stepKey.custom_report_step_key_setting_id
	});

	useEffect(() => {
		const actionPlanFieldName = [...stepKeyFieldName, 'action_plans'];
		form.setFieldValue([...actionPlanFieldName, 'deadline'], moment(actionPlan?.deadline));
		form.setFieldValue([...actionPlanFieldName, 'description'], actionPlan?.description);
		form.setFieldValue([...actionPlanFieldName, 'title'], actionPlan?.title);
		form.setFieldValue([...actionPlanFieldName, 'responsible_user_id'], actionPlan?.responsible_user_id);
	}, [actionPlan]);

	if (levelRisk) {
		form.setFieldValue([...stepKeyFieldName, 'score'], levelRisk.score);
		form.setFieldValue([...stepKeyFieldName, 'result'], levelRisk.result);
	}

	function mapStepKeyDropdownOptions(options: DropdownOptions): AntdDropdownOption[] {
		return options.map(({ id, description }) => ({
			value: id,
			label: I18n.get(description)
		}));
	}

	const actionPlans = {
		errorGettingActionPlan,
		gettingActionPlan: gettingActionPlan || fetchingActionPlan,
		actionPlan: errorGettingActionPlan ? undefined : actionPlan
	};

	const states: States = {
		reba,
		fileId,
		stepId,
		stepKey,
		stepKeyFieldName,
		stepKeyId: stepKey.id,
		subStepKeyId: subStepKey.id,
		actionPlanData: actionPlans,
		hasInjury: stepKey.has_injury,
		subStepKeyName: subStepKey.name,
		injuries: subStepKey.sub_step_key_injuries,
		stepKeySettingId: stepKey.custom_report_step_key_setting_id,
		riskLevelData: {
			levelRisk,
			errorCalculatingRisk,
			gettingRisk: gettingLevelRisk || fetchingLevelRisk
		}
	};

	const methods: Methods = {
		mapStepKeyDropdownOptions
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<PersonalisedReportSubStepKeyContext.Provider value={context}>
			{children}
		</PersonalisedReportSubStepKeyContext.Provider>
	);
};

export function usePersonalisedReportSubStepKeyContext() {
	const context = useContext(PersonalisedReportSubStepKeyContext);
	return context;
}
