import { ResetPasswordRequest } from '@/types/AuthUser';
import { AuthService } from '@/services/authService';
import { useMutation } from '@tanstack/react-query';

const authService = new AuthService();

function useResetPassword() {
	return useMutation((payload: ResetPasswordRequest) => authService.resetPassword(payload));
}

export { useResetPassword };
