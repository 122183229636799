import styled from 'styled-components';

export const Details = styled.div`
	padding: 0.5rem 1rem;
`;

export const DetailTitle = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
`;

export const DetailDescription = styled.span`
	font-weight: 400;
	font-size: 0.8rem;
`;
