import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Api from '@/services/api';
import { BaseContext } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

export type UpdateFilesHierarchyResponse = {
	file_ids: string[];
};

export type UpdateFilesHierarchyRequest = BaseContext & {
	sector_id: string;
	cellule_id: string;
	file_ids?: string[];
	workstation_id: string;
};

async function updateFilesHierarchy(body: UpdateFilesHierarchyRequest): Promise<UpdateFilesHierarchyResponse> {
	const url = '/file/hierarchy';
	const { data } = await Api.put<UpdateFilesHierarchyResponse>(url, body);
	return data;
}

export const useBulkUpdateFilesHierarchy = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateFilesHierarchyRequest) => updateFilesHierarchy(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILES_LIST]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILE_HIERARCHY]);
			message.success(I18n.get('Files updated successfully'));
		}
	});
};
