import React, { useContext } from 'react';
import { Button, Col, message, Row } from 'antd';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { PayloadDownloadPDF, URLDownloadDTO } from './types';
import { ResultsContext } from '../context';
import { queryClient } from '@/store/query';
import { Hooks } from './hooks';

const { useDownloadPDF } = Hooks;
interface IDownloadPDFProps {
	parameters: PayloadDownloadPDF;
}

export const DownloadPDF: React.FC<IDownloadPDFProps> = ({ parameters }) => {
	const pdf = useDownloadPDF();
	const { hasTransaction } = useContext(ResultsContext);
	const { consolidate, preliminaryAnalysis, consolidateClick, setConsolidateClick } = usePreliminaryAnalysis();

	const onMessageError = () => message.error(I18n.get('Failed to generate the report'));

	function onDownload(payload: PayloadDownloadPDF): void {
		pdf.mutate(payload, {
			onSuccess: (data: URLDownloadDTO) => {
				let archive = document.createElement('a');
				archive.target = '_blank';
				archive.href = data.url;
				archive.click();
				archive.remove();
			},
			onError: () => {
				onMessageError();
			}
		});
	}

	function onConsolidate(payload: PayloadDownloadPDF): void {
		setConsolidateClick(true);
		const alert_message = payload.status
			? 'Successfully consolidated analysis'
			: 'Successfully deconsolidated analysis';

		consolidate.mutate(payload, {
			onSuccess: () => {
				message.success(I18n.get(alert_message));
				queryClient.invalidateQueries(['preliminary-analysis-results']);
				setConsolidateClick(false);
			},
			onError: () => {
				onMessageError();
			}
		});
	}

	return (
		<Row justify={'end'}>
			<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					style={{
						color: '#2F54EB',
						borderRadius: '0.25rem',
						border: '1px solid #2F54EB'
					}}
					loading={pdf.isLoading}
					disabled={pdf.isLoading || preliminaryAnalysis.isFetching || hasTransaction || consolidateClick}
					onClick={() => onDownload(parameters)}
					icon={<Icons.DownloadOutlined style={{ color: '#2F54EB' }} />}
				>
					{I18n.get('Download PDF')}
				</Button>
			</Col>
			<Col xl={6} xxl={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
				{!preliminaryAnalysis.data?.consolidated ? (
					<Button
						style={{
							color: '#2cc852',
							borderRadius: '0.25rem',
							border: '1px solid #2cc852'
						}}
						loading={preliminaryAnalysis.isFetching}
						disabled={preliminaryAnalysis.isFetching || hasTransaction || consolidateClick}
						onClick={() => onConsolidate({ ...parameters, status: true })}
						icon={<Icons.LockOutlined style={{ color: '#2cc852' }} />}
					>
						{I18n.get('Consolidate analysis')}
					</Button>
				) : (
					<Button
						style={{
							color: '#828282',
							borderRadius: '0.25rem',
							border: '1px solid #828282'
						}}
						loading={preliminaryAnalysis.isFetching}
						disabled={preliminaryAnalysis.isFetching || consolidateClick}
						onClick={() => onConsolidate({ ...parameters, status: false })}
						icon={<Icons.UnlockOutlined style={{ color: '#828282' }} />}
					>
						{I18n.get('Deconsolidate')}
					</Button>
				)}
			</Col>
		</Row>
	);
};
