import React from 'react';
import { Col, Space } from 'antd';
import * as Typography from '@/components/Typography';

export const Title: React.FC = () => (
	<Col span={24}>
		<Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
			<Typography.Title>Oops!</Typography.Title>
		</Space>
	</Col>
);
