import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, DatePicker, Form, Row } from 'antd';

export const CreatedAt: React.FC = () => (
	<Col sm={6}>
		<Row>
			<Col xs={24}>
				<Form.Item name={['created_at']} label={I18n.get('Created date')} labelCol={{ span: 24 }}>
					<DatePicker.RangePicker
						style={{ width: '100%' }}
						format="L"
						disabledDate={(current) => current && current.valueOf() >= Date.now()}
					/>
				</Form.Item>
			</Col>
		</Row>
	</Col>
);
