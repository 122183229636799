import React from 'react';
import { I18n } from '@aws-amplify/core';
import { CustomFormItem, SubTitle, TextInput } from '../styles';

interface PrimaryFieldFieldProps {
	name: string;
	title: string;
	required?: boolean;
	errorMessage: string;
}

export function PrimaryField({ title, name, errorMessage, required = false }: Readonly<PrimaryFieldFieldProps>) {
	return (
		<CustomFormItem
			name={name}
			labelCol={{ span: 24 }}
			rules={[{ required, message: errorMessage }]}
			label={<SubTitle>{I18n.get(title)}</SubTitle>}
		>
			<TextInput placeholder={I18n.get(title)} />
		</CustomFormItem>
	);
}
