import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalBorder = styled(Modal)<{ $borderSize?: string }>`
	.ant-modal-content {
		border-radius: ${({ $borderSize }) => $borderSize ?? '15px'};
	}

	.ant-modal-close-x {
		font-size: 0.8rem;
	}
`;
