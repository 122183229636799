import { Button, Form, Input, InputNumber, Modal, Radio, Select, Col, Row } from 'antd';
import styled from 'styled-components';

const { Item } = Form;

export const Container = styled(Row)`
	display: flex;
	flex-direction: column;
`;

export const ContentWrapper = styled.div`
	flex-grow: 1;
	height: 100%;
	min-height: 450px;
`;

export const FooterWrapper = styled.div`
	flex-shrink: 0;
	margin-top: auto;
	padding-top: 5%;
`;

export const CustomButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	font-size: 1rem;
	line-height: 22px;
	padding: 0.8rem 1.2rem;
	width: 85px;
	height: 32px;
`;

export const CustomModal = styled(Modal)`
	.ant-modal-close-x {
		font-size: 11px;
		line-height: 4.2rem;
		margin-right: 0.8rem;
	}

	.ant-modal-content {
		min-height: 35rem;
		align-items: center;
		border-radius: 1.5rem;
	}
`;

export const Title = styled.span`
	font-size: 1.6rem;
	font-weight: 600;
	color: #262626;
`;

export const SubTitle = styled.span`
	font-size: 1.2rem;
	font-weight: 600;
	color: #262626;
`;

export const CustomFormItem = styled(Item)`
	margin-bottom: 0px;

	label {
		font-size: 1rem;
		line-height: 1.2rem;
	}

	.ant-form-item-label {
		padding: 0;
	}

	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		position: absolute;
		right: -12px;
	}

	.ant-radio-button-wrapper:last-child {
		border-radius: 6px;
		margin-left: -7px;
	}

	.ant-radio-button-wrapper:first-child {
		border-radius: 6px;
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		display: none;
	}

	.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
		box-shadow: none;
	}
`;

export const SwitchFormItem = styled(CustomFormItem)`
	.ant-form-item-explain-error {
		position: fixed;
		margin-top: 10px;
	}
`;

export const TextInput = styled(Input)`
	font-size: 1rem;
	padding: 4px 12px;
	border-radius: 6px;
	border: solid 1px #e6e6e6;
`;

export const CustomInputNumber = styled(InputNumber)`
	font-size: 1rem;
	padding: 2px 0px;
	border-radius: 5px;
	border: solid 1px #e6e6e6;
`;

export const CustomSelect = styled(Select)`
	font-size: 1rem;
	border: solid 1.5px #ffffff;

	.ant-select-selector {
		padding: 2px 11px !important;
		border-radius: 6px !important;
		height: max-content !important;
	}
`;

export const RadioGroup = styled(Radio.Group)`
	display: flex;
	border-radius: 8px !important;

	label {
		display: flex;
		font-size: 1rem;
		align-items: center;
	}
`;

export const RadioButton = styled(Radio.Button)`
	padding: 1rem;
`;

export const StyledButtonAction = styled.div`
	.ant-btn-background-ghost {
		margin-top: 2px;
		border: none !important;
		box-shadow: none !important;
	}
`;

export const StyledCancelConfirm = styled.div`
	.ant-btn-background-ghost {
		margin-top: 2px;
		border: none !important;
		box-shadow: none !important;
	}
`;

export const Confirm = styled(Col)`
	.ant-btn:not([disabled]) {
		border-color: #2cc852;
		.anticon {
			color: #2cc852;
		}
	}
`;

export const FlexCol = styled(Col)`
	display: flex;
	#company_maximum_time_help {
		display: none;
	}
	#company_maximum_videos_help {
		position: fixed;
	}
	#maximum_time_help {
		position: fixed;
		display: none;
	}
	#maximum_videos_help {
		position: fixed;
	}
`;
