import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Context, Methods, States } from './types';
import { PersonalisedReportResultResponse } from '../hooks';

type PersonalisedReportResultProviderProps = {
	children: ReactNode;
	personalisedReportResult: PersonalisedReportResultResponse;
};

const PersonalisedReportResultContext = createContext<Context>({} as Context);

export const PersonalisedReportResultProvider: React.FC<Readonly<PersonalisedReportResultProviderProps>> = ({
	children,
	personalisedReportResult
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const browserLanguage = window.navigator.language ?? 'en-US';

	function handleLoadingStatus(loading: boolean): void {
		setIsLoading(loading);
	}

	const states: States = {
		isLoading,
		browserLanguage,
		personalisedReportResult
	};

	const methods: Methods = {
		handleLoadingStatus
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<PersonalisedReportResultContext.Provider value={context}>{children}</PersonalisedReportResultContext.Provider>
	);
};

export function usePersonalisedReportResultContext() {
	const context = useContext(PersonalisedReportResultContext);
	return context;
}
