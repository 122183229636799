import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { TableTitle } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

export const Particularities: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { characteristics } = personalisedReportResult;
	
	if (!characteristics) {
		return null;
	}

	return (
		<Row style={{ height: '100%' }}>
			<Col xl={10} xxl={8} style={{ padding: '1rem', background: '#e6e8e8' }}>
				<TableTitle>
					{I18n.get('Particularities')} / {I18n.get('Production')} / {I18n.get('Working regime')}
				</TableTitle>
			</Col>
			<Col xl={14} xxl={16} style={{ padding: '1rem', position: 'relative', borderBottom: '1px solid #e6e8e8' }}>
				<span>
					{characteristics.particularities_description ? characteristics.particularities_description : 'N/A'}
				</span>
			</Col>
			<Col xl={10} xxl={8} style={{ padding: '1rem', background: '#e6e8e8' }}>
				<TableTitle>{I18n.get("Worker's verbalization")}</TableTitle>
			</Col>
			<Col xl={14} xxl={16} style={{ padding: '1rem' }}>
				<span>
					{characteristics.worker_verbalization_description
						? characteristics.worker_verbalization_description
						: 'N/A'}
				</span>
			</Col>
		</Row>
	);
};
