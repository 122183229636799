import { InfiniteData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DashboardService } from './service';
import {
	DashboardActionPlansCardsDTO,
	DashboardActionPlansStatusDTO,
	DashboardNioshDTO,
	DashboardQueryParams,
	DashboardRebaReportsDTO,
	DashboardStrainIndexDTO,
	DashboardSummaryDTO,
	DashboardWorkOganizationDTO,
	DashboardWorkstation,
	SectorDTO
} from './types';

const dashboardService = new DashboardService();

function useDashboardSummary(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
	const summary = useQuery(['dashboard_summary', queryParams], () =>
		dashboardService.fetchSummary(organizationId, companyId, queryParams)
	);
	return {
		...summary,
		data: summary.data || ({} as DashboardSummaryDTO)
	};
}

function useDashboardNiosh(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
	const summary = useQuery(['dashboard_niosh', queryParams], () =>
		dashboardService.fetchNioshReports(organizationId, companyId, queryParams)
	);
	return {
		...summary,
		data: summary.data || ({} as DashboardNioshDTO)
	};
}

function useDashboardStrainIndex(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
	const summary = useQuery(['dashboard_rsi', queryParams], () =>
		dashboardService.fetchStrainIndexReports(organizationId, companyId, queryParams)
	);
	return {
		...summary,
		data: summary.data || ({} as DashboardStrainIndexDTO)
	};
}

function useDashboardWorkOganization(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
	const summary = useQuery(['dashboard_work_organization', queryParams], () =>
		dashboardService.fetchWorkOganizationReports(organizationId, companyId, queryParams)
	);
	return {
		...summary,
		data: summary.data || ({} as DashboardWorkOganizationDTO)
	};
}

function useDashboardBodyParts(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
	const summary = useQuery(['dashboard_body_parts', queryParams], () =>
		dashboardService.fetchRebaReports(organizationId, companyId, queryParams)
	);
	return {
		...summary,
		data: summary.data || ([] as DashboardRebaReportsDTO[])
	};
}

function useDashboardWorkstations(
	organizationId: string,
	companyId: string,
	queryParams: DashboardQueryParams,
	offset = 0,
	limit = 10
) {
	const summary = useInfiniteQuery(
		['dashboard_workstations', queryParams],
		async ({ pageParam = 0 }) => {
			return dashboardService.fetchWorkstationsScores(organizationId, companyId, queryParams, pageParam, limit);
		},
		{
			getNextPageParam: (lastPage) => {
				const nextPage = offset + 1;
				return nextPage * limit > lastPage.length ? undefined : nextPage;
			}
		}
	);
	return {
		...summary,
		data: summary.data || ({} as InfiniteData<DashboardWorkstation[]>)
	};
}

function useDashboardActionPlansStatus(organizationId: string, companyId: string, queryParams: DashboardQueryParams) {
	const summary = useQuery(['dashboard_action_plans_status', queryParams], () =>
		dashboardService.fetchActionPlansStatus(organizationId, companyId, queryParams)
	);
	return {
		...summary,
		data: summary.data || ({} as DashboardActionPlansStatusDTO)
	};
}

function useDashboardActionPlansCards(
	organizationId: string,
	companyId: string,
	queryParams: DashboardQueryParams,
	offset = 0,
	limit = 10
) {
	const summary = useInfiniteQuery(
		['dashboard_action_plans_cards', queryParams],
		async ({ pageParam = 0 }) => {
			return dashboardService.fetchActionPlansCards(organizationId, companyId, queryParams, pageParam, limit);
		},
		{
			getNextPageParam: (lastPage) => {
				const nextPage = offset + 1;
				return nextPage * limit > lastPage.total_plans ? undefined : nextPage;
			}
		}
	);
	return {
		...summary,
		data: summary.data || ({} as InfiniteData<DashboardActionPlansCardsDTO>)
	};
}

function useGetSectors(organizationId: string, companyId: string) {
	const sectors = useQuery<SectorDTO[]>({
		queryKey: ['sectors_list', [organizationId, companyId]],
		queryFn: () => dashboardService.fetchSectors(organizationId, companyId),
		enabled: !!organizationId && !!companyId,
		retry: 0
	});
	return {
		...sectors,
		data: sectors.data ?? []
	};
}

const Hooks = {
	useDashboardSummary,
	useDashboardNiosh,
	useDashboardStrainIndex,
	useDashboardWorkOganization,
	useDashboardBodyParts,
	useDashboardWorkstations,
	useDashboardActionPlansStatus,
	useDashboardActionPlansCards,
	useGetSectors
};

export default Hooks;
