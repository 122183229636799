import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Button, type ModalProps } from 'antd';

import { useBulkUpdateFilesHierarchy } from '@/hooks/useBulkUpdateFilesHierarchy';
import { useGetFileHierarchy } from '@/hooks/useGetFileHierarchy';
import { useApplicationContext } from '@/context/Application';
import { CustomModal } from '@/components/CustomModal';
import { LoadingSkeleton } from './LoadingSkeleton';
import { Title } from '@/components/Typography';
import { Organizations } from './Organizations';
import { Workstations } from './Workstations';
import { Companies } from './Companies';
import { Cellules } from './Cellules';
import { Sectors } from './Sectors';

type FormFields = {
	sector_id: string;
	cellule_id: string;
	company_id: string;
	workstation_id: string;
	organization_id: string;
};

interface EditFileModalProps extends ModalProps {
	fileId?: string;
	filesIds?: string[];
}

const { useForm, useWatch } = Form;

export function EditFileModal({ fileId, filesIds, onCancel, ...props }: Readonly<EditFileModalProps>) {
	const [form] = useForm();
	const { organization, company } = useApplicationContext();

	const workstation_id = useWatch('workstation_id', form);

	const { data: file, isFetching } = useGetFileHierarchy({
		file_id: fileId,
		company_id: company?.id,
		organization_id: organization?.id
	});

	const { mutateAsync: updateHierarchy, isLoading } = useBulkUpdateFilesHierarchy();

	async function handleSaveClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		const values: FormFields = await form.validateFields();
		await updateHierarchy({
			...values,
			file_ids: fileId ? [fileId] : filesIds
		});
		if (onCancel) {
			onCancel(event);
		}
	}

	return (
		<CustomModal onCancel={onCancel} {...props}>
			<Row gutter={[24, 24]}>
				<Col xs={24}>
					<Title level={3}>{filesIds ? 'Edit files' : 'Edit file'}</Title>
				</Col>
				<Col xs={24}>
					{isFetching ? (
						<LoadingSkeleton />
					) : (
						<Form form={form} layout="vertical" initialValues={file}>
							<Row gutter={[16, 16]} justify="center">
								<Col xs={24}>
									<Organizations />
								</Col>
								<Col xs={24}>
									<Companies />
								</Col>
								<Col xs={24}>
									<Sectors />
								</Col>
								<Col xs={24}>
									<Cellules />
								</Col>
								<Col xs={24}>
									<Workstations />
								</Col>
								<Col>
									<Row>
										<Col>
											<Button
												onClick={onCancel}
												disabled={isLoading}
												style={{ marginTop: '1rem' }}
											>
												{I18n.get('Cancel')}
											</Button>
										</Col>
										<Col>
											<Button
												type="primary"
												htmlType="submit"
												onClick={handleSaveClick}
												loading={isFetching || isLoading}
												style={{ margin: '1rem 0 0 0.5rem' }}
												disabled={isLoading || !workstation_id}
											>
												{I18n.get('Save')}
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					)}
				</Col>
			</Row>
		</CustomModal>
	);
}
