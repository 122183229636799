import React, { createContext, ReactNode, useContext } from 'react';

import { Context, Methods, States } from './types';
import { PersonalisedReportResultStepKeyResponse } from '../../../../hooks';

type PersonalisedReportStepKeyResultProviderProps = {
	children: ReactNode;
	stepKey: PersonalisedReportResultStepKeyResponse;
};

const PersonalisedReportStepKeyResultContext = createContext<Context>({} as Context);

export function PersonalisedReportStepKeyResultProvider({
	stepKey,
	children
}: Readonly<PersonalisedReportStepKeyResultProviderProps>) {
	const states: States = {
		stepKey
	};

	const methods: Methods = {};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<PersonalisedReportStepKeyResultContext.Provider value={context}>
			{children}
		</PersonalisedReportStepKeyResultContext.Provider>
	);
}

export function usePersonalisedReportStepKeyResultContext() {
	const context = useContext(PersonalisedReportStepKeyResultContext);
	return context;
}
