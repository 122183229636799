import { Col, Form, Input } from 'antd';
import styled from 'styled-components';

const { Item } = Form;
const { Password } = Input;

export const Container = styled(Col)`
	border-radius: 30px;
	padding: 2rem 4rem;
	box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.05), 3px 3px 4px 0px rgba(0, 0, 0, 0.05);
	background-color: #fff;

	button {
		width: 100%;
	}
`;

export const IconContainer = styled(Col)`
	font-size: 4rem;
	color: #2f54eb;
`;

export const CustomFormItem = styled(Item)`
	margin-bottom: 1rem;

	.ant-form-item-explain-error {
		font-size: 0.8rem;
		margin-bottom: 0.4rem;
	}
`;

export const CustomInputPassword = styled(Password)`
	padding: 2% 4% 2% 2%;
	border-radius: 3px;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.65);

	.site-form-item-icon {
		margin-right: 0.5rem;
	}
`;
