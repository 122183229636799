import { Button, Col } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled.div`
	&:hover {
		span {
			color: white;
		}
	}
`;

export const CreateButton = styled(Button)`
	float: right;
	width: max-content;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.5rem;
	span {
		font-weight: 600;
	}
`;

export const EmptyCol = styled(Col)`
	border-radius: 0.5rem;
	background-color: #ffffff;
`;
