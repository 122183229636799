import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import Title from 'antd/lib/typography/Title';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../context';
import { OrganizationDTO } from '../types';
import { Manage } from './Manage';

const { useForm } = Form;

type InitialValues = {
	company_name: string;
	sector_name: string;
	workstation_name: string;
};

type ManageOrganizationModalProps = {
	organization: OrganizationDTO;
	isModalOpen: boolean;
	onModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ManageOrganizationModal: React.FC<ManageOrganizationModalProps> = ({
	organization,
	isModalOpen,
	onModalOpen
}) => {
	const [form] = useForm();
	const { handleCompanyId, handleSectorId, handleWorkstationId, handleIsEditing } = useManageOrganization();

	const initialValues: InitialValues = {
		company_name: '',
		sector_name: '',
		workstation_name: ''
	};

	useEffect(() => {
		handleCompanyId('');
		handleSectorId('');
		handleWorkstationId('');
	}, []);

	function handleModalClose() {
		form.resetFields();
		handleIsEditing(false);
		onModalOpen(!isModalOpen);
	}

	return (
		<Form form={form} initialValues={initialValues} layout="vertical" style={{ minHeight: '600px' }}>
			<Modal
				style={{ top: '2rem' }}
				bodyStyle={{
					display: 'flex',
					height: '450px',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
				open={isModalOpen}
				onCancel={() => handleModalClose()}
				footer={null}
			>
				<Row justify="center" gutter={[24, 24]}>
					<Col span={24}>
						<Row justify="start" align="middle">
							<Col span="auto">
								<Title style={{ fontWeight: '100' }} level={3}>
									{I18n.get(organization.name)}
								</Title>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Manage />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Row justify="end">
							<Col span="auto">
								<Button type="default" htmlType="submit" onClick={() => handleModalClose()}>
									{I18n.get('Close')}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Modal>
		</Form>
	);
};
