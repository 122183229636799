import React from 'react';
import { Col, Row } from 'antd';

import { StepCard } from './StepCard';
import { Title } from '@/components/Typography';
import { useCustomReportManagerContext } from '../../../context';
import { CustomCol } from './styles';

export const SectionsAndCriteria: React.FC = () => {
	const { selectedSteps } = useCustomReportManagerContext();

	return (
		<Row justify="center">
			<CustomCol xs={24}>
				<Title level={4}>Sections and criteria</Title>
			</CustomCol>
			<Col xs={23}>
				<Row gutter={[16, 16]}>
					{selectedSteps.map((step) => (
						<StepCard key={step.id} step={step} />
					))}
				</Row>
			</Col>
		</Row>
	);
};
