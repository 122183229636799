import React from 'react';
import { Row } from 'antd';
import { Card } from '../../Card';
import { Description } from './Description';
import { ScoreScaleSelect } from './ScoreScaleSelect';
import { ExtendedDescription } from './ExtendedDescription';

export function GeneralInformations() {
	return (
		<Card title="General informations">
			<Row gutter={[0, 18]}>
				<Description />
				<ScoreScaleSelect />
				<ExtendedDescription />
			</Row>
		</Card>
	);
}
