import React from 'react';
import { Col, Row, Form, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Text } from '@/components/Typography';

import { ControlPeriod, MaxMinutesUsed, MaxVideosNumber, RenewalDay, RenewalMonth } from '../../../components';
import { SubTitle, FlexCol } from '../../../styles';

const { useWatch } = Form;

export function ProcessingControl() {
	const maximum_time = useWatch('maximum_time');
	const maximum_videos = useWatch('maximum_videos');
	const recurrence = useWatch('recurrence');

	function hasSomeSwitchChecked() {
		return maximum_time || maximum_videos;
	}

	function switchValidator() {
		if (!maximum_time && !maximum_videos) {
			return Promise.reject(I18n.get('Select a value'));
		}

		return Promise.resolve();
	}

	return (
		<Row gutter={[0, 20]}>
			<Col span={24}>
				<SubTitle>{I18n.get('Processing control')}</SubTitle>
			</Col>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Row>
							<FlexCol span={24}>
								<Form.Item
									name="maximum_time"
									valuePropName="checked"
									rules={[{ validator: switchValidator }]}
								>
									<Switch />
								</Form.Item>
								<Text style={{ fontSize: 15, marginTop: '7px', marginLeft: '5px' }}>
									{I18n.get('Set the maximum processing time')}
								</Text>
							</FlexCol>
						</Row>
					</Col>
					<Col span={24}>
						<Row>
							<FlexCol span={24}>
								<Form.Item
									name="maximum_videos"
									valuePropName="checked"
									rules={[{ validator: switchValidator }]}
								>
									<Switch />
								</Form.Item>
								<Text style={{ fontSize: 15, marginTop: '7px', marginLeft: '5px' }}>
									{I18n.get('Set a maximum number of videos to process')}
								</Text>
							</FlexCol>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 15]}>
					{hasSomeSwitchChecked() && (
						<>
							<Col span={recurrence === 'yearly' ? 24 : 11}>
								<ControlPeriod name="recurrence" required />
							</Col>
							{recurrence === 'yearly' ? (
								<>
									<Col span={12}>
										<RenewalDay name="renewal_day" required />
									</Col>
									<Col span={12}>
										<RenewalMonth name="renewal_month" required />
									</Col>
								</>
							) : (
								<Col span={recurrence === 'yearly' ? 6 : 12}>
									<RenewalDay name="renewal_day" required />
								</Col>
							)}
						</>
					)}
					{maximum_time && (
						<Col span={24}>
							<MaxMinutesUsed name="max_minutes" required />
						</Col>
					)}
					{maximum_videos && (
						<Col span={24}>
							<MaxVideosNumber name="max_uploads" required />
						</Col>
					)}
				</Row>
			</Col>
		</Row>
	);
}
