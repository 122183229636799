import React from 'react';
import { Col, Divider, Row } from 'antd';

import { Title } from '@/components/Typography';

import { Body } from './Body';
import { Header } from './Header';
import { Container } from './styles';
import { INiosh, IConclusion, IRecommended } from './types';

interface NioshProps {
	data: INiosh;
}

export const Niosh: React.FC<NioshProps> = ({ data }) => {
	const conclusion: IConclusion = {
		risk: data.risk,
		mass_m: data.mass_m,
		lifting_index: data.lifting_index,
		recommended_weight_limit: data.recommended_weight_limit
	};

	const recommended: IRecommended = {
		risk: data.risk,
		lifting_index: data.lifting_index,
		reference_weight: data.reference_weight,
		recommended_weight_limit: data.recommended_weight_limit
	};

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={3}>
						Cargo Handling (NIOSH)
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row>
					<Container>
						<Header conclusion={conclusion} />
						<Divider style={{ margin: 0 }} />
						<Body recommended={recommended} />
					</Container>
				</Row>
			</Col>
		</Row>
	);
};
