import React from 'react';
import moment from 'moment';
import { Col, Row, Tooltip } from 'antd';

import { Actions } from '../Actions';
import { PersonalisedReportSVG } from '@/components/Icons/PersonalisedReportSVG';
import { CardContainer, Subtitle, SubtitleRow, TextContent, Title, TitleCol } from './styles';

interface CardProps {
	name: string;
	updated_at: Date;
	report_id: string;
	description: string;
	extended_description: string;
}

export const Card: React.FC<CardProps> = ({ report_id, description, extended_description, name, updated_at }) => {
	const fullDescription = !extended_description ? 'N/A' : extended_description;
	return (
		<CardContainer>
			<Actions report_id={report_id} />
			<Row justify="center" align="middle" gutter={[16, 16]}>
				<TitleCol id="not-hovered" xs={24}>
					<Row justify="center">
						<PersonalisedReportSVG height={55} width={55} />
					</Row>
				</TitleCol>
				<TitleCol xs={24}>
					<Title>{description}</Title>
				</TitleCol>
			</Row>
			<Row>
				<Col xs={24}>
					<SubtitleRow align="middle">
						<Subtitle>Created by</Subtitle>:<TextContent>{name}</TextContent>
					</SubtitleRow>
					<SubtitleRow align="middle">
						<Subtitle>Date of creation</Subtitle>:
						<TextContent>{moment(updated_at).format('L')}</TextContent>
					</SubtitleRow>
					<SubtitleRow align="middle">
						<Col xs={24}>
							<Subtitle>Description</Subtitle>:
						</Col>
						<Col xs={24}>
							<Tooltip title={fullDescription}>
								<TextContent style={{ marginLeft: 0 }}>{fullDescription}</TextContent>
							</Tooltip>
						</Col>
					</SubtitleRow>
				</Col>
			</Row>
		</CardContainer>
	);
};
