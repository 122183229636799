import { I18n } from '@aws-amplify/core';
import React, { useState } from 'react';
import { Row, Switch } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { Spinner } from '@/components/Spinner';

import { useForce2FA, useGetForced2FA } from './hooks';
import { Field, Information } from '../../styles';

export const ForceTwoFA = () => {
	const { data: isForced, isFetched: fetchedForced2FA } = useGetForced2FA();
	const { mutateAsync: force2FA } = useForce2FA();

	const { company, organization } = useApplicationContext();

	const [checked, setChecked] = useState(isForced.force_two_fa);

	async function handleChange(isChecked: boolean) {
		const forced = await force2FA({
			company_id: company.id,
			organization_id: organization.id,
			force_two_fa: isChecked
		});

		if (forced) {
			setChecked(isChecked);
		}
	}

	return !fetchedForced2FA ? (
		<Spinner />
	) : (
		<>
			<Field>{I18n.get('Force two-factor authentication to all users')}</Field>
			<Information>
				{I18n.get('When activated, all users will be forced to activate two-factor authentication on login.')}
			</Information>
			<Row style={{ marginTop: '25px' }}>
				<Switch
					loading={!fetchedForced2FA}
					defaultChecked={!!isForced.force_two_fa}
					checked={checked}
					onChange={(isChecked) => handleChange(isChecked)}
				/>
				<Information style={{ marginLeft: '13px' }}>
					{checked ? I18n.get('Deactivate') : I18n.get('Activate')}
				</Information>
			</Row>
		</>
	);
};
