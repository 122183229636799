import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import {
	Stress,
	StressLevel,
	ChartContainer,
	StressLevelChart,
	StressLevelContainer,
	WorkingPopulationContainer
} from './styles';
import { SelfEvaluations } from '@/types';
import { RiskBar } from '@/components/Dashboard/RiskBar';
import { Title, Paragraph } from '@/components/Typography';
import { usePersonalisedReportResultContext } from '../../../context';
import { Risk, RiskBarOptions } from '@/components/Dashboard/RiskBar/types';
import { ENUM_WORKPOPULATION_SCALE, Gender, WORKING_POPULATION } from './types';

type WorkingPopulation = {
	working_population_men: number;
	total_working_population: number;
	working_population_women: number;
	working_population_others: number;
};

export const ChartsContent: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { characteristics } = personalisedReportResult;

	if (!characteristics) {
		return null;
	}

	const {
		total_working_population,
		working_population_men_percentage,
		working_population_women_percentage,
		working_population_others_percentage,
		worker_self_evaluation
	} = characteristics;

	const workingPopulation: WorkingPopulation = {
		total_working_population,
		working_population_men: working_population_men_percentage,
		working_population_women: working_population_women_percentage,
		working_population_others: working_population_others_percentage
	};

	const stressLevelPercentage: { [key in SelfEvaluations]: number } = {
		nothing_stressful: 20,
		little_stressful: 40,
		stressful: 60,
		very_stressful: 80,
		extremely_stressful: 100
	};

	const stressPercentage = 100 - stressLevelPercentage[worker_self_evaluation.name];

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'right'
		},
		value: {
			align: 'center',
			display: 'bottom'
		},
		orientation: 'vertical'
	};

	function riskMapper(workingPopulation: WorkingPopulation) {
		let id = 0;
		const risks: Risk[] = [];
		for (const worker in workingPopulation) {
			if (worker !== WORKING_POPULATION.TOTAL) {
				const gender = worker as Gender;

				if (workingPopulation[gender] > 0) {
					const mappedWorkingPopulation: Risk = {
						...ENUM_WORKPOPULATION_SCALE[gender],
						id,
						value: workingPopulation[gender] + '%',
						percentage: workingPopulation[gender] + '%'
					};

					risks.push(mappedWorkingPopulation);
					id += 1;
				}
			}
		}

		return risks.sort((a, b) => b.id - a.id);
	}

	return (
		<Row justify="space-around" style={{ height: '100%' }}>
			<Col span={10}>
				<WorkingPopulationContainer>
					<Row justify="center" align="middle">
						<Col xl={24} xxl={16}>
							<Title style={{ margin: 0, textAlign: 'center' }} level={5}>
								{I18n.get('Working Population')}
							</Title>
						</Col>

						<Col span={24}>
							<ChartContainer style={{ marginTop: '1rem' }}>
								<RiskBar
									options={riskBarOptions}
									risks={riskMapper(workingPopulation)}
									total={total_working_population}
								/>
							</ChartContainer>
						</Col>

						<Col span={24} style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}>
							<Paragraph>
								{I18n.get('Total')}: {total_working_population} {I18n.get('workers')}
							</Paragraph>
						</Col>
					</Row>
				</WorkingPopulationContainer>
			</Col>
			<Divider type="vertical" style={{ margin: 0, height: 'auto' }} />
			<Col span={10}>
				<StressLevelContainer>
					<Row justify="center" align="middle" gutter={[0, 12]}>
						<Col xl={20} xxl={14}>
							<Title style={{ margin: 0, textAlign: 'center' }} level={5}>
								{I18n.get('Stress Level')}
							</Title>
						</Col>

						<Col span={24}>
							<ChartContainer percentage={stressPercentage}>
								<StressLevelChart id="chart" percentage={stressPercentage} />
								<StressLevel id="level" />
							</ChartContainer>
						</Col>

						<Col span={24} style={{ textAlign: 'center' }}>
							<Stress>{I18n.get(worker_self_evaluation.description)}</Stress>
						</Col>
					</Row>
				</StressLevelContainer>
			</Col>
		</Row>
	);
};
