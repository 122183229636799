import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import { Title } from '@/components/Typography';

import { Particularities } from './Particularities';
import { ChartsContent } from './ChartsContent';
import { Container } from './styles';

import { ResultsContext } from '../context';

export const Characters = () => {
	const { characteristics } = useContext(ResultsContext);
	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title level={3} style={{ margin: 0 }}>
						Characteristics
					</Title>
				</Row>
			</Col>
			<Col offset={1} flex={1}>
				<Row gutter={[30, 0]}>
					<Col xl={13} xxl={14}>
						<Container>
							<Particularities
								particularitiesText={characteristics?.particularities}
								workerVerbalizationText={characteristics?.worker_verbalization}
							/>
						</Container>
					</Col>
					<Col xl={11} xxl={10}>
						<Container>
							<ChartsContent
								workingPopulation={characteristics?.working_population}
								stresslevel={characteristics?.stress_level}
							/>
						</Container>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
