import React from 'react';
import { Col, Row } from 'antd';

import { Title } from '@/components/Typography';
import { usePersonalisedReportContext } from '../context';

export const Header: React.FC = () => {
	const {personalisedReport} = usePersonalisedReportContext()
	return (
		<Row justify="center">
			<Col>
				<Title level={2}>{personalisedReport.report_name}</Title>
			</Col>
		</Row>
	);
};
