import React from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Title } from './Title';
import { Description } from './styles';
import { ApplyTool } from '../ApplyTool';
import { Text } from '@/components/Typography';
import { ITasksProps, ToolType } from './types';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSearchParams } from '@/hooks';
import { BaseContext } from '@/types';

export const ToolCard: React.FC<ITasksProps> = ({
	subtitle,
	title = '',
	fileId = '',
	isActive = false,
	reportSelected = '' as ToolType,
	onOperationClick
}: ITasksProps) => {
	const [, setName] = useLocalStorage(reportSelected, true);
	const description: string = isActive ? 'Tool already set' : 'Tool not set yet';
	const { company_id, organization_id } = useSearchParams<BaseContext>();
	const handleOperation = async (toolName: ToolType): Promise<void> => {
		const reportsInFullView: ToolType[] = ['niosh', 'strain-index', 'reba', 'kim-mho', 'kim-pp', 'liberty-mutual'];

		let url: string = `/reporting`;

		if (reportsInFullView.includes(toolName)) {
			if (!fileId && onOperationClick) {
				return onOperationClick(toolName);
			}
			url = `/reporting/${toolName}/${fileId ?? ''}`;
		}

		if (company_id && organization_id) {
			url = `${url}?company_id=${company_id}&organization_id=${organization_id}`;
		}

		window.open(url, '_blank');
	};

	const addSettings = (toolName: ToolType): void => {
		handleOperation(toolName);
		setName(true);
	};

	return (
		<Col span={20}>
			<Title isActive={isActive} />
			<ApplyTool type="toTop" onClick={() => addSettings(reportSelected)} isActive={isActive}>
				<Text>Create/update tool</Text>
				<Text type="secondary">{title}</Text>
				{subtitle ? <Text type="secondary">{subtitle}</Text> : <></>}
			</ApplyTool>
			<Description isactive={isActive}>{I18n.get(description)}</Description>
		</Col>
	);
};
