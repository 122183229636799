import { Image } from 'antd';
import styled from 'styled-components';

export const Img = styled(Image)`
	padding-bottom: 20px;
	padding-left: 25px;
	margin-top: 15px;
	height: auto;
	width: 80%;
`;
