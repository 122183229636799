import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomModal } from '@/components/ui/CustomModal';
import { Title } from '@/components/Typography';

import { ConfirmButton, Description } from './styles';
import { useTFAContext } from './context';

export const ConfirmPasswordModal = () => {
	const [password, setPassword] = useState('');

	const { openGenerateTokenModal, openActionModal, openDeactivateTokenModal } = useTFAContext();
	const {
		handleConfirmPassword,
		handleOpenActionModal,
		handleOpenGenerateTokenModal,
		handleCorrectPassword,
		handleOpenDeactivateTokenModal
	} = useTFAContext();

	function handlePasswordChange(value: string) {
		setPassword(value);
	}

	function handleClose() {
		handleCorrectPassword(false);
		handleOpenActionModal(false);
		handleOpenGenerateTokenModal(false);
		handleOpenDeactivateTokenModal(false);
	}

	async function handleConfirmPasswordClick() {
		const user_string = localStorage.getItem('info_user') || '';
		const user = JSON.parse(user_string)?.user;
		if (user) {
			await handleConfirmPassword(user.email, password);
		}
	}

	return (
		<CustomModal
			open={openGenerateTokenModal || openActionModal || openDeactivateTokenModal}
			onCancel={handleClose}
			footer={false}
			width={470}
			bodyStyle={{ height: 400 }}
			centered
		>
			<Form layout="vertical" style={{ margin: '50px 0' }}>
				<Row justify="center">
					<Title>Confirm password</Title>
				</Row>

				<Row justify="center">
					<Description>{I18n.get('Confirm your password to proceed with the desired action')}</Description>
				</Row>

				<Col
					style={{
						margin: 0,
						padding: 0,
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<Form.Item
						name="password"
						style={{
							margin: 0,
							width: '75%'
						}}
					>
						<Input.Password
							type="password"
							autoFocus
							placeholder={I18n.get('Password')}
							iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							onChange={(event) => handlePasswordChange(event.target.value)}
							style={{ padding: '0.4rem 0.8rem', width: '100%', marginBottom: '3.5rem' }}
						/>
					</Form.Item>
				</Col>

				<Row justify="center">
					<ConfirmButton
						disabled={password.length === 0}
						onClick={handleConfirmPasswordClick}
						type="primary"
						htmlType="submit"
					>
						{I18n.get('Confirm')}
					</ConfirmButton>
				</Row>
			</Form>
		</CustomModal>
	);
};
