import React, { ReactElement } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { CustomFormInputs } from './styles';
import { RequiredLabel } from './RequiredLabel';

interface ChildrenProps {
	size: SizeType;
	placeholder?: string;
}

interface FormItemProps {
	title: string;
	fontSize?: string;
	isRequired?: boolean;
	errorMessage?: string;
	namePath: string | string[];
	children: ReactElement<ChildrenProps>;
}

export const FormItem: React.FC<FormItemProps> = ({
	title,
	namePath,
	fontSize,
	children,
	errorMessage,
	isRequired = true
}) => (
	<Col xs={24}>
		<CustomFormInputs
			fontSize={fontSize}
			name={[...namePath]}
			label={<RequiredLabel title={I18n.get(title)} isRequired={isRequired} />}
			labelCol={{ span: 24 }}
			rules={[
				{
					required: isRequired,
					message: errorMessage
				}
			]}
		>
			{children}
		</CustomFormInputs>
	</Col>
);
