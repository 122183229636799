import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { Title } from '@/components/Typography';
import { DescriptionTable } from './DescriptionTable';
import { usePersonalisedReportResultContext } from '../../context';
import { WorkConditionResponse } from '../../hooks';

export const WorkConditions: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();

	const work_conditions = personalisedReportResult?.work_conditions;
	if (!work_conditions) {
		return null;
	}

	function workScheduleText(work_conditions: WorkConditionResponse) {
		let message = `${work_conditions.work_hours} ${I18n.get('hours')}`;

		if (work_conditions.work_minutes !== 0) {
			message = `${work_conditions.work_hours} ${I18n.get('hours')} ${I18n.get('and')} ${
				work_conditions.work_minutes
			} ${I18n.get('minutes')}`;
		}

		return message;
	}

	return (
		<Row justify="center">
			<Col offset={1} xs={23}>
				<Row gutter={[0, 24]}>
					<Col span={23}>
						<Row>
							<Title style={{ margin: 0 }} level={3}>
								Work Conditions
							</Title>
						</Row>
					</Col>
					<Col offset={1} span={23}>
						<Row>
							<Col>
								<Title style={{ margin: 0 }} level={4}>
									{I18n.get('Work schedule')}:
								</Title>
							</Col>
							<Col>
								<Title style={{ margin: 0, marginLeft: 5, fontWeight: 500 }} level={4}>
									{workScheduleText(work_conditions)}
								</Title>
							</Col>
						</Row>
					</Col>
					<Col xs={24}>
						<DescriptionTable />
					</Col>
				</Row>
			</Col>
			<Divider type="horizontal" />
		</Row>
	);
};
