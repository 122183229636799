import { Row } from 'antd';
import React from 'react';

import { Background } from '@/components/ui/Background';

import { Reset2FAContent } from './Reset2FAContent';

export const Reset2FA: React.FC = () => {
	return (
		<>
			<Background />
			<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
				<Reset2FAContent />
			</Row>
		</>
	);
};
