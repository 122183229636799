import React from 'react';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { ConditionContainer, Container } from './styles';

const SUCCESS: string = '#2CC852';
const CRITICAL: string = '#E74150';

const conditions = {
	ok: 'OK' as string,
	not_ok: 'NOT OK' as string
};

type Anthropometry = {
	location: string;
	found: number;
	foreseen: number;
	condition: string;
};

type BodyProps = {
	data?: Array<Anthropometry>;
};

export const Body: React.FC<BodyProps> = ({ data }) => {
	const colorResult = (condition: string): string => (condition === 'ok' ? SUCCESS : CRITICAL);
	return (
		<Row>
			<Col span={24}>
				{data?.map((item, index) => {
					return (
						<Row key={index}>
							<Col span={15}>
								<Container>
									<Row>
										<Col span={8} style={{ padding: '0.5rem 0' }}>
											<span>{item.location}</span>
										</Col>
										<Divider type="vertical" style={{ margin: 0, height: 'auto' }} />
										<Col span={8} style={{ padding: '0.5rem 0' }}>
											<span>{item.found}</span>
										</Col>
										<Divider type="vertical" style={{ margin: 0, height: 'auto' }} />
										<Col
											span={7}
											style={{
												marginLeft: '0.8rem',
												padding: '0.5rem 0'
											}}
										>
											<span>{item.foreseen}</span>
										</Col>
									</Row>
								</Container>
							</Col>
							<Col span={5}>
								<Row justify={'center'}>
									<Col>
										<ConditionContainer color={colorResult(item.condition)}>
											{I18n.get(conditions[item.condition as keyof typeof conditions])}
										</ConditionContainer>
									</Col>
								</Row>
							</Col>
						</Row>
					);
				})}
			</Col>
		</Row>
	);
};
