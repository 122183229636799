import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Table, Space, Tooltip } from 'antd';
import Icon, { DeleteOutlined, EditOutlined, PictureOutlined } from '@ant-design/icons';

import { useGetCompanies, type CompanyDTO } from '../hooks/useGetCompanies';
import { ReactComponent as UsersSvg } from './users.svg';
import { Spinner } from '@/components/Spinner';
import type { ColumnsType } from 'antd/lib/table';
import * as S from './styles';

export function ExpandedRowRender({ record, columns, onDelete, onModalCompanyEdit, onModalUser, onModalLogo }: any) {
	const { isLoading, data } = useGetCompanies(record.organization_id);

	if (isLoading) {
		return <Spinner />;
	}

	const columnsRow: ColumnsType<CompanyDTO> = columns.map((column: any) => {
		if (column.key === 'name') {
			return {
				...column,
				render: () => <></>
			};
		}

		// if (column.key === 'used_minutes') {
		// 	return {
		// 		...column,
		// 		render: (item: any) => {
		// 			return (
		// 				<>
		// 					{`${item?.used_minutes} ${I18n.get(
		// 						`in the ${item?.recurrence === 'monthly' ? 'month' : 'year'}`
		// 					)} (${item?.max_minutes})`}
		// 				</>
		// 			);
		// 		}
		// 	};
		// }

		if (column.key === 'companies') {
			return {
				...column,
				title: I18n.get('Corporate Name'),
				dataIndex: 'name',
				render: (name: string) => name
			};
		}

		if (column.key === 'actions') {
			return {
				...column,
				render: (item: CompanyDTO) => (
					<S.ContainerActions>
						<Space size="middle">
							<Tooltip placement="bottomRight" title={I18n.get('Edit company')}>
								<Button
									ghost
									type="primary"
									onClick={() =>
										onModalCompanyEdit({
											name: item.name,
											organization_id: item.organization_id,
											company_id: item.company_id
										})
									}
									icon={<EditOutlined style={{ fontSize: '20px' }} />}
								/>
							</Tooltip>
							<Tooltip placement="bottomRight" title={I18n.get('Manage users')}>
								<Button
									ghost
									type="primary"
									onClick={() =>
										onModalUser({
											name: item.name,
											type: 'company',
											company_id: item.company_id
										})
									}
									icon={
										<Icon
											component={UsersSvg as React.ForwardRefExoticComponent<any>}
											style={{ fontSize: '20px' }}
										/>
									}
								/>
							</Tooltip>
							<Tooltip placement="bottomRight" title={I18n.get('Upload logos')}>
								<Button
									ghost
									type="primary"
									onClick={() =>
										onModalLogo({
											type: 'company',
											organization_id: item.organization_id,
											url_logo: item.url_logo,
											company_id: item.company_id
										})
									}
									icon={<PictureOutlined style={{ fontSize: '20px' }} />}
								/>
							</Tooltip>
							<Tooltip placement="bottomRight" title={I18n.get('Delete industrial site')}>
								<Button
									ghost
									danger
									disabled={!item?.is_active}
									onClick={() =>
										onDelete({
											name: item.name,
											type: 'company',
											organization_id: item.organization_id,
											company_id: item.company_id
										})
									}
									icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
								/>
							</Tooltip>
						</Space>
					</S.ContainerActions>
				)
			};
		}

		return column;
	});

	columnsRow.unshift({
		dataIndex: 'space',
		key: 'space',
		align: 'left',
		width: '3%',
		render: () => <></>
	});

	return (
		<S.Container>
			<Table
				columns={columnsRow}
				showHeader={false}
				dataSource={data}
				pagination={false}
				rowKey="company_id"
				onRow={(e) =>
					e?.is_active
						? {
								style: {
									background: '#fafafa'
								}
						  }
						: {
								style: {
									background: '#D0D2D6'
								}
						  }
				}
			/>
		</S.Container>
	);
}
