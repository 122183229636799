import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Label } from './styles';
import { useGetCellules } from '@/hooks/useGetCellules';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Cellules() {
	const form = useFormInstance();

	const sectorId = useWatch(HierarchiesField.SECTOR, form);
	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);

	const title = I18n.get('Cellule');
	const fieldName = HierarchiesField.CELLULE;

	const {
		data: cellules,
		isFetching: isGettingCellules,
		isError: errorGettingCellules
	} = useGetCellules({
		organization_id: organizationId,
		company_id: companyId,
		sector_id: sectorId
	});

	function handleSelectCellule(id: string): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearCellule(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={fieldName} label={<Label>{title}</Label>}>
			<SelectWithCreation
				showSearch
				allowClear
				placeholder={title}
				selectOptions={cellules}
				loading={isGettingCellules}
				onClear={handleClearCellule}
				onChange={handleSelectCellule}
				disabled={!sectorId || !cellules || errorGettingCellules}
			/>
		</Form.Item>
	);
}
