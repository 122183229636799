import { ReactNode } from 'react';

import { AntdDropdownOption } from '../../../context/types';

export type OptionType = AntdDropdownOption | undefined;

export enum RiskMap {
	LOW = 1,
	MODERATE = 2,
	HIGH = 3,
	VERY_HIGH = 4,
	EXTREME = 5
}

export type PaletterMapper = {
	[x: number]: string;
};

export type RiskMapper = {
	[key: number]: ReactNode;
};
