import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { DetailTitle, Details, Score } from './styles';

interface LiftLowerCarryProps {
	percentile_man: string;
	percentile_woman: string;
}

export const LiftLowerCarry: React.FC<LiftLowerCarryProps> = ({ percentile_man, percentile_woman }) => (
	<Row style={{ height: '100%' }}>
		<Col xs={12} style={{ paddingLeft: '1rem' }}>
			<Details>
				<Row gutter={[0, 20]}>
					<Col xs={24}>
						<DetailTitle>{I18n.get('Man')}</DetailTitle>
					</Col>
					<Col xs={24}>
						<Score>{percentile_man}</Score>
					</Col>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: '0 0 -28px 0', height: 'auto' }} type="vertical" />
		<Col xs={11} style={{ paddingLeft: '1rem' }}>
			<Details>
				<Row gutter={[0, 20]}>
					<Col xs={24}>
						<DetailTitle>{I18n.get('Woman')}</DetailTitle>
					</Col>
					<Col xs={24}>
						<Score>{percentile_woman}</Score>
					</Col>
				</Row>
			</Details>
		</Col>
	</Row>
);
