import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useMutation } from '@tanstack/react-query';

import Api from '@/services/api';
import { AxiosErrorResponse, BaseContext } from '@/types';

type DownloadPDFDTO = BaseContext & {
	locale: string;
	toolURL: string;
	file_id?: string;
	report_id?: string;
	id?: string;
	custom_report_result_id?: string;
};

async function downloadPDF(payload: DownloadPDFDTO): Promise<string> {
	const url = `/${payload.toolURL}/document/pdf`;
	const { data } = await Api.post<string>(url, payload, {
		responseType: 'blob'
	});
	return data;
}

export const useDownloadErgonomicToolPDF = () => {
	return useMutation<string, AxiosErrorResponse, DownloadPDFDTO>((payload) => downloadPDF(payload), {
		onSuccess: (data) => {
			const blob = new Blob([data], {
				type: 'application/pdf'
			});
			window.open(URL.createObjectURL(blob));
			message.success(I18n.get('Document created successfully'));
		}
	});
};
