import React from 'react';
import { Row, Col } from 'antd';
import { useGetOrganizationUsers } from '../../../hooks/useGetOrganizationUsers';
import { useContractPlansContext } from '../../../context';
import { Content } from './Content';
import * as S from './styles';

type GetParams = {
	organization_id?: string;
	company_id?: string;
};

export function Table() {
	const { queryUrl } = useContractPlansContext();

	function getParameters(): GetParams {
		const organization_id = queryUrl?.get('organization_id');
		const company_id = queryUrl?.get('company_id');
		const type = queryUrl?.get('type');

		if (type === 'company') {
			return { organization_id, company_id };
		}

		return { organization_id };
	}

	const { isLoading, data, isError } = useGetOrganizationUsers(getParameters());

	return (
		<S.Container span={24}>
			<Row>
				<Col span={24}>
					<Content isLoading={isLoading} data={data} isError={isError} />
				</Col>
			</Row>
		</S.Container>
	);
}
