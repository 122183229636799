import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Exposure, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type GetExposuresDTO = BaseContext & {
	score_scale_id?: string;
};

export type ExposureResponse = Pick<Exposure, 'id' | 'name' | 'description' | 'score'>;

async function getExposures<T, Body>(payload: Body): Promise<T> {
	const url = `/ergonomic-tool/exposures`;
	const { data } = await Api.get<T>(url, {
		params: payload
	});
	return data;
}

export function useGetExposures({ organization_id, company_id, score_scale_id }: GetExposuresDTO) {
	const parameters = { organization_id, company_id, score_scale_id };
	const exposures = useQuery<ExposureResponse[], AxiosError<Response>>(
		['exposures', parameters],
		() => getExposures<ExposureResponse[], GetExposuresDTO>(parameters),
		{
			retry: 0,
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...exposures,
		data: exposures.data ?? ([] as ExposureResponse[])
	};
}
