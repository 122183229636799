import React, { useEffect, useRef } from 'react';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import Upload, { RcFile } from 'antd/lib/upload';
import { Spin, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import ImgCrop from 'antd-img-crop';

import { useApplicationContext } from '@/context/Application';
import { Container, UploadContainer } from './styles';
import { useSendImage } from './hooks';
import { IUploadLogo } from './types';

const TYPE = { CUSTOMER: 'customer', ORGANIZATION: 'organization', COMPANY: 'company' };

export const UploadLogo: React.FC<IUploadLogo> = ({
	url,
	type,
	organizationId,
	companyId,
	fileList,
	setFileList,
	blockButton,
	setBlockButton,
	isDisable
}) => {
	const { updateUser, updateCompany, updateOrganization, company, organization } = useApplicationContext();
	const isEditing = useRef(false);

	let initialValue = [
		{
			uid: '-1',
			url: '',
			name: I18n.get(type)
		}
	];

	if (url) {
		initialValue = [
			{
				uid: '-1',
				url: url,
				name: I18n.get(type)
			}
		];
	}
	const { mutate: sendImage, data, isSuccess, isLoading } = useSendImage(organizationId, companyId, type);

	useEffect(() => {
		setFileList(initialValue);
		type !== TYPE.CUSTOMER && setBlockButton!(isLoading);
		if (blockButton) {
			url ? setBlockButton!(false) : setBlockButton!(true);
		}
	}, [isLoading]);

	useEffect(() => {
		if (isSuccess) {
			setFileList([
				{
					uid: '-1',
					url: type === TYPE.CUSTOMER ? data.logo : data.url_logo,
					name: I18n.get(type)
				}
			]);

			type === TYPE.CUSTOMER && updateUser(data.logo);
			type === TYPE.ORGANIZATION && setLogoOrganizationLocalStorage(data.url_logo);
			type === TYPE.COMPANY && setLogoCompanyLocalStorage(data.url_logo);
		}
	}, [isSuccess]);

	const setLogoOrganizationLocalStorage = (url_logo: string | undefined) => {
		if (organizationId === organization.id) {
			updateOrganization(url_logo);
		}
	};

	const setLogoCompanyLocalStorage = (url_logo: string | undefined) => {
		if (companyId === company.id) {
			updateCompany(url_logo);
		}
	};

	const appendFormData = (file: File) => {
		const formData = new FormData();
		formData.append('file', file);

		return formData;
	};

	const onChange = (infos: UploadRequestOption) => {
		const uploadedFile = infos.file as RcFile;

		if (isEditing.current) {
			const appendedFile = appendFormData(uploadedFile);
			sendImage(appendedFile);
			isEditing.current = false;
		}
	};

	const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

	const checkFileFormat = (file: File) => {
		if (['image/jpeg', 'image/png'].includes(file.type)) return true;
		notification.error({
			message: `${I18n.get('Ops... something happened!')}`,
			description: `${I18n.get('Unsupported file')}`
		});
		return false;
	};

	if (isLoading) {
		return <Spin style={{ marginTop: '50%', height: '6rem' }} indicator={antIcon} />;
	}

	return (
		<Container>
			<UploadContainer url={fileList[0]?.url} noPicture={!!fileList[0]} isCompany={isDisable}>
				<ImgCrop
					beforeCrop={checkFileFormat}
					showReset
					rotationSlider
					onModalCancel={() => (isEditing.current = false)}
					onModalOk={() => (isEditing.current = true)}
					modalWidth={598}
					cropShape={'round'}
					aspect={1}
					modalTitle={I18n.get('Edit picture')}
					resetText={I18n.get('Reset')}
				>
					<Upload listType="text" fileList={fileList} customRequest={onChange} showUploadList={false}>
						<EditOutlined title={I18n.get('Edit picture')} />
					</Upload>
				</ImgCrop>
			</UploadContainer>
		</Container>
	);
};
