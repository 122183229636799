import React from 'react';
import { Col, Form, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomItemSwitch } from './styles';

const { useFormInstance, useWatch } = Form;

export const RequireTool: React.FC = () => {
	const form = useFormInstance();
	const tool = useWatch(['step_key', 'has_tool'], form);
	return (
		<Col xs={24}>
			<CustomItemSwitch
				name={['step_key', 'has_tool']}
				wrapperCol={{
					xs: 12,
					lg: 5,
					xxl: 3
				}}
				label={I18n.get('Link tool to analysis')}
			>
				<Switch checked={tool} />
			</CustomItemSwitch>
		</Col>
	);
};
