import styled from 'styled-components';
import { Text, Title } from '@/components/Typography';

export const CardContainer = styled.div`
	gap: 1rem;
	height: 75px;
	display: flex;
	padding: 1rem;
	user-select: none;
	position: relative;
	align-items: center;
	border-radius: 1rem;
	flex-direction: row;
	background-color: #ffffff;
	border: 2px solid #e6e6e6;
`;

export const CustomRow = styled.div`
	width: 100%;
	height: 80%;
	display: flex;
	margin-top: 1rem;
	flex-direction: column;
	justify-content: space-between;
`;

export const CustomTitle = styled(Title)`
	font-weight: 600;
	font-size: 15px !important;
	margin-bottom: 0 !important;
`;

export const CustomSubtitle = styled(Text)`
	font-weight: 400;
	font-size: 0.78rem;
`;
