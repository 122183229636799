import Api from '@/services/api';

import { CustomReportStep } from '@/types';
import { CustomReportStepListResponse } from './types/response';
import { CustomReportStepApiServiceInterface } from './servicesInterfaces';
import { CreateCustomReportStepRequest, GetCustomReportStepsRequest } from './types/request';

export class CustomReportService implements CustomReportStepApiServiceInterface {
	async fetchCustomReportSteps(params: GetCustomReportStepsRequest): Promise<CustomReportStepListResponse> {
		const url = '/custom-report/step/find-all';
		const { data } = await Api.get<CustomReportStepListResponse>(url, {
			params
		});
		return data;
	}

	async createStep(body: CreateCustomReportStepRequest): Promise<CustomReportStep> {
		const url = '/custom-report/step';
		const { data } = await Api.post<CustomReportStep>(url, body);
		return data;
	}
}

export const customReportService = new CustomReportService();
