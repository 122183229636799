import Api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

import { GetDownloadVideoURLRequest } from '@/components/ui/Inputs/types/request/file';

async function getDownloadVideoURL(payload: GetDownloadVideoURLRequest) {
	const url = '/upload/create-url-download';
	const { data } = await Api.post<string>(url, payload);
	return data;
}

export const useGetDownloadVideoURL = ({
	organization_id,
	company_id,
	file_id,
	original_name
}: GetDownloadVideoURLRequest) => {
	const parameters = { organization_id, company_id, file_id, original_name };
	const video = useQuery(['download_video_url', parameters], () => getDownloadVideoURL(parameters), {
		enabled: !!organization_id && !!company_id && !!file_id
	});
	return video;
};
