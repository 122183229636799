import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { Services } from './service';
import {
	CompanyDTO,
	CreateOrganizationRequest,
	CreateRequest,
	DeleteOrganizationRequest,
	DeleteRequest,
	OrganizationDTO,
	SectorDTO,
	UpdateOrganizationRequest,
	UpdateRequest,
	UrlOrganizationFilter,
	WorkstationDTO
} from './types';

function useGetOrganizations(limit: number, offset: number, parameters: UrlOrganizationFilter, organizationId: string) {
	const organizations = useQuery(
		['organizations_list'],
		() => Services.fetchOrganizations(limit, offset, parameters, organizationId),
		{
			enabled: !!organizationId
		}
	);
	return {
		...organizations,
		data: organizations.data || ([] as OrganizationDTO[])
	};
}

function useGetAllOrganizations(parameters?: UrlOrganizationFilter) {
	const organizations = useQuery(['organizations_all_list'], () => Services.fetchAllOrganizations(parameters));
	return {
		...organizations,
		data: organizations.data || ([] as OrganizationDTO[])
	};
}

function useGetCompanies(organizationId: string) {
	const companies = useQuery(['companies_list', organizationId], () => Services.fetchCompanies(organizationId), {
		enabled: !!organizationId
	});
	return {
		...companies,
		data: companies.data || ([] as CompanyDTO[])
	};
}

function useGetSectors(organizationId: string, companyId: string) {
	const sectors = useQuery(
		['sectors_list', organizationId, companyId],
		() => Services.fetchSectors(organizationId, companyId),
		{
			enabled: !!companyId
		}
	);
	return {
		...sectors,
		data: sectors.data || ([] as SectorDTO[])
	};
}

function useGetWorkstations(organizationId: string, companyId: string, sectorId: string) {
	const workstations = useQuery(
		['workstations_list', organizationId, companyId, sectorId],
		() => Services.fetchWorkstations(organizationId, companyId, sectorId),
		{
			enabled: !!sectorId
		}
	);
	return {
		...workstations,
		data: workstations.data || ([] as WorkstationDTO[])
	};
}

function useCreateOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateOrganizationRequest) => Services.createOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['organizations'])
			]);
			message.success(I18n.get('Organization created successfully'));
		}
	});
}

function useUpdateOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateOrganizationRequest) => Services.updateOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['organizations'])
			]);
			message.success(I18n.get('Organization updated successfully'));
		}
	});
}

function useDeleteOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteOrganizationRequest) => Services.deleteOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['organizations'])
			]);
			message.success(I18n.get('Organization deleted successfully'));
		}
	});
}

function useCreate() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateRequest) => Services.create(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations']),
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['companies_list']),
				queryClient.invalidateQueries(['sectors_list']),
				queryClient.invalidateQueries(['workstations_list'])
			]);
			message.success(I18n.get('Data created successfully'));
		}
	});
}

function useUpdate() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateRequest) => Services.update(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['companies_list']),
				queryClient.invalidateQueries(['sectors_list']),
				queryClient.invalidateQueries(['workstations_list'])
			]);
			message.success(I18n.get('Data updated successfully'));
		}
	});
}

function useDelete() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteRequest) => Services.destroy(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['companies_list']),
				queryClient.invalidateQueries(['sectors_list']),
				queryClient.invalidateQueries(['workstations_list'])
			]);
			message.success(I18n.get('Data deleted successfully'));
		}
	});
}

const Hooks = {
	useGetOrganizations,
	useGetCompanies,
	useGetSectors,
	useGetWorkstations,
	useCreateOrganization,
	useUpdateOrganization,
	useDeleteOrganization,
	useGetAllOrganizations,
	useCreate,
	useUpdate,
	useDelete
};

export default Hooks;
