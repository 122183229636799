import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { CustomReportResult, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { customReportService } from '../../../../hooks/services';

export type PersonalisedReportConsolidateResponse = CustomReportResult;

export type PersonalisedReportResultConsolidateDTO = BaseContext & {
	locale: string;
	file_id?: string;
	report_id: string;
	custom_report_result_id?: string;
};

async function consolidateReport<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/consolidate';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useConsolidateReport() {
	const queryClient = useQueryClient();
	return useMutation<
		PersonalisedReportConsolidateResponse,
		AxiosError<Response>,
		PersonalisedReportResultConsolidateDTO
	>(
		(body) =>
			consolidateReport<PersonalisedReportConsolidateResponse, PersonalisedReportResultConsolidateDTO>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async (data) => {
				const description = data.consolidated
					? 'Successfully consolidated analysis'
					: 'Successfully deconsolidated analysis';
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message, description);
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
}
