import React from 'react';

import { CustomFormItem, RadioButton, RadioGroup } from '../styles';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

export const Granularity: React.FC = () => {
	const breakpoints = useBreakpoint();
	return (
		<Col xs={24}>
			<CustomFormItem
				label={I18n.get('Granularity')}
				name={['granularity']}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select a granularity')
					}
				]}
				style={{ height: 'auto' }}
			>
				<RadioGroup buttonStyle="solid" size={!breakpoints.xxl ? 'small' : 'large'}>
					<RadioButton value="year">{I18n.get('Year')}</RadioButton>
					<RadioButton value="month">{I18n.get('Month')}</RadioButton>
					<RadioButton value="day">{I18n.get('Day')}</RadioButton>
				</RadioGroup>
			</CustomFormItem>
		</Col>
	);
};
