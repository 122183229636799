import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { DetailDescription, Details, DetailTitle } from './styles';
import { usePersonalisedReportResultContext } from '../../../context';

export const SecondRow: React.FC = () => {
	const { personalisedReportResult } = usePersonalisedReportResultContext();
	const { basic_information } = personalisedReportResult;
	const { kim_pp } = basic_information;

	return (
		<Row justify="space-between">
			<Col span={6} style={{ paddingLeft: '1rem' }}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Possible health consequences')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>{I18n.get(kim_pp?.possible_health_consequences)}</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={4}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Risk Range')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription style={{ fontSize: '1.2rem', fontWeight: 600 }}>
							{I18n.get(kim_pp?.risk_range)}
						</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={4}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Score')}:</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription style={{ fontSize: '1.2rem', fontWeight: 600 }}>
							{kim_pp?.note}
						</DetailDescription>
					</Row>
				</Details>
			</Col>
			<Divider style={{ margin: 0, height: 'auto' }} type="vertical" />
			<Col span={6}>
				<Details>
					<Row>
						<Col span={24}>
							<DetailTitle>{I18n.get('Measurements')}</DetailTitle>
						</Col>
					</Row>
					<Row>
						<DetailDescription>{I18n.get(kim_pp?.measurements)}</DetailDescription>
					</Row>
				</Details>
			</Col>
		</Row>
	);
};
