import React from 'react';
import { I18n } from '@aws-amplify/core';

import { CreateOrganizationProvider } from './context';
import { Content } from './Content';
import { Header } from '../Header';
import { Footer } from './Footer';
import * as S from '../styles';

interface CreateOrganizationModalProps {
	open: boolean;
	onCancel: () => void;
}

export function CreateOrganizationModal({ open, onCancel }: Readonly<CreateOrganizationModalProps>) {
	return (
		<S.CustomModal centered destroyOnClose open={open} onCancel={onCancel} footer={null}>
			<CreateOrganizationProvider onCancel={onCancel}>
				<S.Container gutter={[0, 15]}>
					<Header>{I18n.get('Create new company')}</Header>
					<S.ContentWrapper>
						<Content />
					</S.ContentWrapper>
					<S.FooterWrapper>
						<Footer />
					</S.FooterWrapper>
				</S.Container>
			</CreateOrganizationProvider>
		</S.CustomModal>
	);
}
