export enum Risk {
	VERY_LOW = 'VERY_LOW',
	LOW = 'LOW',
	MODERATE = 'MODERATE',
	HIGH = 'HIGH',
	VERY_HIGH = 'VERY_HIGH'
}

export enum RiskDescription {
	VERY_LOW = 'Very low',
	LOW = 'Low',
	MODERATE = 'Moderate',
	HIGH = 'High',
	VERY_HIGH = 'Very high'
}

export type INiosh = {
	mass_m: string;
	recommended_weight_limit: string;
	lifting_index: string;
	risk: Risk;
	reference_weight: number;
};

export interface IConclusion {
	mass_m: string;
	lifting_index: string;
	recommended_weight_limit: string;
	risk: Risk;
}

export interface IRecommended {
	recommended_weight_limit: string;
	lifting_index: string;
	reference_weight: number;
	risk: Risk;
}
