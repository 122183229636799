import { PersonalisedReportResultResponse } from '../hooks';

export enum RiskDescription {
	VERY_LOW = 'Very low',
	LOW = 'Low',
	MODERATE = 'Moderate',
	HIGH = 'High',
	VERY_HIGH = 'Very high'
}

export type States = {
	isLoading: boolean;
	browserLanguage: string;
	personalisedReportResult: PersonalisedReportResultResponse;
};

export type Methods = {
	handleLoadingStatus(loading: boolean): void;
};

export type Context = States & Methods;
