import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { PlusCircleFilled } from '@ant-design/icons';

import { Title } from '@/components/Typography';
import * as S from './styles';

const NOT_APPLICABLE = 'N/A';

interface HeaderProps {
	title?: string;
	loading?: boolean;
	noTranslate?: boolean;
	onClick: () => void;
}

export function Header({ title = NOT_APPLICABLE, noTranslate = false, onClick, loading }: Readonly<HeaderProps>) {
	return (
		<Row justify="center" align="middle">
			<Col span={12}>
				{loading && title === NOT_APPLICABLE ? (
					<Skeleton.Input active />
				) : (
					<Title level={4} noTranslate={noTranslate} style={{ marginBottom: 0 }}>
						{title}
					</Title>
				)}
			</Col>
			<Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<S.CreateButton type="primary" icon={<PlusCircleFilled />} onClick={onClick}>
					{I18n.get('New report')}
				</S.CreateButton>
			</Col>
		</Row>
	);
}
