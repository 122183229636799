import Types from './types';

const userStorage = JSON.parse(localStorage.getItem('info_user') || '[]');

const initState = {
	spinnerRoute: true,
	user: userStorage,
	authenticated: false,
	loadingAuth: false,
	errorAuth: null,
	session: {
		loading: false,
		data: null,
		error: null
	}
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.AUTH_SET_SESSION: {
			return {
				...state,
				authenticated: true,
				user: action.payload
			};
		}

		case Types.UPDATE_USER: {
			let session = JSON.parse(localStorage.getItem('info_user') || '[]');
			session.user = { ...session.user, ...action.payload };
			localStorage.setItem('info_user', JSON.stringify(session));
			return { ...state, user: session };
		}

		case Types.UPDATE_PLAN_CONSUMED: {
			let session = JSON.parse(localStorage.getItem('info_user') || '[]');
			session.plan = { ...session.plan, ...action.payload };
			localStorage.setItem('info_user', JSON.stringify(session));
			return { ...state, user: session };
		}

		case Types.UPDATE_USER_SESSION: {
			let session = JSON.parse(localStorage.getItem('info_user') || '[]');
			session.user = { ...session.user, ...action.payload };
			localStorage.setItem('info_user', JSON.stringify(session));
			return { ...state, user: session };
		}

		case Types.UPDATE_USER_INFO: {
			let sessionUser = JSON.parse(localStorage.getItem('info_user') || '[]');
			sessionUser.user = { ...sessionUser.user, ...action.payload };
			return {
				...state,
				user: sessionUser
			};
		}

		case Types.LOGIN_USER_LOADING: {
			return {
				...state,
				session: {
					...state.session,
					loading: !state.session.loading
				}
			};
		}

		case Types.LOGIN_USER_SUCCESS: {
			localStorage.setItem('info_user', JSON.stringify(action.payload));
			return {
				...state,
				authenticated: true,
				user: action.payload,
				session: {
					...state.session,
					data: action.payload
				}
			};
		}

		case Types.LOGIN_USER_ERROR: {
			return {
				...state,
				session: {
					...state.session,
					error: action.payload
				}
			};
		}

		case Types.LOGIN_REQUEST: {
			return { ...state, loadingAuth: !state.loadingAuth };
		}

		case Types.LOGIN_SUCCESS: {
			localStorage.setItem('info_user', JSON.stringify(action.payload));
			return {
				...state,
				authenticated: true,
				user: action.payload
			};
		}

		case Types.LOGIN_FAILURE: {
			return { ...state, errorAuth: action.payload };
		}

		case Types.SPINNER_ROUTE: {
			return { ...state, spinnerRoute: !state.spinnerRoute };
		}

		case Types.AUTHENTICATED: {
			return { ...state, authenticated: action.payload };
		}

		case Types.LOGOUT: {
			window.location.reload();
			return {};
		}

		default:
			return state;
	}
};

export default authReducer;
