export type RiskBarStyles = {
	riskId?: number;
	length?: number;
	percentage?: string;
	color?: string;
	align?: 'center' | 'left' | 'right';
	orientation?: 'horizontal' | 'vertical';
	captionDisplay?: 'top' | 'bottom' | 'left' | 'right';
	valueDisplay?: 'top' | 'bottom' | 'left' | 'right';
};

export type CaptionOptions = {
	align: 'center' | 'left' | 'right';
	display: 'top' | 'bottom' | 'left' | 'right';
};

export type ValueOptions = CaptionOptions;

export type RiskBarOptions = {
	caption: CaptionOptions;
	value?: ValueOptions;
	orientation: 'horizontal' | 'vertical';
};

export type Risk = {
	id: number;
	caption?: string;
	fontColor: string;
	trailColor: string;
	percentage: string;
	score?: number;
	value?: number | string;
	report?: string;
	workstation?: string;
	workstation_id?: string;
	body_part?: string;
	status?: string;
};

export const defaultOptions: RiskBarOptions = {
	orientation: 'horizontal',
	caption: {
		align: 'center',
		display: 'top'
	}
};

export const emptyDataRisks: Risk[] = [
	{
		fontColor: '#262626',
		trailColor: '#D9D9D9',
		id: 0,
		percentage: '100%',
		caption: 'No data available'
	}
];

export const ENUM_RISK_BAR_BORDERS = {
	first: {
		vertical: '0 0 1rem 1rem',
		horizontal: '1rem 0 0 1rem'
	},
	last: {
		vertical: '1rem 1rem 0 0',
		horizontal: '0 1rem 1rem 0'
	}
};

export const ENUM_RISK_BAR_CAPTION = {
	vertical: {
		top: 'row',
		bottom: 'column',
		left: 'row-reverse',
		right: 'row'
	},
	horizontal: {
		top: 'column',
		bottom: 'column-reverse',
		left: 'column',
		right: 'column'
	}
};
