import { Col } from 'antd';
import styled from 'styled-components';

export const FlexCol = styled(Col)`
	display: flex;

	#max_time_check_help {
		display: none;
	}

	#max_uploads_check_help {
		position: absolute;
	}
`;
