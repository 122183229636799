import { SectorsRequest } from '@/components/ui/Inputs/types/request/sector';
import { SectorDTO } from '@/components/ui/Inputs/types/response/sector';
import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getSectors({ organizationId, companyId }: SectorsRequest): Promise<SectorDTO[]> {
	const url = `/sector/${organizationId}/${companyId}`;
	const { data } = await Api.get<SectorDTO[]>(url);
	return data;
}

/*  Deprecated - please use useGetSectors.ts */
export function useSectors({ organizationId, companyId }: SectorsRequest) {
	const enabled = !!organizationId && !!companyId;

	const sectors = useQuery(
		['sectors', { organizationId, companyId }],
		() => getSectors({ organizationId, companyId }),
		{ enabled }
	);
	return { sectors };
}
