import styled from 'styled-components';
import { Col } from 'antd';

export const Card = styled(Col)`
	padding: 3rem 3rem;
	border-radius: 30px;
	background-color: #fff;
	box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.05), 3px 3px 4px 0px rgba(0, 0, 0, 0.05);

	@media only screen and (max-width: 600px) {
		padding: 2rem 2.5rem;
	}
`;
