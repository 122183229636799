import React from 'react';
import { Container } from './styles';
import { AlignType } from './types';

interface LinkProps {
	children: string;
	path: string;
	align?: AlignType;
}

export const Link: React.FC<LinkProps> = ({ children = '', path = '', align }) => (
	<Container align={align} href={path}>
		{children}
	</Container>
);
