import React from 'react';
import { Col, Row } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { type CompanyDTO } from '@/components/Settings/ContractPlans/hooks/index';
import { ActionButton, Actions, Column, Item } from '../styles';
import { Text } from '@/components/Typography';
import { SkeletonList } from './SkeletonList';

interface ListProps {
	items?: CompanyDTO[];
	isLoading: boolean;
	onEdit(company: CompanyDTO): void;
	onDelete(company: CompanyDTO): void;
}

export function List({ items, isLoading, onEdit, onDelete }: Readonly<ListProps>) {
	if (isLoading) {
		return <SkeletonList />;
	}

	if (!items || items.length === 0) {
		return <></>;
	}

	return (
		<Col span={24}>
			<Row gutter={[0, 12]}>
				{items.map((item) => {
					return (
						<Item span={24} key={item.company_id}>
							<Row align="middle">
								<Column span={19}>
									<Text>{item.name}</Text>
								</Column>
								<Actions span={5}>
									<Row gutter={[15, 0]}>
										<Col span={12}>
											<ActionButton onClick={() => onEdit(item)}>
												<EditOutlined style={{ color: '#2F54EB' }} />
											</ActionButton>
										</Col>
										<Col span={12}>
											<ActionButton onClick={() => onDelete(item)}>
												<DeleteOutlined style={{ color: '#E74150' }} />
											</ActionButton>
										</Col>
									</Row>
								</Actions>
							</Row>
						</Item>
					);
				})}
			</Row>
		</Col>
	);
}
