import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import React from 'react';

type ReportNameProps = {
	organizationId: string;
	companyId: string;
};

export function ReportName({ organizationId, companyId }: ReportNameProps) {
	return (
		<Form.Item name="reportName">
			<Input disabled={!organizationId || !companyId} placeholder={I18n.get('Report name')} />
		</Form.Item>
	);
}
