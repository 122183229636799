import React, { useEffect } from 'react';
import { Col, Form, Row, Switch } from 'antd';
import moment from 'moment';

import { ControlPeriod, MaxMinutesUsed, MaxVideosNumber, RenewalDay } from '../../../../components';
import { useCreateOrganizationContext } from '../../../context';
import { CustomFormItem, SubTitle } from '../../../../styles';
import { Text } from '@/components/Typography';
import { FlexCol } from './styles';

import { I18n } from '@aws-amplify/core';

const { useWatch, useFormInstance } = Form;

export function ProcessingControl() {
	const form = useFormInstance();
	const { plan } = useCreateOrganizationContext();

	const is_maxUploads_checked = useWatch('company_max_uploads_check');
	const is_max_minutes_checked = useWatch('company_max_minutes_check');
	const is_processing_control_Checked = useWatch('processing_control_check');

	function setSwitchInitialValues() {
		form.setFieldValue('company_max_minutes_check', false);
		form.setFieldValue('company_max_uploads_check', false);
		form.setFieldValue('processing_control_check', true);
	}

	function setDisabledFieldsValue() {
		form.setFieldValue('company_recurrence', plan.recurrence);
		form.setFieldValue('company_renewal_day', moment(plan.expiration_plan).date());
	}

	function switchValidator() {
		if (!is_maxUploads_checked && !is_max_minutes_checked) {
			return Promise.reject(I18n.get('Select a value'));
		}

		return Promise.resolve();
	}

	useEffect(() => {
		setSwitchInitialValues();
		setDisabledFieldsValue();
	}, []);

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<SubTitle>{I18n.get('Processing control')}</SubTitle>
			</Col>
			<Col span={24}>
				<Row align="middle" gutter={[10, 0]}>
					<Col>
						<CustomFormItem name="processing_control_check">
							<Switch checked={is_processing_control_Checked} />
						</CustomFormItem>
					</Col>
					<Col>
						<Text style={{ fontSize: 14 }}>{I18n.get('Use company minutes and/or number of videos')}</Text>
					</Col>
				</Row>
			</Col>
			{!is_processing_control_Checked && (
				<>
					<Col span={24} style={{ marginLeft: 50 }}>
						<Row gutter={[0, 10]}>
							<FlexCol span={24}>
								<Row align="middle" gutter={[10, 0]}>
									<Col>
										<CustomFormItem
											name="company_max_minutes_check"
											rules={[{ validator: switchValidator }]}
										>
											<Switch size="small" checked={is_max_minutes_checked} />
										</CustomFormItem>
									</Col>
									<Col>
										<Text style={{ fontSize: 12 }}>
											{I18n.get('Set the maximum processing time')}
										</Text>
									</Col>
								</Row>
							</FlexCol>
							<FlexCol span={24}>
								<Row align="middle" gutter={[10, 0]}>
									<Col>
										<CustomFormItem
											name="company_max_uploads_check"
											rules={[{ validator: switchValidator }]}
										>
											<Switch size="small" checked={is_maxUploads_checked} />
										</CustomFormItem>
									</Col>
									<Col>
										<Text style={{ fontSize: 12 }}>
											{I18n.get('Set a maximum number of videos to process')}
										</Text>
									</Col>
								</Row>
							</FlexCol>
						</Row>
					</Col>
					{(is_max_minutes_checked || is_maxUploads_checked) && (
						<Col span={24}>
							<Row gutter={[0, 15]}>
								<Col span={12}>
									<ControlPeriod disabled name="company_recurrence" required />
								</Col>
								<Col span={12}>
									<RenewalDay disabled name="company_renewal_day" required />
								</Col>
								{is_max_minutes_checked && (
									<Col span={24}>
										<MaxMinutesUsed name="company_max_minutes" required />
									</Col>
								)}
								{is_maxUploads_checked && (
									<Col span={24}>
										<MaxVideosNumber name="company_max_uploads" required />
									</Col>
								)}
							</Row>
						</Col>
					)}
				</>
			)}
		</Row>
	);
}
