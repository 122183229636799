import styled from 'styled-components';

export const Title = styled.span`
	font-weight: 600;
	font-size: 1rem;
`;

export const SubTitle = styled.span`
	font-weight: 400;
	font-size: 0.76rem;
`;

export const MovementByScoreContainer = styled.div`
	max-width: 20rem;
	margin-bottom: 2rem;
`;

export const MovementByScoreTitle = styled.span`
	font-weight: 600;
	font-size: 0.9rem;
`;

export const MovementByScoreSubTitle = styled.span`
	margin-left: 1.33rem;
	font-weight: 400;
	font-size: 0.66rem;
`;

export const Value = styled.span`
	margin-left: 1.33rem;
	font-weight: 600;
	font-size: 0.7rem;
`;
