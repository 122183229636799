import Api from '@/services/api';
import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// interface DeleteFileParams {
// 	organization_id?: any;
// 	company_id?: any;
// 	file_id: any;
// }

interface DeleteFileResult {
	status: string;
	message: string;
	data: {
		id: string;
	};
}

async function deleteFile({ organization_id, company_id, file_id }: any): Promise<DeleteFileResult> {
	const url = `/upload/${file_id}`;
	const { data } = await Api.delete(url, { params: { organization_id, company_id } });
	return data;
}

export const useDeleteFile = (params: any) => {
	const queryClient = useQueryClient();
	return useMutation((file_id: any) => deleteFile({ ...params, file_id }), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILES_LIST]);
			message.success(I18n.get('File deleted successfully'));
		}
	});
};
