import { Col, Row } from 'antd';
import React from 'react';

import { I18n } from '@aws-amplify/core';
import { useApplicationContext } from '@/context/Application';

import hooks from '../hooks';
import { RiskBar } from '../RiskBar';
import { DashboardCard } from '../styles';
import { DashboardService } from '../service';
import { useDashboardContext } from '../context';
import { ToolSubtitle, ToolTitle, Total, Value } from './styles';
import { emptyDataRisks, Risk, RiskBarOptions } from '../RiskBar/types';
import { DashboardStrainIndexDTO, ENUM_STRAIN_INDEX_SCORE_SCALE } from '../types';

const { useDashboardStrainIndex } = hooks;
const dashboardService = new DashboardService();

export const Repetitiveness: React.FC = () => {
	const { queryParams } = useDashboardContext();
	const { organization, company } = useApplicationContext();
	const { data: strainIndexReports } = useDashboardStrainIndex(organization.id, company.id, queryParams);

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'top'
		},
		orientation: 'horizontal'
	};

	function risksMapper(strainIndexReports: DashboardStrainIndexDTO): Risk[] {
		if (strainIndexReports) {
			const risks: Risk[] = [];

			let id = 0;
			for (const risk in strainIndexReports.scores) {
				if (dashboardService.reportExistsAndHasType(strainIndexReports, risk)) {
					const percentage = (strainIndexReports.scores[risk].amount / strainIndexReports.total) * 100;
					const element: Risk = {
						...ENUM_STRAIN_INDEX_SCORE_SCALE[strainIndexReports.scores[risk].type],
						id,
						value: strainIndexReports.scores[risk].amount,
						percentage: percentage + '%',
						score: strainIndexReports.scores[risk].score
					};
					element.report = 'rsi';
					id += 1;
					risks.push(element);
				}
			}

			return risks.map((risk) => {
				const hasCaption = risk.id === 0 || risk.id === risks.length - 1;
				return {
					...risk,
					caption: hasCaption ? risk.caption : ''
				};
			});
		}
		return [];
	}

	return (
		<DashboardCard>
			<Row gutter={[0, 12]} align="middle" justify="center">
				<Col span={24}>
					<Row gutter={[0, 2]}>
						<Col span={24}>
							<ToolTitle>{I18n.get('Repetitiveness')}</ToolTitle>
						</Col>

						<Col span={24}>
							<ToolSubtitle>{I18n.get('Score by workstation')}</ToolSubtitle>
						</Col>
					</Row>
				</Col>
				<Col span={8}>
					<Total>{I18n.get('Total evaluations')}:</Total>
				</Col>
				<Col span={16}>
					{strainIndexReports.total ? <Value>{strainIndexReports.total}</Value> : <Value>0</Value>}
				</Col>
				<Col span={20}>
					{strainIndexReports.total > 0 ? (
						<RiskBar risks={risksMapper(strainIndexReports)} options={riskBarOptions} />
					) : (
						<RiskBar risks={emptyDataRisks} options={riskBarOptions} />
					)}
				</Col>
			</Row>
		</DashboardCard>
	);
};
