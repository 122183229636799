import React from 'react';
import { Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useDashboardContext } from '../../context';
import { defaultOptions, Risk, RiskBarOptions } from '../types';
import { Caption, Container, RiskBarCaption, RiskBarContainer, RiskBarContent, RiskScale, Value } from './styles';

interface HorizontalProps {
	risks: Risk[];
	options?: RiskBarOptions;
}

export const Horizontal: React.FC<HorizontalProps> = ({ risks, options = defaultOptions }) => {
	const { handleFilterSelection } = useDashboardContext();

	const handleTypes = (risk: Risk) => {
		const NIOSH_RISK = ['VERY_LOW', 'LOW', 'MODERATE', 'HIGH', 'VERY_HIGH'];
		const types = [
			{ rsi: risk.score! },
			{ niosh: NIOSH_RISK[risk.score! - 1] },
			{ work_organization: risk.score! },
			{ workstations: { score: risk.score!, workstation: risk.workstation_id! } },
			{ reba: { score: risk.score!, body_part: risk.body_part! } },
			{ action_plan: risk.status }
		];
		const getTypes = types.find((elem) => Object.keys(elem)[0] === risk.report);
		return getTypes ? getTypes : {};
	};

	return (
		<Container orientation={options.orientation}>
			{risks.map((risk) => {
				return (
					risk.percentage !== '0%' && (
						<RiskBarContainer
							percentage={risk.percentage}
							orientation={options.orientation}
							key={risk.id}
							valueDisplay={options.value?.display}
						>
							{(risk.caption || risk.value) && (
								<RiskBarCaption
									orientation={options.orientation}
									captionDisplay={options.caption.display}
									valueDisplay={options.value?.display}
								>
									{risk.caption && risk.value ? (
										<Caption
											title={I18n.get(risk.caption)}
											style={{
												fontWeight: risk.trailColor === '#d6d6d6' ? 600 : 400
											}}
										>
											{Number(risk.percentage.split('%')[0]) > 20 ? (
												I18n.get(risk.caption)
											) : (
												<span>
													<br />
												</span>
											)}
										</Caption>
									) : (
										<Caption>
											<span>
												<br />
											</span>
										</Caption>
									)}
									{risk.value ? (
										<Value style={{ color: risk.fontColor }}>
											{Number(risk.percentage.split('%')[0]) < 20 ? (
												<Tooltip title={risk.caption}>{risk.value}</Tooltip>
											) : (
												risk.value
											)}
										</Value>
									) : (
										<Value>
											<span>
												<br />
											</span>
										</Value>
									)}
								</RiskBarCaption>
							)}
							<RiskBarContent
								orientation={options.orientation}
								align={options.caption.align}
								percentage={risk.percentage}
								captionDisplay={options.caption?.display}
								valueDisplay={options.value?.display}
							>
								{Number(risk.percentage.split('%')[0]) < 20 ? (
									<Tooltip title={I18n.get(risk.caption)}>
										<RiskScale
											onClick={() => {
												handleFilterSelection(handleTypes(risk)!);
											}}
											riskId={risk.id}
											length={risks.length}
											orientation={options.orientation}
											color={risk.trailColor}
										/>
									</Tooltip>
								) : (
									<RiskScale
										onClick={() => {
											handleFilterSelection(handleTypes(risk)!);
										}}
										riskId={risk.id}
										length={risks.length}
										orientation={options.orientation}
										color={risk.trailColor}
									/>
								)}
							</RiskBarContent>
						</RiskBarContainer>
					)
				);
			})}
		</Container>
	);
};
