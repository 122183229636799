import React from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { StepButton } from './styles';
import { useCustomReportManagerContext } from '../../context';

interface StepProps {
	id: string;
	selected: boolean;
	description: string;
}

enum ANTD_BUTTON_TYPES_ENUM {
	PRIMARY = 'primary',
	GHOST = 'ghost'
}

export const Step: React.FC<StepProps> = ({ description, id, selected }) => {
	const { firstIncompleteStepId, handleSelectStep } = useCustomReportManagerContext();

	const buttonType = selected ? ANTD_BUTTON_TYPES_ENUM.PRIMARY : ANTD_BUTTON_TYPES_ENUM.GHOST;

	function handleStepClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
		event.preventDefault();
		event.stopPropagation();
		handleSelectStep(id);
	}

	return (
		<Col id={`${selected}`} style={{ paddingBottom: '0.5rem' }}>
			<StepButton incomplete={firstIncompleteStepId === id} onClick={handleStepClick} type={buttonType}>
				{I18n.get(description)}
			</StepButton>
		</Col>
	);
};
