import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { NeedleContainer, Scale, SpeedometerContainer } from './styles';

type SpinnerProps = {
	level: number;
};

export const Spinner: React.FC<SpinnerProps> = ({ level }) => {
	const colors = ['#2CC852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];
	const options = {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false
			},
			datalabels: {
				display: false
			}
		},
		rotation: -90,
		circumference: 180,
		cutout: '60%',
		maintainAspectRatio: false,
		responsive: true
	};

	return (
		<SpeedometerContainer>
			<Scale>
				<Doughnut
					data={{
						datasets: [
							{
								backgroundColor: colors,
								borderColor: colors,
								borderWidth: 1,
								data: [1, 1, 1, 1, 1],
								rotation: -90
							}
						]
					}}
					options={options}
				/>
			</Scale>
			<NeedleContainer level={level}>
				<svg width="100%" viewBox="0 0 52 104" fill="none" xmlns="http://www.w3.org/2000/svg">
					<ellipse cx="26.4434" cy="78.4429" rx="25.5" ry="25.5" fill="#828282" />
					<path
						d="M24.9785 11.2834C25.3906 9.11451 28.4961 9.11452 28.9082 11.2834L37.7507 57.8195C37.9849 59.0517 37.0401 60.1929 35.7859 60.1929H18.1008C16.8466 60.1929 15.9019 59.0517 16.136 57.8195L24.9785 11.2834Z"
						fill="#828282"
					/>
				</svg>
			</NeedleContainer>
		</SpeedometerContainer>
	);
};
