import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { customReportService } from '../../../../hooks/services';
import { CustomReportStepKeyResult, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { GenericStepKeyResult } from './useCreateGenericStepKeyResult';

export type UpdateGenericStepKeyResultDTO = BaseContext & {
	step_setting_id?: string;
	custom_report_id: string;
	custom_report_result_id?: string;
	step_keys_results: GenericStepKeyResult[];
};

export type PersonalisedReportGenericStepKeyResultUpdateResponse = CustomReportStepKeyResult;

async function updateGenericStepKeyResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/step-key';
	const { data } = await Api.put<T>(url, payload);
	return data;
}

export function useUpdateGenericStepKeyResult() {
	return useMutation<
		PersonalisedReportGenericStepKeyResultUpdateResponse[],
		AxiosError<Response>,
		UpdateGenericStepKeyResultDTO
	>(
		(body) =>
			updateGenericStepKeyResult<
				PersonalisedReportGenericStepKeyResultUpdateResponse[],
				UpdateGenericStepKeyResultDTO
			>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message);
			}
		}
	);
};
