const Types = {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	SPINNER_ROUTE: 'SPINNER_ROUTE',
	AUTHENTICATED: 'AUTHENTICATED',
	LOGOUT: 'LOGOUT',

	LOGIN_USER_LOADING: 'LOGIN_USER_LOADING',
	LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
	LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',
	UPDATE_USER_INFO: 'UPDATE_USER_INFO',

	UPDATE_USER_SESSION: 'UPDATE_USER_SESSION',
	UPDATE_PLAN_CONSUMED: 'UPDATE_PLAN_CONSUMED',

	UPDATE_USER: 'UPDATE_USER',

	AUTH_SET_SESSION: 'AUTH_SET_SESSION'
};

export default Types;
