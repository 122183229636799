import React from 'react';
import { Col, Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ExposureResponse } from '../../hooks';
import { RequiredLabel } from './RequiredLabel';
import { OptionType } from './KinebotStepKey/types';
import { CustomFormItem } from './KinebotStepKey/styles';
import { usePersonalisedReportContext } from '../../context';
import { AntdDropdownOption, DropdownOptions } from '../../context/types';
import { ScoreScales, kinebotStepKeyExposureDescriptionMapper } from '@/types';

const { useFormInstance, useWatch } = Form;

interface ExposuresProps {
	disabled?: boolean;
	stepKeyFieldName: string[];
	mapDropdownOptions: (options: DropdownOptions) => AntdDropdownOption[];
}

export const Exposures: React.FC<ExposuresProps> = ({ stepKeyFieldName, mapDropdownOptions, disabled = false }) => {
	const form = useFormInstance();
	const {
		exposuresData,
		personalisedReport: { score_scale_name }
	} = usePersonalisedReportContext();

	const fieldName = [...stepKeyFieldName, 'exposure_id'];
	const exposureId = useWatch(fieldName, form);

	function handleExposureSelect(id: string): void {
		form.setFieldValue(fieldName, id);
	}

	function handleClearExposure(): void {
		form.setFieldValue(fieldName, undefined);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function mapExposuresForKinebotStepKey(): ExposureResponse[] {
		if (score_scale_name === ScoreScales.WILLIAM_T_FINE && stepKeyFieldName.includes('kinebot')) {
			return exposuresData.exposures.map((exposure) => ({
				...exposure,
				description: kinebotStepKeyExposureDescriptionMapper[exposure.name]
			}));
		}
		return exposuresData.exposures;
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Exposure" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the exposure')
					}
				]}
			>
				<Select
					showSearch
					allowClear
					size="large"
					value={exposureId}
					onClear={handleClearExposure}
					placeholder={I18n.get('Exposure')}
					options={mapDropdownOptions(mapExposuresForKinebotStepKey())}
					onChange={(value: string) => handleExposureSelect(value)}
					disabled={disabled || exposuresData.errorGettingExposures}
					filterOption={(input, option) => filterOption(option, input)}
					loading={exposuresData.gettingExposures || exposuresData.fetchingExposures}
				/>
			</CustomFormItem>
		</Col>
	);
};
