import React from 'react';

import { CreateOrganizationModal } from './Modals/CreateOrganizationModal';
import { EditOrganizationModal } from './Modals/EditOrganizationModal';
import { EditCompanyModal } from './Modals/EditCompany';
import { UsersModal } from './Modals/UsersModal';
import { ModalDelete } from './ModalDelete';
import { LogosModal } from './LogosModal';

import { useContractPlansContext } from './context';
import { useQueryParams } from './hooks/index';

export function ModalLocation() {
	const { clearAll } = useQueryParams();
	const { queryUrl, setQueryUrl } = useContractPlansContext();

	function handleOnCancel(): void {
		clearAll(setQueryUrl);
	}

	return (
		<>
			<ModalDelete open={queryUrl && queryUrl.get('modal') === 'delete'} onCancel={handleOnCancel} />
			<CreateOrganizationModal open={queryUrl && queryUrl.get('modal') === 'create'} onCancel={handleOnCancel} />
			<EditOrganizationModal open={queryUrl && queryUrl.get('modal') === 'edit'} onCancel={handleOnCancel} />
			<LogosModal open={queryUrl && queryUrl.get('modal') === 'logo'} onCancel={handleOnCancel} />
			<UsersModal open={queryUrl && queryUrl.get('modal') === 'users'} onCancel={handleOnCancel} />
			<EditCompanyModal
				open={queryUrl && queryUrl.get('modal') === 'edit-company'}
				companyId={queryUrl && queryUrl.get('company_id')}
				onCancel={handleOnCancel}
			/>
		</>
	);
}
