import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { SubTitle, TextInput } from '../../styles';
import { FormItemWrapper } from './styles';
import { EditButton } from './EditButton';

interface EditableFieldProps {
	name: string;
	title: string;
	required?: boolean;
	errorMessage: string;
}

export function EditableField({ name, title, errorMessage, required = false }: Readonly<EditableFieldProps>) {
	const [isDisabledInput, setIsDisabledInput] = useState<boolean>(true);

	function handleToggleEdit(): void {
		setIsDisabledInput(!isDisabledInput);
	}

	return (
		<Row gutter={[10, 10]} align="bottom">
			<Col span={22}>
				<FormItemWrapper
					name={name}
					labelCol={{ span: 24 }}
					rules={[{ required, message: errorMessage }]}
					label={<SubTitle>{I18n.get(title)}</SubTitle>}
				>
					<TextInput disabled={isDisabledInput} placeholder={I18n.get(title)} />
				</FormItemWrapper>
			</Col>
			<EditButton isDisabled={isDisabledInput} onClick={handleToggleEdit} />
		</Row>
	);
}
