import React from 'react';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, TextInput } from '../../styles';
import { FieldsProps } from '../types';

export function City({ name, required = false }: Readonly<FieldsProps>) {
	return (
		<CustomFormItem
			name={name}
			label={I18n.get('City')}
			labelCol={{ span: 24 }}
			rules={[{ required, message: I18n.get('Enter a city') }]}
		>
			<TextInput placeholder={I18n.get('City')} />
		</CustomFormItem>
	);
}
