import styled from 'styled-components';

export const TableContainer = styled.div`
	border: 1px solid #e6e8e8;
	border-radius: 0.25rem;
`;

export const TableHeaderContainer = styled.div`
	background: #e6e8e8;
	padding: 1rem;
`;

export const TableBodyContainer = styled.div`
	background: #fff;
	padding: 0 1rem;

	span {
		font-weight: 400;
		font-size: 0.75rem;
	}
`;

export const HeaderTitle = styled.span`
	font-weight: 600;
	font-size: 1rem;
`;
