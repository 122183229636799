import React from 'react';
import { Button, Col } from 'antd';
import { StyledButtonAction, StyledCancelConfirm } from '../../styles';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

interface EditButtonProps {
	isDisabled: boolean;
	onClick(): void;
}

export function EditButton({ isDisabled, onClick }: Readonly<EditButtonProps>) {
	if (isDisabled) {
		return (
			<Col span={2}>
				<StyledButtonAction>
					<Button
						ghost
						type="primary"
						onClick={onClick}
						icon={<EditOutlined style={{ fontSize: '1.7rem' }} />}
					/>
				</StyledButtonAction>
			</Col>
		);
	}

	return (
		<Col span={2}>
			<StyledCancelConfirm>
				<Button
					ghost
					danger
					type="primary"
					onClick={onClick}
					icon={<CloseOutlined style={{ fontSize: '1.2rem' }} />}
				/>
			</StyledCancelConfirm>
		</Col>
	);
}
