function isFloat(n: number): boolean {
	return Number(n) === n && n % 1 !== 0;
}

function calculatePercentage(array: number[]): string[] {
	const total = array.reduce((acc, value) => acc + value, 0);
	const data = array.map((value) => (value / total) * 100);
	const result = data.map((value) => (isFloat(value) ? value.toFixed(1) : value.toString()));
	return result;
}

export const Utils = {
	calculatePercentage,
	isFloat
};
