import React from 'react';
import { ReactComponent as LinesSVG } from '@/assets/svg/lines.svg';
import { Mask, Container, SvgContainer } from './styles';

export const Background: React.FC = () => {
	return (
		<Container>
			<Mask />
			<SvgContainer>
				<LinesSVG />
			</SvgContainer>
		</Container>
	);
};
