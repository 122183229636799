import styled from 'styled-components';
import { Row, Col, Button, Input, Checkbox } from 'antd';

const { Password } = Input;

interface LinkProps {
	align?: 'start' | 'center' | 'end';
}

export const Link = styled.a<LinkProps>`
	display: flex;
	justify-content: ${({ align }) => align ?? 'end'};
	font-size: 0.9rem !important;

	@media only screen and (min-width: 1600px) {
		font-size: 1rem;
	}
`;

export const WrapperCheckbox = styled(Checkbox)`
	font-size: 0.9rem !important;

	@media only screen and (min-width: 1600px) {
		.ant-form label {
			font-size: 1rem;
		}
	}
`;

export const Container = styled(Col)`
	border-radius: 30px;
	padding: 2rem 3rem;
	background-color: #fff;
	box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.05), 3px 3px 4px 0px rgba(0, 0, 0, 0.05);

	@media only screen and (max-width: 600px) {
		padding: 2rem 2.5rem;
	}
`;

export const ImageBox = styled(Row)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 12% 0 12% 0;
	text-align: center;
	cursor: pointer;

	img {
		width: 75%;
	}

	span {
		vertical-align: text-bottom;
		font-size: 16px;
		text-transform: uppercase;
		display: inline-block;
		font-weight: 700;
	}
`;

export const CustomInput = styled(Input)`
	padding: 2% 4% 2% 2%;
	border-radius: 3px;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.65);
	border: 1px solid #d9d9d9;

	.site-form-item-icon {
		margin-right: 0.5rem;
	}
`;

export const CustomInputPassword = styled(Password)`
	padding: 2% 4% 2% 2%;
	border-radius: 3px;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.65);

	.site-form-item-icon {
		margin-right: 0.5rem;
	}
`;

export const CustomButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 4% 0;
	border-radius: 3px;
	font-weight: 600;
	font-size: 1rem;
`;

export const ContainerAlerts = styled.div`
	padding: 0 0 15px 0;
`;
