import React from 'react';

interface PersonalisedReportSVGProps {
	width: number;
	height: number;
	margin?: string;
	disabled?: boolean;
}

export const PersonalisedReportSVG: React.FC<PersonalisedReportSVGProps> = ({
	height,
	width,
	margin = 0,
	disabled = false
}) => (
	<span style={{ display: 'flex', margin: margin }}>
		<svg width={width} height={height} viewBox="0 0 78 55" fill="none">
			<path
				d="M75.138 0H26.835c-1.583 0-2.863 1.302-2.863 2.914v21.49H2.863C1.28 24.404 0 25.706 0 27.318v24.768C0 53.698 1.28 55 2.862 55h48.303c1.583 0 2.863-1.302 2.863-2.914v-21.49h21.11c1.583 0 2.862-1.302 2.862-2.914V2.914C78 1.302 76.72 0 75.138 0zM47.945 48.808h-17.89V30.596h17.89v18.212zm23.972-24.404h-17.89V6.192h17.89v18.212z"
				fill={disabled ? '#AAAAAA' : 'currentColor'}
			/>
		</svg>
	</span>
);
