import React from 'react';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, CustomSelect } from '../../styles';
import { FieldContainer } from '../styles';
import { FieldsProps } from '../types';

interface RenewalDayProps extends FieldsProps {
	disabled?: boolean;
}

export function RenewalDay({ name, required = false, disabled = false }: Readonly<RenewalDayProps>) {
	const daysList = Array.from(Array(30).keys());

	const options = daysList.map((number) => {
		return {
			value: number + 1,
			label: number + 1
		};
	});

	return (
		<FieldContainer disabled={disabled}>
			<CustomFormItem
				name={name}
				labelCol={{ span: 24 }}
				label={I18n.get('Renewal Day')}
				rules={[{ required, message: I18n.get('Enter a renewal Day') }]}
			>
				<CustomSelect disabled={disabled} placeholder={I18n.get('Renewal Day')} options={options} />
			</CustomFormItem>
		</FieldContainer>
	);
}
