import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomRangePicker, Label } from './styles';

export function CreatedAt() {
	const title = I18n.get('Upload date');

	return (
		<Form.Item name={['created_at']} label={<Label>{title}</Label>}>
			<CustomRangePicker
				format="L"
				allowClear
				disabledDate={(current) => current && current.valueOf() >= Date.now()}
			/>
		</Form.Item>
	);
}
