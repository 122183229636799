import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

type NioshInput = {
	report_id?: string;
	file_id?: string;
	current_step?: number;
};

type NioshInputStep0 = NioshInput & {
	report_name: string;
	collection_date: Date;
	sector_id: string;
	cellule_id?: string;
	workstation_id: string;
	evaluator_id: string;
	file_id?: string;
	activity_id: string;
};

type NioshInputStep1 = NioshInput & {
	report_type: 'NIOSH' | 'ISO_11228';
	coupling: 'POOR' | 'FAIR' | 'GOOD';
	frequency: 0.2 | 0.5 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
	duration: 'LESS_THAN_1H' | 'BETWEEN_1_AND_2H' | 'BETWEEN_2_AND_8H';
	mass_m: number;
	distance_dc: number;
	distance_vc: number;
	distance_h: number;
	angle_a: number;
	workers?: number;
	hands?: number;
};

type NioshInputStep2 = NioshInput & {
	gender: 'MALE' | 'FEMALE' | null;
	age: 'LESS_THAN_20' | 'BETWEEN_20_AND_45' | 'MORE_THAN_45' | null;
	workers: number;
	hands: number;
};

type UpdateKimPpDTO = {
	organization_id: string;
	company_id: string;
	niosh_input: NioshInputStep0 | NioshInputStep1 | NioshInputStep2;
}

type UpdateKimPpResponse = {
	id: string;
	company_id: string;
	organization_id: string;
	file_id?: string;
	report_name: string;
	collection_date: string;
	sector_id: string;
	cellule_id?: string;
	workstation_id: string;
	evaluator_id: string;
	duration?: number;
	left_force_frequency?: number;
	left_force_type?: string;
	right_force_frequency?: number;
	right_force_type?: string;
	arm_posture?: string;
	work_conditions?: string;
	temporal_distribution?: string;
	body_posture?: string;
};

type UpdateKimPpError = AxiosError<any>;

async function updateReport(parameters: UpdateKimPpDTO) {
	const { data } = await Api.put(`/ergonomic-tool/niosh/${parameters.niosh_input.report_id}`, parameters);
	return data;
}

export const useUpdateNiosh = () => {
	const queryClient = useQueryClient();
	return useMutation<UpdateKimPpResponse, UpdateKimPpError, UpdateKimPpDTO>((payload) => updateReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_NIOSH_REPORT]);
			queryClient.invalidateQueries(['file']);
		}
	});
};
