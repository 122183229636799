import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { customReportService } from '../../../../hooks/services';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { CustomReportResultCharacteristic, Response } from '@/types';

export type CreateCharacteristicResultDTO = BaseContext & {
	workstation_id?: string;
	step_setting_id?: string;
	custom_report_id: string;
	working_population_men?: number;
	custom_report_result_id?: string;
	total_working_population: number;
	working_population_women?: number;
	worker_self_evaluation_id: string;
	working_population_others?: number;
	particularities_description?: string;
	worker_verbalization_description?: string;
};

export type PersonalisedReportCharacteristicResultCreateResponse = CustomReportResultCharacteristic;

async function createCharacteristicResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/characteristic';
	const { data } = await Api.post<T>(url, payload);
	return data;
}

export function useCreateCharacteristicResult() {
	const queryClient = useQueryClient();
	return useMutation<
		PersonalisedReportCharacteristicResultCreateResponse,
		AxiosError<Response>,
		CreateCharacteristicResultDTO
	>(
		(body) =>
			createCharacteristicResult<
				PersonalisedReportCharacteristicResultCreateResponse,
				CreateCharacteristicResultDTO
			>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report updated successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
}
