import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined } from '@ant-design/icons';

import { Description, ModalStyled, RoundButton } from './styles';

type DeleteModalProps = {
	open: boolean;
	description: string;
	onCancel: () => void;
	onConfirm: () => void;
};

export function DeleteModal({ description, open, onCancel, onConfirm }: Readonly<DeleteModalProps>) {
	return (
		<ModalStyled width={480} footer={null} open={open} onCancel={onCancel} centered>
			<Row gutter={[0, 30]} justify="center">
				<Col span={24}>
					<Row justify="center">
						<Col>
							<DeleteOutlined
								style={{
									fontSize: 70,
									color: '#E74150'
								}}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={20}>
					<Description>{description}</Description>
				</Col>
				<Col span={24} style={{ marginTop: 10 }}>
					<Row justify="center" align="middle" gutter={[20, 0]}>
						<Col>
							<RoundButton block key="back" onClick={onCancel}>
								{I18n.get('Cancel')}
							</RoundButton>
						</Col>
						<Col>
							<RoundButton block type="primary" onClick={onConfirm}>
								{I18n.get('Yes')}
							</RoundButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</ModalStyled>
	);
}
