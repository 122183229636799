import React from 'react';
import { Col, Row } from 'antd';
import * as I from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { CustomDivider } from '../styles';
import { Title } from '@/components/Typography';
import { RiskDescription } from './RiskDescription';
import { usePersonalisedReportStepKeyResultContext } from './context';

type RiskScaleIconMapper = {
	[key: number]: JSX.Element;
};

export const StepKeyDescription: React.FC = () => {
	const { stepKey } = usePersonalisedReportStepKeyResultContext();
	const { result, description } = stepKey;

	const iconStyle: React.CSSProperties = {
		fontSize: 20,
		width: '1.75rem'
	};

	const riskScaleIconMapper: RiskScaleIconMapper = {
		0: <I.CloseCircleOutlined style={{ ...iconStyle, color: '#b3b3b3' }} />,
		1: <I.CheckCircleOutlined style={{ ...iconStyle, color: '#2cc852' }} />,
		2: <I.ExclamationCircleOutlined style={{ ...iconStyle, color: '#FFDE31' }} />,
		3: <I.ExclamationCircleOutlined style={{ ...iconStyle, color: '#F78A38' }} />,
		4: <I.WarningOutlined style={{ ...iconStyle, color: '#E74150' }} />,
		5: <I.WarningOutlined style={{ ...iconStyle, color: '#9B54E2' }} />
	};

	return (
		<Col span={24}>
			<Row gutter={[0, 0]} align="middle">
				<Col span={1}>{riskScaleIconMapper[result ?? 0]}</Col>
				<Col span={23}>
					<Title style={{ margin: 0 }} level={4}>
						{I18n.get(description)}
					</Title>
				</Col>
			</Row>
			<Row style={{ marginTop: '0.5rem' }}>
				<Col span={1}>
					<CustomDivider type="vertical" />
				</Col>
				<Col span={22} style={{ marginLeft: '0.5rem' }}>
					<RiskDescription />
				</Col>
			</Row>
		</Col>
	);
};
