import { GetCompaniesRequest, MinutesDetailsRequest, OrganizationFilter } from '../types/request';
import { CompaniesDTO, MinutesDetailsDTO, OrganizationsDTO } from '../types/response';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';

export class MinutesDetailsApiService {
	public async getOrganizations(params: OrganizationFilter): Promise<OrganizationsDTO[]> {
		const url = `organization/`;
		const { data } = await Api.get<OrganizationsDTO[]>(url, { params });
		return this.handleData(data);
	}

	public async getCompanies(payload: GetCompaniesRequest): Promise<CompaniesDTO[]> {
		const url: string = '/company/all';
		const { data } = await Api.post<CompaniesDTO[]>(url, payload);
		return this.handleData(data);
	}

	public async getMinutesDetails(payload: MinutesDetailsRequest): Promise<MinutesDetailsDTO> {
		const url: string = '/customer/billing/account';
		const { data } = await Api.post<MinutesDetailsDTO>(url, payload);
		return data;
	}

	private handleData(data: any): any {
		let array;

		const object = {
			name: `-- ${I18n.get('Select all')} --`,
			id: 'all'
		};

		if (data) {
			array = [object, ...data];
		}

		return array;
	}
}
