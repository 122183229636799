import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { useDeleteImage } from '@/components/ui/UploadLogos/hooks';
import { UploadLogo } from '@/components/ui/UploadLogos';
import { SelectId, SwitchText } from '../styles';
import { useContractPlansContext } from '../../../context';

type OptionType = {
	label: string;
	value: string;
};

type SelectCompanyProps = {
	companies: any[];
	companyRefetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<any, unknown>>;
};

export function SelectCompany({ companies }: SelectCompanyProps) {
	const { queryUrl, setIsUrlLogoCompany, isUrlLogoCompany, fileList, setFileList } = useContractPlansContext();

	const [company, setCompany] = useState<any | undefined>();

	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		const is_company_logo = queryUrl.get('type') === 'company';
		isChecked || is_company_logo ? setIsUrlLogoCompany(false) : setIsUrlLogoCompany(true);
		if (!isChecked && fileList.length === 0) {
			setIsUrlLogoCompany(true);
		} else {
			setIsUrlLogoCompany(false);
		}
	}, [isChecked, company, fileList]);

	useEffect(() => {
		if (company) {
			const company_logo = company.url_logo;
			handleOrganizationLogo(company_logo);
			company_logo !== queryUrl.get('url_logo') ? setIsChecked(false) : setIsChecked(true);
		}
	}, [company]);

	useEffect(() => {
		if (queryUrl.get('type') === 'company') {
			handleSelectCompany(queryUrl.get('company_id'));
		}
	}, [company?.url_logo]);

	const company_id = queryUrl.get('company_id');
	const organization_id = queryUrl.get('organization_id');
	const type = 'company';
	const { mutate: removeImage } = useDeleteImage(organization_id, company_id, type);

	function filterCompany(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectCompany(id: string) {
		setCompany(companies.find((elem) => elem.company_id === id));
	}

	const handleOrganizationLogo = (company_logo?: string) => {
		const url_logo = company_logo && company_logo.length > 0 ? company_logo : queryUrl.get('url_logo');
		setFileList([
			{
				uid: '-1',
				url: company_logo ? url_logo : queryUrl.get('url_logo'),
				name: I18n.get(type)
			}
		]);
	};

	const switchCheck = (switchValue: boolean) => {
		if (!switchValue || fileList.length > 0) {
			removeImage();
		}
		setIsUrlLogoCompany(true);
		isChecked ? setFileList([]) : handleOrganizationLogo();
	};
	function handleSwitch(switchValue: boolean) {
		setIsChecked(!isChecked);

		if (fileList.length === 0 && !isChecked) {
			setIsUrlLogoCompany(false);
			handleOrganizationLogo();
		}
		switchCheck(switchValue);
	}

	const handleUpdatedCompany = (value: string) => {
		handleSelectCompany(value);
	};

	const companies_list = companies.map(({ company_id, name }) => ({
		label: name,
		value: company_id
	}));

	return (
		<Col span={24} style={{ height: '170px' }}>
			<Row align="middle" justify="center" gutter={[65, 20]}>
				<Col>
					<SelectId
						selected={!!company_id}
						name="company_id"
						label={I18n.get('Select a industrial site')}
						style={{ margin: '0', fontWeight: 'normal' }}
						rules={[
							{
								message: I18n.get('Please, select a industrial site')
							}
						]}
					>
						<Select
							showSearch
							options={companies_list}
							defaultValue={queryUrl && queryUrl.get('company_id') ? queryUrl.get('company_id') : null}
							filterOption={(input, option) => filterCompany(option, input)}
							onChange={async (value: string) => handleUpdatedCompany(value)}
							placeholder={I18n.get('Select a industrial site')}
						/>
					</SelectId>

					<Row justify={'center'} align={'middle'} style={{ marginTop: '13px' }}>
						<Switch
							size="small"
							defaultChecked
							checked={!company ? false : isChecked}
							onChange={handleSwitch}
							disabled={!company}
							style={{ marginRight: '11px' }}
						/>
						<SwitchText disabled={!company || !isChecked}>
							{I18n.get('Use the same of the company')}
						</SwitchText>
					</Row>
				</Col>
				{company && (
					<Col>
						<UploadLogo
							url={company.url_logo || queryUrl.get('url_logo')}
							organizationId={queryUrl.get('organization_id')}
							companyId={company_id}
							blockButton={isUrlLogoCompany}
							setBlockButton={setIsUrlLogoCompany}
							type={'company'}
							fileList={fileList}
							setFileList={setFileList}
							isDisable={isChecked}
						/>
					</Col>
				)}
			</Row>
		</Col>
	);
}
