import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useApplicationContext } from '@/context/Application';
import { MinutesDetailsRequest } from '../hooks/types/request';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Organizations } from './components/Organizations';
import { Granularity } from './components/Granularity';
import { DotChartOutlined } from '@ant-design/icons';
import { Companies } from './components/Companies';
import { useGetMinutesDetails } from '../hooks';
import { DateInput } from './components/Date';
import { BarChartCollection } from '../Chart';
import { Users } from './components/Users';
import { FilterButton } from './styles';

const { useForm } = Form;

const firstDayDate = moment().startOf('year');
const lastDayDate = moment().endOf('year');

export const DatailForm: React.FC = () => {
	const [form] = useForm();
	const breakpoints = useBreakpoint();
	const { organization, company } = useApplicationContext();

	const initialState = {
		organization_ids: [organization.id],
		company_ids: [company.id],
		start_date: firstDayDate.toISOString(),
		end_date: lastDayDate.toISOString(),
		granularity: 'year'
	} as MinutesDetailsRequest;

	const { mutateAsync, isLoading, data: chartData } = useGetMinutesDetails();
	const [infos, setInfos] = useState<MinutesDetailsRequest>(initialState);

	useEffect(() => {
		infos && getMinutesDetails();
	}, [infos]);

	const getMinutesDetails = async () => {
		await mutateAsync(infos);
	};

	function handleDate(values: any) {
		const { end_date, start_date } = values;

		const params = {
			end_date: '',
			start_date: ''
		};

		const start = moment(end_date).toISOString();
		const end = moment(start_date).toISOString();
		params.end_date = start;
		params.start_date = end;

		return { ...values, ...params };
	}

	async function onFinish(): Promise<void> {
		const fields = await form.validateFields();
		const fieldsDateFix = handleDate(fields);
		setInfos(fieldsDateFix);
	}

	function clearFields(): void {
		form.resetFields();
		setInfos(initialState);
	}

	const initialValues = {
		...initialState,
		user_ids: undefined,
		start_date: firstDayDate,
		end_date: lastDayDate
	};

	return (
		<>
			<Form form={form} onFinish={onFinish} initialValues={initialValues} layout="vertical">
				<Row gutter={[30, 0]} justify="center">
					<Col span={11}>
						<Row>
							<Organizations />
						</Row>
						<Row>
							<Companies />
						</Row>
						<Row>
							<Users />
						</Row>
					</Col>
					<Col span={11}>
						<Row>
							<DateInput name="start_date" label="Start" errorMessage="Select a date" />
						</Row>
						<Row>
							<DateInput name="end_date" label="End" errorMessage="Select a date" />
						</Row>
						<Row>
							<Granularity />
						</Row>
					</Col>
					<Col span={24} style={{ textAlign: 'center' }}>
						<Form.Item>
							<FilterButton htmlType="submit" type="primary" size={!breakpoints.xxl ? 'small' : 'large'}>
								{I18n.get('Filter')}
							</FilterButton>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Row justify="end">
				<Col span={4}>
					<Button onClick={clearFields} type="link" style={{ position: 'absolute', bottom: '15px' }}>
						{I18n.get('Clear')}
					</Button>
				</Col>
			</Row>
			{isLoading ? <SkeletonDatailForm /> : <BarChartCollection chartData={chartData} />}
		</>
	);
};

export const SkeletonDatailForm: React.FC = () => {
	const breakpoints = useBreakpoint();
	return (
		<Row justify="center" align="middle">
			<Col>
				<Skeleton.Node
					active
					style={{
						textAlign: 'center',
						height: `${!breakpoints.xxl ? '150px' : '250px'}`,
						width: `${!breakpoints.xxl ? '400px' : '700px'}`
					}}
				>
					<DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
				</Skeleton.Node>
			</Col>
		</Row>
	);
};
