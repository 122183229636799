import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { Risk, ScoreContainer, AllInfosContainer } from './styles';
import { Title } from '@/components/Typography';
import { Spinner } from './Spinner';

type ScoreProps = {
	score?: number;
	sum?: number;
	result?: number;
};

const colors = ['#2CC852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];
const backgrounds = ['#2CC85233', '#FFDE3133', '#F78A3833', '#E7415033', '#9B54E233'];

const riskLevels = {
	0: 'Acceptable risk',
	1: 'Moderate risk',
	2: 'High Risk',
	3: 'Very high risk',
	4: 'Serious and imminent risk'
};

export function Score({ score, sum, result }: Readonly<ScoreProps>) {
	const normalize = result ? result - 1 : 0;
	const nullScore = score ?? 0;
	const color = colors[normalize];
	const background = backgrounds[normalize];
	const riskLevel = riskLevels[normalize as keyof typeof riskLevels];

	return (
		<>
			<Col lg={{ span: 11, offset: 1 }} xl={{ span: 5, offset: 0 }} xxl={4}>
				<Row>
					<Col span={12}>
						<Title level={3}>{I18n.get('Score')}</Title>
					</Col>
				</Row>
				<Row align={'middle'} style={{ height: '224px' }}>
					<Col span={20}>
						<Row justify={'center'} gutter={[0, 10]}>
							<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
								<Title level={4}>{I18n.get("Score's sum")}:</Title>
							</Col>
							<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
								<ScoreContainer>
									<span>{sum}</span>
								</ScoreContainer>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col lg={12} xl={6} xxl={7}>
				<Row>
					<Col span={24} style={{ textAlign: 'start' }}>
						<Title level={3}>{I18n.get('Worst score')}</Title>
					</Col>
				</Row>
				<Row justify={'center'}>
					<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
						<Risk color={color}>{I18n.get(riskLevel)}</Risk>
					</Col>
					<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
						<AllInfosContainer>
							<Spinner level={normalize} />
						</AllInfosContainer>
					</Col>
					<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
						<ScoreContainer color={color} backgroundColor={background}>
							<span>{nullScore}</span>
						</ScoreContainer>
					</Col>
				</Row>
			</Col>
		</>
	);
}
