import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { PlusCircleFilled } from '@ant-design/icons';

import { useContractPlansContext } from './context';
import { Button } from '@/components/ui/Button';
import { Title } from '@/components/Typography';

export function Header() {
	const { setUrl } = useContractPlansContext();

	function handleOpenCreateModal(): void {
		setUrl('modal', 'create');
	}

	return (
		<Row justify="space-around" align="middle">
			<Col span={12}>
				<Title level={4}>{I18n.get('Manage companies')}</Title>
			</Col>
			<Col span={12}>
				<Button
					type="primary"
					style={{ float: 'right' }}
					icon={<PlusCircleFilled />}
					onClick={handleOpenCreateModal}
				>
					{I18n.get('New company')}
				</Button>
			</Col>
		</Row>
	);
}
