export enum RiskScale {
	NOT_APPLICABLE,
	ACCEPTABLE,
	MODERATE,
	HIGH,
	VERY_HIGH,
	SERIOUS_AND_IMMINENT
}

export enum Size {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large'
}

export const FontSize = {
	[Size.SMALL]: '14px',
	[Size.MEDIUM]: '18px',
	[Size.LARGE]: '22px'
};
