import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import hooks from '../hooks';
import { RiskBar } from '../RiskBar';
import { DashboardCard } from '../styles';
import { emptyDataRisks, Risk, RiskBarOptions } from '../RiskBar/types';
import { useApplicationContext } from '@/context/Application';
import { DashboardRebaReportsDTO, ENUM_FIVE_SCORE_SCALE, RebaScores } from '../types';
import { MovementByScoreContainer, MovementByScoreSubTitle, MovementByScoreTitle, SubTitle, Title } from './styles';
import { useDashboardContext } from '../context';

const { useDashboardBodyParts } = hooks;

export const MovementByScoreCard: React.FC = () => {
	const { queryParams } = useDashboardContext();
	const { company, organization } = useApplicationContext();
	const { data: rebaReports, isError, isLoading } = useDashboardBodyParts(organization.id, company.id, queryParams);

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'right'
		},
		orientation: 'horizontal'
	};

	function risksMapper(bodyPartsQuery: DashboardRebaReportsDTO): Risk[] {
		const risks: Risk[] = mapScoresToRisks(bodyPartsQuery.scores, bodyPartsQuery);

		return risks;
	}

	function mapScoresToRisks(scores: RebaScores[], bodyPartsQuery: DashboardRebaReportsDTO): Risk[] {
		return scores
			.filter(({ amount }) => amount > 0)
			.map(({ amount, type, id, scale }) => {
				return {
					...ENUM_FIVE_SCORE_SCALE[type],
					caption: '',
					id,
					value: amount,
					percentage: (amount / bodyPartsQuery.reviews) * 100 + '%',
					score: scale,
					body_part: bodyPartsQuery.body_part,
					report: 'reba'
				};
			});
	}

	function checkIfHasReports(reports: DashboardRebaReportsDTO[]): boolean {
		return reports.filter((report) => report.reviews > 0).length > 0;
	}

	return (
		<DashboardCard>
			{isLoading ? (
				<span>{I18n.get('Loading')}...</span>
			) : isError ? (
				<span>{I18n.get('Oops... Something went wrong!')}</span>
			) : (
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Row gutter={[0, 2]}>
							<Col span={24}>
								<Title>{I18n.get('Movement by score')}</Title>
							</Col>

							<Col span={24}>
								<SubTitle>{I18n.get('Risk per Workstation')}</SubTitle>
							</Col>
						</Row>
					</Col>

					{checkIfHasReports(rebaReports) ? (
						rebaReports.map((bodyPart) => {
							return (
								<Col span={8} key={bodyPart.id}>
									<MovementByScoreContainer>
										<Row gutter={[0, 8]} align="middle" justify="center">
											<Col span={24}>
												<MovementByScoreTitle>
													{I18n.get(bodyPart.body_part)}
												</MovementByScoreTitle>
											</Col>
											<Col span={24}>
												<MovementByScoreSubTitle>
													{I18n.get('Evaluations')}
												</MovementByScoreSubTitle>
											</Col>
											{risksMapper(bodyPart).length > 0 ? (
												<Col span={20}>
													<RiskBar options={riskBarOptions} risks={risksMapper(bodyPart)} />
												</Col>
											) : (
												<Col span={20}>
													<RiskBar risks={emptyDataRisks} options={riskBarOptions} />
												</Col>
											)}
										</Row>
									</MovementByScoreContainer>
								</Col>
							);
						})
					) : (
						<Col span={8}>
							<MovementByScoreContainer>
								<Row gutter={[0, 8]} align="middle" justify="center">
									<Col span={24}>
										<MovementByScoreTitle>{I18n.get('No data available')}</MovementByScoreTitle>
									</Col>

									<Col span={20}>
										<RiskBar risks={emptyDataRisks} options={riskBarOptions} />
									</Col>
								</Row>
							</MovementByScoreContainer>
						</Col>
					)}
				</Row>
			)}
		</DashboardCard>
	);
};
