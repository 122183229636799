import React from 'react';
import { Row, Col } from 'antd';

import { PersonalisedReportSVG } from '@/components/Icons';

export const PersonalisedReportIcon: React.FC = () => (
	<Col xs={24}>
		<Row justify="center">
			<Col className="custom-icon" span={24}>
				<PersonalisedReportSVG width={25} height={25} />
			</Col>
		</Row>
	</Col>
);
