import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';

async function getCompany(id: string | null): Promise<any> {
	const { data } = await Api.get(`/contract-plan/company/${id}`);
	return data;
}

export const useGetCompany = (company_id: string | null) => {
	return useQuery([QUERY_KEYS.GET_COMPANY_CONTRACT_PLANS, { company_id }], () => getCompany(company_id));
};
