import styled from 'styled-components';
import { DatePicker, Input } from 'antd';

export const CustomRangePicker = styled(DatePicker.RangePicker)`
	width: 100%;
	height: 2.5rem;
	border-radius: 6px;
	border: 1px solid #d9d9d9;

	.ant-picker-input {
		input {
			font-size: 1rem !important;
		}
	}
`;

export const CustomInput = styled(Input)`
	height: 2.5rem;
	font-size: 1rem;
	border-radius: 6px;
	border: 1px solid #d9d9d9;
`;
