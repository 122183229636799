import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import Api from '@/services/api';

type UserMail = string;
type UrlProvider = string;
type UserProviderDTO = UserMail | undefined;

interface UserProvider {
	provider_url: UrlProvider;
}

interface ErrorResponse {
	message: string;
}

const getProviderUser = async (email: UserProviderDTO): Promise<UserProvider> => {
	const { data } = await Api.post('/sso/provider-user', { email });
	return data;
};

const openNotificationWithIcon = (message: string, description: string): void => {
	notification.error({
		message: I18n.get(message),
		description: I18n.get(description)
	});
};

export const useGetProviderUser = () => {
	return useMutation<UserProvider, AxiosError<ErrorResponse>, UserProviderDTO>(getProviderUser, {
		onSuccess: (data) => {
			window.location.replace(data.provider_url);
		},
		onError: (err) => {
			const title = 'Oops! Something happened.';
			const message = err.response?.data?.message || 'Failed to fetch information';
			openNotificationWithIcon(title, message);
		}
	});
};
