import { notification } from 'antd';
import { I18n } from '@aws-amplify/core';

export interface ICustomReport {
	showErrorMessage(message: string, description: string): void;
	showSuccessMessage(message: string, description: string): void;
}

export class CustomReport implements ICustomReport {
	showErrorMessage(message: string, description: string): void {
		notification.error({
			message: I18n.get(message),
			description: I18n.get(description)
		});
	}

	showSuccessMessage(message: string, description = ''): void {
		notification.success({
			duration: 2,
			message: I18n.get(message),
			description: I18n.get(description)
		});
	}
}

export const customReportService = new CustomReport();
