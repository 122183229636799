import React, { ReactElement } from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { Form, InputNumberProps } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Text } from '@/components/Typography';
import { CustomFormItem } from '../../../components/styles';
import { RequiredLabel } from '../../../components/RequiredLabel';

const { useWatch, useFormInstance } = Form;

interface CustomInputFormFieldProps {
	title: string;
	message?: string;
	namePath: NamePath;
	isRequired?: boolean;
	children?: ReactElement<InputNumberProps>;
}

export const CustomInputFormField: React.FC<CustomInputFormFieldProps> = ({
	title,
	message,
	namePath,
	isRequired = true,
	children
}) => {
	const form = useFormInstance();
	const totalWorkingPopulation = useWatch(['characteristics', 'total_working_population'], form);
	const label = isRequired ? <RequiredLabel title={title} /> : <Text>{I18n.get(title)}</Text>;
	return (
		<CustomFormItem
			label={label}
			name={namePath}
			labelCol={{ span: 24 }}
			rules={[
				{
					required: isRequired,
					message: ''
				},
				() => ({
					validator(): Promise<any> {
						if (totalWorkingPopulation === 0) {
							return Promise.reject(I18n.get(message));
						}
						return Promise.resolve();
					}
				})
			]}
		>
			{children}
		</CustomFormItem>
	);
};
