import React from 'react';
import { Text } from '@/components/Typography';
import { Col, Row, Skeleton } from 'antd';

import * as S from './styles';

interface PaginationStyledProps {
	page: number;
	total?: number;
	maxPage?: number;
	loading: boolean;
	hasItemPerPage?: boolean;
	setPage: (size: number) => void;
	setMaxPage?: (size: number) => void;
}

type SelectOption = {
	value: number;
	label: string;
};

export const PaginationCustom: React.FC<PaginationStyledProps> = ({
	page,
	total,
	loading,
	maxPage = 5,
	hasItemPerPage = true,
	setPage,
	setMaxPage = () => {}
}) => {
	const selectOptions: SelectOption[] = [5, 10, 20, 30, 50, 100, 200].map((option) => ({
		label: `${option}`,
		value: option
	}));

	const handleChange = (value: any) => {
		setMaxPage(value);
		setPage(1);
	};

	if (loading) {
		return (
			<Row justify={'space-between'} style={{ marginTop: '1rem', padding: '0 1rem' }}>
				{hasItemPerPage ? (
					<Col>
						<Skeleton.Input active />
					</Col>
				) : (
					<Col></Col>
				)}
				<Col>
					<Skeleton.Input active />
				</Col>
			</Row>
		);
	}

	return (
		<Row justify={'space-between'} style={{ marginTop: '1rem', padding: '0 1rem' }}>
			{hasItemPerPage ? (
				<Col>
					<Text>Items per page</Text>
					<S.SelectCustom defaultValue={maxPage} options={selectOptions} onChange={handleChange} />
				</Col>
			) : (
				<Col></Col>
			)}
			<Col>
				<S.PaginationCustom
					hideOnSinglePage={false}
					showSizeChanger={false}
					total={total}
					current={page === 0 ? page + 1 : page}
					pageSize={maxPage}
					onChange={(page) => {
						setPage(page);
					}}
				/>
			</Col>
		</Row>
	);
};
