import React from 'react';
import { Col, Row } from 'antd';

import { Title } from '@/components/Typography';
import { CustomDivider } from './styles';
import { StepKeyDescription } from './StepKeyDescription';
import { PersonalisedReportResultStepKeyResponse } from '../../hooks';
import { PersonalisedReportStepKeyResultProvider } from './StepKeyDescription/context';

type StepsDescriptionProps = {
	stepName: string;
	stepKeys: PersonalisedReportResultStepKeyResponse[];
};

export const StepsDescription: React.FC<StepsDescriptionProps> = ({ stepName, stepKeys }) => {
	function renderStepKeyResultDescription(): React.ReactNode {
		return stepKeys?.map((stepKey) => {
			if (hasSubStepKeyResponse(stepKey)) {
				return renderSubStepKeyResultDescription(stepKey);
			}
			return (
				<PersonalisedReportStepKeyResultProvider key={stepKey.id} stepKey={stepKey}>
					<StepKeyDescription />
				</PersonalisedReportStepKeyResultProvider>
			);
		});
	}

	function hasSubStepKeyResponse(stepKey: PersonalisedReportResultStepKeyResponse): boolean {
		return !!stepKey.sub_step_keys_results && stepKey.sub_step_keys_results.length > 0;
	}

	function renderSubStepKeyResultDescription(stepKey: PersonalisedReportResultStepKeyResponse) {
		const { sub_step_keys_results } = stepKey;
		return sub_step_keys_results?.map((subStepKeyResult) => {
			const { id, score, result, exposure, consequence, description, probability, sub_step_keys_injuries } =
				subStepKeyResult;
			return (
				<PersonalisedReportStepKeyResultProvider
					key={subStepKeyResult.id}
					stepKey={{
						id,
						score,
						result,
						exposure,
						consequence,
						description,
						probability,
						injuries: sub_step_keys_injuries
					}}
				>
					<StepKeyDescription />
				</PersonalisedReportStepKeyResultProvider>
			);
		});
	}

	return (
		<Col xs={24}>
			<Row gutter={[0, 8]}>
				<Col offset={1} span={23}>
					<Row>
						<Title style={{ margin: 0 }} level={3}>
							{stepName}
						</Title>
					</Row>
				</Col>
				<Col offset={1} span={23}>
					<Row gutter={[0, 8]}>
						<Col span={1}>
							<CustomDivider type="vertical" />
						</Col>
						{renderStepKeyResultDescription()}
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
