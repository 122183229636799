import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Text, Title } from '@/components/Typography';

interface CardContainerProps {
	checked?: boolean;
	skeleton?: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
	gap: 1rem;
	height: 75px;
	display: flex;
	padding: 1rem;
	user-select: none;
	position: relative;
	align-items: center;
	border-radius: 1rem;
	flex-direction: row;
	cursor: ${(props) => (props.skeleton ? 'auto' : 'pointer')};
	background-color: ${(props) => (props.checked ? '#EAEEFD' : '#ffffff')};
	border: ${(props) => (props.checked ? '2px solid #2F54EB' : '2px solid #e6e6e6')};

	.ant-checkbox-wrapper {
		.ant-checkbox {
			display: none !important;
		}
		span {
			gap: 1rem;
			display: flex;
			align-items: center;
			flex-direction: row;
		}
	}

	.ant-skeleton {
		.ant-skeleton-image {
			max-width: 50px;
			max-height: 50px;
			svg {
				height: 2rem;
			}
		}
	}

	transition: all 0.2s;
	&:hover {
		filter: ${(props) => (props.skeleton ? 'brightness(1)' : 'brightness(0.9)')};
	}
`;

export const CustomRow = styled(Row)`
	margin-top: 1rem;
`;

export const CustomTitle = styled(Title)`
	font-weight: 600;
	margin-bottom: 0 !important;
`;

export const CustomSubtitle = styled(Text)`
	font-weight: 400;
	font-size: 0.8rem;
`;

export const CustomToolCol = styled(Col)`
	overflow: hidden !important;
	font-size: 0.6rem !important;
	white-space: nowrap !important;

	h5 {
		@media (min-width: 1600px) {
			width: 110px;
			overflow: hidden !important;
			font-size: 0.8rem !important;
			white-space: nowrap !important;
			text-overflow: ellipsis !important;

			font-size: 1rem !important;
		}
	}
`;

export const CustomCol = styled(Col)`
	.ant-skeleton-element {
		width: 100%;
		.ant-skeleton-input {
			width: 100%;
			min-width: 100%;
		}
	}
`;
