import styled from 'styled-components';
import { Col, Form } from 'antd';

type SelectIdProps = {
	selected: boolean;
};

type NameInputProps = {
	disabled?: boolean;
};
type SwitchTextProps = {
	disabled?: boolean;
};

export const Create = styled.div`
	display: 'flex';
	flex-wrap: 'nowrap';
	padding: 8;
`;

export const Edit = styled(Col)`
	.ant-btn:not([disabled]):hover {
		background: #d6d6d6;
	}
`;

export const Delete = styled(Col)`
	.ant-btn:not([disabled]):hover {
		background: #e74150;
		.anticon {
			color: white;
		}
	}
`;

export const Confirm = styled(Col)`
	.ant-btn:not([disabled]) {
		border-color: #2cc852;
		.anticon {
			color: #2cc852;
		}
	}
`;

export const SelectId = styled(Form.Item)<SelectIdProps>`
	font-weight: ${(props) => (props.selected ? '400' : '600')};
	width: 200px;
	label {
		width: 200px;
	}

	.ant-form-item-explain-error {
		position: absolute;
		white-space: nowrap;
	}
`;

export const NameInput = styled(Form.Item)<NameInputProps>`
	font-weight: ${(props) => (props.disabled ? '400' : '600')};
	.ant-form-item-explain-error {
		position: absolute;
		white-space: nowrap;
	}
`;

export const SwitchText = styled.span<SwitchTextProps>`
	color: ${({ disabled }) => (disabled ? 'rgba(38, 38, 38, 0.5)' : 'black')};
`;
