import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, Skeleton } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { UserResponse } from './types';

const { useFormInstance } = Form;

type UserSelectProps = {
	userId: string;
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	fieldName: NamePath;
	users: UserResponse[] | undefined;
	handleClearUsers?: () => void;
	handleSelectUser?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export const UserSelect: React.FC<UserSelectProps> = ({
	users,
	userId,
	loading,
	disabled,
	fieldName,
	size = 'middle',
	handleSelectUser,
	handleClearUsers
}) => {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string): Promise<void> {
		if (handleSelectUser) {
			handleSelectUser(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear(): void {
		if (handleClearUsers) {
			handleClearUsers();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			value={userId}
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			placeholder={I18n.get('User')}
			onChange={(value: string) => handleChange(value)}
			filterOption={(input, option) => filterOption(option, input)}
			options={users?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
};
